<div class="page__content">
    <div class="content__inner">
        <div class="content__login">
            <div class="card__login">
                <div *ngIf="this.tfa.secret">
                    <h5 style="border-bottom: 1px solid #a8a8a8; padding-bottom: 5px">
                        Current Settings
                    </h5>

                    <img [src]="tfa.secret" alt="" class="img-thumbnail" style="display: block; margin: auto" />

                    <p>Secret Key - {{ tfa.secret || tfa.secret }}</p>

                    <p>Auth Type - Time Based - OTP</p>

                    <button class="btn btn-lg btn-danger btn-block btn-signin" (click)="disabledTfa()">
            Disable TFA
          </button>
                </div>
                <div *ngIf="!tfa.secret">
                    <h5 style="border-bottom: 1px solid #a8a8a8; padding-bottom: 5px">
                        Doble Factor
                    </h5>

                    <span *ngIf="!!tfa.secret">
            <p>Escanera el coógido QR</p>

            <img
              [src]="tfa.secretURL"
              alt=""
              class="img-thumbnail"
              style="display: block; margin: auto"
            />

            <p>Clave secreta - {{ tfa.secret }}</p>

            <form class="form-group" (ngSubmit)="confirm()" #otpForm="ngForm">
              <input
                name="accessToken"
                type="number"
                #iaccessToken="ngModel"
                class="form-control"
                maxlength="6"
                placeholder="Introduzca el código"
                id="accessToken"
                autocomplete="off"
                [(ngModel)]="accessToken"
                required
              />
              <br />
              <div class="form-group">
                <button
                  type="Submit"
                  class="bbtn btn-primary btn-block"
                  [disabled]="iaccessToken?.errors?.['required']"
                >
                  Habilitar
                </button>
              </div>
            </form>
            <p
              class="text-danger"
              style="text-align: center"
              *ngIf="errorMessage"
            >
              {{ errorMessage }}
            </p>
          </span>
                </div>
            </div>
        </div>
    </div>
</div>