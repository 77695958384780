<div class="content__inner">
  <h1>{{getText("titulo")}}</h1>
  <div class="row main-row">
    <div class="col-sm-12">
      <div
        class="field field--name-body field--type-text-with-summary field--label-hidden field--item"
      >
        <h3>{{getText("tit_aviso_legal")}}</h3>

        <p>{{getText("txt_aviso_legal")}}</p>

        <p>{{getText("txt_denominacion_social")}}</p>

        <p>{{getText("txt_domicilio_social")}}</p>

        <p>{{getText("txt_domicilio_social_2")}}</p>

        <p>{{getText("txt_cif")}}</p>

        <p>{{getText("txt_email")}} <a href="{{emailLink}}">{{email}}</a> </p>

        <h4>{{getText("sub_usuarios")}}</h4>

        <p>{{getText("txt_usuarios")}}</p>

        <h4>{{getText("sub_uso_de_web_1")}}</h4>

        <h5><strong>{{getText("sub_uso_de_web_2")}}</strong></h5>

        <p>{{getText("txt_uso_de_web_1")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_uso_de_web_2")}}</p>

        <p>{{getText("txt_uso_de_web_3")}}</p>

        <ul>
          <li>{{getText("txt_compromiso_uso_web_1")}}</li>
          <li>{{getText("txt_compromiso_uso_web_2")}}</li>
          <li>{{getText("txt_compromiso_uso_web_3")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_compromiso_uso_web_4")}}</li>
          <li>{{getText("txt_compromiso_uso_web_5")}}</li>
        </ul>
        <p><strong>{{stxt_hencok_web}}</strong>{{getText("txt_uso_de_web_4")}}</p>

        <p>{{getText("txt_uso_de_web_5")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_uso_de_web_6")}}</p>

        <h5><strong>{{getText("sub_captura_de_información")}}</strong></h5>

        <ul>
          <li>{{getText("txt_captura_de_información_1")}}</li>
          <li>{{getText("txt_captura_de_información_2")}}</li>
          <li>{{getText("txt_captura_de_información_3")}}</li>
        </ul>
        <p>{{getText("txt_uso_de_web_7")}}</p>

        <p>{{getText("txt_uso_de_web_8")}}<em>{{getText("em_uso_de_web_1")}}</em>{{getText("txt_uso_de_web_9")}}
          <em>{{getText("em_uso_de_web_2")}}</em>{{getText("txt_uso_de_web_10")}}</p>

        <p>{{getText("txt_uso_de_web_11")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_uso_de_web_12")}}</p>

        <p>{{getText("txt_uso_de_web_13")}}</p>

        <p>{{getText("txt_uso_de_web_14")}}</p>

        <p>{{getText("txt_uso_de_web_15")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_uso_de_web_16")}}</p>

        <p>{{getText("txt_uso_de_web_17")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_uso_de_web_18")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_uso_de_web_19")}}</p>

        <h4>{{getText("sub_propiedad_intelectual")}}</h4>

        <p><strong>{{stxt_hencok_web}}</strong>{{getText("txt_propiedad_intelectual_1")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_propiedad_intelectual_2")}}</p>

        <p>{{getText("txt_propiedad_intelectual_3")}}<strong>{{stxt_hencok_web}}</strong>{{getText("txt_propiedad_intelectual_4")}}</p>

        <p>{{getText("txt_propiedad_intelectual_5")}}<strong>{{stxt_hencok_dot}}</strong>
        </p>

        <p>{{getText("txt_propiedad_intelectual_6")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_propiedad_intelectual_7")}}<strong>{{stxt_hencok_dot}}</strong></p>

        <p>{{getText("txt_propiedad_intelectual_8")}}</p>

        <h4>{{getText("sub_exclusion_garantias")}}</h4>

        <p><strong>{{stxt_hencok_upper}}</strong>{{getText("txt_exclusion_garantias")}}</p>

        <h4>{{getText("sub_modificaciones")}}</h4>

        <p>
          <strong>{{stxt_hencok_upper}}</strong>{{getText("txt_modificaciones")}}</p>

        <h3>{{getText("tit_politica_enlaces")}}</h3>

        <p>{{getText("txt_politica_enlaces_1")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_politica_enlaces_2")}}</p>

        <ul>
          <li>{{getText("txt_politica_enlaces_3")}}<strong>{{stxt_hencok_dot}}</strong></li>
          <li>{{getText("txt_politica_enlaces_4")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_politica_enlaces_5")}}</li>
          <li>{{getText("txt_politica_enlaces_6")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_politica_enlaces_7")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_politica_enlaces_8")}}</li>
          <li>{{getText("txt_politica_enlaces_9")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_politica_enlaces_10")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_politica_enlaces_11")}}</li>
          <li>
            <strong>{{stxt_hencok_upper}}</strong>{{getText("txt_politica_enlaces_12")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_politica_enlaces_13")}}</li>
          <li>
            <strong>{{stxt_hencok_upper}}</strong>{{getText("txt_politica_enlaces_14")}}</li>
          <li>
            <strong>{{stxt_hencok_upper}}</strong>{{getText("txt_politica_enlaces_15")}}<strong>{{stxt_hencok}}</strong>{{getText("txt_politica_enlaces_16")}}<strong>{{stxt_hencok_dot}}</strong>
          </li>
        </ul>
        <h4>{{getText("sub_derecho_exclusion")}}</h4>

        <p>
          <strong>{{stxt_hencok_upper}}</strong>{{getText("txt_derecho_exclusion")}} </p>

        <h4>{{getText("sub_generalidades")}}</h4>

        <p>
          <strong>{{stxt_hencok_upper}}</strong>{{getText("txt_generalidades")}}</p>

        <h4>{{getText("sub_condiciones_duracion")}}</h4>

        <p>
          <strong>{{stxt_hencok_upper}}</strong>{{getText("txt_condiciones_duracion")}}</p>

        <h4>{{getText("sub_reclamaciones")}}</h4>

        <p>
          <strong>{{stxt_kdigital}}</strong>{{getText("txt_reclamaciones_1")}}<a href="{{emailLink_kdigital}}">{{email_kdigital}}</a>{{getText("txt_reclamaciones_2")}}</p>

        <h4><strong>{{getText("tit_legislación_jurisdicción")}}</strong></h4>

        <p>{{getText("txt_legislación_jurisdicción_1")}}<strong>{{stxt_hencok_upper}}</strong>{{getText("txt_legislación_jurisdicción_2")}}</p>
      </div>
    </div>
  </div>
</div>
