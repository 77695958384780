
<div class="page_content_sidebar" *ngIf="detallesP">
    <div class="content__inner" >
        <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'container2':'container' ">

            <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'center-Hencok':'center'">
                <div *ngIf="!isLoggedIn">
                    <h1>{{environment.TITLE_REGISTER}}</h1>
                    <p>
                        {{environment.TEXT_REGISTER}}
                    </p>
                </div>



                <div class="row">
                    
                    <div class="col">
                        <div class="row pt-4">
            
                                <div class="col-12 ml-4 d-flex px-2" >
                                    <h5>Detalle del gasto/ingreso: {{detallesP.id}}</h5>
                              
    
                            </div>
                          
                                <div class="col-12 ml-4 d-flex px-2">
                                    {{formatDate(detallesP.fechaCreacion)}}
                                </div>
            
                            
                            
                            
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div *ngIf="!userAdmin" class="id-usuario">
                                <div class="col-sm-12 mt-3">
                                    <div class="col-sm-12 w-100">
                                        <p class="colorId">#ID{{userLogged.id}}</p>
                                        <p>Nombre : {{userLogged.nombre}} {{userLogged.apellido1}} {{userLogged.apellido2}}</p>
                                        <p>Correo electrónico : {{userLogged.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="border col-12 mt-3 mx-3"></div>
                    <div class="row pt-4 ml-3">
                        Concepto del gasto: {{detallesP.concepto}}
                    </div>
                    <div class="row pt-4 ml-3 justify-content-between">
                        <div class="col-5 card border-0 card-width-importe totalGasto-resultado-background">
                            <div class="row pl-2 pt-2 pb-1">
                            <div class="col"><p style="font-size:17px"> Total Gasto:</p></div>
                            </div>
                            <div class="row pl-2 pb-1 pt-1">
                                <div class="col">{{detallesP.importe}}€</div>
                                <div class="col mr-3 text-center align-middle align-items-center"  [ngClass]="detallesP.estado.nombre === 'PENDIENTE' ? 'Colorpendiente-inspeccion':
                                detallesP.estado.nombre === 'COMPLETADO' ? 'Colorcompletado-inspeccion' :
                                'Colorrechazado-inspeccion' ">
                                    <p class="m-0 mt-1" style="font-size:16px">
                                        {{detallesP.estado.nombre}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-5 detalleGastoDiv">
                            <div class="row pl-2 pt-2 pb-1">
                                <div class="col"><p style="font-size:17px"> Detalle Gasto:</p></div>
                                </div>
                                <div class="row pl-2 pb-1 pt-1">
                                    <div class="col">Fecha Actual<br> {{fechaActual}}</div>
                                    <div class="col">Total gasto<br> {{detallesP.importe}}€</div>
                                    
                                </div>
                        </div>
                    </div>
                    <div class="border col-12 mt-5 mx-3"></div>

                    <div class="row pt-4 ml-3">
                        Documentos archivados:
                    </div>
                        
                    <div id="listaFacturas" class="row">

                        <li class="list-group-item p-3  border rounded shadow documentosAdjuntos col-12"
                        *ngFor="let detalle of detalles">
                        <div class="row justify-content-center">
                            <div class="col-12 text-center">
                                <div class="card border-0" (click)="sacarEmbed(detalle,$event)" style="cursor:pointer;">
                                    {{detalle.nombre}}
                                </div>
                            </div>
                            <div class="col-12 text-center mt-2">
                                {{sacarPrimerEmbed(detalle)}}
                                
                                
                            </div>
                        </div>
                      </li>
                    </div>
                    <div class="row justify-content-end">
                        <button type="button" class="btn  btn-primary mr-2 justify-content-end" (click)="closeSidebar()" [disabled]="submitted">
                            <div>Cerrar</div>
                        </button>
                    </div>
                    
                        

       </div>
