import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { GastosService } from 'src/app/_services/hencok/gastos.service';
import { ImpuestosService } from 'src/app/_services/hencok/impuestos.service';
import { NexcloudService } from 'src/app/_services/nexcloud.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { RaizComponent } from 'src/app/raiz/raiz.component';
import { environment } from 'src/environments/environment.hencok';
import { GestionImpuestosComponent } from '../gestion-impuestos/gestion-impuestos.component';

@Component({
  selector: 'app-visualizar-impuesto',
  templateUrl: './visualizar-impuesto.component.html',
  styleUrls: ['./visualizar-impuesto.component.css']
})
export class VisualizarImpuestoComponent {
  form: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    periodo_impuesto: new UntypedFormControl("", Validators.required),
    fecha_maxima: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_impuesto: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),

  },);

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  submitted: boolean = false;
  register2 = false;
  InfoRegistro: any = [];
  environment = environment;
  isLoggedIn = false;
  arrayDocuments = [];

  tipos: any = [];
  trimestres: any = []

  usuarios: any[] = [];
  usuario: any = -1;
  fechaActual: string;
  @Input() detallesP: any;
  @Input() detalles: any;
  mostrado: any = false;
  auxiliar: boolean = false;
  @Input() documentoData: any;

  pdfAuxiliar: string;
  userLogged: any;
  userAdmin: Boolean = false;
  name = 'Angular 5';
  load: boolean;

  uploadDocuments: Boolean = false;
  documento:any;


  constructor(
    private _router?: Router,
    private tokenStorageService?: TokenStorageService,
    public datepipe?: DatePipe,
    private impuestoService?: ImpuestosService,
    private _serviceGeneral?: ServicioGeneral,

  ) { }

  adviseError: boolean = false;
  typeError: boolean = false;
  
  ngOnInit(): void {
    // this.detalles.forEach(element => {
    //   this.sacarUrlPdf(element);
    // });

    let date = new Date()
   // console.log('init',this.documentoData)
    this.fechaActual = this.formatDate2(date);

    this.isLoggedIn = !!this.tokenStorageService.getToken();

    this.userLogged = this.tokenStorageService.getUser();
    let rol = this.tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }

    this.register2 = false;
    this.getTipoImpuesto();
    this.getTrimestre();
    this.getUserList();
  


  }


  clearAllDetalles(){
    var lista = Array.from(document.getElementById('impuesto').children as HTMLCollectionOf<HTMLElement>)
    Array.from(lista).forEach((impuesto) => {
     if(impuesto.firstElementChild.firstElementChild.nextElementSibling.children.length > 0){
      impuesto.firstElementChild.firstElementChild.nextElementSibling.innerHTML = "";
     }
    })
    this.auxiliar = true;
  }
  

 
  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => {
       // console.log(error);
      }
    })
  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id)
      .subscribe({
        next: (usuario) => {
          this.usuario = usuario['Usuario'];
         // console.log(this.usuario);

        },
        error: (error) => {
          //console.log(error);
        }
      });
  }
 

  subirArchivo(): any {
    try {
      const formularioDatos = new FormData();
      this.InfoRegistro.forEach(subirArchivo => {
        //console.log(subirArchivo);
        subirArchivo.append('files', subirArchivo)
      })
      this.InfoRegistro.post(environment.API_URL + `/`, this.InfoRegistro)
      //this.InfoRegistro.post(`http://localhost:7090/`, this.InfoRegistro)
        .subcribe(res => {
          //console.log('Respuesta del servidor', res);

        })
    } catch (e) {
      //console.log('ERROR', e);
    }
  }

 
  formatDate2(date: Date) {
    let month = '' + date.getMonth();
    //console.log(month)
    let day = '' + date.getDate()
    const year = date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return day + "/" + month + "/" + year;
  }
  


  upload(): void {
    //get image upload file obj;
  }

  getTipoImpuesto() {
    return this.impuestoService.getTipoimpuesto().subscribe({
      next: (data) => {
        this.tipos = data;
      },
    });
  }
  getTrimestre() {
    return this.impuestoService.getTtrimestre().subscribe({
      next: (data) => {
        this.trimestres = data;
      },
    });
  }


  removeFile(index: number): void {
    this.arrayDocuments.splice(index, 1);
  }

  reloadToMenuOption() {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/admin-hencok/gastos-ingresos'], { queryParams: { action: 2 } });

  }


  sacarPrimerEmbed() {
    this.clearAllDetalles();
    //console.log("llamo a descargar");
    //console.log("le llega al metodo",this.detallesP.documentoData);
    if(this.detallesP.documentoData!=undefined) {
      var lista = Array.from(document.getElementById('impuesto').children as HTMLCollectionOf<HTMLElement>)
      
      var padreEmbed = lista[0].firstElementChild.firstElementChild.nextElementSibling;
      padreEmbed.appendChild(this.detallesP.documentoData);

    }
  }


  closeSidebar(){
    GestionImpuestosComponent.closeSidebarClienteRemoto();
  }

}
