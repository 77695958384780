<div [hidden]="mostrarFormulario" [hidden]="editarFormulario">
    <div class="d-flex flex-row justify-content-between align-items-center full-form-group ">
        <h1 class="m-0">Gestión de Facturas</h1>
        <div class="d-flex flex-row justify-content-between">
            <button class="btn btn-outline-primary align-middle" (click)="setMostrarRecibidas(true)"
                [hidden]="mostrarRecibidas" [hidden]="editarFormulario">
                <mat-icon class="align-middle mr-2">assignment_returned</mat-icon><span
                    class="align-middle">Recibidas</span>
            </button>
            <button class="btn btn-outline-primary align-middle ml-2" (click)="setMostrarRecibidas(false)"
                [hidden]="!mostrarRecibidas" [hidden]="!editarFormulario">
                <mat-icon class="align-middle mr-2">assignment</mat-icon><span class="align-middle">Emitidas</span>
            </button>
            <button class="btn btn-primary align-middle ml-2" (click)="setMostrarFormulario(true)"
                [hidden]="mostrarFormulario" [hidden]="editarFormulario">
                <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nueva factura</span>
            </button>
        </div>
    </div>
    <div class="full-form-group">
        <div>
            
            <div class="col-sm-12 d-flex justify-content-between card-total-grande mt-5 row mb-4 ml-0">
                <!-- <p class="col-sm-12">Gastos e ingresos</p> -->
                <div class="col-12 col-xl-4 p-0">
                    <h5 class="pb-2">Facturas</h5>

                    <div class="card border-0 rounded shadow card-info">
                        <div class="row w-100 card-with-results"></div>
                        <div class="col-12 mt-4 ml-3 mr-3">
                            <p class="tinyLabel">TOTAL FACTURADO</p>
                            <p class="totalFacturado mt-2">€{{total_facturado}}</p>
                        </div>
                        <div class="col-lg-12 mt-2 mb-3 ml-3 mr-3">
                            <div class="row-ingreso-gasto row w-100">
                                <div class="col-xl-6">
                                    <div class="colorPendiente">
                                        <li>Ingreso</li>
                                    </div>
                                    €{{total_pendiente}}
                                </div>
                                <div class="col-xl-6">
                                    <div class="colorDeuda">
                                        <li>Gasto</li>
                                    </div>
                                    €{{total_deuda}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col-12 col-xl-6 border-0 rounded shadow card-info mt-2">
                    <div class="d-flex mt-5">
                        <div class="iconName rounded-circle ml-2" style="width: 60px; height: 60px;">
                                <p *ngIf="usuario?.imgPerfil==undefined">
                                    {{getFirstLetter(usuario?.nombre)}}{{getFirstLetter(usuario?.apellido1)}}</p>
                                <img *ngIf="usuario?.imgPerfil!=undefined" src="data:image;base64,{{usuario.imgPerfil}}" width="100%"
                                    height="100%" class="rounded-circle">
                        </div>
                    </div>

                    <div class="d-flex flex-wrap">
                        <div>
                            <div class="ml-4 mt-3">
                                <p class="colorOption"><b>#ID{{this.usuario ? this.usuario.id : ''}}</b></p>
                                <p class="infoUsu"><b>{{this.usuario ? this.usuario.nombre : ''}} {{this.usuario ?
                                        this.usuario.apellido1 : ''}}
                                        {{this.usuario ? this.usuario.apellido2 : ''}}</b></p>
                                <p class="infoUsu">{{this.usuario ? this.usuario.email : ''}}</p>
                            </div>
                        </div>

                        <div class="col-sm-12 d-flex row flex-row mt-4 pl-4 ml-0">
                            <div class="col-12 col-xl-8 pl-0">
                                <p class="tinyLabel">Dirección</p>
                                <div class="d-flex flex-wrap">
                                    <div>{{this.usuario ? this.usuario.direcciones[0].name_road : ''}}, {{this.usuario ?
                                        this.usuario.direcciones[0].number_road : ''}} &nbsp;</div>
                                    <div>{{this.usuario ? this.usuario.direcciones[0].postal_code : ''}} {{this.usuario
                                        ?
                                        this.usuario.direcciones[0].province.nombre : ''}}</div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-4 pl-0">
                                <p class="tinyLabel">Teléfono</p>
                                <p>{{this.usuario ? this.usuario.telefono : ''}}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="subtitle">
            <h5 class="m-0">Listado de todas las facturas</h5>
        </div>
        <div class="w-100 border-bottom tableHead">
            <div class="w-100 filteringTable">
                <div class="w-100 row ordering-table">
                    <div class="card-width-number-invoice-table filter-container col-1">
                        <input
                        type="text"
                        class="searchinput"
                        placeholder="ID CLIENTE"
                        (keyup)="doFilter($event, 'idCliente', 'LIKE', 'STRING')"
                      />
                      <button (click)="changeSorts('idCliente', 'idCliente')"><a [ngClass]="sortArray['idCliente']==='ASC' ? 'asc-sort': sortArray['idCliente']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div> 
                    <div class="card-width-date-table filter-container col-2" >
                        <input
                            type="text"
                            class="searchinput"
                            onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}"
                            placeholder="FECHA"
                            (change)="
                            doFilter($event, 'fechaFactura', 'LIKE', 'DATE')
                            "
                        />
                        <button (click)="changeSorts('fechaFactura', 'fechaFactura')"><a [ngClass]="sortArray['fechaFactura']==='ASC' ? 'asc-sort': sortArray['fechaFactura']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-invoice-table filter-container col-2">
                        <input
                        type="text"
                        class="searchinput"
                        placeholder="FACTURA"
                        (keyup)="doFilter($event, 'numeroFactura', 'LIKE', 'DOUBLE')"
                      />
                      <button (click)="changeSorts('numeroFactura', 'numeroFactura')"><a [ngClass]="sortArray['numeroFactura']==='ASC' ? 'asc-sort': sortArray['numeroFactura']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-status-table filter-container col-2">
                        <input
                        type="text"
                        class="searchinput"
                        placeholder="ESTADO"
                        (keyup)="doFilter($event, 'estado.nombre', 'LIKE', 'STRING')"
                        />
                        <button (click)="changeSorts('estado.nombre', 'estado')"><a [ngClass]="sortArray['estado']==='ASC' ? 'asc-sort': sortArray['estado']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="col-5 row m-0 pr-0">
                        <div class="card-width-name-table filter-container col-7">
                            <input
                            type="text"
                            class="searchinput"
                            placeholder="NOMBRE"
                            (keyup)="doFilter($event, 'nombre', 'LIKE', 'STRING')"
                          />
                          <button (click)="changeSorts('nombre', 'nombre')"><a [ngClass]="sortArray['nombre']==='ASC' ? 'asc-sort': sortArray['nombre']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                        </div>
                        <div class="card-with-actions col-5 pr-0">
                            <div class="buttons">
                                ACCIONES
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card w-100 card-generic border-0">
            <div *ngIf="facturas?.length == 0"
                    class="d-flex flex-row justify-content-center empty-data-message my-4">

                    <span>No se han encontrado registros que mostrar</span>

                </div>
            <ul class="list-group list-group-flush">
                <div *ngIf="facturas != null">
                    <div class="list-group-item p-3 d-flex border rounded shadow"
                        *ngFor="let factura of facturas | filterFacturas : searchText">
                        <div class="row w-100 row-with-cards ">
                            <div class="card border-0 card-width-number-invoice col-md-6 col-lg-1 col-xl-1">
                                #{{factura.idCliente}}
                            </div>
                            <div class="card border-0 card-width-date col-md-6 col-lg-4 col-xl-2">
                                {{getDateToFilter(factura.fechaFactura)}}
                            </div>
                            <div class="card border-0 card-width-invoice col-md-6 col-lg-4 col-xl-2"
                                (click)="downloadInvoice(factura)">
                                {{factura.numeroFactura}}
                            </div>
                            <div class="card border-0 card-width-status col-md-6 col-lg-3 col-xl-2 status"
                            [ngClass]="factura.estado.nombre === 'PENDIENTE' ? 'colorpendiente':
                            factura.estado.nombre === 'COMPLETADO' ? 'colorcompletado' :
                            'colorrechazado' ">                        {{factura.estado.nombre}}
                            </div>


                            <div *ngIf="!mostrarRecibidas" class="col-12 col-xl-5 row row-name-button">
                                <div class="card border-0 card-width-name col-12 col-xl-7">
                                    {{factura.nombre}}
                                </div>
                                <div class="col-12 col-xl-5 button-center"> 
                                    <div class="buttons-flex-container align-items-center card-width-actions">
                                        <a (click)="openPDF(factura,getDateToFilter(factura.fechaFactura), getDateToFilter(factura.fechaVencimiento))"
                                            class="eye-button activated" title="Ver"></a>
                                        <a (click)="factura.estado.nombre=='PENDIENTE'? setEditarFormularioSend(true, factura): showEditDeleteError('editar')"
                                            class="edit-button activated pencil" title="Editar"></a>
                                        <a (click)="downloadInvoice(factura)" class="download-button activated" title="Descargar"></a>
                                        <a (click)="factura.estado.nombre=='PENDIENTE'? dialog.showModal(): showEditDeleteError('borrar')"
                                            class="delete-button activated" title="Eliminar"></a>
                                        <dialog #dialog>
                                            <div class="m-3 justify-content-center align-items-center">
                                                <div class="m-4 align-items-center">
                                                    <a class="delete-button dialogborrar"></a>

                                                    <h5 id="textdialog">¿Desea confirmar la eliminacion de la factura
                                                        #ID{{factura.id}}?
                                                    </h5>
                                                </div>
                                                <div class="d-flex justify-content-end m-4">
                                                    <button (click)="dialog.close()" id="btcancel"
                                                        class="btn btn-primary mx-1 mt-5"
                                                        type="submit">Cancelar</button>
                                                    <button (click)="deleteFactura(factura,$event,dialog)" id="btacept"
                                                        class="btn btn-primary mx-1 mt-5" 
                                                        [disabled]="isDelete">
                                                        <div class="spinner-border spinner-border-sm" *ngIf="isDelete">
                                                        </div>
                                                        <span class="align-middle"> Confirmar</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </dialog>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="mostrarRecibidas" class="col-12 col-lg-5 row row-name-button">
                                <div class="card border-0 card-width-name col-12 col-lg-10">
                                    {{factura.nombre}}
                                </div>
                                <div class="col-12 col-lg-2 button-center-sm">
                                    <div class="buttons-flex-container align-items-center card-width-actions">
                                        <a (click)="openPDF(factura,getDateToFilter(factura.fechaFactura), getDateToFilter(factura.fechaVencimiento))"
                                            class="eye-button activated"></a>
                                        <a (click)="downloadInvoice(factura)" class="download-button activated"></a>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>

            </ul>
            <div *ngIf="facturas == null" class="d-flex flex-row justify-content-center empty-data-message my-4">
                Este usuario no dispone de facturas {{ mostrarRecibidas ? 'recibidas' : 'emitidas'}}
            </div>
        </div>
        <mat-paginator *ngIf="mostrarPag" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements"
            (page)="nextPage($event)"> </mat-paginator>
    </div>
</div>
<div [hidden]="!mostrarFormulario">
    <app-formulario-factura (newItemEvent)="setMostrarFormularioChild()"></app-formulario-factura>
</div>
<div [hidden]="!editarFormulario">
    <app-formulario-factura-editar [FacturaRec]=facturaEnv
        (newItemEvent)="setEditarFormularioChild()"></app-formulario-factura-editar>
</div>