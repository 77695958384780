<nav id="sidebar">
    <div class="fixed">
        <app-formulario-nuevo-gasto></app-formulario-nuevo-gasto>
    </div>
</nav>

<nav id="sidebar_ingreso">
    <div class="fixed">
        <app-formulario-nuevo-ingreso></app-formulario-nuevo-ingreso>
    </div>
</nav>
<nav id="sidebar_detalle">
    <div class="fixed">
        <app-visualizar-gastos-ingresos [detallesP]="gasto_ingreso" [detalles]="detalles"
            [primeraVez]="primeraVez"></app-visualizar-gastos-ingresos>
    </div>
</nav>

<nav id="sidebar_modificar">
    <div class="fixed">
        <app-formulario-editar-gasto-ingreso [primeraVezM]="primeraVezM" [detallesM]="gasto_ingreso2"
            [detalles2]="detalles2"></app-formulario-editar-gasto-ingreso>
    </div>
</nav>


<div (click)="closeSidebar($event)" class="full-form-group">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <h1 class="m-0">Gestión de Gastos</h1>
        <div>
            <button *ngIf="gastos_ingresos?.length != 0" class="btn btn-primary align-middle nuevo-ingreso mb-1"
                (click)="downloadExcel()">
                <mat-icon class="align-middle mr-2">download</mat-icon><span class="align-middle">Exportar</span>
            </button>
            <button class="btn btn-primary align-middle nuevo-ingreso mb-1" (click)="openSidebar($event)">
                <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo Gasto</span>
            </button>
            <button class="btn btn-primary align-middle nuevo-ingreso mb-1" (click)="openSidebarIngreso($event)">
                <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo Ingreso</span>
            </button>
        </div>
    </div>
    <div>

        <div class="subtitle">
            <h5>Listado de todos los gastos</h5>
        </div>
        <div class="w-100 border-bottom filter-bar tableHead">
            <div class="w-100 mt-4 border-bottom">
                <div class="ordering-table">
                    <div class="card-width-name filter-container col-12 col-md-10 col-xl-3 order-xl-1">
                        <input type="text" class="searchinput" placeholder="NOMBRE"
                            (keyup)="doFilter($event, 'usuario.nombre', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('usuario.nombre', 'nombre')"><a
                                [ngClass]="sortArray['nombre']==='ASC' ? 'asc-sort': sortArray['nombre']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-cliente filter-container col-12 col-md-2 col-xl-1 order-xl-2">
                        <input type="text" class="searchinput" placeholder="ID_CLIENTE"
                            (keyup)="doFilter($event, 'usuario.id', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('usuario.id', 'id')"><a
                                [ngClass]="sortArray['id']==='ASC' ? 'asc-sort': sortArray['id']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-tipo_transaccion filter-container col-12 col-md-4 col-xl-1 order-xl-3">
                        <input type="text" class="searchinput" placeholder="TIPO"
                            (keyup)="doFilter($event, 'tipoTransaccion.nombre', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('tipoTransaccion.nombre', 'tipoGasto')"><a
                                [ngClass]="sortArray['tipoGasto']==='ASC' ? 'asc-sort': sortArray['tipoGasto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-fecha_creacion filter-container col-12 col-xl-2 order-5 order-xl-4">
                        <input type="text" class="searchinput" onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}" placeholder="FECHA" (change)="
                            doFilter($event, 'fechaCreacion', 'CURRENT_DATE', 'DATE')
                            " />
                        <button (click)="changeSorts('fechaCreacion', 'fechaCreacion')"><a
                                [ngClass]="sortArray['fechaCreacion']==='ASC' ? 'asc-sort': sortArray['fechaCreacion']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-factura filter-container col-12 col-xl-2 order-6 order-xl-5">
                        <input type="text" class="searchinput" placeholder="ESTADO"
                            (keyup)="doFilter($event, 'estado.nombre', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('estado.nombre', 'estado')"><a
                                [ngClass]="sortArray['estado']==='ASC' ? 'asc-sort': sortArray['estado']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-importe filter-container col-md-12 col-xl-1 order-4 order-xl-6">
                        <input type="text" class="searchinput" placeholder="IMPORTE"
                            (keyup)="doFilter($event, 'importe', 'LIKE', 'DOUBLE')" />
                        <button (click)="changeSorts('importe', 'importe')"><a
                                [ngClass]="sortArray['importe']==='ASC' ? 'asc-sort': sortArray['importe']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-with-actions col-xl-2 order-7">
                        <div class="buttons">
                            ACCIONES
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card w-100 card-generic border-0">
            <div *ngIf="gastos_ingresos?.length == 0"
                class="d-flex flex-row justify-content-center empty-data-message my-4">

                <span>No se han encontrado registros que mostrar</span>

            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item p-3 d-flex border rounded shadow"
                    *ngFor="let gasto_ingreso of gastos_ingresos">
                    <div class="row w-100 row-with-cards">
                        <div class="card border-0 card-width-name col-12 col-md-10 col-xl-3 order-xl-1">
                            <p class="cut-text" [title]="gasto_ingreso.usuario.nombre?gasto_ingreso.usuario.nombre+' '+gasto_ingreso.usuario.apellido1+' '
                                +gasto_ingreso.usuario.apellido2:''">
                            
                            {{gasto_ingreso.usuario.nombre}} {{gasto_ingreso.usuario.apellido1}}
                            {{gasto_ingreso.usuario.apellido2}}
                        </p>

                        </div>
                        <div *ngIf="userAdmin"
                            class="card border-0 card-width-cliente colorOption col-12 col-md-2 col-xl-1 order-xl-2"
                            [routerLink]="['/admin-hencok/gastos-ingresos', gasto_ingreso.usuario.id]">
                            #{{gasto_ingreso.usuario.id}}
                        </div>
                        <div
                            class="card border-0 card-width-tipo_transaccion let-tipo col-12 col-md-4 col-xl-1 order-xl-3">
                            <p class="cut-text"
                                [title]="gasto_ingreso.tipo_transaccion.nombre?gasto_ingreso.tipo_transaccion.nombre.toLowerCase():''">
                                {{gasto_ingreso.tipo_transaccion.nombre.toLowerCase()}}
                            </p>
                        </div>
                        <div class="card border-0 card-width-fecha_creacion col-12 col-xl-2 order-5 order-xl-4">
                            <p class="cut-text"
                                [title]="formatDate(gasto_ingreso.fechaCreacion)?formatDate(gasto_ingreso.fechaCreacion):''">
                                {{formatDate(gasto_ingreso.fechaCreacion)}}
                            </p>
                        </div>

                        <div class="card border-0 card-width-factura col-12 col-xl-2 order-6 order-xl-5">
                            <select class="form-control tipo status"
                                (change)="changeEstado($any($event.target).value,gasto_ingreso)" [ngClass]="gasto_ingreso.estado.nombre === 'PENDIENTE' ? 'colorPendiente':
                               gasto_ingreso.estado.nombre === 'COMPLETADO' ? 'colorCompletado' :
                               'colorrechazado' "
                                [ngStyle]="{'width.px': calcTextWidth(gasto_ingreso.estado.nombre, 'normal normal 500 14px sans-serif')}">

                                <option *ngIf="estados_gasto[0].nombre== gasto_ingreso.estado.nombre " selected>
                                    {{estados_gasto[0].nombre}}
                                </option>
                                <option *ngIf="estados_gasto[0].nombre!= gasto_ingreso.estado.nombre ">
                                    {{estados_gasto[0].nombre}}
                                </option>
                                <option *ngIf="estados_gasto[1].nombre== gasto_ingreso.estado.nombre " selected>
                                    {{estados_gasto[1].nombre}}
                                </option>
                                <option *ngIf="estados_gasto[1].nombre!= gasto_ingreso.estado.nombre ">
                                    {{estados_gasto[1].nombre}}
                                </option>
                                <option *ngIf="estados_gasto[2].nombre== gasto_ingreso.estado.nombre " selected>
                                    {{estados_gasto[2].nombre}}
                                </option>
                                <option *ngIf="estados_gasto[2].nombre!= gasto_ingreso.estado.nombre ">
                                    {{estados_gasto[2].nombre}}
                                </option>
                            </select>
                        </div>
                        <div class="card border-0 card-width-importe col-md-8 col-xl-1 order-4 order-xl-6">
                            {{gasto_ingreso.importe.toLocaleString()}}€
                        </div>

                        <div class="button-center col-xl-2 order-7">
                            <div class="buttons-flex-container align-items-center card-width-actions">

                                <a (click)="openSidebarDetalles(gasto_ingreso,$event)" class="eye-button activated"
                                    title="Ver"></a>
                                <a (click)="gasto_ingreso.factura==undefined && gasto_ingreso.estado.nombre!='COMPLETADO' ? openSidebarModificar(gasto_ingreso,$event): showEditDeleteError('editar', gasto_ingreso.estado.nombre)"
                                    class="delete-button activated" class="edit-button activated pencil"
                                    title="Editar"></a>
                                <a (click)="downloadFile(gasto_ingreso.documento, gasto_ingreso.id)"
                                    class="download-button activated" title="Descargar"></a>
                                <a (click)="gasto_ingreso.factura==undefined && gasto_ingreso.estado.nombre!='COMPLETADO' ? dialog.showModal(): showEditDeleteError('borrar' , gasto_ingreso.estado.nombre)"
                                    class="delete-button activated" title="Eliminar"></a>


                                <dialog #dialog>
                                    <div class="m-3 justify-content-center align-items-center">
                                        <a class="delete-button dialogborrar"></a>
                                        <div class="m-4">
                                            <h5 id="textdialog">¿Desea confirmar la eliminacion del Gasto/Ingreso
                                                #ID{{gasto_ingreso.id}}?
                                            </h5>
                                        </div>
                                        <div class="d-flex justify-content-end m-4">
                                            <button (click)="dialog.close()" id="btcancel"
                                                class="btn btn-primary mx-1 mt-5" type="submit">Cancelar</button>
                                            <button (click)="deleteGastoIngreso(gasto_ingreso,$event)" id="btacept"
                                                class="btn btn-primary mx-1 mt-5" type="submit" [disabled]="isDelete">
                                                <div class="spinner-border spinner-border-sm" *ngIf="isDelete"></div>
                                                <span class="align-middle"> Confirmar</span>
                                            </button>
                                        </div>
                                    </div>
                                </dialog>

                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <mat-paginator *ngIf="mostrarPag" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" translate="yes"
            [length]="totalElements" (page)="nextPage($event)" toLocaleString="de">
        </mat-paginator>
    </div>
</div>