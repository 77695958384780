<div class="pageTitle">
    <h1 class="m-0">Nueva Factura</h1>
    <div>
        <button class="btn btn-outline-primary align-middle" (click)="addNewItem()">
            <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
        </button>
    </div>
</div>
<div>
    <hr class="linea-divisoria-title">
</div>
<div class="full-form-group">
    <div>
        <td><select class="form-control tipo" id="selectUser" #user
                (change)="userAdmin ? getUser(user.value) : getCliente(user.value)" class="colorSelect">
                <option style="display:none">Seleccionar id</option>
                <option *ngFor="let usuario of usuarios" [value]="usuario.id" class="colorOption">
                    {{ usuario.nombre }}
                    <!-- #ID{{ usuario.id }} -->
                </option>
            </select></td>
    </div>
    <div class="col-sm-12 d-flex pt-4 mb-5 row">
        <div class="w-50">
            <h4>Para</h4>
            <p class="estiloParaDe">{{usuario?.nombre}} {{usuario?.apellido1}} {{usuario?.apellido2}}</p>
            <p class="estiloParaDe" *ngIf="usuario.direcciones == undefined"></p>
            <p class="estiloParaDe" *ngIf="usuario.domicilio != undefined">{{usuario.domicilio}}</p>
            <p class="estiloParaDe" *ngIf="usuario.direcciones != undefined"><b>Direccion:  </b>{{usuario?.direcciones[0]?.name_road}}
                {{usuario?.direcciones[0]?.number_road}} {{usuario?.direcciones[0]?.postal_code}}
                {{usuario?.direcciones[0]?.province.nombre}}</p>
            <p *ngIf="usuario.telefono != undefined" class="estiloParaDe"><b>Núm. de telefono:  </b>{{usuario?.telefono}}</p>
            <p  *ngIf="usuario.numero_documento != undefined" class="estiloParaDe"><b>Núm. de documento:  </b>{{usuario?.numero_documento ? usuario?.numero_documento : usuario.documento}}</p>
            <p  *ngIf="usuario.email != undefined" class="estiloParaDe"><b>Correo electrónico:  </b>{{usuario?.email}}</p>
        </div>
        <div class="col-sm-6 w-50">
            <h4>De</h4>
            <p class="estiloParaDe">{{userLogged?.nombre}} {{userLogged?.apellido1}} {{userLogged?.apellido2}}</p>
       <!--      <p>{{userLogged?.direcciones[0]?.name_road}} {{userLogged?.direcciones[0]?.number_road}}
                {{userLogged?.direcciones[0]?.postal_code}} {{userLogged?.direcciones[0]?.province.nombre}}</p>
            <p>{{userLogged?.telefono}}</p> -->
            <p class="estiloParaDe"><b>Núm. de documento:  </b>{{userLogged?.numero_documento}}</p>
            <p class="estiloParaDe"><b>Correo electrónico:  </b> {{userLogged?.email}}</p>
        </div>
    </div>
    <form [formGroup]="formFactura" (ngSubmit)="onSubmitFacturas()">
        <div class="row">
            <div class="w-100 pt-1">
                <div class="d-flex w-100">
                    <label class="crear-editar-form col-sm-4">Tipo de gasto</label>
                    <label class="crear-editar-form col-sm-4">Fecha de factura</label>
                    <label class="crear-editar-form col-sm-4">Fecha de vencimiento</label>
                </div>
            </div>
            <div class="w-100 d-flex">
                <div class="col-sm-4">
                    <select class="form-control" #gasto (change)="getGasto(gasto.value)" formControlName="tipoGasto"
                        [ngClass]="{'is-invalid': submitFacturas && formFactura.controls['tipoGasto'].errors}">
                        <option style="display:none">Seleccionar tipo de gasto</option>
                        <option *ngFor="let gasto of tipoGastos" [value]="gasto.id">
                            {{ gasto.nombre }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <input type="date" class="form-control" formControlName="fecha_factura"
                    required pattern="\d{4}-\d{2}-\d{2}"
                        [ngClass]="{'is-invalid': submitFacturas && formFactura.controls['fecha_factura'].errors}">
                </div>
                <div class="col-sm-4">
                    <input type="date" class="form-control" formControlName="fecha_vencimiento"
                    required pattern="\d{4}-\d{2}-\d{2}"
                        [ngClass]="{'is-invalid': submitFacturas && formFactura.controls['fecha_vencimiento'].errors}">
                </div>
            </div>
        </div>
    
        <div formArrayName="detallesFactura" class="arrayFacturas row">
            <div class="w-100">
                <div class="d-flex w-100">
                    <label class="crear-editar-form rowForm col-sm-3">Concepto</label>
                    <label class="crear-editar-form rowForm col-sm-1">Cantidad</label>
                    <label class="crear-editar-form rowForm col-sm-2">Precio</label>
                    <label class="crear-editar-form rowForm col-sm-2">Descuento</label>
                    <label class="crear-editar-form rowForm col-sm-2">Cantidad neta</label>
                    <label class="crear-editar-form rowForm col-sm-2">Tipo de IVA/VAT</label>
                </div>
            </div>
            <div *ngFor="let factura of detallesFactura?.controls; let i = index" [formGroupName]="i"
                class="w-100 d-flex pb-3">
                <div class="col-sm-3"><input type="text" formControlName="concepto" placeholder="Concepto"
                        class="form-control"
                        [ngClass]="{'is-invalid': submitFacturas && detallesFactura.controls[i].get('concepto').errors}">
                </div>
                <div class="col-sm-1">
                    <select formControlName="cantidad" (ngModelChange)="calcularCantidadNeta(i)" class="form-control"
                        [ngClass]="{'is-invalid': submitFacturas && detallesFactura.controls[i].get('cantidad').errors}">
                        <option *ngFor="let cantidad of cantidades; let i = index" [value]="cantidad">
                            {{ cantidad }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-2"><input type="text" mask="0*.00" (ngModelChange)="calcularCantidadNeta(i)"
                        formControlName="precio" placeholder="0.00" class="form-control"
                        [ngClass]="{'is-invalid': submitFacturas && detallesFactura.controls[i].get('precio').errors}">
                </div>
                <div class="col-sm-2"><input type="text" mask="0*.00" (ngModelChange)="calcularCantidadNeta(i)"
                        formControlName="descuento" placeholder="0.00" class="form-control"
                        [ngClass]="{'is-invalid': submitFacturas && detallesFactura.controls[i].get('descuento').errors}">
                </div>
                <div class="col-sm-2"><input type="text" formControlName="cantidadNeta"
                        [(ngModel)]="this.formFactura.controls['detallesFactura'].value[i].cantidadNeta"
                        class="form-control" readonly></div>
                <div class="col-sm-2"><input type="text"  
                        mask="0*"  (ngModelChange)="calcularCantidadNeta(i)"
                        formControlName="tipoIva" placeholder="0" class="form-control icon-percent tipoIva"
                        [ngClass]="{'is-invalid': submitFacturas && detallesFactura.controls[i].get('tipoIva').errors}">
                </div>
            </div>
        </div>
        <div class="col-sm-12 pt-1 row">
            <div class="form-group">
                <div class="d-grid gap-2 d-md-flex">
                    <button type="button" (click)="addFactura()" class="btn btn-primary marginButton mr-2">
                        <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Añadir fila</span>
                    </button>
                    <div *ngIf="detallesFactura.length != 1">
                        <button type="button" (click)="deleteFactura()" class="btn btn-outline-primary marginButton">
                            <mat-icon class="align-middle mr-2">delete</mat-icon><span class="align-middle">Eliminar
                                fila</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 pt-2">
                <div class="form-group">
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <div class="col-sm-2">
                            <p>Subtotal</p>
                            <p>IVA</p>
                            <p><b>Cantidad total</b></p>
                        </div>
                        <div class="col-sm-1 pl-0 pr-0">
                            <p class="final-numbers">€ {{subtotal}}</p>
                            <p class="final-numbers">€ {{iva}}</p>
                            <p class="final-numbers"><b>€ {{cantidad_total}}</b></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row w-100 mb-4 d-flex justify-content-end ml-0 mr-0 pr-3">
                <button type="button" class="btn btn-outline-primary mt-3  preview col-sm-2  ml-2 mr-2 buttons-end"
                    (click)="generateFactura()">
                    <mat-icon class="align-middle mr-2">remove_red_eye</mat-icon><label
                        class="align-middle">Vista previa</label>
                </button>
                <button  type="button" class="btn btn-primary mt-3 accept col-sm-2 buttons-end mr-0"
                    [disabled]="uploadDocuments" (click)="onSubmitFacturas()">
                    <mat-icon *ngIf="!uploadDocuments" class="align-middle mr-2">check</mat-icon>
                    <div class="spinner-border spinner-border-sm" *ngIf="uploadDocuments"></div>
                    <label class="align-middle"> Aceptar</label>
                </button>
            </div>
        </div>
    </form>
</div>
