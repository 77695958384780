<nav id="sidebar">
    <div class="fixed">
        <app-formulario-adjuntar-contrato></app-formulario-adjuntar-contrato>
    </div>
</nav>


<div (click)="closeSidebar($event)">
    <div class="d-flex flex-row justify-content-between align-items-center full-form-group">
        <h1 class="m-0">Mercantil</h1>
        <div>
            <button class="btn btn-primary align-middle" (click)="openSidebar($event)">
                <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Adjuntar contrato</span>
            </button>
        </div>
    </div>

    <div class="full-form-group">

        <div class="subtitle">
            <h5>Listado de todos los contratos</h5>
        </div>
        <div class="w-100 border-bottom filter-bar tableHead">
            <div class="w-100 filteringTable">
                <div class="w-100 row ordering-table">
                    <div class="card-width-name col-2">
                        <input
                        type="text"
                        class="searchinput"
                        placeholder="CLIENTE"
                        (keyup)="doFilter($event, 'autonomo.nombre', 'LIKE', 'STRING')"
                      />
                    </div>
                    <div class="card-width-id col-1">
                        <input
                        type="text"
                        class="searchinput"
                        placeholder="ID"
                        (keyup)="doFilter($event, 'autonomo.id', 'LIKE', 'STRING')"
                      />
                    </div> 
                    <div class="card-width-modelo col-2">
                        <input
                        type="text"
                        class="searchinput"
                        placeholder="CONCEPTO"
                        (keyup)="doFilter($event, 'concepto', 'LIKE', 'STRING')"
                      />
                    </div> 
                   <div class="card-width-periodo col-2" >
                        <input
                            type="text"
                            class="searchinput"
                            onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}"
                            placeholder="FECHA EMISIÓN"
                            (change)="
                            doFilter($event, 'fechaEmision', 'CURRENT_DATE', 'DATE')
                            "
                        />
                    </div>
                    <div class="card-width-periodo col-2">
                        <input
                        type="text"
                        class="searchinput"
                        placeholder="TIPO"
                        (keyup)="doFilter($event, 'tipo.nombre', 'LIKE', 'STRING')"
                        />
                    </div> 
                    <div class="card-width-status col-2">
                        <input
                        type="text"
                        class="searchinput"
                        placeholder="ESTADO"
                        (keyup)="doFilter($event, 'id', 'LIKE', 'STRING')"
                        />
                    </div> 
                   
                    <div class="card-with-actions col-1">
                        <div class="buttons">
                            ACCIONES
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card w-100 card-generic border-0">
            <ul class="list-group list-group-flush">
                <li class="list-group-item p-3 d-flex border rounded shadow"
                    *ngFor="let contrato of listadoContratos | filterImpuesto : searchText">
                    
                    <div class="row w-100 row-with-cards ">
                        <div class="card border-0 card-width-name col-lg-5 col-xl-2">
                            {{contrato.autonomo.nombre}} {{contrato.autonomo.apellido1}} {{contrato.autonomo.apellido2}}
                        </div>
                        <div class="card border-0 card-width-id colorOption col-lg-2 col-xl-1">
                            <a
                                [routerLink]="['/admin-hencok/impuestos/', contrato.autonomo.id]">#{{contrato.autonomo.id}}</a>
                        </div>
                        <div class="d-flex flex-row card border-0 card-width-modelo col-lg-5 col-xl-2">
                            {{contrato.concepto}}
                        </div>
                        <div class="card border-0 card-width-periodo col-lg-3 col-xl-2">
                            {{contrato.fechaEmision | replace: '-' : '/'}} 
                        </div>
                        <div class="card border-0 card-width-tipo col-lg-4 col-xl-2">
                           {{ contrato.tipo.nombre}}
                        </div>
                        <div [ngClass]="{'pending-state': contrato.estado.nombre.toLowerCase() == 'pendiente',
                        'approved-state': contrato.estado.nombre.toLowerCase() == 'firmado'}"
                            class="d-flex flex-row align-items-center card-width-status col-lg-5 col-xl-2 status">
                            {{contrato.estado.nombre}}
                        </div>
                        
                        <div *ngIf="contrato.idDocumento == undefined" class="button-center col-xl-1"> 
                            <div class="buttons-flex-container align-items-center card-width-actions">
                                No firmado
                            </div>
                        </div>
                        <div *ngIf="contrato.idDocumento != undefined" class="button-center col-xl-1"> 
                            <div class="buttons-flex-container align-items-center card-width-actions">
                                <a class="eye-button activated" title="Ver/editar"
                                    (click)="openPdf(contrato)"></a> 
                                <a class="download-button activated" title="Descargar" (click)="downloadPdf(contrato)"></a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div *ngIf="listadoContratos?.length == 0"
            class="d-flex flex-row justify-content-center empty-data-message my-4">
            <span>No se han encontrado registros que mostrar</span>
        </div>
        <div *ngIf="primeraVez" class="d-flex flex-row justify-content-center empty-data-message my-4">
            <span>Cargando....</span>
        </div>
        <mat-paginator *ngIf="mostrarPag" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements"
            (page)="nextPage($event)"> </mat-paginator>

    </div>
</div>