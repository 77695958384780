import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "../_services/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class EnvironmentGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenservice: TokenStorageService
  ) { }

  adminRoute = "admin-hencok";
  clientRoute = "client-hencok";
  autoAdminRoute = "admin-autoescuelas-hencok";
  autoClientRoute = "client-autoescuelas-hencok";
  modClientRoute = "mod-autoescuelas-hencok";
  canActivate(route: ActivatedRouteSnapshot) {
    let canNavigate = false;
    let rol = this.tokenservice.getRol();
    if (
      this.router.url.includes(this.adminRoute) ||
      this.router.url.includes(this.autoAdminRoute) ||
      this.router.url.includes(this.autoClientRoute) ||
      this.router.url.includes(this.clientRoute)
    ) {
      switch (rol) {
        case 99:
          canNavigate = true;
          break;
        case 4:
          if (this.router.url.includes(this.autoClientRoute)) {
            canNavigate = true;
          } else {
            //  this.router.navigate(["autoescuelas-hencok/clientes"]);
            canNavigate = false;
          }
          break
        case 3:
          if (this.router.url.includes(this.autoAdminRoute)) {
            canNavigate = true;
          } else {
            //  this.router.navigate(["autoescuelas-hencok/clientes"]);
            canNavigate = false;
          }
          break;
        case 2:
          if (this.router.url.includes(this.adminRoute)) {
            canNavigate = true;
          } else {
            //this.router.navigate(["admin-hencok/clientes"]);
            canNavigate = false;
          }
          break;
        case 1:
          if (this.router.url.includes(this.clientRoute)) {
            canNavigate = true;
          } else {
            // this.router.navigate(["client-hencok/clientes"]);
            canNavigate = false;
          }
          break;
        case 5:
          if (this.router.url.includes(this.modClientRoute)) {
            canNavigate = true;
          } else {
            //  this.router.navigate(["autoescuelas-hencok/clientes"]);
            canNavigate = false;
          }
          break;
        default:
          canNavigate = false;
          break;
      }

    } else {
      canNavigate = true;
    }
    console.log(canNavigate)
    return canNavigate;
  }
}
