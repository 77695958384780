import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../token-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SolicitudPermisoInternacionalService {
  protected resourceUrlCan = `${environment.API_URL}/tramite/solicitudPermisoInternacional`;
  protected API_URL = environment.API_URL

  constructor(
    protected http: HttpClient,
    protected token:TokenStorageService
  ) { }


  saveSoli(form: any) {
    console.log(form)
    return this.http.post(`${this.resourceUrlCan}/save`, form);
  }
  updateSoli(form: any,id) {
    return this.http.post(`${this.resourceUrlCan}/update/${id}`, form);
  }
  getSoliId(id: number) {
    return this.http.get(`${this.resourceUrlCan}/getById/${id}/${this.token.getId()}`);
  }
  signMandato(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrlCan}/signMandato`, {id});
  }

  downloadB64(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrlCan}/getPdfMandato`, { id });
  }
  saveDocumentoModelo(id: number, documento: any): Observable<any> {
    return this.http.post(`${this.resourceUrlCan}/savePdfMandato`, { id, documento });
  }
}
