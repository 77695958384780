import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { Notification, NotificationType } from '../model/notification';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  
  private _subject = new Subject<Notification>();
  private _idx = 0;

  constructor(    private http: HttpClient
    ) { }
  protected resourceUrl = `${environment.API_URL}/notificaciones`;

  getObservable(): Observable<Notification> {
    return this._subject.asObservable();
  }

  info(title: string, message: string, timeout = 30000) {
    this._subject.next(new Notification(this._idx++, NotificationType.info, title, message, timeout));
  }

  success(title: string, message: string, timeout = 30000) {
    this._subject.next(new Notification(this._idx++, NotificationType.success, title, message, timeout));
  }
  successNovo(title: string, message: string, timeout = 5000) {
    this._subject.next(new Notification(this._idx++, NotificationType.success, title, message, timeout));
  }
  warning(title: string, message: string, timeout = 30000) {
    this._subject.next(new Notification(this._idx++, NotificationType.warning, title, message, timeout));
  }

  error(title: string, message: string, timeout = 30000) {
    this._subject.next(new Notification(this._idx++, NotificationType.error, title, message, timeout));
  }



 
   
  }


