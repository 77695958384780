export class ClientePresupuesto{
    id?="";
    tipoVia={};
    nombreVia="";
    numeroVia?:number
    bloque?="";
    escalera?="";
    planta?:number;
    puerta?="";
    km?:number;
    comunidadAutonoma={};
    provincia={};
    localidad:number;
    codigoPostal:number;

    nombre?="";
    apellido1?="";
    apellido2?="";
    email?="";
    dni?="";
    fechaNacimiendo?:Date;
    validezDni?:Date;
    telefono?="";
    nombreEmpresa?="";
    cif?="";

   
    public constructor(data?, init?: Partial<ClientePresupuesto>) {
        if (data){
            this.id=data.id;
            this.tipoVia=data.tipoVia;
            this.nombreVia=data.nombreVia;
            this.numeroVia=data.numeroVia
            this.bloque=data.bloque;
            this.escalera=data.escalera;
            this.planta=data.planta;
            this.puerta=data.puerta;
            this.km=data.km;
            this.comunidadAutonoma=data.comunidadAutonoma;
            this.provincia=data.provincia;
            this.localidad=data.localidad;
            this.codigoPostal=data.codigoPostal;
        
            this.nombre=data.nombre;
            this.apellido1=data.apellido1;
            this.apellido2=data.apellido2;
            this.email=data.email;
            this.dni=data.dni;
            this.fechaNacimiendo=data.fechaNacimiendo;
            this.validezDni=data.validezDni;
            this.telefono=data.telefono;
            this.nombreEmpresa=data.nombreEmpresa;
            this.cif=data.cif;
        }else{
            Object.assign(this, init);
        }
        

    }
}