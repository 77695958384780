<div class="page__content">
    <div class="content__inner">
        <div *ngIf="currentUser">
            <!--p><strong>Usuario:</strong> {{ currentUser.nombre }}</p>

            <p><strong>Email:</strong> {{ currentUser.email }}</p-->
            <div class="row">
                <div class="col-sm-6 d-flex justify-content-around">
                    <div class="iconName rounded-circle" style="width: 100px; height: 100px;">
                        <p>{{getFirstLetter(this.currentUser.nombre)}}{{getFirstLetter(this.currentUser.apellido1)}}</p>
                    </div>
                    <div>
                        <b>{{this.currentUser.nombre}} {{this.currentUser.apellido1}}</b>
                        <h6>{{this.currentUser.email}}</h6>
                    </div>
                </div>
                <div class="col-sm-6" *ngIf="currentUser.email">
                    <div *ngIf="!tfActivated && !isShown && !loading">
                        <p>¿Quieres activar el Doble Factor de Autenticación?</p>
                        <div class="form-group">
                            <form [formGroup]="form2" (ngSubmit)="pedirQR()" class="centerButton">
                                <button type="submit" class="btn btn-primary">
                                    Activar
                                </button>
                            </form>
                        </div>
                    </div>


                    <div *ngIf="loading">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div *ngIf="!tfActivated && isShown && !loading" class="center">
                        <p>
                            Escanee el código QR con la aplicación Authenticator en su móvil
                        </p>
                        <div>
                            <div class="p-2">
                                <img [src]=qr.secretURL class="w-25" />
                            </div>
                            <form [formGroup]="form3" (ngSubmit)="guardarQR()" *ngIf="!loading">
                                <input autocomplete="off" type="text" name="totpkey" (ngModel)="form3" class="mr-2">
                                <button type="submit" class="btn btn-primary m-left">
                                    Activar
                                </button>
                            </form>
                        </div>
                        <div *ngIf="errorQR" class="danger alert-danger w-50 alert2fa">
                            <p>
                                Error al activar doble factor.
                                Por favor vuelva a escaner el código QR e introduzca los 6 dígitos.
                            </p>
                        </div>
                    </div>
                    <div *ngIf="tfActivated" class="center">
                        <form [formGroup]="form3" (ngSubmit)="quitarQR()">
                            <p>Two factor activado</p>
                            <button type="submit" class="btn btn-primary m-left">Desactivar</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row">
                <form *ngIf="!isSuccessful" name="form" (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>
                    <div class="row pt-4 p-2">
                        <div class="col-sm-12">
                            <h6>Datos personales</h6>
                        </div>
                        <div class="border row p-3">
                            <div class="col-sm-4">
                                <label class="requerido">Nombre</label>
                                <input autocomplete="off" type="text" class="form-control" name="nombre" formControlName="nombre"
                                    minlength="3" maxlength="20"
                                    [attr.disabled]="'true'"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['nombre'].errors}"/>
                            </div>

                            <div class="col-sm-4">
                                <label class="requerido">Primer apellido</label>
                                <input autocomplete="off" type="text" class="form-control" name="apellido1" formControlName="apellido1"
                                    minlength="3" maxlength="20"
                                    [attr.disabled]="'true'"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['apellido1'].errors}" />
                            </div>

                            <div class="col-sm-4">
                                <label class="requerido">Segundo apellido</label>
                                <input autocomplete="off" type="text" class="form-control" name="apellido2" formControlName="apellido2"
                                    minlength="3" maxlength="20"
                                    [attr.disabled]="'true'"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['apellido2'].errors}" />
                            </div>
                            <div class="col-sm-4 mt-3">
                                <label class="requerido">Tipo de persona</label>
                                <select class="form-control" formControlName="tipo_persona"
                                    [attr.disabled]="'true'"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['tipo_persona'].errors}">
                                    <option *ngFor="let tipopersona of TPersonas" [value]="tipopersona.id">
                                        {{ tipopersona.nombre }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-4 mt-3">
                                <label class="requerido">Tipo de documento</label>
                                <select class="form-control" formControlName="tipo_documento"
                                    [attr.disabled]="'true'"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['tipo_documento'].errors}">
                                    <option *ngFor="let tipodocumento of TDocumentos" [value]="tipodocumento.id">
                                        {{ tipodocumento.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4 mt-3 mb-3">
                                <label class="requerido">Nº de documento</label>
                                <input autocomplete="off" name="numero_documento" class="w-100 text-align-center form-control"
                                    formControlName="numero_documento"
                                    [attr.disabled]="'true'"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['numero_documento'].errors}" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 mb-3 p-2">
                        <div class="col-sm-12">
                            <h6>Datos de contacto</h6>
                        </div>
                        <div class="border row  p-3">
                            <div class="col-sm-3">
                                <label class="requerido">Tipo de vía</label>
                                <select formControlName="tipo_via" class="form-control"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['tipo_via'].errors}">
                                    <option *ngFor="let vias of TVias" [value]="vias.id">
                                        {{ vias.tipovianormalizado }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-6">
                                <label class="requerido">Dirección</label>
                                <input autocomplete="off" type="text" formControlName="nombre_via" class="form-control"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['nombre_via'].errors}" />
                            </div>


                            <div class="col-sm-3">
                                <label class="requerido">Nº de la vía</label>
                                <input autocomplete="off" mask="9{5}" type="text" formControlName="numero_via" class="form-control"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['numero_via'].errors}" />
                            </div>

                            <div class="col-sm-3 mt-3">
                                <label>Bloque:</label>
                                <input autocomplete="off" type="text" formControlName="bloque" class="form-control" />
                            </div>

                            <div class="col-sm-3 mt-3">
                                <label>Escalera:</label>
                                <input autocomplete="off" type="text" formControlName="escalera" class="form-control" />
                            </div>

                            <div class="col-sm-3 mt-3">
                                <label>Piso:</label>
                                <input autocomplete="off" type="text" formControlName="piso" class="form-control" />
                            </div>

                            <div class="col-sm-3 mt-3">
                                <label>Puerta:</label>
                                <input autocomplete="off" type="text" formControlName="puerta" class="form-control" />
                            </div>

                            <div class="col-sm-4 mt-3">
                                <label class="requerido">País</label>
                                <select formControlName="pais" class="form-control"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['pais'].errors}">
                                    <option *ngFor="let _pais of TPais" [value]="_pais.id">
                                        {{ _pais.nombre }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-4 mt-3">
                                <label class="requerido">Comunidad</label>
                                <select formControlName="ccaa" class="form-control" (change)="onChangeProvincia($event)"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['ccaa'].errors}">
                                    <option *ngFor="let ccaa of TComunidades" [value]="ccaa.id">
                                        {{ ccaa.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4 mt-3">
                                <label class="requerido">Provincia</label>
                                <select formControlName="provincia" class="form-control"
                                    (change)="onChangeMunicipio($event)"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['provincia'].errors}">
                                    <option *ngFor="let provincia of TProvincias" [value]="provincia.id">
                                        {{ provincia.nombre }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-4 mt-3 mb-3">
                                <label class="requerido">Municipio</label>
                                <select formControlName="municipio" class="form-control"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['municipio'].errors}">
                                    <option *ngFor="let municipio of TMunicipios"
                                        [value]="municipio.municipioPK['municipio_id']">
                                        {{ municipio.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4 mt-3">
                                <label class="requerido">Código postal</label>
                                <input autocomplete="off" type="text" mask="9{5}" formControlName="codigo_postal" class="form-control"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['codigo_postal'].errors}" />
                            </div>
                            <div class="col-sm-4 mt-3">
                                <label class="requerido">Teléfono</label>
                                <input autocomplete="off" type="text" class="form-control" name="telefono" formControlName="telefono"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['telefono'].errors}" />
                            </div>

                            <div class="w-100 p-3" style="background-color:#dae7f1; margin-top:2rem !important" *ngIf="this.currentUser.email==null">
                                <div class="row">
                                    <div class="col-sm-4 mt-1">
                                        <label class="requerido">Email</label>
                                        <input autocomplete="off" type="email" name="email" class="form-control" formControlName="email"
                                            [ngClass]="{'is-invalid': submitted && this.form.controls['email'].errors}" />
                                    </div>
            
                                    <div class="col-sm-4 mt-1">
                                        <label class="requerido">Contraseña</label>
                                        <input autocomplete="off" type="password" class="form-control" name="password" formControlName="password"
                                            minlength="6"
                                            [ngClass]="{'is-invalid': submitted && this.form.controls['password'].errors}" />
                                    </div>
                                    <div class="col-sm-4 mt-1">
                                        <label class="requerido">Confirmar contraseña</label>
                                        <input autocomplete="off" type="password" class="form-control" name="password_confirmacion"
                                            formControlName="password_confirmacion" minlength="6"
                                            [ngClass]="{'is-invalid': submitted && this.form.controls['password_confirmacion'].errors}" />
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <h6 class="data_required"> Datos requeridos para realizar un trámite.</h6>
                    <div class="w-100 d-flex justify-content-end pr-3" >
                        <button type="submit" class="btn btn-primary" [attr.disabled]="update?'':null">
                            ACTUALIZAR
                        </button>
                    </div>
                </form>
            </div>
            <!--
            <div>
                <h2>Lista de usuarios</h2>
                <div>
                    <h3>Buscar usuario</h3>
                    <div class="nav-item" style="display: flex;">

                        <input autocomplete="off" type="text" [(ngModel)]="documentSearch" class="mr-2">

                        <a (click)="searchUserByDocument()" class="buscar"></a>

                    </div>

                    <div *ngIf="dataSourceSearchUser.data.length > 0">
                        <table mat-table [dataSource]="dataSourceSearchUser" matSort
                            class="table align-middle mb-0 bg-white" style="margin-bottom: unset !important;">
                            <ng-container matColumnDef="id" class="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"
                                    class="header" style="border-top: unset;">Documento </th>
                                <td mat-cell *matCellDef="let element" style="padding-top: 1%;">
                                    {{element.usuario.numero_documento}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="nombre">
                                <th class="asuntoNombre" mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by nombre" class="header" style="border-top: unset;">
                                    Nombre
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding-top: 1%;">
                                    {{element.usuario.nombre}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Acciones">
                                <th mat-header-cell *matHeaderCellDef class="header " style="border-top: unset;">Enviar solicitud</th>
                                <td mat-cell *matCellDef="let element" class="mat-column acciones"
                                    style="padding-top: 1%;">
                                    <a class="check activated" (click)="sendSolcitud(element.usuario.id)"></a>
                                </td>

                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        </table>
                    </div>
                    <div *ngIf="dataSourceRepresento.data.length > 0">
                        <h3>Represento</h3>
                        <table mat-table [dataSource]="dataSourceRepresento" matSort
                            class="table align-middle mb-0 bg-white" style="margin-bottom: unset !important;">
                            <ng-container matColumnDef="id" class="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"
                                    class="header" style="border-top: unset;">Documento </th>
                                <td mat-cell *matCellDef="let element" style="padding-top: 1%;">
                                    {{element.usuario.numero_documento}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="nombre">
                                <th class="asuntoNombre" mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by nombre" class="header" style="border-top: unset;">
                                    Nombre
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding-top: 1%;">
                                    {{element.usuario.nombre}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Acciones">
                                <th mat-header-cell *matHeaderCellDef class="header " style="border-top: unset;"></th>
                                <td mat-cell *matCellDef="let element" class="mat-column acciones"
                                    style="padding-top: 1%;">
                                    <a class="cancel activated" (click)="sendAnswer(false,element.usuario.id)"></a>
                                </td>

                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


           

                        </table>
                    </div>

                    <div *ngIf="dataSourceMeRepresenta.data.length > 0">
                        <h3>Me representa</h3>
                        <table mat-table [dataSource]="dataSourceMeRepresenta" matSort
                            class="table align-middle mb-0 bg-white" style="margin-bottom: unset !important;">
                            <ng-container matColumnDef="id" class="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"
                                    class="header" style="border-top: unset;">Documento </th>
                                <td mat-cell *matCellDef="let element" style="padding-top: 1%;">
                                    {{element.usuario.numero_documento}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="nombre">
                                <th class="asuntoNombre" mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by nombre" class="header" style="border-top: unset;">
                                    Nombre
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding-top: 1%;">
                                    {{element.usuario.nombre}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Acciones">
                                <th mat-header-cell *matHeaderCellDef class="header " style="border-top: unset;"></th>
                                <td mat-cell *matCellDef="let element" class="mat-column acciones"
                                    style="padding-top: 1%;">
                                    <a class="cancel activated" (click)="sendAnswer(false,element.usuario.id)"></a>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                    <div *ngIf="dataSourcePendienteMeRepresenta.data.length > 0">
                        <h3>Pendientes de aprobacion para representarme</h3>
                        <table mat-table [dataSource]="dataSourcePendienteMeRepresenta" matSort
                            class="table align-middle mb-0 bg-white" style="margin-bottom: unset !important;">
                            <ng-container matColumnDef="id" class="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"
                                    class="header" style="border-top: unset;">Documento </th>
                                <td mat-cell *matCellDef="let element" style="padding-top: 1%;">
                                    {{element.usuario.numero_documento}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="nombre">
                                <th class="asuntoNombre" mat-header-cell *matHeaderCellDef mat-sort-header
                                    sortActionDescription="Sort by nombre" class="header" style="border-top: unset;">
                                    Nombre
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding-top: 1%;">
                                    {{element.usuario.nombre}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Acciones">
                                <th mat-header-cell *matHeaderCellDef class="header " style="border-top: unset;"></th>
                                <td mat-cell *matCellDef="let element" class="mat-column acciones"
                                    style="padding-top: 1%;">
                                    <div class="buttons-flex-container">
                                        <a class="check activated" (click)="sendAnswer(true,element.usuario.id)"></a>
                                        <a class="cancel activated" (click)="sendAnswer(false,element.usuario.id)"></a>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            -->

                <!-- <div class="row mt-3" *ngFor="let direccion of this.userFormDirecciones; let i = index ">
                    <app-card-direcciones [direccion]="direccion" [index]="i" class="w-100" ></app-card-direcciones>
                </div>
    
                <div class="w-100 d-flex mt-2 justify-content-end">
                    <button *ngIf="!newdirection" class="btn btn-primary" (click)="nuevaDireccion();newdirection=!newdirection" [attr.disabled]="update?'':null">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" style="padding-bottom: 2px;" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                        </svg>
                            Añadir Dirección
                    </button>
                </div> -->

            </div>
            
            <ng-template #loggedOut> Logueate </ng-template>
        </div>
    </div>