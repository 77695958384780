
<div class="content__inner">
    <h1>{{getText("titulo")}}</h1>
    <div class="row main-row">
        <div class="col-sm-12">
            <p>{{getText("discliamer")}}</p>
            <h3>{{getText("sub_confidencialidad")}}</h3>
            <p>{{getText("txt_confidencialidad")}}</p>
            <h3>{{getText("sub_registro_ficheros")}}</h3>
            <p>{{getText("txt_registro_ficheros_1")}}</p>
            <p>{{getText("txt_registro_ficheros_2")}}</p>
            <h3>{{getText("sub_cesion_datos")}}</h3>
            <p>{{getText("txt_cesion_datos")}}</p>
            <h3>{{getText("sub_ejercicios_de_derecho")}}</h3>
            <p>{{getText("txt_ejercicios_de_derecho_1")}} <a href="{{emailLink}}" class="emailLink">{{email}}</a>{{getText("txt_ejercicios_de_derecho_2")}}</p>
            <h3>{{getText("sub_aceptacion")}}</h3>
            <p>{{getText("txt_aceptacion")}}</p>
        </div>
    </div>
</div>