import { Component } from '@angular/core';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent {

  constructor(
    private _sharedDataService : SharedDataService
  ){}
  
  environment = environment;
  searchText : String = "";

  searchValue(newValue){
    this._sharedDataService.changeMessage(newValue);
  }

  changeBorder(focus: Boolean){
    let buscador = document.getElementById('buscador-wrapper');
    if(buscador != null){
      if(focus)  buscador.style.borderColor = "#94959660";
      else  buscador.style.borderColor = "#94959620"
    }
  }
}
