import { Departamento, Direccione } from 'src/app/model/usuario.data.model';
import { Tipopersona } from '../../third_model';
import { TipoGastoIngreso } from './tipo-gasto-ingreso.model';
import { TipoGestion } from './tipo-gestion.model';

export class IGastosIngresos {
  id?: number | null;
  fechaAlta?: string | null;
  fechaBaja?: Date | null;
  fechaModificacion?: Date | null;
  tipoGestion?: TipoGestion | null;
  importe?: number | null;
  tipo_gasto?: TipoGastoIngreso | null;
  tipo_ingreso?: TipoGastoIngreso | null;
  numDocumento?: string | null;
  usuario?: Usuario | null;
  concepto?: string | null

  constructor(mappedData) {
    this.id = mappedData?.id ?? null;
    this.fechaAlta = mappedData?.fechaAlta ?? null;
    this.fechaBaja = mappedData?.fechaBaja ?? null;
    this.fechaModificacion = mappedData?.fechaModificacion ?? null;
    this.tipoGestion = mappedData?.tipoGestion ?? null;
    this.importe = mappedData?.importe ?? null;
    this.tipo_gasto = mappedData?.tipo_gasto ?? null;
    this.tipo_ingreso = mappedData?.tipo_ingreso ?? null;
    this.numDocumento = mappedData?.numDocumento ?? null;
    this.usuario = new Usuario(mappedData?.usuario) ?? null;
    this.concepto = mappedData?.concepto ?? null;
  }
}

export class Usuario {
  id?: number;
  nombre?: string;
  email?: string;
  apellido1?: string;
  apellido2?: string;
  numero_documento?: number;
  rol?: Tipopersona[];
  departamento?: Departamento;
  twoFactorEnabled?: boolean;
  direcciones?: Direccione[];
  telefono?: string;
  clave?: boolean;
  third?: boolean;
  name_road: string;
  number_road: string;
  tipopersona: string;

  constructor(mappedData) {
    this.id = mappedData?.id ?? null;
    this.nombre = mappedData?.nombre ?? null;
    this.apellido1 = mappedData?.apellido1 ?? null;
    this.apellido2 = mappedData?.apellido2 ?? null;
    this.rol = mappedData?.rol ?? null;
    this.departamento = mappedData?.departamento ?? null;
    this.twoFactorEnabled = mappedData?.twoFactorEnabled ?? null;
    this.direcciones = mappedData?.direcciones ?? null;
    this.clave = mappedData?.clave ?? null;
    this.third = mappedData?.third ?? null;
  }
}



export type NewGastosIngresos = Omit<IGastosIngresos, 'id'> & { id: null };
