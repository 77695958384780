import { Component, OnInit } from "@angular/core";
import { AuthService } from "../_services/auth.service";
import { TokenStorageService } from "../_services/token-storage.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ServicioGeneral } from "../_services/servicio-general.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormGroup, FormControl } from "@angular/forms";
import { UrlService } from "../_services/urlService";
import { environment } from 'src/environments/environment';
import { UserService } from "../_services/user.service";
import { NotificationService } from "../_services/notification.service";
import { RaizComponent } from "../raiz/raiz.component";
import { ArrayDataSource } from "@angular/cdk/collections";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {

  currentEnv = environment.environment;

  form: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
    totpkey: new FormControl(""),
  });

  form2: FormGroup = new FormGroup({
    totpkey: new FormControl("", [Validators.required, Validators.minLength(6)])
  });

  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = "";
  midata: any;
  tfaFlag: boolean = false;
  tfa: string = null;
  userRoles: any = [];
  submitted = false;
  loading = false;
  returnUrl: string;
  errorTfa: boolean;
  check2fa: boolean = false;
  errorQR: boolean;
  tfActivated: boolean;
  resetQR: boolean = false;
  resetedQR: boolean = false;
  environment: string = environment.environment;
  previousUrl: string = '';
  showPassword: boolean = false;
  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private urlService: UrlService,
    private _notificationSvc: NotificationService,
    private raiz: RaizComponent,
    private tokenService: TokenStorageService,
    private servicioGeneral: ServicioGeneral
  ) {
  }

  ngOnInit(): void {
    // console.log('login');

    this.checkPrevious()
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.midata = this.tokenStorage.getUser();
      let rol = this.tokenService.getRol();
      
      if (rol == 99 || rol == 2) {
        this.router.navigateByUrl('/admin-hencok/clientes').then(() => {
          this.router.navigate(['admin-hencok/clientes']);
        });
      }
       else if (rol == 3) {
        this.router.navigate(['admin-autoescuelas-hencok/clientes'])
      } 
      else if (rol == 5) {        
        this.router.navigate(['mod-autoescuelas-hencok/clientes'])
      } 
      else if (rol == 4) {
        this.router.navigate(['client-autoescuelas-hencok/listado-tramites'])
      }
      else {
        this.router.navigate(['client-hencok/clientes'])
      }
    }
    if (this.previousUrl) {
      this.returnUrl = this.previousUrl
    } else {
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/profile";
    }
  }

  checkPrevious() {
    this.previousUrl = this.urlService.getPreviousUrl();
  }



  onSubmit(): any {
    this.submitted = true;

    if (this.form.get('email').hasError('pattern')) {
      this._notificationSvc.warning('Información', 'El email no es válido.', 2000);
      this.submitted = false;
      return false;
    }
    if (this.form.get('password').hasError('minlength')) {
      this._notificationSvc.warning('Información', 'La contraseña debe contener 6 caracteres.', 2000);
      this.submitted = false;
      return false;
    }
    if (!this.form.valid) {
      this._notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
      this.submitted = false;
      // console.log( this.form.controls['totpkey'].value);

    } else {
      let email = this.form.controls['email'].value;
      this.authService.login(this.form.controls['email'].value, this.form.controls['password'].value, this.form.controls['totpkey'].value).subscribe({
        next: async (data) => {
          // console.log("response" , data, this.tfaFlag);
          console.log(data)
          if(data['updateCredentials']){
            this.servicioGeneral.findRecoverUserByEmail(email).subscribe({
              next: (data) =>{
                console.log(data)
                this.router.navigate(['new-user/',data['identifier']])
              }
            })
            
          }
          else{
            if (data['2faEnabled'] && data['tfa']) {
              if (data['2faEnabled'] === "true" && data['tfa'] === "disabled") {
  
                this.tokenStorage.saveToken(data.accessToken);
                this.resetearQR();
              } else if (this.tfaFlag === false) {
                this.tokenStorage.saveToken(data.accessToken);
                this.loginTfaDisabled();
              } else if (data['tfa'] !== "disabled") {
                this.form.controls['totpkey'].addValidators([Validators.required, Validators.minLength(6)])
                this.submitted = false;
              }
            } else {
              if (data.codigoError == "INVALID_2FA" && this.tfaFlag) {
                this._notificationSvc.info("INFORMACIÓN", data['mensajeError'], 2000);
                this.submitted = false;
              }
              if (data.codigoError == "INVALID_2FA") {
                this.tfaFlag = true;
                this.submitted = false;
              }
              if (this.tfaFlag === false) {
                this.tokenStorage.saveToken(data.accessToken);
                this.loginTfaDisabled();
              }
  
            }
          }
          
        },
        error: (error) => {
          this.submitted = false;
          this._notificationSvc.error('Error', error.error.message, 2000);
          this.isLoginFailed = true;
        },
      });
    }
  }

  logout(): void {
    this.tokenStorage.signOut();
    window.location.reload();
  }

  loginTfaEnabled(): any {
    this.submitted = true;

    if (this.form.get('totpkey').hasError('minlength')) {
      this._notificationSvc.error('Error', 'El código debe contener 6 caracteres.', 2000);
      return false;
    }

    if (!this.form.valid) {
      this._notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
      return false;
    }
    this.authService.login(this.form.controls['email'].value, this.form.controls['password'].value, this.form.controls['totpkey'].value).subscribe({
      next: (dataTotpkey) => {

        if (dataTotpkey.codigoError !== "INVALID_2FA") {
          this.tokenStorage.saveToken(dataTotpkey.accessToken);
          this.authService.updateAuthStatus(true);
          this.userService.getCurrentUserFromServer().subscribe({
            next: (data) => {
              // console.log(data);
              this.tokenStorage.setUser(data.Usuario);
              this.raiz.reloadHeader = !this.raiz.reloadHeader;
              if (this.environment == 'hencok') {
                this.submitted = false;
                let rol = this.tokenService.getRol()
                if (rol == 99 || rol == 2) {
                  this.router.navigateByUrl('/admin-hencok/clientes').then(() => {
                    this.router.navigate(['admin-hencok/clientes']);
                  });
                } 
                else if (rol == 3) {
                  this.router.navigate(['admin-autoescuelas-hencok/clientes'])
                } 
                else if (rol == 4) {
                  this.router.navigate(['client-autoescuelas-hencok/listado-tramites'])
                }
                else if (rol == 5) {        
                  this.router.navigate(['mod-autoescuelas-hencok/clientes'])
                } 
                else {
                  this.router.navigate(['client-hencok/clientes'])
                }

              } else {
                this.router.navigateByUrl(this.previousUrl);
              }
            },
            error: (error) => {
              //TODO error
            }
          })
        } else {
          this.submitted = false;
          this._notificationSvc.error('Error', 'El código no es correcto.', 2000);
          this.form.controls['totpkey'].setErrors({ 'incorrect': true });
        }
      },
      error: (error) => {
        this.submitted = false;
        this._notificationSvc.error('Error', error.error.message, 2000);
        this.isLoginFailed = true;
      },
    })
  }

  loginTfaDisabled(): any {
    this.isLoggedIn = true;
    this.isLoginFailed = false;
    this.authService.updateAuthStatus(true);
    this.userService.getCurrentUserFromServer().subscribe({
      next: (data) => {
        // console.log(data.Usuario)
        this.tokenStorage.setUser(data.Usuario);
        this.raiz.reloadHeader = !this.raiz.reloadHeader;
        if (this.environment == 'hencok') {
          let rol = this.tokenService.getRol()
          console.log(rol)
          if (rol == 99 || rol == 2) {
            this.submitted = false;
            this.router.navigateByUrl('/admin-hencok/clientes').then(() => {
              this.router.navigate(['admin-hencok/clientes']);
            });
          } else if (rol == 3) {
            this.router.navigate(['admin-autoescuelas-hencok/clientes'])
          } else if (rol == 4) {
            this.router.navigate(['client-autoescuelas-hencok/listado-tramites'])
          } else if (rol == 5 || rol == 6) {        
            this.router.navigate(['mod-autoescuelas-hencok/clientes'])
          } 
          else {
            this.submitted = false;
            this.router.navigate(['client-hencok/clientes'])
          }

        } else {
          this.router.navigateByUrl(this.previousUrl);
        }
      },
      error: (error) => {

      }
    })

  }

  resetearQR(): any {
    this.resetQR = true;
    this.pedirQR();
    //this.guardarQR();
  }

  qr;
  pedirQR(): any {
    this.submitted = false;
    this.authService.pedirQr().subscribe({
      next: (data) => {

        // console.log(data);
        this.qr = data;

      },
      error: (err) => {
        // console.log('Error de qr');

        // console.log(err);
      }
    });
  }

  guardarQR(): any {
    this.submitted = true;

    if (this.form2.get('totpkey').hasError('minlength')) {
      this._notificationSvc.error('Error', 'El código debe contener 6 caracteres.', 2000);
      return false;
    }

    if (!this.form2.valid) {
      this._notificationSvc.warning('Error', 'Faltan campos por rellenar', 2000);
    } else {
      this.authService.guardarQR(this.qr.secret, this.form2.get('totpkey').value).subscribe({
        next: (data) => {
          this.qr = data;
          if (this.qr.result === "success") {
            this.tfActivated = true;
            this.errorQR = false;
            this.resetedQR = true;
            this.authService.updateAuthStatus(true);
            this.userService.getCurrentUserFromServer().subscribe({
              next: (data) => {
                // console.log(data);

                this.tokenStorage.setUser(data.Usuario);
                this.raiz.reloadHeader = !this.raiz.reloadHeader;
                this.router.navigateByUrl(this.previousUrl);
              },
              error: (error) => {

              }
            })
          } else {
            this.form2 = this.formBuilder.group({
              totpkey: [""]
            });
            this.errorQR = true;
          }
        },
        error: (error) => {
          if (error.error.codigo == 637) {
            this._notificationSvc.error('Error', 'Código incorrecto', 2000);
          }
        }
      });
    }
  }
}

