import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FileHandle } from './drag-drop.directive';
import { Observable, ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { Documentos } from 'src/app/model/autoescuela/documentos';
import { ConsultaService } from 'src/app/_services/autoescuela/consulta.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { TramitesService } from 'src/app/_services/autoescuela/tramites.service';

@Component({
  selector: 'app-formulario-consulta',
  templateUrl: './formulario-consulta.component.html',
  styleUrls: ['./formulario-consulta.component.css']
})
export class FormularioConsultaComponent {
  isAdmin = false
  isMod = false
  usuario = this.tokenStorage.getId();
  idConsulta
  isUpdate
  isRequired
  documentosAux = []
  arrayDocuments = []
  submitted
  email = ""

  form: UntypedFormGroup = new UntypedFormGroup({
    usuario: new UntypedFormControl(this.usuario),

    concepto: new UntypedFormControl("", Validators.required),
    mensaje: new UntypedFormControl("", Validators.required),
    documentos: new UntypedFormControl(this.arrayDocuments),
  })

  constructor(
    private servicioConsulta: ConsultaService,
    private router: Router,
    private notificationService: NotificationService,
    private tokenStorage: TokenStorageService,
    private route?: ActivatedRoute,
    private tramiteService?: TramitesService
  ) { }

  async ngOnInit() {
    if (this.tokenStorage.getRol() == 3 || this.tokenStorage.getRol() == 99) {
      this.isAdmin = true;
    }
    
  if (this.tokenStorage.getRol() == 5 || this.tokenStorage.getRol() == 6) {
    this.isMod = true;
  }

    this.route.params.subscribe((params) => {
      this.idConsulta = params["id"];
    })

    //var id = this.location.getState()["id"]


    if (this.idConsulta) {
      this.getConsulta(this.idConsulta)
      this.isUpdate = true
    }
  }
  getConsulta(id) {
    this.servicioConsulta.getConsultaById(id).subscribe((data) => {
      if (data == null) {
        this.notificationService.error(
          "Error",
          "Acceso no permitido",
          2000
        );
        if (!this.isAdmin) {
          this.router.navigate(['/client-autoescuelas-hencok/listado-tramites'])
        } else {
          this.router.navigate(['/admin-autoescuelas-hencok/listado-tramites'])
        }
      }
      console.log(data);

      this.getDocumentos(data)
    })

  }
  initiateForm(data) {
    console.log(data)

    //console.log(this.observaciones)
    this.form = new UntypedFormGroup({
      concepto: new UntypedFormControl(data["concepto"], Validators.required),
      mensaje: new UntypedFormControl(data["mensaje"], Validators.required),
      documentos: new UntypedFormControl(this.arrayDocuments),
    }, { updateOn: 'change' });



  }
  getDocumentos(data) {
    this.documentosAux = data["documentosAdjuntos"]
    if (this.documentosAux.length == 0) {
      this.initiateForm(data)
    }
    this.documentosAux.forEach(element => {


      if (!element["idViafirma"]) {
        this.tramiteService.getDocumentos(element["idNextcloud"]).subscribe((documento) => {

          let document: Documentos = {
            nombre: documento["nombre"],
            base64: documento["base64"],
            idNextcloud: element["idNextcloud"]
          };
          this.arrayDocuments.push(document)
          if (this.documentosAux.length == this.arrayDocuments.length) {
            this.initiateForm(data)
          }
        })
      }

    });
  }
  removeFile(index: number, idNextcloud?): void {
    if (idNextcloud != null) {
      this.tramiteService.deleteDocumento(idNextcloud).subscribe()
    }
    this.arrayDocuments.splice(index, 1);
    this.notificationService.success(
      "Exito",
      "Documento borrado",
      2000
    );


  }
  filesDropped(files: FileHandle[]) {
    this.convertFile(files[0].file).subscribe((base64) => {
      let document: Documentos = {
        nombre: files[0].file.name,
        mime: files[0].file.type,
        base64: base64,
        isNew: true,
      };
      this.arrayDocuments.push(document)
    })
  }
  onFileChange(event) {
    if (
      event.target.files[0].type == "application/pdf"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
          isNew: true,
        };
        this.arrayDocuments.push(document)
      });
    } else {
      this.notificationService.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        2000
      );
    }
  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  abrirArchivo(documento) {
    const byteArray = new Uint8Array(atob(documento).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank")
  }

  async onSubmit(): Promise<any> {
    this.email = this.tokenStorage.getUser().email
    this.isRequired = false

    console.log(this.form)

    this.submitted = true;
    if (!this.form.valid) {

      this.notificationService.warning('Información', 'Rellene todos los campos por favor.', 2000);
      this.isRequired = true;
      return false;
    } else {

      if (this.form.get('mensaje').value == "" || this.form.get('mensaje').value == "") {
        this.notificationService.warning('Información', 'Rellene todos los campos por favor.', 2000);
        this.isRequired = true;
        return false;
      }
      if (this.form.valid) {
        this.submitted = true;
        if (!this.isUpdate) {
          this.servicioConsulta.saveConsulta(this.form.value).subscribe({
            next: (data) => {
              console.log(data)
              this.notificationService.success('Exito', "Consulta creada", 2000);
              this.tramiteService.changeStateTramite(1,data["id"]).subscribe()

              if (!this.isAdmin) {
                this.router.navigate(['/client-autoescuelas-hencok//consulta/' + data["id"]])
              } else {
                this.router.navigate(['admin-autoescuelas-hencok/consulta/' + data["id"]])
              }

              this.submitted = false;
              this.isRequired = false;

            },
            error: (err) => {
            }
          })
        } else {
          this.servicioConsulta.updateConsulta(this.form.value, this.idConsulta).subscribe({
            next: (data) => {
              console.log(data)
              this.notificationService.success('Exito', "Consulta actualizada", 2000);
              if (!this.isAdmin) {
                this.router.navigate(['/client-autoescuelas-hencok/consulta/' + data["id"]])
              } else {
                this.router.navigate(['admin-autoescuelas-hencok/consulta/' + data["id"]])
              }
              this.submitted = false;
              this.isRequired = false;

            },
            error: (err) => {
            }
          })
        }


      }


    }



  }
}
