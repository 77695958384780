import { Component, Input, SimpleChanges } from "@angular/core";
import {
  AbstractControl,
  Form,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/_services/auth.service";
import { InfoService } from "src/app/_services/info.service";
import { NotificationService } from "src/app/_services/notification.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { UserService } from "src/app/_services/user.service";
import { RaizComponent } from "src/app/raiz/raiz.component";
import { environment } from "src/environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { GastosService } from "src/app/_services/hencok/gastos.service";
import { TipoGasto } from "src/app/model/tipoGasto";
import { DatePipe } from "@angular/common";
import { IngresosGastosService } from "src/app/_services/hencok/ingresos-gastos.service";
import { FileHandle } from "./drag-drop.directive";
import { GestionIngresosGastosComponent } from "../gestion-ingresos-gastos/gestion-ingresos-gastos.component";
@Component({
  selector: "app-formulario-editar-gasto-ingreso",
  templateUrl: "./formulario-editar-gasto-ingreso.component.html",
  styleUrls: ["./formulario-editar-gasto-ingreso.component.css"],
})
export class FormularioEditarGastoIngresoComponent {
  form: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    fecha_creacion: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_gasto: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),
    usuario_id: new UntypedFormControl()

  });
  @Input() detalles2: any;
  @Input() detallesM: any;
  @Input() primeraVezM: boolean = false;

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = "";
  submitted: boolean = false;
  register2 = false;
  InfoRegistro: any = [];
  environment = environment;
  isLoggedIn = false;
  arrayDocuments = [];
  arrayDocumentsActual = [];
  gastos: any = [];
  miTipoGasto: any = "";
  gastosaux: any = [];
  usuarios: any[] = [];
  usuario: any = -1;
  usuariosaux: any[] = [];;
  miUsuario: any;
  fechaActual: string;

  mostrado: any = false;
  auxiliar: boolean = false;

  pdfAuxiliar: string;
  userLogged: any;
  userAdmin: Boolean = false;
  name = "Angular 5";
  load: boolean;

  uploadDocuments: Boolean = false;

  constructor(
    private _notificationSvc?: NotificationService,
    private _router?: Router,
    private _ingresosGastosService?: IngresosGastosService,
    private raiz?: RaizComponent,
    private tokenStorageService?: TokenStorageService,
    private gastoservice?: GastosService,
    public datepipe?: DatePipe,
    private _serviceGeneral?: ServicioGeneral,
    public _activatedRoute?: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.getDocumentsList()

    this.gastosaux = [];
    this.usuariosaux = [];
    this.miTipoGasto = "";
    this.miUsuario =[];
    if (this.primeraVezM) {
      this.form.get("importe").setValue(this.detallesM.importe + ".00"),
        this.form.get("concepto").setValue(this.detallesM.concepto),
        this.form
          .get("fecha_creacion")
          .setValue(this.formatDate(this.detallesM.fechaCreacion));
      this.miTipoGasto = this.detallesM.tipoGasto;
      this.gastos.forEach((value) => {
        if (this.miTipoGasto.nombre != value.nombre) {
          this.gastosaux.push(value);
          
        }

      });
      this.form.get("tipo_gasto").setValue(this.miTipoGasto.nombre)

      this.gastosaux.unshift(this.miTipoGasto);
    
  
      this.miUsuario = this.detallesM.usuario;
      this.usuarios.forEach((value) => {

        if (this.detallesM.usuario.id != value.id) {
          this.usuariosaux.push(value);
        }
      });
      this.usuariosaux.unshift(this.miUsuario);
      this.usuario=this.miUsuario
      
    }
  }

  ngOnInit(): void {
    // this.detalles.forEach(element => {
    //   this.sacarUrlPdf(element);
    // });

    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.userLogged = this.tokenStorageService.getUser();
    let rol = this.tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }

    this.register2 = false;
    this.getTipoGasto();
    this.getUserList();
  }

  getDocumentsList(){
    this.arrayDocumentsActual=[]
    this.arrayDocuments=[]
  //  console.log("size--->" +this.detalles2.size)
    this.detalles2.forEach(element => {
      this.arrayDocuments.push(element) 
    });
    this.arrayDocumentsActual.push(this.arrayDocuments)
    this.form.controls["documento"].setValue(this.arrayDocuments);

  }

  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => {},
    });
  }

  getTipoGasto() {
    return this.gastoservice.getTipoGasto().subscribe({
      next: (data) => {
        this.gastos = data;
      },
    });
  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];
      },
      error: (error) => {},
    });
  }

  removeFile(index: number): void {
   
    if(this.arrayDocuments.length==0){
      this.arrayDocuments.splice(index, 1); 

      this._notificationSvc.error('ERROR', "No puede dejar el ingreso/gasto sin ningun documento", 2000);

      this.uploadDocuments=false
    }else{
    
        this._ingresosGastosService.DeleteFile(this.arrayDocuments[index].id_gestor).subscribe({
        next: (data) => {
        //  console.log(data);
        },
        error: (error) => {
        //  console.log(error);
      }  })
      //.pipe(finalize( () => {
this.arrayDocuments.splice(index, 1); 
    }
  }

 
  

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }



  onFileChange(event) {
    if (
      event.target.files[0].type == "application/pdf" ||
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpeg"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        this.arrayDocuments = [document];
        this.form.controls["documento"].setValue(this.arrayDocuments);
      });
    } else {
      this._notificationSvc.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        2000
      );
    }
  }

  filesDropped(files: FileHandle[]): void {
    this.form.patchValue({
      documento: files,
    });
    this.convertFile(files[0].file).subscribe((base64) => {
      let document = {
        nombre: files[0].file.name,
        mime: files[0].file.type,
        base64: base64,
      };
      this.arrayDocuments = [document];
      this.form.controls["documento"].setValue(this.arrayDocuments);
    });

    // document.getElementById('image1').onchange = function () {
    //   document.getElementById('fichero').innerHTML = document.getElementById('image1').files[0].name;
    // }
  }
  formatDate(date) {
    let month = "" + date[1];
    let day = "" + date[2];
    const year = date[0];
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return year + "-" + month + "-" + day;
  }
  formatDate2(date: Date) {
    let month = "" + date.getMonth();
    let day = "" + date.getDate();
    const year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return day + "/" + month + "/" + year;
  }

  async onSubmit(): Promise<any> {
  //  console.log("form")
  //  console.log(this.form.controls["documento"].value)
    this.register2 = false;

    if (
      this.form.controls["documento"].value === "" ||
      this.form.controls["documento"].value.length == 0
    ) {
    //  console.log("documento invalido");

      this.form.controls["documento"].setValue([]);
      this._notificationSvc.warning(
        "Informacion",
        "El documento es obligatorio",
        2000
      );
      return false;
    }
    this.form.addControl("tipo_transaccion", new UntypedFormControl()); 
    this.form.controls["tipo_transaccion"].setValue(this.detallesM.tipo_transaccion.nombre);
    this.form.controls["usuario_id"].setValue(this.usuario.id);
    if (this.userAdmin) {
      if (this.usuario.id == null) {
        this._notificationSvc.warning(
          "Informacion",
          "El usuario es obligatorio",
          2000
        );
        return false;
      }
    console.log((this.form.controls))
    if(this.form.controls["fecha_creacion"].invalid){
      this._notificationSvc.warning(
        "Informacion",
        "El formato de la fecha no es correcto",
        2000
      );
      return false;
    }
    } else {
      this.form.controls["usuario_id"].setValue(this.userLogged.id);
    }

    if (!this.form.valid) {
    //  console.log("form invalid");
      this._notificationSvc.warning(
        "Informacion",
        "Rellene todos los campos por favor",
        2000
      );
      return false;
    } else {
      this.submitted = true;
      this.uploadDocuments = true;
      this.form.controls["fecha_creacion"].setValue(
        this.datepipe.transform(
          this.form.controls["fecha_creacion"].value,
          "dd/MM/yyyy"
        )
      );
      this.form.addControl("gasto_id", new UntypedFormControl());
      this.form.controls["gasto_id"].setValue(this.detallesM.id);      

      

      }
      //  console.log(this.form.value)
      this.gastoservice.updateGasto(this.form.value).subscribe({
        next: (data) => {
          this.submitted = true;
          if(this.userAdmin){
          this._router.navigateByUrl("/admin-hencok").then(() => {
            this._router.navigate(["admin-hencok/gastos-ingresos"]);
          })}
          else{
            this._router.navigateByUrl("/client-hencok").then(() => {
              this._router.navigate(["client-hencok/gastos-ingresos"]);
            })
          }
          this._notificationSvc.success(
            "Hecho",
            "Ingreso guardado con exito",
            12000
          );
        },
        error: (err) => {
          this.uploadDocuments = false;
        },
      });
    }
    closeSidebar(){
      GestionIngresosGastosComponent.closeSidebarClienteRemoto();
    }
  }

