<nav id="sidebar">
    <div class="fixed">
        <app-formulario-nuevo-gasto></app-formulario-nuevo-gasto>
    </div>
</nav>

<nav id="sidebar_ingreso">
    <div class="fixed">
        <app-formulario-nuevo-ingreso></app-formulario-nuevo-ingreso>
    </div>
</nav>
<nav id="sidebar_detalle">
    <div class="fixed">
        <app-visualizar-gastos-ingresos [detallesP]="gasto_ingreso" [detalles]="detalles"
            [primeraVez]="primeraVez"></app-visualizar-gastos-ingresos>
    </div>
</nav>

<nav id="sidebar_modificar">
    <div class="fixed">
        <app-formulario-editar-gasto-ingreso [primeraVezM]="primeraVezM" [detallesM]="gasto_ingreso2"
            [detalles2]="detalles2"></app-formulario-editar-gasto-ingreso>
    </div>
</nav>

<div class="d-flex flex-row justify-content-between align-items-center full-form-group">
    <h1 class="m-0">Gestión de Gastos e Ingresos</h1>
    <div>
        <button class="btn btn-primary align-middle nuevo-ingreso mb-1" (click)="openSidebar()">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo Gasto</span>
        </button>
        <button class="btn btn-primary align-middle nuevo-ingreso mb-1" (click)="openSidebarIngreso()">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo Ingreso</span>
        </button>
    </div>
</div>
<div (click)="closeSidebar($event)" class="full-form-group">

    <div class="col-sm-12 d-flex justify-content-between card-total-grande row mt-5 ml-0">
        <div class="col-12 col-xl-4 p-0">
            <h5 class="pb-2">Gastos e Ingresos</h5>

            <div class="card border-0 rounded shadow card-info">
                <div class="row w-100 card-with-results"></div>
                <div class="col-12 mt-4 ml-3 mr-3">
                    <p class="tinyLabel">TOTAL FACTURADO</p>
                    <p class="totalFacturado mt-2">€{{total_facturado}}</p>
                </div>
                <div class="col-lg-12 mt-2 mb-3 ml-3 mr-3">
                    <div class="row-ingreso-gasto row w-100">
                        <div class="col-xl-6">
                            <div class="colorPendiente">
                                <li>Ingreso</li>
                            </div>
                            €{{total_ingreso}}
                        </div>
                        <div class="col-xl-6">
                            <div class="colorDeuda">
                                <li>Gasto</li>
                            </div>
                            €{{total_deuda}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-12 col-xl-6 border-0 rounded shadow card-info mt-2">
            <div class="d-flex mt-5">
                <div class="iconName rounded-circle ml-2" style="width: 60px; height: 60px;">
                    <p *ngIf="usuario?.imgPerfil==undefined">
                        {{getFirstLetter(usuario?.nombre)}}{{getFirstLetter(usuario?.apellido1)}}</p>
                    <img *ngIf="usuario?.imgPerfil!=undefined" src="data:image;base64,{{usuario.imgPerfil}}"
                        width="100%" height="100%" class="rounded-circle">
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div>
                    <div class="ml-4 mt-3">
                        <p class="colorOption"><b>#ID{{this.usuario ? this.usuario.id : ''}}</b></p>
                        <p class="infoUsu"><b>{{this.usuario ? this.usuario.nombre : ''}} {{this.usuario ?
                                this.usuario.apellido1 : ''}}
                                {{this.usuario ? this.usuario.apellido2 : ''}}</b></p>
                        <p class="infoUsu">{{this.usuario ? this.usuario.email : ''}}</p>
                    </div>
                </div>

                <div class="col-sm-12 d-flex row flex-row mt-4 pl-4 ml-0">
                    <div class="col-12 col-xl-5 pl-0">
                        <p class="tinyLabel">Dirección</p>
                        <div class="d-flex flex-wrap">
                            <div>{{this.usuario ? this.usuario.direcciones[0].name_road : ''}}, {{this.usuario ?
                                this.usuario.direcciones[0].number_road : ''}} &nbsp;</div>
                            <div>{{this.usuario ? this.usuario.direcciones[0].postal_code : ''}} {{this.usuario ?
                                this.usuario.direcciones[0].province.nombre : ''}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-4 pl-0">
                        <p class="tinyLabel">Teléfono</p>
                        <p>{{this.usuario ? this.usuario.telefono : ''}}</p>
                    </div>
                    <div class="col-12 col-xl-3 p-0">
                        <p class="tinyLabel">Fecha de alta</p>
                        <p>{{fechaAlta}}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="subtitle">
        <h5 class="m-0">Listado de todas las transacciones</h5>
    </div>
    <div class="w-100 border-bottom filter-bar tableHead">
        <div class="w-100 mt-4 border-bottom">
            <div class="ordering-table">
                <div class="card-width-fecha_creacion filter-container">
                    <input type="text" class="searchinput" onfocus="(this.type='date')"
                        onblur="if(this.value==''){this.type='text'}" placeholder="FECHA" (change)="
                                doFilter($event, 'fechaCreacion', 'CURRENT_DATE', 'DATE')
                                " />
                    <button (click)="changeSorts('fechaCreacion', 'fechaCreacion')"><a
                            [ngClass]="sortArray['fechaCreacion']==='ASC' ? 'asc-sort': sortArray['fechaCreacion']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-width-concepto filter-container">
                    <input type="text" class="searchinput" placeholder="CONCEPTO"
                        (keyup)="doFilter($event, 'concepto', 'LIKE', 'STRING')" />
                    <button (click)="changeSorts('concepto', 'concepto')"><a
                            [ngClass]="sortArray['concepto']==='ASC' ? 'asc-sort': sortArray['concepto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-width-importe filter-container">
                    <input type="text" class="searchinput" placeholder="IMPORTE"
                        (keyup)="doFilter($event, 'importe', 'LIKE', 'DOUBLE')" />
                    <button (click)="changeSorts('importe', 'importe')"><a
                            [ngClass]="sortArray['importe']==='ASC' ? 'asc-sort': sortArray['importe']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-width-factura filter-container">
                    <input type="text" class="searchinput" placeholder="ESTADO"
                        (keyup)="doFilter($event, 'estado.nombre', 'LIKE', 'STRING')" />
                    <button (click)="changeSorts('estado.nombre', 'estado')"><a
                            [ngClass]="sortArray['estado']==='ASC' ? 'asc-sort': sortArray['estado']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-width-tipo_transaccion filter-container">
                    <input type="text" class="searchinput" placeholder="TIPO"
                        (keyup)="doFilter($event, 'tipoGasto.nombre', 'LIKE', 'STRING')" />
                    <button (click)="changeSorts('tipoGasto.nombre', 'tipoGasto')"><a
                            [ngClass]="sortArray['tipoGasto']==='ASC' ? 'asc-sort': sortArray['tipoGasto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-actions">
                    <div class="buttons">
                        ACCIONES
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card w-100 card-generic border-0">
        <div *ngIf="gastos_ingresos?.length == 0"
            class="d-flex flex-row justify-content-center empty-data-message my-4">

            <span>No se han encontrado registros que mostrar</span>

        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item p-3 d-flex border rounded shadow" *ngFor="let gasto_ingreso of gastos_ingresos">
                <div class="row w-100 row-with-cards">
                    <div
                        class="card border-0 card-width-fecha_creacion col-12 col-md-6 order-2 order-lg-3 order-xl-1 col-lg-3 col-xl-2">
                        <p class="cut-text"
                            [title]="formatDate(gasto_ingreso.fechaCreacion)?formatDate(gasto_ingreso.fechaCreacion):''">
                            {{formatDate(gasto_ingreso.fechaCreacion)}}
                        </p>

                    </div>
                    <div
                        class="card border-0 card-width-concepto col-12 order-1 order-lg-1 order-xl-2 col-lg-9 col-xl-3">
                        <p class="mb-0 cut-text"
                            [ngClass]="gasto_ingreso.tipo_transaccion.nombre === 'GASTO' ? 'colorDeudaCard':'colorPendienteCard' "
                            [title]="gasto_ingreso.concepto?gasto_ingreso.concepto:''">
                            {{gasto_ingreso.concepto}}

                        </p>
                    </div>
                    <div
                        class="card border-0 card-width-importe col-12 col-md-6 order-5 order-lg-5 order-xl-3 col-lg-7 col-xl-2">
                        {{gasto_ingreso.importe}}€
                    </div>
                    <div class="card border-0 card-width-factura status col-6 order-3 order-lg-2 order-xl-4 col-lg-3 col-xl-2"
                        [ngClass]="gasto_ingreso.estado.nombre === 'PENDIENTE' ? 'colorpendiente':
                    gasto_ingreso.estado.nombre === 'COMPLETADO' ? 'colorcompletado' :
                    'colorrechazado' ">
                        <p class="cut-text" [title]="gasto_ingreso.estado.nombre?gasto_ingreso.estado.nombre:''">
                            {{gasto_ingreso.estado.nombre}}
                        </p>
                    </div>
                    <div
                        class="card border-0 card-width-tipo_transaccion let-tipo col-6 order-4 order-lg-4 order-xl-5 col-lg-2 col-xl-1">
                        <p class="cut-text"
                            [title]="gasto_ingreso.tipoGasto.nombre?gasto_ingreso.tipoGasto.nombre.toLowerCase():''">
                            {{gasto_ingreso.tipoGasto.nombre.toLowerCase()}}
                        </p>
                    </div>
                    <div class="button-center col-12 order-6 order-lg-6 order-xl-6 col-lg-12 col-xl-2">
                        <div class="buttons-flex-container align-items-center card-width-actions">

                            <a (click)="openSidebarDetalles(gasto_ingreso,$event)" class="eye-button activated"
                                title="Ver"></a>
                            <a (click)="gasto_ingreso.factura==undefined && gasto_ingreso.estado.nombre=='PENDIENTE' ? openSidebarModificar(gasto_ingreso,$event): showEditDeleteError('editar', gasto_ingreso.estado.nombre)"
                                class="delete-button activated" class="edit-button activated pencil" title="Editar"></a>
                            <a (click)="downloadFile(gasto_ingreso.documento, gasto_ingreso.id)"
                                class="download-button activated" title="Descargar"></a>
                            <a (click)="gasto_ingreso.factura==undefined && gasto_ingreso.estado.nombre=='PENDIENTE' ? dialog.showModal(): showEditDeleteError('borrar' , gasto_ingreso.estado.nombre)"
                                class="delete-button activated" title="Eliminar"></a>
                            <dialog #dialog>
                                <div class="m-3 justify-content-center align-items-center">
                                    <a class="delete-button dialogborrar"></a>

                                    <div class="m-4">
                                        <h5 id="textdialog">¿Desea confirmar la eliminacion del Gasto/Ingreso
                                            #ID{{gasto_ingreso.id}}?
                                        </h5>
                                    </div>
                                    <div class="d-flex justify-content-end m-4">
                                        <button (click)="dialog.close()" id="btcancel" class="btn btn-primary mx-1 mt-5"
                                            type="submit">Cancelar</button>
                                        <button (click)="deleteGastoIngreso(gasto_ingreso,$event)" id="btacept"
                                            class="btn btn-primary mx-1 mt-5" type="submit" [disabled]="isDelete">
                                            <div class="spinner-border spinner-border-sm" *ngIf="isDelete"></div>
                                            <span class="align-middle"> Confirmar</span>
                                        </button>
                                    </div>
                                </div>
                            </dialog>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <mat-paginator *ngIf="mostrarPag" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements"
        (page)="nextPage($event)">
    </mat-paginator>
</div>