import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { TokenStorageService } from './token-storage.service';

const AUTH_API = environment.API_URL+'/user/';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": '*',
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
    "Access-Control-Max-Age": "300"
  })
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  headerOptions: any = null
  API_URL: string = environment.API_URL;

  _isLoggedIn: boolean = false;
  authSub = new Subject<any>();


  constructor(
    private http: HttpClient,
    private cookies: CookieService,
    private tokenservice: TokenStorageService
  ) { }
  /*
  
    login(form: any) {
      if (form.accessToken) {
        //console.log('Appending headers');
        this.headerOptions = new HttpHeaders({
          'accessToken': form.accessToken
        });
      }
      return this.http.post(`${this.API_URL}/user/` + 'login', { email: form.email, password: form.password }, { observe: 'response', headers: this.headerOptions });
    }
    */



  login(email: string, password: string, totpkey: string): Observable<any> {

    return this.http.post(`${this.API_URL}/user/` + 'login', {
      email,
      password,
      totpkey
    }, httpOptions);
  }

  verifyTotpkey(totpKey: String): Observable<any> {

    return this.http.post(`${this.API_URL}/user/` + 'login', {
      totpKey,
    }, httpOptions);
  }

  register(nombre: string, apellido1: string, apellido2: string, email: string, tipo_persona: string, tipo_documento: string, numero_documento: string, telefono: string,
    tipo_via: string, nombre_via: string, numero_via: string, bloque: string, escalera: string, piso: string, puerta: string,
    codigo_postal: string, pais: string, ccaa: string, provincia: string, municipio: string, iban: string, titular: string, entidad_bancaria: string, rol:number,password?:string, id?:string, id_iban?:string, id_direccion?:string): Observable<any> {
    return this.http.post(`${this.API_URL}/user/` + 'register', {
      nombre,
      apellido1,
      apellido2,
      email,
      tipo_persona,
      tipo_documento,
      numero_documento,
      telefono,
      tipo_via,
      nombre_via,
      numero_via,
      bloque,
      escalera,
      piso,
      puerta,
      codigo_postal,
      pais,
      ccaa,
      provincia,
      municipio,
      iban,
      titular,
      entidad_bancaria,
      rol,
      password,
      id,
      id_iban,
      id_direccion,
    }, httpOptions);
  }
  saveIban(iban) {
    return this.http.post(`${this.API_URL}/iban/insertarIban`, iban)

  }
  updateObservacion(observacion) {
    return this.http.post(`${this.API_URL}/observaciones/update`, observacion)

  }
  register2(concepto: string, fechaAlta: string, importe: string, Tipo: string, IVA: string): Observable<any> {
    return this.http.post(`${this.API_URL}/user/` + 'register2', {
      concepto,
      fechaAlta,
      importe,
      Tipo,
      IVA
    }, httpOptions);
  }

  delete(id_user: number): Observable<any> {
    return this.http.post(`${this.API_URL}` + 'delete', {
      id_user
    }, httpOptions);
  }



  update(nombre?: string, apellido1?: string, 
     email?: string, tipodocumento?: string,
     documento?: string, telefono?: string,
    isActieve?:boolean): Observable<any> {
    let id = this.tokenservice.getId();
   // console.log("ID ->",id)
    return this.http.post(`${this.API_URL}/user/update/${id}`, {
      'nombre': nombre,
      'email': email,
      'apellido1':apellido1,
     // 'apellido2':apellido2,
      'tipoDocumento':tipodocumento,
      'numeroDocumento':documento,
      'telefono':telefono,
      'isActive':isActieve
    }, {
      headers: {
        "accessToken": this.tokenservice.getToken()
      }
    });
  }
  updateTwoFactor(idUsuario?: any,twoFactorEnabled?: boolean){
    return this.http.post(`${this.API_URL}/user/updateCliente/${idUsuario}`, {
      'twoFactor': twoFactorEnabled
    }, {
      headers: {
        "accessToken": this.tokenservice.getToken()
      }
    });
  }
  updateClienteHencok(idUsuario?:any,nombre?: string, apellido1?: string, 
    email?: string, tipodocumento?: string,
    documento?: string, telefono?: string,
   isActieve?:boolean): Observable<any> {
   //let id = this.tokenservice.getId();
   return this.http.post(`${this.API_URL}/user/updateCliente/${idUsuario}`, {
     'nombre': nombre,
     'email': email,
     'apellido1':apellido1,
    // 'apellido2':apellido2,
     'tipoDocumento':tipodocumento,
     'numeroDocumento':documento,
     'telefono':telefono,
     'isActive':isActieve,

   }, {
     headers: {
       "accessToken": this.tokenservice.getToken()
     }
   });
 }

  setUserDirection(
    tipoVia: string,
    nombreVia: string,
    numeroVia: string,
    bloque: string,
    escalera: string,
    piso: string,
    puerta: string,
    codigoPostal: string,
    pais: string,
    ccaa: string,
    provincia: string,
    municipio: string,
    isactive: boolean
  ) {

    let id = this.tokenservice.getId();
    return this.http.post(`${this.API_URL}/user/direction/save`, {
      'tipoVia': tipoVia,
      'nombreVia': nombreVia,
      'numeroVia': numeroVia,
      'bloque': (bloque) ? bloque : '',
      'escalera': (escalera) ? escalera : '',
      'piso': (piso) ? piso : '',
      'puerta': (puerta) ? puerta : '',
      'codigoPostal': codigoPostal,
      'pais': pais,
      'ccaa': ccaa,
      'municipio': municipio,
      'provincia': provincia,
      'isActive': isactive,
      'usuario': id
    }, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT',
        'Content-Type': 'application/json; charset=utf-8',
        "accessToken": this.tokenservice.getToken()
      }
    });

  }

  updateUserDirection(
    id: number,
    tipoVia: any,
    nombreVia: string,
    numeroVia: string,
    bloque: string,
    escalera: string,
    piso: string,
    puerta: string,
    codigoPostal: string,
    pais: string,
    ccaa: string,
    provincia: any,
    municipio: string,
    isactive: boolean
  ) {

    let id_user = this.tokenservice.getId();
    //console.log(id)
    return this.http.post(`${this.API_URL}/user/direction/update/${id}`,  {
      'tipoVia': tipoVia?.id.toString(),
      'nombreVia': nombreVia,
      'numeroVia': numeroVia,
      'bloque': (bloque) ? bloque : '',
      'escalera': (escalera) ? escalera : '',
      'piso': (piso) ? piso : '',
      'puerta': (puerta) ? puerta : '',
      'codigoPostal': codigoPostal,
      'pais': pais,
      'ccaa': ccaa,
      'municipio': municipio,
      'provincia': provincia.id,
      'isActive': isactive,
      'usuario': id_user
    }, {
      headers: {
        "accessToken": this.tokenservice.getToken()
      }
    });

  }
  updateUserDirectionCliente(
    idUser:number,
    id: number,
    tipoVia: any,
    nombreVia: string,
    numeroVia: string,
    bloque: string,
    escalera: string,
    piso: string,
    puerta: string,
    codigoPostal: string,
    pais: string,
    ccaa: string,
    provincia: any,
    municipio: string,
    isactive: boolean
  ) {
    return this.http.post(`${this.API_URL}/user/direction/updateCliente/${id}`,  {
      'tipoVia': tipoVia?.id.toString(),
      'nombreVia': nombreVia,
      'numeroVia': numeroVia,
      'bloque': (bloque) ? bloque : '',
      'escalera': (escalera) ? escalera : '',
      'piso': (piso) ? piso : '',
      'puerta': (puerta) ? puerta : '',
      'codigoPostal': codigoPostal,
      'pais': pais,
      'ccaa': ccaa,
      'municipio': municipio,
      'provincia': provincia.id,
      'isActive': isactive,
      'usuario': idUser
    }, {
      headers: {
        "accessToken": this.tokenservice.getToken()
      }
    });

  }
  pedirQr(): Observable<any> {

    return this.http.post(`${this.API_URL}/user/set2fa`, {}, {});
  }

  guardarQR(secret: string, totpKey: string): Observable<any> {
    return this.http.post(`${this.API_URL}/user/set2fa`, { 'secret': secret, 'totpKey': totpKey }, {
      headers: {
        'accessToken': this.tokenservice.getToken()
      }
    });
  }

  quitarQR(disable: boolean): Observable<any> {
    return this.http.post(`${this.API_URL}/user/set2fa`, { 'disable': disable }, {
      headers: {
        'accessToken': this.tokenservice.getToken()
      }
    });
  }

  consultarStatus(): Observable<any> {
    return this.http.post(`${this.API_URL}/user/status`, {}, {
      headers: {
        'accessToken': this.tokenservice.getToken()
      }
    });
  }

  verifyRol(id: number): Observable<any> {
    return this.http.get(`${this.API_URL}/user/${id}/roles`, {})
  }

  getUserData(): Observable<any> {
    let id = this.tokenservice.getId();
    return this.http.get(`${this.API_URL}/user/get/${id}`, {
      headers: {
        "accessToken": this.tokenservice.getToken()
      }
    });
  }

  updateUser(data: any): Observable<any> {
    return this.http.post(`${this.API_URL}/user/updateUser`, data);
  }

  setToken(token: String) {
    this.cookies.set("token", 'token');
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('auth-token');
    // Check whether the token is expired and return
    // true or false
    return true;
  }

  updateAuthStatus(value: boolean) {
    this._isLoggedIn = value
    this.authSub.next(this._isLoggedIn);
    localStorage.setItem('isLoggedIn', value ? "true" : "false");
  }

  setupAuth() {
    return this.http.post(`${this.API_URL}/user/set2fa`, {}, { observe: 'response' })
  }


  getAuth() {
    return this.http.get(`${this.API_URL}/user/set2fa`, { observe: 'response' });
  }

  deleteAuth() {
    return this.http.delete(`${this.API_URL}/user/set2fa`, { observe: 'response' });
  }

  verifyAuth(token: any) {
    return this.http.post(`${this.API_URL}/user/set2fa`, { token }, { observe: 'response' });
  }


  getAuthStatus() {
    this._isLoggedIn = localStorage.getItem('isLoggedIn') == "true" ? true : false;
    return this._isLoggedIn
  }

  changePassword(password:any, id:any){
    return this.http.post(`${this.API_URL}/user/updateClientPassword`, { 'password':password, 'id':id });
  }
  resetAccess(id:any){
    return this.http.post(`${this.API_URL}/user/resetAccess`, {'id':id });
  }
  saveSignedContract(document: string, email: string){
    return this.http.post(`${this.API_URL}/user/saveContract`, {'document': document, 'email': email });
  }
}
