import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';


import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { InicioComponent } from './inicio/inicio.component';
import { AuthGuard } from './guards/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RaizComponent } from './raiz/raiz.component';
import { EnvironmentGuard } from './guards/environment.guard';
import { BoardAdminHencokComponent } from './board-admin-hencok/board-admin-hencok.component';
import { FichaClienteComponent } from './hencok/ficha-cliente/ficha-cliente.component';
import { GestionClientesComponent } from './hencok/gestion-clientes/gestion-clientes.component';
import { GestionIngresosGastosComponent } from './hencok/gestion-ingresos-gastos/gestion-ingresos-gastos.component';
import { GestionFacturasComponent } from './hencok/gestion-facturas/gestion-facturas.component';
import { GestionImpuestosComponent } from './hencok/gestion-impuestos/gestion-impuestos.component';
import { CumplimentacionFacturaComponent } from './hencok/cumplimentacion-factura/cumplimentacion-factura.component';
import { GestionIngresosGastosClienteComponent } from './hencok/gestion-ingresos-gastos-cliente/gestion-ingresos-gastos-cliente.component';
import { GestionFacturasClientesComponent } from './hencok/gestion-facturas-clientes/gestion-facturas-clientes.component';
import { FichaClienteActualizarComponent } from './hencok/ficha-cliente-actualizar/ficha-cliente-actualizar/ficha-cliente-actualizar.component';
import { ImpuestosClienteComponent } from './hencok/impuestos-cliente/impuestos-cliente.component';
import { FichaClienteNoAdminComponent } from './hencok/ficha-cliente-no-admin/ficha-cliente-no-admin.component';
import { FichaClienteNoAdminActualizarComponent } from './hencok/ficha-cliente-no-admin-actualizar/ficha-cliente-no-admin-actualizar.component';
import { VisualizarNotificacionesComponent } from './hencok/visualizar-notificaciones/visualizar-notificaciones.component';
import { ManualUtilizacionComponent } from './hencok/manual-utilizacion/manual-utilizacion.component';
import { PoliticaPrivacidadComponent } from './politica-privacidad/politica-privacidad.component';
import { AvisoLegalComponent } from './aviso-legal/aviso-legal.component';
import { GestionContratosComponent } from './hencok/gestion-contratos/gestion-contratos.component';
import { LaboralClienteComponent } from './hencok/laboral-cliente/laboral-cliente.component';
import { FormularioTramiteComponent } from './autoescuela/formulario-tramite/formulario-tramite.component';
import { CalculadoraItpComponent } from './autoescuela/calculadora-itp/calculadora-itp.component';
import { PresupuestoComponent } from './autoescuela/presupuesto/presupuesto.component';
import { VisualizarTransferenciaComponent } from './autoescuela/visualizar-transferencia/visualizar-transferencia.component';
import { ListadoTramitesComponent } from './autoescuela/listado-tramites/listado-tramites.component';
import { GestionPresupuestosComponent } from './autoescuela/gestion-presupuestos/gestion-presupuestos.component';
import { GestionPresupuestosGestorComponent } from './autoescuela/gestion-presupuestos-gestor/gestion-presupuestos-gestor.component';
import { FormularioConsultaComponent } from './autoescuela/formulario-consulta/formulario-consulta.component';
import { VisualizarConsultaComponent } from './autoescuela/visualizar-consulta/visualizar-consulta.component';
import { FormularioInformeDgtComponent } from './autoescuela/formulario-informe-dgt/formulario-informe-dgt.component';
import { FormularioDistintivoAmbientalComponent } from './autoescuela/formulario-distintivo-ambiental/formulario-distintivo-ambiental.component';
import { VisualizarDistintivoAmbientalComponent } from './autoescuela/visualizar-distintivo-ambiental/visualizar-distintivo-ambiental.component';
import { VisualizarInformeDgtComponent } from './autoescuela/visualizar-informe-dgt/visualizar-informe-dgt.component';
import { FormularioDuplicadoFichaTecnicaComponent } from './autoescuela/formulario-duplicado-ficha-tecnica/formulario-duplicado-ficha-tecnica.component';
import { VisualizarDuplicadoFichaTecnicaComponent } from './autoescuela/visualizar-duplicado-ficha-tecnica/visualizar-duplicado-ficha-tecnica.component';
import { FormularioCanjeDelPermisoComponent } from './autoescuela/formulario-canje-del-permiso/formulario-canje-del-permiso.component';
import { VisualizarCanjeDelPermisoComponent } from './autoescuela/visualizar-canje-del-permiso/visualizar-canje-del-permiso.component';
import { FormualrioSolicitudPermisoInternacionalComponent } from './autoescuela/formualrio-solicitud-permiso-internacional/formualrio-solicitud-permiso-internacional.component';
import { VisualizarSolicitudPermisoInternacionalComponent } from './autoescuela/visualizar-solicitud-permiso-internacional/visualizar-solicitud-permiso-internacional.component';
import { VisualizarDuplicadoPermisoConducirComponent } from './autoescuela/visualizar-duplicado-permiso-conducir/visualizar-duplicado-permiso-conducir.component';
import { DuplicadoPermisoConducirComponent } from './autoescuela/formulario-duplicado-permiso-conducir/duplicado-permiso-conducir.component';
import { FormularioMatriculacionComponent } from './autoescuela/formulario-matriculacion/formulario-matriculacion.component';
import { VisualizarMatriculacionComponent } from './autoescuela/visualizar-matriculacion/visualizar-matriculacion.component';
import { EstadisticasComponent } from './autoescuela/estadisticas/estadisticas.component';

const routes: Routes = [
  {
    path: '', component: RaizComponent,
    children: [
      { path: 'inicio', component: InicioComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password/:identificador', component: ResetPasswordComponent },
      { path: 'new-user/:identificador', component: ResetPasswordComponent },
      {
        path: 'admin-hencok', component: BoardAdminHencokComponent, canActivate: [AuthGuard, EnvironmentGuard], children: [
          { path: 'clientes', component: GestionClientesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'notificaciones', component: VisualizarNotificacionesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'notificaciones/:id', component: VisualizarNotificacionesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'gastos-ingresos', component: GestionIngresosGastosComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'gastos-ingresos/:id', component: GestionIngresosGastosClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'facturacion', component: GestionFacturasComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'facturacion/:id', component: GestionFacturasClientesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'impuestos', component: GestionImpuestosComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'perfil/:id', component: FichaClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editarCliente/:id', component: FichaClienteActualizarComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'perfilCliente/:id', component: FichaClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'impuestos/:id', component: ImpuestosClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'manual-utilizacion', component: ManualUtilizacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'gestion-mercantil', component: GestionContratosComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'nueva-transferencia', component: FormularioTramiteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'presupuestos', component: GestionPresupuestosGestorComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'transferencia/:id', component: VisualizarTransferenciaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'listado-tramites', component: ListadoTramitesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'gestion-calculadora_itp', component: CalculadoraItpComponent, canActivate: [AuthGuard, EnvironmentGuard]},
          {path: 'solicitar-informe-dgt', component: FormularioInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard]},
          {path: 'editar-informe-dgt/:id', component: FormularioInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard]},
          { path: 'informe-dgt/:id', component: VisualizarInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          {path: 'editar-distintivo-ambiental/:id', component: FormularioDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard]},
          { path: 'distintivo-ambiental/:id', component: VisualizarDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          {path: 'solicitar-distintivo-ambiental', component: FormularioDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard]},
          {path: 'solicitar-duplicado-ficha-tecnica', component: FormularioDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard]},
          { path: 'duplicado-ficha-tecnica/:id', component: VisualizarDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          {path: 'editar-duplicado-ficha-tecnica/:id', component: FormularioDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard]},
          { path: 'canje-permiso/:id', component: VisualizarCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-canje-permiso', component: FormularioCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-canje-permiso/:id', component: FormularioCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'actualizar-permiso-internacional/:id', component: FormualrioSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitud-permiso-internacional', component: FormualrioSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'permiso-internacional/:id', component: VisualizarSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'estadisticas', component: EstadisticasComponent, canActivate: [AuthGuard, EnvironmentGuard] },
        ]
      },
      {
        path: 'client-hencok', component: BoardAdminHencokComponent, canActivate: [AuthGuard, EnvironmentGuard], children: [
          { path: 'perfilCliente' },
          { path: 'editarCliente' },
          { path: 'miPerfil/editar', component: FichaClienteActualizarComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'miPerfil', component: FichaClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'clientes', component: GestionClientesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'perfil/:id', component: FichaClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'notificaciones/:id', component: VisualizarNotificacionesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'notificaciones/', component: VisualizarNotificacionesComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'gastos-ingresos/:id', component: GestionIngresosGastosComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'perfilCliente/:id', component: FichaClienteNoAdminComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editarCliente/:id', component: FichaClienteNoAdminActualizarComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'gastos-ingresos', component: GestionIngresosGastosClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'facturacion', component: GestionFacturasComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'facturacion/:id', component: GestionFacturasClientesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'misImpuestos' },
          { path: 'impuestos/:id', component: ImpuestosClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'manual-utilizacion', component: ManualUtilizacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'aviso-legal', component: AvisoLegalComponent },
          { path: 'politica-privacidad', component: PoliticaPrivacidadComponent },
          /*  { path: 'laboral'},
           { path: 'laboral/:id', component: LaboralClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] }, */
          /*  { path: 'laboral'},
           { path: 'laboral/:id', component: LaboralClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] }, */
        ]
      },
      {
        path: 'admin-autoescuelas-hencok', component: BoardAdminHencokComponent, canActivate: [AuthGuard, EnvironmentGuard], children: [
          { path: 'nueva-transferencia', component: FormularioTramiteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-informe-dgt', component: FormularioInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-distintivo-ambiental', component: FormularioDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-duplicado-ficha-tecnica', component: FormularioDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-transferencia' },
          { path: 'editar-transferencia/:id', component: FormularioTramiteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-informe-dgt/:id', component: FormularioInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-distintivo-ambiental/:id', component: FormularioDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'nueva-consulta', component: FormularioConsultaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-consulta/:id', component: FormularioConsultaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'clientes', component: GestionClientesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'notificaciones', component: VisualizarNotificacionesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'listado-tramites', component: ListadoTramitesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'gestion-calculadora_itp', component: CalculadoraItpComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'transferencia/:id', component: VisualizarTransferenciaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'canje-permiso/:id', component: VisualizarCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-permiso-internacional/:id', component: FormualrioSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-permiso-internacional', component: FormualrioSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'permiso-internacional/:id', component: VisualizarSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'distintivo-ambiental/:id', component: VisualizarDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'duplicado-ficha-tecnica/:id', component: VisualizarDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'informe-dgt/:id', component: VisualizarInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'consulta/:id', component: VisualizarConsultaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'presupuesto', component: PresupuestoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'presupuestos', component: GestionPresupuestosGestorComponent, canActivate: [AuthGuard, EnvironmentGuard] },
         
          { path: 'perfilCliente/:id', component: FichaClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editarCliente/:id', component: FichaClienteActualizarComponent, canActivate: [AuthGuard, EnvironmentGuard] },
       
          { path: 'editar-duplicado-ficha-tecnica/:id', component: FormularioDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-canje-permiso', component: FormularioCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-canje-permiso/:id', component: FormularioCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
       
          { path: 'editar-duplicado-permiso-conducir/:id', component: DuplicadoPermisoConducirComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-duplicado-permiso-conducir', component: DuplicadoPermisoConducirComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'duplicado-permiso-conducir/:id', component: VisualizarDuplicadoPermisoConducirComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'editar-matriculacion/:id', component: FormularioMatriculacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-matriculacion', component: FormularioMatriculacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'matriculacion/:id', component: VisualizarMatriculacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'manual-utilizacion', component: ManualUtilizacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'estadisticas', component: EstadisticasComponent, canActivate: [AuthGuard, EnvironmentGuard] },
        ]
      },
      {
        path: 'mod-autoescuelas-hencok', component: BoardAdminHencokComponent, canActivate: [AuthGuard, EnvironmentGuard], children: [
          { path: 'clientes', component: GestionClientesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'notificaciones', component: VisualizarNotificacionesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'manual-utilizacion', component: ManualUtilizacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'listado-tramites', component: ListadoTramitesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'nueva-transferencia', component: FormularioTramiteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-informe-dgt', component: FormularioInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-distintivo-ambiental', component: FormularioDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-duplicado-ficha-tecnica', component: FormularioDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
        
          { path: 'transferencia/:id', component: VisualizarTransferenciaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'canje-permiso/:id', component: VisualizarCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-permiso-internacional/:id', component: FormualrioSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-permiso-internacional', component: FormualrioSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'permiso-internacional/:id', component: VisualizarSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'distintivo-ambiental/:id', component: VisualizarDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'duplicado-ficha-tecnica/:id', component: VisualizarDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'informe-dgt/:id', component: VisualizarInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'consulta/:id', component: VisualizarConsultaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'nueva-consulta', component: FormularioConsultaComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'presupuesto', component: PresupuestoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'presupuestos', component: GestionPresupuestosGestorComponent, canActivate: [AuthGuard, EnvironmentGuard] },
         
          { path: 'perfilCliente/:id', component: FichaClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editarCliente/:id', component: FichaClienteActualizarComponent, canActivate: [AuthGuard, EnvironmentGuard] },
       
          { path: 'solicitar-canje-permiso', component: FormularioCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'gestion-calculadora_itp', component: CalculadoraItpComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'duplicado-permiso-conducir/:id', component: VisualizarDuplicadoPermisoConducirComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-duplicado-permiso-conducir', component: DuplicadoPermisoConducirComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'matriculacion/:id', component: VisualizarMatriculacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-matriculacion', component: FormularioMatriculacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'estadisticas', component: EstadisticasComponent, canActivate: [AuthGuard, EnvironmentGuard] },

        ]
      },
      {
        path: 'client-autoescuelas-hencok', component: BoardAdminHencokComponent, canActivate: [AuthGuard, EnvironmentGuard], children: [
          { path: 'nueva-transferencia', component: FormularioTramiteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-informe-dgt', component: FormularioInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-distintivo-ambiental', component: FormularioDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-duplicado-ficha-tecnica', component: FormularioDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-transferencia' },
          { path: 'editar-transferencia/:id', component: FormularioTramiteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-informe-dgt/:id', component: FormularioInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-distintivo-ambiental/:id', component: FormularioDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'nueva-consulta', component: FormularioConsultaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-consulta/:id', component: FormularioConsultaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'notificaciones', component: VisualizarNotificacionesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'listado-tramites', component: ListadoTramitesComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'gestion-calculadora_itp', component: CalculadoraItpComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'transferencia/:id', component: VisualizarTransferenciaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'canje-permiso/:id', component: VisualizarCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-permiso-internacional/:id', component: FormualrioSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-permiso-internacional', component: FormualrioSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'permiso-internacional/:id', component: VisualizarSolicitudPermisoInternacionalComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'distintivo-ambiental/:id', component: VisualizarDistintivoAmbientalComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'duplicado-ficha-tecnica/:id', component: VisualizarDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'informe-dgt/:id', component: VisualizarInformeDgtComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'consulta/:id', component: VisualizarConsultaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'presupuesto', component: PresupuestoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'presupuestos', component: GestionPresupuestosComponent, canActivate: [AuthGuard, EnvironmentGuard] },
         
          { path: 'perfilCliente/:id', component: FichaClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editarCliente/:id', component: FichaClienteActualizarComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'miPerfil/editar', component: FichaClienteActualizarComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'miPerfil', component: FichaClienteComponent, canActivate: [AuthGuard, EnvironmentGuard] },
       
          { path: 'editar-duplicado-ficha-tecnica/:id', component: FormularioDuplicadoFichaTecnicaComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-canje-permiso', component: FormularioCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'editar-canje-permiso/:id', component: FormularioCanjeDelPermisoComponent, canActivate: [AuthGuard, EnvironmentGuard] },

          { path: 'editar-duplicado-permiso-conducir/:id', component: DuplicadoPermisoConducirComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-duplicado-permiso-conducir', component: DuplicadoPermisoConducirComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'duplicado-permiso-conducir/:id', component: VisualizarDuplicadoPermisoConducirComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          
          { path: 'editar-matriculacion/:id', component: FormularioMatriculacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'solicitar-matriculacion', component: FormularioMatriculacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'matriculacion/:id', component: VisualizarMatriculacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
          { path: 'manual-utilizacion', component: ManualUtilizacionComponent, canActivate: [AuthGuard, EnvironmentGuard] },
        ]
      },
    ]
  },

  { path: 'cumplimentarFactura/:id', component: CumplimentacionFacturaComponent, canActivate: [EnvironmentGuard] },
  { path: '**', redirectTo: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
