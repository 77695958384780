<div class="d-flex flex-row justify-content-between align-items-center full-form-group">
    <h1 class="m-0">Ficha cliente</h1>
    <button class="btn btn-primary mx-3 mt-5 mr-5" type="submit" (click)="goEdit()">
        <mat-icon class="align-middle mr-2">add</mat-icon>Actualizar</button>
</div>
<div class="mr-5 ml-5">
    <hr class="linea-divisoria mr-5">
</div>

<div class="flex-row d-flex justify-content-between mb-5">
    <div class="col-sm-4 d-flex justify-content-around mb-5">
        <div class="iconName rounded-circle" style="width: 100px; height: 100px;">
            <p>{{getFirstLetter(usuario?.nombre)}}{{getFirstLetter(usuario?.apellido1)}}</p>
        </div>
        <div>
            <b>
                <p id="id"><b>#ID{{usuario?.idVisible}}</b></p>
            </b>
            <p id="info"><b>{{usuario?.nombre}} {{usuario?.apellido1}}</b></p>
            <p id="info">{{usuario?.email}}</p>
        </div>
    </div>
</div>

<div class="col-sm-12 pt-3 d-flex justify-content-between ml-4">
    <div class="col-sm-3">
        <p id="info">Nombre y apellidos</p>
        <h5>{{nombre}}</h5>
    </div>
    <div class="col-sm-2">
        <p id="info">CIF/NIF</p>
        <h5>{{numero_documento}}</h5>
    </div>
    <div class="col-sm-2">
        <p id="info">Email</p>
        <h5>{{email}}</h5>
    </div>
    <div class="col-sm-2">
        
    </div>
</div>
<div class="col-sm-12 pt-5 d-flex justify-content-between ml-4">

    <div class="col-sm-3">
        <p id="info">Calle (direccion)</p>
        <h5>{{name_road}}</h5>
    </div>
    <div class="col-sm-2">
        <p id="info">Número (direccion)</p>
        <h5>{{number_road}}</h5>
    </div>
    <div class="col-sm-2">
        <p id="info">Provincia</p>
        <h5>{{provincia}}</h5>
    </div>
    <div class="col-sm-2">
        <p id="info">C. postal</p>
        <h5>{{codigo_postal}}</h5>
    </div>

</div>

<div class="col-sm-12 pt-5 d-flex">

</div>