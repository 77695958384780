<!DOCTYPE html>
<html> <!-- Ejemplo basico de lanzador de la aplicacion -->
  <head>
	<title>Ejemplo de despliegue del Cliente @firma</title>
    <meta http-equiv="Content-Type" content="text/html;charset=utf-8" >
	<script type="text/javascript" src="js/autoscript.js"></script>
	<script type="text/javascript" src="js/constantes.js"></script>
  </head>
	<body>			
		<fieldset><legend>Entrada de datos (Opcional)</legend>
		<div>
		 <input id="data" type="hidden">
		 <div><span>Fichero de datos: </span><span id="dataFilename"></span></div>
		 <input type="button" value="Examinar" onclick="browseDatos('', document.getElementById('data'), document.getElementById('dataFilename'));">&nbsp;
		 <input type="button" value="Limpiar" onclick="cleanDataField(document.getElementById('data'), document.getElementById('dataFilename'));"><br/><br/>

		 <input id="signature" type="hidden">
		 <div><span>Fichero de firma: </span><span id="signatureFilename"></span></div>
		 <input type="button" value="Examinar" onclick="browseFirma('', document.getElementById('signature'), document.getElementById('signatureFilename'));">&nbsp;
		 <input type="button" value="Limpiar" onclick="cleanDataField(document.getElementById('signature'), document.getElementById('signatureFilename'));"><br/><br/>
		 
		</div>
		</fieldset>
		<br/>

		<fieldset><legend>Configuraci&oacute;n de la firma</legend>
		<div>
		 <label for="format">Formato</label>
		 <select id="format">
		 <option value="CAdES" selected>CAdES</option>
		 <option value="CAdEStri">CAdEStri</option>
		 <option value="Adobe PDF">PAdES</option>
		 <option value="PAdEStri">PAdEStri</option>
		 <option value="XAdES">XAdES</option>
		 <option value="XMLdSig">XMLdSig</option>
		 <option value="XAdEStri">XAdEStri</option>
		 <option value="FacturaE">FacturaE</option>
		 <option value="FacturaEtri">FacturaEtri</option>
		 <option value="ODF">ODF</option>
		 <option value="OOXML">OOXML</option>
		 <option value="NONE">PKCS#1</option>
		 <option value="AUTO">AUTO</option>
		 </select>
		 <span>(El formato AUTO detecta el formato en las operaciones de multifirma)</span>
		</div>
		
		<div>
		 <label for="algorithm">Algoritmo</label>
		 <select id="algorithm">
		 <option value="SHA1withRSA">SHA1 con RSA</option>
		 <option value="SHA256withRSA">SHA256 con RSA</option>
		 <option value="SHA512withRSA" selected>SHA512 con RSA</option>
		 </select>
		</div>

		<div>
		 <label for="newParam">ExtraParams</label>
		 <input id="newParam" type="text"><input type="button" value="Agregar" onclick="addExtraParam(document.getElementById('newParam').value);">&nbsp;
		 <input type="button" value="Limpiar" onclick="cleanExtraParams();">&nbsp;
		 <span>(Insertar las propiedades de una en una)</span>
		 <br>
		<textarea id="params" cols="50" rows="5" readonly></textarea>
		<br>
		<br>
		<input id="sticky" type="checkbox" onchange="setStickySignature();">Sticky Signature
		</div>
		</fieldset>
		<br/>

		<input type="button" value="Firmar" onclick="doSign();">&nbsp;
		<input type="button" value="Firmar Batch" onclick="doSignBatch();">&nbsp;
		<input type="button" value="Cofirmar" onclick="doCoSign();">&nbsp;
		<input type="button" value="Contrafirmar" onclick="doCounterSign();">
		<input type="button" value="Seleccionar certificado" onclick="doSelectCert();">
		<input type="button" value="Firmar y guardar" onclick="doSignAndSave('sign');">
		<input type="button" value="Cofirmar y guardar" onclick="doSignAndSave('cosign');">
		<input type="button" value="Contrafirmar y guardar" onclick="doSignAndSave('countersign');">
		<input type="button" value="Mostrar Log" onclick="showAppletLog();">
		<input type="button" value="Descargar y firmar" onclick="downloadAndSign();">
		<br/>
		<br/>
		
		<div>
		 <span>Consola</span>
		 <br>
		 <textarea id="console" cols="150" rows="10"  style="max-width: 100%;">
		 </textarea>
		</div>
		
		<div>
		 <span>Resultado</span>
		 <br>
		 <textarea id="result" cols="150" rows="10" style="max-width: 100%;">
		 </textarea>
		</div>
		<input type="button" value="Guardar" onclick="saveSignature();">
		
		<script>
			var paramsElement = document.getElementById("params");
			paramsElement.innerHTML = "serverUrl=" + Constants.URL_BASE_SERVICES + "/afirma-server-triphase-signer/SignatureService";
		</script>
	</body>
</html>	