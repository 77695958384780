import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TransferenciaService } from 'src/app/_services/autoescuela/transferencia.service';
import { CalculadoraitpService } from 'src/app/_services/hencok/calculadoraItpservice';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { Direccione } from 'src/app/model/third_model';

import { finalize } from 'rxjs';
import { PresupuestoService } from 'src/app/_services/autoescuela/presupuesto.service';
import { EntidadTransferencia } from 'src/app/model/autoescuela/entidad_transferencia';
import { VehiculoTransderencia } from 'src/app/model/autoescuela/vehiculo_transferencia';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
@Component({
  selector: 'app-formulario-tramite',
  templateUrl: './formulario-tramite.component.html',
  styleUrls: ['./formulario-tramite.component.css']
})
export class FormularioTramiteComponent {
  isAdmin = false;
  isMod=false;
  comprador: EntidadTransferencia = new EntidadTransferencia()
  vehiculo: VehiculoTransderencia = new VehiculoTransderencia()
  vendedor: EntidadTransferencia = new EntidadTransferencia()

  @Input() direccion?: Direccione | undefined;
  @Output() newItemEvent = new EventEmitter<Boolean>();
  usuario = this.token.getId();
  idTransf
  isUpdated: boolean = false;
  iscomprador: boolean=true;
  transferenciaUpadte
  submitted: boolean = false;
  ListMunicipios: any = [];
  ListMunicipiosVendedor: any = [];
  ListComunidades: any = [];
  ListVias: any = [];
  ListProvincias: any = [];
  ListProvinciasVendedor: any = [];
  isEmpresa: boolean = true;
  isEmpresaVendedor: boolean = true;
  isMoto: boolean = false;
  isHistorico: boolean = false;
  isEconomicas: boolean = false;
  hayFact: boolean = false;
  isEmbargo: boolean = false;
  isHerencia: boolean = false;
  ListModelos: any = [];
  ListMarcas: any = [];
  ListCombustibles: any = [];
  isRequired: boolean = false;
  marca: string = "";
  listTipo = [{
    id: "1",
    nombre: "Empresa"
  }, {
    id: "2",
    nombre: "Particular"
  },]
  formCliente2: any;
  @Input() formCliente: any;
  @Input() formVehiculo: any;
  presupuesto: boolean = false;

  form: UntypedFormGroup = new UntypedFormGroup({

    usuario: new UntypedFormControl(this.usuario),

    tipoEntidad: new UntypedFormControl(this.listTipo[1]["id"]),
    nombre: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", Validators.required),
    apellido1: new UntypedFormControl("", Validators.required),
    apellido2: new UntypedFormControl(""),
    numero_documento: new UntypedFormControl("", Validators.required),
    fecha_nacimiento: new UntypedFormControl(""),
    validez_dni: new UntypedFormControl(""),
    telefono: new UntypedFormControl("", Validators.required),
    tipo_via: new UntypedFormControl("", Validators.required),
    nombre_via: new UntypedFormControl("", Validators.required),
    numero_via: new UntypedFormControl("", Validators.required),
    bloque: new UntypedFormControl(""),
    escalera: new UntypedFormControl(""),
    piso: new UntypedFormControl(""),
    puerta: new UntypedFormControl(""),
    km: new UntypedFormControl(""),
    ccaa: new UntypedFormControl("", Validators.required),
    provincia: new UntypedFormControl("", Validators.required),
    municipio: new UntypedFormControl("", Validators.required),
    codigo_postal: new UntypedFormControl("", Validators.required),
    nombre_empresa: new UntypedFormControl(""),
    cif_empresa: new UntypedFormControl(""),

    tipoEntidadVendedor: new UntypedFormControl(this.listTipo[1]["id"]),
    nombreVendedor: new UntypedFormControl("", Validators.required),
    emailVendedor: new UntypedFormControl("", Validators.required),
    apellido1Vendedor: new UntypedFormControl("", Validators.required),
    apellido2Vendedor: new UntypedFormControl(""),
    numero_documentoVendedor: new UntypedFormControl("", Validators.required),
    fecha_nacimientoVendedor: new UntypedFormControl(""),
    validez_dniVendedor: new UntypedFormControl(""),
    telefonoVendedor: new UntypedFormControl("", Validators.required),
    tipo_viaVendedor: new UntypedFormControl("", Validators.required),
    nombre_viaVendedor: new UntypedFormControl("", Validators.required),
    numero_viaVendedor: new UntypedFormControl("", Validators.required),
    bloqueVendedor: new UntypedFormControl(""),
    escaleraVendedor: new UntypedFormControl(""),
    pisoVendedor: new UntypedFormControl(""),
    puertaVendedor: new UntypedFormControl(""),
    kmVendedor: new UntypedFormControl(""),
    ccaaVendedor: new UntypedFormControl("", Validators.required),
    provinciaVendedor: new UntypedFormControl("", Validators.required),
    municipioVendedor: new UntypedFormControl("", Validators.required),
    codigo_postalVendedor: new UntypedFormControl("", Validators.required),
    nombre_empresaVendedor: new UntypedFormControl(""),
    cif_empresaVendedor: new UntypedFormControl(""),

    tipoVehiculo: new UntypedFormControl(this.isMoto),
    fecha_matriculacion: new UntypedFormControl("", Validators.required),
    isHistorico: new UntypedFormControl(this.isHistorico),
    isEconomicas: new UntypedFormControl(this.isEconomicas),
    marca: new UntypedFormControl("", Validators.required),
    combustible: new UntypedFormControl("", Validators.required),
    modelo: new UntypedFormControl("", Validators.required),
    matricula: new UntypedFormControl("", Validators.required),
    bastidor: new UntypedFormControl("", Validators.required),
    kilometros: new UntypedFormControl("", Validators.required),
    valor: new UntypedFormControl("", Validators.required),
    cilindrada: new UntypedFormControl(""),
    hayFactura: new UntypedFormControl(this.hayFact),

    isHerencia: new UntypedFormControl(this.isHerencia),
    isEmbargo: new UntypedFormControl(this.isEmbargo),
  });






  constructor(
    private notificationSvc: NotificationService,
    private servicioGeneral: ServicioGeneral,
    private tramiteService: CalculadoraitpService,
    private servicioTransferencia: TransferenciaService,
    private presupuestoService: PresupuestoService,
    public router?: Router,
    public route?: ActivatedRoute,
    private token?: TokenStorageService
  ) { }

  ngOnInit() {

    if (this.token.getRol() == 3 || this.token.getRol() == 99) {
      this.isAdmin = true;
    }
    if (this.token.getRol() == 5 || this.token.getRol() == 6) {
      this.isMod = true;
    }

    this.formCliente2 = this.route.snapshot.paramMap
    this.formCliente2 = this.formCliente2.params

    if (this.formCliente2.AsociarTramite != undefined) {
      this.formCliente = this.formCliente2
    }

    if (this.formCliente2.AsociarTramite != undefined) {
      this.presupuesto = true
    }
    console.log(this.formCliente)
    if(this.formCliente==undefined){
    this.route.params.subscribe((params) => {
      this.idTransf = params["id"];
    })
  }

  

  console.log(this.idTransf)
    if (this.idTransf) {
     this.getTransferencia(this.idTransf)
     console.log(this.form)
      this.isUpdated = true
    }

    this.getVias();
    this.getComunidades();
    this.getMarcas();

  }


  ngOnChanges(changes: SimpleChanges) {

    if (this.formVehiculo != undefined) {
      this.form.controls["tipoVehiculo"].setValue(this.formVehiculo.tipo_vehiculo);
      this.form.controls["isHistorico"].setValue(this.formVehiculo.historico);
      this.form.controls["marca"].setValue(this.formVehiculo.marca);
      this.onChangeMarca2(this.formVehiculo.marca)
      this.form.controls["modelo"].setValue(this.formVehiculo.modelo);
    }
    if (this.formCliente != undefined) {
    
      this.presupuesto = true;
    }

  }
  getTransferencia(id) {
    this.servicioTransferencia.getTransferenciaId(id).subscribe((data) => {
      if (data == null) {
        this.notificationSvc.error(
          "Error",
          "Acceso no permitido",
          2000
        );
        if (!this.isAdmin) {
          this.router.navigate(['/client-autoescuelas-hencok/listado-tramites'])

        } else {
          this.router.navigate(['/admin-autoescuelas-hencok/listado-tramites'])

        }
      }
    
      this.comprador = data["comprador"];
      this.vendedor = data["vendedor"];
      this.vehiculo = data["vehiculo"];
      this.initiateForm()
    })

  }


  initiateForm() {

    this.getProvinciasByID(this.comprador.comunidadAutonoma.id, false)
    this.getProvinciasByID(this.vendedor.comunidadAutonoma.id, true)
    this.isEmpresa = true
    this.onChangeMarca2(this.vehiculo.vehiculoMaestro.marca)
    this.onChangeCombustibleActu(this.vehiculo.vehiculoMaestro.carburante.id)


    if (this.comprador.tipoEntidad.id == 1) this.isEmpresa = false
    if (this.vendedor.tipoEntidad.id == 1) this.isEmpresaVendedor = true
    if (this.vehiculo.tipoVehiculo.id == 1) this.isMoto = true



    this.form = new UntypedFormGroup({
      usuario: new UntypedFormControl(this.usuario),
      tipoEntidad: new UntypedFormControl(this.comprador.tipoEntidad.id),
      nombre: new UntypedFormControl(this.comprador.nombre, Validators.required),
      email: new UntypedFormControl(this.comprador.email, Validators.required),
      apellido1: new UntypedFormControl(this.comprador.apellido1, Validators.required),
      apellido2: new UntypedFormControl(this.comprador.apellido2, Validators.required),
      numero_documento: new UntypedFormControl(this.comprador.dni, Validators.required),
      fecha_nacimiento: new UntypedFormControl(undefined, Validators.required),
      validez_dni: new UntypedFormControl(undefined, Validators.required),
      telefono: new UntypedFormControl(this.comprador.telefono + "", Validators.required),
      tipo_via: new UntypedFormControl(this.comprador.tipoVia.id, Validators.required),
      nombre_via: new UntypedFormControl(this.comprador.nombreVia, Validators.required),
      numero_via: new UntypedFormControl(this.comprador.numeroVia.toString(), Validators.required),
      bloque: new UntypedFormControl(this.comprador.bloque + ""),
      escalera: new UntypedFormControl(this.comprador.escalera),
      piso: new UntypedFormControl(this.comprador.planta),
      puerta: new UntypedFormControl(this.comprador.puerta),
      km: new UntypedFormControl(this.comprador.km),
      ccaa: new UntypedFormControl(this.comprador.comunidadAutonoma.id, Validators.required),
      provincia: new UntypedFormControl(this.comprador.provincia.id, Validators.required),
      municipio: new UntypedFormControl("", Validators.required),
      codigo_postal: new UntypedFormControl(this.comprador.codigoPostal, Validators.required),
      nombre_empresa: new UntypedFormControl(this.comprador.nombreEmpresa),
      cif_empresa: new UntypedFormControl(this.comprador.cif),

      tipoEntidadVendedor: new UntypedFormControl(this.vendedor.tipoEntidad.id),
      nombreVendedor: new UntypedFormControl(this.vendedor.nombre, Validators.required),
      emailVendedor: new UntypedFormControl(this.vendedor.email, Validators.required),
      apellido1Vendedor: new UntypedFormControl(this.vendedor.apellido1, Validators.required),
      apellido2Vendedor: new UntypedFormControl(this.vendedor.apellido2, Validators.required),
      numero_documentoVendedor: new UntypedFormControl(this.vendedor.dni, Validators.required),
      fecha_nacimientoVendedor: new UntypedFormControl(undefined, Validators.required),
      validez_dniVendedor: new UntypedFormControl(undefined, Validators.required),
      telefonoVendedor: new UntypedFormControl(this.vendedor.telefono + "", Validators.required),
      tipo_viaVendedor: new UntypedFormControl(this.vendedor.tipoVia.id, Validators.required),
      nombre_viaVendedor: new UntypedFormControl(this.vendedor.nombreVia, Validators.required),
      numero_viaVendedor: new UntypedFormControl(this.vendedor.numeroVia.toString(), Validators.required),
      bloqueVendedor: new UntypedFormControl(this.vendedor.bloque),
      escaleraVendedor: new UntypedFormControl(this.vendedor.escalera),
      pisoVendedor: new UntypedFormControl(this.vendedor.planta),
      puertaVendedor: new UntypedFormControl(this.vendedor.puerta),
      kmVendedor: new UntypedFormControl(this.vendedor.km),
      ccaaVendedor: new UntypedFormControl(this.vendedor.comunidadAutonoma.id, Validators.required),
      provinciaVendedor: new UntypedFormControl(this.vendedor.provincia.id, Validators.required),
      municipioVendedor: new UntypedFormControl("", Validators.required),
      codigo_postalVendedor: new UntypedFormControl(this.vendedor.codigoPostal, Validators.required),
      nombre_empresaVendedor: new UntypedFormControl(this.vendedor.nombreEmpresa),
      cif_empresaVendedor: new UntypedFormControl(this.vendedor.cif),

      tipoVehiculo: new UntypedFormControl(this.isMoto),
      fecha_matriculacion: new UntypedFormControl(this.formatDate(this.vehiculo.fechaMatriculacion)),
      isHistorico: new UntypedFormControl(this.vehiculo.historico),
      isEconomicas: new UntypedFormControl(this.vehiculo.ativEconomicas),
      marca: new UntypedFormControl(this.vehiculo.vehiculoMaestro.marca, Validators.required),
      combustible: new UntypedFormControl(this.vehiculo.vehiculoMaestro.carburante.id, Validators.required),
      modelo: new UntypedFormControl(this.vehiculo.vehiculoMaestro.modelo, Validators.required),
      matricula: new UntypedFormControl(this.vehiculo.matricula, Validators.required),
      bastidor: new UntypedFormControl(this.vehiculo.bastidor, Validators.required),
      kilometros: new UntypedFormControl(this.vehiculo.km.toString(), Validators.required),
      valor: new UntypedFormControl(this.vehiculo.precio.toString(), Validators.required),
      hayFactura: new UntypedFormControl(this.vehiculo.hayFactura),

      isHerencia: new UntypedFormControl(this.vehiculo.herencia),
      isEmbargo: new UntypedFormControl(this.vehiculo.embargo),
    }, { updateOn: 'change' });

    if(this.comprador.tipoEntidad.id == 1){
      this.form.get('nombre_empresa').addValidators(Validators.required);
      this.form.get('cif_empresa').addValidators(Validators.required);

      this.form.get('fecha_nacimiento').removeValidators(Validators.required);
      this.form.get('validez_dni').removeValidators(Validators.required);

      this.form.controls['fecha_nacimiento'].setErrors(null);
      this.form.controls['validez_dni'].setErrors(null);
    }
    else{
      this.form.controls['fecha_nacimiento'].setValue(this.formatDate(this.comprador.fechaNacimiendo))
      this.form.controls['validez_dni'].setValue(this.formatDate(this.comprador.validezDni))
    }

    if(this.vendedor.tipoEntidad.id == 1){
      this.form.get('nombre_empresaVendedor').addValidators(Validators.required);
      this.form.get('cif_empresaVendedor').addValidators(Validators.required);

      this.form.get('fecha_nacimientoVendedor').removeValidators(Validators.required);
      this.form.get('validez_dniVendedor').removeValidators(Validators.required);

      this.form.controls['fecha_nacimientoVendedor'].setErrors(null);
      this.form.controls['validez_dniVendedor'].setErrors(null);
    }
    else{
      this.form.controls['fecha_nacimientoVendedor'].setValue(this.formatDate(this.vendedor.fechaNacimiendo))
      this.form.controls['validez_dniVendedor'].setValue(this.formatDate(this.vendedor.validezDni))
    }

    console.log(this.form)

    this.getMunicipiosByID(this.comprador.provincia.id + "", false, true)
    this.getMunicipiosByID(this.vendedor.provincia.id + "", true, true)

  }
  getComunidades() {
    return this.servicioGeneral.getComunidades().subscribe((data) => {
      this.ListComunidades = data['ComunidadAutonoma'];
    });
  }
  getVias() {
    return this.servicioGeneral.getTipoVias().subscribe((data) => {
      this.ListVias = data['TipoVia'];
    });
  }
  getMarcas() {
    return this.tramiteService.listMarcas().subscribe((data) => {
      this.ListMarcas = data;
    });

  }

  onChangeProvincia(event: Event, isVendedor) {
    if (isVendedor) {
      this.ListProvinciasVendedor = [];
    } else {
      this.ListProvincias = [];

    }
    const id = (event.target as HTMLInputElement).value;
    this.getProvinciasByID(id, isVendedor);
  }
  onChangeMunicipio(event: Event, isVendedor) {
    if (isVendedor) {
      this.ListMunicipiosVendedor = [];
    } else {
      this.ListMunicipios = [];

    }
    const id = (event.target as HTMLInputElement).value;
    this.getMunicipiosByID(id, isVendedor);
  }
  onChangeCombustible(combustible) {
    this.ListModelos = []
    if(!this.isMoto){
    return this.tramiteService.listModelosByMarcaAndComb(this.marca, (combustible.target as HTMLInputElement).value).subscribe((data) => {
      this.ListModelos = data;
    });
    }
    return undefined;
  }
  onChangeCombustibleActu(combustible) {
    console.log(combustible)
    console.log(this.marca)
    this.ListModelos = []
    if(!this.isMoto){
      return this.tramiteService.listModelosByMarcaAndComb(this.marca, combustible).subscribe((data) => {
        this.ListModelos = data;
        console.log(data)
      });
    }
    return undefined;
  }



  onChangeMarca(marca: Event) {


    this.ListCombustibles = []
    this.ListModelos = []
    this.marca = (marca.target as HTMLInputElement).value;
    return this.tramiteService.listCombustibles(this.marca).subscribe((data) => {
      this.ListCombustibles = data
      if(this.formVehiculo!= undefined){
      this.form.controls["combustible"].setValue(this.sacaridCombustible(this.formVehiculo.combustible));
      }

    });
  }

  onChangeMarca2(marca) {

    this.marca = marca
    return this.tramiteService.listCombustibles(this.marca).pipe(
      finalize(() => {
        this.form.controls["combustible"].setValue(this.sacaridCombustible(this.formVehiculo.combustible));

        this.tramiteService.listModelosByMarcaAndComb(this.marca, this.sacaridCombustible(this.formVehiculo.combustible)).subscribe((data) => {
          this.ListModelos = data;
        });

      })
    ).subscribe((data) => {
      this.ListCombustibles = data
    });
  }

  getMunicipiosByID(id: string, isVendedor, first?) {

    this.servicioGeneral.getMunicipios().pipe(
      finalize(() => {

        if(this.iscomprador){
        this.form.controls["municipio"].setValue( this.sacaridMunicipio(this.formCliente.localidad));
       }
        else{
        this.form.controls["municipioVendedor"].setValue( this.sacaridMunicipio(this.formCliente.localidad));
   }
       })) .subscribe((data) => {
      data['Municipio'].forEach((municipio) => {
        if (municipio.municipioPK["provincia"] == id) {
          if (isVendedor) {
            if (first!=undefined && first==true && this.vendedor.localidad===municipio.nombre) {
              this.form.controls["municipioVendedor"].setValue(municipio.municipioPK['municipio_id']);
            }
            this.ListMunicipiosVendedor.push(municipio);
          } else {
            this.ListMunicipios.push(municipio);
            if (first!=undefined && first==true && this.comprador.localidad===municipio.nombre) {
              this.form.controls["municipio"].setValue(municipio.municipioPK['municipio_id']);
            }
          }

        }
      });
    });
  }
  getProvinciasByID(id: string, isVendedor) {
    this.servicioGeneral.getProvincias().pipe(
      finalize(() => {
        if(this.formCliente!=undefined){
          if(this.comprador){
         
            this.form.controls["provincia"].setValue(this.formCliente.provincia2);
            
          }else{
            this.form.controls["provinciaVendedor"].setValue(this.formCliente.provincia2);
          }}
                        
    })
  ).subscribe(
      (data) => {
        data['Provincia'].forEach((provincia) => {
          if (provincia.ccaa["id"] == id) {
            if (isVendedor) {
              this.ListProvinciasVendedor.push(provincia);
              return this.ListProvinciasVendedor;
            } else {
              this.ListProvincias.push(provincia);
              return this.ListProvincias;
            }

          }
        });
      });
  }


  isEmpresaOnchanged() {
    if(this.isEmpresa){
      this.isEmpresa=false;

      this.form.get('fecha_nacimiento').removeValidators(Validators.required);
      this.form.get('validez_dni').removeValidators(Validators.required);
      this.form.get('nombre_empresa').addValidators(Validators.required);
      this.form.get('cif_empresa').addValidators(Validators.required);

      this.form.controls['fecha_nacimiento'].setErrors(null);
      this.form.controls['validez_dni'].setErrors(null);

      this.form.controls['fecha_nacimiento'].setValue("")
      this.form.controls['validez_dni'].setValue("")

      console.log(this.form)
    }else{
      this.isEmpresa=true;

      this.form.get('fecha_nacimiento').addValidators(Validators.required);
      this.form.get('validez_dni').addValidators(Validators.required);
      this.form.get('nombre_empresa').removeValidators(Validators.required);
      this.form.get('cif_empresa').removeValidators(Validators.required);

      this.form.controls['nombre_empresa'].setErrors(null);
      this.form.controls['cif_empresa'].setErrors(null);

      this.form.controls['nombre_empresa'].setValue("");
      this.form.controls['cif_empresa'].setValue("");

      console.log(this.form)
    }

  }

  isEmpresaVendedorOnchanged() {
    if(this.isEmpresaVendedor==true){
      this.isEmpresaVendedor=false;


      this.form.get('fecha_nacimientoVendedor').removeValidators(Validators.required);
      this.form.get('validez_dniVendedor').removeValidators(Validators.required);
      this.form.get('nombre_empresaVendedor').addValidators(Validators.required);
      this.form.get('cif_empresaVendedor').addValidators(Validators.required);

      this.form.controls['fecha_nacimientoVendedor'].setErrors(null);
      this.form.controls['validez_dniVendedor'].setErrors(null);

      this.form.controls['fecha_nacimientoVendedor'].setValue("")
      this.form.controls['validez_dniVendedor'].setValue("")

      console.log(this.form)
    }else{

      this.form.get('fecha_nacimientoVendedor').addValidators(Validators.required);
      this.form.get('validez_dniVendedor').addValidators(Validators.required);
      this.form.get('nombre_empresaVendedor').removeValidators(Validators.required);
      this.form.get('cif_empresaVendedor').removeValidators(Validators.required);

      this.form.controls['nombre_empresaVendedor'].setErrors(null);
      this.form.controls['cif_empresaVendedor'].setErrors(null);

      this.form.controls['nombre_empresaVendedor'].setValue("");
      this.form.controls['cif_empresaVendedor'].setValue("");

      this.isEmpresaVendedor=true;
      console.log(this.form)
    }

  }

  isMotoOnchanged() {
    this.isMoto = !this.isMoto
    if(this.isMoto){
      this.form.get('marca').removeValidators(Validators.required);
      this.form.get('modelo').removeValidators(Validators.required);
      this.form.get('cilindrada').addValidators(Validators.required);

      this.form.controls['marca'].setErrors(null);
      this.form.controls['modelo'].setErrors(null);
    }
    else if(!this.isMoto){
      this.form.get('marca').addValidators(Validators.required);
      this.form.get('modelo').addValidators(Validators.required);
      this.form.get('cilindrada').removeValidators(Validators.required);
      this.form.controls['modelo'].setErrors(null);
    }
    this.tramiteService.listCarburantes().subscribe((data) => {
      this.ListCombustibles = data
    });
  }
  isEconomicasOnchanged() {
    this.isEconomicas = !this.isEconomicas
  }
  isHistoricoOnchanged() {
    this.isHistorico = !this.isHistorico
  }
  hayFactOnchanged() {
    this.hayFact = !this.hayFact;
  }
  isEmbargoOnchanged() {
    this.isEmbargo = !this.isEmbargo
  }
  isHerenciaOnchanged() {
    this.isHerencia = !this.isHerencia
  }

  async onSubmit(): Promise<any> {

    this.isRequired = true
    this.form.controls['hayFactura'].setValue(this.hayFact)
    this.form.controls['tipoVehiculo'].setValue(this.isMoto)

    console.log(this.form);

    this.submitted = true;
    var matricula = String(this.form.controls['matricula'].value).replace(/ /g, "");
    matricula = matricula.replace(/\//g, "");
    matricula = matricula.replace(/-/g, "");
    matricula = matricula.toLowerCase();
    
    console.log(matricula);

    var vin = String(this.form.controls['bastidor'].value).toUpperCase();


    if(matricula.match(/[a-z]{1,2}[0-9]{4}[a-z]{1,3}$|[0-9]{4}[b-df-hj-np-tv-z]{3}$|[a-z]{1,2}[0-9]{6}/)){
      matricula = matricula.toUpperCase();
      this.form.controls['matricula'].setValue(matricula)
      console.log("matches")
    }
    else{
      this.notificationSvc.warning('Información', 'La matrícula no es valida', 2000);
      this.form.controls['matricula'].setErrors({ 'incorrect': true });
      this.submitted = false;
      return false;
    }
    var re = new RegExp("^[A-HJ-NPR-Z\\d]{11}\\d{6}$");
    if(vin.match(re)){
      console.log("matchvin")
      this.form.controls['bastidor'].setValue(vin);
    }
    else{
      this.notificationSvc.warning('Información', 'El número de bastidor no es válido', 2000);
      this.form.controls['bastidor'].setErrors({ 'incorrect': true });
      this.submitted = false;
      return false;
    }

    if (!this.form.valid) {

      this.notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
      this.isRequired = true;
      return false;
    } else {
      if (this.isEmpresa) {
        if (this.form.get('nombre_empresa')?.value == undefined ||
          this.form.get('cif_empresa')?.value == undefined) {
          this.notificationSvc.warning('Información', 'Rellene los campos necesarios para empresas.', 2000);
          this.submitted = false;
        }
      }

      if (this.form.get('telefono').value.length != 9) {
        this.notificationSvc.error('Error', 'No es un teléfono válido.', 2000);

        return false;
      }
      if (this.form.get('codigo_postal').value.length != 5) {
        this.notificationSvc.error('Error', 'No es un código postal válido.', 2000);
        return false;
      }
      if (this.form.get('fecha_matriculacion').value == "") {
        this.notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
        this.isRequired = true;

        return false;
      }
    }
      if (this.form.valid) {
        if(!this.isUpdated){
        this.submitted = true;
        this.servicioTransferencia.saveTransferencia(this.form.value).subscribe({
          next: (data) => {
            if (this.presupuesto) {
              if (this.formCliente.AsociarTramite != undefined) {
                this.presupuestoService.asociarTramiteAPresupuesto
                  (data["id"], this.formCliente.AsociarTramite,2).subscribe(
                    (data) => { this.notificationSvc.success('Exito', "Transferencia asociada", 2000); }
                    , (error) => { console.log(error)}
                  )
              }
            }

            this.notificationSvc.success('Exito', "Transferencia creada", 2000);
            if (!this.isAdmin) {
              this.router.navigate(['/client-autoescuelas-hencok/transferencia/' + data["id"]])

            } else {
              this.router.navigate(['admin-autoescuelas-hencok/transferencia/' + data["id"]])
            }
            this.submitted = false;
            this.isRequired = false;

          },
          error: (err) => {

            if (err.error.status == 644) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
            } else if (err.error.status == 627) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['email'].setErrors({ 'incorrect': true });
            }
            else {
              this.notificationSvc.error('ERROR', err.error + "", 2000);
            }
            this.submitted = false;
          }
        })
      } else {
        this.servicioTransferencia.updateTransferencia(this.form.value, this.idTransf).subscribe({
          next: (data) => {
            this.notificationSvc.success('Exito', "Transferencia creada", 2000);
            this.newItemEvent.emit(false);
            if (!this.presupuesto) {
              if (!this.isAdmin) {
                this.router.navigate(['/client-autoescuelas-hencok/transferencia/' + data["id"]])

              } else {
                this.router.navigate(['admin-autoescuelas-hencok/transferencia/' + data["id"]])
              }
            }
            this.submitted = false;
            this.isRequired = false;

          },
          error: (err) => {

            if (err.error.status == 644) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
            } else if (err.error.status == 627) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['email'].setErrors({ 'incorrect': true });
            }
            else {
              this.notificationSvc.error('ERROR', err.error + "", 2000);
            }
            this.submitted = false;
          }
        })
      }


    }


  }



  seleccionarCompradorVendedor(event: Event) {
    const valorSeleccionado = (event.target as HTMLInputElement).value;
 
    if (valorSeleccionado === '1') {
      this.setComprador();
    } else if (valorSeleccionado === '2') {
      this.setVendedor();
    }
  }
  setComprador() {
    this.iscomprador = true
    if (!this.formCliente.cif || this.formCliente.cif == "" || this.formCliente.cif == "null") {
      this.isEmpresa = true
      this.form.controls["tipoEntidad"].setValue(2)

    } else {
      this.isEmpresa = false
      this.form.controls["tipoEntidad"].setValue(1)
      this.form.controls["nombre_empresa"].setValue(this.formCliente.nombreEmpresa);
      this.form.controls["cif_empresa"].setValue(this.formCliente.cif)
    }

    this.form.controls["nombre"].setValue(this.formCliente.nombre);

    this.form.controls["apellido1"].setValue(this.formCliente.apellido1);
    this.form.controls["apellido1"].setValue(this.formCliente.apellido1);
    this.form.controls["apellido2"].setValue(this.formCliente.apellido2);

    this.form.controls["numero_documento"].setValue(this.formCliente.dni);
    this.form.controls["fecha_nacimiento"].setValue(this.formCliente.fechaNacimiendo);
    this.form.controls["validez_dni"].setValue(this.formCliente.validezDni);

    this.form.controls["telefono"].setValue(this.formCliente.telefono);
    this.form.controls["email"].setValue(this.formCliente.email);

    this.form.controls["tipo_via"].setValue(this.formCliente.tipoVia2);
    this.form.controls["nombre_via"].setValue(this.formCliente.nombreVia);
    this.form.controls["numero_via"].setValue(this.formCliente.numeroVia);

    this.form.controls["bloque"].setValue(this.formCliente.bloque == "null" ? "" : this.formCliente.bloque);
    this.form.controls["escalera"].setValue(this.formCliente.escalera == "null" ? "" : this.formCliente.escalera);
    this.form.controls["piso"].setValue(this.formCliente.planta == "null" ? "" : this.formCliente.planta);
    this.form.controls["puerta"].setValue(this.formCliente.puerta == "null" ? "" : this.formCliente.puerta);
    this.form.controls["km"].setValue(this.formCliente.km == "null" ? "" : this.formCliente.km);
    this.form.controls["codigo_postal"].setValue(this.formCliente.codigoPostal);
    this.form.controls["ccaa"].setValue(this.formCliente.comunidadAutonoma);
    this.getProvinciasByID(this.formCliente.comunidadAutonoma, false)
    this.form.controls["provincia"].setValue("this.formCliente.provincia");
    this.getMunicipiosByID(this.formCliente.provincia2, false)



  }

  setVendedor() {
    this.iscomprador = false
    if (!this.formCliente.cif || this.formCliente.cif == "" || this.formCliente.cif == "null") {
      this.isEmpresaVendedor = true
      this.form.controls["tipoEntidadVendedor"].setValue(2)

    } else {
      this.isEmpresaVendedor = false
      this.form.controls["tipoEntidadVendedor"].setValue(1)
      this.form.controls["nombre_empresaVendedor"].setValue(this.formCliente.nombreEmpresa);
      this.form.controls["cif_empresaVendedor"].setValue(this.formCliente.cif)
    }
    this.form.controls["nombreVendedor"].setValue(this.formCliente.nombre);
    this.form.controls["apellido1Vendedor"].setValue(this.formCliente.apellido1);
    this.form.controls["apellido1Vendedor"].setValue(this.formCliente.apellido1);
    this.form.controls["apellido2Vendedor"].setValue(this.formCliente.apellido2);
    this.form.controls["numero_documentoVendedor"].setValue(this.formCliente.dni);
    this.form.controls["fecha_nacimientoVendedor"].setValue(this.formCliente.fechaNacimiendo);
    this.form.controls["validez_dniVendedor"].setValue(this.formCliente.validezDni);
    this.form.controls["telefonoVendedor"].setValue(this.formCliente.telefono);
    this.form.controls["emailVendedor"].setValue(this.formCliente.email);

    this.form.controls["tipo_viaVendedor"].setValue(this.formCliente.tipoVia2);
    this.form.controls["nombre_viaVendedor"].setValue(this.formCliente.nombreVia);
    this.form.controls["numero_viaVendedor"].setValue(this.formCliente.numeroVia);

    this.form.controls["bloqueVendedor"].setValue(this.formCliente.bloque == "null" ? "" : this.formCliente.bloque);
    this.form.controls["escaleraVendedor"].setValue(this.formCliente.escalera == "null" ? "" : this.formCliente.escalera);
    this.form.controls["pisoVendedor"].setValue(this.formCliente.planta == "null" ? "" : this.formCliente.planta);
    this.form.controls["puertaVendedor"].setValue(this.formCliente.puerta == "null" ? "" : this.formCliente.puerta);
    this.form.controls["kmVendedor"].setValue(this.formCliente.km == "null" ? "" : this.formCliente.km); this.form.controls["codigo_postalVendedor"].setValue(this.formCliente.codigoPostal);
    this.form.controls["ccaaVendedor"].setValue(this.formCliente.comunidadAutonoma);
    this.getProvinciasByID(this.formCliente.comunidadAutonoma, true)
    this.form.controls["provinciaVendedor"].setValue(this.formCliente.provincia2);
    this.getMunicipiosByID(this.formCliente.provincia2, true)
    // this.form.controls["municipioVendedor"].setValue( this.sacaridMunicipio(this.formCliente.localidad));

  }


  sacaridCombustible(nombre: String): String {
    var id: String = "";
    this.ListCombustibles.forEach(element => {
      if (element.tipo == nombre) {
        id = element.id;
      }
    });
    return id;
  }

  sacaridMunicipio(nombre: String){

    var id: number = 0;
    for(let i = 0; i < this.ListMunicipios.length ; i++){
      if (this.ListMunicipios.at(i).nombre == nombre) {
        id = this.ListMunicipios.at(i).municipioPK.municipio_id;
      }
    }
    return id;
  }


  formatDate(date) {
    var date2 = []
    date2 = date.split("-");
    let dia = date2[0];
    let mes = date2[1];
    let anio = date2[2];
    return anio + "-" + mes + "-" + dia;
  }


  setMostrarFormularioChild() {
    this.newItemEvent.emit(false);
  }
}