import { Provincia } from "../provincia";
import { Ccaa, TypeOfRoad } from "../usuario.data.model";


export class EntidadTransferencia {
    id?: number;
    nombre: String;
    apellido1: String;
    apellido2: String;
    bloque?: String;
    codigoPostal: String;
    dni: String;
    email: String;
    fechaNacimiendo: String;
    km: String;
    localidad: String;
    nombreVia: String;
    numeroVia: String;
    telefono: Number;
    validezDni: String;
    provincia: Provincia;
    tipoEntidad: Entidad;
    comunidadAutonoma: Ccaa;
    escalera?: String;
    planta?: Number
    puerta?: String;
    nombreEmpresa?: String;
    cif?: String;
    tipoVia: TypeOfRoad;
    
}
export class Entidad {
    id: number;
    nombre: string;
}