<nav id="sidebar_detalle_notificacion">
    <div class="fixed">
        <app-detalles-notificaciones [notificacion]="notificacion"></app-detalles-notificaciones>
    </div>
</nav>


<div (click)="closeSidebar($event)">
<div class=" flex-row justify-content-between align-items-center full-form-group" >
    <h1 class="m-0">Notificaciones</h1>

</div>

<div class="full-form-group">
    <hr class="linea-divisoria">
    <div class="overlay" *ngIf="popup">
        <div class="popup">
            <h2>{{titulo}}</h2>
            <a class="close" (click)="popup = false">&times;</a>
            <div class="content">
              {{descripcion}}
            </div>
        </div>
      </div>
    <div>
        <div>
            <div>
            </div>
            <div class="w-100 border-bottom" *ngIf="!userAdmin">
                <table class="w-100">
                    <tr class="w-100">
                        <th class="card-width-cuerpo-bar">NOTIFICACIÓN</th>
                        <th class="card-width-notifica-bar">FECHA CREACIÓN
                        </th>

                    </tr>
                </table>
            </div>
            <div class="w-100 border-bottom" *ngIf="userAdmin">
                <table class="w-100">
                    <tr class="w-100">
                        <th class="card-width-cuerpo-bar w-25 pl-2">ASUNTO</th>
                        <th class="card-width-cuerpo-bar w-50 pl-2">CUERPO</th>
                        <th class="card-width-cuerpo-bar w-20 pl-5">FECHA CREACIÓN</th>

                        <th class="card-width-cuerpo-bar w-5  pr-3" >VISUALIZAR</th>

                    </tr>
                </table>
            </div>
            <div class="card w-100 card-generic border-0">
                <div *ngIf="notificaciones?.length == 0"

                class="d-flex flex-row justify-content-center empty-data-message my-4">
    
                <span>No se han encontrado registros que mostrar</span>
    
            </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item p-3 d-flex border rounded shadow"
                        *ngFor="let notificacion of notificaciones | filterUser : searchText">


                    <div *ngIf="!userAdmin" class="row w-100 ">
                        <div class="card border-0 col-sm-9">
                             {{notificacion.cuerpo}}
                        </div>

                        <div class="card border-0 pl-5 ml-5">
                            {{formatDate(notificacion.fechaCreacion)}}
                        </div>
                    </div>   
                    <div class=" row w-100" *ngIf="userAdmin">
                        <div class="card border-0 card-width-cuerpo w-25 ">
                            {{notificacion.asunto}}
                       </div>
                        <div class="card border-0 card-width-cuerpo w-50">
                             {{notificacion.cuerpo}}
                        </div>

                        <div class="card border-0 card-width-notifica w-20">
                            {{formatDate(notificacion.fechaCreacion)}}
                        </div>
                      <div class="buttons-flex-container ml-4 pl-2 w-5" >
                        <a (click)="doPopUp(notificacion);$event.stopPropagation();$event.preventDefault()" class="eye-button activated"></a>
                      </div>
                    </div> 




                    </li>
                </ul>
           
            </div>
    
        </div>
    </div>
</div>

</div>