<div class="d-flex flex-row justify-content-center align-items-center full-form-group my-5 text-center">
    <h1 class="m-0" style="color:#275B55;">Cumplimentar Factura</h1>
</div>
<div>
    <hr class="linea-divisoria">
</div>
<div class="container mb-5" *ngIf="mostrarPagina">
    <table class="mb-5" *ngIf="size()">
        <tr>
            <th class="">Email</th>
            <th class="">Tipo de pago</th>
            <th class="">Fecha factura</th>
            <th class="">Fecha Venciminento</th>
        </tr>
        <tr>
            <td class="">{{email}}</td>
            <td class="" *ngIf="factura.tipoGasto.id == 1">Efectivo</td>
            <td class="" *ngIf="factura.tipoGasto.id == 2">Tarjeta</td>
            <td class="">{{fechaFactura}}</td>
            <td class="">{{fechaVencimiento}}</td>
        </tr>
    </table>
    <table class="w-100 mx-1" *ngIf="!size()" style="overflow-x: hidden;">
        <tr class="row">
            <th class="col-4 col-md-3">Email</th>
            <td class="col-8 col-md-3" >{{emailSplit}}</td>
        </tr>
        <tr class="row">
            <th class="col-4 col-md-3">Tipo de pago</th>
            <td class="col-8 col-md-3" *ngIf="factura.tipoGasto.id == 1">Efectivo</td>
            <td class="col-8 col-md-3" *ngIf="factura.tipoGasto.id == 2">Tarjeta</td>
        </tr>
        <tr class="row">
            <th class="col-4 col-md-3">Fecha factura</th>
            <td class="col-8 col-md-3">{{fechaFactura}}</td>
        </tr>
        <tr class="row">
            <th class="col-4 col-md-3">Fecha Venciminento</th>
            <td class="col-8 col-md-3">{{fechaVencimiento}}</td>
        </tr>
    </table>

    <form [formGroup]="formFactura" (ngSubmit)="onSubmitFacturas()" *ngIf="mostrarPagina">
        <div class="container px-auto px-md-5 py-5">
            <div class="row mt-2  align-items-center">
                <div class="col-6 col-sm-4">
                    <p class="m-1">Nombre o Razón Social</p>
                    <input type="text" placeholder="Nombre" class="form-control mb-2" formControlName="nombre"
                        [ngClass]="{'is-invalid': submitForm && this.formFactura.controls['nombre'].errors}">
                </div>
                <div class="col-6 col-sm-4">
                    <p class="m-1">CIF/NIF</p>
                    <input type="text" class="form-control mb-2" formControlName="cif" placeholder="CIF/NIF"
                        [ngClass]="{'is-invalid': submitForm && this.formFactura.controls['cif'].errors}">
                </div>
                <div class="col-6 col-sm-4">
                    <p class="m-1">Domicilio</p>
                    <input type="text" class="form-control mb-2" formControlName="domicilio" placeholder="Domicilio"
                        [ngClass]="{'is-invalid': submitForm && this.formFactura.controls['domicilio'].errors}">
                </div>
                <div class="col-6 col-sm-4">
                    <p class="m-1">Código Postal</p>
                    <input type="text" class="form-control mb-2" formControlName="codigo_postal"
                        placeholder="Código Postal"
                        [ngClass]="{'is-invalid': submitForm && this.formFactura.controls['codigo_postal'].errors}">
                </div>
                <div class="col-6 col-sm-4">
                    <p class="m-1">Localidad</p>
                    <input type="text" class="form-control mb-2" formControlName="localidad" placeholder="Localidad"
                        [ngClass]="{'is-invalid': submitForm && this.formFactura.controls['localidad'].errors}">
                </div>
                <div class="col-6 col-sm-4 mt-3">
                    <button type="submit" class="btn btn-primary mt-2" style="height: 48px; width: 100%;">
                        <mat-icon class="align-middle">check</mat-icon><span class="align-middle">Aceptar</span>
                    </button>
                </div>
            </div>

        </div>
    </form>

    <div class="col-12 pt-2">
            <div class="row d-none d-md-flex " style="width: 100%;">
                <div class="col-md-2">
                    <p><b>Concepto</b></p>
                </div>
                <div class="col-md-2">
                    <p><b>Cantidad</b></p>
                </div>
                <div class="col-md-2">
                    <p><b>Precio</b></p>
                </div>
                <div class="col-md-2">
                    <p><b>Descuento</b></p>
                </div>
                <div class="col-md-2">
                    <p><b>Cantidad neta</b></p>
                </div>
                <div class="col-md-2">
                    <p><b>Tipo de IVA/VAT</b></p>
                </div>
                <div class="col-12" >
                    <div class="row" *ngFor="let detalle of detalles">
                        <div class="col-md-2">
                            <p>{{detalle.concepto}}</p>
                        </div>
                        <div class="col-md-2">
                            <p>{{detalle.cantidad}}</p>
                        </div>
                        <div class="col-md-2">
                            <p>{{detalle.precio}}</p>
                        </div>
                        <div class="col-md-2">
                            <p>{{detalle.descuento}}</p>
                        </div>
                        <div class="col-md-2">
                            <p>{{detalle.cantidadNeta}}</p>
                        </div>
                        <div class="col-md-2">
                            <p>{{detalle.tipoIva}}</p>
                        </div>
                    </div>
                </div>
            </div>
        <div class="row d-flex d-md-none justify-content-center" *ngFor="let detalle of detalles">
                <button class="col-6 btn my-2" style="color:white; background-color: #3E8E85; font-weight: bold;" (click)="showDetalles(detalle, $event)">{{detalle.concepto}}</button>


        </div>
    </div>
    <hr class="linea-divisoria">
    <div class="col-sm-12 pt-2 mt-4">
        <div class="row">
            <div class="col-8 col-sm-10 d-grid gap-2 d-flex justify-content-end"><p>Subtotal</p></div>
            <div class="col-4 col-sm-2 d-grid gap-2 d-flex justify-content-end"><p>€ {{factura.subtotal}}</p></div>
            <div class="col-8 col-sm-10 d-grid gap-2 d-flex justify-content-end"><p>IVA</p></div>
            <div class="col-4 col-sm-2 d-grid gap-2 d-flex justify-content-end"><p>€ {{factura.importeIva}}</p></div>
            <div class="col-8 col-sm-10 d-grid gap-2 d-flex justify-content-end"><p><b>Cantidad total</b></p></div>
            <div class="col-4 col-sm-2 d-grid gap-2 d-flex justify-content-end"><p><b>€ {{factura.cantidadTotal}}</b></p></div>

        </div>
    </div>
</div>

<div *ngIf="mostrarPagina == false" class="text-center mt-5">
    <p class="mt-5" style="font-size: medium;">Los datos se han cumplimentado con éxito.</p>
    <p class="mt-5" style="font-size: large; color: green;">En breves momentos se decargará la factura.</p>
</div>