<nav id="sidebar">
    <div class="fixed">
        <app-register (newItemEvent)="closeReload($event)"></app-register>
    </div>
</nav>

<nav id="sidebar_detalle">
    <div class="fixed">
        <app-formulario-nuevo-cliente (newItemEvent)="closeReload($event)"></app-formulario-nuevo-cliente>
    </div>
</nav>
<div class="d-flex flex-row justify-content-between align-items-center full-form-group">
    <h1 class="m-0">Gestión de Clientes</h1>
    <div>
        <button class="btn btn-primary align-middle" (click)="userAdmin ? openSidebar() : openSidebarCliente()">
            <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo Cliente</span>
        </button>

    </div>
</div>

<div class="full-form-group">
    <div>
        <div class="subtitle">
            <h5>Listado de todos los clientes</h5>
        </div>
        <div (click)="userAdmin ? closeSidebar() : closeSidebarCliente()">

            <div class="w-100 border-bottom columnFilter tableHead">
                <table class="w-100">
                    <tr class="w-100">
                        <th [ngClass]="adminAuto ? 'card-width-name-admin-auto': 'card-width-name'">
                            <div class="filter-container">
                                <input
                                    type="text"
                                    class="searchinput"
                                    placeholder="NOMBRE"
                                    (keyup)="doFilter($event, 'nombre', 'LIKE', 'STRING')"
                                />
                                <button (click)="changeSorts('nombre', 'nombre')"><a [ngClass]="sortArray['nombre']==='ASC' ? 'asc-sort': sortArray['nombre']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div>
                        </th>
                        <th class="card-width-name-admin-auto " *ngIf="adminAuto">
                            <div class="filter-container">
                                <input
                                type="text"
                                class="searchinput"
                                placeholder="NOMBRE MODERADOR"
                                (keyup)="doFilter($event, 'idPadre.nombre', 'LIKE', 'STRING')"
                              />
                              <button (click)="changeSorts('idPadre.nombre', 'idPadreNombre')"><a [ngClass]="sortArray['idPadreNombre']==='ASC' ? 'asc-sort': sortArray['idPadreNombre']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div>
                        </th>
                        <th [ngClass]="adminAuto ? 'card-width-id-admin-auto': 'card-width-id'" *ngIf="userAdmin">
                            <div class="filter-container">
                                <input
                                    type="text"
                                    class="searchinput"
                                    placeholder="ID"
                                    (keyup)="doFilter($event, 'id', 'LIKE', 'STRING')"
                                />
                                <button (click)="changeSorts('id', 'id')"><a [ngClass]="sortArray['id']==='ASC' ? 'asc-sort': sortArray['id']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div></th>
                          <th class="card-width-id-admin-auto " *ngIf="adminAuto">
                            <div class="filter-container">
                                <input
                                    type="text"
                                    class="searchinput"
                                    placeholder="ID MOD"
                                    (keyup)="doFilter($event, 'idPadre.id', 'LIKE', 'STRING')"
                                />
                                <button (click)="changeSorts('idPadre.id', 'idPadreId')"><a [ngClass]="sortArray['idPadreId']==='ASC' ? 'asc-sort': sortArray['idPadreId']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div></th>

                          <th class="card-width-id " *ngIf="!userAdmin">
                            <div class="filter-container">
                                <input
                                    type="text"
                                    class="searchinput"
                                    placeholder="ID"
                                    (keyup)="doFilter($event, 'idVisible', 'LIKE', 'STRING')"
                                />
                                <button (click)="changeSorts('idVisible', 'idVisible')"><a [ngClass]="sortArray['idVisible']==='ASC' ? 'asc-sort': sortArray['idVisible']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div></th>
                        <th [ngClass]="adminAuto ? 'card-width-email-admin-auto': 'card-width-email'">
                            <div class="filter-container">
                                <input
                                    type="text"
                                    class="searchinput"
                                    placeholder="EMAIL"
                                    (keyup)="doFilter($event, 'email', 'LIKE', 'STRING')"
                                />
                                <button (click)="changeSorts('email', 'email')"><a [ngClass]="sortArray['email']==='ASC' ? 'asc-sort': sortArray['email']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div>
                        </th>
                        <th [ngClass]="adminAuto ? 'card-width-status-admin-auto': 'card-width-status'" *ngIf="userAdmin">
                            <div class="filter-container">
                                <select (change)="doFilter($event, 'isActive', 'EQUAL', 'BOOLEAN')">
                                    <option [value]="true">
                                        ACTIVO
                                    </option>
                                    <option [value]="false">
                                        INACTIVO
                                    </option>
                                </select>
                                <button (click)="changeSorts('isActive', 'isActive')"><a [ngClass]="sortArray['isActive']==='ASC' ? 'asc-sort': sortArray['isActive']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div>
                            
                        </th>
                        <th [ngClass]="adminAuto ? 'card-width-telephone-admin-auto': 'card-width-telephone'" *ngIf="userAdmin">
                            <div class="filter-container">
                                <input
                            type="text"
                            class="searchinput"
                            placeholder="TELÉFONO"
                            (keyup)="doFilter($event, 'telefono', 'LIKE', 'STRING')"
                            />
                            <button (click)="changeSorts('telefono', 'telefono')"><a [ngClass]="sortArray['telefono']==='ASC' ? 'asc-sort': sortArray['telefono']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div>
                        </th>
                        <th *ngIf="!userAdmin" class="card-width-document ">
                            <div class="filter-container">
                                <input
                                type="text"
                                class="searchinput"
                                placeholder="Nº DOCUMENTO"
                                (keyup)="doFilter($event, 'documento', 'LIKE', 'STRING')"
                                />
                                <button (click)="changeSorts('documento', 'documento')"><a [ngClass]="sortArray['documento']==='ASC' ? 'asc-sort': sortArray['documento']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                            </div>
                        </th>
                        <th class="card-with-actions">
                            <div class="buttons">
                                ACCIONES
                            </div>
                        </th>
                    </tr>
                </table>
            </div>
            <div class="card w-100 card-generic border-0">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item p-3 d-flex border rounded shadow"
                        *ngFor="let usuario of usuarios | filterUser : searchText">
                        <div [ngClass]="adminAuto ? 'card-width-name-admin-auto': 'card-width-name'" class="card border-0">
                            {{ usuario?.tipopersona.id==1 ? (usuario.nombre + ' ' + usuario.apellido1 + ' ' + usuario.apellido2) : usuario.nombre}}
                        </div>
                        <div *ngIf="adminAuto" class="card border-0 card-width-name-admin-auto ">
                            <div *ngIf="usuario.idPadre">{{ usuario.idPadre.tipopersona.id==1 ? (usuario.idPadre.nombre + ' ' + usuario.idPadre.apellido1 + ' ' + usuario.idPadre.apellido2) : usuario.idPadre.nombre}}</div>
                        </div>
                        <div *ngIf="!userAdmin" class="card border-0 card-width-id colorOption">
                            #ID{{usuario.idVisible}}
                        </div>
                        <div [ngClass]="adminAuto ? 'card-width-id-admin-auto': 'card-width-id'" *ngIf="userAdmin" class="card border-0 colorOption">
                            #ID{{usuario.id}}
                        </div>
                        <div *ngIf="userAdmin" class="card border-0 card-width-id-admin-auto colorOption">
                            <div *ngIf="usuario.idPadre">#ID{{usuario.idPadre.id}}</div>
                        </div>
                        <div [ngClass]="adminAuto ? 'card-width-email-admin-auto': 'card-width-email'" class="card border-0">
                            {{usuario.email}}
                        </div>
                        <div *ngIf="userAdmin" [ngClass]="[adminAuto ? 'card-width-status-admin-auto' : 'card-width-status', usuario.active === true ? 'activo' : 'inactivo']"
                            [ngClass]="">
                            <span class="dot-prefix mr-1">&#x2022;</span>{{usuario.active === true ? 'ACTIVO' :
                            'INACTIVO'}}
                        </div>

                        <div [ngClass]="adminAuto ? 'card-width-telephone-admin-auto': 'card-width-telephone'" *ngIf="userAdmin" class="card border-0">
                            {{usuario.telefono}}
                        </div>
                        <div *ngIf="!userAdmin" class="card border-0 card-width-document">
                            {{usuario.documento}}
                        </div>
                        <div class="buttons-flex-container align-items-right card-width-actions">

                            <a (click)="ver(usuario.id)" class="eye-button activated" title="Ver"></a>
                            <a (click)="actualizar(usuario.id)" class="edit-button activated pencil" title="Editar"></a>
                            <!--                         <a *ngIf="userAdmin" class="download-button activated"></a>-->
                            <a (click)="dialog.showModal()" class="delete-button activated" title="Eliminar"></a>
                            
                            <dialog #dialog>
                                <div class="m-3 justify-content-center align-items-center">
                                    <div class="m-4 align-items-center">
                                        <a class="delete-button dialogborrar"></a>

                                        <h5 id="textdialog">¿Desea confirmar la eliminacion de {{usuario.nombre}}
                                            {{usuario.apellido1}}?
                                        </h5>
                                    </div>
                                    <div class="d-flex justify-content-end m-4">
                                        <button (click)="dialog.close()" id="btcancel"
                                            class="btn btn-primary mx-1 mt-5"
                                            type="submit">Cancelar</button>
                                        <button (click)="desactivar(usuario.id, dialog)" id="btacept"
                                            class="btn btn-primary mx-1 mt-5" 
                                            [disabled]="isDelete">
                                            <div class="spinner-border spinner-border-sm" *ngIf="isDelete">
                                            </div>
                                            <span class="align-middle"> Confirmar</span>
                                        </button>
                                    </div>
                                </div>
                            </dialog>
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="usuarios.length == 0" class="d-flex flex-row justify-content-center empty-data-message my-4">
                <span>No se han encontrado registros que mostrar</span>
            </div>
            <mat-paginator *ngIf="mostrarPag" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"
                [length]="totalElements" (page)="nextPage($event)"> </mat-paginator>

        </div>
    </div>