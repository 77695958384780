<div *ngIf="solicitante">
    <div class="pageTitle">
        <h1 class="">Distintivo ambiental</h1>
    </div>
    <div class="">
        <hr class="linea-divisoria-title">
    </div>

    <div class="pageTitle">
        <h3 class="">Datos solicitante</h3>
    </div>

    <div class="full-form-group detailViewer">
        <div class="row">
            <div class="col-sm-12 detail">
                <p class="detailsBold">Tipo: </p><p class="details">{{solicitante.tipoEntidad.nombre}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Nombre: </p><p class="details">{{solicitante.nombre}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Primer apellido: </p><p class="details">{{solicitante.apellido1}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Segundo apellido: </p><p class="details">{{solicitante.apellido2}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Email: </p><p class="details">{{solicitante.email}}</p>
            </div>
            <div *ngIf="solicitante.tipoEntidad.id==1" class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Nombre empresa: </p><p class="details">{{solicitante.nombreEmpresa}}</p>
            </div>
            <div *ngIf="solicitante.tipoEntidad.id==1" class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">CIF: </p><p class="details">{{solicitante.cif}}</p>
            </div>
            <div *ngIf="solicitante.tipoEntidad.id==2" class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">NIF/NIE: </p><p class="details">{{solicitante.dni}}</p>
            </div>
            <div *ngIf="solicitante.tipoEntidad.id==2" class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Fecha nacimiento: </p><p class="details">{{solicitante.fechaNacimiendo}}</p>
            </div>
            <div *ngIf="solicitante.tipoEntidad.id==2" class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Fecha validez NIF/NIE: </p><p class="details">{{solicitante.validezDni}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Móvil: </p><p class="details">{{solicitante.telefono}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Comunidad: </p><p class="details">{{solicitante.comunidadAutonoma.nombre}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Provincia: </p><p class="details">{{solicitante.provincia.nombre}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Municipio: </p><p class="details">{{solicitante.localidad}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Código postal: </p><p class="details">{{solicitante.codigoPostal}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Tipo de vía: </p><p class="details">{{solicitante.tipoVia.descripcion}}</p>
            </div>

            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Dirección: </p><p class="details">{{solicitante.nombreVia}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail">
                <p class="detailsBold">Nº de la vía: </p><p class="details">{{solicitante.numeroVia}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail" *ngIf="solicitante.bloque">
                <p class="detailsBold">Bloque: </p><p class="details">{{solicitante.bloque}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail" *ngIf="solicitante.escalera">
                <p class="detailsBold">Escalera: </p><p class="details">{{solicitante.escalera}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail" *ngIf="solicitante.planta">
                <p class="detailsBold">Piso: </p><p class="details">{{solicitante.planta}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail" *ngIf="solicitante.puerta">
                <p class="detailsBold">Puerta: </p><p class="details">{{solicitante.puerta}}</p>
            </div>
            <div class="col-md-6 col-xl-3 detail" *ngIf="solicitante.km">
                <p class="detailsBold">Km: </p><p class="details">{{solicitante.km}}</p>
            </div>

        </div>

    </div>
    <div class="subtitleViewer">
        <h3 class="">Datos vehículo</h3>
    </div>
    <div class="full-form-group detailViewer">
        <div class="row">
            <div class="col-md-6 col-xl-4 detail">
                <p class="detailsBold">Matrícula: </p><p class="details">{{matricula}}</p>
            </div>
        </div>
    </div>

    
    

    <div *ngIf="isLoaded" class="subtitleViewer">
        <h3 *ngIf="hasPresu">Resumen presupuesto</h3>
        <h3 *ngIf="!hasPresu &&  !isEditNewPresu">Borrador presupuesto</h3>
        <h3 *ngIf="!hasPresu &&  isEditNewPresu">Editar nuevo presupuesto</h3>
        <a *ngIf="!hasPresu && !isMod" (click)=" setEditarChild()"
            class="edit-button activated pencil button-ed tam ml-2"></a>
        <a *ngIf="hasPresu" (click)="abrirArchivo(documentoPresupuesto)">
            <img src="/assets/autoescuela/openPdf.svg" alt="icono de archivo" class="icono-archivo tam ml-2">
        </a>
    </div>
    <div class="">
        <div [hidden]="!mostrarForm " *ngIf="!hasPresu">
            <app-presupuesto-filled (newItemEvent)="setMostrarFormularioChild($event)"
                [infoPresupuesto]="infoPresupuesto" [isNew]="true"></app-presupuesto-filled>
        </div>
    </div>
    <div *ngIf="isLoaded&&presupuesto!=undefined && !isEditNewPresu" class="full-form-group">
        <div class="border-bottom row list_head_viewer">
            <div class="card-width-name col-md-4 col-8">CONCEPTO</div>
            <div class="card-width-number-invoice col-2 text-right">BASE IMPONIBLE</div>
            <div class="card-width-date col-2 text-right">IVA</div>
            <div class="card-width-date-expiration col-2 text-right">IRPF</div>
            <div class="card-width-invoice col-md-2 col-4 text-right">TOTAL</div>
        </div>
        <div class="details_presupuesto_viewer shadow">
            <div *ngFor="let detalle of presupuesto.detalle">
                <div class="row list_content_viewer">
                    <div class="card-width-name col-md-4 col-8">{{detalle.concepto}}</div>
                    <div class="card-width-number-invoice col-2 text-right">{{detalle.baseImponible.toFixed(2)}}€</div>
                    <div class="card-width-date col-2 text-right">{{detalle.tipoIva}}%</div>
                    <div class="card-width-date-expiration col-2 text-right">{{detalle.tipoIrpf}}%</div>
                    <div class="card-width-invoice col-md-2 col-4 text-right">{{detalle.total.toFixed(2)}}€</div>
                </div>
            </div>
            <div>
                <div class="row list_content_viewer">
                    <div class="card-width-name col-md-4 col-8"><b>Total Presupuesto</b></div>
                    <div class="card-width-number-invoice col-2 text-right"><b>{{presupuesto.baseImponible.toFixed(2)}}€</b></div>
                    <div class="card-width-date col-2 text-right"><b>{{presupuesto.importeIva.toFixed(2)}}€</b></div>
                    <div class="card-width-date-expiration col-2 text-right"><b>{{presupuesto.importeIrpf.toFixed(2)}}€</b></div>
                    <div class="card-width-invoice col-md-2 col-4 text-right"><b>{{presupuesto.total.toFixed(2)}}€</b></div>
                </div>
            </div>
        </div>


    </div>



    <div *ngIf="isLoaded&&!hasPresu && !isEditNewPresu && !isMod" class="presupuestoSelector">
            <div class="col-sm-12 flex-row d-md-flex justify-content-md-end pr-0 pl-0">
                <div class="d-flex flex-row">
                    <button type="button" class="btn  btn-outline-primary mr-2 ml-3 mr-3"
                        (click)="generatePresupuesto(solicitante)"
                        [disabled]="isCargando">
                        <div class="spinner-border spinner-border-sm" *ngIf="isCargando"></div>
                        <span class="align-middle">Crear presupuesto</span>
                    </button>
                    <button type="button" class="btn  btn-outline-primary"
                        (click)="showListaPresupuesto()">Seleccionar</button>
                    <button *ngIf="selectPresupuesto"
                        class="btn btn-outline-primary align-middle d-flex flex-row ml-3 close-button"
                        (click)="closeListaPresupuesto()">
                        <a class="dismiss-button iconDismiss"></a> <span class="align-middle">Descartar</span>
                    </button>
                </div>
            </div>
        <div *ngIf="selectPresupuesto" class=" mb-5">
            <select class="form-control mt-3" (change)="onChangePresupuesto($event)">
                <option *ngFor="let presupuesto of presupuestos index as i" [value]="i">
                    {{presupuesto.codigoPresupuesto}}, {{getDateToFilter(presupuesto.fecha)}}
                </option>
            </select>
            <button type="button" class="btn  btn-outline-primary mr-2 mt-3" (click)="asignPresupuesto()">
                <div class="spinner-border spinner-border-sm" *ngIf="isCargando"></div>Asignar presupuesto
            </button>
        </div>

    </div>

    <div class="subtitleViewer">
        <h3 class="">Documentos cumplimentados</h3>
        <a class="questionico" title="Como firmar documentos" (click)="isExtraInfo = true"></a>
    </div>
    <div class="">
        <hr class="linea-divisoria-title">
    </div>

    <div class="state-overlay" *ngIf="isExtraInfo">
        <div class="popup">
          <h2>Instrucciones para firmar documentos.</h2>
          <a class="close" (click)="isExtraInfo = false">&times;</a>
          <div>
            <ol type = "1">
                <li>Click en botón Editar PDF y comprobar que los datos son correctos. Seguidamente guardar el archivo en el ordenador.</li>
                <li>Click en botón Adjuntar el PDF guardado en el ordenador.</li>
                <li>Click en botón Enviar</li>
             </ol>
          </div>
        </div>
    </div>
    <div class="full-form-group p-0 col-sm-12 d-flex">
        <div class="cardDoc mr-3" appDrag (files)="filesDropped($event)">
            <p>MANDATO DE TRÁFICO
            </p>
            <div *ngIf="!isMod" class="d-flex">
                <a>
                    <img src="/assets/autoescuela/uploadArchivo.svg" alt="icono de archivo" class="icono-archivo">
                    Adjuntar
                    <input id="file" value="file" name="file" class="pruebaInput" (change)="onFileChange($event)"
                        onclick="event.target.value=null" type="file" />
                </a>

                <a (click)="enviarAFirmar()">
                    <img src="/assets/autoescuela/sendArchivo.svg" alt="icono de archivo" class="icono-archivo">
                    Enviar
                </a>

                <a (click)="abrirArchivo(documentoMandato)">
                    <img src="/assets/autoescuela/openPdf.svg" alt="icono de archivo" class="icono-archivo">
                    Editar
                </a>
            </div>
            <div *ngIf="isMod" class="d-flex">
                <a (click)="abrirArchivo(documentoMandato)">
                    <img src="/assets/autoescuela/openPdf.svg" alt="icono de archivo" class="icono-archivo">
                    Ver
                </a>
            </div>
        </div>

    </div>

     <div class="subtitleViewer">
        <h3 class="">Archivos adjuntos</h3>
    </div>
    <div class="">
        <hr class="linea-divisoria-title">
    </div>
    <div class="row d-flex paddedFormGroup">
        <div class="col-sm-6">
            <p>Por favor adjunte a continuación los siguientes documentos requeridos y aplicables al trámite:</p>
            <ul>
                <li>DNI Solicitante</li>
                <li>Permiso circulación</li>
                <li>Justificante de transferencia de pago del trámite</li>
            </ul>
            <p>Si interviene empresa:</p>
            <ul>
                <li>Certificado de IAE</li>
                <li>DNI administrador/apoderado</li>
            </ul>
        </div>
        <div class=" col-sm-6 flex-row justify-content-between align-items-center row m-0 p-0 pr-2">
            <div class="col-12 row m-0 p-0" *ngIf="!isMod">
                <div class="col-12 body bodyBorder file-manager-content d-flex p-2 m-0">
                    <div class="border backGround-colorAdjuntar borderAdjuntar adjuntar-area.active col-6 file-manager m-0"
                        appDrag (files)="filesDroppedArr($event)" >
                        <input id="file" value="file" name="file"
                        class="pruebaInput" (change)="onFileChangeArr($event)"
                        onclick="event.target.value=null" type="file" /> 
                        <div class="border rounded-circle cloudContainer">

                            <div class="text-center">
                                <a class="iconCloud"></a>
                            </div>
                        </div>
                        <div class="text-center">
                            <h6 class="texto mt-1">Arrastre aquí los archivos o haga clic para seleccionar</h6>
                            <h6 class="indicaciones mt-1">PDF de 20MB máximo. </h6>
                        </div>

                    </div>
                    <div *ngIf="this.arrayDocuments?.length>0" class="col-sm-6 pt-2">
                        <div *ngFor="let documento of this.arrayDocuments" class="p-0">
                            <div class="documento pb-1">
                                <a class="cortar-texto" (click)="abrirArchivo(documento.base64)">{{documento['nombre']}}</a>
                                <div class="card border-0 card-width-actions">
                                    <div>
                                        <a (click)="changeEstadoDocumento(documento.idNextcloud, 2)"
                                            *ngIf="documento.estado == '1' && isAdmin"
                                            class="accept-button activated iconAccept" img
                                            src="/assets/autoescuela/Accept.svg"></a>
        
                                        <a *ngIf="documento.estado == '2'" class="accept-button activated iconAccept" img
                                            src="/assets/autoescuela/Accept.svg"></a>
                                    </div>
                                    <div>
                                        <a (click)="changeEstadoDocumento(documento.idNextcloud, 3)"
                                            *ngIf="documento.estado == '1' && isAdmin"
                                            class="dismiss-button activated iconAccept" img
                                            src="/assets/autoescuela/Accept.svg"></a>
        
                                        <a *ngIf="documento.estado == '3'" class="rechazado dismiss-button activated" img
                                            src="/assets/autoescuela/Accept.svg"></a>
                                    </div>
                                    <div>
                                        <a (click)="removeFile(arrayDocuments.indexOf(documento),documento.idNextcloud)"
                                            class="delete-button activated boton-eliminar"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div *ngIf="isMod && this.arrayDocuments.length != 0" class="col-12 row lista-documentos" >
                <div class="pt-2 pb-2 w-100">
                    <div *ngFor="let documento of this.arrayDocuments" class="pr-3 pl-3 pt-2 pb-2">
                        <div class="documento pb-1">
                            <a class="doc-texto" (click)="abrirArchivo(documento.base64)">{{documento['nombre']}}</a>
                            <div class="card border-0 card-width-actions">
                                <div>
                                    <a *ngIf="documento.estado == '2'" class="accept-button activated iconAccept" img
                                        src="/assets/autoescuela/Accept.svg"></a>
                                </div>
                                <div>
                                    <a *ngIf="documento.estado == '3'" class="rechazado dismiss-button activated" img
                                        src="/assets/autoescuela/Accept.svg"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isMod" class="col-12 pr-0 d-flex justify-content-end align-items-center">
                <button *ngIf="hasPresu" (click)="goPago()" type="button" class="btn btn-outline-primary mt-4  preview col-sm-2  ml-2 mr-2 buttons-end">
                    <mat-icon class="align-middle mr-2">
                        credit_card
                    </mat-icon><label class="align-middle">Pagar</label>
                </button>
                <button class="btn btn-primary ml-4 mt-4" type="submit"
                (click)="saveDocuments(arrayDocuments)"[disabled]="uploadDocuments">
                <div class="spinner-border spinner-border-sm mr-1" *ngIf="uploadDocuments"></div> 
                <span>Subir</span>
            </button>
            </div>
        </div>
    </div>
        <div class="mt-5 mb-4">
            <app-notificaciones-tramites (newItemEvent)="closePage($event)" [idTramite]="idTramite" [idTipoTramite]="tipoTramite"
                *ngIf="!isAdmin && !isMod"></app-notificaciones-tramites>
        </div>