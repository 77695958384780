export class Presupuesto{
    id?:number;
    fecha:any ="";
    codigoPresupuesto="";
    fechaValidez:any= "";
    notas = "";
    baseImponible?:Number;

    

    constructor(fecha: Date,codigoPresupuesto: string,fechaValidez: Date, notas?:string,baseImponible?: Number ,id?: number){
        this.fecha = fecha;
        this.codigoPresupuesto = codigoPresupuesto;
        this.fechaValidez = fechaValidez;
        this.notas = notas;
        this.baseImponible = baseImponible;
        this.id = id;
    }

}