import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CifValidator } from '../class/CifValidator';
import { DniValidator } from '../class/DniValidator';
import { NieValidator } from '../class/NieValidator';
import { PasaporteValidator } from '../class/PasaporteValidator';
import { Direccione } from '../model/third_model';
import { RaizComponent } from '../raiz/raiz.component';
import { AuthService } from '../_services/auth.service';
import { CifService } from '../_services/cif.service';
import { DniService } from '../_services/dni.service';
import { InfoService } from '../_services/info.service';
import { NieService } from '../_services/nie.service';
import { NotificationService } from '../_services/notification.service';
import { PasaporteService } from '../_services/pasaporte.service';
import { ServicioGeneral } from '../_services/servicio-general.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { GestionClientesComponent } from '../hencok/gestion-clientes/gestion-clientes.component';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  @Input() direccion?: Direccione | undefined;
  @Output() newItemEvent = new EventEmitter<any>();

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let password = group.get('password').value;
    let confirmPassword = group.get('password_confirmacion').value
    return password === confirmPassword ? null : { notSame: true }
  }

  form: UntypedFormGroup = new UntypedFormGroup({
    nombre: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", Validators.required),
    apellido1: new UntypedFormControl(""),
    apellido2: new UntypedFormControl(""),
    tipo_persona: new UntypedFormControl("", Validators.required),
    tipo_documento: new UntypedFormControl("", Validators.required),
    telefono: new UntypedFormControl("", [Validators.required, Validators.pattern("[0-9]{9}")]),
    numero_documento: new UntypedFormControl("", Validators.required),
    tipo_via: new UntypedFormControl("", Validators.required),
    nombre_via: new UntypedFormControl("", Validators.required),
    numero_via: new UntypedFormControl("", Validators.required),
    bloque: new UntypedFormControl(""),
    escalera: new UntypedFormControl(""),
    piso: new UntypedFormControl(""),
    puerta: new UntypedFormControl(""),
    codigo_postal: new UntypedFormControl("", [Validators.required, Validators.pattern("^(?:0[1-9]\\d{3}|[1-4]\\d{4}|5[0-2]\\d{3})$")]),
    pais: new UntypedFormControl("", Validators.required),
    ccaa: new UntypedFormControl("", Validators.required),
    provincia: new UntypedFormControl("", Validators.required),
    municipio: new UntypedFormControl("", Validators.required),

  });

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  TPersonas: any = [];
  TDocumentos: any = [];
  TComunidades: any = [];
  TProvincias: any = [];
  provincias = this._servicioGeneral.getProvincias();
  TMunicipios: any = [];
  municipios = this._servicioGeneral.getMunicipios();
  TVias: any = [];
  TPais: any = [];
  submitted: boolean = false;
  register = false;
  InfoRegistro: any = [];
  environment = environment;
  isLoggedIn = false;
  isRequired: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  rol=0;
  roles:any;
  id:string;
  id_iban:string;
  id_direccion:string
  usuarioBaja:any;


  constructor(
    private _authService: AuthService,
    private _servicioGeneral: ServicioGeneral,
    private _notificationSvc: NotificationService,
    private _router: Router,
    private _infoService: InfoService,
    private _tokenStorage: TokenStorageService,
    private _userService: UserService,
    private raiz: RaizComponent,
    private tokenStorageService: TokenStorageService,
  ) { }

  ngOnInit(): void {

    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if(this.isLoggedIn){
      this.rol = this.tokenStorageService.getRol();
    }
    if(this.rol == 3 || this.rol == 5 || this.rol == 6){
      this.form.addControl('iban', new UntypedFormControl(''));
      this.form.addControl('titular', new UntypedFormControl(''));
      this.form.addControl('entidad_bancaria', new UntypedFormControl(''));
      this.form.addControl('rol_control', new UntypedFormControl('', Validators.required));
      this.form.addControl('password', new UntypedFormControl('',  Validators.minLength(6)));
      this.form.addControl('password_confirmacion', new UntypedFormControl(''));
    }
    else if(this.rol==99){
      this.form.addControl('iban', new UntypedFormControl(''));
      this.form.addControl('titular', new UntypedFormControl(''));
      this.form.addControl('entidad_bancaria', new UntypedFormControl(''));
      this.form.addControl('rol_control', new UntypedFormControl('', Validators.required));
      this.form.addControl('password', new UntypedFormControl('',  Validators.minLength(6)));
      this.form.addControl('password_confirmacion', new UntypedFormControl(''));
    }
    else{
      this.form.addControl('iban', new UntypedFormControl('', Validators.required));
      this.form.addControl('titular', new UntypedFormControl('', Validators.required));
      this.form.addControl('entidad_bancaria', new UntypedFormControl('', Validators.required));
      this.form.addControl('password', new UntypedFormControl('',  [Validators.required, Validators.minLength(6)]));
      this.form.addControl('password_confirmacion', new UntypedFormControl('', Validators.required));
      this.form.setValidators(this.checkPasswords);
    }
   
    this.getDocumentos();
    this.getTipoPersona();
    this.getVias();
    this.getPais();
    this.getComunidades();
    this.getProvincias();
    this.getInformacion();
    this.setRoles();
    this.register = false;

  }
  reload() {
    if (this.isLoggedIn) {
      this.newItemEvent.emit(false);
    } else {
      this._router.navigate(['/login']);
    }
  }

  async onSubmit(dialog): Promise<any> {
    dialog.close();
    console.log(this.form)
    let rol = 1
    if(this.isLoggedIn){
       rol = this.tokenStorageService.getRol();
    }
    this.isRequired = true;
    this.register = false;
    console.log(this.form)
    if(this.form.get('email').hasError('incorrect')){
      this._notificationSvc.error('Error', 'Email en uso', 2000);
      return false;
    }
    if (this.form.get('telefono').hasError('pattern')) {
      this._notificationSvc.error('Error', 'No es un teléfono válido.', 2000);
      return false;
    }
    if (this.form.get('codigo_postal').hasError('pattern')) {
      this._notificationSvc.error('Error', 'No es un código postal válido.', 2000);
      return false;
    }

    if(rol!=3 && rol!=5 && rol!=6){
      if (this.form.get('password').hasError('minLength')) {
        this._notificationSvc.error('Error', 'La contraseña debe tener al menos 6 caracteres', 2000);
        return false;
      }
  
      if (this.form.hasError('notSame')) {
        this._notificationSvc.error('Error', 'Las contraseñas no coinciden', 2000);
        this.form.controls['password_confirmacion'].setErrors({ 'incorrect': true });
        return false;
      }
    }

    if (this.form.get('iban').hasError('notfound')) {
      this._notificationSvc.error('Error', 'El iban es invalido', 2000);
      return false;
    }

    if (!this.form.valid) {
      this._notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
      return false;
    } else {
      let submitRol;
      let password;
      if(rol==3 || rol==5 || rol==6 || rol==99){
        submitRol=this.form.get('rol_control').value;
        if(this.form.get('password').value != "" || this.form.get('password').value != undefined){
          password = this.form.get('password').value;
        }
      }
      else if(this.isLoggedIn==false){
        submitRol = 2;
        password = this.form.get('password').value;
      }
      else{
        submitRol = 2;
        password = this.form.get('password').value;
      }
      this.submitted = true;
      this._authService.register(
        this.form.get('nombre').value,
        this.form.get('apellido1').value,
        this.form.get('apellido2').value,
        this.form.get('email').value,
        this.form.get('tipo_persona').value,
        this.form.get('tipo_documento').value,
        this.form.get('numero_documento').value,
        this.form.get('telefono').value,
        this.form.get('tipo_via').value as string,
        this.form.get('nombre_via').value,
        this.form.get('numero_via').value,
        this.form.get('bloque')?.value,
        this.form.get('escalera')?.value,
        this.form.get('piso')?.value,
        this.form.get('puerta')?.value,
        this.form.get('codigo_postal').value,
        this.form.get('pais').value,
        this.form.get('ccaa').value,
        this.form.get('provincia').value,
        this.form.get('municipio').value,

        this.form.get('iban')?.value,
        this.form.get('titular')?.value,
        this.form.get('entidad_bancaria')?.value,
        submitRol,
        password,
        this.id,
        this.id_iban,
        this.id_direccion,

        // this.form.get('concepto')?.value,
        // this.form.get('fechaAlta')?.value,
        // this.form.get('importe')?.value,
        // this.form.get('Tipo')?.value,
        // this.form.get('IVA')?.value

      ).subscribe({
        next: async data => {
          if (this.isLoggedIn) {
            this.newItemEvent.emit(true);
            this.submitted = false;
            this.isRequired = false;
            this.id = undefined;
            this.id_iban = undefined;
            this.id_direccion = undefined;
            this._notificationSvc.success('Hecho', "Cliente registrado con exito", 2000);
            this.form.reset()
          } else {
              this._router.navigate(['/login']);
              this._notificationSvc.success('Atención', "Le llegará el contrato a firmar para la gestoría Hencok. Una vez firmado podrá acceder a la plataforma.", 20000);
            /*  else {
              this._notificationSvc.success('Hecho', "Se ha registrado con éxito", 20000);
              this._tokenStorage.saveToken(data.accessToken);
              this._userService.getCurrentUserFromServer().subscribe({
                next: (data) => {
                  if (this.environment.environment == 'novotaxi') {
                    this._router.navigateByUrl('/admin-novotaxi').then(() => {
                      this._router.navigate(['admin-novotaxi']);
                    });
                  } else {
                    this._tokenStorage.setUser(data.Usuario);
                    this.raiz.reloadHeader = !this.raiz.reloadHeader;
                    this._router.navigate(['/inicio']);
                  }
                },
                error: (error) => {
                  this._router.navigate(['/inicio']);
                }
              });
            }*/
            this.isSignUpFailed = false;
            this.register = true;
          } 

        },

        error: err => {
          console.log(err.error);
          this.register = false;
          this.submitted = false;
          this.id = undefined;
          this.id_iban = undefined;
          this.id_direccion = undefined;
          if (err.status == 226) {
            this._notificationSvc.error('Error', err.error.text, 2000);
            this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
          }
          if (err.error.status == 628) {
            this._notificationSvc.error('Error', err.error.message, 2000);
            this.form.controls['email'].setErrors({ 'incorrect': true });
          }

          if (err.error.status == 627) {
            this._notificationSvc.error('Error', err.error.message, 2000);
            this.form.controls['email'].setErrors({ 'incorrect': true });
          }
          if (err.error.status == 627.1) {
            if(this.isLoggedIn){
              this.id = err.error.id;
              this.id_iban = err.error.id_iban;
              this.id_direccion = err.error.id_direccion;
              dialog.showModal();
            }
            else{
              this._notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['email'].setErrors({ 'incorrect': true });
            }
          }
          if (err.error.status == 646) {
            this._notificationSvc.error('Error', err.error.message, 2000);
            this.form.controls['iban'].setErrors({ 'incorrect': true });
          }
          if (err.error.status == 644) {
            this._notificationSvc.error('Error',err.error.message, 2000);
            this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
          } 
          if (err.error.status == 645) {
            this._notificationSvc.error('Error', err.error.message, 2000);
            this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
          }
          if (err.error.status == 400) {
            this._notificationSvc.error('Ha habido un error general, pruebe más tarde', err.error.message, 2000);
          }
          this.isSignUpFailed = true;
        }
      });
    }
  }

  getTipoPersona() {
    return this._servicioGeneral.getTipoPersona().subscribe((data) => {
      this.TPersonas = data['TipoPersona'];
    });
  }
  tipo;
  isTipoPersona: boolean = false;
  tipoDoc;
  tipoPersona() {

    this.tipoDoc = this.TDocumentos.find(tipoD => tipoD.id == '4')
    this.tipo = this.TPersonas.find(tipo => tipo.id == this.form.value.tipo_persona);
    if (this.tipo.nombre == 'Persona Jurídica') {
      this.isTipoPersona = true;
    }
    if (this.tipo.nombre == 'Persona Física') {
      this.isTipoPersona = false;
    }
  }

  getDocumentos() {
    return this._servicioGeneral.getTipoDocumento().subscribe((data) => {
      this.TDocumentos = data['TipoDocumento'];
    });
  }

  get documento() {
    return this.form.get("numero_documento");
  }

  getComunidades() {
    return this._servicioGeneral.getComunidades().subscribe((data) => {
      this.TComunidades = data['ComunidadAutonoma'];
    });
  }

  getProvincias() {
    return this._servicioGeneral.getProvincias().subscribe((data) => {
      this.TProvincias = data['Provincia'];
    });
  }

  onChangeProvincia(event: Event) {
    this.TProvincias = [];
    const id = (event.target as HTMLInputElement).value;
    this.getProvinciasByID(id);
  }

  getProvinciasByID(id: string) {
    this.provincias.subscribe(
      (data) => {
        data['Provincia'].forEach((provincia) => {
          if (provincia.ccaa["id"] == id) {
            this.TProvincias.push(provincia);
            return this.TProvincias;
          }
        });
      });
  }

  getMunicipios() {
    if (!this.direccion?.province["id"]) {
      return this._servicioGeneral.getMunicipios().subscribe((data) => {
        this.TMunicipios = data;
      });
    } else {
      return this.getMunicipiosByID(this.direccion?.province["id"])
    }
  }

  onChangeMunicipio(event: Event) {
    this.TMunicipios = [];
    const id = (event.target as HTMLInputElement).value;
    this.getMunicipiosByID(id);
  }

  getMunicipiosByID(id: string) {
    this.municipios.subscribe((data) => {
      data['Municipio'].forEach((municipio) => {
        if (municipio.municipioPK["provincia"] == id) {
          this.TMunicipios.push(municipio);
          return this.TMunicipios
        }
      });
    });
  }

  getVias() {
    return this._servicioGeneral.getTipoVias().subscribe((data) => {
      this.TVias = data['TipoVia'];
    });
  }

  getPais() {
    return this._servicioGeneral.getPais().subscribe((data) => {
      this.TPais = data['Pais'];
    });
  }

  getInformacion() {
    return this._infoService.getInfRegistro().subscribe((data) => {
      //console.log(data.Informacion);

      this.InfoRegistro = data.Informacion;
    })
  }

  setRoles(){
    if(this.isLoggedIn){
      this._servicioGeneral.getAllRoles().subscribe((data) => {
        console.log(data['Rol'])
      this.roles = [];
      if(this.rol==5){
        for(let uRol of data['Rol']){
          console.log(uRol)
          if(uRol['id'] == 4 || uRol['id'] == 6){
            this.roles.push(uRol);
          }
        }
      }
      else if(this.rol==3){
        for(let uRol of data['Rol']){
          console.log(uRol)
          if(uRol['id'] == 4 || uRol['id'] == 5 || uRol['id'] == 6){
            this.roles.push(uRol);
          }
        }
      }
      else if(this.rol==6){
        for(let uRol of data['Rol']){
          console.log(uRol)
          if(uRol['id'] == 4){
            this.roles.push(uRol);
          }
        }
      }
      else if(this.rol==99){
        for(let uRol of data['Rol']){
          console.log(uRol)
          this.roles.push(uRol);
        }
      }
        
      })
    }

  }

  reloadToMenuOption() {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/admin-hencok'], { queryParams: { action: 1 } });

  }

  cancelRestore(dialog){
    this.id = undefined;
    this.id_iban = undefined;
    this.id_direccion = undefined;
    dialog.close();
  }
}
