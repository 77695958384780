import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AvisoLegalService {
  protected resourceUrl = `${environment.API_URL}/avisoLegal`;

  constructor(
    protected http: HttpClient,
  ){}

 

  getAviso(): Observable<any>{
   
    return this.http.get(`${this.resourceUrl}/list`);
  } 

}
