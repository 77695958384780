import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ImpuestosService } from 'src/app/_services/hencok/impuestos.service';
import { NexcloudService } from 'src/app/_services/nexcloud.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { Impuesto } from 'src/app/model/hencok/impuesto';

@Component({
  selector: 'app-impuestos-cliente',
  templateUrl: './impuestos-cliente.component.html',
  styleUrls: ['./impuestos-cliente.component.css']
})
export class ImpuestosClienteComponent implements OnInit {

  constructor(
    private impuestosService: ImpuestosService,
    private notificacionService: NotificationService,
    private nextCloud: NexcloudService,
    private route: ActivatedRoute
  ) { }

  listadoImpuestos: Impuesto[];
  tipoImpuesto: string = "IRPF";
  periodoImpuesto: string = "anual";
  annioImpuesto: string = (new Date()).getFullYear().toString();
  totalElements: number = 0;
  user = this.route.snapshot.params['id'];
  listadoImpuestosTrimestre: any[] = [];
  listadoTrimestres: string[] = ["Primer trimestre", "Segundo trimestre", "Tercer trimestre", "Cuarto trimestre"];
  listadoAnnios: string[] = [];

  impuesto: any = null;
  detalles = [];
  mostrarImpuesto: boolean = false;
  isShowedImpueto: boolean = false;

  collapsableFormWidth;



  totalImporteAnual: Number = 0;
  totalImportePrimTrim: Number = 0;
  totalImporteSegTrim: Number = 0;
  totalImporteTerTrim: Number = 0;
  totalImporteCuaTrim: Number = 0;

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];
  sortArray : any = {};

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };

  ngOnInit() {
    this.getCollapsableFormWidth();
    this.listByTipoAndPeriodo({ tipo: this.tipoImpuesto, periodo: this.periodoImpuesto }, this.user)
    this.sortArrayInitialize();
  }

  public listByTipoAndPeriodo(request, id) {
    return this.impuestosService.indexByTipoAndPeriodo(request, id).subscribe((data) => {
      if (data.status != 630) { //Si encuentra elementos en la consulta
        this.listadoImpuestos = data.impuesto.content;
        this.totalElements = data.impuesto.totalElements;
        this.listadoAnnios = data.annios;
        this.calcularTotalImporteAnual(this.listadoImpuestos)
        if (this.periodoImpuesto == 'trimestral') {
          this.listadoImpuestosTrimestre.push(this.listadoImpuestos.filter((impuesto) => impuesto.trimestre.id == 1))
          this.listadoImpuestosTrimestre.push(this.listadoImpuestos.filter((impuesto) => impuesto.trimestre.id == 2))
          this.listadoImpuestosTrimestre.push(this.listadoImpuestos.filter((impuesto) => impuesto.trimestre.id == 3))
          this.listadoImpuestosTrimestre.push(this.listadoImpuestos.filter((impuesto) => impuesto.trimestre.id == 4))
          this.calcularTotalImporteTrimestral()
        }
      } else {
        this.listadoImpuestos = [];
        this.totalElements = 0;
      }
    })
  }

  calcularTotalImporteAnual(listadoImpuestos: any) {
    listadoImpuestos.forEach(element => {
      this.totalImporteAnual = element.importe + this.totalImporteAnual
    });
  }
  calcularTotalImporteTrimestral() {
    this.totalImportePrimTrim = 0;
    this.totalImporteSegTrim = 0;
    this.totalImporteTerTrim = 0;
    this.totalImporteCuaTrim = 0;
    this.totalImporteAnual = 0;
    this.listadoImpuestosTrimestre.forEach(element => {
      element.forEach(element => {
     
          switch (element.trimestre.id) {
            case 1:
              this.totalImportePrimTrim = this.totalImportePrimTrim + element.importe
              break;
            case 2:
              this.totalImporteSegTrim = this.totalImporteSegTrim + element.importe
              break;
            case 3:
              this.totalImporteTerTrim = this.totalImporteTerTrim + element.importe
              break;
            case 4:
              this.totalImporteCuaTrim = this.totalImporteCuaTrim + element.importe
              break;
          }

        }
      

      )
    });

  }



  public listByPeriodo(event) {
    this.listadoImpuestosTrimestre = [];
    this.periodoImpuesto = event.target.value.toLowerCase();
    this.listByTipoAndPeriodo({ periodo: this.periodoImpuesto, tipo: this.tipoImpuesto, annio: this.annioImpuesto }, this.user)
  }

  public listByAnnio(event) {
    this.listadoImpuestosTrimestre = [];
    this.annioImpuesto = event.target.value.toLowerCase();
    this.listByTipoAndPeriodo({ periodo: this.periodoImpuesto, tipo: this.tipoImpuesto, annio: this.annioImpuesto }, this.user)
  }

  public changeTipoImpuesto(event) {
    this.listadoImpuestosTrimestre = [];
    this.tipoImpuesto = event.target.innerText;
    this.listByTipoAndPeriodo({ periodo: this.periodoImpuesto, tipo: this.tipoImpuesto, annio: this.annioImpuesto }, this.user);
  }



  openPdf(impuesto: Impuesto) {
    this.nextCloud.downloadJustificanteB64(impuesto.idDocumento)
      .subscribe({
        next: (documento) => {
          const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        },
        error: (error) => {
          this.notificacionService.error('Error', 'No se ha podido abrir el archivo', 2000);
        }
      })
  }

  downloadPdf(impuesto: Impuesto) {
    this.nextCloud.downloadJustificanteB64(impuesto.idDocumento).subscribe({
      next: (documento) => {
        const linkSource = 'data:application/pdf;base64,' + documento.Result;
        const downloadLink = document.createElement("a");
        const fileName = impuesto.concepto;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        this.notificacionService.success('Hecho', 'Pdf descargado con éxito', 2000);

      },
      error: (error) => {
        this.notificacionService.error('Error', 'No se ha podido descargar el archivo', 2000);
      }
    })
  }



  nextPage(event: PageEvent) {
    const request = { periodo: this.periodoImpuesto, tipo: this.tipoImpuesto };
    request['page'] = event.pageIndex.toString();
    request['size'] = event.pageSize.toString();
    this.listByTipoAndPeriodo(request, this.user);
  }

  @HostListener('window:resize')
  getCollapsableFormWidth() {
    let centerWidth = document.querySelector<HTMLElement>('.center-Hencok').offsetWidth;
    let viewport = document.documentElement.clientWidth;
    this.collapsableFormWidth = centerWidth * (100 / viewport);
  }

  openForm() {
    let collapsableForm = document.getElementById("collapsable-form-wrapper");
    collapsableForm.scrollTop = 0;
    collapsableForm.style.width = this.collapsableFormWidth + 'vw';
    collapsableForm.style.right = "0";
  }

  closeForm() {
    let collapsableForm = document.getElementById("collapsable-form-wrapper");
    collapsableForm.style.right = "-" + this.collapsableFormWidth + 'vw';
  }
  openSidebar(event: Event) {
    var element = document.getElementById("sidebar");
    element.classList.add("active");
    event.stopPropagation();
  }
  openSidebarVisualizar(impuesto, event: any) {
    var ojos = Array.from(document.getElementsByClassName('eye-button') as HTMLCollectionOf<HTMLElement>)

    Array.from(ojos).forEach((ojo) => {
      ojo.style.cssText = "pointer-events:none"
    })

    this.mostrarImpuesto = true;

    var padre = event.target.parentElement;
    var ojo = event.target;

    padre.removeChild(ojo)
    var spinner = document.createElement('div');
    spinner.classList.add("spinner-border");
    spinner.classList.add("spinner-border-sm")
    padre.prepend(spinner)

    var element = document.getElementById("sidebar_detalle");
    this.impuesto = impuesto;

    this.nextCloud.downloadJustificanteB64(impuesto.idDocumento).subscribe((documento) => {

      var embed = document.createElement('embed');
      const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
      embed.setAttribute("type", 'application/pdf');
      var blob = new Blob([byteArray], { type: 'application/pdf' });
      var pdfUrl = URL.createObjectURL(blob)
      //this.pdfAuxiliar = pdfUrl
      embed.setAttribute("src", pdfUrl);
      embed.setAttribute("width", "500px");
      embed.setAttribute("height", "600px");
      this.impuesto['documentoData'] = embed;
      this.impuestosService.getByImpuestoId(impuesto.id).subscribe({
        next: (data) => {
          this.detalles = data['idDocumento']
          this.isShowedImpueto = true;
          padre.removeChild(spinner);
          padre.prepend(ojo);
          Array.from(ojos).forEach((ojo) => {
            ojo.style.cssText = ""
          })
          element?.classList.add('active');
        },
        error: (error) => {
          // console.log(error);
        }
      })

      event.stopPropagation();

    })


  }

  closeSidebar(e: any) {
    if (e.target.classList.value == "eye-button activated") {
    } else {
      this.impuesto = null
      var element = document?.getElementById("sidebar");
      var elementvisualizar = document?.getElementById("sidebar_detalle");
      element?.classList.remove("active");
      elementvisualizar?.classList.remove("active");
    }
    e.stopPropagation();
  }
  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getPresupuestos();
  }

  getPresupuestos() {
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this.impuestosService
      .getImpuestosFilter(this.request)
      .subscribe((data: any) => {
        this.listadoImpuestos = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
      });
  }

  sortArrayInitialize(){
    this.sortArray = {concepto:'none', periodoImpuesto:'none', estado:'none'};
  }

  changeSorts(key, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = key instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of key){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(key);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    this.getPresupuestos();
  }

}
