import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntidadTransferencia } from 'src/app/model/autoescuela/entidad_transferencia';
import { VehiculoTransderencia } from 'src/app/model/autoescuela/vehiculo_transferencia';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TransferenciaService {
  protected resourceUrltransfe = `${environment.API_URL}/tramite/transferencia`;
  protected API_URL = environment.API_URL

  constructor(
    protected http: HttpClient,
    protected token:TokenStorageService
  ) { }

  saveTransferencia(form: any) {
    return this.http.post(`${this.resourceUrltransfe}/save`, form);
  }
  updateTransferencia(form: any,id) {
    return this.http.post(`${this.resourceUrltransfe}/update/${id}`, form);
  }
  getTransferenciaId(id: number) {
    return this.http.get(`${this.resourceUrltransfe}/getById/${id}/${this.token.getId()}`);
  }
  downloadContratoCompraVentaB64(id: Number): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/getContratoContraventa`, { id });
  }
  downloadCambioTitularDGTB64(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/getCambioTitularidaDGT`, { id });
  }
  downloadPersonaJuridica(id: number, isComprador: boolean): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/getModeloAcreditacionPersonaJuridica`, { id, isComprador });
  }
  downloadModeloPorFalleciemiento(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/getModeloPorFalleciemiento`, { id });
  }
  downloadEmbargo(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/getModeloPorEmbargo`, { id });
  }
  downloadMandatoTrafico(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/getMandatoTrafico`, { id });
  }
  downloadMandatoTraficoVendedor(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/getMandatoTraficoVendedor`, { id });
  }
  saveDocumentoModelo(id: number,idMandato:Number, documento:string): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/saveDocTranferencia`, { id,idMandato,documento });
  }
  signMandato(id: number,idMandato:Number): Observable<any> {
    return this.http.post(`${this.resourceUrltransfe}/signMandato`, { id,idMandato});
  }
  

}
