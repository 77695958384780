import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/_services/hencok/cliente.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-gestion-clientes',
  templateUrl: './gestion-clientes.component.html',
  styleUrls: ['./gestion-clientes.component.css']
})
export class GestionClientesComponent {

  usuario: any = null;
  usuarios = [];
  usuariosInicio = [];
  sortedUsuarios = [];
  filteredUsuarios = [];

  userLogged: any = -1;
  userAdmin: Boolean = false;
  adminAuto: Boolean = false;
  sortArray: any = {};

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };

  iban: String = "";

  isSortedByName: string = "";
  isSortedById: string = "";
  isSortedByEmail: string = "";
  isSortedByTelephone: string = "";
  isSortedByDocumento: string = "";
  isDelete = false;

  totalElements: number = 0;

  showFiller = false;

  isActivo = true;
  isInactivo = true;

  rol;

  msgError = null;
  mostrarPag = true;
  // -- Variables para pasar al componente
  editar: Boolean = false;
  mostrarFormulario: Boolean = false;
  searchText: String = "";
  firstFilter: Boolean=true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;


  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _sharedDataService: SharedDataService,
    private _notificationSvc: NotificationService,
    private _router: Router,
    private _tokenStorageService: TokenStorageService,
    private _clienteService: ClienteService,
    private _userService: UserService
  ) { }

  ngOnInit(): void {
    this.userLogged = this._tokenStorageService.getUser();
    // console.log("rolaso", this.userLogged.rol)
    this.rol = this._tokenStorageService.getRol();
    if (this.rol == 99 || this.rol == 2 || this.rol == 3 || this.rol == 5 || this.rol == 6) {
      this.userAdmin = true;
    }
    if (this.rol == 99 || this.rol == 3 || this.rol == 5 || this.rol == 6) {
      this.adminAuto = true;
    }

    this.page = "0";
    this.size = "5";
    this.getUsers();
    this.sortArrayInitialize();
    


  }

  // -- Paginado
  pageSize = 5;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  openSidebar() {
    var element = document.getElementById('sidebar');
    element.classList.add('active');
    // event.stopPropagation();

  }

  openSidebarCliente() {
    var element = document.getElementById('sidebar_detalle');
    element.classList.add('active');
  }

  closeSidebar() {
    var element = document.getElementById('sidebar');
    element.classList.remove('active');
    // event.stopPropagation();
  }

  static closeSidebarRemoto() {
    var element = document.getElementById('sidebar');
    element.classList.remove('active');
    
    // event.stopPropagation();
  }

  closeReload(value){
    var element = document.getElementById('sidebar');
    element.classList.remove('active');
    if(value==true){
      this.page = "0";
      this.size = "5";
      this.getUsers();
    }
  }

  closeReloadCliente(event:any){
    var element = document.getElementById('sidebar');
    element.classList.remove('active');
    this.page = "0";
    this.size = "5";
    this.getUsers();
  }


  closeSidebarCliente() {
    var element = document.getElementById('sidebar_detalle');
    element.classList.remove('active');
  }
  static closeSidebarClienteRemoto() {
    var element = document.getElementById('sidebar_detalle');
    element.classList.remove('active');
  }


  ver(id) {
    if (this.rol == 99 || this.rol == 2) {
      this._router.navigate(["/admin-hencok/perfilCliente/" + id]);
    } else if (this.rol == 3) {
      this._router.navigate(["admin-autoescuelas-hencok/perfilCliente/" + id]);
    }
    else if (this.rol == 5 || this.rol == 6) {
      this._router.navigate(["mod-autoescuelas-hencok/perfilCliente/" + id]);
    }
    else {

      this._router.navigate(["client-hencok/perfilCliente"]).then(() => {
        this._router.navigate(["/client-hencok/perfilCliente/", id], { skipLocationChange: true });
      });
    }
  }
  actualizar(id) {
    if (this.rol == 99 || this.rol == 2) {
      this._router.navigate(["/admin-hencok/editarCliente/" + id]);
    } else if (this.rol == 3) {
      this._router.navigate(["admin-autoescuelas-hencok/editarCliente/" + id]);
    } else if (this.rol == 5 || this.rol == 6) {
      this._router.navigate(["mod-autoescuelas-hencok/editarCliente/" + id]);
    } else {
      this._router.navigate(["client-hencok/editarCliente"]).then(() => {
        this._router.navigate(["/client-hencok/editarCliente/", id], { skipLocationChange: true });
      });
    }
  }
  desactivar(id, dialog) {
    this.isDelete = true;
    if (this.userAdmin) {
      this._userService.desactivateUser(id).subscribe(
        (data) => {
          this.getUsers();

          this._notificationSvc.success(
            "Hecho",
            "Borrado correctamente",
            2000
          );

        }
      )
      this.isDelete = false;
    } else {
      this._clienteService.desactivateClient(id).subscribe(
        (data) => {
          dialog.close()
          this.getUsers();

          this._notificationSvc.success(
            "Hecho",
            "Borrado correctamente",
            2000
          );
        }
      )
    }
    this.isDelete = false;

  }

  orderById() {
    this.isSortedByEmail = ""
    this.isSortedByTelephone = "";
    this.isSortedByDocumento = "";
    this.isSortedByName = "";
    if (this.isSortedById != "chevron-button") {
      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (parseInt(n1.id) > parseInt(n2.id)) {
          return -1;
        }

        if (parseInt(n1.id) < parseInt(n2.id)) {
          return 1;
        }

        return 0;
      });
      this.isSortedById = "chevron-button";

    } else if (this.isSortedById == "chevron-button") {

      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (parseInt(n1.id) > parseInt(n2.id)) {
          return 1;
        }

        if (parseInt(n1.id) < parseInt(n2.id)) {
          return -1;
        }

        return 0;
      });
      this.isSortedById = "chevron-button-up";

    }
  }

  orderByName() {
    this.isSortedByEmail = ""
    this.isSortedByTelephone = "";
    this.isSortedByDocumento = "";
    this.isSortedById = "";
    if (this.isSortedByName != "chevron-button") {
      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (n1.nombre > n2.nombre) {
          return -1;
        }

        if (n1.nombre < n2.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByName = "chevron-button";

    } else if (this.isSortedByName == "chevron-button") {

      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (n1.nombre > n2.nombre) {
          return 1;
        }

        if (n1.nombre < n2.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByName = "chevron-button-up";

    }
  }

  orderByEmail() {
    this.isSortedByName = ""
    this.isSortedByTelephone = "";
    this.isSortedByDocumento = "";
    this.isSortedById = "";
    if (this.isSortedByEmail != "chevron-button") {
      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (n1.email > n2.email) {
          return -1;
        }

        if (n1.email < n2.email) {
          return 1;
        }

        return 0;
      });
      this.isSortedByEmail = "chevron-button";

    } else if (this.isSortedByEmail == "chevron-button") {

      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (n1.email > n2.email) {
          return 1;
        }

        if (n1.email < n2.email) {
          return -1;
        }

        return 0;
      });
      this.isSortedByEmail = "chevron-button-up";

    }

  }

  orderByTelephone() {
    this.isSortedByName = ""
    this.isSortedByEmail = "";
    this.isSortedByDocumento = "";
    this.isSortedById = "";
    if (this.isSortedByTelephone != "chevron-button") {
      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (n1.telefono > n2.telefono) {
          return -1;
        }

        if (n1.telefono < n2.telefono) {
          return 1;
        }

        return 0;
      });
      this.isSortedByTelephone = "chevron-button";

    } else if (this.isSortedByTelephone == "chevron-button") {

      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (n1.telefono > n2.telefono) {
          return 1;
        }

        if (n1.telefono < n2.telefono) {
          return -1;
        }

        return 0;
      });
      this.isSortedByTelephone = "chevron-button-up";

    }
  }

  orderByDocumento() {
    this.isSortedByName = ""
    this.isSortedByEmail = "";
    this.isSortedByTelephone = "";
    this.isSortedById = "";
    if (this.isSortedByDocumento != "chevron-button") {
      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (n1.documento > n2.documento) {
          return -1;
        }

        if (n1.documento < n2.documento) {
          return 1;
        }

        return 0;
      });
      this.isSortedByDocumento = "chevron-button";

    } else if (this.isSortedByDocumento == "chevron-button") {

      this.usuarios = this.usuarios.sort((n1, n2) => {
        if (n1.documento > n2.documento) {
          return 1;
        }

        if (n1.documento < n2.documento) {
          return -1;
        }

        return 0;
      });
      this.isSortedByDocumento = "chevron-button-up";

    }
  }

  nextPage(event: PageEvent) {
    const request = {};
    console.log("this method")
    this.page = event.pageIndex.toString();
    this.size = event.pageSize.toString();
    this.getUsers();
  }

   doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getUsers();
  }

  getUsers() {

    let filterUser = {
      key: 'fechaBaja',
      operator: "IS_NULL",
    };
    this.filters.push(filterUser);

    this.firstFilter = true;
    let rol = this._tokenStorageService.getRol();
     if (rol == 5 && this.firstFilter) {
      console.log(rol)
      let type = ['idPadre.id', 'idPadre.idPadre.id']
      let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        console.log(composeKey);
      let filterUser = {
        key: composeKey,
        keys: type,
        operator: "EQUAL_OR",
        field_type: "STRING",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
      this.firstFilter = true;
    }
    if (rol == 6 && this.firstFilter) {
      let type = 'idPadre.id'
      let filterUser = {
        key: type,
        operator: "EQUAL",
        field_type: "STRING",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
      this.firstFilter = true;
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    if(this.rol == 1){
      this._clienteService
      .getClienteFilter(this.request)
      .subscribe((data: any) => {
        this.usuarios = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
      });
    }
    else{
      this._userService
        .getUserFilter(this.request)
        .subscribe({next: (data:any) => {
          this.usuarios = data.pageList;
          console.log(data);

          this.totalElements = data.nrOfElements;
      },error: (err:any) =>{
        console.log(err)
      }});
    }
  }

  sortArrayInitialize(){
    this.sortArray = {nombre:'none', idPadreNombre:'none', idPadreId:'none', id:'none', idVisible:'none', email:'none', active:'none', telefono:'none', documento:'none'};
  }

  changeSorts(key, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = key instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of key){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(key);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    this.getUsers();
  }

}

