import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../token-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DuplicadoPermisoConducirService {



  protected resourceUrldupl = `${environment.API_URL}/tramite/duplicadoPermicoConducir`;
  protected API_URL = environment.API_URL

  constructor(
    protected http: HttpClient,
    protected token: TokenStorageService
  ) { }


  saveDupl(form: any) {
    console.log(form)
    return this.http.post(`${this.resourceUrldupl}/save`, form);
  }
  updateDupl(form: any, id) {
    return this.http.post(`${this.resourceUrldupl}/update/${id}`, form);
  }
  getDuplId(id: number) {
    return this.http.get(`${this.resourceUrldupl}/getById/${id}/${this.token.getId()}`);
  }
  downloadTramiteB54(id: number, idDocumento:number): Observable<any> {
    return this.http.post(`${this.resourceUrldupl}/getPdfTramite`, { id, idDocumento });
  }
  saveDocumentoModelo(id: number, documento: any, idDocumento:number): Observable<any> {
    return this.http.post(`${this.resourceUrldupl}/savePfgTramite`, { id, documento, idDocumento });

  }
  signMandato(id: number, idDocumento:number): Observable<any> {
    return this.http.post(`${this.resourceUrldupl}/signPdfTramite`, { id, idDocumento });
  }
}
