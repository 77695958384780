<div class="pageTitle">
    <h1 *ngIf="!isUpdated"class="m-0">Nuevo duplicado ficha técnica</h1>
    <h1 *ngIf="isUpdated"class="m-0">Editar duplicado ficha técnica</h1>
</div>
<div class="ml-4">
    <hr class="linea-divisoria">
</div>

<div class="firstFormTitle">
    <label class="formTitleText">Datos solicitante</label>
</div>
<div  class="formGroup bgLightGreen" >

<form [formGroup]=" form" class="flexn"  >



    <div class="rowForm">
        <div class="row">
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Tipo de persona</label>
                <select formControlName="tipoEntidad" class="form-control"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['tipoEntidad'].errors}"
                    (change)="isEmpresaOnchanged()">
                    <option *ngFor="let tipo of listTipo" [value]="tipo.id">
                        {{ tipo.nombre }}
                    </option>
                </select>
            </div>
            <div class="col-sm-6" *ngIf="isEmpresa">
                <label class="crear-editar-form requerido">Nombre empresa</label>
                <input class="form-control" type="text" id="nombre_empresa" formControlName="nombre_empresa"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_empresa'].errors}">
            </div>
            
            <div class="col-sm-3" *ngIf="isEmpresa">
                <label class="crear-editar-form requerido">CIF empresa</label>
                <input class="form-control" type="text" id="cif_empresa" formControlName="cif_empresa"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['cif_empresa'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="!isEmpresa">
                <label class="crear-editar-form requerido">Nombre </label>
                <input class="form-control" type="text" formControlName="nombre"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="!isEmpresa">
                <label class="crear-editar-form requerido">Primer apellido</label>
                <input class="form-control" type="text" formControlName="apellido1"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido1'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="!isEmpresa">
                <label class="crear-editar-form requerido">Segundo apellido</label>
                    <input class="form-control" type="text" formControlName="apellido2"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido2'].errors}">
            </div>     
        </div>
  
    </div>

    <div class="rowForm" *ngIf="!isEmpresa">
        <div class="row">
            <!--<label class="crear-editar-form requerido col-sm-6">Tipo documento*</label>-->
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Fecha de nacimiento</label>
                <input type="date" class="form-control" name="fecha_nacimiento" formControlName="fecha_nacimiento"
                minlength="3" maxlength="20"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['fecha_nacimiento'].value == ''}" />
            </div>
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">NIF/NIE</label>
                <input class="form-control" type="text" id="numero_documento" formControlName="numero_documento"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_documento'].errors}">
            </div>
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Fecha validez NIF/NIE</label>
                <input type="date" class="form-control" name="validez_dni" formControlName="validez_dni" minlength="3"
                maxlength="20"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['validez_dni'].value == ''}" />
            </div>
            
        </div>
 
    </div>

    <div class="rowForm" *ngIf="isEmpresa">
        <div class="row">
            <!--<label class="crear-editar-form requerido col-sm-6">Tipo documento*</label>-->
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Nombre administrador</label>
                <input class="form-control" type="text" formControlName="nombre"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Primer apellido administrador</label>
                <input class="form-control" type="text" formControlName="apellido1"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido1'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Segundo apellido administrador</label>
                <input class="form-control" type="text" formControlName="apellido2"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido2'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">NIF/NIE administrador</label>
                <input class="form-control" type="text" id="numero_documento" formControlName="numero_documento"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_documento'].errors}">
            </div>
            
        </div>
 
    </div>

    <div class="rowForm">
        <div class="row">
            <div class="col-sm-6">
                <label class="crear-editar-form requerido">Móvil</label>
            <input class="form-control outlinenone" type="text" mask="9{9}" id="telefono" formControlName="telefono"
            [ngClass]="{'is-invalid': isRequired && this.form.controls['telefono'].errors}">
            </div>
            <div class="col-sm-6">
                <label class="crear-editar-form requerido">Correo electrónico</label>
                <input type="email" name="email" class="form-control" formControlName="email"
                [ngClass]="{'is-invalid': isRequired&& this.form.controls['email'].errors}" />
            </div>
            </div>
        </div>
    
            <div class="rowForm">
                <div class="row">
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Tipo de vía</label>
                        <select formControlName="tipo_via" class="form-control"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['tipo_via'].errors}">
                            <option *ngFor="let vias of ListVias" [value]="vias.id">
                                {{ vias.tipovianormalizado }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Dirección</label>
                            <input type="text" formControlName="nombre_via" class="form-control"
                            [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_via'].errors}" />
                    </div>
                    
    
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Nº de la vía</label>
                        <input type="text" mask="9{5}" formControlName="numero_via" class="form-control"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_via'].errors}" />
                    </div>
                    
                    <div class="col-sm-3">
                        <label class="crear-editar-form">Bloque</label>
                        <input type="text" formControlName="bloque" class="form-control" />
                    </div>
                    
                </div>
            
            </div>
            <div class="rowForm">
            <div class="row ">
            <div class="col-sm-3">
                <label class="crear-editar-form">Escalera</label>
                <input type="text" formControlName="escalera" class="form-control" />
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form">Planta</label>
                    <input type="text" formControlName="piso" class="form-control" />
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form">Puerta</label>
                <input type="text" formControlName="puerta" class="form-control" />
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form">Km</label>
                <input type="text" formControlName="km" class="form-control" />
            </div>
            
        </div>
    </div>
    
    <div class="rowForm">
        <div class="row">
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Comunidad autónoma</label>
                <select formControlName="ccaa" class="form-control" (change)="onChangeProvincia($event)"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['ccaa'].errors}">
                    <option *ngFor="let ccaa of ListComunidades" [value]="ccaa.id">
                        {{ ccaa.nombre }}
                    </option>
                </select>
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Provincia</label>
                <select formControlName="provincia" class="form-control" (change)="onChangeMunicipio($event)"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['provincia'].errors}">
                    <option *ngFor="let provincia of ListProvincias" [value]="provincia.id">
                        {{ provincia.nombre }}
                    </option>
                </select>
            </div>

            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Municipio</label>
                <select formControlName="municipio" class="form-control"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['municipio'].errors}">
                    <option *ngFor="let municipio of ListMunicipios" [value]="municipio.municipioPK['municipio_id']">
                        {{ municipio.nombre }}
                    </option>
                </select>
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Código postal</label>
                <input type="text" mask="9{5}" formControlName="codigo_postal" class="form-control"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['codigo_postal'].errors}">
            </div>
        </div>
    </div>
</form>
</div>

<div class="formTitle">
    <label class="formTitleText">Datos vehículo</label>
</div>
<div class="formGroup bgLightGreen">
    <form [formGroup]=" form">
        <div class="row pt-3 ">
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Matrícula</label>
                <input class="form-control" type="text" formControlName="matricula"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['matricula'].errors}">
            </div>

            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Fecha Matriculación</label>
                <input type="date" class="form-control" name="fecha_matriculacion" formControlName="fecha_matriculacion"
                minlength="3" maxlength="20"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['fecha_matriculacion'].value == ''}" />
            </div>
       
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Bastidor</label>
                <input class="form-control" type="text" formControlName="bastidor"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['bastidor'].errors}">
            </div>
            
        </div>
</form>
</div>

<div  *ngIf="!isMod"class="d-flex justify-content-end">
    <button type="button" class="btn btn-primary mt-4 mr-4" (click)="onSubmit() " type="submit">Guardar y continuar</button>
  </div>
