import { Component, Input, SimpleChanges } from '@angular/core';
import { GestionContratosComponent } from '../gestion-contratos/gestion-contratos.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/_services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ContratoService } from 'src/app/_services/hencok/contrato.service';
import { DatePipe } from '@angular/common';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { Observable, ReplaySubject } from 'rxjs';
import { FileHandle } from './drag-drop.directive';

@Component({
  selector: 'app-formulario-adjuntar-contrato',
  templateUrl: './formulario-adjuntar-contrato.component.html',
  styleUrls: ['./formulario-adjuntar-contrato.component.css']
})
export class FormularioAdjuntarContratoComponent {
  form: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),
    usuario_id: new UntypedFormControl(),
    tipoContrato: new UntypedFormControl("", Validators.required),
  });
  @Input() adjContrato: any;
  @Input() primeraVezM: boolean = false;

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = "";
  submitted: boolean = false;
  register2 = false;
  InfoRegistro: any = [];
  environment = environment;
  isLoggedIn = false;
  arrayDocuments = [];
  usuarios: any[] = [];
  usuariosaux: any[] = [];
  usuario: any = -1;
  miUsuario: any;
  tipos: any = []
  miTipoContrato: any = "";
  mostrado: any = false;
  auxiliar: boolean = false;

  pdfAuxiliar: string;
  userLogged: any;
  userAdmin: Boolean = false;
  name = "Angular 5";
  load: boolean;

  uploadDocuments: Boolean = false;

  constructor(
    private _notificationSvc?: NotificationService,
    private _router?: Router,
    private tokenStorageService?: TokenStorageService,
    private contratoService?: ContratoService,
    public datepipe?: DatePipe,
    private _serviceGeneral?: ServicioGeneral,
    public _activatedRoute?: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.userLogged = this.tokenStorageService.getUser();
    let rol = this.tokenStorageService.getRol()
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }

    this.register2 = false;
    this.getUserList();
    this.getTipoContrato();
  }
  getTipoContrato() {
    return this.contratoService.getTipoContrato().subscribe({
      next: (data) => {
        this.tipos = data;
      },
    });
  }

  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => { },
    });
  }


  getUser(id): void {
    this._serviceGeneral.getUserId(id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];
      },
      error: (error) => { },
    });
  }

  removeFile(index: number): void {
    this.arrayDocuments.splice(index, 1);
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  onFileChange(event) {
    if (
      event.target.files[0].type == "application/pdf"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        this.arrayDocuments = [document];
        this.form.controls["documento"].setValue(document);
      });
    } else {
      this._notificationSvc.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        2000
      );
    }
  }

  filesDropped(files: FileHandle[]): void {
    this.form.patchValue({
      documento: files,
    });
    this.convertFile(files[0].file).subscribe((base64) => {
      let document = {
        nombre: files[0].file.name,
        mime: files[0].file.type,
        base64: base64,
      };
      this.arrayDocuments = [document];
      this.form.controls["documento"].setValue(document);
    });
  }

  async onSubmit(): Promise<any> {

    this.register2 = false;
    //  console.log("save", this.form.controls);

    if (this.form.controls['documento'].value === "" || this.form.controls['documento'].value.length == 0) {
      //  console.log("documento invalido");

      this.form.controls['documento'].setValue([]);
      this._notificationSvc.warning('Informacion', 'El documento es obligatorio', 2000);
      return false;
    }

    this.form.addControl('usuario_id', new UntypedFormControl);

    //  console.log(this.userAdmin);

    if (this.userAdmin) {
      if (this.usuario.id == null) {
        this._notificationSvc.warning('Informacion', 'El usuario es obligatorio', 2000);
        return false;
      }
      this.form.controls['usuario_id'].setValue(this.usuario.id);
    } else {

      this.form.controls['usuario_id'].setValue(this.userLogged.id);
    }

    if (!this.form.valid) {
      this._notificationSvc.warning('Informacion', 'Rellene todos los campos por favor', 2000);
      return false;

    } else {
      this.submitted = true;
      this.uploadDocuments = true;
      this.form.addControl('admin_id', new UntypedFormControl);
      this.form.controls['admin_id'].setValue(this.userLogged.id)


      this.contratoService.saveContrato(this.form.value).subscribe({
        next: (data) => {

          this._router.navigateByUrl('/admin-hencok').then(() => {
            this._router.navigate(['admin-hencok/gestion-mercantil']);
          });
          this._notificationSvc.success('Hecho', "Contrato guardado con exito", 2000);
        },
        error: (err) => {
          this.submitted=false;
          console.log(err)
          this.uploadDocuments = false;
          this._notificationSvc.error('Error', "El contrato no se ha podido guardar", 2000);
        }
      })

    }
  }
  closeSidebar() {
    GestionContratosComponent.closeSidebarClienteRemoto();
  }
}
