<div class="pageTitle justify-content-between">
  <h1 class="m-0">Estadísticas</h1>
  <div class="justify-content-between">
    <button class="btn btn-primary align-middle" *ngIf="rol==5" (click)="extractComission()">Cálculo comisiones</button>
    <button class="btn btn-primary align-middle ml-2" (click)="generateComissionExcel()">Extraer comisiones</button>
  </div>
  
</div>
<div class="ml-4">
  <hr class="linea-divisoria" />
</div>

<div class="firstFormTitle">
  <label class="formTitleText">Estadísticas importantes</label>
</div>

<div class="bgLightGreen full-form-group">
  <div class="cardsgreenIni">
    <p class="cardtext">Número trámites</p>
    <p class="cardtextbig">{{ numeroTramites }}</p>
  </div>
  <div class="cardsgreen">
    <p class="cardtext">Total presupuestado</p>
    <p class="cardtextbig">{{ totalPresupuestado }}€</p>
  </div>
  <div class="cardsgreen">
    <p class="cardtext">Total pagado</p>
    <p class="cardtextbig">{{ totalPagado }}€</p>
  </div>
  <div class="cardsgreen">
    <p class="cardtext">Total Comisiones</p>
    <p class="cardtextbig">{{ totalComisiones }}€</p>
  </div>
  <div class="cardsgreen">
    <p class="cardtext">Trámite más presupuestado</p>
    <p class="cardtextbig">{{ tramiteMasPresupuestado.total }}€</p>
  </div>
  <div class="cardsgreen">
    <p class="cardtext">Trámite más común</p>
    <p class="cardtextsm">
      {{ tramiteMasComun.tipoTramite.nombre }} ({{ tramiteMasComun.total }})
    </p>
  </div>
</div>

<div class="full-form-group">
    <div class="rowForm">
        <div class="row">
            <div class="col-sm-4">
                <label class="crear-editar-form">Fecha desde</label>
                <input type="date" class="form-control" [(ngModel)]="dateFrom" (change)="checkDates()">
            </div>
            <div class="col-sm-4">
                <label class="crear-editar-form">Fecha hasta</label>
                <input type="date" class="form-control" [(ngModel)]="dateTo" (change)="checkDates()">
            </div>
            <div class="col-sm-4">
              <label class="crear-editar-form">Provincia</label>
              <select type="text" class="form-control" [(ngModel)]="provincia" (change)="filterProvincias($event, ['comprador.provincia.id', 'solicitanteDistintivo.provincia.id', 'solicitanteInforme.provincia.id', 'solicitanteFichaTecnica.provincia.id', 'solicitante.provincia.id', 'titularVehiculo.provincia.id'],
              'LIKE_OR',
              'STRING')">
                <option value="">
                  Seleccionar provincia
                </option>
                <option *ngFor="let provincia of provincias index as i" [value]="provincia.id">
                  {{ provincia.nombre }}
              </option>
              </select>
          </div>
        </div>
    </div>
</div>
<div class="full-form-group">
  <div class="rowForm">
    <div class="row">
        <div class="col-sm-4">
          <label class="crear-editar-form">Ciudad</label>
          <select type="text" class="form-control" [(ngModel)]="ciudad" (change)="filterEvent($event, ['comprador.localidad', 'solicitanteDistintivo.localidad', 'solicitanteInforme.localidad', 'solicitanteFichaTecnica.localidad', 'solicitante.localidad', 'titularVehiculo.localidad'],
          'LIKE_OR',
          'STRING')">
            <option value="">
              Seleccionar ciudad
            </option>
            <option *ngFor="let municipio of municipios index as i" [value]="municipio.nombre">
              {{ municipio.nombre }}
          </option>
          </select>
      </div>
      <div class="col-sm-4">

    </div>
    <div class="col-sm-4 row justify-content-between m-0">
      <button class="btn btn-primary col-sm-5" (click)="cleanFilters()">Limpiar</button>
      <button class="btn btn-primary col-sm-5" (click)="getTramites()">Buscar</button>
    </div>
    </div>
  </div>
</div>

<div class="mt-5 border-bottom full-form-group">
    <div class="ordering-table row-with-cards">
        <div class="card-with-tramite">
          <label>NOMBRE TRÁMITE</label>
        </div>
        <div class="card-with-total-presu">
          <label>IMPORTE PRESUPUESTO</label>
        </div>
        <div class="card-with-total-presu">
          <label>IMPORTE PAGADOS</label>
        </div>
        <div class="card-with-total-comis">
          <label>IMPORTE COMISIONES</label>
        </div>
        <div class="card-with-num-tram">
          <label>NÚMERO DE TRÁMITES</label>
        </div>
        <div class="card-with-num-tram-presu">
          <label>TRÁMITES PRESUPUESTADOS</label>
        </div>
        <div class="card-with-num-tram-pag">
          <label>TRÁMITES PAGADOS</label>
        </div>
        
      </div>
</div>

<div
  class="list-group-item full-form-group p-3 rounded shadow"
  *ngFor="let infoTramite of infoTramites"
>
  <div class="row-with-cards">
    <div class="card border-0 card-with-tramite">
      <p class="cut-text">{{ infoTramite.nombre }}</p>
    </div>
    <div class="card border-0 card-with-total-presu">
      <p class="cut-text">{{ infoTramite.totalPresupuestado }}€</p>
    </div>
    <div class="card border-0 card-with-total-presu">
      <p class="cut-text">{{ infoTramite.totalPagado }}€</p>
    </div>
    <div class="card border-0 card-with-total-comis">
      <p class="cut-text">{{ infoTramite.comisiones }}€</p>
    </div>
    <div class="card border-0 card-with-num-tram">
      <p class="cut-text">
        {{ infoTramite.numeroTramites }}
      </p>
    </div>
    <div class="card border-0 card-with-num-tram-presu">
      <p class="cut-text">
        {{ infoTramite.tramitesPresupuestados }}
      </p>
    </div>
    <div class="card border-0 card-with-num-tram-pag">
      <p class="cut-text">
        {{ infoTramite.tramitesPagados }}
      </p>
    </div>
  </div>
</div>
