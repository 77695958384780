import { Usuario } from "../novotaxi/gastos-ingresos/gastos-ingresos.model";

export class Iban {
    id: number;
    iban?: string | null;
    titular?: string | null;
    entidad?: string | null;
    usuario?:Usuario | null;



}