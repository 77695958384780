import { DatePipe } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { GastosService } from 'src/app/_services/hencok/gastos.service';
import { IngresosGastosService } from 'src/app/_services/hencok/ingresos-gastos.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { RaizComponent } from 'src/app/raiz/raiz.component';
import { environment } from 'src/environments/environment';
import { FileHandle } from './drag-drop.directive';
import { ImpuestosService } from 'src/app/_services/hencok/impuestos.service';
import { GestionImpuestosComponent } from '../gestion-impuestos/gestion-impuestos.component';

@Component({
  selector: 'app-formulario-editar-modelo',
  templateUrl: './formulario-editar-modelo.component.html',
  styleUrls: ['./formulario-editar-modelo.component.css']
})
export class FormularioEditarModeloComponent {
  
  form: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    periodo_solicitud: new UntypedFormControl("", Validators.required),
    fecha_maxima: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_impuesto: new UntypedFormControl("", Validators.required),
    trimestre: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),
    usuario_id: new UntypedFormControl(),
  });
  @Input() detalles: any;
  @Input() impuesto: any;

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = "";
  submitted: boolean = false;
  register2 = false;
  InfoRegistro: any = [];
  environment = environment;
  isLoggedIn = false;
  arrayDocuments = [];
  arrayDocumentsActual = [];
  trimestres: any = [];
  tipos: any = [];
  miTipoGasto: any = "";
  gastosaux: any = [];
  usuarios: any[] = [];
  usuario: any = -1;
  usuariosaux: any[] = [];;
  miUsuario: any;
  fechaActual: string;

  mostrado: any = false;
  auxiliar: boolean = false;

  pdfAuxiliar: string;
  userLogged: any;
  userAdmin: Boolean = false;
  name = "Angular 5";
  load: boolean;

  uploadDocuments: Boolean = false;

  constructor(
    private _notificationSvc?: NotificationService,
    private _router?: Router,
    private _ingresosGastosService?: IngresosGastosService,
    private raiz?: RaizComponent,
    private tokenStorageService?: TokenStorageService,
    public datepipe?: DatePipe,
    private _serviceGeneral?: ServicioGeneral,
    public _activatedRoute?: ActivatedRoute,
    private impuestoService?: ImpuestosService
  ) { }

  ngOnChanges(changes: SimpleChanges) {

    if (this.impuesto != null) {
      this.getDocumentsList()
     // console.log("detalles   ", this.detalles)
     // console.log("impuesto", this.impuesto)
      this.miUsuario = [];
      this.form.get("importe").setValue(this.impuesto.importe + ".00"),
        this.form.get("concepto").setValue(this.impuesto.concepto),
        this.form.get("periodo_solicitud").setValue(this.formatDate(this.impuesto.periodoImpuesto.split("-")));
      this.form.get("fecha_maxima").setValue(this.formatDate(this.impuesto.fechaMaxima.split("-")));

      this.form.get("tipo_impuesto").setValue(this.impuesto.tipoImpuesto.id)
      this.form.get("trimestre").setValue(this.impuesto.trimestre.id)

      this.miUsuario = this.impuesto.autonomo;
      this.usuarios.forEach((value) => {

        if (this.impuesto.autonomo.id != value.id) {
          this.usuariosaux.push(value);
        }
      });
      this.usuariosaux.unshift(this.miUsuario);
      this.usuario = this.miUsuario
    }

  }

  ngOnInit(): void {
    // this.detalles.forEach(element => {
    //   this.sacarUrlPdf(element);
    // });

    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.userLogged = this.tokenStorageService.getUser();
    let rol = this.tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }

    this.register2 = false;
    this.getTipoImpuesto();
    this.getTrimestre();
    this.getUserList();
  }

  getDocumentsList() {
    if (this.detalles != null) {
      this.arrayDocuments= [this.detalles]
      this.arrayDocumentsActual= [this.detalles]
      this.form.controls["documento"].setValue(this.detalles);
    }

  }

  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => { },
    });
  }


  getUser(id): void {
    this._serviceGeneral.getUserId(id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];
      },
      error: (error) => { },
    });
  }

  removeFile(index: number): void {

    if (this.arrayDocuments.length == 0) {
      this.arrayDocuments.splice(index, 1);

      this._notificationSvc.error('ERROR', "No puede dejar el ingreso/gasto sin ningun documento", 2000);

      this.uploadDocuments = false
    } else {
      this.impuestoService.deleteFile(this.arrayDocuments[index].id_gestor).subscribe({
        next: (data) => {
         // console.log(data);
        },
        error: (error) => {
         // console.log(error);
        }
      })
      this.arrayDocuments.splice(index, 1);
    }
  }




  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  onFileChange(event) {
    if (
      event.target.files[0].type == "application/pdf"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        this.arrayDocuments = [document];
        this.form.controls["documento"].setValue(document);
      });
    } else {
      this._notificationSvc.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        2000
      );
    }
  }

  filesDropped(files: FileHandle[]): void {
    this.form.patchValue({
      documento: files,
    });
    this.convertFile(files[0].file).subscribe((base64) => {
      let document = {
        nombre: files[0].file.name,
        mime: files[0].file.type,
        base64: base64,
      };
      this.arrayDocuments = [document];
      this.form.controls["documento"].setValue(document);
    });
  }
  
  formatDate(date) {
   // console.log(date[1])
   // console.log(date[2])
   // console.log(date[0])
    let month = "" + date[1];
    let day = "" + date[0];
    const year = date[2];
    return year + "-" + month + "-" + day;
  }


  getTipoImpuesto() {
    return this.impuestoService.getTipoimpuesto().subscribe({
      next: (data) => {
        this.tipos = data;
      },
    });
  }
  getTrimestre() {
    return this.impuestoService.getTtrimestre().subscribe({
      next: (data) => {
        this.trimestres = data;
      },
    });
  }
  async onSubmit(): Promise<any> {

    if (this.form.controls['documento'].value === "" || this.form.controls['documento'].value.length == 0) {
      this.form.controls['documento'].setValue([]);
      this._notificationSvc.warning('Informacion', 'El documento es obligatorio', 2000);
      return false;
    }

    this.form.addControl('usuario_id', new UntypedFormControl);
    this.form.addControl('id', new UntypedFormControl)

   // console.log(this.userAdmin);

    if (this.userAdmin) {
      if (this.usuario.id == null) {
        this._notificationSvc.warning('Informacion', 'El usuario es obligatorio', 2000);
        return false;
      }
      this.form.controls['usuario_id'].setValue(this.usuario.id);
    } else {

      this.form.controls['usuario_id'].setValue(this.userLogged.id);
    }

    if(this.form.controls["periodo_solicitud"].invalid){
      this._notificationSvc.warning(
        "Informacion",
        "El formato de la fecha del periodo de solicitud no es correcto",
        2000
      );
      return false;}
   
      if(this.form.controls["fecha_maxima"].invalid){
        this._notificationSvc.warning(
          "Informacion",
          "El formato de la fecha máxima no es correcto",
          2000
        );
        
        return false;}

        if(this.form.controls["periodo_solicitud"].value > this.form.controls["fecha_maxima"].value){
          this._notificationSvc.warning(
            "Informacion",
            "La fecha maxima de presentación no puede ser anterior al fecha de emisión",
            2000
          );
          return false;
        }
    if (!this.form.valid) {
     // console.log("form invalid")
      this._notificationSvc.warning('Informacion', 'Rellene todos los campos por favor', 2000);
      return false;

    } else {
      this.form.controls['id'].setValue(this.impuesto.id);

      this.submitted = true;
      this.uploadDocuments = true;
      this.form.controls['periodo_solicitud'].setValue(this.datepipe.transform(this.form.controls['periodo_solicitud'].value, 'dd/MM/yyyy'));
      this.form.controls['fecha_maxima'].setValue(this.datepipe.transform(this.form.controls['fecha_maxima'].value, 'dd/MM/yyyy'));
     // console.log(this.form.value)

      this.impuestoService.updateImpuesto(this.form.value).subscribe({
        next: (data) => {
         // console.log(data);
          this.submitted = true;
          this._router.navigateByUrl('/admin-hencok').then(() => {
            this._router.navigate(['admin-hencok/impuestos']);
          });
          this._notificationSvc.success('Hecho', "Modelo guardado con exito", 2000);
        },
        error: (err) => {
          this.uploadDocuments = false;
         // console.log(err);

        }
      })
    }
  }
  closeSidebar(){
    GestionImpuestosComponent.closeSidebarClienteRemoto();
  }

  /* async onSubmit(): Promise<any> {


    this.register2 = false;
    this.form.controls["documento"].setValue(this.arrayDocuments);

    if (
      this.form.controls["documento"].value === "" ||
      this.form.controls["documento"].value.length == 0
    ) {
      console.log("documento invalido");

      this.form.controls["documento"].setValue([]);
      this._notificationSvc.warning(
        "Informacion",
        "El documento es obligatorio",
        2000
      );
      return false;
    }
    this.form.addControl("tipo_transaccion", new UntypedFormControl());
    this.form.controls["tipo_transaccion"].setValue("gasto");
    this.form.controls["usuario_id"].setValue(this.usuario.id);
    if (this.userAdmin) {
      if (this.usuario.id == null) {
        this._notificationSvc.warning(
          "Informacion",
          "El usuario es obligatorio",
          2000
        );
        return false;
      }

    } else {
      this.form.controls["usuario_id"].setValue(this.userLogged.id);
    }

    if (!this.form.valid) {
      console.log("form invalid");
      this._notificationSvc.warning(
        "Informacion",
        "Rellene todos los campos por favor",
        2000
      );
      return false;
    } else {
      this.submitted = true;
      this.uploadDocuments = true;
      this.form.controls["fecha_creacion"].setValue(
        this.datepipe.transform(
          this.form.controls["fecha_creacion"].value,
          "dd/MM/yyyy"
        )
      );
      this.form.addControl("gasto_id", new UntypedFormControl());
      this.form.controls["gasto_id"].setValue(this.impuesto.id);

      if (this.miTipoGasto.nombre == "Tarjeta") {
        this.form.get("tipo_gasto").setValue(1)
      }
      else {
        this.form.get("tipo_gasto").setValue(2)
      }

    }
    console.log(this.form.value)
    this.gastoservice.updateGasto(this.form.value).subscribe({
      next: (data) => {
        this.submitted = true;
        if (this.userAdmin) {
          this._router.navigateByUrl("/admin-hencok").then(() => {
            this._router.navigate(["admin-hencok/gastos-ingresos"]);
          })
        }
        else {
          this._router.navigateByUrl("/client-hencok").then(() => {
            this._router.navigate(["client-hencok/gastos-ingresos"]);
          })
        }
        this._notificationSvc.success(
          "Hecho",
          "Ingreso guardado con exito",
          20000
        );
      },
      error: (err) => {
        this.uploadDocuments = false;
      },
    });
  } */
}
