<h1>Manual de utilizacion de la web</h1>
<hr class="linea-divisoria">
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>

                <div *ngIf="node.name=='crearcliente'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoCrearCliente1")}}</p>
                        <img height="20%" width="20%" class="mt-2 mb-4" src="assets/hencok/nuevocliente.png"
                                alt="nuevocliente">
                        <p>{{getByCodigo("web_textoCrearCliente2")}}</p>
                        <img *ngIf="isAdmin" height="50%" width="50%" class="mt-2 mb-4" src="assets/hencok/formulariocliente.png"
                                alt="formulariocliente">
                </div>
                <div *ngIf="node.name=='visualizarcliente'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoVisualizarCliente1")}} <a
                                        class="eye-button activated mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoVisualizarCliente2")}} </p>
                        <p>{{getByCodigo("web_textoVisualizarCliente3")}} </p>
                        <img height="20%" width="20%" class="mt-2 mb-4" src="assets/hencok/btnactualizar.png"
                                alt="nuevocliente">
                        <p *ngIf="isAdmin">{{getByCodigo("web_textoVisualizarCliente4")}}</p>
                        <img *ngIf="isAdmin" height="50%" width="50%" class="mt-2 mb-4" src="assets/hencok/doblefactor.png"
                                alt="formulariocliente">
                </div>
                <div *ngIf="node.name=='editarCliente'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoEditarCliente1")}} <a
                                        class="edit-button activated pencil mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoEditarCliente2")}} </p>
                        <img height="20%" width="20%" class="mt-2 mb-4" src="assets/hencok/btnactualizar.png"
                                alt="nuevocliente">
                        <p>{{getByCodigo("web_textoEditarCliente3")}} </p>
                        <p>{{getByCodigo("web_textoEditarCliente4")}} </p>
                        <img *ngIf="isAdmin" height="80%" width="80%" class="mt-2 mb-4" src="assets/hencok/formeditarcliente.png"
                                alt="formulariocliente">

                </div>
                <div *ngIf="node.name=='eliminarCliente'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoEliminarCliente1")}} <a
                                        class="delete-button activated mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoEliminar")}} </p>
                        <img height="50%" width="50%" class="mt-2 mb-4" src="assets/hencok/modalborrarcliente.png"
                                alt="formulariocliente">
                        <p *ngIf="isAdmin">El cliente se quedara desactivado en la base de datos pero si quiere eliminarlo completamente deberá
                                 contactar con el administrador de la base de datos para que proceda con la eliminacion completa</p>
                        <p *ngIf="!isAdmin">En caso de que quiera eliminar toda su informacion debera contactar con su administrador</p>
                </div>



                <div *ngIf="node.name=='creargasing'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoCrearGasIng1")}}</p>
                        <img height="30%" width="30%" class="mt-2 mb-4" src="assets/hencok/creargasing.png"
                                alt="nouevogasing">
                        <p>{{getByCodigo("web_textoCrearGasIng2")}}</p>


                </div>
                <div *ngIf="node.name=='visualizargasing'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoGasIngPagPrin")}} <a
                                        class="eye-button activated mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoVisualizarGasIng1")}}</p>
                        <p>{{getByCodigo("web_textoVisualizarGasIng2")}}</p>
                        <img height="30%" width="30%" class="mt-2 mb-4" src="assets/hencok/visualizacionarchivo.png"
                                alt="visualizararchivo">
                </div>
                <div *ngIf="node.name=='editargasing'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoGasIngPagPrin")}} <a
                                        class="edit-button activated pencil mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p *ngIf="isAdmin">{{getByCodigo("web_textoEditarGasIng1")}}</p>
                        <p *ngIf="!isAdmin">{{getByCodigo("web_textoEditarGasIng2")}}<strong>{{getByCodigo("web_stextoCrearFactura")}}</strong>{{getByCodigo("web_textoEditarGasIng3")}}<strong>{{getByCodigo("web_stextoEditarFactura")}}</strong>{{getByCodigo("web_textoEditarGasIng4")}}</p>
                        <p *ngIf="!isAdmin">{{getByCodigo("web_textoEditarGasIng5")}}</p>
                </div>
                <div *ngIf="node.name=='eliminargasing'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoGasIngPagPrin")}} <a
                                        class="delete-button activated mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p *ngIf="!isAdmin">{{getByCodigo("web_textoEliminarGasIng1")}}<strong>{{getByCodigo("web_stextoCrearFactura")}}</strong>{{getByCodigo("web_textoEliminarGasIng2")}}<strong>{{getByCodigo("web_stextoEliminarFactura")}}</strong></p>
                        
                        <p>{{getByCodigo("web_textoEliminar")}} </p>
                       
                        <p *ngIf="!isAdmin">{{getByCodigo("web_textoEliminarGasIng3")}}</p>
                        <img height="50%" width="50%" class="mt-2 mb-4" src="assets/hencok/modalelimgasing.png"
                                alt="eliminargasing">
                        
                </div>


                <div *ngIf="node.name=='cearfactura'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoCrearFacura1")}}</p>
                        <img height="30%" width="30%" class="mt-2 mb-4" src="assets/hencok/nuevafactu.png"
                                alt="nouevafactura">

                        <p>{{getByCodigo("web_textoCrearFacura2")}}{{getByCodigo("web_textoCrearFacura3")}}</p>
                        <img height="30%" width="30%" class="mt-2 mb-4" src="assets/hencok/botonesguardfactura.png"
                                alt="nouevafactura">
                        
                        <p *ngIf="!isAdmin">{{getByCodigo("web_textoCrearFacura4")}}</p>
                </div>

                <div *ngIf="node.name=='visualizarfactura'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoFacuraPagPrin")}}<a
                                        class="eye-button activated mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoVisualizarFacura1")}}</p>
                </div>
                <div *ngIf="node.name=='editarfactura'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoFacuraPagPrin")}}<a
                                        class="edit-button activated pencil mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p *ngIf="isAdmin">{{getByCodigo("web_textoEditarFacura1")}}</p>
                        <p *ngIf="!isAdmin">{{getByCodigo("web_textoEditarFacura2")}}</p>
                        <p *ngIf="!isAdmin">{{getByCodigo("web_textoEditarFacura3")}}</p>
                </div>
                <div *ngIf="node.name=='eliminarfactura'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoFacuraPagPrin")}}<a
                                        class="delete-button activated mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoEliminar")}} </p>
                        <p *ngIf="!isAdmin">{{getByCodigo("web_textoEliminarFacura1")}}</p>
                        <img height="50%" width="50%" class="mt-2 mb-4" src="assets/hencok/modalelimfactu.png"
                                alt="eliminargasing">
                </div>


                <div *ngIf="node.name=='cearimpuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoCrearImpuesto1")}}</p>
                        <img height="30%" width="30%" class="mt-2 mb-4" src="assets/hencok/nuevomodelo.png"
                                alt="nuevomodelo">

                        <p>{{getByCodigo("web_textoCrearImpuesto2")}}{{getByCodigo("web_textoCrearImpuesto3")}}</p>

                </div>
                <div *ngIf="node.name=='visualizarimpuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoImpuestoPagPrin")}}<a
                                        class="eye-button activated mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoVisualizarImpuesto1")}}</p>
                </div>
                <div *ngIf="node.name=='editarimpuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoImpuestoPagPrin")}}<a
                                        class="edit-button activated pencil mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoEditarImpuesto1")}}
                        </p>
                </div>
                <div *ngIf="node.name=='eliminarimpuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_textoImpuestoPagPrin")}}<a
                                        class="delete-button activated mt-2 jusify-content-center align-items-center"></a>
                        </p>
                        <p>{{getByCodigo("web_textoEliminar")}} </p>
                        <img height="50%" width="50%" class="mt-2 mb-4" src="assets/hencok/elimimpuesto.png"
                                alt="eliminargasing">
                </div>

                <div *ngIf="node.name=='calcularITP'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_calcularITP1")}}</p> <img height="15%" width="15%" class="mt-2 mb-4" src="assets/autoescuela/ButCalcular.png"
                        alt="calcular"><p>{{getByCodigo("web_calcularITP2")}}</p>

                </div>
                <div *ngIf="node.name=='presupuestoITP'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_presupuestoITP1")}}
                        </p><img height="30%" width="30%" class="mt-2 mb-4" src="assets/autoescuela/ButGeneratePresupuesto.png"
                        alt="generarpresupuesto">
                        <p>{{getByCodigo("web_presupuestoITP2")}}</p>
                </div>



                <div *ngIf="node.name=='createPresupuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_createPresupuesto1")}}</p><img height="30%" width="30%" class="mt-2 mb-4" src="assets/autoescuela/ButNewPresupuesto.png"
                        alt="nuevopresupuesto"><p>{{getByCodigo("web_createPresupuesto2")}}</p><img height="30%" width="30%" class="mt-2 mb-4" src="assets/autoescuela/Butspresupuesto1.png"
                        alt="botonpresupuesto">   
                </div>

                <div *ngIf="node.name=='presupuestoToTramite'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_presupuestoToTramite1")}}</p><img height="30%" width="30%" class="mt-2 mb-4" src="assets/autoescuela/Butspresupuesto2.png"
                        alt="botonpresupuesto"><p>{{getByCodigo("web_presupuestoToTramite2")}}</p>   
                </div>

                <div *ngIf="node.name=='presupuestoToNewTramite'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_presupuestoToNewTramite1")}}</p><img height="30%" width="30%" class="mt-2 mb-4" src="assets/autoescuela/Butspresupuesto2.png"
                        alt="botonpresupuesto"><p>{{getByCodigo("web_presupuestoToNewTramite2")}}</p>   
                </div>

                <div *ngIf="node.name=='editPresupuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_editPresupuesto1")}}<a
                                class="edit-button activated pencil mt-2 jusify-content-center align-items-center"></a>{{getByCodigo("web_editPresupuesto2")}}</p><img height="30%" width="30%" class="mt-2 mb-4" src="assets/autoescuela/Butspresupuesto2.png"
                                alt="botonpresupuesto">   
                </div>

                <div *ngIf="node.name=='visualizarPresupuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_visualizarPresupuesto1")}}<a
                                class="eye-button activated pencil mt-2 jusify-content-center align-items-center"></a>{{getByCodigo("web_visualizarPresupuesto2")}}</p>   
                </div>

                <div *ngIf="node.name=='eliminarPresupuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_eliminarPresupuesto1")}}<a
                                class="delete-button activated pencil mt-2 jusify-content-center align-items-center"></a>{{getByCodigo("web_eliminarPresupuesto2")}}</p> 
                                <img height="30%" width="30%" class="mt-2 mb-4" src="assets/autoescuela/DialogPresupuesto.png" alt="dialogpresupuesto">  
                </div>

                

                <div *ngIf="node.name=='createTramite'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_createTramite1")}}</p> <img height="60%" width="60%" class="mt-2 mb-4" src="assets/autoescuela/CardList.png" alt="cardlist">  
                        <p>{{getByCodigo("web_createTramite2")}}</p> <img height="20%" width="20%" class="mt-2 mb-4" src="assets/autoescuela/ButGuardar.png" alt="guardar">  
                        <p>{{getByCodigo("web_createTramite3")}}</p>
                </div>

                <div *ngIf="node.name=='tramiteToPresupuesto'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_tramiteToPresupuesto1")}}</p><img height="60%" width="60%" class="mt-2 mb-4" src="assets/autoescuela/PntPresupuesto.png" alt="presupuesto">
                        <p>{{getByCodigo("web_tramiteToPresupuesto2")}}</p>
                </div>

                <div *ngIf="node.name=='tramiteToPresupuestoCustom'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_tramiteToPresupuestoCustom1")}}</p><img height="60%" width="60%" class="mt-2 mb-4" src="assets/autoescuela/PntPresupuesto.png" alt="presupuesto"><p>{{getByCodigo("web_tramiteToPresupuestoCustom2")}}<a
                                class="edit-button activated pencil mt-2 jusify-content-center align-items-center"></a>{{getByCodigo("web_tramiteToPresupuestoCustom3")}}</p>   
                </div>

                <div *ngIf="node.name=='selectPresupuestoTramite'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_selectPresupuestoTramite1")}}</p>
                        <img height="60%" width="60%" class="mt-2 mb-4" src="assets/autoescuela/PntPresupuesto.png" alt="presupuesto"> 
                        <p>{{getByCodigo("web_selectPresupuestoTramite2")}}</p><img height="60%" width="60%" class="mt-2 mb-4" src="assets/autoescuela/PntSelectPresupuesto.png" alt="selectpresupuesto">   
                </div>

                <div *ngIf="node.name=='cumplimetarDocumentos'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_cumplimetarDocumentos1")}}</p>
                        <img height="20%" width="20%" class="mt-2 mb-4" src="assets/autoescuela/DocsCuplimentar.png" alt="docs">
                        <p>{{getByCodigo("web_cumplimetarDocumentos2")}}</p>   
                </div>

                <div *ngIf="node.name=='sendNotificaciones'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_sendNotificaciones")}}</p>  
                        <img height="60%" width="60%" class="mt-2 mb-4" src="assets/autoescuela/PntNotificacion.png" alt="notificacion">
                </div>

                <div *ngIf="node.name=='visualizarTramite'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_visualizarTramite1")}}<a
                                class="eye-button activated pencil mt-2 jusify-content-center align-items-center"></a>{{getByCodigo("web_visualizarTramite2")}}</p>   
                </div>

                <div *ngIf="node.name=='editarTramite'" class="mb-4 mt-2">
                        <p>{{getByCodigo("web_editarTramite1")}}<a class="edit-button activated pencil mt-2 jusify-content-center align-items-center"> 
                        </a>{{getByCodigo("web_editarTramite2")}}</p> <img height="20%" width="20%" class="mt-2 mb-4" src="assets/autoescuela/ButGuardar.png" alt="guardar">
                </div>


        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div class="mat-tree-node jusify-content-center">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                                class="mr-2">
                                <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                        </button>
                        <h1 *ngIf="treeControl.isExpanded(node)">{{node.name}} </h1>
                        <h2 *ngIf="!treeControl.isExpanded(node)">{{node.name}}</h2>
                </div>

                <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                        <ng-container matTreeNodeOutlet hidden=true>


                        </ng-container>

                </div>


        </mat-nested-tree-node>
</mat-tree>