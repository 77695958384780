<div [hidden]="mostrarFormulario" [hidden]="editarFormulario">
    <div *ngIf="!selectPresupuesto" class="d-flex flex-row justify-content-between align-items-center full-form-group">
        <h1 class="m-0">Gestión de Presupuestos</h1>
        <div class="d-flex flex-row justify-content-between">
            <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true)">
                <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nuevo Presupuesto</span>
            </button>
        </div>

    </div>
    <div *ngIf="!selectPresupuesto" class="full-form-group ">
        <div class="subtitle">
            <h5>Listado de todos los presupuestos</h5>
        </div>
    </div>
    <div *ngIf="selectPresupuesto" class="d-flex flex-row justify-content-between align-items-center full-form-group">
        <h1 class="m-0">Selección de presupuesto</h1>
        <div class="d-flex flex-row justify-content-between">
        </div>
    </div>
    <div class="full-form-group">
        <div *ngIf="!selectPresupuesto" class="mt-4">

        </div>
        <div class="w-100 mt-4 border-bottom">
            <div class="ordering-table">
                <div class="card-with-invoice filter-container"><input type="text" class="searchinput"
                        placeholder="Nº PRESUPUESTO"
                        (keyup)="doFilter($event, 'codigoPresupuesto', 'LIKE', 'STRING')" />
                    <button (click)="changeSorts('codigoPresupuesto', 'codigoPresupuesto')"><a
                            [ngClass]="sortArray['codigoPresupuesto']==='ASC' ? 'asc-sort': sortArray['codigoPresupuesto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-date filter-container">
                    <input type="text" class="searchinput" onfocus="(this.type='date')"
                        onblur="if(this.value==''){this.type='text'}" placeholder="FECHA" (change)="
                            doFilter($event, 'fecha', 'CURRENT_DATE', 'DATE')
                            " />
                    <button (click)="changeSorts('codigoPresupuesto', 'codigoPresupuesto')"><a
                            [ngClass]="sortArray['codigoPresupuesto']==='ASC' ? 'asc-sort': sortArray['codigoPresupuesto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-client filter-container">
                    <input type="text" class="searchinput" placeholder="CLIENTE"
                        (keyup)="doFilter($event, ['cliente.nombre', 'cliente.nombreEmpresa'], 'LIKE_OR', 'STRING')" />
                    <button
                        (click)="changeSorts(['cliente.nombreEmpresa', 'cliente.nombre'], ['nombreEmpresa', 'nombre'])"><a
                            [ngClass]="sortArray['nombre']==='ASC' ? 'asc-sort': sortArray['nombre']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-nif filter-container">
                    <input type="text" class="searchinput" placeholder="NIF/CIF"
                        (keyup)="doFilter($event, ['cliente.dni', 'cliente.cif'], 'LIKE_OR', 'STRING')" />
                    <button (click)="changeSorts(['cliente.dni', 'cliente.cif'], ['dni', 'cif'])"><a
                            [ngClass]="sortArray['dni']==='ASC' ? 'asc-sort': sortArray['dni']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-base filter-container">
                    <input type="text" class="searchinput" placeholder="BASE IMPONIBLE"
                        (keyup)="doFilter($event, 'baseImponible', 'LIKE', 'DOUBLE')" />
                    <button (click)="changeSorts('baseImponible', 'baseImponible')"><a
                            [ngClass]="sortArray['baseImponible']==='ASC' ? 'asc-sort': sortArray['baseImponible']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-iva filter-container">
                    <input type="text" class="searchinput" placeholder="IMPORTE IVA"
                        (keyup)="doFilter($event, 'importeIva', 'LIKE', 'DOUBLE')" />
                    <button (click)="changeSorts('importeIva', 'importeIva')"><a
                            [ngClass]="sortArray['importeIva']==='ASC' ? 'asc-sort': sortArray['importeIva']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-irpf filter-container">
                    <input type="text" class="searchinput" placeholder="IMPORTE IRPF"
                        (keyup)="doFilter($event, 'importeIrpf', 'LIKE', 'DECIMAL')" />
                    <button (click)="changeSorts('importeIrpf', 'importeIrpf')"><a
                            [ngClass]="sortArray['importeIrpf']==='ASC' ? 'asc-sort': sortArray['importeIrpf']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-total filter-container">
                    <input type="text" class="searchinput" placeholder="TOTAL"
                        (keyup)="doFilter($event, 'total', 'LIKE', 'DOUBLE')" />
                    <button (click)="changeSorts('importeIrpf', 'importeIrpf')"><a
                            [ngClass]="sortArray['importeIrpf']==='ASC' ? 'asc-sort': sortArray['importeIrpf']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <div class="card-with-actions">
                    <div class="buttons">
                        ACCIONES
                    </div>
                </div>
            </div>
        </div>
        <div class="card w-100 card-generic border-0">
            <ul class="list-group list-group-flush">
                <div *ngIf="presupuestos?.length == 0"
                    class="d-flex flex-row justify-content-center empty-data-message my-4">

                    <span>No se han encontrado registros que mostrar</span>

                </div>
                <div *ngIf="presupuestos != null">
                    <div class="list-group-item p-3 rounded shadow" *ngFor="let presupuesto of presupuestos">
                        <div class="row-with-cards">
                            <div class="card border-0 card-with-invoice " (click)="downloadInvoice(presupuesto)">
                                <p class="cut-text"
                                    [title]="presupuesto.codigoPresupuesto?presupuesto.codigoPresupuesto:''">

                                    {{presupuesto.codigoPresupuesto}}
                                </p>
                            </div>
                            <div class="card border-0 card-with-date ">
                                <p class="cut-text"
                                    [title]="getDateToFilter(presupuesto.fecha)?getDateToFilter(presupuesto.fecha):''">
                                    {{getDateToFilter(presupuesto.fecha)}}
                                </p>
                            </div>
                            <div *ngIf="presupuesto.cliente.nombreEmpresa" class="card border-0 card-with-client ">
                                <p class="cut-text"
                                    [title]="presupuesto.cliente.nombreEmpresa?presupuesto.cliente.nombreEmpresa:''">
                                    {{presupuesto.cliente.nombreEmpresa}}
                                </p>
                            </div>
                            <div *ngIf="!presupuesto.cliente.nombreEmpresa" class="card border-0 card-with-client ">
                                <p class="cut-text" [title]="presupuesto.cliente.nombre?presupuesto.cliente.nombre:''">
                                    {{presupuesto.cliente.nombre}}
                                </p>
                            </div>
                            <div *ngIf="presupuesto.cliente.cif" class="card border-0 card-with-nif ">
                                <p class="cut-text" [title]="presupuesto.cliente.cif?presupuesto.cliente.cif:''">
                                    {{presupuesto.cliente.cif}}
                                </p>
                            </div>
                            <div *ngIf="!presupuesto.cliente.cif" class="card border-0 card-with-nif ">
                                <p class="cut-text" [title]="presupuesto.cliente.dni?presupuesto.cliente.dni:''">
                                    {{presupuesto.cliente.dni}}
                                </p>
                            </div>
                            <div class="card border-0 card-with-base ">
                                <p class="cut-text"
                                    [title]="presupuesto.baseImponible.toFixed(2)?presupuesto.baseImponible.toFixed(2)+'€':''">
                                    {{presupuesto.baseImponible.toFixed(2)}}€
                                </p>
                            </div>
                            <div class="card border-0 card-with-iva ">
                                <p class="cut-text"
                                    [title]="presupuesto.importeIva.toFixed(2)?presupuesto.importeIva.toFixed(2)+'€':''">
                                    {{presupuesto.importeIva.toFixed(2)}}€
                                </p>
                            </div>
                            <div class="card border-0 card-with-irpf ">
                                <p class="cut-text"
                                    [title]="presupuesto.importeIrpf.toFixed(2)?presupuesto.importeIrpf.toFixed(2)+'€':''">
                                    {{presupuesto.importeIrpf.toFixed(2)}}€
                                </p>
                            </div>
                            <div class="card border-0 card-with-total ">
                                <p class="cut-text"
                                    [title]="presupuesto.total.toFixed(2)?presupuesto.total.toFixed(2)+'€':''">
                                    {{presupuesto.total.toFixed(2)}}€
                                </p>
                            </div>


                            <div *ngIf="!selectPresupuesto" class="card-with-actions">

                                <div class="button-center">
                                    <div class="buttons">
                                        <a (click)="openPDF(presupuesto)" class="eye-button activated action-button"
                                            title="Ver"></a>
                                        <a *ngIf="!(presupuesto.estado.nombre=='RECHAZADO' || presupuesto.estado.nombre=='PAGADO')"
                                            (click)="setEditarPresupuestoSend(true, presupuesto)"
                                            class="edit-button activated pencil button-ed" title="Editar"></a>
                                        <a *ngIf="presupuesto.estado.nombre=='RECHAZADO' || presupuesto.estado.nombre=='PAGADO'"
                                            class="edit-button activated pencil button-ed button-off"></a>
                                        <a (click)="downloadInvoice(presupuesto)"
                                            class="download-button activated action-button" title="Descargar"></a>
                                        <a *ngIf="!(presupuesto.estado.nombre=='RECHAZADO' || presupuesto.estado.nombre=='PAGADO')"
                                            (click)="dialog.showModal()" class="delete-button activated action-button"
                                            title="Eliminar"></a>
                                        <a *ngIf="presupuesto.estado.nombre=='RECHAZADO' || presupuesto.estado.nombre=='PAGADO'  "
                                            class="delete-button activated action-button button-off"></a>
                                        <dialog #dialog>
                                            <div class="m-3 justify-content-center align-items-center">
                                                <div class="m-4 align-items-center">
                                                    <a class="delete-button dialogborrar"></a>

                                                    <h5 id="textdialog">¿Desea confirmar la eliminación del presupuesto
                                                        #ID{{presupuesto.id}}?
                                                    </h5>
                                                </div>
                                                <div class="d-flex justify-content-end m-4">
                                                    <button (click)="dialog.close()" id="btcancel"
                                                        class="btn btn-primary mx-1 mt-5"
                                                        type="submit">Cancelar</button>
                                                    <button (click)="deletePresupuesto(presupuesto,$event,dialog)"
                                                        id="btacept" class="btn btn-primary mx-1 mt-5"
                                                        [disabled]="isDelete">
                                                        <div class="spinner-border spinner-border-sm" *ngIf="isDelete">
                                                        </div>
                                                        <span class="align-middle"> Confirmar</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </dialog>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="selectPresupuesto" class="card-with-actions">
                                <div class="button-center">
                                    <div class="buttons">
                                        <a class="accept-button activated iconAccept"
                                            (click)="selectedPresupuesto(presupuesto)"></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </ul>
            <div *ngIf="presupuestos == null" class="d-flex flex-row justify-content-center empty-data-message my-4">
                Este usuario no dispone de presupuestos
            </div>
        </div>
        <mat-paginator *ngIf="mostrarPag" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements"
            (page)="nextPage($event)"> </mat-paginator>
    </div>
</div>
<dialog id="dialogPayment" #dialogPayment>
    <div class="m-3 justify-content-center align-items-center">
        <div class="m-4 text-center ">
            <a *ngIf="paymentData.status=='paid'" class="dialog-icon accept-button"></a>
            <a *ngIf="paymentData.status=='unpaid'" class="dialog-icon dismiss-button"></a>
            
        </div>
        <div class="text-center">
            <label>La orden de compra "{{paymentData.orderId}}" asociada al presupuesto "{{paymentData.codPresu}}" {{paymentData.statusText}}</label>
        </div>
        <div class="d-flex justify-content-center m-4">
            <button (click)="dialogPayment.close()" class="btn btn-primary align-middle"
                type="submit">OK</button>
        </div>
    </div>
</dialog>
<div [hidden]="!mostrarFormulario">
    <app-presupuesto [itpInfo]="undefined" (newItemEvent)="setMostrarFormularioChild()"></app-presupuesto>
</div>
<div [hidden]="!editarFormulario">
    <app-presupuesto-filled [infoPresupuesto]="infoPresupuestoEnv"
        (newItemEvent)="setEditarFormularioChild()"></app-presupuesto-filled>
</div>