
<div class="page__content_hencok">
    <div class="content__inner_hencok">
        <!-- (click)="closeSidebar($event) "-->
            <div class="container_hencok">
                <div class="left d-flex flex-column justify-content-between align-items-center scrollMenu" *ngIf="menuOut" [class.show]="(getScreenWidth>768)"  id="navbarSupportedContent" aria-controls="navbarSupportedContent"> 
                    <div class="d-flex- flex-column rigthView">
                        <div class="d-flex flex-wrap logo-wrapper" (click)="menuOut=!menuOut">
                            <img src="assets/hencok/logo-hencok.png" alt="Logo Hencok Asesores"
                                class="logo">
                            <hr>
                        </div>
                        <div class="sideMenu">
                            <div *ngFor="let menu of menuListado" id="lista-crud" class="d-flex flex-row">
                                <li *ngIf="menu.listadoHijos &&  menu.listadoHijos.length == 0 && menu.route != 'perfil' && menu.action < 20 "
                                    class="router-group title-menus" 
                                    (click)="navegar(menu.url)"
                                    routerLinkActive="active {{getCorporativo()}}"
                                    attr.aria-label="{{menu.nombre}}">
                                    <img class="" src="{{ environment.API_URL }}{{ menu.icono }}" alt="Icono menú {{menu.nombre}}"/> 
                                    {{ menu.nombre.toUpperCase() }}
                                </li>
                            </div>
                        </div>
                    </div>
                    <div class="datos-personales rigthView">    
                        <hr>
                        <div class="d-flex flex-row align-items-center mb-2">
                            <div class="user-wrapper">
                                <span *ngIf="tienesNotificaciones" class="badge-wrapper position-relative" title="Tines notificaciones">
                                    <span class="badge badge-element position-absolute"></span>
                                </span>  
                               
                                <div class="user-image-wrapper rounded-circle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="dropdown-menu pt-3 pb-3" aria-labelledby="dropdownMenuButton">
                                        <div *ngFor="let menu of menuListado">
                                            <ng-template [ngIf]="menu.action ==20">
                                                <a class="dropdown-item d-flex flex-row align-items-center" (click)="navegar(menu.url)" attr.aria-label="{{ menu.nombre }}">
                                                    <img src="{{ environment.API_URL }}{{ menu.icono }}"
                                                    class="iconoMenu align-self-end" alt="Icono {{ menu.nombre }}">
                                                    <span class="ml-3">{{ menu.nombre }}</span> 
                                                </a>
                                            </ng-template>
                                          
                                            <ng-template [ngIf]="menu.action ==21">
                                                <a class="dropdown-item d-flex flex-row align-items-center" (click)="navegar(menu.url)" attr.aria-label="{{ menu.nombre }}">
                                                    <img src="{{ environment.API_URL }}{{ menu.icono }}"
                                                    class="iconoMenu align-self-end" alt="Icono {{ menu.nombre }}">
                                                    <span class="ml-3">{{ menu.nombre }}</span> 
                                                </a>
                                            </ng-template>
                                            
                                            <ng-template [ngIf]="menu.action ==22">
                                                <a class="dropdown-item d-flex flex-row align-items-center" (click)="navegar(menu.url)" attr.aria-label="{{ menu.nombre }}">
                                                    <img src="{{ environment.API_URL }}{{ menu.icono }}"
                                                    class="iconoMenu align-self-end" alt="Icono {{ menu.nombre }}">
                                                    <span class="ml-3">{{ menu.nombre }}</span> 
                                                </a>
                                            </ng-template>
                                            <ng-template [ngIf]="menu.action == 23">
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item d-flex flex-row align-items-center" (click)="logout()" aria-label="Cerrar sesión">
                                                    <img src="{{ environment.API_URL }}{{ menu.icono }}"
                                                    class="iconoMenu align-self-end" alt="Icono {{ menu.nombre }}">
                                                    <span class="ml-3">{{ menu.nombre }}</span> 
                                                </a>
                                                <div class="dropdown-item d-flex flex-row justify-content-around legal mt-2">
                                                    <a (click)="navegar('client-hencok/politica-privacidad')">Privacidad</a>
                                                    <a (click)="navegar('client-hencok/aviso-legal')">Aviso legal</a>
                                                </div>
                                            </ng-template>
                                        </div>
                                        
                                      </div>
                                    <div class="iconName rounded-circle">
                                        <p *ngIf="currentUser?.imgPerfil==undefined">
                                            {{getFirstLetter(currentUser?.nombre)}}{{getFirstLetter(currentUser?.apellido1)}}</p>
                                        <img *ngIf="currentUser?.imgPerfil!=undefined" src="data:image;base64,{{currentUser.imgPerfil}}" width="100%"
                                            height="100%" class="rounded-circle">
                                    </div>                                  
                                </div>
                            </div>
                            
                            <div class="d-flex flex-column ml-3">
                                <span class="email-usuario font-weight-light" title="{{currentUser.email}}">{{ currentUser.email }}</span>
                                <span class="nombre-usuario" title="{{ currentUser.nombre }} {{ currentUser.apellido1 }}">{{ currentUser.nombre }} {{ currentUser.apellido1 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!menuOut" class="leftSm d-flex flex-column justify-content-between align-items-center scrollMenu" [class.show]="(getScreenWidth>768)"  id="navbarSupportedContent" aria-controls="navbarSupportedContent"> 
                    <div class="d-flex- flex-column rigthView">
                        <div class="d-flex flex-wrap logo-wrapper" (click)="menuOut=!menuOut">
                            <img src="assets/hencok/logo_h_white.png" alt="Logo Hencok Asesores"
                                class="smallLogo">
                            <hr>
                        </div>
                        <div class="sideMenu">
                            <div *ngFor="let menu of menuListado" id="lista-crud-small" class="d-flex flex-row">
                                <li *ngIf="menu.listadoHijos &&  menu.listadoHijos.length == 0 && menu.route != 'perfil' && menu.action < 20 "
                                    class="router-group d-flex justify-content-center" 
                                    (click)="navegar(menu.url)"
                                    routerLinkActive="active {{getCorporativo()}}"
                                    attr.aria-label="{{menu.nombre}}">
                                    <img class="" src="{{ environment.API_URL }}{{ menu.icono }}" alt="Icono menú {{menu.nombre}}"/> 
                                </li>
                            </div>
                        </div>
                    </div>
                    <div class="datos-personales rigthView">    
                        <hr>
                        <div class="d-flex flex-row align-items-center justify-content-center mb-2">
                            <div class="user-wrapper">
                                <span *ngIf="tienesNotificaciones" class="badge-wrapper position-relative" title="Tines notificaciones">
                                    <span class="badge badge-element position-absolute"></span>
                                </span>  
                               
                                <div class="user-image-wrapper rounded-circle" type="button" id="dropdownMenuButtonSm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="dropdown-menu pt-3 pb-3" aria-labelledby="dropdownMenuButton">
                                        <div class="d-flex flex-column ml-3">
                                            <span class="email-usuario font-weight-light" title="{{currentUser.email}}">{{ currentUser.email }}</span>
                                            <span class="nombre-usuario" title="{{ currentUser.nombre }} {{ currentUser.apellido1 }}">{{ currentUser.nombre }} {{ currentUser.apellido1 }}</span>
                                        </div>
                                        <div class="dropdown-divider"></div>
                                        <div *ngFor="let menu of menuListado">
                                            <ng-template [ngIf]="menu.action ==20">
                                                <a class="dropdown-item d-flex flex-row align-items-center" (click)="navegar(menu.url)" attr.aria-label="{{ menu.nombre }}">
                                                    <img src="{{ environment.API_URL }}{{ menu.icono }}"
                                                    class="iconoMenu align-self-end" alt="Icono {{ menu.nombre }}">
                                                    <span class="ml-3">{{ menu.nombre }}</span>
                                                </a>
                                            </ng-template>
                                          
                                            <ng-template [ngIf]="menu.action ==21">
                                                <a class="dropdown-item d-flex flex-row align-items-center" (click)="navegar(menu.url)" attr.aria-label="{{ menu.nombre }}">
                                                    <img src="{{ environment.API_URL }}{{ menu.icono }}"
                                                    class="iconoMenu align-self-end" alt="Icono {{ menu.nombre }}">
                                                    <span class="ml-3">{{ menu.nombre }}</span> 
                                                </a>
                                            </ng-template>
                                            
                                            <ng-template [ngIf]="menu.action ==22">
                                                <a class="dropdown-item d-flex flex-row align-items-center" (click)="navegar(menu.url)" attr.aria-label="{{ menu.nombre }}">
                                                    <img src="{{ environment.API_URL }}{{ menu.icono }}"
                                                    class="iconoMenu align-self-end" alt="Icono {{ menu.nombre }}">
                                                    <span class="ml-3">{{ menu.nombre }}</span> 
                                                </a>
                                            </ng-template>
                                            <ng-template [ngIf]="menu.action == 23">
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item d-flex flex-row align-items-center" (click)="logout()" aria-label="Cerrar sesión">
                                                    <img src="{{ environment.API_URL }}{{ menu.icono }}"
                                                    class="iconoMenu align-self-end" alt="Icono {{ menu.nombre }}">
                                                    <span class="ml-3">{{ menu.nombre }}</span> 
                                                </a>
                                                <div class="dropdown-item d-flex flex-row justify-content-around legal mt-2">
                                                    <a (click)="navegar('client-hencok/politica-privacidad')">Privacidad</a>
                                                    <a (click)="navegar('client-hencok/aviso-legal')">Aviso legal</a>
                                                </div>
                                            </ng-template>
                                        </div>
                                        
                                    </div>
                                        <div class="iconName rounded-circle">
                                            <p *ngIf="currentUser?.imgPerfil==undefined">
                                                {{getFirstLetter(currentUser?.nombre)}}{{getFirstLetter(currentUser?.apellido1)}}</p>
                                            <img *ngIf="currentUser?.imgPerfil!=undefined" src="data:image;base64,{{currentUser.imgPerfil}}" width="100%"
                                                height="100%" class="rounded-circle">
                                        </div>         
                                    </div>                     
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="getScreenWidth>=768 ? 'center-Hencok':'center-Hencok-small'">
                    <router-outlet></router-outlet>
                </div>
            </div>
    </div>
</div>

