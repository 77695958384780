export const environment = {
    //production: false,
    production: true,
    environment: 'hencok',
    //NECESARIO CAMBIAR CUANDO SE INSTALA EN PRO O EN PRE
    //url de preproducción   
    //API_URL: "https://hencok-pre.aeioros.com/portal",
    //url de producción
    API_URL: "https://gestion.hencokasesores.com/portal",
    //url de desarrollo  
    //API_URL: "http://localhost:7090", 
    optionalModules: [],

    //url de producción  
    REDSYS_URL: 'https://sis.redsys.es/sis/realizarPago',
    //url de preproducción/desarrollo  
    //REDSYS_URL: 'https://sis-t.redsys.es:25443/sis/realizarPago',

    TITLE_HEAD:'Oficina virtual - Gestoría Hencok',
    ICON_TITLE_HEAD: 'assets/hencok/favicon.ico',
    TITLE_REGISTER: 'Bienvenido a la Oficina Virtual de Hencok Asesores',
    TEXT_REGISTER: 'Gestione sus trámites fácilmente desde cualquier lugar y en cualquier momento.',
    ICON_LATERAL: 'assets/hencok/logo_hencok_color.png',
    LOGO_HEAD: 'assets/emvs/EMVS-letras-color.png',
    TIENE_CALENDARIO: true,
    TIENE_INFO: true,
    TIENE_BUSQUEDA: true,
    FOOTER_INFO_TITLE: null,
    FOOTER_INFO: null,
    COPYRIGHT: null,
};