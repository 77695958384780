import { Component, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { ClienteService } from "src/app/_services/hencok/cliente.service";
import { IngresosGastosService } from "src/app/_services/hencok/ingresos-gastos.service";
import { VisualizarNotificacionesService } from "src/app/_services/hencok/visualizar-notificaciones.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { SharedDataService } from "src/app/_services/shared-data.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { UserService } from "src/app/_services/user.service";

@Component({
  selector: "app-visualizar-notificaciones",
  templateUrl: "./visualizar-notificaciones.component.html",
  styleUrls: ["./visualizar-notificaciones.component.css"],
})
export class VisualizarNotificacionesComponent {
  notificaciones: any = [];
  notificacionescopia: any = [];
  notificacion: any;
  notificacionesInicio: any;

  usuario: any = null;
  usuarios = [];
  usuariosInicio = [];
  sortedUsuarios = [];
  filteredUsuarios = [];
  pageSize = 5;
  pageIndex = 2;
  pageSizeOptions = [5, 10, 25, 50, 100];
  popup
  descripcion
  titulo
  userLogged: any = -1;
  userAdmin: Boolean = false;
  isSortedByName: string = "";
  isSortedById: string = "";
  isSortedByEmail: string = "";
  isSortedByTelephone: string = "";
  isSortedByDocumento: string = "";

  totalElements: number = 0;

  showFiller = false;

  isActivo = true;
  isInactivo = true;

  msgError = null;

  // -- Variables para pasar al componente
  editar: Boolean = false;
  mostrarFormulario: Boolean = false;
  searchText: String = "";

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;

  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _sharedDataService: SharedDataService,
    private _router: Router,
    private _tokenStorageService: TokenStorageService,
    private _clienteService: ClienteService,
    private visualizarNotificaciones: VisualizarNotificacionesService,
    private gastosservice: IngresosGastosService
  ) { }

  ngOnInit(): void {
    this.userLogged = this._tokenStorageService.getUser();
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 3) {
      this.visualizarNotificaciones.setfechaAceptacionAdmin(this.userLogged.id).subscribe();
      this.userAdmin = true;
    } else {
      this.visualizarNotificaciones.setfechaAceptacion(this.userLogged.id).subscribe();
      this.userAdmin = false;

    }
    this.genNotificationList({ page: "0", size: "5" });




  }







  genNotificationList(request) {
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 3) {
      this.visualizarNotificaciones
        .findByidAdmin({ id: this.userLogged.id, page: request.page, size: request.size })
        .subscribe({
          next: (data) => {
            this.notificaciones = data["content"];
            this.notificacionescopia = []
            for (let index = 0; index < this.notificaciones.length; index++) {
              var auxcuerpo: String = this.notificaciones[index].cuerpo
              var auxasunto: String = this.notificaciones[index].asunto

              console.log(this.notificacionescopia[index])

              this.notificaciones[index].cuerpo;
              if (this.notificaciones[index].cuerpo.length > 550) {
                this.notificaciones[index].cuerpo = this.notificaciones[index].cuerpo.slice(0, 55) + "..33."

              }
              if (this.notificaciones[index].asunto.length > 12) {
                this.notificaciones[index].asunto = this.notificaciones[index].asunto.slice(0, 12) + "..."
              }
              this.notificacionescopia.push({ cuerpo: auxcuerpo, asunto: auxasunto, fechaCreacion: this.notificaciones[index].fechaCreacion, id: this.notificaciones[index].id })
            }


            this.pageIndex;

            this.totalElements = data["totalElements"];
          },
          error: (error) => {
          },
        });
    } else {

      this.visualizarNotificaciones
        .findByIdUsuario({ id: this.userLogged.id, page: request.page, size: request.size })
        .subscribe({
          next: (data) => {
            this.notificaciones = data["content"];
            this.pageIndex;
            this.totalElements = data["totalElements"];
          },
          error: (error) => {
          },
        });
    }
  }
  doPopUp(notificacion) {
    this.notificacionescopia.forEach(element => {
      if (element.id == notificacion.id) {
        this.notificacion = element
      }
    });
    this.titulo = this.notificacion.asunto
    this.descripcion = this.notificacion.cuerpo
    this.popup = true
  }

  /*
    openSidebarDetalles(event: Event, notificacion) {
      var element = document.getElementById("sidebar_detalle_notificacion");
      element.classList.add("active");
      event.stopPropagation();
      this.notificacionescopia.forEach(element => {
        if(element.id==notificacion.id){
          this.notificacion= element
        }
      });
    }
    */


  nextPage(event: PageEvent) {
    const request = {};


    request["page"] = event.pageIndex.toString();
    request["size"] = event.pageSize.toString();
    this.genNotificationList({ page: event.pageIndex.toString(), size: event.pageSize.toString() });
  }

  formatDate(date) {
    let month = "" + date[1];
    let day = "" + date[2];
    const year = date[0];
    let hour = "" + date[3];
    if (hour.length < 2) hour = "0" + hour;
    let min = date[4];

    if (min < 10) min = "0" + min;
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return hour + ":" + min + "  " + day + "-" + month + "-" + year;
  }

  closeSidebar(e: any) {
    console.log(e)
    var element = document?.getElementById("sidebar_detalle_notificacion");
    element?.classList.remove("active");

  }
  static closeSidebarClienteRemoto() {
    var element = document?.getElementById("sidebar_detalle_notificacion");
    element?.classList.remove("active");
  }


}
