<div class="page_content_sidebar">
    <div class="content_inner">
        <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'container2':'container' ">

            <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'center-Hencok':'center'">
                <div *ngIf="!isLoggedIn">
                    <h1>{{environment.TITLE_REGISTER}}</h1>
                    <p>
                        {{environment.TEXT_REGISTER}}
                    </p>
                </div>
                <form *ngIf="!isSuccessful" name="form" (ngSubmit)="onSubmit()" [formGroup]="form" novalidate
                    method="post" enctype="multipart/form-data">
                    <div class="row pt-4">
                        <div class="d-flex justify-content-between col-sm-12 p-0">
                            <div class="col-sm-6 ml-4 d-flex">
                                <h5>Adjuntar Contrato</h5>
                            </div>

                            <div class="col-sm-6 mt-3" *ngIf="!_activatedRoute.snapshot.url[1]"
                                [ngClass]="user.value == 'Seleccionar id' ? 'mb-5' : 'mb-0'">
                                <td><select class="form-control tipo" #user (change)="getUser(user.value)"
                                        class="colorOption">
                                        <option style="display:none">Seleccionar id</option>
                                        <option *ngFor="let usuario of usuarios" [value]="usuario.id"
                                            class="colorOption">
                                            #ID{{ usuario.id }}
                                        </option>
                                    </select></td>
                                <div class="col-sm-12 w-100">
                                    <p><b>{{usuario.nombre}} {{usuario.apellido1}} {{usuario.apellido2}}</b></p>
                                    <p>{{usuario.email}}</p>
                                </div>
                            </div>




                            <div class="col-sm-6 mt-3" *ngIf="_activatedRoute.snapshot.url[1] && !userAdmin">
                                <div class="col-sm-12 w-100">
                                    <p class="colorId">#ID{{userLogged.id}}</p>
                                    <p><b>{{userLogged.nombre}} {{userLogged.apellido1}} {{userLogged.apellido2}}</b>
                                    </p>
                                    <p>{{userLogged.email}}</p>
                                </div>
                            </div>


                            <div *ngIf="_activatedRoute.snapshot.url[1] && userAdmin" class="col-sm-6 mt-3">
                                <div class="col-sm-12 mt-3">
                                    <div class="col-sm-12 w-100">
                                        <p class="colorId">#ID{{usuario.id}}</p>
                                        <p>Nombre : {{usuario.nombre}} {{usuario.apellido1}} {{usuario.apellido2}}</p>
                                        <p>Correo electrónico : {{usuario.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row paddingCustom lineas"
                            [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'border-top':'border'">
                            <div class="col-sm-12">
                                <label class="crear-editar-form requerido">Concepto</label>
                                <input type="text" class="form-control" name="concepto" formControlName="concepto"
                                    minlength="3" maxlength="20"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['concepto'].errors}" />

                            </div>
                            <div *ngIf="false" class="col-sm-3">
                                <label class="crear-editar-form requerido">Tipo</label>
                                <td><select formControlName="tipoContrato" class="form-control" name="tipoContrato"
                                        [ngClass]="{'is-invalid': submitted && this.form.controls['tipoContrato'].errors}">
                                        <option *ngFor="let tipo of tipos" [value]="tipo.id">
                                            {{ tipo.nombre}}
                                        </option>
                                    </select></td>
                            </div>
                            <br>
                            <div class="col-sm-12 ml-0 mb-1 mt-3">
                                <label class="crear-editar-form">Adjuntar Ficheros</label>
                            </div>

                            <div class="body bodyBorder col-sm-12 d-flex">
                                <div class="border border backGround-colorAdjuntar borderAdjuntar adjuntar-area.active col-sm-6"
                                    appDrag (files)="filesDropped($event)">
                                    <div class="border rounded-circle">

                                        <div class="text-center">
                                            <img src="./assets/hencok/imagenArchivo.png" class="rounder-circle">
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <h6 class="texto mt-1">Arrastre y suelte aquí</h6>
                                        <h6 class="texto2">o</h6>
                                        <button class="btn btn-light pruebaBoton ">Seleccionar Archivo</button>
                                        <input id="file" value="file" name="file" class="pruebaInput"
                                            (change)="onFileChange($event)" onclick="event.target.value=null"
                                            type="file" />
                                        <h6 class="indicaciones mt-1">PDF de 20MB máximo. </h6>
                                    </div>

                                </div>
                                <div class="col-sm-6 cardDoc pt-2">
                                    <div *ngFor="let documento of this.arrayDocuments"
                                        class="documento pl-0 pb-1">
                                        <a class="cortar-texto">{{documento['nombre']}}</a>
                                        <div class="border-0 card-width-actions">
                                            <div>
                                                <a (click)="removeFile(arrayDocuments.indexOf(documento))"
                                                    class="delete-button activated boton-eliminar"></a>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-end">
                            <button type="button" class="btn  btn-outline-primary mr-2" (click)="closeSidebar()" [disabled]="submitted">
                                <div>Cancelar</div>
                            </button>
                            <button type="submit" class="btn btn-primary" [disabled]="submitted">
                                <div class="spinner-border spinner-border-sm" *ngIf="uploadDocuments"></div>
                                <div *ngIf="!uploadDocuments">Guardar</div>
                            </button>
                        </div>
                    </div>
                </form>
                <div class="alert alert-success" *ngIf="isSuccessful">
                    Se ha registrado satisfactoriamente!
                </div>
            </div>
        </div>
    </div>
</div>