import { Component } from "@angular/core";
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { FacturaService } from "src/app/_services/hencok/factura.service";
import { NotificationService } from "src/app/_services/notification.service";
import { PdfGenerateHencokService } from "src/app/_services/pdfGenerateHencok/pdf-generate-hencok.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {FormArray,FormControl,FormGroup,UntypedFormControl,UntypedFormGroup,Validators,} from "@angular/forms";
import { Factura } from "src/app/model/hencok/factura";
import { GastosService } from "src/app/_services/hencok/gastos.service";
import { IngresosGastosService } from "src/app/_services/hencok/ingresos-gastos.service";
import { finalize } from "rxjs";
import { GastoIngreso } from "src/app/model/hencok/gasto_ingreso";
import { SharedDataService } from "src/app/_services/shared-data.service";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-gestion-facturas-clientes",
  templateUrl: "./gestion-facturas-clientes.component.html",
  styleUrls: ["./gestion-facturas-clientes.component.css"],
})
export class GestionFacturasClientesComponent {
  constructor(
    private _facturaService: FacturaService,
    private _notificationService: NotificationService,
    private _tokenService: TokenStorageService,
    private _generatePdf: PdfGenerateHencokService,
    private _gastosService: GastosService,
    private _router: Router,
    private _serviceGeneral: ServicioGeneral,
    private _ingresos_gastosService: IngresosGastosService,
    private _sharedDataService: SharedDataService,

  ) {
    this._serviceGeneral.translatePaginator();
  }

  ngOnInit(): void {
    this.getFacturaListByUsuario({ page: "0", size: "5" });
    this.sumUp();
    this.totalesemitidas();

    this._sharedDataService.currentMessage.subscribe((message) =>
      this.filtrar(message)
    );
    this.sortArrayInitialize();
  }

  usuario = this._tokenService.getUser();
  id: number;

  facturas: any;
  total_facturas:any;
  total_facturasCompleto:any;

  facturasRecibidas: any;
  facturasInicio: any;
  facturaEnv: any;
  pageIndex: number = 0;
  pageSize: number = 5;

  total_deuda: number = 0;
  total_pendiente: number = 0;
  total_facturado: any = 0;

  totalElements: number = 0;

  mostrarFormulario: Boolean = false;
  editarFormulario: Boolean = false;
  mostrarRecibidas: Boolean = false;
  submitted: boolean = false;
  uploadDocuments: Boolean = false;

  isSortedById: string = "";
  isSortedByFecha: string = "";
  isSortedByFactura: string = "";
  isSortedByEstado: string = "";
  isSortedByNombre: string = "";
  isDelete = false;

  arrayDocuments = [];
  mostrarPag=true;
  allFacturas: any = [];
  sortArray: any = {};

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };

  //A pesar de no haber campos de tipo formulario, el formulario
  //es necesario para crear una factura de reebolso.
  formFactura: FormGroup = new FormGroup({
    numeroFactura: new FormControl("", Validators.required),
    tipoGasto: new FormControl("", Validators.required),
    fecha_factura: new FormControl(new Date(), Validators.required),
    fecha_vencimiento: new FormControl("", Validators.required),
    importe_iva: new FormControl("", Validators.required),
    subtotal: new FormControl("", Validators.required),
    usuario: new FormControl("", Validators.required),
    detallesFactura: new FormArray([]),
  });

  //Se utiliza para generar la factura como ingreso.
  formIngreso: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    fecha_creacion: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_gasto: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),
    tipo_transaccion: new UntypedFormControl(2),
    usuario_id: new UntypedFormControl(),
    factura_id: new UntypedFormControl()
  });


  
  get detallesFactura(): FormArray {
    return this.formFactura.get("detallesFactura") as FormArray;
  }

  searchText: String = "";

  totalesemitidas(){
    this._facturaService.getFacturaListAllByID(this._tokenService.getUser().id).subscribe({
      next:(data)=>{    
        this.total_facturas= data;
        this.total_facturasCompleto=data;
      },
      error:(error)=>{
        
      }
    })
  }

  totalesrecibidas(){
 
    this._facturaService.getFacturaListAllByIDCliente(this._tokenService.getUser().id).subscribe({
      next:(data)=>{    
        this.total_facturas= data;
        this.total_facturasCompleto=data;
      },
      error:(error)=>{
      }
     })
  }

  filtrar(mesagge: string) {
    if(this.total_facturasCompleto!= undefined){

    if (mesagge.length > 0) {
      this.facturas = this.total_facturasCompleto.filter(
        (item) =>
          item.numeroFactura.toLowerCase().indexOf(mesagge.toLowerCase()) >
          -1 ||
          this.getDateToFilter(item.fechaFactura).indexOf(mesagge) > -1 ||
          this.getDateToFilter(item.fechaVencimiento).indexOf(mesagge) > -1 ||
          (item.usuario.nombre.toLowerCase() + " "+ item.usuario.apellido1.toLowerCase() + " "+ 
          item.usuario.apellido2.toLowerCase()) .indexOf(mesagge.toLowerCase()) >
          -1 ||
          
          item.estado.toLowerCase().indexOf(mesagge.toLowerCase()) > -1 ||
          item.nombre.toLowerCase().indexOf(mesagge.toLowerCase()) > -1
      );
      this.total_facturas=this.facturas;
      this.pageSize=this.total_facturas.size
    } else {
     
      if(this.mostrarRecibidas){
        this.totalesrecibidas()
      }else{
      this.totalesemitidas();}
      if(this.total_facturas!=null){
      this.facturas= this.total_facturas.slice(0, 5)}}
      }
     
 if(mesagge != ""){
  this.mostrarPag=false
}else{
  this.mostrarPag=true
}
  }


  setMostrarFormularioChild() {
    this.setMostrarFormulario(false);
  }

  setEditarFormularioChild() {
    this.setEditarFormulario(false);
  }

  public getFacturaListByUsuario(request) {
    this._facturaService
      .getFacturaListByUsuario(this.usuario.id, request)
      .subscribe({
        next: (data) => {
          this.facturas = data["content"];
          this.facturasInicio = data["content"];

          this.totalElements = data["totalElements"];
        },
        error: (error) => {
          this.facturas = null;
          // console.log(error);
        },
      });
  }

  public getFacturaListByDocument(request) {
    this._facturaService
      .getFacturaListByDocumento(this.usuario.numero_documento, request)
      .subscribe({
        next: (data) => {
          this.facturas = data["content"];

          this.facturasInicio = data["content"];

          this.totalElements = data["totalElements"];
        },
        error: (error) => {
          this.facturas = null;
          // console.log(error);
        },
      });
  }

  public getFacturaFullListByDocument() {
    this._facturaService
      .getFullFacturasByDocumento(this.usuario.numero_documento)
      .subscribe({
        next: (data) => {
          let facturasRecibidas: any=[];
          facturasRecibidas = data;
          for(let factura of facturasRecibidas){
            this.total_facturado -= parseFloat(factura.cantidadTotal.toFixed(2));
            this.total_deuda += parseFloat(factura.cantidadTotal.toFixed(2));
          }

          this.total_facturado = Math.round((this.total_facturado + Number.EPSILON) * 100) / 100
          //console.log(this.total_facturado)
          this.total_pendiente = Math.round((this.total_pendiente + Number.EPSILON) * 100) / 100
          this.total_deuda = Math.round((this.total_deuda + Number.EPSILON) * 100) / 100;
        },
        error: (error) => {
          
          // console.log(error);
        },
      });
      
  }

  getDateToFilter(fechas) {
    var fechaString = new Date(fechas);
    let month = "" + (fechaString.getMonth() + 1);
    let day = "" + fechaString.getDate();
    const year = fechaString.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  orderById() {
    this.isSortedByNombre = "";
    this.isSortedByFecha = "";
    this.isSortedByFactura = "";
    this.isSortedByEstado = "";
    if (this.isSortedById != "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (parseInt(n1.idCliente) > parseInt(n2.idCliente)) {
          return -1;
        }

        if (parseInt(n1.idCliente) < parseInt(n2.idCliente)) {
          return 1;
        }

        return 0;
      });
      this.isSortedById = "chevron-button";
    } else if (this.isSortedById == "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (parseInt(n1.idCliente) > parseInt(n2.idCliente)) {
          return 1;
        }

        if (parseInt(n1.idCliente) < parseInt(n2.idCliente)) {
          return -1;
        }

        return 0;
      }); 
      this.isSortedById = "chevron-button-up";

    }

      this.facturas=[]
      this.facturas=this.total_facturas.slice(0, this.pageSize);
    
  }


  orderByFecha() {
    this.isSortedByNombre = "";
    this.isSortedById = "";
    this.isSortedByFactura = "";
    this.isSortedByEstado = "";
    if (this.isSortedByFecha != "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (parseInt(n1.fechaFactura) > parseInt(n2.fechaFactura)) {
          return -1;
        }

        if (parseInt(n1.fechaFactura) < parseInt(n2.fechaFactura)) {
          return 1;
        }

        return 0;
      });
      this.isSortedByFecha = "chevron-button";
    } else if (this.isSortedByFecha == "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (parseInt(n1.fechaFactura) > parseInt(n2.fechaFactura)) {
          return 1;
        }

        if (parseInt(n1.fechaFactura) < parseInt(n2.fechaFactura)) {
          return -1;
        }

        return 0;
      });
      this.isSortedByFecha = "chevron-button-up";
    }
    
    this.facturas=[]
    this.facturas=this.total_facturas.slice(0, this.pageSize);
  }
  orderByFactura() {
    this.isSortedByNombre = "";
    this.isSortedById = "";
    this.isSortedByFecha = "";
    this.isSortedByEstado = "";
//    console.log(this.total_facturas)

    if (this.isSortedByFactura != "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (n1.numeroFactura > n2.numeroFactura) {
          return -1;
        }

        if (n1.numeroFactura < n2.numeroFactura) {
          return 1;
        }

        return 0;
      });
      this.isSortedByFactura = "chevron-button";
    } else if (this.isSortedByFactura == "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (n1.numeroFactura > n2.numeroFactura) {
          return 1;
        }

        if (n1.numeroFactura < n2.numeroFactura) {
          return -1;
        }

        return 0;
      });
      this.isSortedByFactura = "chevron-button-up";
    }

      this.facturas=[]
      this.facturas=this.total_facturas.slice(0, this.pageSize);

  }
  orderByEstado() {
    this.isSortedByNombre = "";
    this.isSortedById = "";
    this.isSortedByFecha = "";
    this.isSortedByFactura = "";
    if (this.isSortedByEstado != "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (n1.estado > n2.estado) {
          return -1;
        }

        if (n1.estado < n2.estado) {
          return 1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button";
    } else if (this.isSortedByEstado == "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (n1.estado > n2.estado) {
          return 1;
        }

        if (n1.estado < n2.estado) {
          return -1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button-up";
    
    }
    this.facturas=[]
      this.facturas=this.total_facturas.slice(0, this.pageSize);
  }
  orderByNombre() {
    this.isSortedByEstado = "";
    this.isSortedById = "";
    this.isSortedByFecha = "";
    this.isSortedByFactura = "";
    if (this.isSortedByNombre != "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (n1.nombre > n2.nombre) {
          return -1;
        }

        if (n1.nombre < n2.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByNombre = "chevron-button";
    } else if (this.isSortedByNombre == "chevron-button") {
      this.total_facturas = this.total_facturas.sort((n1, n2) => {
        if (n1.nombre > n2.nombre) {
          return 1;
        }

        if (n1.nombre < n2.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByNombre = "chevron-button-up";
    }

      this.facturas=[]
      this.facturas=this.total_facturas.slice(0, this.pageSize);
    
  }

  downloadInvoice(factura) {
    let documento= factura.documento;
    this._facturaService
      .downloadJustificanteB64(documento.id_gestor_documental)
      .subscribe({
        next: (fichero) => {
          const linkSource = "data:application/pdf;base64," + fichero.Result;
          const downloadLink = document.createElement("a");
          const fileName = factura.numeroFactura;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();

          this._notificationService.success(
            "Hecho",
            "Pdf descargado con éxito",
            2000
          );
        },
        error: (error) => {
          //console.log(error);
        },
      });
  }

  openPDF(factura, fecha_factura, fecha_vencimiento) {
    this._facturaService.downloadJustificanteB64(factura.documento.id_gestor_documental)
    .subscribe({
      next: (documento) => {
        const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank")
      },
      error: (error) => {
        this._notificationService.error('Error', 'No se ha podido abrir el archivo', 2000);
      }
    })
      
  }

  deleteFactura(factura, e: any,dialog) {
    this.isDelete=true
    var ultimaFactura: any
    if(factura.rectificada!=null || !factura.rectificada){
      this._facturaService.getFacturaFullListByUsuario(this.usuario.id).subscribe({
        next : (data) => {
          let listaFacturas: any = []
          let detalles_id_gestor = [];
          listaFacturas=data;
          ultimaFactura = listaFacturas.at(listaFacturas.length - 1);
          console.log(ultimaFactura)
          console.log(factura)
          if(factura.id == ultimaFactura.id && factura.estado.nombre == "PENDIENTE"){
            this._ingresos_gastosService.getByFacturaId(factura.id).subscribe({next: (data) => {
              var ingreso = data;
              this._ingresos_gastosService
              .getByGastoId(ingreso.id)
              .subscribe({
                next: (data) => {
                 // console.log(data)
                  let detalles = data["documentos"];          

                    detalles.forEach((detalle) =>
                      detalles_id_gestor.push(detalle.id_gestor)
                    );
                    this._ingresos_gastosService
                      .DeleteFile(detalles_id_gestor)
                      .pipe(
                        finalize(() => {
                    
  
                          this._ingresos_gastosService
                            .deleteGastoIngreso(ingreso.id)
                            .subscribe({
                              next: (data) => { 
                                this._facturaService.deleteFactura(factura.id, false).subscribe({next: (data) => {
                                  },
                                error: (err) => {
                                  dialog.close();
                                  this._notificationService.success(
                                    "Hecho",
                                    "Borrado correctamente",
                                    2000
                                  );
                                  this.isDelete=false
                                  this._router.navigateByUrl("/client-hencok/facturacion").then(() => {
                                    this._router.navigate(
                                      ["client-hencok/facturacion/" + this.usuario.id],
                                      { skipLocationChange: true }
                                    );
                                  });
                                },
                                })                               
                              },
                              error: (err) => {
                                this.isDelete=false
                                dialog.close();
                                this._notificationService.error(
                                  "ERROR",
                                  "No se ha podido borrar  el ingreso/gasto",
                                  2000
                                );
                              },
                            });
                        })
                      )
                      .subscribe({ next: (data) => {}});
                  
                  
                },
                error: (err) => {},
              });
              
            }})

          }
          else{
            //Factura nueva para hacer rectificativa de la borrada
            this.createFactura(factura);
            dialog.close();
          }      
        }
      })
    }
    else if(factura.rectificada){
      //Factura nueva para hacer rectificativa de la borrada
      this.createFactura(factura);
      dialog.close();
    }
    else{
      dialog.close();
    }    
  }
  

  createFactura(factura) {
    this.uploadDocuments = true;
   // console.log(factura.tipoGasto.id);
    this.formFactura.controls["numeroFactura"].setValue(factura.numeroFactura);
    this.formFactura.controls["tipoGasto"].setValue(factura.tipoGasto.id);
    this.formFactura.controls["fecha_factura"].setValue(
      this.formatDate(new Date())
    );
    this.formFactura.controls["fecha_vencimiento"].setValue(
      this.formatDate(factura.fechaVencimiento)
    );
    this.formFactura.controls["importe_iva"].setValue(factura.importeIva * -1);
    this.formFactura.controls["subtotal"].setValue(factura.subtotal * -1);
    this.formFactura.controls["usuario"].setValue(factura.usuario);
    var detallesFacturaRem = [];

    var facturaFinal = new Factura(
      this.formatDate(this.formFactura.controls["fecha_factura"].value),
      this.formatDate(this.formFactura.controls["fecha_vencimiento"].value),
      (factura.importeIva * -1).toString(),
      (factura.subtotal * -1).toString(),
      (factura.cantidadTotal * -1).toString(),
      factura.numeroFactura,
      factura.idCliente,
      factura.email,
      factura.numeroDocumento,
      factura.nombre,
      factura.domicilio,
      factura.codigoPostal,
      factura.localidad,
      factura.tipoGasto, 
      undefined,
      true,
    );
    
    var iva = ((factura.importeIva / factura.subtotal) * 100).toFixed(0).toString();
    const detalle = {
      concepto : "Factura rectificativa de " + factura.numeroFactura,
      precio : (factura.subtotal * -1),
      descuento : 0,
      cantidadNeta : factura.cantidadTotal * -1,
      tipoIva: iva,
      cantidad:1,
    }
    detallesFacturaRem.push(detalle); 

   // console.log(detallesFacturaRem);

   // console.log(this.formFactura.value);

   this.formIngreso.controls["fecha_creacion"].setValue(
     this.formatDateIngreso(
       this.formFactura.controls["fecha_factura"].value
     )
   );
   this.formIngreso.controls["importe"].setValue(
     factura.cantidadTotal * -1
   );
  // console.log(factura.tipoGasto.id);
   this.formIngreso.controls["tipo_gasto"].setValue(
     factura.tipoGasto.id
   );

   this.formIngreso.controls["usuario_id"].setValue(
     this.usuario.id
   );

    let tipo_transaccion = {
      id:2,
      nombre:"INGRESO",
    }
   
    let ingreso = new GastoIngreso(
      this.usuario.id,
      "",
      tipo_transaccion,
    )

    this._facturaService
    .createWithIngreso(facturaFinal, detallesFacturaRem, ingreso,false, false)
    .subscribe({
      next: (data) => {
        this.submitted = true;

        var facturaRectificada = new Factura(
          this.formatDate(this.formFactura.controls["fecha_factura"].value),
          this.formatDate(this.formFactura.controls["fecha_vencimiento"].value),
          factura.importeIva,
          factura.subtotal,
          factura.cantidadTotal,
          factura.numeroFactura,
          factura.idCliente,
          factura.email,
          factura.numeroDocumento,
          factura.nombre,
          factura.domicilio,
          factura.codigoPostal,
          factura.localidad,
          factura.tipoGasto,
          factura.id,
          true
        );

       // console.log("Estoy " + facturaRectificada)
        this._facturaService.updateWithIngreso(facturaRectificada,factura.detalle,ingreso,false).subscribe({next: (data) =>{
          this._notificationService.success(
            "Hecho",
            "Factura rectificativa generada con exito",
            2000
          );
          this._router.navigateByUrl("/client-hencok/facturacion").then(() => {
            this._router.navigate(
              ["client-hencok/facturacion/" + this.usuario.id],
              { skipLocationChange: true }
            );
          });
        }});
       // console.log("save data", data);
      },
      error: (error) => {
        this.uploadDocuments = false;
       // console.log(error);
      },
    });
        
  }

  setMostrarFormulario(mode: Boolean) {
    this.mostrarFormulario = mode;
  }

  setEditarFormulario(mode: Boolean) {
    this.editarFormulario = mode;
  }

  setEditarFormularioSend(mode: Boolean, factura) {
    if(!factura.rectificada || factura.estado=='COMPLETADO'){
      this.facturaEnv = factura;
    if (this.mostrarRecibidas == false) {
      this.editarFormulario = mode;
    }
    }
    
  }

  setMostrarRecibidas(mode: Boolean) {
    this.mostrarRecibidas = mode;
    if (this.mostrarRecibidas) {
      this.totalesrecibidas()
      this.getFacturaListByDocument({ page: "0", size: "5" });
    } else {
      this.totalesemitidas()
      this.getFacturaListByUsuario({ page: "0", size: "5" });
    }
  }

  sumUp(){
    this._facturaService.getFacturaFullListByUsuario(this.usuario.id).subscribe({
      next : (data) => {
        let listaFacturas: any = []
        listaFacturas=data;
        for(let factura of listaFacturas){
          this.total_facturado += parseFloat(factura.cantidadTotal.toFixed(2));
          this.total_pendiente += parseFloat(factura.cantidadTotal.toFixed(2));
        }
        this.getFacturaFullListByDocument();
      }});
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  formatDateIngreso(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  getFirstLetter(nombre: string) {
    return nombre.charAt(0).toUpperCase();
  }

  nextPage(event: PageEvent) {
    const request = {};
    request["page"] = event.pageIndex.toString();
    this.pageIndex = event.pageIndex;
    request["size"] = event.pageSize.toString();
    this.pageSize = event.pageSize;
    this.getFacturaListByUsuario(request);
  }

  showModal(factura, dialog) {
    var ultimaFactura: any
    this._facturaService.getFacturaFullListByUsuario(this.usuario.id).subscribe({
      next: (data) => {
        let listaFacturas: any = []
        listaFacturas = data;
        ultimaFactura = listaFacturas.at(listaFacturas.length - 1);
       // console.log(ultimaFactura)
       // console.log(factura)
        if (factura.numeroFactura === ultimaFactura.numeroFactura && !factura.rectificada && factura.estado == "PENDIENTE") {
          dialog.showModal();
        }
        else if (!factura.rectificada) {
          dialog.showModal();
        } else {
          this._notificationService.warning(

            "Aviso!",

            "No es posible eliminar la factura",

            2000

          );
        }
      }
    })
  }

  showEditDeleteError(error){
    this._notificationService.info(
      "Aviso!",
      "No se puede " + error + " la factura, está completada",
      2000
    );
  }

  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        field_type == "STRING"
        value = this.formatDate(value);
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getPresupuestos();
  }

  getPresupuestos() {
    let rol = this._tokenService.getRol();

    if (rol == 1) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this._tokenService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this._facturaService
      .getFacturaFilter(this.request)
      .subscribe((data: any) => {
        this.facturas = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
      });
  }

  checkPresence(object, parameter){
    if(parameter in object){
      return true;
    }
    else{
      return false;
    }
  }

  sortArrayInitialize(){
    this.sortArray = {idCliente:'none', fechaFactura:'none', numeroFactura:'none', estado:'none', nombre:'none'};
  }

  changeSorts(key, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = key instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of key){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(key);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    this.getPresupuestos();
  }

}
