import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PresupuestoService } from 'src/app/_services/autoescuela/presupuesto.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Presupuesto } from 'src/app/model/autoescuela/presupuesto';
import { ClientePresupuesto } from 'src/app/model/autoescuela/clientePresupuesto';
import { VehiculoTransderencia } from 'src/app/model/autoescuela/vehiculo_transferencia';
import { EntidadTransferencia } from 'src/app/model/autoescuela/entidad_transferencia';
import { TramitesService } from 'src/app/_services/autoescuela/tramites.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-presupuesto',
  templateUrl: './presupuesto.component.html',
  styleUrls: ['./presupuesto.component.css']
})
export class PresupuestoComponent {

  protected REDSYS_URL = environment.REDSYS_URL;

  @Input() vehiculo: VehiculoTransderencia;
  @Input() itpInfo: any;
  @Output() newItemEvent = new EventEmitter<any>();
  @Input() comprador: EntidadTransferencia;
  @Input() vendedor: EntidadTransferencia;
  @Input() formVehiculo: any;
  mTransferencia: boolean = true;
  transferencia = true;
  userAdmin: Boolean = false;
  usuario: any;
  userLogged: any = -1;
  tramiteSeleccionado: string;
  subtotal = 0;
  iva = 0.0;
  irpf = 0.0;
  cantidad_total = 0;
  isMod = false;

  uploadPresupuesto = false;

  detallesPresupuestoSave = [];

  submitPresupuesto = false;

  isParticular = true;
  firstTime = true;
  itp = false;

  tipoVias: any = [];
  comunidades: any = [];
  provincias: any = [];
  municipios: any = [];

  idComunidad = 0;
  idProvincia = 0;
  municipio = "";
  idVia = 0;
  presupuestoInfoList: any = [];
  listaInfoConceptos: any = [];

  via: any;
  comunidad: any;
  provincia: any;
  CTransferencia: boolean = false;
  i = -1;

  tipoEntidad:any;

  listTipo = [ {
    id: "1",
    nombre: "Particular"
  },
  {
    id: "2",
    nombre: "Empresa"
  },]
  idEnviar: any;

  tramitesSinAsociar: any;
  valorSeleccionado: any = 0;
  listaTramites: any;
  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _presupuestoService: PresupuestoService,
    private _tokenStorageService: TokenStorageService,
    private _notificationService: NotificationService,
    private _router: Router,
    private datepipe: DatePipe,
    private tramiteService: TramitesService,
    private token?: TokenStorageService,
  ) { }

  formPresupuesto: FormGroup = new FormGroup({
    fecha_emision: new FormControl(new Date(), Validators.required),
    fecha_vencimiento: new FormControl("", Validators.required),
    codPresupuesto: new FormControl("", Validators.required),
    notas: new FormControl(""),
    vendedorComprador: new FormControl(""),
    detallesPresupuesto: new FormArray([
    ]),
  });

  formCliente: FormGroup = new FormGroup({
    tipoVia: new FormControl(undefined, Validators.required),
    nombreVia: new FormControl(undefined, Validators.required),
    numeroVia: new FormControl(undefined),
    bloque: new FormControl(undefined),
    escalera: new FormControl(undefined),
    planta: new FormControl(undefined),
    puerta: new FormControl(undefined),
    comunidadAutonoma: new FormControl(undefined, Validators.required),
    provincia: new FormControl(undefined, Validators.required),
    localidad: new FormControl(undefined, Validators.required),
    codigoPostal: new FormControl(undefined, [Validators.required, Validators.minLength(5)]),
    km: new FormControl(undefined),
    nombre: new FormControl("", Validators.required),
    apellido1: new FormControl("", Validators.required),
    apellido2: new FormControl(undefined),
    email: new FormControl("", [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required]),
    dni: new FormControl("", Validators.required),
    fechaNacimiendo: new FormControl(undefined, Validators.required),
    validezDni: new FormControl(undefined, Validators.required),
    telefono: new FormControl("", [Validators.required, Validators.minLength(5)]),
    tipoEntidad: new FormControl("", Validators.required),
    nombreEmpresa: new FormControl(undefined),
    cif: new FormControl(undefined),

  });

  ngOnInit(): void {
    this.listaInfoConceptos = [];
    this.inciarTramites()
    this.userLogged = this._tokenStorageService.getUser();
    //console.log(this.userLogged.rol);
    if (this._tokenStorageService.getRol() == 3 || this._tokenStorageService.getRol() == 99) {
      this.userAdmin = true;
    }
    if (this.token.getRol() == 5 || this.token.getRol() == 6) {
      this.isMod = true;
    }

    this.formPresupuesto.controls["fecha_emision"].setValue(
      this.formatDate(new Date())
    );

    if (this.itpInfo != undefined) {
    }
    else {
      this.addPresupuesto();
      this.getVias();
      this.getComunidades();
      this.getPreciosTramites();
    }

    this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
      next: (data) => {
        this.formPresupuesto.controls["codPresupuesto"].setValue(
          data["codigo"]
        );
      },
      error: (error) => {
      },
    }
    )

    this.formCliente.controls["tipoEntidad"].setValue(1)


  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.firstTime == false) {
      this.userLogged = this._tokenStorageService.getUser();
      //console.log(this.userLogged.rol);
      if (this.userLogged.rol.indexOf(2) > -1) {
        this.userAdmin = true;
      }

      if (this.vehiculo != undefined) {
        this.transferencia = false;


      }
      this.formPresupuesto.controls["fecha_emision"].setValue(
        this.formatDate(new Date())
      );

      if (this.itpInfo != undefined) {
        this.itp = true;

        this.detallesPresupuesto.clear();
        var detalles = new FormGroup({
          concepto: new FormControl(this.itpInfo["concepto"], Validators.required),
          baseImponible: new FormControl(String(this.itpInfo["baseImponible"]), Validators.required),
          tipoIva: new FormControl(""),
          tipoIrpf: new FormControl(""),
          total: new FormControl(Number(this.itpInfo["baseImponible"]).toFixed(2), Validators.required),
        });
        this.listaInfoConceptos.push({conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined, iva: 0, base: 0, total: 0})


        this.detallesPresupuesto.push(detalles);

        this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);

      }

      this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
        next: (data) => {
          this.formPresupuesto.controls["codPresupuesto"].setValue(
            data["codigo"]
          );
        },
        error: (error) => {
        },
      }
      )
      /*       this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value); */
    }
    else {
      this.firstTime = false
    }


  }

  getVias() {
    return this._serviceGeneral.getTipoVias().subscribe((data) => {
      this.tipoVias = data['TipoVia'];
    });
  }

  get detallesPresupuesto(): FormArray {
    return this.formPresupuesto.get("detallesPresupuesto") as FormArray;
  }

  getComunidades() {
    return this._serviceGeneral.getComunidades().subscribe((data) => {
      this.comunidades = data['ComunidadAutonoma'];
    });
  }

  getProvincias() {
    return this._serviceGeneral.getProvincias().subscribe((data) => {
      this.provincias = data['Provincia'];
    });
  }

  getProvinciasByID() {
    if (this.idComunidad != 0) {
      this._serviceGeneral.getProvincias().subscribe(
        (data) => {
          data['Provincia'].forEach((provincia) => {
            if (provincia.ccaa["id"] == this.idComunidad) {
              this.provincias.push(provincia);
              return this.provincias;
            }
          });
        });
    }

  }

  getMunicipios() {
    return this._serviceGeneral.getMunicipios().subscribe((data) => {
      this.municipios = data['Municipio'];
    });
  }

  getMunicipiosByID() {
    if (this.idProvincia != 0) {
      this._serviceGeneral.getMunicipios().subscribe((data) => {
        data['Municipio'].forEach((municipio) => {
          if (municipio.municipioPK["provincia"] == this.idProvincia) {
            this.municipios.push(municipio);
            return this.municipios
          }
        });
      });
    }
  }

  onChangeComunidad(event: Event) {
    this.provincias = [];
    this.idComunidad = Number((event.target as HTMLInputElement).value);
    this.comunidad = this.comunidades[this.idComunidad - 1];
    this.getProvinciasByID();
  }

  onChangeProvincia(event: Event) {
    this.municipios = [];
    this.provincia = this.provincias[((event.target as HTMLInputElement).value)];
    this.idProvincia = this.provincia.id;
    this.getPreciosTramites();
    this.getMunicipiosByID();
  }

  onChangeMunicipio(event: Event) {
    this.municipio = String((event.target as HTMLInputElement).value);
  }

  onChangeTipoVia(event: Event) {
    this.via = this.tipoVias[Number((event.target as HTMLInputElement).value)];
    this.idVia = this.via.id;

  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];
        //console.log(this.usuario);
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }


  addPresupuesto() {
    this.detallesPresupuesto.push(new FormGroup({
      concepto: new FormControl("", Validators.required),
      baseImponible: new FormControl("", Validators.required),
      tipoIva: new FormControl(""),
      tipoIrpf: new FormControl(""),
      total: new FormControl("", Validators.required),
    }));
    this.i++
    this.listaInfoConceptos.push({conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined, iva: 0, base: 0, total: 0})
  }

  onSubmitPresupuesto() {
    var comunidadAux = this.formCliente.controls['comunidadAutonoma'].value
    var provinciaAux = this.formCliente.controls['provincia'].value
    var localidadAux = this.formCliente.controls['localidad'].value
    var tipoViaAux = this.formCliente.controls['tipoVia'].value
    let detallesPresupuestoSave = this.changeDetails(this.formPresupuesto.controls["detallesPresupuesto"]);
    console.log(this.formCliente);
    if (!this.transferencia) {
      if (this.formPresupuesto.invalid) {
        this.uploadPresupuesto = false;
        return this._notificationService.warning("Información",
          "Rellene todos los campos correctamente",
          2000)
      } 
      if (this.formCliente.invalid) {
        this.uploadPresupuesto = false;
        return this._notificationService.warning("Información",
          "Rellene todos los campos correctamente",
          2000)
      }
      else {
        this.tipoEntidad = this.formCliente.controls['tipoEntidad'].value
        this.formCliente.removeControl('tipoEntidad');
        this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "dd-MM-yyyy"));
        this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "dd-MM-yyyy"));
        //this.formPresupuesto.addControl('vendedorComprador', new FormGroup("");
        var presupuesto = new Presupuesto(
          new Date(this.formPresupuesto.controls["fecha_emision"].value),
          this.formPresupuesto.controls["codPresupuesto"].value,
          new Date(new Date().setDate(new Date().getDate() + 30)),
          this.formPresupuesto.controls["notas"].value,
          this.subtotal
        )
        if (this.formPresupuesto.controls['vendedorComprador'].value == 1) {
          
          this.formCliente.controls['nombre'].setValue(this.comprador.nombre);
          this.formCliente.controls['apellido1'].setValue(this.comprador.apellido1);
          this.formCliente.controls['apellido2'].setValue(this.comprador.apellido2);
          this.formCliente.controls['email'].setValue(this.comprador.email);
          this.formCliente.controls['dni'].setValue(this.comprador.dni);
          this.formCliente.controls['fechaNacimiendo'].setValue(this.comprador.fechaNacimiendo);
          this.formCliente.controls['validezDni'].setValue(this.comprador.validezDni);
          this.formCliente.controls['telefono'].setValue(this.comprador.telefono);
          this.formCliente.controls['tipoVia'].setValue(this.comprador.tipoVia);
          this.formCliente.controls['nombreVia'].setValue(this.comprador.nombreVia);
          this.formCliente.controls['numeroVia'].setValue(this.comprador.numeroVia);
          this.formCliente.controls['bloque'].setValue(this.comprador.bloque);
          this.formCliente.controls['escalera'].setValue(this.comprador.escalera);
          this.formCliente.controls['planta'].setValue(this.comprador.planta);
          this.formCliente.controls['puerta'].setValue(this.comprador.puerta);
          this.formCliente.controls['km'].setValue(this.comprador.km);
          this.formCliente.controls['comunidadAutonoma'].setValue(this.comprador.comunidadAutonoma);
          this.formCliente.controls['provincia'].setValue(this.comprador.provincia);
          this.formCliente.controls['localidad'].setValue(this.comprador.localidad);
          this.formCliente.controls['codigoPostal'].setValue(this.comprador.codigoPostal);
          this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
            next: (data) => {
              this.formPresupuesto.controls["codPresupuesto"].setValue(
                data["codigo"]
              );
            },
            error: (error) => {
            },
          }
          )
          this._presupuestoService.create(presupuesto, detallesPresupuestoSave.value, new ClientePresupuesto(undefined, this.formCliente.value)).subscribe({
            next: (data) => {
              this.uploadPresupuesto = false;
              this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
                next: (data) => {
                  this.formPresupuesto.controls["codPresupuesto"].setValue(
                    data["codigo"]
                  );
                },
                error: (error) => {
                },
              }
              )

              if (!this.transferencia) {
                this._presupuestoService.updateEstado("ACEPTADO", data["id"]).subscribe({
                  next: (data => {

                  })
                })
                this.newItemEvent.emit(data);
              }
            },
            error: (error) => {
              console.log(error)
              this.uploadPresupuesto = false;
              this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
              this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
              if (error["error"] == "Not valid DNI") {
                return this._notificationService.warning("Atención",
                  "El DNI no es valido",
                  5000)
              }
              return this._notificationService.error("Error",
                "El presupuesto no se ha podido gurdar, intente más tarde",
                5000)
            }
          })
          this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
          this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
        } else {

          this.formCliente.controls['nombre'].setValue(this.vendedor.nombre);
          this.formCliente.controls['apellido1'].setValue(this.vendedor.apellido1);
          this.formCliente.controls['apellido2'].setValue(this.vendedor.apellido2);
          this.formCliente.controls['email'].setValue(this.vendedor.email);
          this.formCliente.controls['dni'].setValue(this.vendedor.dni);
          this.formCliente.controls['fechaNacimiendo'].setValue(this.vendedor.fechaNacimiendo);
          this.formCliente.controls['validezDni'].setValue(this.vendedor.validezDni);
          this.formCliente.controls['telefono'].setValue(this.vendedor.telefono);
          this.formCliente.controls['tipoVia'].setValue(this.vendedor.tipoVia);
          this.formCliente.controls['nombreVia'].setValue(this.vendedor.nombreVia);
          this.formCliente.controls['numeroVia'].setValue(this.vendedor.numeroVia);
          this.formCliente.controls['bloque'].setValue(this.vendedor.bloque);
          this.formCliente.controls['escalera'].setValue(this.vendedor.escalera);
          this.formCliente.controls['planta'].setValue(this.vendedor.planta);
          this.formCliente.controls['puerta'].setValue(this.vendedor.puerta);
          this.formCliente.controls['km'].setValue(this.vendedor.km);
          this.formCliente.controls['comunidadAutonoma'].setValue(this.vendedor.comunidadAutonoma);
          this.formCliente.controls['provincia'].setValue(this.vendedor.provincia);
          this.formCliente.controls['localidad'].setValue(this.vendedor.localidad);
          this.formCliente.controls['codigoPostal'].setValue(this.vendedor.codigoPostal);

          this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
            next: (data) => {
              this.formPresupuesto.controls["codPresupuesto"].setValue(
                data["codigo"]
              );
            },
            error: (error) => {
            },
          }
          )

          this._presupuestoService.create(presupuesto, detallesPresupuestoSave.value, new ClientePresupuesto(undefined, this.formCliente.value)).subscribe({
            next: (data) => {
              this.uploadPresupuesto = false;
              this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
                next: (data) => {
                  this.formPresupuesto.controls["codPresupuesto"].setValue(
                    data["codigo"]
                  );
                },
                error: (error) => {
                },
              }
              )
              if (!this.transferencia) {
                console.log(data, "\n", data["id"])
                this._presupuestoService.updateEstado("ACEPTADO", data["id"]).subscribe({
                  next: (data => {
                  })
                })

                this.newItemEvent.emit(data);

              }
            },
            error: (error) => {
              console.log(error)
              this.uploadPresupuesto = false;
              this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
              this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
              if (error["error"] == "Not valid DNI") {
                return this._notificationService.warning("Atención",
                  "El DNI no es valido",
                  5000)
              }
              return this._notificationService.error("Error",
                "El presupuesto no se ha podido gurdar, intente más tarde",
                5000)
            }
          })
        }
        this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
        this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
        return null;
      }
    }


    this.uploadPresupuesto = true;
    this.submitPresupuesto = true;
    var test_nif = new RegExp("([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])");
    if (this.formPresupuesto.invalid) {
      console.log(this.formCliente,"\n", this.isParticular);
      this.uploadPresupuesto = false;
      return this._notificationService.warning("Información",
        "Rellene todos los campos correctamente",
        2000)
    }
    else if (this.formCliente.invalid) {
      this.uploadPresupuesto = false;
      return this._notificationService.warning("Información",
        "Rellene todos los campos correctamente",
        2000)
    }
    else if (this.formPresupuesto.controls["fecha_emision"].value > this.formPresupuesto.controls["fecha_vencimiento"].value) {
      this.uploadPresupuesto = false;
      return this._notificationService.warning("Información",
        "La fecha de vencimiento no puede ser anterior a la fecha de emisión",
        2000)
    }
    else {
      this.tipoEntidad = this.formCliente.controls['tipoEntidad'].value
      this.formCliente.removeControl('tipoEntidad');

      if(this.isParticular){
        this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "dd-MM-yyyy"));
        this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "dd-MM-yyyy"));
      }

      /* var cliente = this.formPresupuesto */

      let viaValue = this.formCliente.controls["tipoVia"].value;
      let provinciaValue = this.formCliente.controls["provincia"].value;

      this.formCliente.controls["tipoVia"].setValue(this.via);
      this.formCliente.controls["comunidadAutonoma"].setValue(this.comunidad);
      this.formCliente.controls["provincia"].setValue(this.provincia);

      var presupuesto = new Presupuesto(
        new Date(this.formPresupuesto.controls["fecha_emision"].value),
        this.formPresupuesto.controls["codPresupuesto"].value,
        new Date(this.formPresupuesto.controls["fecha_vencimiento"].value),
        this.formPresupuesto.controls["notas"].value,
        this.subtotal
      )

      this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
        next: (data) => {
          this.formPresupuesto.controls["codPresupuesto"].setValue(
            data["codigo"]
          );
        },
        error: (error) => {
        },
      }
      )

      this._presupuestoService.create(presupuesto, detallesPresupuestoSave.value, new ClientePresupuesto(undefined, this.formCliente.value)).subscribe({
        next: (data) => {
          this.uploadPresupuesto = false;
          this.idEnviar = data["id"]
          this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
            next: (data) => {
              this.formPresupuesto.controls["codPresupuesto"].setValue(
                data["codigo"]
              );
              console.log(data)
            },
            error: (error) => {
            },
          }
          )
          this.formCliente.controls["tipoVia"].setValue(viaValue);
          this.formCliente.controls["comunidadAutonoma"].setValue(this.idComunidad);
          this.formCliente.controls["provincia"].setValue(provinciaValue);
          if(this.isParticular){
            this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "dd-MM-yyyy"));
            this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "dd-MM-yyyy"));
          }

          if (!this.transferencia) {
            this._presupuestoService.updateEstado("ACEPTADO", data["id"]).subscribe({
              next: (data => {

              })
            })
            this.newItemEvent.emit(data);
          }
          this.formCliente.controls['comunidadAutonoma'].setValue(comunidadAux)
          this.formCliente.controls['provincia'].setValue(provinciaAux)
          this.formCliente.controls['localidad'].setValue(localidadAux)
          this.formCliente.controls['tipoVia'].setValue(tipoViaAux)
          if(this.isParticular){
            this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "yyyy-MM-dd"));
            this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "yyyy-MM-dd"));
          }
          this.CTransferencia = true;

          this.getTramitesSinAsociar(this.token.getId())
          this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
          this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
          return this._notificationService.success("Exito",
            "El presupuesto se ha guardado correctamente",
            5000)
        },
        error: (error) => {
          console.log(error)
          this.uploadPresupuesto = false;
          this.formCliente.controls['comunidadAutonoma'].setValue(comunidadAux)
          this.formCliente.controls['provincia'].setValue(provinciaAux)
          this.formCliente.controls['localidad'].setValue(localidadAux)
          this.formCliente.controls['tipoVia'].setValue(tipoViaAux)
          if(this.isParticular){
            this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "yyyy-MM-dd"));
            this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "yyyy-MM-dd"));
          }
          this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
          this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
          if (error["error"] == "Not valid DNI") {
            return this._notificationService.warning("Atención",
              "El DNI no es valido",
              5000)
          }
          return this._notificationService.error("Error",
            "El presupuesto no se ha podido gurdar, intente más tarde",
            5000)
        }
      })
    }
  }

  deleteDetalle() {
    if(this.listaInfoConceptos[this.listaInfoConceptos.length - 1].conceptoSelected == true){
      this.deleteConceptos(this.listaInfoConceptos[this.listaInfoConceptos.length - 1].parentConceptoIndex)
      if(this.detallesPresupuesto.length>1){
        this.detallesPresupuesto.removeAt(this.detallesPresupuesto.length - 1);
        this.listaInfoConceptos.pop();
        this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
      }
      else{
        let detallePresupuesto = this.detallesPresupuesto.controls[0] as FormGroup;
        this.listaInfoConceptos[0] = {conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined};
        detallePresupuesto.controls['concepto'].setValue("");
        detallePresupuesto.controls['baseImponible'].setValue("");
        detallePresupuesto.controls['tipoIva'].setValue("");
        detallePresupuesto.controls['tipoIrpf'].setValue("");
        detallePresupuesto.controls['total'].setValue("");
        this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
      }
    }
    else{
      this.detallesPresupuesto.removeAt(this.detallesPresupuesto.length - 1);
      this.listaInfoConceptos.pop();
      this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
    }
    
  }

  calcularCantidadNeta(index: number) {
    var rowPresupuesto = this.formPresupuesto.controls["detallesPresupuesto"].value[index];
    var ivaTotal = 0;
    var irpfTotal = 0;


    if (rowPresupuesto.baseImponible != "") {
      rowPresupuesto.total = rowPresupuesto.baseImponible;

      if (rowPresupuesto.tipoIva != "") {
        ivaTotal = (rowPresupuesto.baseImponible * rowPresupuesto.tipoIva / 100);
      }

      if (rowPresupuesto.tipoIrpf != "") {
        irpfTotal = (rowPresupuesto.baseImponible * rowPresupuesto.tipoIrpf / 100);
      }

      this.formPresupuesto.controls["detallesPresupuesto"].value[index].total = (parseFloat(rowPresupuesto.baseImponible) + ivaTotal + irpfTotal).toFixed(2);

      this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
    } else {
      this.formPresupuesto.controls["detallesPresupuesto"].value[index].total = 0;
    }
  }

  calcularTotales(presupuestos:any []) {
    this.subtotal = 0;
    this.iva = 0.0;
    this.irpf = 0.0;
    this.cantidad_total = 0;
    let presupuesto: any;
    for (let i = 0; i < presupuestos.length; i++) {
      presupuesto = presupuestos[i];
      if(!(this.listaInfoConceptos[i].conceptoSelected === true && this.listaInfoConceptos[i].mainConcepto === false)){
        if (presupuesto.baseImponible == "") {
          this.subtotal += 0.0;
        } else {
          this.subtotal += Number.parseFloat(presupuesto.baseImponible);
        }
  
        if (presupuesto.tipoIva == "") {
          this.iva += 0.0;
        } else {
          if(this.listaInfoConceptos[i].iva > 0){
            this.iva += presupuesto.total - presupuesto.baseImponible
          }
          else{
            this.iva += presupuesto.baseImponible * (presupuesto.tipoIva / 100);
          }
        }
  
        if (presupuesto.tipoIrpf == "") {
          this.irpf += 0.0;
        } else {
          this.irpf += presupuesto.baseImponible * (presupuesto.tipoIrpf / 100);
        }
      }
      
    }
    this.subtotal = Number.parseFloat(this.subtotal.toFixed(2));
    this.iva = Number.parseFloat(this.iva.toFixed(2));
    this.irpf = Number.parseFloat(this.irpf.toFixed(2))
    this.cantidad_total = parseFloat((this.subtotal + this.iva + this.irpf).toFixed(2));
  }

  generatePreview() {
    if (this.formPresupuesto.invalid) {
      return this._notificationService.warning("Información",
        "Rellene todos los campos correctamente",
        2000)
    }
    else if (this.formPresupuesto.controls["fecha_emision"].value > this.formPresupuesto.controls["fecha_vencimiento"].value) {
      return this._notificationService.warning("Información",
        "La fecha de vencimiento no puede ser anterior a la fecha de emisión",
        2000)
    }
    else if (this.formCliente.invalid) {
      this.uploadPresupuesto = false;
      console.log(this.formCliente);
      return this._notificationService.warning("Información",
        "Rellene todos los campos correctamente",
        2000)
        
    }
    else {
      var comunidadAux = this.formCliente.controls['comunidadAutonoma'].value
      var provinciaAux = this.formCliente.controls['provincia'].value
      var localidadAux = this.formCliente.controls['localidad'].value
      var tipoViaAux = this.formCliente.controls['tipoVia'].value
      this.tipoEntidad = this.formCliente.controls['tipoEntidad'].value
      this.formCliente.removeControl('tipoEntidad');
      if(this.isParticular){
        this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "dd-MM-yyyy"));
        this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "dd-MM-yyyy"));
      }
      this.formCliente.controls["tipoVia"].setValue(this.via);
      this.formCliente.controls["comunidadAutonoma"].setValue(this.comunidad);
      this.formCliente.controls["provincia"].setValue(this.provincia);

      var presupuesto = new Presupuesto(
        new Date(this.formPresupuesto.controls["fecha_emision"].value),
        this.formPresupuesto.controls["codPresupuesto"].value,
        new Date(this.formPresupuesto.controls["fecha_vencimiento"].value),
        this.formPresupuesto.controls["notas"].value,
        this.subtotal
      )

      let detallesPresupuestoSave = this.changeDetails(this.detallesPresupuesto);

      this._presupuestoService.getDocPreview(presupuesto, detallesPresupuestoSave.value, this.formCliente.value).subscribe({
        next: (data) => {
          this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
          this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
          this.formCliente.controls['comunidadAutonoma'].setValue(comunidadAux)
          this.formCliente.controls['provincia'].setValue(provinciaAux)
          this.formCliente.controls['localidad'].setValue(localidadAux)
          this.formCliente.controls['tipoVia'].setValue(tipoViaAux)
          if(this.isParticular){
            this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "yyyy-MM-dd"));
            this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "yyyy-MM-dd"));
          }
          const byteArray = new Uint8Array(atob(data["docBytes"]).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        }
      })
    }
  }

  addNewItem() {
    console.log("emitiendo false");
    this.newItemEvent.emit(false);
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  getYear(date) {
    const d = new Date(date);
    const year = d.getFullYear();

    return year;
  }


  sacaridlocalidad(nombre: String): String {
    var id: String = "";

    this.municipios.forEach(element => {

      if (element.nombre == nombre) {
        id = element.municipioPK.municipio_id;
        return null;
      }
    });
    return id;
  }


  setMostrarFormularioChild() {

    this.mTransferencia = !this.mTransferencia
  }

  mostrarTransferencia(mostrar: boolean) {
    this.completarFormulario();

    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/nueva-transferencia', this.formCliente.value]);
    }
    else {
      this._router.navigate(['/client-autoescuelas-hencok/nueva-transferencia', this.formCliente.value]);
    }
    this.mTransferencia = mostrar

  }


  mostrarInformeDgt() {
    this.completarFormulario()

    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-informe-dgt', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-informe-dgt', this.formCliente.value]);
    }

  }

  mostrarDuplicadoPermiso() {
    this.completarFormulario()

    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-duplicado-permiso-conducir', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-duplicado-permiso-conducir', this.formCliente.value]);
    }

  }

  mostrarMatriculacion() {
    this.completarFormulario()

    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-matriculacion', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-matriculacion', this.formCliente.value]);
    }

  }


  mostrarDistintivo() {
    this.completarFormulario()
    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-distintivo-ambiental', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-distintivo-ambiental', this.formCliente.value]);
    }

  }


  mostrarDuplicado() {
    this.completarFormulario()
    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-duplicado-ficha-tecnica', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-duplicado-ficha-tecnica', this.formCliente.value]);
    }

  }

  mostrarCanje() {
    this.completarFormulario()
    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-canje-permiso', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-canje-permiso', this.formCliente.value]);
    }
  }



  mostrarInternacional() {
    this.completarFormulario()
    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitud-permiso-internacional', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitud-permiso-internacional', this.formCliente.value]);
    }
  }
  completarFormulario() {
    this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "dd-MM-yyyy"));
    this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "dd-MM-yyyy"));

    this.formCliente.addControl('tipoVia2', new FormControl);
    this.formCliente.controls["tipoVia2"].setValue(this.via.id);
    this.formCliente.addControl('comunidadAutonoma2', new FormControl);
    this.formCliente.controls["comunidadAutonoma2"].setValue(this.comunidad.id);
    this.formCliente.addControl('provincia2', new FormControl);

    this.formCliente.controls["provincia2"].setValue(this.provincia.id);
    this.formCliente.addControl('municipio2', new FormControl);

    this.formCliente.controls["municipio2"].setValue(this.municipio);
    this.formCliente.addControl("AsociarTramite", new FormControl);
    this.formCliente.controls["AsociarTramite"].setValue(this.idEnviar);

  }

  vincularTramiteAPresupuesto() {
    var ruta = ""
    this._presupuestoService.asociarTramiteAPresupuesto
      (this.tramitesSinAsociar[this.valorSeleccionado].id, this.idEnviar,
        this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id).subscribe(
          (data) => {
            this._notificationService.success("Exito",
              "El presupuesto se vinculado correctamente",
              5000)
            if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "2") {
              ruta = "transferencia";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "3") {
              ruta = "solicitar-matriculacion";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "4") {
              ruta = "solicitar-duplicado-ficha-tecnica";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "5") {
              ruta = "distintivo-ambiental";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "6") {
              ruta = "solicitar-duplicado-permiso-conducir";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "7") {
              ruta = "canje-permiso";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "8") {
              ruta = "permiso-internacional";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "9") {
              ruta = "informe-dgt";
            }


            if (this.token.getRol() == 99) {
              this._router.navigate(['/admin-hencok/' + ruta + '/' + this.tramitesSinAsociar[this.valorSeleccionado].id]);
            } else if (this.token.getRol() == 3) {
              this._router.navigate(['/admin-autoescuelas-hencok/' + ruta + '/' + this.tramitesSinAsociar[this.valorSeleccionado].id]);
            } else {
              this._router.navigate(['/client-autoescuelas-hencok/' + ruta + '/' + this.tramitesSinAsociar[this.valorSeleccionado].id]);
            }

          })


  }




  inciarTramites() {
    this.tramiteService.getTramites().subscribe({
      next: (data) => {
        this.listaTramites = data
        console.log(this.listaTramites = data)

      },
      error: (error) => {
      },
    }
    )
  }
  irATramiteVinculado() {
    var ruta = ""
    console.log(this.idEnviar)

    if (this.idEnviar == "2") {
      ruta = "transferencia";
    }
    else if (this.idEnviar == "3") {
      ruta = "solicitar-matriculacion";
    }
    else if (this.idEnviar == "4") {
      ruta = "duplicado-ficha-tecnica";
    }
    else if (this.idEnviar == "5") {
      ruta = "distintivo-ambiental";
    }
    else if (this.idEnviar == "5") {
      ruta = "solicitar-duplicado-permiso-conducir";
    }
    else if (this.idEnviar == "7") {
      ruta = "canje-permiso";
    }
    else if (this.idEnviar == "8") {
      ruta = "permiso-internacional";
    }
    if (this.idEnviar == "9") {
      ruta = "informe-dgt";
    }

    if (this.token.getRol() == 99) {
      this._router.navigate(['/admin-hencok/' + ruta + '/' + this.idEnviar]);
    } else if (this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/' + ruta + '/' + this.idEnviar]);

    } else {
      this._router.navigate(['/client-autoescuelas-hencok/' + ruta + '/' + this.idEnviar]);

    }
  }


  mostrarTramiteAutocompletado() {
    console.log(this.tramiteSeleccionado)
    switch (this.tramiteSeleccionado) {

      case ("1"):
        // this.mostrarConsulta();
        break;
      case ("2"):
        console.log("si")
        this.mostrarTransferencia(false);
        break;

      case ("3"):
        this.mostrarMatriculacion();
        break;

      case ("4"):
        this.mostrarDuplicado();
        break;
      case ("5"):
        this.mostrarDistintivo();
        break;
      case ("6"):
        this.mostrarDuplicadoPermiso();
        break;
      case ("7"):
        this.mostrarCanje();
        break;
      case ("8"):
        this.mostrarInternacional();
        break;
      case ("9"):
        this.mostrarInformeDgt();
        break;
    }
  }


  onChangeTramiteAAsociar(event: Event) {
    this.tramiteSeleccionado = (event.target as HTMLSelectElement).value
    console.log(this.tramiteSeleccionado)
  }

  onChangeTramite(event: Event) {
    this.valorSeleccionado = (event.target as HTMLSelectElement).value

  }

  getTramitesSinAsociar(id) {
    this._presupuestoService.getTramitesSinAsociar(id).subscribe(
      (data) => {
        this.tramitesSinAsociar = data;
        console.log(this.tramitesSinAsociar)
      },
      (error) => {
        console.log(error)
      }
    )
  }
  params
  firma
  presupuestoRecibidoId
  receivedHtml

  goPago() {
    console.log(window.location.href);

    this._presupuestoService.tryPayment(this.idEnviar, window.location.href).subscribe({
      next: (data) => {
        this.params = data.params
        this.firma = data.firma

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = this.REDSYS_URL;

        // Agregar el HTML como un campo oculto en el formulario
        const htmlInput = document.createElement('input');
        htmlInput.type = 'hidden';
        htmlInput.name = 'Ds_SignatureVersion';
        htmlInput.value = "HMAC_SHA256_V1";
        form.appendChild(htmlInput);

        const htmlInput1 = document.createElement('input');
        htmlInput1.type = 'hidden';
        htmlInput1.name = 'Ds_MerchantParameters';
        htmlInput1.value = this.params;
        form.appendChild(htmlInput1);

        const htmlInput2 = document.createElement('input');
        htmlInput2.type = 'hidden';
        htmlInput2.name = 'Ds_Signature';
        htmlInput2.value = this.firma;
        form.appendChild(htmlInput2);


        document.body.appendChild(form);
        let info = form.submit();
        console.log(info)
      }
    })
  };

  getPreciosTramites(){
  this.tramiteService.getPreciosTramiteList(this.provincia).subscribe((data) => {
    console.log(data)
      this.presupuestoInfoList = data;
    })
  }

  setConceptos(index, event){
    let presupuestoInfoIndex : any = (event.target as HTMLInputElement).value;
    let presupuestoInfo:any = this.presupuestoInfoList[presupuestoInfoIndex];
    let detallePresupuesto = this.detallesPresupuesto.controls[index] as FormGroup;
    console.log(this.listaInfoConceptos[index].mainConcepto === true)
    if(this.listaInfoConceptos[index].mainConcepto === true){
      this.deleteConceptos(index);
    }
    if(presupuestoInfo){
      let importe = 0;
      let total = 0;
      let iva = 0;
      if(presupuestoInfo.tasaDgt){
        importe += presupuestoInfo.tasaDgt;
        total += presupuestoInfo.tasaDgt;
      }
      if(presupuestoInfo.tasaGestores1){
        importe += presupuestoInfo.tasaGestores1;
        total += presupuestoInfo.tasaGestores1;
      }
      if(presupuestoInfo.tasaGestores2){
        importe += presupuestoInfo.tasaGestores2;
        total += presupuestoInfo.tasaGestores2;
      }
      console.log((Math.round((total) * 100) / 100).toFixed(2))
      console.log(parseFloat((Math.round((total) * 100) / 100).toFixed(2)))
      importe = parseFloat((Math.round((importe) * 100) / 100).toFixed(2))
      total = Number((Math.round((total) * 100) / 100).toFixed(2))
      this.listaInfoConceptos[index] = {conceptoSelected: true, mainConcepto: true, parentConceptoIndex: undefined, iva: iva, base: importe, total: total};
      this.formPresupuesto.controls["detallesPresupuesto"].value[index].concepto = presupuestoInfo.tipoTramite.nombre

      /* if(presupuestoInfo.tasaDgt){
        detallePresupuesto.controls['concepto'].setValue(presupuestoInfo.tipoTramite.nombre);
        detallePresupuesto.controls['baseImponible'].setValue(String(presupuestoInfo.tasaDgt));
        detallePresupuesto.controls['tipoIva'].setValue("");
        detallePresupuesto.controls['tipoIrpf'].setValue("");
        detallePresupuesto.controls['total'].setValue(String(presupuestoInfo.tasaDgt));
        if(presupuestoInfo.honorariosGestoria){
          this.nuevoConcepto("Honorarios gestoría", presupuestoInfo.honorariosGestoria, presupuestoInfo.tipoIva, 0, (presupuestoInfo.honorariosGestoria + (presupuestoInfo.honorariosGestoria * presupuestoInfo.tipoIva / 100)).toFixed(2), {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index, iva: presupuestoInfo.tipoIva});
        }
      }
      else if(presupuestoInfo.honorariosGestoria){

        detallePresupuesto.controls['concepto'].setValue(presupuestoInfo.tipoTramite.nombre);
        detallePresupuesto.controls['baseImponible'].setValue(String(presupuestoInfo.honorariosGestoria));
        detallePresupuesto.controls['tipoIva'].setValue(String(presupuestoInfo.tipoIva));
        detallePresupuesto.controls['tipoIrpf'].setValue("");
        detallePresupuesto.controls['total'].setValue((presupuestoInfo.honorariosGestoria + (presupuestoInfo.honorariosGestoria * presupuestoInfo.tipoIva / 100)).toFixed(2));
        this.listaInfoConceptos[index].iva = presupuestoInfo.tipoIva;
      }
      if(presupuestoInfo.tasaGestores1){
        this.nuevoConcepto("Tasas gestiones", presupuestoInfo.tasaGestores1, 0, 0, presupuestoInfo.tasaGestores1, {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index, iva: 0});
      }
      if(presupuestoInfo.tasaGestores2){
        this.nuevoConcepto("Otras tasas de gestiones", presupuestoInfo.tasaGestores2, 0, 0, presupuestoInfo.tasaGestores2, {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index, iva: 0});
      }
      
      if(presupuestoInfo.envio){
        this.nuevoConcepto("Envío", presupuestoInfo.envio, 0, 0, presupuestoInfo.envio, {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index, iva: 0});
      } */
      detallePresupuesto.controls['concepto'].setValue(presupuestoInfo.tipoTramite.nombre);
      detallePresupuesto.controls['baseImponible'].setValue(String(total.toFixed(2)));
      detallePresupuesto.controls['tipoIva'].setValue("");
      detallePresupuesto.controls['tipoIrpf'].setValue("");
      detallePresupuesto.controls['total'].setValue(String(total.toFixed(2)));
      if(presupuestoInfo.honorariosGestoria && total != 0){
        this.nuevoConcepto("Honorarios gestoría", presupuestoInfo.honorariosGestoria, presupuestoInfo.tipoIva, 0, (presupuestoInfo.honorariosGestoria + (presupuestoInfo.honorariosGestoria * presupuestoInfo.tipoIva / 100)).toFixed(2), {conceptoSelected: false, mainConcepto: false, parentConceptoIndex: index, iva: presupuestoInfo.tipoIva});
      }
      else{
        detallePresupuesto.controls['baseImponible'].setValue(String(presupuestoInfo.honorariosGestoria.toFixed(2)));
        detallePresupuesto.controls['tipoIva'].setValue(String(presupuestoInfo.tipoIva));
        detallePresupuesto.controls['tipoIrpf'].setValue("");
        detallePresupuesto.controls['total'].setValue(String((presupuestoInfo.honorariosGestoria + (presupuestoInfo.honorariosGestoria * presupuestoInfo.tipoIva / 100)).toFixed(2)));
      }
      this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
    }
    else{
      this.listaInfoConceptos[index] = {conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined,  iva: 0, base: 0, total: 0};
      detallePresupuesto.controls['concepto'].setValue("");
      detallePresupuesto.controls['baseImponible'].setValue("");
      detallePresupuesto.controls['tipoIva'].setValue("");
      detallePresupuesto.controls['tipoIrpf'].setValue("");
      detallePresupuesto.controls['total'].setValue("");
      this.deleteConceptos(index);
      this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
    }
  }

  deleteConceptos(index){
    for(let i=0; i<this.listaInfoConceptos.length;){
      console.log(i, this.listaInfoConceptos[i].parentConceptoIndex === index)
      if(this.listaInfoConceptos[i].parentConceptoIndex === index){
        this.detallesPresupuesto.removeAt(i);
        this.listaInfoConceptos.splice(i,1);
      }
      else{i++}
    }
    console.log(this.listaInfoConceptos, this.detallesPresupuesto)
  }



  nuevoConcepto(concepto:any, baseImponible:any, tipoIva:any, tipoIrpf:any, total:any, infoConceptos?){
    var detalles = new FormGroup({
      concepto: new FormControl(concepto, Validators.required),
      baseImponible: new FormControl(String(baseImponible), Validators.required),
      tipoIva: new FormControl(String(tipoIva)),
      tipoIrpf: new FormControl(String(tipoIrpf)),
      total: new FormControl(String(total), Validators.required),
    });

    if(infoConceptos){
      this.listaInfoConceptos.push(infoConceptos)
    }
    else{
      this.listaInfoConceptos.push({conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined, iva: 0, base: 0, total: 0})
    }
    this.detallesPresupuesto.push(detalles);
    this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
  }

  calculoConceptoSeleccionado(i){
    let presupuestos = this.formPresupuesto.controls["detallesPresupuesto"].value;
    let presupuesto = presupuestos[i];
    let suplemento = Number((presupuesto.baseImponible - this.listaInfoConceptos[i].total).toFixed(2));
    let iva = presupuesto.tipoIva;
    if(iva == ""){
      iva = 0;
    }
    if(this.listaInfoConceptos[i+1]){
      let presupuestoSuplemento = presupuestos[i + 1];
      presupuestoSuplemento.baseImponible = String((Math.round(suplemento * 100) / 100).toFixed(2));
      presupuestoSuplemento.tipoIva = String(iva);
      if(suplemento>0){
        suplemento += suplemento * (iva / 100)
      };
      this.formPresupuesto.controls["detallesPresupuesto"].value[i + 1].total = String((Math.round(suplemento * 100) / 100).toFixed(2));
      this.formPresupuesto.controls["detallesPresupuesto"].value[i].total = String((Math.round((suplemento + this.listaInfoConceptos[i].total) * 100) / 100).toFixed(2));
    }
    else{
      this.nuevoConcepto(presupuesto.concepto + " suplemento", suplemento, 0, 0, suplemento + suplemento * (iva / 100), {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: i, iva: 0, base: 0, total: 0});
      if(suplemento>0){
        suplemento += suplemento * (iva / 100)
      };
      this.formPresupuesto.controls["detallesPresupuesto"].value[i].total = String((Math.round((suplemento + this.listaInfoConceptos[i].total) * 100) / 100).toFixed(2));
    }
    this.calcularTotales(presupuestos);
  }

  isEmpresaOnchanged() {
    console.log(this.tipoEntidad)
    if(this.formCliente.controls["tipoEntidad"].value == 2){
      this.isParticular = false

      this.formCliente.get('nombre').removeValidators(Validators.required);
      this.formCliente.get('apellido1').removeValidators(Validators.required);
      this.formCliente.get('apellido2').removeValidators(Validators.required);
      this.formCliente.get('dni').removeValidators(Validators.required);
      this.formCliente.get('fechaNacimiendo').removeValidators(Validators.required);
      this.formCliente.get('validezDni').removeValidators(Validators.required);
      this.formCliente.get('nombreEmpresa').addValidators(Validators.required);
      this.formCliente.get('cif').addValidators(Validators.required);

      this.formCliente.controls['nombre'].setErrors(null);
      this.formCliente.controls['apellido1'].setErrors(null);
      this.formCliente.controls['apellido2'].setErrors(null);
      this.formCliente.controls['dni'].setErrors(null);
      this.formCliente.controls['fechaNacimiendo'].setErrors(null);
      this.formCliente.controls['validezDni'].setErrors(null);

      this.formCliente.controls['nombre'].setValue(undefined)
      this.formCliente.controls['apellido1'].setValue(undefined)
      this.formCliente.controls['apellido2'].setValue(undefined)
      this.formCliente.controls['dni'].setValue(undefined)
      this.formCliente.controls['fechaNacimiendo'].setValue(undefined)
      this.formCliente.controls['validezDni'].setValue(undefined)

      console.log(this.formCliente)
    }else{
      this.isParticular = true

      this.formCliente.get('nombre').addValidators(Validators.required);
      this.formCliente.get('apellido1').addValidators(Validators.required);
      this.formCliente.get('apellido2').addValidators(Validators.required);
      this.formCliente.get('dni').addValidators(Validators.required);
      this.formCliente.get('fechaNacimiendo').addValidators(Validators.required);
      this.formCliente.get('validezDni').addValidators(Validators.required);
      this.formCliente.get('nombreEmpresa').removeValidators(Validators.required);
      this.formCliente.get('cif').removeValidators(Validators.required);

      this.formCliente.controls['nombreEmpresa'].setErrors(null);
      this.formCliente.controls['cif'].setErrors(null);

      this.formCliente.controls['nombreEmpresa'].setValue(undefined);
      this.formCliente.controls['cif'].setValue(undefined);

      console.log(this.formCliente)
    }

  }

  changeDetails(detallesPresupuesto){
    const newDetallesPresupuesto: any = new FormArray([]);
    var detalles;
    for(let i = 0; i< detallesPresupuesto.length; i++){
      detalles = new FormGroup({
        concepto: new FormControl(detallesPresupuesto.controls[i].value.concepto, Validators.required),
        baseImponible: new FormControl(detallesPresupuesto.controls[i].value.baseImponible, Validators.required),
        tipoIva: new FormControl(detallesPresupuesto.controls[i].value.tipoIva),
        tipoIrpf: new FormControl(""),
        total: new FormControl(detallesPresupuesto.controls[i].value.total, Validators.required),
      });
      console.log(detallesPresupuesto.controls[i])
      console.log(detallesPresupuesto.controls[i].value.total)
      console.log(detalles)
      newDetallesPresupuesto.push(detalles)
      
      if(this.listaInfoConceptos[i].parentConceptoIndex == i-1 && newDetallesPresupuesto.controls[i].controls['concepto'].value == newDetallesPresupuesto.controls[i-1].controls['concepto'].value + " suplemento"){
        newDetallesPresupuesto.controls[i-1].controls['baseImponible'].setValue(this.listaInfoConceptos[i-1].total);
        newDetallesPresupuesto.controls[i-1].controls['total'].setValue(this.listaInfoConceptos[i-1].total);
        newDetallesPresupuesto.controls[i-1].controls['tipoIva'].setValue("")
      }
    }
    return newDetallesPresupuesto;
  }

}
