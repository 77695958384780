import { Injectable, Input } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { firstValueFrom } from "rxjs";
import { NotificationService } from "./notification.service";
import { UserService } from "./user.service";

const TOKEN_KEY = "auth-token";
const USER_KEY = "auth-user";
const USER_ID = "auth_user_id";

@Injectable({
  providedIn: "root",
})
export class TokenStorageService {
  constructor(
    private cookieservice: CookieService,
    private userService: UserService,
    private _router: Router,
    private _notificationSvc: NotificationService
  ) {}

  timeoutID: any;

  dataUserForm = {
    nombre: "",
    apellido1: "",
    apellido2: "",
    id: "",
    email: "",
    tfa: "",
    "2faEnabled": "",
    rol: "",
    representados: [],
    esRepresentado: [],
    numero_documento: "",
    tipo_documento: "",
    tipopersona: "",
    telefono: "",
    direcciones: [],
    titulacion: "",
    fechaNacimiento: "",
    genero: "",
  };
  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);

    //this.setExpitationTimeout();
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public getId(): string | null {
    return window.sessionStorage.getItem(USER_ID).replace(/["]/g, "");
  }

  public setUser(user): void {
    window.sessionStorage.setItem(USER_ID, user.id);
    window.sessionStorage.setItem(
      USER_KEY,
      JSON.stringify(this.buildDataUser(user, {}))
    );
  }

  public saveUser(): void {
    window.sessionStorage.removeItem(USER_KEY);
    firstValueFrom(this.userService.getCurrentUserRepresented()).then(
      (dataRepresented) => {
        firstValueFrom(this.userService.getCurrentUserFromServer()).then(
          (dataUser) => {
            this.buildDataUser(dataUser.Usuario, dataRepresented);
            window.sessionStorage.setItem(USER_ID, dataUser.Usuario.id);
            window.sessionStorage.setItem(
              USER_KEY,
              JSON.stringify(this.dataUserForm)
            );
          }
        );
      }
    );
  }

  buildDataUser(dataUser, dataRepresented) {
    this.dataUserForm.id = dataUser.id;
    this.dataUserForm.nombre = dataUser.nombre;
    this.dataUserForm.apellido1 = dataUser.apellido1;
    this.dataUserForm.apellido2 = dataUser.apellido2;
    this.dataUserForm.tfa =
      dataUser.twoFactorSecret != null ? dataUser.twoFactorSecret : "disabled";
    this.dataUserForm.email = dataUser.email;
    this.dataUserForm.titulacion = dataUser.titulacion;
    this.dataUserForm.fechaNacimiento = dataUser.fechaNacimiento;
    this.dataUserForm.genero = dataUser.genero;

    this.dataUserForm["2faEnabled"] = dataUser.twoFactorEnabled;
    this.dataUserForm.rol = dataUser.rol.map((item) => item.id);
    this.dataUserForm.representados = dataUser.representados;
    this.dataUserForm.numero_documento = dataUser.numero_documento;
    this.dataUserForm.tipo_documento = dataUser.tipo_documento;
    this.dataUserForm.tipopersona = dataUser.tipopersona;
    this.dataUserForm.telefono = dataUser.telefono;
    this.dataUserForm.direcciones = dataUser.direcciones;
    this.dataUserForm.representados = dataRepresented.representa
      ? ""
      : dataRepresented.representa;
    this.dataUserForm.esRepresentado = dataRepresented.representado_por
      ? ""
      : dataRepresented.representado_por;

    return this.dataUserForm;
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    //console.log("usuario   "+user)
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  setExpitationTimeout() {
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(() => {
      this.signOutExpiration();
    }, 45 * 60000);
  }

  signOutExpiration(): void {
    window.sessionStorage.clear();
    this._notificationSvc.warning(
      "Ha expirado su sesión",
      "Su sesión ha expirado por inactividad, debe volver a logearse para continuar",
      5000
    );
    this._router.navigate(["/"], { replaceUrl: true });
  }

  getRol() {

    let roles = this.getUser().rol
    let highestRole = -1; // Inicializamos el valor más alto como 0

    for (let index = 0; index < roles.length; index++) {
      const role = roles[index];
      if (role > highestRole) {
        highestRole = role;
      }
    }
    return highestRole;
  }


  isExpired(token){
    return  Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000

  }
}
