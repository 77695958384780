<div class="page_content_sidebar">
    <div class="content_inner">
        <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'container2':'container' ">

            <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'center-Hencok':'center'">
                <div *ngIf="!isLoggedIn">
                    <h1>{{environment.TITLE_REGISTER}}</h1>
                    <p>
                        {{environment.TEXT_REGISTER}}
                    </p>
                </div>
                <form *ngIf="!isSuccessful" name="form" (ngSubmit)="onSubmit()" [formGroup]="form" novalidate
                    method="post" enctype="multipart/form-data">
                    <div class="row pt-4 p-0">
                        <div class="col-sm-6 d-flex p-0 flex-wrap">
                            <h5 class="col-sm-12 p-0">Nuevo Cliente</h5>
                            <p class="col-sm-12 p-0">Alta de clientes del autónomo </p>
                        </div>
                        <div class="row paddingCustom lineas pt-5"
                            [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'border-top':'border'">
                            <div class="col-sm-6 pt-2">
                                <label class="crear-editar-form requerido">Email</label>
                                <input type="text" class="form-control" name="email" formControlName="email"
                                    minlength="3"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['email'].errors}" />

                            </div>
                            <div class="col-sm-6 pt-2">
                                <label class="crear-editar-form requerido">Nombre o Razón social</label>
                                <input type="text" class="form-control" name="nombre"
                                    formControlName="nombre" 
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['nombre'].errors}" />

                            </div>
                            <div class="col-sm-6 pt-2">
                                <label class="crear-editar-form requerido">CIF/NIF</label>
                                <input type="text" class="form-control" name="documento" formControlName="documento"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['documento'].errors}" />
                            </div>
                            <div class="col-sm-6 pt-2">
                                <label class="crear-editar-form requerido">Domicilio</label>
                                <input type="text" class="form-control" name="domicilio" formControlName="domicilio"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['domicilio'].errors}" />
                            </div>
                            <div class="col-sm-2 pt-2">
                                <label class="crear-editar-form requerido">Nº domicilio</label>
                                <input type="text" class="form-control" name="numero_direccion" formControlName="numero_direccion"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['numero_direccion'].errors}" />
                            </div>
                            <div class="col-sm-4 pt-2">
                                <label class="crear-editar-form requerido">Código postal</label>
                                <input type="text" class="form-control" name="codigo_postal" formControlName="codigo_postal"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['codigo_postal'].errors}" />
                            </div>
                            <div class="col-sm-6 pt-2">
                                <label class="crear-editar-form requerido">Localidad</label>
                                <input type="text" class="form-control" name="localidad" formControlName="localidad"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['localidad'].errors}" />
                            </div>
                        </div>
                        <div class="w-100 d-flex guardar pt-2 justify-content-end">
                            <button type="button" class="btn  btn-outline-primary mr-2" (click)="closeSidebar()" [disabled]="submitted">
                                <div>Cancelar</div>
                            </button>
                            <button type="submit" class="btn btn-primary" [disabled]="submitted">
                                <div>Guardar</div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>