<div class="page__content">
    <div class="content__inner">
        <div class="content__login">
            <div class="card__login">
                <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                    <h1 *ngIf="!isNewUser">Restablecer contraseña</h1>
                    <h1 *ngIf="isNewUser"> Establezca su Acceso a la Plataforma</h1>
                    <div class="form-group">
                        <input type="email " class="form-control" name="email " [(ngModel)]="form.email" required
                            #email="ngModel" placeholder="Email " />
                        <div class="alert-danger" *ngIf="email.errors && f.submitted">
                            <div *ngIf="email.errors['required']">El email es requerido</div>
                            <div *ngIf="email.errors['email']">Escriba un email válido</div>
                        </div>
                    </div>
                    <div *ngIf="isNewUser" class="form-group">
                        <div class="password-div">
                            <input type="{{showOldPassword ? 'text' : 'password' }}" class="form-control" name="oldPassword " [(ngModel)]="form.oldPassword"
                            required minlength="6 " #newPassword="ngModel" placeholder="Contraseña actual (Contraseña recibida por correo)" />
                            <mat-icon matSuffix (click)="showOldPassword = !showOldPassword" class="eye-b">{{showOldPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </div>
                        <div class="alert-danger" role="alert " *ngIf="newPassword.errors && f.submitted">
                            <div *ngIf="newPassword.errors['required']">
                                La contraseña es requerida!
                            </div>
                            <div *ngIf="newPassword.errors['minlength']">
                                La contraseña debe tener al menos 6 caracteres
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="password-div">
                            <input type="{{showPassword ? 'text' : 'password' }}" class="form-control" name="newPassword " [(ngModel)]="form.newPassword"
                            required minlength="6 " #newPassword="ngModel" placeholder="Nueva contraseña" />
                            <mat-icon matSuffix (click)="showPassword = !showPassword" class="eye-b">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </div>
                        <div class="alert-danger" role="alert " *ngIf="newPassword.errors && f.submitted">
                            <div *ngIf="newPassword.errors['required']">
                                La contraseña es requerida!
                            </div>
                            <div *ngIf="newPassword.errors['minlength']">
                                La contraseña debe tener al menos 6 caracteres
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <!--
                        <button [disabled]="loading" class="btn btn-primary btn-block">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
              ></span>
              Restablecer contraseña
            </button>
            -->
                        <div class="form-group">
                            <button *ngIf="!isNewUser" class="btn btn-primary btn-block" [disabled]="loading">
                                Restablecer contraseña
                            </button>
                            <button type="button" (click)="changeCredentialsNewUser()" *ngIf="isNewUser" type class="btn btn-primary btn-block" [disabled]="loading">
                                Guardar
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <a routerLink="/login " class="forgot-password">Cancelar</a>
                    </div>
                </form>
                <div class="alert alert-success" *ngIf="isSuccessful">
                    Ha reseteado la contraseña!. Acceda con los nuevos datos de acceso.
                </div>
            </div>
        </div>
    </div>
</div>