import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ServicioGeneral } from '../_services/servicio-general.service';
import { NotificationService } from '../_services/notification.service';
import { AuthService } from '../_services/auth.service';

enum TokenStatus {
  Validating,
  Valid,
  Invalid
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  form: any = {
    email: null,
    newPassword: null,
    oldPassword: null,
  };
  TokenStatus = TokenStatus;
  tokenStatus = TokenStatus.Validating;
  isSuccessful = false;
  loading = false;
  identificador: string = '';
  showPassword: boolean = false;
  showOldPassword: boolean = false;
  isNewUser=false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private serviciogeneral: ServicioGeneral,
    private _notificacionService: NotificationService,
    private authService: AuthService,

  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]]
    });

    const token = this.route.snapshot.queryParams['token'];

    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.obtenerIdentificador();
    this.checkNewUser();

  }

  obtenerIdentificador() {
    this.route.params
      .subscribe(params => {
        //console.log(params);
        this.identificador = params['identificador'];
        //console.log(this.identificador);
      }
      );
  }

  checkNewUser(){
    let currentUrl = window.location.hash;
    if(currentUrl.includes('/new-user/')){
      this.isNewUser=true;
      this.form.addControl('oldPassword', [Validators.required]);
      this.form = this.formBuilder.group({
        email: ["", [Validators.required, Validators.email]],
        newPassword: ["", [Validators.required]],
        oldPassword: ["", [Validators.required]],
      });
    }
  }

  get f() { return this.form.controls; }

  onSubmit(): any {
    const { email, newPassword } = this.form;
    let tipo = "cambio";
    this.loading = true;
    this.serviciogeneral.resetPassword(this.identificador, email, newPassword, tipo).subscribe({
      next: data => {
        this.isSuccessful = true;
        this._notificacionService.success("Hecho", "Contraseña reseteada. Acceda con los nuevos datos de acceso.", 5000);
        this.router.navigate(['/login']);
      },
      error: err => { 
        this.loading = false;
        if(err.error.responseCode){
          if(err.error.responseCode == "625") this._notificacionService.error("Error", "La solicitud de cambio de contraseña no corresponde con este usuario.", 5000);
        }
        else{
          this._notificacionService.error("Error", err.error.message, 5000);
        }
      }
    });

  }

  changeCredentialsNewUser(){
    const { email, newPassword, oldPassword } = this.form;
    const tipo = "nuevo";
    this.loading = true;
      this.authService.login(email, oldPassword, "").subscribe({
        next: async (data) => {
          console.log(data);
          // console.log("response" , data, this.tfaFlag);
          this.serviciogeneral.resetPassword(this.identificador, email, newPassword, tipo).subscribe({
            next: data => {
              this.isSuccessful = true;
              this._notificacionService.success("Hecho", "Acceso establecido. Acceda con los nuevos datos de acceso.", 5000);
              this.router.navigate(['/login']);
            },
            error: err => { 
              this.loading = false;
              this._notificacionService.error("Error", err.error.message, 5000);
            }
          });
          
        },
        error: (error) => {
          this.loading = false;
          if(error.error.message == 'Debe firmar el contrato para poder iniciar sesión'){
            error.error.message = 'Debe firmar el contrato para poder establecer su acceso a la plataforma'
          }
          this._notificacionService.error('Error', error.error.message, 5000);
        },
      });
    
  }

}
