import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ContratoService } from 'src/app/_services/hencok/contrato.service';
import { NexcloudService } from 'src/app/_services/nexcloud.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { Contrato } from 'src/app/model/hencok/contrato';

@Component({
  selector: 'app-gestion-contratos',
  templateUrl: './gestion-contratos.component.html',
  styleUrls: ['./gestion-contratos.component.css']
})
export class GestionContratosComponent {

  constructor(
    private _notificationSvc: NotificationService,
    private contratoService: ContratoService,
    private nextCloud: NexcloudService,
    private notificacionService: NotificationService,

  ) { }

  mostrarFormulario: Boolean = false;
  editarFormulario: Boolean = false;
  totalElements: number = 0;
  listadoContratos: Contrato[];
  listadoTotalContratos: any;
  listadoTotalContratosInmutable: any;

  pageSize = 5;
  modeloEnv: any;
  mostrarContrato: boolean = false;

  isSortedByName: string = "";
  isSortedById: string = "";
  isSortedByConcepto: string = "";
  isSortedByPeriodoSolicitud: string = "";
  isSortedByEstado: string = "";
  isSortedByTipo: string = "";

  mostrarPag = true;
  searchText: String = "";

  isDelete = false;

  detalles;
  isShowedImpueto: boolean = false;

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };


  primeraVez: boolean = true;
  documentoData;
  ngOnInit() {
    this.contratoService.checkContratos().subscribe(() => {
      this.indexContratos({ page: "0", size: "5" })
      this.geTotalImpuestos()
    })


  }

  geTotalImpuestos() {
    this.contratoService.getImpuestosList().subscribe({
      next: (data) => {
        this.listadoTotalContratos = data
        this.listadoTotalContratosInmutable = data
      },
      error: (error) => {

      }
    })
  }



  filtrar(mesagge: string) {
    if (this.listadoTotalContratosInmutable != undefined) {
      if (mesagge.length > 0) {
        this.listadoContratos = this.listadoTotalContratosInmutable.filter(
          (item) =>
            (item.concepto.toLowerCase().indexOf(mesagge.toLowerCase()) > -1) ||
            item.concepto.toLowerCase().indexOf(mesagge.toLowerCase()) > 1 ||
            (item.autonomo.nombre.toLowerCase() + " " + item.autonomo.apellido1.toLowerCase() + " " +
              item.autonomo.apellido2.toLowerCase()).indexOf(mesagge.toLowerCase()) > -1 ||
            item.estado.nombre.toLowerCase().indexOf(mesagge.toLowerCase()) > -1 ||
            item.autonomo.id.toString().toLowerCase().indexOf(mesagge.toLowerCase()) > -1 ||
            item.periodoImpuesto.replaceAll("-", "/").indexOf(mesagge) > -1 ||
            item.fechaMaxima.replaceAll("-", "/").indexOf(mesagge) > -1
        );
        this.listadoTotalContratos = this.listadoContratos;
        this.pageSize = this.listadoTotalContratos.size
      } else {
        this.indexContratos({ page: "0", size: "5" })
        this.geTotalImpuestos()
      }

    }
    if (mesagge != "") {
      this.mostrarPag = false
    } else {
      this.mostrarPag = true
    }
  }




  public indexContratos(request) {
    return this.contratoService.index(request).subscribe((data) => {
      console.log(data)
      //  console.log(data)
      if (data.status != 630) { //Si encuentra elementos en la consulta
        this.listadoContratos = data['contrato']['content'];
        this.totalElements = data['contrato']['totalElements'];
      } else {
        this.listadoContratos = [];
        this.totalElements = 0;
      }
    })
  }

  nextPage(event: PageEvent) {
    const request = {};
    request['page'] = event.pageIndex.toString();
    request['size'] = event.pageSize.toString();
    this.pageSize = event.pageSize;
    this.indexContratos(request);
  }

  previewModel(contato: Contrato) {
    //  console.log(impuesto)
  }

  openPdf(contrato: Contrato) {
    this.nextCloud.downloadJustificanteB64(contrato.idDocumento)
      .subscribe({
        next: (documento) => {
          const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        }
      })
  }

  downloadPdf(contrato: Contrato) {
    this.nextCloud.downloadJustificanteB64(contrato.idDocumento).subscribe(
      (documento) => {
        const linkSource = 'data:application/pdf;base64,' + documento.Result;
        const downloadLink = document.createElement("a");
        const fileName = contrato.viafirmaCode;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        this.notificacionService.success('Hecho', 'Pdf descargado con éxito', 2000);

      }
    )
  }
  openSidebar(event: Event) {
    var element = document.getElementById("sidebar");
    element.classList.add("active");
    event.stopPropagation();
  }

  static closeSidebarClienteRemoto() {
    var element = document?.getElementById("sidebar");
    element?.classList.remove("active");
  }


  closeSidebar(e: any) {
    if (e.target.classList.value == "eye-button activated") {
    } else {
      var element = document?.getElementById("sidebar");
      element.classList.remove("active")
    }
    e.stopPropagation();
  }
  orderByTipo() {
   
    this.isSortedByConcepto = ""
    this.isSortedById = "";
    this.isSortedByPeriodoSolicitud = "";
    this.isSortedByEstado = "";
    this.isSortedByName = "";


    if (this.isSortedByTipo != "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        //console.log(n1.tipo_transaccion)
        //console.log(n1.tipo_transaccion > n2.tipo_transaccion)
        if (n1.tipo.nombre > n2.tipo.nombre) {
          return -1;
        }

        if (n1.tipo < n2.tipo) {
          return 1;
        }

        return 0;
      });
      this.isSortedByTipo = "chevron-button";
    } else if (this.isSortedByTipo == "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
       /*  console.log(n1.tipo)
        console.log(n2.tipo)
        console.log(n1.tipo.nombre > n2.tipo.nombre) */
        if (n1.tipo.nombre > n2.tipo.nombre) {
          return 1;
        }

        if (n1.tipo_transaccion.nombre < n2.tipo_transaccion.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByTipo = "chevron-button-up";
    }
    
    this.listadoContratos = []
    this.listadoContratos = this.listadoTotalContratos.slice(0, this.pageSize);
  }
 
  orderByName() {

    this.isSortedByConcepto = ""
    this.isSortedById = "";
    this.isSortedByPeriodoSolicitud = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";

    if (this.isSortedByName != "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (n1.autonomo.nombre > n2.autonomo.nombre) {
          return -1;
        }

        if (n1.autonomo.nombre < n2.autonomo.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByName = "chevron-button";

    } else if (this.isSortedByName == "chevron-button") {

      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (n1.autonomo.nombre > n2.autonomo.nombre) {
          return 1;
        }

        if (n1.autonomo.nombre < n2.autonomo.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByName = "chevron-button-up";

    }
    this.listadoContratos = []
    this.listadoContratos = this.listadoTotalContratos.slice(0, this.pageSize);
  }

  orderById() {
    this.isSortedByConcepto = ""
    this.isSortedByName = "";
    this.isSortedByPeriodoSolicitud = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";

    if (this.isSortedById != "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (n1.autonomo.id > n2.autonomo.id) {
          return -1;
        }

        if (n1.autonomo.id < n2.autonomo.id) {
          return 1;
        }

        return 0;
      });
      this.isSortedById = "chevron-button";

    } else if (this.isSortedById == "chevron-button") {

      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (n1.autonomo.id > n2.autonomo.id) {
          return 1;
        }

        if (n1.autonomo.id < n2.autonomo.id) {
          return -1;
        }

        return 0;
      });
      this.isSortedById = "chevron-button-up";

    }
    this.listadoContratos = []
    this.listadoContratos = this.listadoTotalContratos.slice(0, this.pageSize);
  }
  orderByConcepto() {
    this.isSortedById = "";
    this.isSortedByName = "";
    this.isSortedByPeriodoSolicitud = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";

    if (this.isSortedByConcepto != "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (n1.concepto > n2.concepto) {
          return -1;
        }

        if (n1.concepto < n2.concepto) {
          return 1;
        }

        return 0;
      });
      this.isSortedByConcepto = "chevron-button";

    } else if (this.isSortedByConcepto == "chevron-button") {

      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (n1.concepto > n2.concepto) {
          return 1;
        }

        if (n1.concepto < n2.concepto) {
          return -1;
        }

        return 0;
      });
      this.isSortedByConcepto = "chevron-button-up";

    }
    this.listadoContratos = []
    this.listadoContratos = this.listadoTotalContratos.slice(0, this.pageSize);
  }


  orderByEstado() {
    this.isSortedById = "";
    this.isSortedByName = "";
    this.isSortedByPeriodoSolicitud = "";
    this.isSortedByConcepto = "";
    this.isSortedByTipo = "";


    if (this.isSortedByEstado != "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {

        if (n1.estado.nombre > n2.estado.nombre) {
          return -1;
        }

        if (n1.estado.nombre < n2.estado.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button";
    } else if (this.isSortedByEstado == "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (n1.estado.nombre > n2.estado.nombre) {
          return 1;
        }

        if (n1.estado.nombre < n2.estado.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button-up";
    }

    this.listadoContratos = []
    this.listadoContratos = this.listadoTotalContratos.slice(0, this.pageSize);
  }



  getDateToCompare(fechas) {

    var date = new Date(fechas);
    return date.getTime();
  }
  orderByfecha() {
    this.isSortedById = "";
    this.isSortedByName = "";
    this.isSortedByConcepto = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";


    if (this.isSortedByPeriodoSolicitud != "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (this.getDateToCompare(n1.periodoImpuesto) > this.getDateToCompare(n2.periodoImpuesto)) {
          return -1;
        }

        if (this.getDateToCompare(n1.periodoImpuesto) < this.getDateToCompare(n2.periodoImpuesto)) {
          return 1;
        }

        return 0;
      });
      this.isSortedByPeriodoSolicitud = "chevron-button";
    } else if (this.isSortedByPeriodoSolicitud == "chevron-button") {
      this.listadoTotalContratos = this.listadoTotalContratos.sort((n1, n2) => {
        if (this.getDateToCompare(n1.periodoImpuesto) > this.getDateToCompare(n2.periodoImpuesto)) {
          return 1;
        }

        if (this.getDateToCompare(n1.periodoImpuesto) < this.getDateToCompare(n2.periodoImpuesto)) {
          return -1;
        }

        return 0;
      });
      this.isSortedByPeriodoSolicitud = "chevron-button-up";
    }

    this.listadoContratos = []
    this.listadoContratos = this.listadoTotalContratos.slice(0, this.pageSize);
  }

  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getPresupuestos();
  }

  getPresupuestos() {

    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this.contratoService
      .getContratoFilter(this.request)
      .subscribe((data: any) => {
        this.listadoContratos = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
      });
  }
}
