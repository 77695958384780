import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry } from 'rxjs';
import { TipoImpuesto } from 'src/app/model/tipoImpuesto';
import { environment } from 'src/environments/environment.hencok';
import { ServicioGeneral } from '../servicio-general.service';
import { Trimestre } from 'src/app/model/trimestre';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ImpuestosService {

  
 

  protected resourceUrl = `${environment.API_URL}/impuesto`;
  protected API_URL = environment.API_URL
  constructor(
    protected http: HttpClient,
    private serviceGeneral: ServicioGeneral,
  ){}

  index(request): Observable<any>{
    const params = request;
    return this.http.get(`${this.resourceUrl}`, {params});
  } 

  indexByTipoAndPeriodo(request, id:number): Observable<any>{
    const params = request;
    return this.http.get(`${this.resourceUrl}/listByTipoPeriodoPaginated/${id}`, {params});
  } 
  
  getTipoimpuesto() : Observable<TipoImpuesto>{
    return this.http
      .get(`${this.API_URL}/tipo_impuesto/list`)
      .pipe(retry(1), catchError(this.serviceGeneral.handleError));
  }
  getByImpuestoId(id: any):Observable<any> {
    return this.http
    .get(`${this.API_URL}/impuesto/getBy/${id}`)
    .pipe(retry(1), catchError(this.serviceGeneral.handleError));
  }

  saveImpuesto(form: any) {
    return this.http.post(`${this.API_URL}/impuesto/save`,form);
  }

  updateImpuesto(form: any) {
    return this.http.post(`${this.API_URL}/impuesto/update`,form);

  }
  getTtrimestre() : Observable<Trimestre> {
    return this.http
    .get(`${this.API_URL}/trimestre/list`)
    .pipe(retry(1), catchError(this.serviceGeneral.handleError));
  }
  deleteFile(detalles: any) {
    
    const datos = {
      id: detalles
    }
   /*  console.log("datos enviados a eliminar",datos);
  console.log("estoy en deletefile") */
  return this.http.post(`${this.API_URL}/impuesto/deleteFile`, datos);
    
  }
  deleteImpuesto(id: any)  {
    return this.http.get(this.API_URL +"/impuesto/deleteImpuesto/"+id);
    }

    getImpuestosList() {
      return this.http.get(`${this.resourceUrl}/listAll`)
    }
  getImpuestosFilter(request: any){
    return this.http.post(`${this.resourceUrl}/filter`, request);
  }
}
