<div [hidden]="mostrarFormulario" [hidden]="editarFormulario">
    <div class="d-flex flex-row justify-content-between align-items-center full-form-group ">
        <h1 class="m-0">Gestión de Facturas</h1>
        <div class="d-flex flex-row justify-content-between">

            <button *ngIf="total_facturas.length !=0" class="btn btn-primary align-middle mr-2"
                (click)="downloadExcel()" [hidden]="mostrarFormulario">
                <mat-icon class="align-middle mr-2">download</mat-icon><span class="align-middle"> Exportar</span>
            </button>

            <button class="btn btn-primary align-middle" (click)="setMostrarFormulario(true)"
                [hidden]="mostrarFormulario">
                <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Nueva factura</span>
            </button>
        </div>
    </div>
    <div class="full-form-group">

        <div class="subtitle">
            <h5>Listado de todas las facturas</h5>
        </div>

        <div class="w-100 border-bottom tableHead">
            <div class="w-100 filteringTable">
                <div class="w-100 row ordering-table">
                    <div class="card-width-name filter-container">
                        <input type="text" class="searchinput" placeholder="NOMBRE"
                            (keyup)="doFilter($event, 'nombre', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('nombre', 'nombre')"><a
                                [ngClass]="sortArray['nombre']==='ASC' ? 'asc-sort': sortArray['nombre']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-number-invoice filter-container">
                        <input type="text" class="searchinput" placeholder="ID"
                            (keyup)="doFilter($event, 'id', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('id', 'id')"><a
                                [ngClass]="sortArray['id']==='ASC' ? 'asc-sort': sortArray['id']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-date filter-container">
                        <input type="text" class="searchinput" onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}" placeholder="FECHA EMISIÓN" (change)="
                        doFilter($event, 'fechaFactura', 'LIKE', 'DATE')
                        " />
                        <button (click)="changeSorts('fechaFactura', 'fechaFactura')"><a
                                [ngClass]="sortArray['fechaFactura']==='ASC' ? 'asc-sort': sortArray['fechaFactura']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-date-expiration filter-container">
                        <input type="text" class="searchinput" onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}" placeholder="FECHA EXPIRACIÓN" (change)="
                        doFilter($event, 'fechaVencimiento', 'LIKE', 'DATE')
                        " />
                        <button (click)="changeSorts('fechaVencimiento', 'fechaVencimiento')"><a
                                [ngClass]="sortArray['fechaVencimiento']==='ASC' ? 'asc-sort': sortArray['fechaVencimiento']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-invoice filter-container">
                        <input type="text" class="searchinput" placeholder="FACTURA"
                            (keyup)="doFilter($event, 'numeroFactura', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('numeroFactura', 'numeroFactura')"><a
                                [ngClass]="sortArray['numeroFactura']==='ASC' ? 'asc-sort': sortArray['numeroFactura']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-status filter-container">
                        <input type="text" class="searchinput" placeholder="ESTADO"
                            (keyup)="doFilter($event, 'estado.nombre', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('estado.nombre', 'estado')"><a
                                [ngClass]="sortArray['estado']==='ASC' ? 'asc-sort': sortArray['estado']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-with-actions pr-0">
                        <div class="buttons">
                            ACCIONES
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card w-100 card-generic border-0">
            <div *ngIf="facturas?.length == 0" class="d-flex flex-row justify-content-center empty-data-message my-4">

                <span>No se han encontrado registros que mostrar</span>

            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item p-3 d-flex border rounded shadow"
                    *ngFor="let factura of facturas | filterFacturas : searchText">
                    <div class="row-with-data">
                        <div class="card border-0 card-width-name">
                            <p class="cut-text" [title]="factura.nombre?factura.nombre:''">
                                {{factura.nombre}}
                            </p>
                        </div>
                        <div class="card border-0 card-width-number-invoice">
                            #{{factura.idCliente}}
                        </div>
                        <div class="card border-0 card-width-date">
                            <p class="cut-text"
                                [title]="getDateToFilter(factura.fechaFactura)?getDateToFilter(factura.fechaFactura):''">
                                {{getDateToFilter(factura.fechaFactura)}}
                            </p>
                        </div>
                        <div class="card border-0 card-width-date-expiration">
                            <p class="cut-text"
                                [title]="getDateToFilter(factura.fechaVencimiento)?getDateToFilter(factura.fechaVencimiento):''">
                                {{getDateToFilter(factura.fechaVencimiento)}}
                            </p>
                        </div>
                        <div class="card border-0 card-width-invoice" (click)="downloadInvoice(factura)">
                            <p class="cut-text" [title]="factura.numeroFactura?factura.numeroFactura:''">
                                {{factura.numeroFactura}}
                            </p>
                        </div>
                        <div class="d-flex flex-row align-items-center card-width-status status" [ngClass]="factura.estado.nombre === 'PENDIENTE' ? 'colorpendiente':
                    factura.estado.nombre === 'COMPLETADO' ? 'colorcompletado' :
                    'colorrechazado' ">
                            <p class="cut-text" [title]="factura.estado.nombre?factura.estado.nombre:''">
                                {{factura.estado.nombre}}
                            </p>
                        </div>

                        <div class="card border-0 button-center">
                            <div class="buttons-flex-container align-items-center card-width-actions">
                                <a (click)="openPDF(factura,getDateToFilter(factura.fechaFactura), getDateToFilter(factura.fechaVencimiento))"
                                    class="eye-button activated" title="Ver"></a>
                                <a (click)="setEditarFormularioSend(true, factura)" class="edit-button activated pencil"
                                    title="Editar"></a>
                                <a (click)="downloadInvoice(factura)" class="download-button activated"
                                    title="Descargar"></a>
                                <a (click)="showModal(factura,dialog)" class="delete-button activated"
                                    title="Eliminar"></a>
                                <dialog #dialog>
                                    <div class="m-3 justify-content-center align-items-center">
                                        <a class="delete-button dialogborrar"></a>
                                        <div class="m-4">
                                            <h5 id="textdialog">¿Desea confirmar la eliminacion de la factura
                                                #ID{{factura.id}}?
                                            </h5>
                                        </div>
                                        <div class="d-flex justify-content-end m-4">
                                            <button (click)="dialog.close()" id="btcancel"
                                                class="btn btn-primary mx-1 mt-5" type="submit">Cancelar</button>
                                            <button (click)="deleteFactura(factura,$event,dialog)" id="btacept"
                                                class="btn btn-primary mx-1 mt-5" type="submit" [disabled]="isDelete">
                                                <div class="spinner-border spinner-border-sm" *ngIf="isDelete"></div>
                                                <span class="align-middle"> Confirmar</span>
                                            </button>
                                        </div>
                                    </div>
                                </dialog>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div *ngIf="facturas == null" class="d-flex flex-row justify-content-center empty-data-message my-4">
                Este usuario no dispone de facturas
            </div>
        </div>
        <mat-paginator *ngIf="mostrarPag" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements"
            (page)="nextPage($event)"> </mat-paginator>
    </div>
</div>
<div [hidden]="!mostrarFormulario">
    <app-formulario-factura (newItemEvent)="setMostrarFormularioChild()"></app-formulario-factura>
</div>
<div [hidden]="!editarFormulario">
    <app-formulario-factura-editar [FacturaRec]=facturaEnv
        (newItemEvent)="setEditarFormularioChild()"></app-formulario-factura-editar>
</div>