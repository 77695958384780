import { Component, EventEmitter, Output } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ClienteService } from "src/app/_services/hencok/cliente.service";
import { FacturaService } from "src/app/_services/hencok/factura.service";
import { GastosService } from "src/app/_services/hencok/gastos.service";
import { NotificationService } from "src/app/_services/notification.service";
import { PdfGenerateHencokService } from "src/app/_services/pdfGenerateHencok/pdf-generate-hencok.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { Factura } from "src/app/model/hencok/factura";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { GastoIngreso } from "src/app/model/hencok/gasto_ingreso";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-formulario-factura",
  templateUrl: "./formulario-factura.component.html",
  styleUrls: ["./formulario-factura.component.css"],
})
export class FormularioFacturaComponent {
  @Output() newItemEvent = new EventEmitter<Boolean>();

  formIngreso: UntypedFormGroup = new UntypedFormGroup({
    fecha_creacion: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_gasto: new UntypedFormControl("", Validators.required),
    tipo_transaccion: new UntypedFormControl(2),
  });

  arrayDocuments = [];
  userLogged: any = -1;
  userAdmin: Boolean = false;
  usuario: any = -1;
  usuarios: any[] = [];

  facturas: any;
  facturasInicio: any;
  submitFacturas: Boolean = false;
  submitted: boolean = false;

  uploadDocuments: Boolean = false;

  detallesFinal: [];
  tipoGastos: any = [];
  gasto: any = "";

  mostrarFormulario: Boolean = false;

  cantidades: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  cantidad: number = 0.0;
  subtotal: number = 0.0;
  iva: number = 0.0;
  cantidad_total: number = 0.0;
  cantidadNeta: number;
  precio: number = 0.0;
  total_deuda: number = 0;
  total_pendiente: number = 0;
  total_facturado: any = 0;

  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _tokenStorageService: TokenStorageService,
    private _facturaService: FacturaService,
    private _gastosService: GastosService,
    private notificationService: NotificationService,
    private _generatePdf: PdfGenerateHencokService,
    private _clienteService: ClienteService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.userLogged = this._tokenStorageService.getUser();
    //console.log(this.userLogged.rol);
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }

    this.getUserList();
    this.getTipoGastosList();

    this.formFactura.controls["fecha_factura"].setValue(
      this.formatDate(new Date())
    );
  }

  formFactura: FormGroup = new FormGroup({
    tipoGasto: new FormControl("", Validators.required),
    fecha_factura: new FormControl(new Date(), Validators.required),
    fecha_vencimiento: new FormControl("", Validators.required),
    detallesFactura: new FormArray([
      new FormGroup({
        concepto: new FormControl("", Validators.required),
        cantidad: new FormControl("", Validators.required),
        precio: new FormControl("", Validators.required),
        descuento: new FormControl(""),
        cantidadNeta: new FormControl("", Validators.required),
        tipoIva: new FormControl(""),
      }),
    ]),
  });

  get detallesFactura(): FormArray {
    return this.formFactura.get("detallesFactura") as FormArray;
  }

  deleteFactura() {
    this.formFactura.controls["detallesFactura"].value.pop();
    for (
      let i = 0;
      i < this.formFactura.controls["detallesFactura"].value.length;
      i++
    ) {
      this.calcularCantidadNeta(i);
    }

    this.detallesFactura.removeAt(this.detallesFactura.length - 1);
  }

  addFactura() {
    this.detallesFactura.push(
      new FormGroup({
        concepto: new FormControl("", Validators.required),
        cantidad: new FormControl("", Validators.required),
        precio: new FormControl("", Validators.required),
        descuento: new FormControl(""),
        cantidadNeta: new FormControl("", Validators.required),
        tipoIva: new FormControl(""),
      })
    );
  }

  async onSubmitFacturas(): Promise<any> {
    
   // console.log(this.formFactura.controls)
    if (this.usuario == -1) {
      this.notificationService.warning(
        "INFORMACIÓN",
        "Debe seleccionar un usuario",
        2000
        
      );
     
 
      var element = document.getElementById("selectUser");
      element.focus();
       return false;
        } else  if(this.formFactura.controls["fecha_factura"].invalid){
        this.notificationService.warning(
          "Informacion",
          "El formato de la fecha  no es correcto",
          2000
        );
        return false;
       }else if(this.formFactura.controls["fecha_vencimiento"].invalid){
          this.notificationService.warning(
            "Informacion",
            "El formato de la fecha de vencimiento no es correcto",
            2000
          );
          return false;
        
          
        }else if(this.formFactura.controls["detallesFactura"].invalid){
          this.notificationService.warning(
            "Informacion",
            "los datos del detalle de la factura no son correctos",
            2000
          );
          return false;
      
    } else if (!this.formFactura.valid) {
      this.notificationService.warning(
        "Informacion",
        "Rellene todos los campos por favor",
        2000
      );
      return false;
    }
    else if(this.formFactura.controls["fecha_factura"].value > this.formFactura.controls["fecha_vencimiento"].value){
      this.notificationService.warning(
        "Informacion",
        "La fecha de vencimiento no puede ser anterior a la fecha de emisión",
        2000
      );
      return false;
    }
    else {
      this.submitFacturas = true;
      this.uploadDocuments = true;
      var gastoFinal = { id: this.gasto};
      
      var idCliente
      if(this.userAdmin){
        idCliente=this.usuario.id;
      }
      else{
        idCliente=this.usuario.idVisible;
      }

      //console.log(this.gasto);
      var domicilio = this.usuario?.direcciones
        ? this.usuario?.direcciones[0].name_road +
          " " +
          this.usuario?.direcciones[0]?.number_road
        : this.usuario.domicilio + this.usuario.numero_direccion;
      var codPostal = this.usuario?.direcciones
        ? this.usuario?.direcciones[0].postal_code
        : this.usuario.codigo_postal;
      var localidad = this.usuario?.direcciones
        ? this.usuario?.direcciones[0].province.nombre
        : this.usuario.localidad;
      var documento = this.usuario.numero_documento
        ? this.usuario.numero_documento
        : this.usuario.documento;

      var nombre = this.usuario?.apellido1
        ? this.usuario.nombre +
          " " +
          this.usuario.apellido1 +
          " " +
          this.usuario.apellido2
        : this.usuario.nombre;
      var facturaFinal = new Factura(
        this.formatDate(this.formFactura.controls["fecha_factura"].value),
        this.formatDate(this.formFactura.controls["fecha_vencimiento"].value),
        this.iva.toString(),
        this.subtotal.toString(),
        this.cantidad_total.toString(),
        "0",
        idCliente,
        this.usuario.email,
        documento,
        nombre,
        domicilio,
        codPostal,
        localidad,
        gastoFinal
      );

      this.detallesFinal = this.formFactura.controls["detallesFactura"].value;
      
      if (this.userAdmin != true) {
        this.formIngreso.controls["importe"].setValue(
          facturaFinal.cantidadTotal
        );
        //console.log(gastoFinal);
        this.formIngreso.controls["tipo_gasto"].setValue(
          gastoFinal.id
        );

        let tipo_transaccion = {
          id:2,
          nombre:"INGRESO",
        }

        let ingreso = new GastoIngreso(
          this.usuario.id,
          "",
          //this.formatDate(this.formFactura.controls["fecha_factura"].value),
          tipo_transaccion,
        )

        this._facturaService
        .createWithIngreso(facturaFinal, this.detallesFinal, ingreso, false, false)
        .subscribe({
          next: (data) => {
            //console.log("save data", data);
            this._router.navigateByUrl("/client-hencok/facturacion").then(() => {
              this._router.navigate(
                ["client-hencok/facturacion/" + this.userLogged.id],
                { skipLocationChange: true }
              );
            });
            this.notificationService.success(
              "Hecho",
              "Factura guardada con éxito",
              2000
            );
            this.uploadDocuments = false;
            
          },
          error: (error) => {
            this.uploadDocuments = false;
          //  console.log(error);
          },
        });

        //console.log(this.arrayDocuments);
        //console.log("Voy a entrar a guardar gasto");
      } else {
        this._facturaService
        .create(facturaFinal, this.detallesFinal, true)
        .subscribe({
          next: (data) => {
            //console.log("save data", data);
            this._router.navigateByUrl("/admin-hencok").then(() => {
              this._router.navigate(["admin-hencok/facturacion"]);
            });
            this.notificationService.success(
              "Hecho",
              "Factura guardada con éxito",
              2000
            );
            this.uploadDocuments = false;
            
          },
          error: (error) => {
            this.uploadDocuments = false;
            //console.log(error);
          },
        });
      }
      //console.log(this.formFactura.value);
    }
  }

  addNewItem() {
    this.newItemEvent.emit(false);
  }

  getGasto(gasto) {
    this.gasto = gasto;
  }

  setMostrarFormulario(mode: Boolean) {
    this.mostrarFormulario = mode;
  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];
        //console.log(this.usuario);

        // this._serviceGeneral.getIbanByUser(id).subscribe({
        //   next: (iban) => {
        //     this.iban = iban.iban
        //   }
        // });
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }

  getCliente(id): void {
    this._serviceGeneral.getClientId(id, this.userLogged.id).subscribe({
      next: (usuario) => {
        this.usuario = usuario;
        // this._serviceGeneral.getIbanByUser(id).subscribe({
        //   next: (iban) => {
        //     this.iban = iban.iban
        //   }
        // });
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }

  public downloadPdf(base64Document, fileName) {
    const source = "data:application/pdf;base64," + base64Document;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  calcularCantidadNeta(index: number) {
    var rowFactura = this.formFactura.controls["detallesFactura"].value[index];
    var cantidad = 1;
    var descuento = 0;
    var ivaTotal = 0;

    if (rowFactura.precio != "") {
      if (rowFactura.cantidad != "") {
        cantidad = rowFactura.cantidad;
      }
      if (rowFactura.descuento != "") {
        descuento = rowFactura.descuento;
      }

      this.formFactura.controls["detallesFactura"].value[index].cantidad =
        parseFloat(
          this.formFactura.controls["detallesFactura"].value[index].cantidad
        );
      this.formFactura.controls["detallesFactura"].value[index].cantidadNeta = (
        cantidad * rowFactura.precio -
        descuento
      ).toFixed(2);

      // calculamos el iva y se lo sumamos a cada prodiucto
      if (rowFactura.tipoIva != "") {
        ivaTotal =
          (cantidad * rowFactura.precio - descuento) *
          (rowFactura.tipoIva / 100);
      }
      //Modificamos subtotal, IVA total y Precio final
      this.calcularTotales(this.formFactura.controls["detallesFactura"].value);
    } else {
      this.formFactura.controls["detallesFactura"].value[
        index
      ].cantidadNeta = 0;
      this.subtotal = 0;
      this.cantidad_total = 0;
    }
  }

  calcularTotales(facturas) {
    this.subtotal = 0;
    this.iva = 0.0;
    this.cantidad_total = 0;

    for (let factura of facturas) {
      if (factura.subtotal == "") {
        this.subtotal += 0.0;
      } else {
        this.subtotal += Number.parseFloat(factura.cantidadNeta);
      }

      if (factura.tipoIva == "") {
        this.iva += 0.0;
      } else {
        this.iva += factura.cantidadNeta * (factura.tipoIva / 100);
      }
    }
    this.subtotal = Number.parseFloat(this.subtotal.toFixed(2));
    this.iva = Number.parseFloat(this.iva.toFixed(2));
    this.cantidad_total = parseFloat((this.subtotal + this.iva).toFixed(2));
  }

  generateFactura() {
    //console.log(this.formFactura.value);
    this._generatePdf
      .generateFacturaCompleta(
        this.userLogged,
        this.usuario,
        this.formFactura.value,
        this.iva.toFixed(2).toString(),
        this.subtotal.toString(),
        this.cantidad_total.toString(),
        "BORRADOR",
        "BORRADOR",
        this.userAdmin
      )
      .then((documentDefinition) => {
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.open();
      });
  }

  getUserList() {
    if (this.userAdmin) {
      this._serviceGeneral.getUsersRoleOne().subscribe({
        next: (data) => {
          this.usuarios = data;
        },
        error: (error) => {
          //console.log(error);
        },
      });
    } else {
      this._clienteService
        .getListClientesByIdAutonomo(this.userLogged.id)
        .subscribe({
          next: (data) => {
            this.usuarios = data;
          },
          error: (error) => {
            //console.log(error);
          },
        });
    }
  }

  getTipoGastosList() {
    this._gastosService.getTipoGasto().subscribe({
      next: (data) => {
        this.tipoGastos = data;
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  formatDateIngreso(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }
}
