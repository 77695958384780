import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatriculacionService } from 'src/app/_services/autoescuela/matriculacion.service';
import { PresupuestoService } from 'src/app/_services/autoescuela/presupuesto.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { EntidadTransferencia } from 'src/app/model/autoescuela/entidad_transferencia';
import { VehiculoTransderencia } from 'src/app/model/autoescuela/vehiculo_transferencia';

@Component({
  selector: 'app-formulario-matriculacion',
  templateUrl: './formulario-matriculacion.component.html',
  styleUrls: ['./formulario-matriculacion.component.css']
})
export class FormularioMatriculacionComponent {
  isAdmin = false;
  isMod = false;

  titular: EntidadTransferencia = new EntidadTransferencia()
  vehiculo: VehiculoTransderencia = new VehiculoTransderencia()

  usuario = this.token.getId();
  idMatr
  isUpdated: boolean = false;
  iscomprador: boolean = true;
  transferenciaUpadte
  submitted: boolean = false;
  ListMunicipios: any = [];
  ListComunidades: any = [];
  ListVias: any = [];
  ListProvincias: any = [];
  isEmpresa: boolean = false;
  formCliente2: any;
  formCliente: any;

  isRequired: boolean = false;
  marca: string = "";
  listTipo = [{
    id: "1",
    nombre: "Empresa"
  }, {
    id: "2",
    nombre: "Particular"
  },]

  presupuesto: boolean = false;

  form: UntypedFormGroup = new UntypedFormGroup({

    usuario: new UntypedFormControl(this.usuario),

    tipoEntidad: new UntypedFormControl(this.listTipo[1]["id"]),
    nombre: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", Validators.required),
    apellido1: new UntypedFormControl("", Validators.required),
    apellido2: new UntypedFormControl("", Validators.required),
    numero_documento: new UntypedFormControl("", Validators.required),
    fecha_nacimiento: new UntypedFormControl(""),
    validez_dni: new UntypedFormControl(""),
    telefono: new UntypedFormControl("", Validators.required),
    tipo_via: new UntypedFormControl("", Validators.required),
    nombre_via: new UntypedFormControl("", Validators.required),
    numero_via: new UntypedFormControl("", Validators.required),
    bloque: new UntypedFormControl(""),
    escalera: new UntypedFormControl(""),
    piso: new UntypedFormControl(""),
    puerta: new UntypedFormControl(""),
    km: new UntypedFormControl(""),
    ccaa: new UntypedFormControl("", Validators.required),
    provincia: new UntypedFormControl("", Validators.required),
    municipio: new UntypedFormControl("", Validators.required),
    codigo_postal: new UntypedFormControl("", Validators.required),
    nombre_empresa: new UntypedFormControl(""),
    cif_empresa: new UntypedFormControl(""),

    bastidor: new UntypedFormControl("", Validators.required),


  });






  constructor(
    private notificationSvc: NotificationService,
    private servicioGeneral: ServicioGeneral,
    private servicioMatriculacion: MatriculacionService,
    private presupuestoService: PresupuestoService,
    public router?: Router,
    public route?: ActivatedRoute,
    private token?: TokenStorageService
  ) { }

  ngOnInit() {
    
    if (this.token.getRol() == 3 || this.token.getRol() == 99) {
      this.isAdmin = true;
    }
    if (this.token.getRol() == 5 || this.token.getRol() == 6) {
      this.isMod = true;
    }
    this.formCliente2 = this.route.snapshot.paramMap
    this.formCliente2 = this.formCliente2.params
    console.log(this.formCliente2)
    if (this.formCliente2.AsociarTramite != undefined) {
      this.formCliente = this.formCliente2
      this.  setMatriculacion();
    }

    if (this.formCliente2.AsociarTramite != undefined) {
      this.presupuesto = true
    }
    if(this.formCliente==undefined){
      this.route.params.subscribe((params) => {
        this.idMatr = params["id"];
      })
  }
    this.getVias();
    this.getComunidades();
    if(this.idMatr){
      this.isUpdated = true;
      this.getMatriculacion(this.idMatr)

    }
  }



  getMatriculacion(id) {
    this.servicioMatriculacion.getMatrId(id).subscribe((data) => {
      if (data == null) {
        this.notificationSvc.error(
          "Error",
          "Acceso no permitido",
          2000
        );
        if (!this.isAdmin) {
          this.router.navigate(['/client-autoescuelas-hencok/listado-tramites'])

        } else {
          this.router.navigate(['/admin-autoescuelas-hencok/listado-tramites'])

        }
      }
      console.log(data);
      
      this.titular = data["titularVehiculo"];
      this.vehiculo = data["vehiculo"];
      this.initiateForm()
    })

  }


  initiateForm() {

    this.getProvinciasByID(this.titular.comunidadAutonoma.id, false)
    this.isEmpresa = false


    if (this.titular.tipoEntidad.id == 1) this.isEmpresa = true



    this.form = new UntypedFormGroup({
      usuario: new UntypedFormControl(this.usuario),
      tipoEntidad: new UntypedFormControl(this.titular.tipoEntidad.id.toString()),
      nombre: new UntypedFormControl(this.titular.nombre, Validators.required),
      email: new UntypedFormControl(this.titular.email, Validators.required),
      apellido1: new UntypedFormControl(this.titular.apellido1, Validators.required),
      apellido2: new UntypedFormControl(this.titular.apellido2, Validators.required),
      numero_documento: new UntypedFormControl(this.titular.dni, Validators.required),
      fecha_nacimiento: new UntypedFormControl(undefined, Validators.required),
      validez_dni: new UntypedFormControl(undefined, Validators.required),
      telefono: new UntypedFormControl(this.titular.telefono + "", Validators.required),
      tipo_via: new UntypedFormControl(this.titular.tipoVia.id, Validators.required),
      nombre_via: new UntypedFormControl(this.titular.nombreVia, Validators.required),
      numero_via: new UntypedFormControl(this.titular.numeroVia.toString(), Validators.required),
      bloque: new UntypedFormControl(this.titular.bloque + ""),
      escalera: new UntypedFormControl(this.titular.escalera),
      piso: new UntypedFormControl(this.titular.planta),
      puerta: new UntypedFormControl(this.titular.puerta),
      km: new UntypedFormControl(this.titular.km),
      ccaa: new UntypedFormControl(this.titular.comunidadAutonoma.id, Validators.required),
      provincia: new UntypedFormControl(this.titular.provincia.id, Validators.required),
      municipio: new UntypedFormControl("", Validators.required),
      codigo_postal: new UntypedFormControl(this.titular.codigoPostal, Validators.required),
      nombre_empresa: new UntypedFormControl(this.titular.nombreEmpresa),
      cif_empresa: new UntypedFormControl(this.titular.cif),
      
      bastidor: new UntypedFormControl(this.vehiculo.bastidor, Validators.required),

    }, { updateOn: 'change' });

    if(this.titular.tipoEntidad.id == 1){
      this.form.get('nombre_empresa').addValidators(Validators.required);
      this.form.get('cif_empresa').addValidators(Validators.required);

      this.form.get('fecha_nacimiento').removeValidators(Validators.required);
      this.form.get('validez_dni').removeValidators(Validators.required);

      this.form.controls['fecha_nacimiento'].setErrors(null);
      this.form.controls['validez_dni'].setErrors(null);
    }
    else{
      this.form.controls['fecha_nacimiento'].setValue(this.formatDate(this.titular.fechaNacimiendo))
      this.form.controls['validez_dni'].setValue(this.formatDate(this.titular.validezDni))
    }

    this.getMunicipiosByID(this.titular.provincia.id + "", false, true)

  }
  getComunidades() {
    return this.servicioGeneral.getComunidades().subscribe((data) => {
      this.ListComunidades = data['ComunidadAutonoma'];
    });
  }
  getVias() {
    return this.servicioGeneral.getTipoVias().subscribe((data) => {
      this.ListVias = data['TipoVia'];
    });
  }


  onChangeProvincia(event: Event, isVendedor) {

    this.ListProvincias = [];


    const id = (event.target as HTMLInputElement).value;
    this.getProvinciasByID(id, isVendedor);
  }
  onChangeMunicipio(event: Event, isVendedor) {

    this.ListMunicipios = [];


    const id = (event.target as HTMLInputElement).value;
    this.getMunicipiosByID(id, isVendedor);
  }





  getMunicipiosByID(id: string, isVendedor, first?) {

    this.servicioGeneral.getMunicipios().subscribe((data) => {
      
      data['Municipio'].forEach((municipio) => {
        if (municipio.municipioPK["provincia"] == id) {
          this.ListMunicipios.push(municipio);
          if (first != undefined && first == true && this.titular.localidad === municipio.nombre) {
            this.form.controls["municipio"].setValue(municipio.municipioPK['municipio_id']);
          }
        }
        
      })
      console.log(this.formCliente.localidad)
      this.form.controls["municipio"].setValue( this.sacaridMunicipio(this.formCliente.localidad));
    });

  }
  getProvinciasByID(id: string, isVendedor) {
    this.servicioGeneral.getProvincias().subscribe(
      (data) => {
        data['Provincia'].forEach((provincia) => {
          if (provincia.ccaa["id"] == id) {

            this.ListProvincias.push(provincia);

          }
        });
        this.form.controls["provincia"].setValue(this.formCliente.provincia2);
      });
  }

    isEmpresaOnchanged() {
    if(this.isEmpresa){
      this.form.controls["tipoEntidad"].setValue(2)
      this.isEmpresa=false;

      this.form.get('fecha_nacimiento').addValidators(Validators.required);
      this.form.get('validez_dni').addValidators(Validators.required);
      this.form.get('nombre_empresa').removeValidators(Validators.required);
      this.form.get('cif_empresa').removeValidators(Validators.required);

      this.form.controls['nombre_empresa'].setErrors(null);
      this.form.controls['cif_empresa'].setErrors(null);

      this.form.controls['nombre_empresa'].setValue("");
      this.form.controls['cif_empresa'].setValue("");

      console.log(this.form)
    }else{
      this.form.controls["tipoEntidad"].setValue(1)
      this.isEmpresa=true;

      this.form.get('fecha_nacimiento').removeValidators(Validators.required);
      this.form.get('validez_dni').removeValidators(Validators.required);
      this.form.get('nombre_empresa').addValidators(Validators.required);
      this.form.get('cif_empresa').addValidators(Validators.required);

      this.form.controls['fecha_nacimiento'].setErrors(null);
      this.form.controls['validez_dni'].setErrors(null);

      this.form.controls['fecha_nacimiento'].setValue("")
      this.form.controls['validez_dni'].setValue("")

      console.log(this.form)
    }

  }

  async onSubmit(): Promise<any> {

    this.isRequired = true

    console.log(this.form)

    this.submitted = true;
    var vin = String(this.form.controls['bastidor'].value).toUpperCase();

    var re = new RegExp("^[A-HJ-NPR-Z\\d]{11}\\d{6}$");
    if (vin.match(re)) {
      console.log("matchvin")
      this.form.controls['bastidor'].setValue(vin);
    }
    else {
      this.notificationSvc.warning('Información', 'El número de bastidor no es válido', 2000);
      this.form.controls['bastidor'].setErrors({ 'incorrect': true });
      this.submitted = false;
      return false;
    }

    if (!this.form.valid) {

      this.notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
      this.isRequired = true;
      return false;
    } else {
      if (this.isEmpresa) {
        if (this.form.get('nombre_empresa')?.value == undefined ||
          this.form.get('cif_empresa')?.value == undefined) {
          this.notificationSvc.warning('Información', 'Rellene los campos necesarios para empresas.', 2000);
          this.submitted = false;
        }
      }

      if (this.form.get('telefono').value.length != 9) {
        this.notificationSvc.error('Error', 'No es un teléfono válido.', 2000);

        return false;
      }
      if (this.form.get('codigo_postal').value.length != 5) {
        this.notificationSvc.error('Error', 'No es un código postal válido.', 2000);
        return false;
      }
      if ((this.form.get('validez_dni').value == ""
        || this.form.get('fecha_nacimiento').value == "") && this.form.controls["tipoEntidad"].value == 2) {
        this.notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
        this.isRequired = true;

        return false;
      }
    }
    if (this.form.valid) {
      if (!this.isUpdated) {
        this.submitted = true;
        this.servicioMatriculacion.saveMatr(this.form.value).subscribe({
          next: (data) => {
            if (this.presupuesto) {
              if (this.formCliente.AsociarTramite != undefined) {


                this.presupuestoService.asociarTramiteAPresupuesto
                  (data["id"], this.formCliente["AsociarTramite"],3).subscribe(
                    (data) => { this.notificationSvc.success('Exito', "Tramite asociado", 2000); }
                    , (error) => { }
                  )
              }
            }
            this.notificationSvc.success('Exito', "Tramite creado", 2000);
            if (!this.isAdmin) {
              this.router.navigate(['/client-autoescuelas-hencok/matriculacion/' + data["id"]])

            } else {
              this.router.navigate(['admin-autoescuelas-hencok/matriculacion/' + data["id"]])
            }
            this.submitted = false;
            this.isRequired = false;

          },
          error: (err) => {
            console.log(err)

            if (err.error.status == 644) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
            } else if (err.error.status == 627) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['email'].setErrors({ 'incorrect': true });
            }
            else {
              this.notificationSvc.error('ERROR', err.error + "", 2000);
            }
            this.submitted = false;
          }
        })
      } else {
        this.servicioMatriculacion.updateMatr(this.form.value, this.idMatr).subscribe({
          next: (data) => {
            this.notificationSvc.success('Exito', "Transferencia creada", 2000);

            if (!this.isAdmin) {
              this.router.navigate(['/client-autoescuelas-hencok/matriculacion/' + data["id"]])

            } else {
              this.router.navigate(['admin-autoescuelas-hencok/matriculacion/' + data["id"]])
            }

            this.submitted = false;
            this.isRequired = false;

          },
          error: (err) => {

            if (err.error.status == 644) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
            } else if (err.error.status == 627) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['email'].setErrors({ 'incorrect': true });
            }
            else {
              this.notificationSvc.error('ERROR', err.error + "", 2000);
            }
            this.submitted = false;
          }
        })
      }


    }


  }

  formatDate(date) {
    var date2 = []
    date2 = date.split("-");
    let dia = date2[0];
    let mes = date2[1];
    let anio = date2[2];
    return anio + "-" + mes + "-" + dia;
  }

  setMatriculacion() {
    if (!this.formCliente.cif || this.formCliente.cif == "" || this.formCliente.cif == "null") {
      this.isEmpresa = false
      this.form.controls["tipoEntidad"].setValue(2)

    } else {
      this.isEmpresa = true
      this.form.controls["tipoEntidad"].setValue(1)
      this.form.controls["nombre_empresa"].setValue(this.formCliente.nombreEmpresa);
      this.form.controls["cif_empresa"].setValue(this.formCliente.cif)
    }

    this.form.controls["nombre"].setValue(this.formCliente.nombre);

    this.form.controls["apellido1"].setValue(this.formCliente.apellido1);
    this.form.controls["apellido1"].setValue(this.formCliente.apellido1);
    this.form.controls["apellido2"].setValue(this.formCliente.apellido2);

    this.form.controls["numero_documento"].setValue(this.formCliente.dni);
    this.form.controls["fecha_nacimiento"].setValue(this.formCliente.fechaNacimiendo);
    this.form.controls["validez_dni"].setValue(this.formCliente.validezDni);

    this.form.controls["telefono"].setValue(this.formCliente.telefono);
    this.form.controls["email"].setValue(this.formCliente.email);

    this.form.controls["tipo_via"].setValue(this.formCliente.tipoVia2);
    this.form.controls["nombre_via"].setValue(this.formCliente.nombreVia);
    this.form.controls["numero_via"].setValue(this.formCliente.numeroVia);

    this.form.controls["bloque"].setValue(this.formCliente.bloque == "null" ? "" : this.formCliente.bloque);
    this.form.controls["escalera"].setValue(this.formCliente.escalera == "null" ? "" : this.formCliente.escalera);
    this.form.controls["piso"].setValue(this.formCliente.planta == "null" ? "" : this.formCliente.planta);
    this.form.controls["puerta"].setValue(this.formCliente.puerta == "null" ? "" : this.formCliente.puerta);
    this.form.controls["km"].setValue(this.formCliente.km == "null" ? "" : this.formCliente.km);
    this.form.controls["codigo_postal"].setValue(this.formCliente.codigoPostal);
    this.form.controls["ccaa"].setValue(this.formCliente.comunidadAutonoma);
    console.log("11")
   this.getProvinciasByID(this.formCliente.comunidadAutonoma, false)
    this.form.controls["provincia"].setValue("this.formCliente.provincia");
  this.getMunicipiosByID(this.formCliente.provincia2, false)



  }

  sacaridMunicipio(nombre: String){

    var id: number = 0;
    console.log(this.ListMunicipios)
    for(let i = 0; i < this.ListMunicipios.length ; i++){
      
      if (this.ListMunicipios.at(i).nombre == nombre) {
        id = this.ListMunicipios.at(i).municipioPK.municipio_id;
        console.log(this.ListMunicipios.at(i))
      }
    }
    console.log(id)
    return id;
  }

}
