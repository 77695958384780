<div class="d-flex flex-row justify-content-between align-items-center pr-5 ml-4">
    <h1 class="m-0 " *ngIf="!isNew">Editar Presupuesto</h1>


    <div [hidden]="itp" [hidden]="inTramite">

        <button class="btn btn-outline-primary align-middle" (click)="addNewItem()">
            <mat-icon class="align-middle mr-2">table_rows</mat-icon><span class="align-middle">Mostrar tabla</span>
        </button>
    </div>
</div>
<div class="" *ngIf="!isNew">
    <hr class="linea-divisoria ml-4">
</div>


<form [formGroup]="formCliente" [ngClass]="!isParticular == true ? 'trueParticular':
    'trueFalse' ">
    <div class="firstFormTitle">
        <label class="formTitleText">Datos de contacto</label>
    </div>
    <div class=" formGroup bgLightGreen">
            <div class="rowForm" >
                <div class="row">
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Tipo de persona</label>
                        <select formControlName="tipoEntidad" class="form-control"
                            [ngClass]="{'is-invalid': submitPresupuesto && this.formCliente.controls['tipoEntidad'].errors}"
                            (change)="isEmpresaOnchanged()">
                            <option *ngFor="let tipo of listTipo" [value]="tipo.id">
                                {{ tipo.nombre }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-3" *ngIf="isParticular">
                        <label class="crear-editar-form requerido">Nombre</label>
                        <input type="text" formControlName="nombre"
                            [ngClass]="{'is-invalid': submitPresupuesto && formCliente.controls['nombre'].errors}"
                            class="form-control">
                    </div>
                    <div class="col-sm-3" *ngIf="isParticular">
                        <label class="crear-editar-form requerido">Primer apellido</label>
                        <input type="text" formControlName="apellido1"
                            [ngClass]="{'is-invalid': submitPresupuesto && formCliente.controls['apellido1'].errors}"
                            class="form-control">
                    </div>
    
    
                    <div class="col-sm-3" *ngIf="isParticular">
                        <label class="crear-editar-form">Segundo apellido</label>
                        <input type="text" formControlName="apellido2" class="form-control">
                    </div>
                    <div class="col-sm-6" *ngIf="!isParticular">
                        <label class="crear-editar-form requerido">Nombre de empresa</label>
                        <input type="text" formControlName="nombreEmpresa"
                            [ngClass]="{'is-invalid': submitPresupuesto && !isParticular && formCliente.controls['nombreEmpresa'].errors}"
                            class="form-control">
                    </div>
    
                    <div class="col-sm-3" *ngIf="!isParticular">
                        <label class="crear-editar-form requerido">CIF</label>
                        <input type="text" formControlName="cif"
                            [ngClass]="{'is-invalid': submitPresupuesto && !isParticular && formCliente.controls['cif'].errors}"
                            class="form-control">
                    </div>
    
                </div>
            </div>
    
            <div class="rowForm" *ngIf="isParticular == true">
                <div class="row">
                    <div class="col-sm-4">
                        <label class="crear-editar-form requerido">Fecha de nacimiento</label>
                        <input type="date" formControlName="fechaNacimiendo"
                            [ngClass]="{'is-invalid': submitPresupuesto && formCliente.controls['fechaNacimiendo'].errors}"
                            class="form-control">
                    </div>
    
                    <div class="col-sm-4">
                        <label class="crear-editar-form requerido">DNI</label>
                        <input type="text" formControlName="dni"
                            [ngClass]="{'is-invalid': submitPresupuesto && formCliente.controls['dni'].errors}"
                            class="form-control">
                    </div>
    
                    <div class="col-sm-4">
                        <label class="crear-editar-form requerido">Fecha validez DNI</label>
                        <input type="date" formControlName="validezDni"
                            [ngClass]="{'is-invalid': submitPresupuesto && formCliente.controls['validezDni'].errors}"
                            class="form-control">
                    </div>
    
                </div>
            </div>
            <div class="rowForm">
                <div class="row">
                    <div class="col-sm-6">
                        <label class="crear-editar-form requerido">Móvil</label>
                        <input type="text" formControlName="telefono" mask="0*" maxlength="9"
                            [ngClass]="{'is-invalid': submitPresupuesto && formCliente.controls['telefono'].errors}"
                            class="form-control ">
                    </div>
    
                    <div class="col-sm-6">
                        <label class="crear-editar-form requerido">Correo electrónico</label>
                        <input type="text" formControlName="email"
                            [ngClass]="{'is-invalid': submitPresupuesto && formCliente.controls['email'].errors}"
                            class="form-control">
                    </div>
    
                </div>
            </div>
    </div>
    <div class="formTitle">
        <label class="formTitleText">Dirección</label>
    </div>
    <div class="formGroup bgLightGreen">
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Tipo vía</label>
                    <select formControlName="tipoVia" class="form-control" (change)="onChangeTipoVia($event)"
                        [ngClass]="{'is-invalid': submitPresupuesto && this.formCliente.controls['tipoVia'].errors}">
                        <option *ngFor="let vias of tipoVias index as i" [value]="i">
                            {{ vias.tipovianormalizado }}
                        </option>
                    </select>
                </div>
    
    
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Nombre vía</label>
                    <input type="text" formControlName="nombreVia"
                        [ngClass]="{'is-invalid': submitPresupuesto && formCliente.controls['nombreVia'].errors}"
                        class="form-control">
                </div>
    
                <div class="col-sm-3">
                    <label class="crear-editar-form">Nº</label>
                    <input type="text" formControlName="numeroVia" mask="0*" class="form-control">
                </div>
    
                <div class="col-sm-3">
                    <label class="crear-editar-form">Bloque</label>
                    <input type="text" formControlName="bloque" class="form-control">
                </div>
    
            </div>
        </div>
    
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-3">
                    <label class="crear-editar-form">Escalera</label>
                    <input type="text" formControlName="escalera" class="form-control">
                </div>
    
                <div class="col-sm-3">
                    <label class="crear-editar-form">Planta</label>
                    <input type="text" formControlName="planta" mask="0*" class="form-control">
                </div>
    
                <div class="col-sm-3">
                    <label class="crear-editar-form">Puerta</label>
                    <input type="text" formControlName="puerta" class="form-control">
                </div>
    
    
                <div class="col-sm-3">
                    <label class="crear-editar-form">Km</label>
                    <input type="text" formControlName="km" mask="0*" class="form-control">
                </div>
    
            </div>
        </div>
    
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Comunidad autónoma</label>
                    <select formControlName="comunidadAutonoma" class="form-control" (change)="onChangeComunidad($event)"
                        [ngClass]="{'is-invalid': submitPresupuesto && this.formCliente.controls['comunidadAutonoma'].errors}">
                        <option *ngFor="let ccaa of comunidades" [value]="ccaa.id">
                            {{ ccaa.nombre }}
                        </option>
                    </select>
                </div>
    
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Provincia</label>
                    <select formControlName="provincia" class="form-control" (change)="onChangeProvincia($event)"
                        [ngClass]="{'is-invalid': submitPresupuesto && this.formCliente.controls['provincia'].errors}">
                        <option *ngFor="let provincia of provincias index as i" [value]="i">
                            {{ provincia.nombre }}
                        </option>
                    </select>
                </div>
    
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Municipio</label>
                    <select formControlName="localidad" class="form-control" (change)="onChangeMunicipio($event)"
                        [ngClass]="{'is-invalid': submitPresupuesto && this.formCliente.controls['localidad'].errors}">
                        <option *ngFor="let municipio of municipios" [value]="municipio.nombre">
                            {{ municipio.nombre }}
                        </option>
                    </select>
                </div>
    
    
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Código postal</label>
                    <input type="text" formControlName="codigoPostal" mask="0*" maxlength="5"
                        [ngClass]="{'is-invalid': submitPresupuesto && this.formCliente.controls['codigoPostal'].errors}"
                        class="form-control">
                </div>
    
            </div>
        </div>
    </div>
</form>

<div class="formTitle">
    <label class="formTitleText">Presupuesto</label>
</div>

<form [formGroup]="formPresupuesto" class="ml-4 mr-4">

    <div>
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-4">
                    <label class="crear-editar-form">Nº presupuesto</label>
                    <input type="text" formControlName="codPresupuesto" readonly
                        [ngClass]="{'is-invalid': submitPresupuesto && formPresupuesto.controls['codPresupuesto'].errors}"
                        class="form-control mt-2">
                </div>

                <div class="col-sm-4">
                    <label class="crear-editar-form requerido">Fecha de emisión</label>
                    <input type="date" class="form-control mt-2" formControlName="fecha_emision"
                        [ngClass]="{'is-invalid': submitPresupuesto && formPresupuesto.controls['fecha_emision'].errors}"
                        required pattern="\d{4}-\d{2}-\d{2}">
                </div>

                <div class="col-sm-4">
                    <label class="crear-editar-form requerido">Fecha de vencimiento</label>
                    <input type="date" class="form-control mt-2" formControlName="fecha_vencimiento"
                        [ngClass]="{'is-invalid': submitPresupuesto && formPresupuesto.controls['fecha_vencimiento'].errors}"
                        required pattern="\d{4}-\d{2}-\d{2}">
                </div>

            </div>
        </div>


        <div class="rowForm">
            <div class="row">
                <div class="col-sm-12">
                    <label class="crear-editar-form">Notas</label>
                    <textarea type="text" formControlName="notas" placeholder="Notas (Máximo 255 caracteres)"
                        maxlength="255" cols="1" rows="2" class="form-control "></textarea>
                </div>

            </div>
        </div>

    </div>

    <div formArrayName="detallesPresupuesto" class="arrayPresupuestos">

        <div *ngFor="let presupuesto of detallesPresupuesto?.controls; index as i" [formGroupName]="i" class="rowForm">
            <div class="row" *ngIf="!(listaInfoConceptos[i] && listaInfoConceptos[i].conceptoSelected === true && listaInfoConceptos[i].mainConcepto === false )">
                <div class="col-sm-5">
                    <label class="crear-editar-form requerido">Concepto</label>
                    <div class="stacked-controls" >
                        <input class="form-control" type="text" id="datahere" formControlName="concepto" [readOnly]="listaInfoConceptos[i] && listaInfoConceptos[i].conceptoSelected === true"/>
                        <select class="form-control" id="selectoption" (change)="setConceptos(i, $event)" [disabled]="listaInfoConceptos[i] && listaInfoConceptos[i].conceptoSelected === true && listaInfoConceptos[i].mainConcepto === false">
                            <option>Concepto genérico</option>
                            <option *ngFor="let presupuestoInfo of presupuestoInfoList index as j" [value]="j">
                                {{ presupuestoInfo.tipoTramite.nombre }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-2">
                   <label class="crear-editar-form requerido">Precio</label>
                    <input *ngIf="listaInfoConceptos[i] && listaInfoConceptos[i].conceptoSelected === false" type="text" (ngModelChange)="calcularCantidadNeta(i)" mask="0*.00"
                        formControlName="baseImponible"
                        [ngClass]="{'is-invalid': submitPresupuesto && detallesPresupuesto.controls[i].get('baseImponible').errors}"
                        placeholder="0.00" class="form-control">
                    <input *ngIf="listaInfoConceptos[i] && listaInfoConceptos[i].conceptoSelected === true" type="text" (ngModelChange)="calculoConceptoSeleccionado(i)" mask="0*.00"
                        formControlName="baseImponible"
                        [ngClass]="{'is-invalid': submitPresupuesto && detallesPresupuesto.controls[i].get('baseImponible').errors}"
                        placeholder="0.00" class="form-control">
                </div>
                <div class="col-sm-2">
                    <label *ngIf="!(listaInfoConceptos[i] && listaInfoConceptos[i].conceptoSelected === true && listaInfoConceptos[i].iva == 0)" class="crear-editar-form">Tipo de IVA</label>
                    <input *ngIf="listaInfoConceptos[i] && listaInfoConceptos[i].conceptoSelected === false" type="text" mask="0*" (ngModelChange)="calcularCantidadNeta(i)" formControlName="tipoIva"
                        placeholder="0" class="form-control icon-percent tipos">
                    <input *ngIf="listaInfoConceptos[i] && listaInfoConceptos[i].conceptoSelected === true && listaInfoConceptos[i].iva != 0" type="text" mask="0*" (ngModelChange)="calculoConceptoSeleccionado(i)" formControlName="tipoIva"
                    placeholder="0" class="form-control icon-percent tipos">
                </div>

                <div class="col-sm-3">
                    <label class="crear-editar-form">Total</label>
                    <input type="text" formControlName="total"
                        [(ngModel)]="this.formPresupuesto.controls['detallesPresupuesto'].value[i].total"
                        class="form-control" readonly>
                </div>

            </div>

        </div>
        <div class="col-sm-12 pt-4">
            <div class="form-group">
                <div class="row">
                    <button type="button" (click)="addPresupuesto()"
                        class="btn btn-primary marginButton  d-flex justify-content-start">
                        <mat-icon class="align-middle   mr-4">add</mat-icon><label class="align-middle">Añadir
                            fila</label>
                    </button>
                    <button type="button" (click)="deleteDetalle()"
                        class="btn btn-outline-primary  d-flex justify-content-start ml-3 ">
                        <mat-icon class="align-middle  mr-4">delete_outline</mat-icon><label
                            class="align-middle">Eliminar fila</label>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-sm-12 pt-2">
            <div class="form-group">
                <div class="d-grid gap-2 d-md-flex justify-content-md-end row">
                    <div class="col-sm-4 col-xl-3 row pr-0 pl-0 mr-0 ml-0">
                        <div class="col-sm-12 pl-0 pr-0 row mr-0 ml-0">
                            <p class="col-sm-6 col-xl-6 pr-2 pl-2">Subtotal: </p>
                            <p class="col-sm-5 col-xl-6 pr-0 pl-2 text-right">{{subtotal}}€</p>
                        </div>
                        <div class="col-sm-12 pl-0 pr-0 row mr-0 ml-0">
                            <p class="col-sm-6 col-xl-6 pr-2 pl-2">IVA: </p>
                            <p class="col-sm-5 col-xl-6 pr-0 pl-2 text-right">{{iva}}€</p>
                        </div>
                        <div class="col-sm-12 pl-0 pr-0 row mr-0 ml-0">
                            <p class="col-sm-6 col-xl-6 pr-2 pl-2"><b>Cantidad total: </b></p>
                            <p class="col-sm-5 col-xl-6 pr-0 pl-2 text-right"><b>{{cantidad_total}}€</b></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="row w-100 mb-4 d-flex justify-content-end ml-0 mr-0">
            <button *ngIf="mostrarVincular  && !inTramite" type="button"
                class="btn btn-outline-primary mt-3  col-sm-2 buttons-end" (click)="dialog.showModal()">Asociar
                trámite</button>
            <dialog #dialog>
                <label class="d-flex justify-content-start pl-1  pt-2">Selecciona un trámite </label>
                <div class="d-flex justify-content-start pl-1 pt-1">
                    <select class="form-control" (change)="onChangeTramite($event)">
                        <option *ngFor="let tramite of tramitesSinAsociar index as i" [value]="i">
                            #ID:{{tramite.numeroTramite}}, Tipo trámite:{{tramite.tipoTramite.nombre}}
                        </option>
                    </select>

                </div>
                <div class="d-flex justify-content-start mt-1">
                    <button type="button" class="btn btn-primary mt-2 ml-1 "
                        (click)="vincularTramiteAPresupuesto()">Asociar</button>

                </div>
                <div class="row w-100 ml-1">
                    <label class=" mt-4">Nuevo trámite asociado:
                        <select class="form-control mt-1" (change)="onChangeTramiteAAsociar($event)">
                            <option *ngFor="let tramite of listaTramites" [value]="tramite.id">
                                {{ tramite.nombre }}
                            </option>
                        </select></label>
                </div>
                <div class="w-100 d-flex justify-content-start mb-2">
                    <button type="button" class="btn btn-primary mt-2 ml-1 " (click)="mostrarTramiteAutocompletado()">Ir
                        a trámite</button>
                </div>
                <div class="w-100 d-flex justify-content-end mt-1">
                    <button (click)="dialog.close()" class="btn btn-outline-primary   mt-2"
                        type="submit">Cancelar</button>
                </div>
            </dialog>

            <button *ngIf="!inTramite && presupuestoRecibido && presupuestoRecibido.estado.nombre != 'PAGADO'" type="button" class="btn btn-outline-primary mt-3  preview col-sm-2  ml-2 mr-2 buttons-end"
                (click)="goPago()">
                <mat-icon class="align-middle mr-2">
                    credit_card
                </mat-icon><label class="align-middle">Pagar</label>
            </button>

            <button type="button" class="btn btn-outline-primary mt-3  preview col-sm-2  ml-2 mr-2 buttons-end"
                (click)="generatePreview()">
                <mat-icon class="align-middle mr-2">remove_red_eye</mat-icon><label
                    class="align-middle">Vista previa</label>
            </button>

            <button type="button" (click)="onSubmitPresupuesto()"
                class="btn btn-primary mt-3 accept col-sm-2 buttons-end mr-0" [disabled]="uploadPresupuesto">
                <mat-icon *ngIf="!uploadPresupuesto" class="align-middle mr-2">check</mat-icon>
                <div class="spinner-border spinner-border-sm" *ngIf="uploadPresupuesto"></div>
                <label class="align-middle"> Aceptar</label>
            </button>
        </div>

    </div>

    <div class="d-flex justify-content-start">
        <b *ngIf="!mostrarVincular" class="subrayado" (click)="irATramiteVinculado()">Ir a Trámite:
            #{{this.presupuestoRecibido.id}} ({{this.presupuestoRecibido.tramite.nombre}} Asociada)</b>
    </div>
    <div class=" bgLightGreen p-3">
        <div class="firstFormTitle">
            <label class="formTitleText">Información de ingreso</label>
        </div>
        <div class="paddedFormGroup">
            IBAN: ES11 2100 6107 2702 0009 4833 
        </div>
    </div>
</form>