import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/_services/hencok/cliente.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { environment } from 'src/environments/environment';
import { GestionClientesComponent } from '../gestion-clientes/gestion-clientes.component';

@Component({
  selector: 'app-formulario-nuevo-cliente',
  templateUrl: './formulario-nuevo-cliente.component.html',
  styleUrls: ['./formulario-nuevo-cliente.component.css']
})
export class FormularioNuevoClienteComponent {

  @Output() newItemEvent = new EventEmitter<any>();

  isLoggedIn = false;
  userLogged: any;

  submitted: boolean = false;
  
  isSuccessful = false;

  environment = environment;

  constructor(
    private tokenStorageService: TokenStorageService,
    private clienteService : ClienteService,
    private _notificationService: NotificationService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.userLogged = this.tokenStorageService.getUser();
  }

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl("", Validators.required),
    nombre: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl("", Validators.required),
    domicilio: new UntypedFormControl("", Validators.required),
    numero_direccion: new UntypedFormControl("", Validators.required),
    codigo_postal: new UntypedFormControl("", Validators.required),
    localidad: new UntypedFormControl("", Validators.required),
  });

  closeSidebar(){
    this.newItemEvent.emit(false)
  }

  async onSubmit(): Promise<any> {
    this.submitted = true
    this.form.addControl('autonomo_id', new UntypedFormControl);
    this.form.controls['autonomo_id'].setValue(this.userLogged.id);
    
    if (!this.form.valid) {
      this._notificationService.warning('Informacion', 'Rellene todos los campos por favor', 2000);
    } else {
      this.clienteService.save(this.form.value).subscribe({
        next: (data) => {
          this.submitted = false;
          this._notificationService.success('Hecho', "Cliente guardado con exito", 2000);
          this.newItemEvent.emit(true);
        },
        error: (err) => {
          //console.log(err);
        }
      })
    }
  }

}
