import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TipoPersona } from '../model/tipopersona';
import { Provincia } from '../model/provincia';
import { Municipio } from '../model/municipio';
import { Documento } from '../model/documento';
import { Via } from '../model/via';
import { Pais } from '../model/pais';

import { environment } from 'src/environments/environment';
import { RolUsuario } from '../model/rolusuario';

import { TokenStorageService } from './token-storage.service';
import { Procedimiento } from '../model/procedimiento';
import { UsuarioRecuperar } from '../model/usuariorecuperar';
import { EstadoCivil } from '../model/estadocivil';
import { MatPaginatorIntl } from '@angular/material/paginator';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};



@Injectable({
  providedIn: 'root'
})


export class ServicioGeneral {
  /* getCardTramite() {
     throw new Error('Method not implemented.');
   }*/

  API_URL: string = environment.API_URL;

  //API_URL = 'http://localhost:7090';
  //API_URL = 'https://oficinavirtual.simplegob.com/portal';



  constructor(
    private http: HttpClient,
    private tokenservice: TokenStorageService,
    private paginatorIntl: MatPaginatorIntl,
  ) {
    this.translatePaginator()
  }

  /*
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
*/

  getProcedimiento(): Observable<Procedimiento> {

    let headers = new HttpHeaders().set('access-control-allow-origin', this.API_URL)

    return this.http
      .get<Procedimiento>(`${this.API_URL}/notifica/procedimientos`)
      .pipe(retry(1), catchError(this.handleError));

  }



  getTipoPersona(): Observable<TipoPersona> {
    return this.http
      .get<TipoPersona>(`${this.API_URL}/tipopersona/list`)
      .pipe(retry(1), catchError(this.handleError));


  }

  getProvincias(): Observable<Provincia> {
    return this.http
      .get<Provincia>(`${this.API_URL}/provincia/list`)
      .pipe(retry(1), catchError(this.handleError));

  }

  getComunidades(): Observable<Provincia> {
    return this.http
      .get<Provincia>(`${this.API_URL}/ccaa/list`)
      .pipe(retry(1), catchError(this.handleError));

  }

  getMunicipios(): Observable<Municipio> {
    return this.http
      .get<Municipio>(`${this.API_URL}/municipio/list`)
      .pipe(retry(1), catchError(this.handleError));

  }

  getNombreMunicipio(provincia: string, municipio: string): Observable<Municipio> {
    return this.http
      .get<Municipio>(`${this.API_URL}/municipio/get/` + provincia + `/` + municipio)
      .pipe(retry(1), catchError(this.handleError));

  }

  getTipoDocumento(): Observable<Documento> {
    return this.http
      .get<Documento>(`${this.API_URL}/tipodocumento/list`)
      .pipe(retry(1), catchError(this.handleError));

  }

  getTipoVias(): Observable<Via> {
    return this.http
      .get<Via>(`${this.API_URL}/tipovia/list`)
      .pipe(retry(1), catchError(this.handleError));

  }
  getPais(): Observable<Pais> {
    return this.http
      .get<Pais>(`${this.API_URL}/pais/list`)
      .pipe(retry(1), catchError(this.handleError));
  }


  getRolUsuario(): Observable<RolUsuario> {

    let headers = new HttpHeaders({
      Authorization: `Basic ${this.tokenservice.getUser()}`

    })



    return this.http
      .get<RolUsuario>(`${this.API_URL}/user/${this.tokenservice.getId()}/list`)
      .pipe(retry(1), catchError(this.handleError));
  }


  getUserList(): Observable<any> {

    return this.http
      .get<any>(`${this.API_URL}/user/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getUsersRoleOnePaginate(request): Observable<any> {
    const params = request;

    return this.http
      .get<any>(`${this.API_URL}/user/getUserRoleOnePaginate`, { params })

  }

  getUsersRoleOne(): Observable<any> {
    return this.http
      .get<any>(`${this.API_URL}/user/getUserRoleOne`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getClientId(id: number, id_user: any): Observable<any> {
    const url = `${this.API_URL}/cliente/get/${id}`;
    return this.http.post<any>(url,
      { "idusu": id_user },
      {
        headers: {
          "accessToken": this.tokenservice.getToken()
        }
      }
    )

  }
  getUserId(id: number): Observable<any> {

    const url = `${this.API_URL}/user/get/${id}`;
    return this.http.get<any>(url)
      .pipe(retry(1), catchError(this.handleError));

  }

  getIbanByUser(id: number): Observable<any> {

    const url = `${this.API_URL}/iban/buscarPorUsuario/${id}`;
    return this.http.get<any>(url)
      .pipe(retry(1), catchError(this.handleError));

  }

  getMenuLateralList(): Observable<any> {

    return this.http
      .get<any>(`${this.API_URL}/menu/list`)
      .pipe(retry(1), catchError(this.handleError));
  }


  getMenuLateralListByRol(): Observable<any> {
    let rol = this.tokenservice.getRol()
    return this.http
      .get<any>(`${this.API_URL}/menu/getRol/${rol}`)
      .pipe(retry(1), catchError(this.handleError));
  }
  getObservacionesUsuario(id): Observable<any> {

    return this.http
      .get<any>(`${this.API_URL}/observaciones/list/${id}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMenuPadreHijo(id: number): Observable<any> {

    return this.http
      .get<any>(`${this.API_URL}/menu/getChildren/${id}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getMenuParent(): Observable<any> {

    return this.http
      .get<any>(`${this.API_URL}/menu/getParentList`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getParents(): Observable<any> {

    return this.http
      .get<any>(`${this.API_URL}/menu/getParent`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getEstadoCivil(): Observable<EstadoCivil> {
    return this.http
      .get<EstadoCivil>(`${this.API_URL}/estado_civil/list`)
      .pipe(retry(1), catchError(this.handleError));
  }

  tieneRol(id: number): Observable<any> {

    return this.http
      .get<any>(`${this.API_URL}/user/usuario/${id}/roles`)
      .pipe(retry(1), catchError(this.handleError));
  }


  exportarCSV(identificador: string, id: number): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      responseType: 'blob',
      Accept: 'application/pdf',
      observe: 'response',
    }).set('identificador', identificador)

    let API_URL = `${this.API_URL}/exportar/csv/${id}`;
    return this.http.get(API_URL, { headers: headers, responseType: 'blob' })

  }


  forgotPasswordData(data: UsuarioRecuperar): Observable<any> {
    let API_URL = `${this.API_URL}/recovery/recover`;
    return this.http.post(API_URL, data)

  }

  forgotPassword(email: string): Observable<any> {

    let API_URL = `${this.API_URL}/recovery/recover`;
    return this.http.post(`${API_URL}`, { email });
  }


  resetPassword(identificador: string, email: string, newPassword: string, tipo: string): Observable<any> {

    return this.http.post(`${this.API_URL}/recovery/recovering`, { identificador, email, newPassword, tipo}, httpOptions);
  }

  findRecoverUserByEmail(email) {
    return this.http.post(`${this.API_URL}/recovery/findIdentifierByEmail`, { 'email': email }, httpOptions);
  }

  translatePaginator() {
    this.paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
  }

  getAllRoles() {
    return this.http.get(`${this.API_URL}/rol/list`);
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }
  downloadJustificanteB64(id: number): Observable<any> {
    let API_URL = `${this.API_URL}/nextcloud/downloadB64/${id}`;
    return this.http.get(API_URL);
  }
}
