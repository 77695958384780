import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DetallesFactura } from 'src/app/model/hencok/detalleFactura';
import { Documento } from 'src/app/model/hencok/documento';
import { Factura } from 'src/app/model/hencok/factura';
import { GastoIngreso } from 'src/app/model/hencok/gasto_ingreso';
import { environment } from 'src/environments/environment.hencok';

@Injectable({
  providedIn: 'root'
})
export class FacturaService {

  protected resourceUrl = `${environment.API_URL}/factura`;
  protected API_URL = environment.API_URL;


  constructor(protected http: HttpClient) { }

  create(factura: Factura, detalleFactura: DetallesFactura[], admin:boolean){    
    return this.http.post(`${this.resourceUrl}/save`, {'factura': factura, 'detalles': detalleFactura, 'admin':admin});
  }

  createComisiones(factura: Factura, detalleFactura: DetallesFactura[]){    
    return this.http.post(`${this.resourceUrl}/saveComisiones`, {'factura': factura, 'detalles': detalleFactura});
  }

  update(factura: Factura, detalles: DetallesFactura[], admin:boolean){    
   /*  console.log("FACTURA ->",factura);
    console.log("DETALLE ->",detalles);
    console.log(this.resourceUrl); */
    
    return this.http.post(`${this.resourceUrl}/update`, {factura, detalles, admin});
  }

  updateState(id: number, estado:string){    
    /*  console.log("FACTURA ->",factura);
     console.log("DETALLE ->",detalles);
     console.log(this.resourceUrl); */

     const datos = {
      id_factura: id,
      estado: estado, 
    };
     
     return this.http.post(`${this.resourceUrl}/updateState`, datos);
   }

  createWithIngreso(factura: Factura, detalleFactura: DetallesFactura[], ingreso:GastoIngreso, admin:boolean, comisiones: boolean){    
    return this.http.post(`${this.resourceUrl}/save`, {'factura': factura, 'detalles': detalleFactura, 'admin':admin,'ingreso':ingreso, 'comisiones': comisiones});
  }

  updateWithIngreso(factura: Factura, detalles: DetallesFactura[], ingreso:GastoIngreso, admin:boolean){    
   /*  console.log("FACTURA ->",factura);
    console.log("DETALLE ->",detalles);
    console.log(this.resourceUrl); */
    
    return this.http.post(`${this.resourceUrl}/update`, {factura, detalles, admin, ingreso});
  }

  cumplimentar(factura: Factura, detalles: DetallesFactura[], ingreso: GastoIngreso){    
/*     console.log("FACTURA ->",factura);
    console.log("DETALLE ->",detalles);
    console.log(this.resourceUrl); */
    
    return this.http.post(`${this.resourceUrl}/completeFactura`, {factura, detalles, ingreso});
  }

  uploadInvoice(base64: string, nombre: string, mime: string, id: number){
    return this.http.post(`${this.resourceUrl}/uploadInvoice/${id}`, {base64, nombre, mime});
  }

  deleteFactura(id: number, admin:boolean){
    return this.http.post(`${this.resourceUrl}/delete`, {id,admin});
  }

  getFacturaListByUsuario(id : number, request){
    const params = request;
    
    return this.http
      .get(`${this.resourceUrl}/getByIdUsuario/${id}`, {params});

  }

  getFacturaListByDocumento(documento : string, request){
    const params = request;
    
    return this.http
      .get(`${this.resourceUrl}/getFacturaByDocumento/${documento}`, {params});

  }

  getFacturaList(request){
    const params = request;
    return this.http
      .get(`${this.resourceUrl}/listPaginate`, {params});
  }


  getFacturaListAllByID(id) {
    return this.http.get(`${this.resourceUrl}/listbyid/${id}`)
  }
  getFacturaListAllByIDCliente(id) {
    return this.http.get(`${this.resourceUrl}/listbyidCliente/${id}`)
  }

  getFacturaFullListByUsuario(id: number){
    return this.http
      .get(`${this.resourceUrl}/getByUsuario/${id}`);
  }

  getFullFacturasByDocumento(id: number){
    return this.http
      .get(`${this.resourceUrl}/getFullFacturasByDocumento/${id}`);
  }

  downloadJustificanteB64(id: number): Observable<any> {
    let API_URL = `${this.API_URL}/nextcloud/downloadB64/${id}`;
    return this.http.get(API_URL);
  }

  getTotalFacturado(id: number): Observable<any> {
    let API_URL = `${this.resourceUrl}/calcularTotalFactura/${id}`;
    return this.http.get(API_URL);
  }

  getByFacturaId(idFactura: number){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": '*',
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
      "Access-Control-Max-Age": "300"
    })
    
    let API_URL = `${this.resourceUrl}/getByFacturaId/${idFactura}`;
    return this.http.get(API_URL, {headers: headers});
  }
  getFacturaFilter(request: any){
    return this.http.post(`${this.resourceUrl}/filter`, request);
  }
}

