import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { TramitesService } from 'src/app/_services/autoescuela/tramites.service';
import { FacturaService } from 'src/app/_services/hencok/factura.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Tramite } from 'src/app/model/autoescuela/tramite';
import { Factura } from 'src/app/model/hencok/factura';

@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.css']
})
export class EstadisticasComponent {
  rol = 0;
  totalElements = 0;
  page = "0";
  size = "100000000";
  filters: any[] = [];
  sorts: any[] = [];
  tipoTramites: any;
  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
    type: ''
  };
  firstFilter:boolean=true;

  tramites: any [] = [];
  infoTramites: any [] = [];
  numeroTramites: number = 0;
  totalPagado: number = 0;
  totalPresupuestado: number = 0;
  totalComisiones: number = 0;
  tramiteMasPresupuestado: any = {tipoTramite: "", total: 0};
  tramiteMasComun: any = {tipoTramite: "", total: 0};
  groupedTramitesComisiones: any[] = [];
  dateFrom: Date;
  dateTo: Date;
  user: any;
  provincia: any;
  ciudad: any;

  provincias: any [] = [];
  municipios: any [] = [];

  sortVariable = {
    direction: undefined,
    key: undefined
  }



  constructor(
    private tramiteService: TramitesService, 
    private tokenService: TokenStorageService,
    private serviceGeneral: ServicioGeneral,
    private facturaService: FacturaService,
    private notificationService: NotificationsService
    ){

  }


  ngOnInit(){
    this.rol = this.tokenService.getRol();
    this.user = this.tokenService.getUser();
    this.initializeFilters();
    this.getTipoTramites();
    this.getTramites();
    this.getProvincias();
  }

  getTipoTramites() {
    this.tramiteService.getPreciosTramiteList(undefined).subscribe((data) => {
      console.log(data)
        this.tipoTramites = data;
      })
  }

  startInfoTramites(){
    this.groupedTramitesComisiones=[];
    this.infoTramites = [];
    if(this.rol==5){
      for(let tipoTramite of this.tipoTramites){
        this.infoTramites.push({nombre: tipoTramite.tipoTramite.nombre, totalPresupuestado: 0, totalPagado: 0, numeroTramites: 0, tramitesPresupuestados: 0, tramitesPagados: 0, comisiones: 0, comisionesEquipo: 0})
        this.groupedTramitesComisiones.push([]);
      }
    }
    else{
      for(let tipoTramite of this.tipoTramites){
        this.infoTramites.push({nombre: tipoTramite.tipoTramite.nombre, totalPresupuestado: 0, totalPagado: 0, numeroTramites: 0, tramitesPresupuestados: 0, tramitesPagados: 0, comisiones: 0})
        this.groupedTramitesComisiones.push([]);
      }
    }
   
    console.log(this.infoTramites)
  }

  getProvincias() {
    return this.serviceGeneral.getProvincias().subscribe((data) => {
      this.provincias = data['Provincia'];
    });
  }

  initializeFilters(){
    this.filters = [];
    if (this.rol == 5) {
      console.log(this.rol)
      let type:any = ['usuario.idPadre.id', 'usuario.idPadre.idPadre.id']
      let composeKey:any = "";
        type.forEach((element) => {
          composeKey += element;
        });
        console.log(composeKey);
      let filterUser:any = {
        key: composeKey,
        keys: type,
        operator: "EQUAL_OR",
        field_type: "STRING",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterUser);
      type = 'comisionExtraida'
      filterUser = {
        key: type,
        operator: "EQUAL",
        field_type: "BOOLEAN",
        value: 'false',
      };
      this.filters.push(filterUser);
    }
    if (this.rol == 6) {
      let type = 'usuario.idPadre.id'
      let filterUser:any = {
        key: type,
        operator: "EQUAL",
        field_type: "STRING",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterUser);
      type = 'comisionExtraida'
      filterUser = {
        key: type,
        operator: "EQUAL",
        field_type: "BOOLEAN",
        value: 'false',
      };
      this.filters.push(filterUser);
    }
  }
  
  getTramites() {
    console.log()
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
      type: "Comisiones"
    };
    console.log(this.request)
    if (this.rol == 3 || this.rol == 99) {
      let filterBorr = {
        key: "usuario.id",
        operator: "CUSTOM_BORRADO",
        field_type: "LONG",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterBorr);
    }
    if (this.rol == 4) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
      type: "Comisiones"
    };
    this.tramiteService
      .getTramitesRealizadosFilter(this.request, this.sortVariable)
      .subscribe((data: any) => {
        this.tramites = data.pageList;
        console.log(data);

        this.totalElements = data.nrOfElements;
        this.startInfoTramites();
        this.sumAllPresupuestos();
      });
  }

  checkDates(){
    let dates = []
    if(!this.dateFrom){
      dates.push(new Date(1600000000).toISOString().split('T')[0])
    }
    else{
      dates.push(this.dateFrom)
    }
    if(!this.dateTo){
      dates.push(new Date().toISOString().split('T')[0])
    }
    else{
      dates.push(this.dateTo)
    }
    console.log(dates)
    console.log(this.dateFrom)
    console.log(this.dateTo)
    this.doFilter(dates, "fechaCreacion", "BETWEEN_DATES", "DATE");
  }

  filterProvincias(event, type, operator, field_type){
    let value = event.target.value;
    console.log();
    this.getMunicipiosByID(value);
    this.filterEvent(event, type, operator, field_type);
  }

  filterEvent(event, type, operator, field_type){
    let value = event.target.value;
    this.doFilter(value, type, operator, field_type);
  }

  doFilter(value, type, operator, field_type) {
    let isArray = type instanceof Array;
    let isValueArray = value instanceof Array;
    let valueFrom
    let valueTo
    //console.log(isArray);

    if (isValueArray || value != "") {
      if (field_type == "DATE" && !isValueArray) {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      else if(field_type == "DATE" && isValueArray){
        valueFrom = formatDate(value[0], "dd-MM-yyyy", "en-US");
        valueTo = formatDate(value[1], "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        if(isValueArray){
          index = this.filters.findIndex((item) => item.key == type);
          newFilter = {
            key: type,
            operator: operator,
            field_type: field_type,
            value: valueFrom,
            value_to: valueTo,
          };
        }
        else{
          index = this.filters.findIndex((item) => item.key == type);
          newFilter = {
            key: type,
            operator: operator,
            field_type: field_type,
            value: value,
          };
        }
        console.log(newFilter)
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    this.page = "0";
    this.size = "100000000";
    //console.log("los filtros son", this.filters);
  }

  sumAllPresupuestos(){
  this.totalComisiones = 0;
  this.numeroTramites = 0;
  this.totalPresupuestado = 0;
  this.tramiteMasPresupuestado = {tipoTramite: "", total: 0};
  this.tramiteMasComun = {tipoTramite: "", total: 0};
  let tramitesComisiones = [];
    for(let tramite of this.tramites){
      for(let i = 0; i<this.tipoTramites.length; i++){
        tramitesComisiones = [];
        if(tramite.tipoTramite.id === this.tipoTramites[i].tipoTramite.id){
          if(tramite.presupuesto){
            this.infoTramites[i].totalPresupuestado = parseFloat((this.infoTramites[i].totalPresupuestado + tramite.presupuesto.total).toFixed(2));
            this.totalPresupuestado = parseFloat((this.totalPresupuestado + tramite.presupuesto.total).toFixed(2));
            
            this.infoTramites[i].tramitesPresupuestados++
            if(tramite.presupuesto.estado.nombre == 'PAGADO'){
              this.infoTramites[i].tramitesPagados++
              this.totalPagado = parseFloat((this.totalPagado + tramite.presupuesto.total).toFixed(2));
              this.infoTramites[i].totalPagado = parseFloat((this.infoTramites[i].totalPagado + tramite.presupuesto.total).toFixed(2));
              if(this.rol == 5){
                this.groupedTramitesComisiones[i].push(tramite.id);
                this.infoTramites[i].comisiones = (parseFloat(this.infoTramites[i].comisiones) + (this.tipoTramites[i].honorariosGestoria * this.tipoTramites[i].comisiones1 / 100)).toFixed(2);
                this.infoTramites[i].comisionesEquipo = (parseFloat(this.infoTramites[i].comisionesEquipo) + this.tipoTramites[i].comisiones2).toFixed(2);
                this.totalComisiones = (Number)(this.totalComisiones + parseFloat((this.tipoTramites[i].honorariosGestoria * this.tipoTramites[i].comisiones1 / 100).toFixed(2)));
                this.totalComisiones.toFixed(2);
              }
              if(this.rol == 6 && this.tipoTramites[i].comisiones2){
                tramitesComisiones.push(tramite.id);
                this.infoTramites[i].comisiones = (parseFloat(this.infoTramites[i].comisiones) + this.tipoTramites[i].comisiones2).toFixed(2);
                this.totalComisiones = (Number)((this.totalComisiones + this.tipoTramites[i].comisiones2).toFixed(2));
                this.totalComisiones.toFixed(2);
              }
            }
            if(this.tramiteMasPresupuestado.total < this.infoTramites[i].totalPresupuestado){
              this.tramiteMasPresupuestado = {tipoTramite: this.tipoTramites[i], total: this.infoTramites[i].totalPresupuestado}
            }
          }
          this.infoTramites[i].numeroTramites++
          this.numeroTramites++
          if(this.tramiteMasComun.total < this.infoTramites[i].numeroTramites){
            this.tramiteMasComun = {tipoTramite: this.tipoTramites[i].tipoTramite, total: this.infoTramites[i].numeroTramites}
          }
          
          break;
        }
      }
    
      
    }
    console.log(this.tramiteMasPresupuestado, this.tramiteMasComun);
  }

  extractComission(){
     for(let i=0; i < this.groupedTramitesComisiones.length; i++){

      if(this.groupedTramitesComisiones[i].length>0){
        this.tramiteService.extractComission(this.tipoTramites[i].tipoTramite.id, this.groupedTramitesComisiones[i]).subscribe((data) => {
          if(this.groupedTramitesComisiones[i].length == i+1){
            
            this.getTramites();
          }
        })
      }
      if(this.groupedTramitesComisiones[i].length == i+1){
        this.getTramites();
      }
      
    }
      let factura: Factura =
      {fechaFactura : this.formatDate(new Date()),
      fechaVencimiento: this.formatDate(new Date().setDate(new Date().getDate() + 30)),
      importeIva:"0",
      subtotal:this.totalComisiones.toString(),
      cantidadTotal:this.totalComisiones.toString(),
      numeroFactura:"0",
      idCliente:this.user.id,
      email: this.user.email,
      numeroDocumento:this.user.numero_documento,
      nombre: this.user.nombre,
      domicilio:"Calle calle 1",
      codigoPostal:"28260",
      localidad:"Madrid",
      tipoGasto:{id:2,nombre:"TARJETA"},
      rectificada:true,
      };

      let detalles = [];
      let detalle = 
      {concepto:"Comisiones",
      cantidad:1,
      precio: this.totalComisiones,
      descuento:"",
      cantidadNeta:this.totalComisiones,
      tipoIva:"0"}
      detalles.push(detalle);
      this.facturaService.createComisiones(factura, detalles).subscribe((data) => {
        const byteArray = new Uint8Array(atob(data['documento']).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
        this.createExcel();
      })
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  openPDF(factura) {
    this.facturaService.downloadJustificanteB64(factura.documento.id_gestor_documental)
      .subscribe({
        next: (documento) => {
          const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        },
        error: (error) => {
          this.notificationService.error('Error', 'No se ha podido abrir el archivo', 2000);
        }
      })

  }

  getMunicipiosByID(id: string, first?) {
    this.municipios = [];
    this.serviceGeneral.getMunicipios().subscribe((data) => {
      data['Municipio'].forEach((municipio) => {
        if (municipio.municipioPK["provincia"] == id) {
          this.municipios.push(municipio);
        }
      });
    });
  }

  cleanFilters(){
    this.provincia=""
    this.ciudad=""
    this.dateFrom = new Date;
    this.dateTo = new Date;
    this.initializeFilters();
    this.getTramites();
  }

  generateComissionExcel(){
    this.filters = [];
    if (this.rol == 5) {
      let type:any = ['usuario.idPadre.id', 'usuario.idPadre.idPadre.id']
      let composeKey:any = "";
        type.forEach((element) => {
          composeKey += element;
        });
        console.log(composeKey);
      let filterUser:any = {
        key: composeKey,
        keys: type,
        operator: "EQUAL_OR",
        field_type: "STRING",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterUser);
      
    }
    let type = 'comisionExtraida'
    let filterUser = {
      key: type,
      operator: "EQUAL",
      field_type: "BOOLEAN",
      value: 'true',
    };
    this.filters.push(filterUser);

    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
      type: "Comisiones"
    };

    this.tramiteService
    .getTramitesRealizadosFilter(this.request, this.sortVariable)
    .subscribe((data: any) => {
      this.tramites = data.pageList;

      this.totalElements = data.nrOfElements;
      this.startInfoTramites();
      this.sumAllPresupuestos();
      console.log( this.infoTramites);
      this.createExcel();
    });
  }

  createExcel(){
    this.tramiteService.generateComissionExcel(this.rol, this.infoTramites).subscribe({
      next: (data: any) => {
        console.log(data)
        const byteCharacters = atob(data["document"]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'comisiones.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url); 
        this.initializeFilters();
        this.getTramites();
      }
        ,error:(error)=>{
          console.log(error)
        }
    });
  }

}
