import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterImpuesto'
})
export class FilterImpuestoPipe implements PipeTransform {



  transform(items: any[], filter: Object): any {

    if (!items || !filter) {
        return items;
    }
    
    return items.filter(contact => {      
     // console.log(contact)
      return contact.autonomo.nombre.toLocaleLowerCase().match(filter) ||
        contact.estado.toLocaleLowerCase().match(filter)||
        contact.concepto.toLocaleLowerCase().match(filter)||
        contact.id.match(filter)
        
        // || contact.telefono.toLocaleLowerCase().match(filter)
    });
  }
}
