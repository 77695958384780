import { Component, Input,Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { CalculadoraitpService } from 'src/app/_services/hencok/calculadoraItpservice';
import { HttpClient } from "@angular/common/http";
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/_services/notification.service';
import { VisualizarNotificacionesService } from 'src/app/_services/hencok/visualizar-notificaciones.service';
@Component({
  selector: 'app-calculadora-itp',
  templateUrl: './calculadora-itp.component.html',
  styleUrls: ['./calculadora-itp.component.css']
})
export class CalculadoraItpComponent {
  constructor(
    private tokenStorageService: TokenStorageService,
    private _servicioGeneral: ServicioGeneral,
    private http: HttpClient,
    public datepipe: DatePipe,
    private calculadoraitpService: CalculadoraitpService,
    private _notificationSvc: NotificationService,
    private notificacionessService: VisualizarNotificacionesService,

  ) { }

  
  
  form: UntypedFormGroup = new UntypedFormGroup({
    tipo_vehiculo: new UntypedFormControl("COCHE", Validators.required),
    comunidad_autonoma: new UntypedFormControl("", Validators.required),
    fecha_matriculacion: new UntypedFormControl("", Validators.required),
    historico: new UntypedFormControl(false),
    empadronado: new UntypedFormControl(false),
    marca: new UntypedFormControl(""),
    modelo: new UntypedFormControl(""),
    potencia_kW: new UntypedFormControl(""),
    cilindrada: new UntypedFormControl(""),
    combustible: new UntypedFormControl("", Validators.required),
  });

  @Input() vehiculo: any;
  
   @Output() newItemEvent = new EventEmitter<any>();
  @Input() comprador: any;
  @Input() vendedor: any;
  DatosRecividos=  new Map();
  TComunidades: any = [];
  TMarcas: any = [];
  TModelos: any = [];
  TCarburantes: any = [];
  register2 = false;
  environment = environment;
  isLoggedIn = false;
  userLogged: any;
  isMod: Boolean = false;
  submitted: boolean = false;
  API_URL: string = environment.API_URL;
  adviseError: boolean = false;
  typeError: boolean = false;
  mostrarFormulario:boolean = false;
  fechaAux: any;
  itpPresupuesto=undefined;
 

  ngOnInit(): void {
  
   this.TMarcas = [];
    this.TModelos = [];
    this.TCarburantes = [];
    
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    this.userLogged = this.tokenStorageService.getUser();

    if (this.tokenStorageService.getRol() == 5) {
      this.isMod = true;
    }

    this.register2 = false;
    this.getComunidades();
    this.getMarcas();
    if(this.vehiculo!=null){
      this.form.controls['fecha_matriculacion'].setValue(this.formatDate(this.vehiculo.fechaMatriculacion));
      this.form.controls['tipo_vehiculo'].setValue(this.vehiculo.tipoVehiculo.nombre);
      this.form.controls['combustible'].setValue(this.vehiculo.vehiculoMaestro.carburante.tipo);
      this.form.controls['marca'].setValue(this.vehiculo.vehiculoMaestro.marca);
      this.getModelos( this.vehiculo.vehiculoMaestro.marca)
      this.form.controls['modelo'].setValue(this.vehiculo.vehiculoMaestro.modelo);
      this.form.controls['comunidad_autonoma'].setValue(this.vendedor.comunidadAutonoma.id);
    }
   
  }

  getComunidades() {
    return this._servicioGeneral.getComunidades().subscribe((data) => {
      this.TComunidades = data['ComunidadAutonoma'];
    });
  }
  getMarcas() {
    return this.calculadoraitpService.listMarcas().subscribe((data) => {
      this.TMarcas=data
    }
    ,(error)=>{
    }
    )
  }
  getModelos( combustible: Event) {
    if(this.form.controls['tipo_vehiculo'].value=="MOTO"){
      if((Number)((combustible.target as HTMLInputElement).value) == 5){
        this.form.controls['cilindrada'].setValue("");
      }
      else{
        this.form.controls['potencia_kW'].setValue("");
      }
    }
    return this.calculadoraitpService.listModelosByMarcaAndComb(this.form.controls["marca"].value, (combustible.target as HTMLInputElement).value).subscribe((data) => {
      this.TModelos = data;
    });
  }
  getCarburantes() {
    return this.calculadoraitpService.listCarburantes().subscribe((data) => {
      this.TCarburantes = data;
    });
  }


  
  generatePresupuesto(mostrar){

    var attr1="";
    var attr2="";

    if( this.form.controls['tipo_vehiculo'].value=="COCHE"){
      this.form.controls['marca'].addValidators(Validators.required)
      this.form.controls['modelo'].addValidators(Validators.required)
      attr1=this.form.controls['marca'].value;
      attr2=this.form.controls['modelo'].value;
    }else{
      attr1=this.form.controls['potencia_kW'].value;
      attr2=this.form.controls['cilindrada'].value;
    }
    this.submitted=true;
    this.fechaAux=this.form.controls['fecha_matriculacion'].value

    

    if(this.form.valid){
    this.http.post(`${this.API_URL}/ipt/calcularIpt`,this.form.value,{responseType: 'text'}).subscribe({
      next: (data: any) => {
      
        const objetoJavaScript =JSON.parse(data);
        var toPresupuesto= new Map(Object.entries(objetoJavaScript));

        var descuento = ""
        if (toPresupuesto.get("descuento_empadronamiento"))  {
          descuento = "\nDescuento: " + toPresupuesto.get("descuento_empadronamiento")
        }

        this.itpPresupuesto = {concepto: "ITP.\nMarca: " + attr1 + ".\nModelo: " + attr2 + ".\nCombustible: " + this.form.controls["combustible"].value + ".\nFecha de matriculación: " + this.form.controls["fecha_matriculacion"].value + 
        ".\nComunidad: " + toPresupuesto.get("comunidad"), baseImponible: toPresupuesto.get("facturacion")}

        /* this.itpPresupuesto = {concepto: attr1 + " " + attr2 + "\nValoración en tablas: " + toPresupuesto.get("valoracón en tablas") + "\nFactor de corrección: " +toPresupuesto.get("factorCorrecion")
        + "\nTipo gravamen: " + toPresupuesto.get("tipoGravement") + "\nCC: " + toPresupuesto.get("cc") + "\nCVf: " + toPresupuesto.get("CVf ") + "\nComunidad: " + toPresupuesto.get("comunidad")
        + descuento, baseImponible: toPresupuesto.get("facturacion")} */
        
        this.setMostrarFormulario(mostrar);
      },
      error:(error)=>{

      }});
    }else{
      this._notificationSvc.warning(
        "Informacion",
        "Tiene que introducir todos los datos",
        2000
      );
    }
  }

  setMostrarFormulario(bool){
    console.log("this.mostrarFormulario")
    console.log(this.mostrarFormulario)
    if(this.mostrarFormulario==true){
      this.newItemEvent.emit(false);
    }
    this.mostrarFormulario=bool;   
     
  }


  setMostrarFormularioChild(value){
    if(value==false){
      this.setMostrarFormulario(false);
    }
    else{
      this.newItemEvent.emit(value);
    }
    //this.newItemEvent.emit(false);
    
  }

  async onSubmit(): Promise<any> {
   
    if( this.form.controls['tipo_vehiculo'].value=="COCHE"){
      this.form.controls['marca'].addValidators(Validators.required)
      this.form.controls['modelo'].addValidators(Validators.required)
    }
    else if(this.form.controls['tipo_vehiculo'].value=="MOTO"){
      if(this.form.controls['combustible'].value==5){
        this.form.controls['potencia_kW'].addValidators(Validators.required)
      }
      else if(this.form.controls['combustible'].value==1){
        this.form.controls['cilindrada'].addValidators(Validators.required)
      }
    }
    this.submitted=true;
    this.fechaAux=this.form.controls['fecha_matriculacion'].value
        
    if(this.form.valid){
   this.http.post(`${this.API_URL}/ipt/calcularIpt`,this.form.value,{responseType: 'text'}).subscribe({
  next: (data: any) => {
    const objetoJavaScript =JSON.parse(data)  
   this.DatosRecividos= new Map(Object.entries(objetoJavaScript));
   if( this.form.controls['tipo_vehiculo'].value=="COCHE"){
    this.form.controls['marca'].removeValidators(Validators.required)
    this.form.controls['modelo'].removeValidators(Validators.required)
  }
  else if(this.form.controls['tipo_vehiculo'].value=="MOTO"){
    if(this.form.controls['combustible'].value==5){
      this.form.controls['potencia_kW'].removeValidators(Validators.required)
    }
    else if(this.form.controls['combustible'].value==1){
      this.form.controls['cilindrada'].removeValidators(Validators.required)
    }
  }

  },
  error:(error)=>{

  }});
}else{
  this._notificationSvc.warning(
    "Informacion",
    "Tiene que introducir todos los datos",
    2000
  );
}

 
}

onChangeMarca(marca: Event) {
   this.TCarburantes = []
  this.TModelos=[]
  this.form.controls["marca"].setValue((marca.target as HTMLInputElement).value);
  return this.calculadoraitpService.listCombustibles(this.form.controls["marca"].value).subscribe((data) => {
    this.TCarburantes = data;
    if (this.TCarburantes.length>0) {
      this.form.controls["combustible"].setValue(this.TCarburantes.at(0));
    }
   

  });
}

vehicleTypeChange(tipo){
  this.form.controls['fecha_matriculacion'].setValue("");
  this.form.controls['marca'].setValue("");
  this.form.controls['modelo'].setValue("");
  this.form.controls['potencia_kW'].setValue("");
  this.form.controls['cilindrada'].setValue("");
  this.DatosRecividos = new Map();
  this.submitted = false;
  if(tipo=='MOTO'){
    this.calculadoraitpService.listCarburantes().subscribe((data) => {
      console.log(data);
      this.TCarburantes = data;
      this.form.controls['combustible'].setValue(this.TCarburantes.at(0));
    })
  }
}

formatDate(date) {
  var date2 = []  
  date2 = date.split("-");
  let dia = date2[0];
  let mes = date2[1];
  let anio = date2[2];
  return anio + "-" + mes + "-" + dia;
}
@Output()
propagar = new EventEmitter<boolean>();

}

