import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { InfoService } from 'src/app/_services/info.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';
import { RaizComponent } from 'src/app/raiz/raiz.component';
import { environment } from 'src/environments/environment';
import { FileHandle } from './drag-drop.directive';
import { Observable, ReplaySubject } from 'rxjs';
import { GastosService } from 'src/app/_services/hencok/gastos.service';
import { TipoGasto } from 'src/app/model/tipoGasto';
import { DatePipe } from '@angular/common';
import { GestionIngresosGastosComponent } from '../gestion-ingresos-gastos/gestion-ingresos-gastos.component';

@Component({
  selector: 'app-formulario-nuevo-gasto',
  templateUrl: './formulario-nuevo-gasto.component.html',
  styleUrls: ['./formulario-nuevo-gasto.component.css']
})
export class FormularioNuevoGastoComponent {

  form: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    fecha_creacion: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_gasto: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),

  },);

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  submitted: boolean = false;
  register2 = false;
  InfoRegistro: any = [];
  environment = environment;
  isLoggedIn = false;
  arrayDocuments = [];
  gastos: any = [];
  usuarios: any[] = [];
  usuario: any = -1;

  userLogged: any;
  userAdmin: Boolean = false;

  name = 'Angular 5';
  files: FileHandle[] = [];

  uploadDocuments: Boolean = false;


  constructor(
    private _notificationSvc: NotificationService,
    public _router: Router,
    private tokenStorageService: TokenStorageService,
    private gastoservice: GastosService,
    public datepipe: DatePipe,
    public _activatedRoute: ActivatedRoute,
    private _serviceGeneral: ServicioGeneral
  ) { }

  adviseError: boolean = false;
  typeError: boolean = false;

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    this.userLogged = this.tokenStorageService.getUser();
    let rol = this.tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }
    this.getUser(this.userLogged.id)
    this.register2 = false;
    this.getTipoGasto();
    this.getUserList();
  }



  onFileChange(event) {
    if (
      event.target.files[0].type == "application/pdf" ||
      event.target.files[0].type == "image/png" ||
      event.target.files[0].type == "image/jpeg"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        this.arrayDocuments = [document];
        this.form.controls["documento"].setValue(this.arrayDocuments);
      });
    } else {
      this._notificationSvc.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        2000
      );
    }
  }
  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => {
        //  console.log(error);
      }
    })
  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id)
      .subscribe({
        next: (usuario) => {
          this.usuario = usuario['Usuario'];

        },
        error: (error) => {
          //console.log(error);
        }
      });
  }
  async onSubmit(): Promise<any> {

    this.register2 = false;
    //console.log("save", this.form.controls);

    if (this.form.controls['documento'].value === "" || this.form.controls['documento'].value.length == 0) {
      //console.log("documento invalido");

      this.form.controls['documento'].setValue([]);
      this._notificationSvc.warning('Informacion', 'El documento es obligatorio', 2000);
      return false;
    }
    this.form.addControl('tipo_transaccion', new UntypedFormControl);
    this.form.controls['tipo_transaccion'].setValue(1);
    this.form.addControl('usuario_id', new UntypedFormControl);



    if (this.userAdmin) {
      if (this.usuario.id == null) {
        this._notificationSvc.warning('Informacion', 'El usuario es obligatorio', 2000);
        return false;
      }
      this.form.controls['usuario_id'].setValue(this.usuario.id);
    } else {

      this.form.controls['usuario_id'].setValue(this.userLogged.id);
    }

    if (this.form.controls["fecha_creacion"].invalid) {
      this._notificationSvc.warning(
        "Informacion",
        "El formato de la fecha no es correcto",
        2000
      );
      this.form.controls["fecha_creacion"].setValue(null)
      return false;
    }
    if (!this.form.valid) {
      this._notificationSvc.warning('Informacion', 'Rellene todos los campos por favor', 2000);
      return false;
    } else {
      this.submitted = true;
      this.uploadDocuments = true;
      this.form.controls['fecha_creacion'].setValue(this.datepipe.transform(this.form.controls['fecha_creacion'].value, 'dd/MM/yyyy'));
      this.gastoservice.saveGasto(this.form.value).subscribe({
        next: (data) => {
          this._notificationSvc.success('Hecho', "Gasto guardado con exito", 2000);
          this.submitted = true; 
          GestionIngresosGastosComponent.closeSidebarClienteRemoto();
           this._router.navigateByUrl('inicio').then(()=>{
            if(this.userAdmin){
              this._router.navigateByUrl('admin-hencok/gastos-ingresos')
            }else{
              this._router.navigateByUrl(
                'client-hencok/gastos-ingresos'
              );
            }
           
          })
        },
        error: (err) => {
          this.uploadDocuments = false;
          //console.log("err");
        }
      })
    }

  }

  subirArchivo(): any {
    try {
      const formularioDatos = new FormData();
      this.InfoRegistro.forEach(subirArchivo => {
        //console.log(subirArchivo);
        subirArchivo.append('files', subirArchivo)
      })
      this.InfoRegistro.post(environment.API_URL + `/`, this.InfoRegistro)
        .subcribe(res => {
          //console.log('Respuesta del servidor', res);

        })
    } catch (e) {
      //console.log('ERROR', e);
    }
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  filesDropped(files: FileHandle[]): void {

    this.form.patchValue({
      documento: files
    });
    this.convertFile(files[0].file).subscribe(base64 => {
      let document = { nombre: files[0].file.name, mime: files[0].file.type, base64: base64 }
      this.arrayDocuments.push(document);
      this.form.controls['documento'].setValue(this.arrayDocuments);
    });

    // document.getElementById('image1').onchange = function () {
    //   document.getElementById('fichero').innerHTML = document.getElementById('image1').files[0].name;
    // }
  }

  upload(): void {
    //get image upload file obj;
  }

  getTipoGasto() {
    return this.gastoservice.getTipoGasto().subscribe({
      next: (data) => {
        this.gastos = data;

      },
    })
  }

  removeFile(index: number): void {
    this.arrayDocuments.splice(index, 1);
  }

  reloadToMenuOption() {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/admin-hencok/gastos-ingresos'], { queryParams: { action: 2 } });

  }

  closeSidebar() {
    GestionIngresosGastosComponent.closeSidebarClienteRemoto();
  }


}
