import { Component } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { FacturaService } from "src/app/_services/hencok/factura.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { SharedDataService } from "src/app/_services/shared-data.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { PdfGenerateHencokService } from "src/app/_services/pdfGenerateHencok/pdf-generate-hencok.service";
import { Router } from "@angular/router";
import { NotificationService } from "src/app/_services/notification.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Factura } from "src/app/model/hencok/factura";
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { GastosService } from "src/app/_services/hencok/gastos.service";
import { finalize } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { formatDate } from "@angular/common";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: "app-gestion-facturas",
  templateUrl: "./gestion-facturas.component.html",
  styleUrls: ["./gestion-facturas.component.css"],
})
export class GestionFacturasComponent {
  totalElements: number = 0;
  mostrarFormulario: Boolean = false;
  editarFormulario: Boolean = false;
  pageIndex: number = 0;
  pageSize: number = 5;

  facturas: any;
  facturasInicio: any;
  facturaEnv: any;
  total_facturas: any=[];
  total_facturasImutable: any;

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };

  allFacturas: any;

  usuarios: any[] = [];
  usuario: any = -1;
  userLogged: any = -1;
  userAdmin: Boolean = false;
  submitted: boolean = false;
  uploadDocuments: Boolean = true;

  searchText: String = "";

  isSortedById: string = "";
  isSortedByFechaEmision: string = "";
  isSortedByFechaExpiracion: string = "";
  isSortedByFactura: string = "";
  isSortedByEstado: string = "";
  isSortedByNombre: string = "";
  isDelete = false;
  mostrarPag=true;
  arrayDocuments = [];
  sortArray = {};

  //A pesar de no haber campos de tipo formulario, el formulario
  //es necesario para crear una factura de reebolso.
  formFactura: FormGroup = new FormGroup({
    numeroFactura: new FormControl("", Validators.required),
    tipoGasto: new FormControl("", Validators.required),
    fecha_factura: new FormControl(new Date(), Validators.required),
    fecha_vencimiento: new FormControl("", Validators.required),
    importe_iva: new FormControl("", Validators.required),
    subtotal: new FormControl("", Validators.required),
    usuario: new FormControl("", Validators.required),
    detallesFactura: new FormArray([]),
  });

  //Se utiliza para generar la factura como ingreso.
  formIngreso: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    fecha_creacion: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_gasto: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),
    tipo_transaccion: new UntypedFormControl(2),
    usuario_id: new UntypedFormControl(),
  });

  get detallesFactura(): FormArray {
    return this.formFactura.get("detallesFactura") as FormArray;
  }

  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _tokenStorageService: TokenStorageService,
    private _facturaService: FacturaService,
    private _gastosService: GastosService,
    private _sharedDataService: SharedDataService,
    private notificationService: NotificationService,
    private _generatePdf: PdfGenerateHencokService,
    private _router: Router,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.userLogged = this._tokenStorageService.getUser();
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    } else {
      this._router.navigateByUrl("/client-hencok/facturacion").then(() => {
        this._router.navigate(
          ["client-hencok/facturacion/" + this.userLogged.id],
          { skipLocationChange: true }
        );
      });
    }
    this.getUserList();
    this.getFacturas();
    this.getTotalFacturas();
    this.sortArrayInitialize();

  }

  API_URL: string = environment.API_URL;
  downloadExcel(){
    console.log(this.total_facturas.length)
    var  listaFacturas:any=[];
    this.total_facturas.forEach(element => {
      listaFacturas.push(element.id)
    });
    
     return this.http.post(`${this.API_URL}/factura/generateExcelSeleccion`, listaFacturas,{responseType: 'text'}).subscribe({
       next: (data: any) => {
        // Decodifica el archivo Base64 a un arreglo de bytes
       const byteCharacters = atob(data);
       const byteNumbers = new Array(byteCharacters.length);
       for (let i = 0; i < byteCharacters.length; i++) {
         byteNumbers[i] = byteCharacters.charCodeAt(i);
       }
       const byteArray = new Uint8Array(byteNumbers);
       // Crea un objeto Blob y URL para el archivo
       const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       const url = window.URL.createObjectURL(blob);
       // Crea un enlace de descarga y simula un clic para descargar el archivo
       const a = document.createElement('a');
       a.href = url;
       a.download = 'mi_archivo.xlsx';
       document.body.appendChild(a);
       a.click();
       window.URL.revokeObjectURL(url); }
       ,error:(error)=>{
         console.log(error)
       }
   });
   }
 
  getTotalFacturas(){
    this._facturaService.getFacturaListAllByID(this.userLogged.id)    
    .subscribe({
     next:(data)=>{
 this.total_facturas= data;
 this.total_facturasImutable=data;
     },
     error:(error)=>{
     }
    })
  }


  setMostrarFormularioChild() {
    this.setMostrarFormulario(false);
  }

  setEditarFormularioChild() {
    this.setEditarFormulario(false);
  }

  getDateToFilter(fechas) {
    var fechaString = new Date(fechas);
    let month = "" + (fechaString.getMonth() + 1);
    let day = "" + fechaString.getDate();
    const year = fechaString.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  public downloadPdf(base64Document, fileName) {
    const source = "data:application/pdf;base64," + base64Document;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  downloadInvoice(documento) {
   // console.log(documento);
    this._facturaService
      .downloadJustificanteB64(documento.documento.id_gestor_documental)
      .subscribe({
        next: (fichero) => {
         // console.log(fichero);
          const linkSource = "data:application/pdf;base64," + fichero.Result;
          const downloadLink = document.createElement("a");
          const fileName = documento.numeroFactura;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();

          this.notificationService.success(
            "Hecho",
            "Pdf descargado con éxito",
            2000
          );
        },
        error: (error) => {
          //console.log(error);
        },
      });
  }

  openPDF(factura, fecha_factura, fecha_vencimiento) {
    this._facturaService.downloadJustificanteB64(factura.documento.id_gestor_documental)
      .subscribe({
        next: (documento) => {
          const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        },
        error: (error) => {
          this.notificationService.error('Error', 'No se ha podido abrir el archivo', 2000);
        }
      })

  }

  nextPage(event: PageEvent) {
    
    this.page = event.pageIndex.toString();
    this.size = event.pageSize.toString();
    this.getFacturas();
  }

  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => {
       // console.log(error);
      },
    });
  }

  getFacturaListByUsuario(request) {
    this._facturaService
      .getFacturaListByUsuario(this.userLogged.id, request)
      .subscribe({
        next: (data) => {
          console.log(data)
          this.facturas = data["content"];
          this.facturasInicio = data["content"];

          this.totalElements = data["totalElements"];
        },
        error: (error) => {
         // console.log(error);
        },
      });
  }

  refresh(): void {
    window.location.reload();
  }

  deleteFactura(factura, e: any, dialog) {
    this.isDelete = true;
    var ultimaFactura: any
    this._facturaService.getFacturaFullListByUsuario(this.userLogged.id).subscribe({
      next: (data) => {
        let listaFacturas: any = []
        listaFacturas = data;
        ultimaFactura = listaFacturas.at(listaFacturas.length - 1);
       // console.log(ultimaFactura)
       // console.log(factura)
        if (factura.numeroFactura === ultimaFactura.numeroFactura && !factura.rectificada && factura.estado.nombre == "PENDIENTE") {
         // console.log(factura)

          this._facturaService.deleteFactura(factura.id,true).subscribe({
            next: (data) => {
             // console.log(data)
             this.isDelete = false;
            },
            error: (error) => {
              dialog.close();
              this.refresh();
              this.isDelete = false;
            }
          })
        }
        else if (!factura.rectificada) {
          //Factura nueva para hacer rectificativa de la borrada
          this.createFactura(factura);
          dialog.close();
        } else {
          dialog.close();
        }
      }
    })
    



  }

  createFactura(factura) {
    this.uploadDocuments = false;
   // console.log(factura.tipoGasto.id);
    this.formFactura.controls["numeroFactura"].setValue(factura.numeroFactura);
    this.formFactura.controls["tipoGasto"].setValue(factura.tipoGasto.id);
    this.formFactura.controls["fecha_factura"].setValue(
      this.formatDate(new Date())
    );
    this.formFactura.controls["fecha_vencimiento"].setValue(
      this.formatDate(factura.fechaVencimiento)
    );
    this.formFactura.controls["importe_iva"].setValue(factura.importeIva * -1);
    this.formFactura.controls["subtotal"].setValue(factura.subtotal * -1);
    this.formFactura.controls["usuario"].setValue(factura.usuario);
    var detallesFacturaRem = [];

    var facturaFinal = new Factura(
      this.formatDate(this.formFactura.controls["fecha_factura"].value),
      this.formatDate(this.formFactura.controls["fecha_vencimiento"].value),
      (factura.importeIva * -1).toString(),
      (factura.subtotal * -1).toString(),
      (factura.cantidadTotal * -1).toString(),
      factura.numeroFactura,
      factura.idCliente,
      factura.email,
      factura.numeroDocumento,
      factura.nombre,
      factura.domicilio,
      factura.codigoPostal,
      factura.localidad,
      factura.tipoGasto,
      undefined,
      true,
    );
    var iva = ((factura.importeIva / factura.subtotal) * 100).toFixed(0).toString();
    var can = factura.cantidadNeta;
   // console.log(iva, "\n", can);
    const detalle = {
      concepto: "Factura rectificativa de " + factura.numeroFactura,
      precio: (factura.subtotal * -1),
      descuento: 0,
      cantidadNeta: factura.cantidadTotal * -1,
      tipoIva: iva,
      cantidad: 1,
    }
    detallesFacturaRem.push(detalle);

   // console.log(detallesFacturaRem);

    const usr = {
      nombre: factura.nombre,
      numero_documento: factura.numeroDocumento,
      domicilio: factura.domicilio,
      codigo_postal: factura.codigoPostal,
    };
    this._facturaService.create(facturaFinal, detallesFacturaRem,true).subscribe({
      next: (data) => {
       // console.log("save data", data);

        this.formFactura.controls["fecha_factura"].setValue(
          this.formatDate(factura.fechaFactura)
        );
        var facturaRectificada = new Factura(
          this.formatDate(this.formFactura.controls["fecha_factura"].value),
          this.formatDate(this.formFactura.controls["fecha_vencimiento"].value),
          factura.importeIva,
          factura.subtotal,
          factura.cantidadTotal,
          factura.numeroFactura,
          factura.idCliente,
          factura.email,
          factura.numeroDocumento,
          factura.nombre,
          factura.domicilio,
          factura.codigoPostal,
          factura.localidad,
          factura.tipoGasto,
          factura.id,
          true
        );
       // console.log("Estoy " + facturaRectificada)
        this._facturaService.update(facturaRectificada, factura.detalle,true).subscribe({
          next: (data) => {
           // console.log(data)
            this.notificationService.success(
              "Hecho",
              "Factura rectificativa con éxito",
              2000
            );
            const request = {};
            request["page"] = this.pageIndex;
            request["size"] = this.pageSize;
            this.getFacturaListByUsuario({
              page: this.pageIndex,
              size: this.pageSize,
            });
          }
        });

        var numeroFactura = data["numeroFactura"];
        var idFactura = data["id"];
      },
      error: (error) => {
        this.uploadDocuments = false;
       // console.log(error);
      },
    });



  }

  setMostrarFormulario(mode: Boolean) {
    this.mostrarFormulario = mode;
  }

  setEditarFormulario(mode: Boolean) {
    this.editarFormulario = mode;
  }

  setEditarFormularioSend(mode: Boolean, factura) {
    if (!factura.rectificada) {
      this.facturaEnv = factura;
      this.editarFormulario = mode;
    }else{
      this.notificationService.warning(

        "Aviso!",

        "No es posible editar la factura",

        2000

      );
    }

  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  formatDateIngreso(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  getFirstLetter(nombre: string) {
    return nombre.charAt(0).toUpperCase();
  }

  showModal(factura, dialog) {
    var ultimaFactura: any
    this._facturaService.getFacturaFullListByUsuario(this.userLogged.id).subscribe({
      next: (data) => {
        let listaFacturas: any = []
        listaFacturas = data;
        ultimaFactura = listaFacturas.at(listaFacturas.length - 1);
       // console.log(ultimaFactura)
       // console.log(factura)
        if (factura.numeroFactura === ultimaFactura.numeroFactura && !factura.rectificada && factura.estado == "PENDIENTE") {
          dialog.showModal();
        }
        else if (!factura.rectificada) {
          dialog.showModal();
        } else {
          this.notificationService.warning(

            "Aviso!",

            "No es posible eliminar la factura",

            2000

          );
        }
      }
    })
  }
  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        field_type == "STRING"
        value = this.formatDate(value);
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getFacturas();
  }

  getFacturas() {
    let rol = this._tokenStorageService.getRol();

    if (rol == 1) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this._facturaService
      .getFacturaFilter(this.request)
      .subscribe((data: any) => {
        this.facturas = data.pageList;
        console.log(data);

        this.totalElements = data.nrOfElements;
      });
  }

  sortArrayInitialize(){
    this.sortArray = {nombre:'none', id:'none', fechaFactura:'none', fechaVencimiento:'none', estado:'none'};
  }

  changeSorts(key, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = key instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of key){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(key);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){      
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    this.getFacturas();
  }
}
