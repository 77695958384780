import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormGroupDirective, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { NgImageSliderModule } from "ng-image-slider";
import { InicioComponent } from './inicio/inicio.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DatePipe, DecimalPipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask-2'
import { PrecisionPipe } from './pipe/custompipe';
import { DecimaLimitDirective } from './directives/decima-limit.directive'
import { CookieModule } from 'ngx-cookie';
import { AuthGuard } from './guards/auth.guard';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgPipesModule } from 'ngx-pipes';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MaterialModule } from './material/material.module'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CardDireccionesComponent } from './profile/card-direcciones/card-direcciones.component';
import { NotificationComponent } from './notification/notification.component';
import { AutoFirmaComponent } from './auto-firma/auto-firma';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RaizComponent } from './raiz/raiz.component';
import { ReloadDirectiveDirective } from './directives/reload-directive.directive';
import { EnvironmentGuard } from './guards/environment.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { BoardAdminHencokComponent } from './board-admin-hencok/board-admin-hencok.component';
import { GestionClientesComponent } from './hencok/gestion-clientes/gestion-clientes.component';
import { GestionIngresosGastosComponent } from './hencok/gestion-ingresos-gastos/gestion-ingresos-gastos.component';
import { FormularioNuevoGastoComponent } from './hencok/formulario-nuevo-gasto/formulario-nuevo-gasto.component'
import { DragDropDirective } from './hencok/formulario-nuevo-gasto/drag-drop.directive';
import { FilterPipeUser } from './pipe/filterUser.pipe';
import { GestionFacturasComponent } from './hencok/gestion-facturas/gestion-facturas.component';
import { FichaClienteComponent } from './hencok/ficha-cliente/ficha-cliente.component';
import { FilterFacturasPipe } from './pipe/filter-facturas.pipe';
import { FormularioNuevoIngresoComponent } from './hencok/formulario-nuevo-ingreso/formulario-nuevo-ingreso.component';
import { GestionImpuestosComponent } from './hencok/gestion-impuestos/gestion-impuestos.component';
import { ReplacePipe } from './pipe/replace.pipe';
import { FichaClienteActualizarComponent } from './hencok/ficha-cliente-actualizar/ficha-cliente-actualizar/ficha-cliente-actualizar.component';
import { GestionIngresosGastosClienteComponent } from './hencok/gestion-ingresos-gastos-cliente/gestion-ingresos-gastos-cliente.component';
import { CumplimentacionFacturaComponent } from './hencok/cumplimentacion-factura/cumplimentacion-factura.component';
import { FormularioNuevoClienteComponent } from './hencok/formulario-nuevo-cliente/formulario-nuevo-cliente.component';
import { VisualizarGastosIngresosComponent } from './hencok/visualizar-gastos-ingresos/visualizar-gastos-ingresos.component';
import { GestionFacturasClientesComponent } from './hencok/gestion-facturas-clientes/gestion-facturas-clientes.component';
import { FormularioFacturaComponent } from './hencok/formulario-factura/formulario-factura.component';
import { ImpuestosClienteComponent } from './hencok/impuestos-cliente/impuestos-cliente.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { UrlService } from './_services/urlService';
import { FormularioEditarGastoIngresoComponent } from './hencok/formulario-editar-gasto-ingreso/formulario-editar-gasto-ingreso.component';
import { FichaClienteNoAdminComponent } from './hencok/ficha-cliente-no-admin/ficha-cliente-no-admin.component';
import { FichaClienteNoAdminActualizarComponent } from './hencok/ficha-cliente-no-admin-actualizar/ficha-cliente-no-admin-actualizar.component';
import { FormularioFacturaEditarComponent } from './hencok/formulario-factura-editar/formulario-factura-editar.component';
import { FormularioAdjuntarModeloComponent } from './hencok/formulario-adjuntar-modelo/formulario-adjuntar-modelo.component';
import { VisualizarImpuestoComponent } from './hencok/visualizar-impuesto/visualizar-impuesto.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { MatButtonToggleModule   } from '@angular/material/button-toggle';
import { FormularioEditarModeloComponent } from './hencok/formulario-editar-modelo/formulario-editar-modelo.component';
import { FilterImpuestoPipe } from './pipe/filter-impuesto.pipe';
import { VisualizarNotificacionesComponent } from './hencok/visualizar-notificaciones/visualizar-notificaciones.component';
import { DetallesNotificacionesComponent } from './hencok/detalles-notificaciones/detalles-notificaciones.component';
import { ManualUtilizacionComponent } from './hencok/manual-utilizacion/manual-utilizacion.component';
import { PoliticaPrivacidadComponent } from './politica-privacidad/politica-privacidad.component';
import { AvisoLegalComponent } from './aviso-legal/aviso-legal.component';
import { GestionContratosComponent } from './hencok/gestion-contratos/gestion-contratos.component';
import { FormularioAdjuntarContratoComponent } from './hencok/formulario-adjuntar-contrato/formulario-adjuntar-contrato.component';
import { LaboralClienteComponent } from './hencok/laboral-cliente/laboral-cliente.component';
import { FormularioTramiteComponent } from './autoescuela/formulario-tramite/formulario-tramite.component';
import { CalculadoraItpComponent } from './autoescuela/calculadora-itp/calculadora-itp.component';
import { PresupuestoComponent } from './autoescuela/presupuesto/presupuesto.component';
import { VisualizarTransferenciaComponent } from './autoescuela/visualizar-transferencia/visualizar-transferencia.component';
import { ListadoTramitesComponent } from './autoescuela/listado-tramites/listado-tramites.component';
import { GestionPresupuestosComponent } from './autoescuela/gestion-presupuestos/gestion-presupuestos.component';
import { GestionPresupuestosGestorComponent } from './autoescuela/gestion-presupuestos-gestor/gestion-presupuestos-gestor.component';
import { PresupuestoFilledComponent } from './autoescuela/presupuesto-filled/presupuesto-filled.component';
import { NotificacionesTramitesComponent } from './autoescuela/notificaciones-tramites/notificaciones-tramites.component';
import { FormularioConsultaComponent } from './autoescuela/formulario-consulta/formulario-consulta.component';
import { VisualizarConsultaComponent } from './autoescuela/visualizar-consulta/visualizar-consulta.component';
import { FormularioInformeDgtComponent } from './autoescuela/formulario-informe-dgt/formulario-informe-dgt.component';
import { FormularioDistintivoAmbientalComponent } from './autoescuela/formulario-distintivo-ambiental/formulario-distintivo-ambiental.component';
import { VisualizarDistintivoAmbientalComponent } from './autoescuela/visualizar-distintivo-ambiental/visualizar-distintivo-ambiental.component';
import { VisualizarInformeDgtComponent } from './autoescuela/visualizar-informe-dgt/visualizar-informe-dgt.component';
import { FormularioDuplicadoFichaTecnicaComponent } from './autoescuela/formulario-duplicado-ficha-tecnica/formulario-duplicado-ficha-tecnica.component';
import { VisualizarDuplicadoFichaTecnicaComponent } from './autoescuela/visualizar-duplicado-ficha-tecnica/visualizar-duplicado-ficha-tecnica.component';
import { FormularioCanjeDelPermisoComponent } from './autoescuela/formulario-canje-del-permiso/formulario-canje-del-permiso.component';
import { VisualizarCanjeDelPermisoComponent } from './autoescuela/visualizar-canje-del-permiso/visualizar-canje-del-permiso.component';
import { VisualizarSolicitudPermisoInternacionalComponent } from './autoescuela/visualizar-solicitud-permiso-internacional/visualizar-solicitud-permiso-internacional.component';
import { FormualrioSolicitudPermisoInternacionalComponent } from './autoescuela/formualrio-solicitud-permiso-internacional/formualrio-solicitud-permiso-internacional.component';
import { DuplicadoPermisoConducirComponent } from './autoescuela/formulario-duplicado-permiso-conducir/duplicado-permiso-conducir.component';
import { VisualizarDuplicadoPermisoConducirComponent } from './autoescuela/visualizar-duplicado-permiso-conducir/visualizar-duplicado-permiso-conducir.component';
import { FormularioMatriculacionComponent } from './autoescuela/formulario-matriculacion/formulario-matriculacion.component';
import { VisualizarMatriculacionComponent } from './autoescuela/visualizar-matriculacion/visualizar-matriculacion.component';
import { EstadisticasComponent } from './autoescuela/estadisticas/estadisticas.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    BuscadorComponent,
    InicioComponent,
    PrecisionPipe,
    DecimaLimitDirective,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CardDireccionesComponent,
    NotificationComponent,
    AutoFirmaComponent,
    RaizComponent,
    ReloadDirectiveDirective,
    BoardAdminHencokComponent,
    GestionClientesComponent,
    GestionIngresosGastosComponent,
    FormularioNuevoGastoComponent,
    DragDropDirective,
    FilterPipeUser,
    GestionFacturasComponent,
    FichaClienteComponent,
    FilterFacturasPipe,
    FormularioNuevoIngresoComponent,
    GestionImpuestosComponent,
    ReplacePipe,
    FichaClienteActualizarComponent,
    GestionIngresosGastosClienteComponent,
    CumplimentacionFacturaComponent,
    FormularioNuevoClienteComponent,
    VisualizarGastosIngresosComponent,
    GestionFacturasClientesComponent,
    FormularioFacturaComponent,
    ImpuestosClienteComponent,
    FormularioEditarGastoIngresoComponent,
    FichaClienteNoAdminComponent,
    FichaClienteNoAdminActualizarComponent,
    FormularioFacturaEditarComponent,
    FormularioAdjuntarModeloComponent,
    VisualizarImpuestoComponent,
    FormularioEditarModeloComponent,
    FilterImpuestoPipe,
    VisualizarNotificacionesComponent,
    DetallesNotificacionesComponent,
    ManualUtilizacionComponent,
    PoliticaPrivacidadComponent,
    AvisoLegalComponent,
    GestionContratosComponent,
    FormularioAdjuntarContratoComponent,
    LaboralClienteComponent,
    FormularioTramiteComponent,
    CalculadoraItpComponent,
    PresupuestoComponent,
    
    VisualizarTransferenciaComponent,
    ListadoTramitesComponent,
    GestionPresupuestosComponent,
    GestionPresupuestosGestorComponent,
    PresupuestoFilledComponent,
    NotificacionesTramitesComponent,
    FormularioConsultaComponent,
    VisualizarConsultaComponent,
    FormularioInformeDgtComponent,
    FormularioDistintivoAmbientalComponent,
    VisualizarDistintivoAmbientalComponent,
    VisualizarInformeDgtComponent,
    FormularioDuplicadoFichaTecnicaComponent,
    VisualizarDuplicadoFichaTecnicaComponent,
    FormularioCanjeDelPermisoComponent,
    VisualizarCanjeDelPermisoComponent,
    VisualizarSolicitudPermisoInternacionalComponent,
    FormualrioSolicitudPermisoInternacionalComponent,
    DuplicadoPermisoConducirComponent,
    VisualizarDuplicadoPermisoConducirComponent,
    FormularioMatriculacionComponent,
    VisualizarMatriculacionComponent,
    EstadisticasComponent,    
   ],
  imports: [
    BrowserModule,
    UiSwitchModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgImageSliderModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    CookieModule.withOptions(),
    NgxMaskModule.forRoot({ dropSpecialCharacters: false }),
    SimpleNotificationsModule.forRoot(),
    NgPipesModule,
    MaterialModule,
    ModalModule,
    PopoverModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [authInterceptorProviders,
    DatePipe,
    DecimalPipe,
    PrecisionPipe,
    AuthGuard,
    EnvironmentGuard,
    MatTableDataSource,
    FormGroupDirective,
    MatDatepickerModule,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private urlService: UrlService) { }

}