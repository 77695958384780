import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root",})
export class NexcloudService {

    API_URL: string = environment.API_URL;

    constructor( private http: HttpClient, ) {}

    downloadJustificanteB64(id: number): Observable<any> {
        let API_URL = `${this.API_URL}/nextcloud/downloadB64/${id}`;
        return this.http.get(API_URL);
    }

    updateJustificanteB64(form: object): Observable<any> {
        let API_URL = `${this.API_URL}/tramite_emvs/updateFirma`;
        return this.http.post(API_URL,form);
    }
}