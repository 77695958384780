import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, NgModule } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ManualService } from 'src/app/_services/hencok/manual.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-manual-utilizacion',
  templateUrl: './manual-utilizacion.component.html',
  styleUrls: ['./manual-utilizacion.component.css']
})

export class ManualUtilizacionComponent {
  treeControl = new NestedTreeControl<ManualData>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ManualData>();
  manualdata
  isAdmin: boolean
  constructor(private manualSrvice: ManualService,
    private _tokenStorageService:TokenStorageService) {
    
    this.getManualdata()
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.isAdmin = true;
      this.dataSource.data = TREE_DATA;
    }else if(rol>=3 && rol<=5){
      this.isAdmin = false;
     this.dataSource.data = TREE_DATA_NON_ADMIN_AUTO;
    }
    else{
     this.isAdmin = false;
     this.dataSource.data = TREE_DATA_NON_ADMIN;
    }
  }

  hasChild = (_: number, node: ManualData) => !!node.children && node.children.length > 0;                                                         

  getManualdata() {
    this.manualSrvice.getAll().subscribe({
      next: (data) => {
        this.manualdata = data.manual
      }
    })
  }

  getByCodigo(codigo: String) {
    var texto: String;
    if (this.manualdata!=undefined){
      this.manualdata.forEach(manual => {
        if (codigo == manual.codigo) {
          texto = manual.texto
        }
      });
    }
    return texto;
  }
}
interface ManualData {
  name: string;
  children?: ManualData[];
}

const TREE_DATA: ManualData[] = [
  {
    name: 'Clientes',
    children: [{ name: 'Crear Cliente', children: [{ name: 'crearcliente' }] }, { name: 'Visualizar Cilente', children: [{ name: 'visualizarcliente' }] }
      , { name: 'Editar Cliente', children: [{ name: 'editarCliente' }] }, { name: 'Eliminar Cliente', children: [{ name: 'eliminarCliente' }] }],
  },
  {
    name: 'Gastos/ingresos',
    children: [{ name: 'Crear Gasto/Ingreso', children: [{ name: 'creargasing' }] }, { name: 'Visualizar Gasto/Ingreso', children: [{ name: 'visualizargasing' }] },
    { name: 'Editar Gasto/Ingreso', children: [{ name: 'editargasing' }] }, { name: 'Eliminar Gasto/Ingreso', children: [{ name: 'eliminargasing' }] }]
  },
  {
    name: 'Facturas',
    children: [{ name: 'Crear Factura', children: [{ name: 'cearfactura' }] }, { name: 'Visualizar Factura', children: [{ name: 'visualizarfactura' }] },
    { name: 'Editar Factura', children: [{ name: 'editarfactura' }] }, { name: 'Eliminar Factura', children: [{ name: 'eliminarfactura' }] }],
  },
  {
    name: 'Impuestos',
    children: [{ name: 'Crear Impuesto', children: [{ name: 'cearimpuesto' }] }, { name: 'Visualizar impuesto', children: [{ name: 'visualizarimpuesto' }] }, 
    { name: 'Editar impuesto', children: [{ name: 'editarimpuesto' }] }, { name: 'Eliminar Impuesto', children: [{ name: 'eliminarimpuesto' }] }],
  },

];

const TREE_DATA_NON_ADMIN: ManualData[] = [
  {
    name: 'Clientes',
    children: [{ name: 'Crear Cliente', children: [{ name: 'crearcliente' }] }, { name: 'Visualizar Cilente', children: [{ name: 'visualizarcliente' }] }
      , { name: 'Editar Cliente', children: [{ name: 'editarCliente' }] }, { name: 'Eliminar Cliente', children: [{ name: 'eliminarCliente' }] }],
  },
  {
    name: 'Gastos/ingresos',
    children: [{ name: 'Crear Gasto/Ingreso', children: [{ name: 'creargasing' }] }, { name: 'Visualizar Gasto/Ingreso', children: [{ name: 'visualizargasing' }] },
    { name: 'Editar Gasto/Ingreso', children: [{ name: 'editargasing' }] }, { name: 'Eliminar Gasto/Ingreso', children: [{ name: 'eliminargasing' }] }]
  },
  {
    name: 'Facturas',
    children: [{ name: 'Crear Factura', children: [{ name: 'cearfactura' }] }, { name: 'Visualizar Factura', children: [{ name: 'visualizarfactura' }] },
    { name: 'Editar Factura', children: [{ name: 'editarfactura' }] }, { name: 'Eliminar Factura', children: [{ name: 'eliminarfactura' }] }],
  },
  {
    name: 'Impuestos',
    children: [{ name: 'Visualizar impuesto', children: [{ name: 'visualizarimpuesto' }] },],
  },


];

const TREE_DATA_NON_ADMIN_AUTO: ManualData[] = [
  {
    name: 'Cálculo ITP',
    children: [{ name: 'Calcular ITP', children: [{ name: 'calcularITP' }] }, { name: 'Crear Presupuesto con cálculo ITP', children: [{ name: 'presupuestoITP' }] },]
  },
  {
    name: 'Presupuestos',
    children: [{ name: 'Crear un Presupuesto', children: [{ name: 'createPresupuesto' }] }, { name: 'Asociar presupuesto a tramite', children: [{ name: 'presupuestoToTramite' }] },
    { name: 'Crear un tramite a base de un presupuesto', children: [{ name: 'presupuestoToNewTramite' }] }, { name: 'Editar Presupuesto', children: [{ name: 'editPresupuesto' }] },
    { name: 'Visualizar un presupuesto', children: [{ name: 'visualizarPresupuesto' }] }, { name: 'Eliminar un presupuesto', children: [{ name: 'eliminarPresupuesto' }] },]
  },

  {
    name: 'Trámites',
    children: [{ name: 'Crear un Trámite', children: [{ name: 'createTramite' }] }, { name: 'Crear Presupuesto asociado a trámite', children: [{ name: 'tramiteToPresupuesto' }] },
    { name: 'Crear Presupuesto asociado a trámite personalizado', children: [{ name: 'tramiteToPresupuestoCustom' }] }, { name: 'Seleccionar presupuesto', children: [{ name: 'selectPresupuestoTramite' }] },
    { name: 'Cumplimentar Documentos', children: [{ name: 'cumplimetarDocumentos' }] }, { name: 'Enviar notificaciones', children: [{ name: 'sendNotificaciones' }] },
    { name: 'Visualizar Trámite', children: [{ name: 'visualizarTramite' }] },{ name: 'Editar Trámite', children: [{ name: 'editarTramite' }] },]
  },
];

