<div class="pageTitle">
    <h1 class="m-0">Ficha cliente</h1>
    <div>
        <button *ngIf="usuario?.idDocumentoFirmado != undefined "class="btn btn-primary mx-3 mr-5" type="submit" (click)="downloadContrato()">
            <mat-icon class="align-middle mr-2">description</mat-icon>Contrato Firmado</button>
            <button *ngIf="usuario?.idDocumentoFirmado == undefined "class="btn btn-primary mx-3 mr-5" type="submit" (click)="downloadContrato()">
                <mat-icon class="align-middle mr-2">description</mat-icon>Contrato</button>
            <button class="btn btn-primary mx-3 mr-5" type="submit" (click)="goUpdate()">
                <mat-icon class="align-middle mr-2">add</mat-icon>Actualizar</button>
    </div>
    
</div>
<div class="">
    <hr class="linea-divisoria-title
    
    
    
    
    
    
    
    
    
    
    ">
</div>
<!--
<dialog #dialog>
    <div class="m-3">
        <div>
            <h5>¿Desea confirmar la {{isActive ? 'desactivacion' : 'activacion'}} del doble factor?</h5>
        </div>
        <div class="d-flex justify-content-end">
            <button id="btacept" class="btn btn-primary mx-1 mt-5" type="submit" (click)="updateTwoFactor()">Confirmar</button>
            <button id="btcancel" class="btn btn-primary mx-1 mt-5" type="submit" (click)="dialog.close()">Cancelar</button>
        </div>
    </div>
</dialog>
-->
<div class="flex-row d-flex justify-content-between mb-3">
    <div class="col-sm-4 d-flex justify-content-around mb-5">
        <div class="iconName rounded-circle" style="width: 100px; height: 100px;">
            <p *ngIf="usuario?.imgPerfil==undefined">
                {{getFirstLetter(usuario?.nombre)}}{{getFirstLetter(usuario?.apellido1)}}</p>
            <img *ngIf="usuario?.imgPerfil!=undefined" src="data:image;base64,{{usuario.imgPerfil}}" width="100%"
                height="100%" class="rounded-circle">

        </div>
        <div>
            <b>
                <p id="id"><b>#ID{{usuario?.id}}</b></p>
            </b>
            <p id="info"><b>{{ usuario?.tipopersona.id==1 ? (usuario?.nombre + ' ' + usuario?.apellido1) : usuario?.nombre}}</b></p>
            <p id="info">{{usuario?.email}}</p>
        </div>
    </div>
    <div class="justify-content-center align-items-center text-center pr-5 mr-5">
        <h5><b>¿Quieres {{isActive ? 'desactivar' : 'activar'}} el doble factor de autenticación?</b></h5>
        <button class="btn btn-primary mx-3 mt-5" style="width: 150px;" type="submit" (click)="updateTwoFactor()">
            {{isActive ? 'Desactivar' : 'Activar'}}</button>
    </div>
</div>

<form [formGroup]="form" (ngSubmit)="submit() " class="full-form-group">

    <h3 class="subtitle">Datos Personales</h3>
    <div class="rowForm">
        <div class="row">
            <div class="col-sm-3 " *ngIf="usuario?.tipopersona.id==1">
                <p class="detailsBold">Nombre y apellidos: </p>
                <p class="details">{{nombre}} {{apellido1}} {{apellido2}}</p>
            </div>
            <div class="col-sm-3 " *ngIf="usuario?.tipopersona.id==2">
                <p class="detailsBold">Razón social: </p>
                <p class="details">{{nombre}}</p>
            </div>
            <div class="col-sm-3">
                <p class="detailsBold">{{usuario?.tipo_documento?.nombre}}: </p>
                <p class="details">{{numero_documento}}</p>
            </div>
            <div class="col-sm-3">
                <p class="detailsBold">Email: </p>
                <p class="details">{{email}}</p>
            </div>
            <div class="col-sm-3">
                <p class="detailsBold">Teléfono: </p>
                <p class="details">{{telefono}}</p>
            </div>
        </div>
    </div>
    <h3 class="subtitle">Dirección</h3>
    <div class="rowForm">
        <div class="row">

            <div class="col-sm-3">
                <p class="detailsBold">Calle (dirección): </p>
                <p class="details">{{name_road}}</p>
            </div>
            <div class="col-sm-3">
                <p class="detailsBold">Número (dirección): </p>
                <p class="details">{{number_road}}</p>
            </div>
            <div class="col-sm-3">
                <p class="detailsBold">Provincia: </p>
                <p class="details">{{provincia}}</p>
            </div>
            <div class="col-sm-3">
                <p class="detailsBold">Código postal: </p>
                <p class="details">{{codigo_postal}}</p>
            </div>
    
        </div>
    </div>
    <h3 *ngIf="iban!=undefined" class="subtitle">Información bancaria</h3>
    <div *ngIf="iban!=undefined" class="rowForm">
        <div class="row">
            <div class="col-sm-3">
                <p class="detailsBold">IBAN: </p>
                <p class="details">{{ibann}}</p>
            </div>
            <div class="col-sm-3">
                <p class="detailsBold">Titular: </p>
                <p class="details">{{titular}}</p>
            </div>
            <div class="col-sm-3">
                <p class="detailsBold">Entidad: </p>
                <p class="details">{{entidad}}</p>
            </div>
        </div>
    </div>
    <h3 class="subtitle">Otra Información</h3>
    <div class="rowForm">
        <div class="row">
            <div class="col-sm-3">
                <p class="detailsBold">Estado: </p>
                <p class="details">{{estado ? "Activo" : "Inactivo"}}</p>
            </div>
        </div>
    
        
        <h3 class="subtitle">Observaciones</h3>
            <div class="rowForm">
                    <textarea class="form-control mt-2 mr-5" id="exampleFormControlTextarea1" rows="6" style="resize: none;"
                disabled>{{observaciones}}</textarea>
        </div>
    </div>

</form>