


<div class="page__content_sidebar"  *ngIf="this.notificacion!= null" >
    
        <div class="container"> 
        <div class="row ">
            <div class="col-sm col-sm-auto">
                Asunto:
            </div>
            <div class="col">
                <p style="font-size:17px">
                    {{this.notificacion.asunto}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm col-sm-auto">
                Fecha:
            </div>
            <div class="col">
                <p style="font-size:17px">{{formatDate(notificacion.fechaCreacion)}}</p>
            </div>
          </div>
        
        <div class="row">
          <div class="col-sm col-sm-auto">
            Cuerpo:
          </div>
          <div class="col">
            <p style="font-size:17px">{{this.notificacion.cuerpo}}</p>
          </div>
        </div>
       <div class="row d-flex justify-content-end pr-5">
        <button type="button" class="btn  btn-outline-primary mr-2" (click)="closeSidebar()" [disabled]="submitted">
            <div>volver</div>
        </button>
        </div></div>
</div>
