<div class="d-flex flex-row justify-content-between align-items-center pl-3 pr-3">
    <h1 class="m-0">Visualizar consulta</h1>
</div>
<div class="ml-4">
    <hr class="linea-divisoria mr-4">
</div>
<div class="col-sm-12 pt-3 pr-0">
    <div class="col-sm-12 row pr-0">
        <div class="col-sm-4">
            <label class="col-12 pl-0 pr-0 crear-editar-form">Consulta</label>
            <input class="form-control col-12" type="text" disabled
                value="{{concepto}}">
        </div>
        <div class="col-sm-4">
            <label class="col-12 pl-0 pr-0 crear-editar-form">Nombre</label>
            <input class="form-control col-12" type="text" disabled
                value="{{nombre}}">
        </div>
        <div class="col-sm-4">
            <label class="col-12 pl-0 pr-0 crear-editar-form">Email</label>
            <input class="form-control col-12" type="text" disabled
                value="{{email}}">
        </div>


    </div>
</div>
<div class="col-sm-12 pt-3">
    <div class="col-sm-3">
        <label class="crear-editar-form">Observaciones</label>
    </div>
    <div class="col-sm-12">
        <textarea class="form-control" disabled rows="6">{{mensaje}}</textarea>
    </div>
</div>
<div class="col-sm-12 pt-3 ">
    <div class="col-sm-12">
        <label class="crear-editar-form">Documentos adjuntos</label>
    </div>
    <div *ngIf="this.documentos?.length>0" class="col-sm-122 mb-4 pt-2 pb-2">
        <div class="pt-2 pb-2 lista-documentos">
            <div *ngFor="let documento of this.documentos" class="pr-3 pl-3 pt-2 pb-2">
                <div class="documento pt-1 pb-1">
                    <a class="cortar-texto" (click)="openPdf(documento.base64)">{{documento['nombre']}}</a>

                </div>
            </div>
        </div>

    </div>
</div>