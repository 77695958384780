import { ActivatedRoute } from "@angular/router";
import { Component } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { IngresosGastosService } from "src/app/_services/hencok/ingresos-gastos.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { SharedDataService } from "src/app/_services/shared-data.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { finalize } from "rxjs";
import { NotificationService } from "src/app/_services/notification.service";
import { GastosService } from "src/app/_services/hencok/gastos.service";
import { NexcloudService } from "src/app/_services/nexcloud.service";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-gestion-ingresos-gastos-cliente",
  templateUrl: "./gestion-ingresos-gastos-cliente.component.html",
  styleUrls: ["./gestion-ingresos-gastos-cliente.component.css"],
})
export class GestionIngresosGastosClienteComponent {
  usuario: any;
  id;

  total_facturado: any = 0;
  total_ingreso: number = 0;
  total_deuda: number = 0;

  totalElements: number = 0;
  gastos_ingresos: any;
  total_gastos_ingresos: any;
  total_gastos_ingresosInmutable: any;

  gastos_ingresosInicio: any;
  gastoIngreso: any;
  gasto_ingreso2: any = null;

  searchText: String = "";
  primeraVezM: boolean = false;
  gasto_ingreso: any = null;
  detalles = [];
  detalles2 = [];

  fecha_actual: string;
  load: boolean;

  isSortedByFecha: string = "";
  isSortedByConcepto: string = "";
  isSortedByImporte: string = "";
  isSortedByEstado: string = "";
  isSortedByTipo: string = "";
  isSortedByCliente: string = "";
  isShowedGastoIngreso: boolean = false;
  mostrarGastoIngreso: boolean = false;
  isDelete = false;


  primeraVez: boolean = true;
  usuarios = [];
  sortedUsuarios = [];
  pageSize = 5;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  fechaAlta ="";
  mostrarPag=true;

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];
  sortArray : any = {}

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };

  constructor(
    private nextCloudService: NexcloudService,
    private _notificationSvc: NotificationService,
    private _serviceGeneral: ServicioGeneral,
    private _ingresosGastosService: IngresosGastosService,
    private _route: ActivatedRoute,
    private _tokenService: TokenStorageService,
    private _tokenStorageService: TokenStorageService,
    private _sharedDataService: SharedDataService,

  ) {}

  ngOnInit(): void {
    
    this.fecha();
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
     // console.log("user admin")
      this.id= this._route.snapshot.paramMap.get('id')
    }else{
    //  console.log("user ")
    this.usuario = this._tokenService.getUser();
    this.id = this.usuario.id;
    }
    this.usuario = this.getUser();

      this._sharedDataService.currentMessage.subscribe(message => (this.filtrar(message)));

     this.getoTotalGastos();

     this.sortArrayInitialize();
  
  }

  getoTotalGastos(){
    this._ingresosGastosService
    .getIngresoGastoListbyId(this.id)
    .subscribe({
      next: (data) => {
        this.total_gastos_ingresos=data;
        this.total_gastos_ingresosInmutable=data;

      },
      error: (error) => {
      //  console.log(error);
      },
    });
  }

  filtrar(mesagge: string) {
    if(this.total_gastos_ingresosInmutable!= undefined){
    if (mesagge.length > 0) {
      this.gastos_ingresos = this.total_gastos_ingresosInmutable.filter((item) =>
      item.concepto.toLowerCase().indexOf(mesagge.toLowerCase()) != -1 ||
      item.estado.nombre.toLowerCase().indexOf(mesagge.toLowerCase()) != -1 ||
      item.tipoGasto.nombre.toLowerCase().indexOf(mesagge.toLowerCase()) != -1 ||
      item.importe.toString().includes(mesagge)||
      item.fechaCreacion.toString().includes(mesagge)||
      ( item.fechaCreacion[2].toString()+"/" + item.fechaCreacion[1].toString() + "/" + item.fechaCreacion[0].toString()).includes(mesagge)
      )
      this.total_gastos_ingresos=this.gastos_ingresos;
      this.pageSize=this.total_gastos_ingresos.size
    } else{
      this.getIngresosListByUsuario({page: "0", size: "5"  });
      this.getoTotalGastos();
    }
    if(mesagge != ""){
      this.mostrarPag=false
    }else{
      this.mostrarPag=true
    }

  }

     

}
  private getUser(): void {
  //  console.log("22user")
    this._serviceGeneral.getUserId(this.id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];

      this.fechaAlta = this.usuario.fechaAlta[2]+"/"+this.usuario.fechaAlta[1]+"/"+this.usuario.fechaAlta[0]
      
        this.getIngresosListByUsuario({ page: this.pageIndex, size: this.pageSize });
        this._ingresosGastosService
          .getTotalFacturado(this.usuario.id)
          .subscribe({
            next: (data) => {
             // console.log(data)
              this.total_ingreso = Math.round((data["totalIngreso"] + Number.EPSILON) * 100) / 100;
              this.total_deuda = Math.round((data["totalGasto"] + Number.EPSILON) * 100) / 100; 
              this.total_facturado = data["total"].toLocaleString();
            },
            error: (error) => {
            // console.log(error);
            },
          });
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }

  getIngresosListByUsuario(request) {

    this._ingresosGastosService
      .getIngresoGastoListByUsuario(request, this.id)
      .subscribe({
        next: (data) => {
          this.gastos_ingresos = data["content"];
          this.gastos_ingresosInicio = data["content"];
          this.totalElements = data["totalElements"];
        },
        error: (error) => {
          //console.log(error);
        },
      });

  }



  getDateToCompare(fechas) {
    var fechaString = fechas.join("-");
    var date = new Date(fechaString);
    return date.getTime();
  }
 
  
  orderByFecha() {
    this.isSortedByConcepto = "";
    this.isSortedByImporte = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";


    if (this.isSortedByFecha != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (
          this.getDateToCompare(n1.fechaCreacion) >
          this.getDateToCompare(n2.fechaCreacion)
        ) {
          return -1;
        }

        if (
          this.getDateToCompare(n1.fechaCreacion) <
          this.getDateToCompare(n2.fechaCreacion)
        ) {
          return 1;
        }

        return 0;
      });
      this.isSortedByFecha = "chevron-button";
    } else if (this.isSortedByFecha == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (
          this.getDateToCompare(n1.fechaCreacion) >
          this.getDateToCompare(n2.fechaCreacion)
        ) {
          return 1;
        }

        if (
          this.getDateToCompare(n1.fechaCreacion) <
          this.getDateToCompare(n2.fechaCreacion)
        ) {
          return -1;
        }

        return 0;
      });
      
      this.isSortedByFecha = "chevron-button-up";
    }
  
      this.gastos_ingresos=[]
      this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);

  }
  orderByConcepto() {
    this.isSortedByFecha = "";
    this.isSortedByImporte = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";

    if (this.isSortedByConcepto != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        
        if (n1.concepto > n2.concepto) {
          return -1;
        }

        if (n1.concepto < n2.concepto) {
          return 1;
        }

        return 0;
      });
      this.isSortedByConcepto = "chevron-button";
    } else if (this.isSortedByConcepto == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
   
        if (n1.concepto > n2.concepto) {
          return 1;
        }

        if (n1.concepto < n2.concepto) {
          return -1;
        }

        return 0;
      });
      this.isSortedByConcepto = "chevron-button-up";
    }
  
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }

  orderByImporte() {
    this.isSortedByFecha = "";
    this.isSortedByConcepto = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";
    if (this.isSortedByImporte != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (parseInt(n1.importe) > parseInt(n2.importe)) {
          return -1;
        }
        if (parseInt(n1.importe) < parseInt(n2.importe)) {
          return 1;
        }
        return 0;
      });
      this.isSortedByImporte = "chevron-button";
    } else if (this.isSortedByImporte == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (parseInt(n1.importe) > parseInt(n2.importe)) {
          return 1;
        }
        if (parseInt(n1.importe) < parseInt(n2.importe)) {
          return -1;
        }
        return 0;
      });
      this.isSortedByImporte = "chevron-button-up";
    }
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }
  orderByEstado() {
    this.isSortedByFecha = "";
    this.isSortedByImporte = "";
    this.isSortedByConcepto = "";
    this.isSortedByTipo = "";
    if (this.isSortedByEstado != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (n1.estado.nombre > n2.estado.nombre) {
          return -1;
        }
        if (n1.estado.nombre < n2.estadonombre) {
          return 1;
        }
        return 0;
      });
      this.isSortedByEstado = "chevron-button";
    } else if (this.isSortedByEstado == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (n1.estado.nombre > n2.estado.nombre) {
          return 1;
        }

        if (n1.estado.nombre < n2.estado.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button-up";
    }
    
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }
  orderByTipo() {
    this.isSortedByFecha = "";
    this.isSortedByImporte = "";
    this.isSortedByConcepto = "";
    this.isSortedByEstado = "";

    if (this.isSortedByTipo != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
  
        if (n1.tipoGasto.nombre > n2.tipoGasto.nombre) {
          return -1;
        }

        if (n1.tipoGasto.nombre < n2.tipoGasto.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByTipo = "chevron-button";
    } else if (this.isSortedByTipo == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
       
        if (n1.tipoGasto.nombre > n2.tipoGasto.nombre) {
          return 1;
        }

        if (n1.tipoGasto.nombre < n2.tipoGasto.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByTipo = "chevron-button-up";
    }
    
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }
  
  openSidebar() {
    var element = document.getElementById("sidebar");
    element.classList.add("active");
  }

  closeSidebar(e: any) {
    if (e.target.classList.value == "eye-button activated") {
    } else {
      var element_gasto = document?.getElementById("sidebar");
      var element = document?.getElementById("sidebar_ingreso");
      var elementV = document?.getElementById("sidebar_detalle");
      var element_modificar = document?.getElementById("sidebar_modificar");
      elementV?.classList.remove("active");
      element?.classList.remove("active");
      element_gasto?.classList.remove("active");
      element_modificar?.classList.remove("active");
    }
  }

  openSidebarIngreso() {
    var element = document.getElementById("sidebar_ingreso");

    element.classList.add("active");
  }

  closeSidebarIngreso() {
    var element_gasto = document.getElementById("sidebar");
    var element = document.getElementById("sidebar_ingreso");
    element.classList.remove("active");
    element_gasto.classList.remove("active");
  }

  openSidebarDetalles(gasto_ingreso, e: any) {
    var ojos = Array.from(
      document.getElementsByClassName(
        "eye-button"
      ) as HTMLCollectionOf<HTMLElement>
    );
    Array.from(ojos).forEach((ojo) => {
      ojo.style.cssText = "pointer-events:none";
    });
    this.mostrarGastoIngreso = true;
    var padre = e.target.parentElement;
    var ojo = e.target;
    padre.removeChild(ojo);
    var spinner = document.createElement("div");
    spinner.classList.add("spinner-border");
    spinner.classList.add("spinner-border-sm");
    padre.prepend(spinner);
    var element = document.getElementById("sidebar_detalle");

    this.gasto_ingreso = gasto_ingreso;
    this._ingresosGastosService.getByGastoId(gasto_ingreso.id).subscribe({
      next: (data) => {
        this.detalles = data["documentos"];
        this.isShowedGastoIngreso = true;
        padre.removeChild(spinner);
        padre.prepend(ojo);
        Array.from(ojos).forEach((ojo) => {
          ojo.style.cssText = "";
        });
        this.primeraVez = true;
        element?.classList.add("active");
      },
      error: (error) => {
        // console.log(error);
      },
    });
  }

  closeSidebarDetalles() {
    var element = document.getElementById("sidebar_detalle");
    element.classList.remove("active");
  }

  nextPage(event: PageEvent) {
    const request = {};
    request["page"] = event.pageIndex;
    this.pageIndex= event.pageIndex
    request["size"] = event.pageSize;
    this.pageSize=event.pageSize
    this.getIngresosListByUsuario(request);
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  getFirstLetter(nombre: string) {
    if(nombre!= null){
    return nombre.charAt(0).toUpperCase();}
    else 
    return "R"

  }

  fecha() {
    const d = new Date();

    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    this.fecha_actual = [day, month, year].join("-");
  }

  deleteGastoIngreso(gasto_ingreso, e: any) {
    this.isDelete = true;

    let detalles_id_gestor = [];
    this.gasto_ingreso= gasto_ingreso
    if(this.gasto_ingreso.estado.nombre == "PENDIENTE"){

    this._ingresosGastosService
      .getByGastoId(gasto_ingreso.id)
      .pipe(
        finalize(() => {
   
          this.detalles.forEach((detalle) =>
            detalles_id_gestor.push(detalle.id_gestor)
          );
          this._ingresosGastosService
            .DeleteFile(detalles_id_gestor)
            .pipe(
              finalize(() => {
          

                this._ingresosGastosService
                  .deleteGastoIngreso(gasto_ingreso.id)
                  .subscribe({
                    next: (data) => {
                      this.getIngresosListByUsuario({
                        page: this.pageIndex,
                        size: this.pageSize,
                      });

                      this._notificationSvc.success(
                        "Hecho",
                        "Borrado correctamente"
                      );
                      this.isDelete = false;

                    },
                    error: (err) => {
                      this.isDelete=false
                      this._notificationSvc.error(
                        "ERROR",
                        "No se ha podido borrar  el ingreso/gasto"
                      );
                    },
                  });
              })
            )
            .subscribe({ next: (data) => {}, error: (err) => {} });
        })
      )
      .subscribe({
        next: (data) => {
          this.detalles = data["documentos"];
        },
        error: (err) => {},
      });
    }else{
      this.isDelete=false
      this._notificationSvc.info(
        "Aviso!",
        "No es posible modificar un gasto completado",
        2000
      );
    }
  }

  


  openSidebarModificar(gasto_ingreso2, e: any) {
    var spinner = document.createElement("div");
    spinner.classList.add("spinner-border");
    spinner.classList.add("spinner-border-sm");
    var element = document.getElementById("sidebar_modificar");

    this.gasto_ingreso2 = gasto_ingreso2;
    if(this.gasto_ingreso2.estado.nombre == "PENDIENTE"){

    this._ingresosGastosService
      .getByGastoId(gasto_ingreso2.id)
      .subscribe({
        next: (data) => {
          this.detalles2 = data["documentos"];
         // console.log("detalles 2 ->" +this.detalles2)
        },
        error: (error) => {
         // console.log(error);
        },
      })
      .add(() => {
        this.primeraVezM = true;

        element?.classList.add("active");
      });
    }else{
      this._notificationSvc.info(
        "Aviso!",
        "No es posible modificar un gasto completado",
        2000
      );
    }
  }

  downloadFile(idarch: String, idgasto: number) {
    this._ingresosGastosService
      .getByGastoId(idgasto)
      .pipe(
        finalize(() =>
          /* if( this.detalles[0]== undefined){
          throw this._notificationSvc.error('ERROR', "No se ha podido descargar el documento", 2000);
        }*/
          this.detalles.forEach((detalle) =>
            this.nextCloudService
              .downloadJustificanteB64(detalle.gasto_id)
              .subscribe({
                next: (fichero) => {
                 // console.log(fichero);
                  const linkSource =
                    "data:application/pdf;base64," + fichero.Result;
                  const downloadLink = document.createElement("a");
                  const fileName = detalle.nombre;

                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();
                  this._notificationSvc.success(
                    "Descargando",
                    "Descarga realiza con exito",
                    2000
                  );
                },
                error: (err) => {
                 // console.log(err);
                  this._notificationSvc.error(
                    "ERROR",
                    "No se ha podido descargar el documento",
                    2000
                  );
                },
              })
          )
        )
      )
      .subscribe({
        next: (data) => {
          this.detalles = data["documentos"];
        },
        error: (err) => {},
      });
  }
  showEditDeleteError(error, estado){
    if(estado == 'COMPLETADO'){
      this._notificationSvc.info(
        "Aviso!",
        "No se ha podido " + error + " el gasto/ingreso, es un gasto/ingreso completado",
        2000
      );
    }
    else{
      this._notificationSvc.info(
        "Aviso!",
        "No se ha podido " + error + " el gasto/ingreso, es una factura emitida",
        2000
      );
    }
  }
  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getPresupuestos();
  }

  getPresupuestos() {
    let rol = this._tokenStorageService.getRol();

    if (rol == 4) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this._ingresosGastosService
      .getGastoIngresoFilter(this.request)
      .subscribe((data: any) => {
        this.gastos_ingresos = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
      });
  }
  
  sortArrayInitialize(){
    this.sortArray = {fechaCreacion:'none', concepto:'none', importe:'none', estado:'none', tipoGasto:'none'};
  }

  changeSorts(key, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = key instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of key){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(key);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    this.getPresupuestos();
  }
}
