import { formatDate } from "@angular/common";
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PresupuestoService } from 'src/app/_services/autoescuela/presupuesto.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-gestion-presupuestos',
  templateUrl: './gestion-presupuestos.component.html',
  styleUrls: ['./gestion-presupuestos.component.css']
})
export class GestionPresupuestosComponent {
  @Input() comprador: any;
  @Input() vendedor: any;
  @Output() newItemEvent = new EventEmitter<boolean>();

  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _presupuestoService: PresupuestoService,
    private _tokenStorageService: TokenStorageService,
    private _notificationService: NotificationService,
    private _router: Router
  ) { }

  usuario = this._tokenStorageService.getUser();

  page = "0";
  size = "5";

  filters: any[] = [];
  sorts: any[] = [];
  firstTime: boolean = false;
  userLogged: any;

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };

  totalElements: any;

  mostrarFormulario: boolean = false;
  editarFormulario: boolean = false;
  mostrarPag: boolean = true;
  isDelete: boolean = false;
  userAdmin: boolean = false;
  selectPresupuesto: boolean = false;
  viewPresupuestos: boolean = false

  presupuestos: any = [];
  infoPresupuestoEnv: any;
  presupuestosInit: any = [];
  sortArray: any = {};
  paymentData: any = {};

  ngOnInit(): void {
    let rol = this._tokenStorageService.getRol();
    if (rol == 3 || rol == 4 || rol == 99) {
      this.userAdmin = true;
    }
    var url = window.location.href;
    var urlVars = url.split('?');
    console.log(urlVars)
    window.history.pushState(null, urlVars[0], urlVars[0])
    if(urlVars.length>1){
      this.filterVariables(urlVars[1])
    }
    else{
      this.getPresupuestos();
    }

    this.getPresupuestos();
    this.sortArrayInitialize();

  }

  public getPaginatedPresupuestoListByUsuario(request) {
    this._presupuestoService
      .getPaginatedPresupuestoListByUsuario(this.usuario.id, request)
      .subscribe({
        next: (data) => {
          this.presupuestos = data["content"];
          this.presupuestosInit = data["content"];

          this.totalElements = data["totalElements"];
        },
        error: (error) => {
          this.presupuestos = null;
          // console.log(error);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.firstTime == false) {
      this.viewPresupuestos = false;
      this.userLogged = this._tokenStorageService.getUser();
      //console.log(this.userLogged.rol);
      if (this.userLogged.rol.indexOf(2) > -1) {
        this.userAdmin = true;
      }
      if (this.comprador != undefined) {
        this.filters = [];
        this.selectPresupuesto = true;
        this.filter(this.comprador.dni, 'cliente.dni', 'LIKE', 'STRING');
        console.log(this.presupuestos);
        console.log(this.comprador.nombre);
        this.filter(this.comprador.nombre, 'cliente.nombre', 'LIKE', 'STRING');
        console.log(this.presupuestos);

        this.filter("ACEPTADO", 'estado.nombre', 'LIKE', 'STRING')

        this.viewPresupuestos = true;

      }

    }
    else {
      this.firstTime = false
    }


  }



  openPDF(presupuesto) {
    this._presupuestoService.downloadJustificanteB64(presupuesto.documento.id_gestor_documental)
      .subscribe({
        next: (documento) => {
          const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        },
        error: (error) => {
          this._notificationService.error('Error', 'No se ha podido abrir el archivo', 2000);
        }
      })

  }

  nextPage(event: PageEvent) {
    this.page = event.pageIndex.toString();
    this.size = event.pageSize.toString();
    this.getPresupuestos();
  }

  setMostrarFormulario(mostrar) {
    this.mostrarFormulario = mostrar;
  }



  deletePresupuesto(presupuesto, e: any, dialog) {
    this.isDelete = true;

    if (presupuesto.estado.nombre != "RECHAZADO") {
      this._presupuestoService.delete(presupuesto.id).subscribe({
        next: (data) => {
          this._notificationService.success(
            "Exito",
            "El gasto se ha borrado correctamente",
            2000
          );
          this.isDelete = false;
          dialog.close();
          this.getPaginatedPresupuestoListByUsuario({ page: "0", size: "5" });
        },
        error(error) {
          this._notificationService.warning(
            "Aviso!",
            "El gasto no se ha podido borrar",
            2000
          );
          this.isDelete = false;
          dialog.close();
          this.getPaginatedPresupuestoListByUsuario({ page: "0", size: "5" });
        }
      })

    }
    else if (presupuesto.estado.nombre == "Rechazado") {
      this._notificationService.info(
        "Aviso!",
        "No es posible modificar un presupuesto rechazado",
        2000
      );
    }
    else {
      this._notificationService.info(
        "Aviso!",
        "No es posible modificar un ingreso \ngenerado a partir de una factura",
        2000
      );
    }
  }

  setEditarPresupuestoSend(mode: boolean, presupuesto) {
    this.editarFormulario = mode;
    this.infoPresupuestoEnv = { presupuesto: presupuesto, detalle: presupuesto.detalle, generateItp: undefined, cliente: presupuesto.cliente };
  }

  setMostrarFormularioChild() {
    this.setMostrarFormulario(false);
    this.getPresupuestos();
  }

  setEditarFormularioChild() {
    this.editarFormulario = false;
    this.getPresupuestos();
  }

  downloadInvoice(presupuesto) {
    if(presupuesto.factura){
      this._presupuestoService
        .downloadZip(presupuesto.id)
        .subscribe({
          next: (data) => {
            if (data == null) {
              this._notificationService.warning('Alerta', "No tiene contenido", 2000);
            } else {
              var file = new Blob([data], { type: 'application/zip' })
              var fileUrl = URL.createObjectURL(file)
              var a = document.createElement('a')
              a.href = fileUrl
              a.download = "Presupuesto_" + presupuesto.codigoPresupuesto + ".zip"
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              this._notificationService.success(
                "Hecho",
                "Documentos descargados con éxito",
                2000
              );
            }
          },
          error: (error) => {
            //console.log(error);
          },
        });
    }
    else{
      let documento= presupuesto.documento;
      this._presupuestoService
        .downloadJustificanteB64(documento.id_gestor_documental)
        .subscribe({
          next: (fichero) => {
            const linkSource = "data:application/pdf;base64," + fichero.Result;
            const downloadLink = document.createElement("a");
            const fileName = documento.nombre_documento;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            this._notificationService.success(
              "Hecho",
              "Pdf descargado con éxito",
              2000
            );
          },
          error: (error) => {
            //console.log(error);
          },
        });
    }
    
  }

  getDateToFilter(fechas) {
    var fechaString = new Date(fechas);
    let month = "" + (fechaString.getMonth() + 1);
    let day = "" + fechaString.getDate();
    const year = fechaString.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  showEditDeleteError(error) {
    this._notificationService.info(
      "Aviso!",
      "No se puede " + error + " el presupuesto",
      2000
    );
  }

  doFilter(event, type, operator, field_type) {

    let value = event.target.value;
    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, 'dd-MM-yyyy', 'en-US');
      }
      let index = this.filters.findIndex((item) => item.key == type);
      let newFilter = {
        key: type,
        operator: operator,
        field_type: field_type,
        value: value,
      };
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      this.filters = this.filters.filter((item) => item.key !== type);
    }
    this.page = "0";
    this.size = "5";
    console.log("los filtros son", this.filters);
    this.getPresupuestos();
  }

  getPresupuestos() {
    let rol = this._tokenStorageService.getRol();

    if (rol == 4) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this._presupuestoService
      .getPresupuestoFilter(this.request)
      .subscribe((data: any) => {
        this.presupuestos = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
      });
  }

  async filter(originalValue, type, operator, field_type) {

    let value = originalValue;
    console.log(value)
    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, 'dd-MM-yyyy', 'en-US');
      }
      let index = this.filters.findIndex((item) => item.key == type);
      let newFilter = {
        key: type,
        operator: operator,
        field_type: field_type,
        value: value,
      };
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      this.filters = this.filters.filter((item) => item.key !== type);
    }
    this.page = "0";
    this.size = "5";
    console.log("los filtros son", this.filters);
    await this.getFirstPresupuestos();

  }

  getFirstPresupuestos() {
    let rol = this._tokenStorageService.getRol();

    if (rol == 4) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this._presupuestoService
      .getPresupuestoFilter(this.request)
      .subscribe((data: any) => {
        this.presupuestos = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
        return true;
      });
  }


  selectedPresupuesto(presupuesto) {
    this.newItemEvent.emit(presupuesto);
  }

  sortArrayInitialize(){
    this.sortArray = {codigoPresupuesto:'none', fecha:'none', nombre:'none', nombreEmpresa:'none', dni:'none', cif:'none',estado:'none', baseImponible:'none', importeIva:'none', importeIrpf: 'none', total: 'none'};
  }

  changeSorts(key, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = key instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of key){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(key);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){      
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    console.log(this.sorts)
    this.getPresupuestos();
  }
  
  filterVariables(vars){
    var DS_variables = vars.split("&");
    console.log(DS_variables)
    DS_variables[0] = DS_variables[0].replace('Ds_SignatureVersion=', '');
    DS_variables[1] = DS_variables[1].replace('Ds_MerchantParameters=', '').replace("%3D0", '');
    DS_variables[2] = DS_variables[2].replace('Ds_Signature=', '');
    const paymentRespVars = {
      Ds_SignatureVersion: DS_variables[0],
      Ds_MerchantParameters: DS_variables[1],
      Ds_Signature: DS_variables[2]
    }
    this._presupuestoService.getPaymentResponse(paymentRespVars).subscribe((data) => {
      console.log(data)
      var statusText = "ha sido pagada correctamente."
      if(data['status'] == 'unpaid'){
        statusText = "no se ha logrado pagar."
      }
      if(data['status'] == 'paid'){
        console.log(data['presu'].id_tramite, data['presu'])
        let info = data;
        this._presupuestoService.asociarTramiteAPresupuesto
        (data['presu'].id_tramite, data['presu'].id, data['presu'].tramite.id).subscribe(
          (data) => { }
          , (error) => { this.paymentData = {
            codPresu: info['presu'].codigoPresupuesto,
            orderId: info['orderId'],
            status: info['status'],
            statusText: statusText};
            const dialogPayment = document.getElementById("dialogPayment") as HTMLDialogElement;
            dialogPayment.showModal();
            this.getPresupuestos(); }
        )
        this.paymentData = {
          codPresu: data['presu'].codigoPresupuesto,
          orderId: data['orderId'],
          status: data['status'],
          statusText: statusText};
          const dialogPayment = document.getElementById("dialogPayment") as HTMLDialogElement;
          dialogPayment.showModal();
          this.getPresupuestos();
      }

    })
  }

}
