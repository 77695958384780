import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class IngresosGastosService {

  API_URL: string = environment.API_URL;
  private resourceUrl: string = `${environment.API_URL}/gasto`;

  constructor(
    private http: HttpClient,
  ) { }

  getIngresoGastoList() {
    return this.http.get(`${this.resourceUrl}/list`,)
  }

  getIngresoGastoListbyId(id) {
    return this.http.get(`${this.resourceUrl}/list/${id}`,)
  }

  getIngresoGastoListByUsuario(request, id:number): Observable<any> {
    const params = request;
    return this.http
    .get<any>(`${this.resourceUrl}/getGastoIngresoByIdUsuarioPaginate/${id}`,{params})
    .pipe(retry(1), catchError(this.handleError));
  }

  getIngresoGastoListPaginate(request){
    const params = request;
    return this.http
      .get(`${this.resourceUrl}/listPaginate`, {params});
  }

  getTotalFacturado(id: number){
    return this.http.get(`${this.resourceUrl}/calcularTotal/${id}`)
  }

  getDocumentosIngresoGasto(id:number): Observable<any> {
    return this.http
    .get<any>(`${this.resourceUrl}/find/${id}`)
    .pipe(retry(1), catchError(this.handleError));
  }

  getByGastoId(id:number): Observable<any>{
    
    return this.http
    .get<any>(`${this.resourceUrl}/getGastoById/${id}`)
    .pipe(retry(1), catchError(this.handleError));
  }


  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }


  deleteGastoIngreso(id){
/*     console.log("estoy en deletegasto")
 */    return this.http.post(this.API_URL +"/gasto/deleteGastoIngreso", id);
  }

  
  DeleteFile(detalles: any) {
    const datos = {
      id_gestor: detalles
    }
  /*   console.log(datos);
  console.log("estoy en deletefile") */
  return this.http.post(`${this.API_URL}/gasto/deleteFile`, datos);
    
  }

  getByFacturaId(id:number): Observable<any>{
    
    return this.http
    .get<any>(`${this.resourceUrl}/findByFacturaId/${id}`)
    .pipe(retry(1), catchError(this.handleError));
  }

  getGastoIngresoFilter(request: any){
    return this.http.post(`${this.resourceUrl}/filter`, request);
  }
}




