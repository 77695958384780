import { Injectable } from '@angular/core';
import { imagenes } from './constantes';

@Injectable({
  providedIn: 'root'
})
export class PdfGenerateHencokService {

  constructor() { }

  usuarioRemitente: object = {};
  usuarioDestinatario: object = {};


  formFactura: any = {};

  iva: string = "";
  subtotal: string = "";
  cantidad_total: string = "";
  watermark: string = "";
  numero_factura: string = "";

  userAdmin: Boolean = false;

  public async generateFacturaCompleta(currentUser, usuarioDestinatario, formularioFactura, iva, subtotal, cantidad_total, watermark, numero_factura, userAdmin): Promise<{}> {

    this.usuarioRemitente = currentUser;
    this.usuarioDestinatario = usuarioDestinatario;

    this.formFactura = formularioFactura;

    this.iva = iva;
    this.subtotal = subtotal;
    this.cantidad_total = cantidad_total;
    this.watermark = watermark;
    this.numero_factura = numero_factura;

    this.userAdmin = userAdmin;

    return this.generateFactura();
  }

  public generateFactura(): object {

    return {
      watermark: this.watermark,
      content: [
        {
          text: ' ',
        },
        {
          text: ' ',
        },
        {
          table: {
            headerRows: 2,
            widths: ['*', '*'],
            body: [
              [{ text: 'FACTURA', style: 'titleInvoice' }, this.addlogo()],
            ]
          },
          layout: 'noBorders'
        },
        this.crearTablaRemitente(),
        {
          text: ' ',
        },
        {
          text: ' ',
        },
        this.crearTablaDestinatario(),
        {
          text: ' ',
        },
        this.crearTablaDetalleFactura(),
        {
          text: ' ',
        },
        this.crearTablaCalculos(),
      ],
      styles: {
        tableHeader: {
          fontSize: 14,
        },
        bodyUser: {
          fontSize: 11,
          marginTop: 10,
        },
        titleInvoice: {
          margin: [30, 50, 0, 0],
          fontSize: 34,
          bold: true,
          color: '#275B55'
        },
        headersInvoice: {
          fontSize: 12,
          alignment: 'center',
          color: '#275B55',
        }
      }
    }
  }

  public addlogo(): object {
    if (this.userAdmin) {
      return {
        image: imagenes.logohencok,
        width: 150,
        alignment: 'right',
        margin: [0, 30, 0, 0]
      }
    } else {
      return {
        text: ' ',
      }
    }
  }

  public crearTablaRemitente(): Object {
    var tabla =
    {
      table: {
        headerRows: 1,
        widths: ['180', '*', '*'],
        body: [
          [{ text: [{ text: this.usuarioRemitente['nombre'] }, ' ', this.usuarioRemitente['apellido1'], ' ', this.usuarioRemitente['apellido2']], alignment: 'justify', fontSize: 10, bold: true, margin: [50, 0, 0, 0], }, {}, {}],
          [{ text: [{ text: this.usuarioRemitente['numero_documento'] }], alignment: 'justify', fontSize: 10, bold: true, margin: [50, 0, 0, 0] }, {}, {}],
          [{ text: [{ text: this.usuarioRemitente['direcciones'] ? this.usuarioRemitente['direcciones'][0]['name_road'] : '' }, ' ', this.usuarioRemitente['direcciones'] ? this.usuarioRemitente['direcciones'][0]['number_road'] : ''], alignment: 'justify', fontSize: 10, margin: [50, 0, 0, 0] }, {}, {}],
          [{ text: [{ text: this.usuarioRemitente['direcciones'] ? this.usuarioRemitente['direcciones'][0]['postal_code'] : '' }, ' ', this.usuarioRemitente['direcciones'] ? this.usuarioRemitente['direcciones'][0]['province']['nombre'] : ''], alignment: 'justify', fontSize: 10, margin: [50, 0, 0, 0] }, {}, {}]
        ]
      },
      layout: 'noBorders'
    }
    return tabla;
  }

  public crearTablaDestinatario(): Object {
    var tabla =
    {
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        body: [
          [{}, {}],
          [{
            stack: [
              [{ text: 'FACTURAR A', fontSize: 12, alignment: 'justify', bold: true, color: '#275B55', margin: [50, 0, 0, 0] }],
              [{ text: [{ text: this.usuarioDestinatario['nombre'] }, ' ', this.usuarioDestinatario['apellido1'], ' ', this.usuarioDestinatario['apellido2']], alignment: 'justify', fontSize: 10, margin: [50, 0, 0, 0] }],
              [{ text: [{ text: this.usuarioDestinatario['numero_documento'] ? this.usuarioDestinatario['numero_documento'] : this.usuarioDestinatario['documento'] }], alignment: 'justify', fontSize: 10, margin: [50, 0, 0, 0] }],
              [{ text: [{ text: this.usuarioDestinatario['direcciones'] ? this.usuarioDestinatario['direcciones'][0]['name_road'] : this.usuarioDestinatario['domicilio'] ? this.usuarioDestinatario['domicilio'] : '' }, ' ', this.usuarioDestinatario['direcciones'] ? this.usuarioDestinatario['direcciones'][0]['number_road'] : this.usuarioDestinatario['domicilio'] ? this.usuarioDestinatario['numero_direccion'] : ''], alignment: 'justify', fontSize: 10, margin: [50, 0, 0, 0] }],
              [{ text: [{ text: this.usuarioDestinatario['direcciones'] ? this.usuarioDestinatario['direcciones'][0]['postal_code'] : this.usuarioDestinatario['codigo_postal'] ? this.usuarioDestinatario['codigo_postal'] : '' }, ' ', this.usuarioDestinatario['direcciones'] ? this.usuarioDestinatario['direcciones'][0]['province']['nombre'] : this.usuarioDestinatario['localidad'] ? this.usuarioDestinatario['localidad'] : ''], alignment: 'justify', fontSize: 10, margin: [50, 0, 0, 0] }]
            ],
            margin: [0, 0, 90, 0]
          },
          {
            table: {
              body: [
                [{ text: 'Nº DE FACTURA', fontSize: 12, color: '#275B55', bold: true }, { text: this.numero_factura, fontSize: 10 }],
                [{ text: 'Fecha emisión', color: '#275B55', bold: true }, { text: this.formFactura.fecha_factura, fontSize: 10 }],
                [{ text: 'Fecha vencimiento', color: '#275B55', bold: true }, { text: this.formFactura.fecha_vencimiento, fontSize: 10 }],
              ]
            },
            margin: [60, 0, 0, 0],
            layout: 'noBorders'
          }
          ],
        ]
      },
      layout: 'noBorders'
    }
    return tabla;
  }

  public crearTablaDetalleFactura(): Object {

    var columnaCantidad: object[] = [];
    var columnaConcepto: object[] = [];
    var columnaPrecio: object[] = [];
    var columnaDescuento: object[] = [];
    var columnaCantidadNeta: object[] = [];
    var columnaIva: object[] = [];
    
    var iva= 0.00;

    for (var detalle of this.formFactura.detallesFactura) {
      iva = Number.parseFloat((detalle.cantidadNeta * (detalle.tipoIva / 100)).toFixed(2));

      columnaCantidad.push({ alignment: 'center', style: 'bodyUser', text: detalle.cantidad ? detalle.cantidad : ' ' });
      columnaConcepto.push({ alignment: 'center', style: 'bodyUser', marginTop: 10, text: detalle.concepto ? detalle.concepto : ' ' });
      columnaPrecio.push({ alignment: 'center', style: 'bodyUser', marginTop: 10, text: detalle.precio + '€' ? detalle.precio : ' ' });
      columnaDescuento.push({ alignment: 'center', style: 'bodyUser', marginTop: 10, text: detalle.descuento + '€' ? detalle.descuento : ' ' });
      columnaCantidadNeta.push({ alignment: 'center', style: 'bodyUser', marginTop: 10, text: detalle.cantidadNeta + '€' ? detalle.cantidadNeta : ' ' });
      columnaIva.push({ alignment: 'center', style: 'bodyUser', marginTop: 10, text: detalle.tipoIva ? iva + '€' : ' ' });
    }

    var tabla =
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*'],
        body: [
          [{ text: 'CANT.', style: 'headersInvoice' }, { text: 'CONCEPTO', style: 'headersInvoice' }, { text: 'PRECIO UNITARIO', style: 'headersInvoice' }, { text: 'DESCUENTO', style: 'headersInvoice' }, { text: 'IMPORTE', style: 'headersInvoice' }, { text: 'IVA', style: 'headersInvoice' }],
          [columnaCantidad, columnaConcepto, columnaPrecio, columnaDescuento, columnaCantidadNeta, columnaIva]
        ],
      },
      layout: 'headerLineOnly',
      margin: [20, 0, 0, 0],
    }
    return tabla;
  }

  public crearTablaCalculos(): Object {

    var tabla =
    {
      table: {
        headerRows: 3,
        widths: ['*', '*', '*'],
        body: [
          [{}, {}, [
            {
              table: {
                body: [
                  [{ text: 'Subtotal: ', fontSize: 12, bold: true }, { text: this.subtotal + '€' }],
                  [{ text: 'IVA: ', fontSize: 12, bold: true }, { text: this.iva + '€' }],
                  [{ text: 'Cantidad total: ', fontSize: 12, bold: true }, { text: this.cantidad_total + '€' }],
                ],
                layout: 'noBorders'
              },
              layout: 'noBorders'
            }
          ]],
        ],
      },
      layout: 'noBorders'
    }
    return tabla;
  }
}
