
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimalPrecision' })
export class PrecisionPipe implements PipeTransform {
  transform(value: number): number {

    return Math.round((value + Number.EPSILON) * 100) / 100

  }
}