import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { InfoService } from 'src/app/_services/info.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';
import { RaizComponent } from 'src/app/raiz/raiz.component';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { GastosService } from 'src/app/_services/hencok/gastos.service';
import { TipoGasto } from 'src/app/model/tipoGasto';
import { DatePipe } from '@angular/common';
import { IngresosGastosService } from 'src/app/_services/hencok/ingresos-gastos.service';
import { GestionIngresosGastosComponent } from '../gestion-ingresos-gastos/gestion-ingresos-gastos.component';

@Component({
  selector: 'app-visualizar-gastos-ingresos',
  templateUrl: './visualizar-gastos-ingresos.component.html',
  styleUrls: ['./visualizar-gastos-ingresos.component.css']
})
export class VisualizarGastosIngresosComponent {
  form: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    fecha_creacion: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_gasto: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),

  },);

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  submitted: boolean = false;
  register2 = false;
  InfoRegistro: any = [];
  environment = environment;
  isLoggedIn = false;
  arrayDocuments = [];
  gastos: any = [];
  usuarios: any[] = [];
  usuario: any = -1;
  fechaActual: string;
  @Input() detallesP: any;
  @Input() detalles: any;
mostrado:any = false;
  @Input() primeraVez: boolean;
  auxiliar:boolean = false;
    
pdfAuxiliar:string;
  userLogged : any;
  userAdmin: Boolean = false;
  name = 'Angular 5';
  load: boolean;

  uploadDocuments: Boolean = false;


  constructor(
    private _notificationSvc?: NotificationService,
    private _router?: Router,
    private _ingresosGastosService?: IngresosGastosService,
    private raiz?: RaizComponent,
    private tokenStorageService?: TokenStorageService,
    private gastoservice?: GastosService,
    public datepipe?: DatePipe,

    private _serviceGeneral?: ServicioGeneral
  ) { }

  adviseError: boolean = false;
  typeError: boolean = false;

  ngOnInit(): void {
 
    // this.detalles.forEach(element => {
    //   this.sacarUrlPdf(element);
    // });

   
    let date = new Date()
    this.fechaActual = this.formatDate2(date);

    this.isLoggedIn = !!this.tokenStorageService.getToken();

    this.userLogged = this.tokenStorageService.getUser();
    let rol = this.tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }

    this.register2 = false;
    this.getTipoGasto();
    this.getUserList();
  

  }

  sacarPrimerEmbed(detalle){
   
    let meterPrimero = true;
    if(this.auxiliar ){
this.auxiliar = false;
    }else{
      var lista = Array.from(document.getElementById('listaFacturas').children as HTMLCollectionOf<HTMLElement>)
    Array.from(lista).forEach((list) => {
        if(list.firstElementChild.firstElementChild.nextElementSibling.children.length > 0){
          meterPrimero = false;
        }else{
  
       }
      })
       if(meterPrimero){
        if(lista[0].firstElementChild.firstElementChild.nextElementSibling.children.length > 0){

        }else{
          let nombre = lista[0].firstElementChild.firstElementChild.firstElementChild.textContent;
          if(nombre.trim() == detalle.nombre.trim()){
            var padreEmbed = lista[0].firstElementChild.firstElementChild.nextElementSibling;
            if(padreEmbed.children.length > 0){
    
            }else{
              
            }
            var base64Data = detalle.base64
            var binaryData = atob(base64Data);
            var length = binaryData.length;
            var uint8Array = new Uint8Array(length);
            for (let i = 0; i < length; i++) {
              uint8Array[i] = binaryData.charCodeAt(i);
              
            }
         
            var embed = document.createElement('embed');
            switch (detalle.mime) {
              case "application/pdf":
                embed.setAttribute("type",'application/pdf');
                var blob = new Blob([uint8Array], {type: 'application/pdf'});
                
                break;
                case "image/jpeg":
                  embed.setAttribute("type",'image/jpeg');
                  var blob = new Blob([uint8Array], {type: 'image/jpeg'});
                  
                  break;
                  
                  case "image/png":
                embed.setAttribute("type",'image/png');
            var blob = new Blob([uint8Array], {type: 'image/png'});
                
                break;
                
                default:
                  break;
                }
                var pdfUrl = URL.createObjectURL(blob)
                //this.pdfAuxiliar = pdfUrl
                embed.setAttribute("src",pdfUrl);
            embed.setAttribute("width","500px");
            embed.setAttribute("height","600px");
            padreEmbed.appendChild(embed);
          
          }else{
    
          }
        }
        
       }
  

    
    }
   
  
  }
  sacarEmbed(detalle,e:any){
        this.clearAllDetalles();
var padre = e.target.parentElement.nextElementSibling;
var base64Data = detalle.base64
var binaryData = atob(base64Data);
var length = binaryData.length;
var uint8Array = new Uint8Array(length);
for (let i = 0; i < length; i++) {
  uint8Array[i] = binaryData.charCodeAt(i);
  
}

var embed = document.createElement('embed');
switch (detalle.mime) {
  case "application/pdf":
    embed.setAttribute("type",'application/pdf');
    var blob = new Blob([uint8Array], {type: 'application/pdf'});
    
    break;
    case "image/jpeg":
      embed.setAttribute("type",'image/jpeg');
      var blob = new Blob([uint8Array], {type: 'image/jpeg'});
      
      break;
      
      case "image/png":
    embed.setAttribute("type",'image/png');
var blob = new Blob([uint8Array], {type: 'image/png'});
    
    break;
    
    default:
      break;
    }
    var pdfUrl = URL.createObjectURL(blob)
    //this.pdfAuxiliar = pdfUrl
    embed.setAttribute("src",pdfUrl);
embed.setAttribute("width","400px");
embed.setAttribute("height","400px");
padre.appendChild(embed);

  }

  clearAllDetalles(){
    var lista = Array.from(document.getElementById('listaFacturas').children as HTMLCollectionOf<HTMLElement>)
    Array.from(lista).forEach((factura) => {
     if(factura.firstElementChild.firstElementChild.nextElementSibling.children.length > 0){
      factura.firstElementChild.firstElementChild.nextElementSibling.innerHTML = "";
     }
    })
    this.primeraVez = true;
    this.auxiliar = true;
  }

  onFileChange(event) {
    const file = event.target.files[0];
    this.convertFile(file).subscribe(base64 => {
      let document = { nombre: event.target.files[0].name, mime: event.target.files[0].type, base64: base64 }
      this.arrayDocuments.push(document);
      this.form.controls['documento'].setValue(this.arrayDocuments);

    });
  }
  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => {
       // console.log(error);
      }
    })
  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id)
      .subscribe({
        next: (usuario) => {
          this.usuario = usuario['Usuario'];

        },
        error: (error) => {
         // console.log(error);
        }
      });
  }
  async onSubmit(): Promise<any> {
    
    this.register2 = false;
    this.form.controls['fecha_creacion'].setValue(this.datepipe.transform(this.form.controls['fecha_creacion'].value, 'dd/MM/yyyy'));
   // console.log("save",this.form.controls);
    
    if (this.form.controls['documento'].value === "") {
     // console.log("documento invalido");
      
      this.form.controls['documento'].setValue([]);
      this._notificationSvc.warning('Informacion', 'El documento es obligatorio', 2000);
      return false;
    }
    this.form.addControl('tipo_transaccion', new UntypedFormControl);
    this.form.controls['tipo_transaccion'].setValue('gasto');
    this.form.addControl('usuario_id', new UntypedFormControl);
    
    
    if(this.userAdmin){
      if (this.usuario.id == null ) {
        this._notificationSvc.warning('Informacion', 'El usuario es obligatorio', 2000);
        return false;
      }
      this.form.controls['usuario_id'].setValue(this.usuario.id);
    } else {
      
      this.form.controls['usuario_id'].setValue(this.userLogged.id);
    }
    
    if (!this.form.valid) {
     // console.log("form invalid")
      this._notificationSvc.warning('Informacion', 'Rellene todos los campos por favor', 2000);
      return false;

    } else {
      this.submitted = true;
      this.uploadDocuments = true;
      this.gastoservice.saveGasto(this.form.value).subscribe({
        next: (data) => {
          this.submitted = true;
          this._router.navigateByUrl('/admin-hencok').then(() => {
            this._router.navigate(['admin-hencok/gastos-ingresos']);
          });
          this._notificationSvc.success('Hecho', "Ingreso guardado con exito", 2000);
        },
        error: (err) => {
          this.uploadDocuments=false;
         // console.log(err);

        }
      })
    }
  }

  subirArchivo(): any {
    try {
      const formularioDatos = new FormData();
      this.InfoRegistro.forEach(subirArchivo => {
        subirArchivo.append('files', subirArchivo)
      })
      this.InfoRegistro.post(environment.API_URL + `/`, this.InfoRegistro)
      //this.InfoRegistro.post(`http://localhost:7090/`, this.InfoRegistro)
        .subcribe(res => {

        })
    } catch (e) {
     // console.log('ERROR', e);
    }
  }

  openPdf(detalle: any){
    var pdf_newTab = window.open("");

    pdf_newTab.document.write(
    
        "<html><head><title>Prueba</title></head><body><iframe title='MY title'  width='100%' height='100%' src='data:application/pdf;base64, " +  detalle.base64+ "'></iframe></body></html>"
    
    );  
}


  formatDate(date) {
    let month = '' + date[1]
    let day = '' + date[2]
    const year = date[0]
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('/');
  }
  formatDate2(date: Date){
    let month = '' + date.getMonth();
    let day = '' + date.getDate()
    const year = date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return day + "/" + month + "/" + year;
  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }


  upload(): void {
    //get image upload file obj;
  }

  getTipoGasto() {
    return this.gastoservice.getTipoGasto().subscribe({
      next: (data) => {
        this.gastos = data;

      },
    })
  }

  removeFile(index: number): void {
    this.arrayDocuments.splice(index, 1);
  }

  reloadToMenuOption() {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/admin-hencok/gastos-ingresos'], { queryParams: { action: 2 } });

  }

  closeSidebar(){
    GestionIngresosGastosComponent.closeSidebarClienteRemoto();
  }

}
