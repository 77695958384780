<form  [formGroup]="formDirecciones" (ngSubmit)="updateDirection()"  class="p-3">
    <h5 class="mt-3 w-100">Dirección {{index+1}}</h5>
    <div class="row w-100 mt-3">
        <div class="col-sm-4 mt-3">
            <label>Tipo de vía</label>
            <select formControlName="tipovia" class="form-control" [ngClass]="{'is-invalid': submitted && this.formDirecciones.controls['tipovia'].errors}">
                <option *ngFor="let vias of TVias" [value]="vias.id">
                    {{ vias.tipovianormalizado }}
                </option>
            </select>
            <div *ngIf="submitted && this.formDirecciones.controls['tipovia'].errors" class="invalid-feedback">
                Debes seleccionar un campo.
            </div>
        </div>
        <div class="col-sm-8 mt-3">
            <label>Nombre de la vía</label>
            <input type="text" formControlName="nombrevia" class="form-control" [ngClass]="{'is-invalid': submitted && this.formDirecciones.controls['nombrevia'].errors}"/>
            <div *ngIf="submitted && this.formDirecciones.controls['nombrevia'].errors" class="invalid-feedback">
                El nombre de la vía es obligatorio.
            </div>
        </div>
    </div>
    <div class="row w-100 mt-3">
        <div class="col-sm-6 mt-3">
            <label>Número de la vía</label>
            <input type="text" formControlName="numerovia" class="form-control" [ngClass]="{'is-invalid': submitted && this.formDirecciones.controls['numerovia'].errors}"/>
            <div *ngIf="submitted && this.formDirecciones.controls['numerovia'].errors" class="invalid-feedback">
                El nombre de la vía es obligatorio.
            </div>
        </div>
        <div class="col-sm-6 mt-3">
            <label>Bloque</label>
            <input type="text" formControlName="bloque" class="form-control"/>
        </div>
    </div>
    <div class="row w-100 mt-3">
        <div class="col-sm-6">
            <label>Escalera</label>
            <input type="text" formControlName="escalera" class="form-control"/>
        </div>
        <div class="col-sm-6">
            <label>Piso</label>
            <input type="text" formControlName="piso" class="form-control"/>
        </div>
    </div>
    <div class="row w-100 mt-3">
        <div class="col-sm-6">
            <label>Puerta</label>
            <input type="text" formControlName="puerta" class="form-control"/>
        </div>
        <div class="col-sm-6">
            <label>País</label>
            <select formControlName="pais" class="form-control" [ngClass]="{'is-invalid': submitted && this.formDirecciones.controls['pais'].errors}">
                <option *ngFor="let _pais of TPais" [value]="_pais.id">
                    {{ _pais.nombre }}
                </option>
            </select>
            <div *ngIf="submitted && this.formDirecciones.controls['pais'].errors" class="invalid-feedback">
                Debes seleccionar un campo.
            </div>
        </div>
    </div>
    <div class="row w-100 mt-3">
        <div class="col-sm-6">
            <label>Comunidad</label>
            <select formControlName="ccaa" class="form-control" (change)="onChangeProvincia($event)" [ngClass]="{'is-invalid': submitted && this.formDirecciones.controls['ccaa'].errors}">
                <option *ngFor="let ccaa of TComunidades" [value]="ccaa.id">
                    {{ ccaa.nombre }}
                </option>
            </select>
            <div *ngIf="submitted && this.formDirecciones.controls['ccaa'].errors" class="invalid-feedback">
                Debes seleccionar un campo.
            </div>
        </div>
        <div class="col-sm-6">
            <label>Provincia</label>
            <select formControlName="provincia" class="form-control" (change)="onChangeMunicipio($event)" [ngClass]="{'is-invalid': submitted && this.formDirecciones.controls['provincia'].errors}">
                <option *ngFor="let provincia of TProvincias" [value]="provincia.id">
                    {{ provincia.nombre }}
                </option>
            </select>
            <div *ngIf="submitted && this.formDirecciones.controls['provincia'].errors" class="invalid-feedback">
                Debes seleccionar un campo.
            </div>
        </div>
    </div>
    <div class="row w-100 mt-3">
        <div class="col-sm-6 mt-3">
            <label>Municipio</label>
            <select formControlName="municipio" class="form-control" [ngClass]="{'is-invalid': submitted && this.formDirecciones.controls['municipio'].errors}">
                <option *ngFor="let municipio of TMunicipios" [value]="municipio.municipioPK['municipio_id']">
                    {{ municipio.nombre }}
                </option>
            </select>
            <div *ngIf="submitted && this.formDirecciones.controls['municipio'].errors" class="invalid-feedback">
                Debes seleccionar un campo.
            </div>
        </div>
        <div class="col-sm-6 mt-3">
            <label>Código postal</label>
            <input type="text" mask="9{5}" formControlName="codigopostal" class="form-control"  [ngClass]="{'is-invalid': submitted && this.formDirecciones.controls['codigopostal'].errors}"/>
            <div *ngIf="submitted && this.formDirecciones.controls['codigopostal'].hasError('required')" class="invalid-feedback">
                El código postal es obligatorio.
            </div>
            <div *ngIf="submitted && this.formDirecciones.controls['codigopostal'].hasError('pattern')" class="invalid-feedback">
                El código postal no es válido.
            </div>
        </div>
    </div>
    <div class="row w-100 mt-3 pl-3">
        <div class="col-sm-6 mt-3">
            <div class="row d-flex">
                <input type="checkbox" formControlName="isactive" class=""/>
                <label class="col">¿Desea guardar esta dirección como dirección principal para futuras gestiones?</label>
            </div>
        </div>
    </div>
    <div class="row w-100 mt-3 pl-3">
        <div class="col-sm-12 mt-3 text-right">
        <button class="btn btn-primary" type="submit" [attr.disabled]="update?'':null">
            Guardar Dirección 
        </button>
        </div>
    </div>
</form>
