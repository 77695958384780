import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.hencok';
import { ServicioGeneral } from '../servicio-general.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ContratoService {
 
  


  protected resourceUrl = `${environment.API_URL}/contrato`;
  protected API_URL = environment.API_URL
  constructor(
    protected http: HttpClient,
    private serviceGeneral: ServicioGeneral,
  ){}

  getImpuestosList() {
    return this.http.get(`${this.resourceUrl}/listAll`)
  }
  index(request): Observable<any>{
    const params = request;
    return this.http.get(`${this.resourceUrl}`, {params});
  } 
  saveContrato(form: any): Observable<any> {
    return this.http.post(`${this.resourceUrl}/save`,form);
  }
  getTipoContrato() {
    return this.http
      .get(`${this.API_URL}/tipo_contrato/list`)
  }
  checkContratos() {
    return this.http.get(`${this.resourceUrl}/checkSigned`)
  }
  indexByTipo(request, id:number): Observable<any>{
    const params = request;
    return this.http.get(`${this.resourceUrl}/listByTipoPaginated/${id}`, {params});
  } 
  getContratoFilter(request: any){
    return this.http.post(`${this.resourceUrl}/filter`, request);
  }
}
