import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsultaService } from 'src/app/_services/autoescuela/consulta.service';
import { TramitesService } from 'src/app/_services/autoescuela/tramites.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { Documentos } from 'src/app/model/autoescuela/documentos';

@Component({
  selector: 'app-visualizar-consulta',
  templateUrl: './visualizar-consulta.component.html',
  styleUrls: ['./visualizar-consulta.component.css']
})
export class VisualizarConsultaComponent {


  id;
  mensaje
  concepto
  nombre
  email
  documentos = []
  documentosAux = []

  constructor(
    private servicioConsulta: ConsultaService,
    private route: ActivatedRoute,
    private tramiteService: TramitesService,
    private notificationService: NotificationService,
    private router:Router
  ) { }


  ngOnInit(): void {
    this.getConsulta()
  }
  getConsulta() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
    })
    this.servicioConsulta.getConsultaById(this.id).subscribe({
      next: (data) => {
        if(data==null){
          this.notificationService.error(
            "Error",
            "No tiene acceso a estos datos",
            4000
          );
                    this.router.navigate(['/client-autoescuelas-hencok/listado-tramites'])


        }
        console.log(data)
        this.mensaje = data["mensaje"]
        this.concepto = data["concepto"]
        this.documentosAux = data["documentosAdjuntos"]
        this.nombre = data["usuario"]["nombre"] + " " + data["usuario"]["apellido1"] + " " + data["usuario"]["apellido2"]
        this.email = data["usuario"]["email"]
        this.getDocumentos()
      },
      error: (err) => {
      }
    })
  }
  getDocumentos() {
    this.documentosAux.forEach(element => {
      console.log(this.documentosAux);
      console.log(element);

      if (!element["idViafirma"]) {


        this.tramiteService.getDocumentos(element["idNextcloud"]).subscribe({
          next: (documento) => {
            console.log(documento)
            let document: Documentos = {
              nombre: documento["nombre"],
              base64: documento["base64"],
              idNextcloud: element["idNextcloud"]
            };
            this.documentos.push(document)
          },
          error: (documento) => {
            console.log(documento)
            let document: Documentos = {
              nombre: documento["nombre"],
              base64: documento["base64"],
              idNextcloud: element["idNextcloud"]
            };

            this.documentos.push(document)
          }
        })
      }

    });
  }

  openPdf(documento) {
    const byteArray = new Uint8Array(atob(documento).split('').map(char => char.charCodeAt(0)));
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank")
  }

}
