import { Component, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PresupuestoService } from 'src/app/_services/autoescuela/presupuesto.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Presupuesto } from 'src/app/model/autoescuela/presupuesto';
import { ClientePresupuesto } from 'src/app/model/autoescuela/clientePresupuesto';
import { environment } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { TramitesService } from 'src/app/_services/autoescuela/tramites.service';

@Component({
  selector: 'app-presupuesto-filled',
  templateUrl: './presupuesto-filled.component.html',
  styleUrls: ['./presupuesto-filled.component.css']
})
export class PresupuestoFilledComponent {

  protected REDSYS_URL = environment.REDSYS_URL;

  @Input() infoPresupuesto: any;
  @Input() isNew: any;
  @Output() newItemEvent = new EventEmitter<any>();

  mTransferencia: boolean = true;
  userAdmin: Boolean = false;
  usuario: any;
  userLogged: any = -1;
  API_URL: string = environment.API_URL;
  i = -1;
  subtotal = 0;
  iva = 0.0;
  irpf = 0.0;
  cantidad_total = 0;

  uploadPresupuesto = false;

  detallesPresupuestoSave = [];
  presupuestoInfoList: any = [];
  listaInfoConceptos: any = [];

  submitPresupuesto = false;
  listaTramites: any;
  isParticular = true;
  itp = false;
  everChanged = false;
  tramiteSeleccionado: string;
  detallesPresupuestoRecibidos: any;
  presupuestoRecibido: any;
  funcion: any;
  mostrarVincular: boolean = true;

  tipoVias: any = [];
  comunidades: any = [];
  provincias: any = [];
  municipios: any = [];

  idComunidad = 0;
  idProvincia = 0;
  municipio = "";
  idVia = 0;
  inTramite = false;

  tipoEntidad = 0;

  via: any;
  comunidad: any;
  provincia: any;
  tramitesSinAsociar: any;
  valorSeleccionado: any = 0;
  listTipo = [ {
    id: "1",
    nombre: "Particular"
  },
  {
    id: "2",
    nombre: "Empresa"
  },]
  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _presupuestoService: PresupuestoService,
    private _tokenStorageService: TokenStorageService,
    private _notificationService: NotificationService,
    private _router: Router,
    private datepipe: DatePipe,
    private http: HttpClient,
    private tramiteService: TramitesService,
    private token?: TokenStorageService,
  ) { }

  formPresupuesto: FormGroup = new FormGroup({
    id: new FormControl(undefined),
    fecha_emision: new FormControl(new Date(), Validators.required),
    fecha_vencimiento: new FormControl("", Validators.required),
    codPresupuesto: new FormControl("", Validators.required),
    notas: new FormControl(""),
    detallesPresupuesto: new FormArray([
    ]),
  });

  formCliente: FormGroup = new FormGroup({
    id: new FormControl(undefined),
    tipoVia: new FormControl(undefined, Validators.required),
    nombreVia: new FormControl(undefined, Validators.required),
    numeroVia: new FormControl(undefined),
    bloque: new FormControl(undefined),
    escalera: new FormControl(undefined),
    planta: new FormControl(undefined),
    puerta: new FormControl(undefined),
    comunidadAutonoma: new FormControl(undefined, Validators.required),
    provincia: new FormControl(undefined, Validators.required),
    localidad: new FormControl(undefined, Validators.required),
    codigoPostal: new FormControl(undefined, [Validators.required, Validators.minLength(5)]),
    km: new FormControl(undefined),
    nombre: new FormControl("", Validators.required),
    apellido1: new FormControl("", Validators.required),
    apellido2: new FormControl(undefined),
    email: new FormControl("", [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required]),
    dni: new FormControl("", Validators.required),
    fechaNacimiendo: new FormControl(undefined, Validators.required),
    validezDni: new FormControl(undefined, Validators.required),
    telefono: new FormControl("", [Validators.required, Validators.minLength(5)]),
    tipoEntidad: new FormControl("", Validators.required),
    nombreEmpresa: new FormControl(undefined),
    cif: new FormControl(undefined),

  });

  ngOnInit(): void {
    this.listaInfoConceptos = [];
    this.inciarTramites()

    this.userLogged = this._tokenStorageService.getUser();
    //console.log(this.userLogged.rol);
    if (this.token.getRol() == 3 || this.token.getRol() == 99) {
      this.userAdmin = true;
    }

    this.formPresupuesto.controls["fecha_emision"].setValue(
      this.formatDate(new Date())
    );




    this.addPresupuesto();
    this.getVias();
    this.getComunidades();
    this.getPreciosTramites();

    this._presupuestoService.getCodigo(this.getYear(new Date())).subscribe({
      next: (data) => {
        this.formPresupuesto.controls["codPresupuesto"].setValue(
          data["codigo"]
        );
      },
      error: (error) => {
      },
    }
    )
    this.formCliente.controls["tipoEntidad"].setValue(2)
  }



  ngOnChanges(changes: SimpleChanges) {
    this.listaInfoConceptos = [];
    this.mostrarVincular = true;
    if (this.infoPresupuesto != undefined) {
      this.presupuestoRecibido = this.infoPresupuesto['presupuesto'];
      console.log("Presu");
      console.log(this.presupuestoRecibido);


      if (this.infoPresupuesto['isTransferencia'] != undefined) {
        this.inTramite = true;
      }

      if (this.presupuestoRecibido.id_tramite) {
        this.mostrarVincular = false;
      }


      if (this.presupuestoRecibido.notas != undefined) {
        this.formPresupuesto.controls['notas'].setValue(this.presupuestoRecibido.notas);
      }

      if (this.checkPresence(this.presupuestoRecibido, "id")) {
        this.formPresupuesto.controls['id'].setValue(this.presupuestoRecibido.id);
      }

      this.formPresupuesto.controls['fecha_emision'].setValue(this.formatDate(this.presupuestoRecibido.fecha));
      this.formPresupuesto.controls['fecha_vencimiento'].setValue(this.formatDate(this.presupuestoRecibido.fechaValidez));
      this.formPresupuesto.controls['codPresupuesto'].setValue(this.presupuestoRecibido.codigoPresupuesto);


      if (this.infoPresupuesto["detalle"] != undefined) {
        this.detallesPresupuesto.clear();
        this.detallesPresupuestoRecibidos = this.infoPresupuesto["detalle"];
        console.log("detalles en editar presupuesto");
        console.log(this.detallesPresupuestoRecibidos);
        var hasSup = false;
        var detalleInfo = undefined;
        var detalleSupInfo = undefined;
        var detalle = undefined;
        var detalleSup = undefined;
        var index = undefined;
        var presupuestoInfo = -1;
        for (let i = 0; i < this.detallesPresupuestoRecibidos.length; i++) {
          detalleSup = undefined;
          detalle = this.detallesPresupuestoRecibidos.at(i);
          console.log(detalle)
          presupuestoInfo = -1;
          presupuestoInfo = this.presupuestoInfoList.findIndex(e => e.tipoTramite.nombre === detalle.concepto);
          if (presupuestoInfo != -1 || detalle.concepto === "Tasa DGT") {
            index = i;
            this.listaInfoConceptos.push({conceptoSelected: true, mainConcepto: true, parentConceptoIndex: undefined,  iva: 21, base: 0, total: detalle.total })
            console.log(this.listaInfoConceptos);
            console.log(this.detallesPresupuesto);
            if(this.detallesPresupuestoRecibidos.length > i+1){
              if(this.detallesPresupuestoRecibidos[i+1].concepto === detalle.concepto + " suplemento"){
                i++;
                this.listaInfoConceptos.push({conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index,  iva: 0, base: 0, total: 0})
                detalleSup = this.detallesPresupuestoRecibidos.at(i);
                detalle.baseImponible += detalleSup.baseImponible;
                detalle.tipoIva = detalleSup.tipoIva;
                detalle.total += detalleSup.total;
                hasSup=true;
              }
            }
            
          }
          else if(detalle.concepto === "Honorarios gestoría" || detalle.concepto === "Tasas gestiones" || detalle.concepto === "Otras tasas de gestiones" || detalle.concepto === "Envío"){
            this.listaInfoConceptos.push({conceptoSelected: false, mainConcepto: false, parentConceptoIndex: index,  iva: 0, base: 0, total: 0 })
          }
          
          else{
            this.listaInfoConceptos.push({conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined,  iva: 0, base: 0, total: 0})
          }
          detalleInfo = new FormGroup({
            id: new FormControl(detalle.id),
            concepto: new FormControl(detalle.concepto, Validators.required),
            baseImponible: new FormControl(String(detalle.baseImponible), Validators.required),
            tipoIva: new FormControl(String(detalle.tipoIva)),
            tipoIrpf: new FormControl(String(detalle.tipoIrpf)),
            total: new FormControl((detalle.total).toFixed(2), Validators.required),
          })

          this.detallesPresupuesto.push(detalleInfo);
          console.log(this.detallesPresupuesto)
          if(hasSup){
            detalleSupInfo = new FormGroup({
              id: new FormControl(detalleSup.id),
              concepto: new FormControl(detalleSup.concepto, Validators.required),
              baseImponible: new FormControl(String(detalleSup.baseImponible), Validators.required),
              tipoIva: new FormControl(String(detalleSup.tipoIva)),
              tipoIrpf: new FormControl(String(detalleSup.tipoIrpf)),
              total: new FormControl((detalleSup.total).toFixed(2), Validators.required),
            })
            this.detallesPresupuesto.push(detalleSupInfo);
            console.log(this.detallesPresupuesto)
            this.calculoConceptoSeleccionado(i-1)
          }
          else{
            this.calcularCantidadNeta(i)
          }
          
        }



        if (this.infoPresupuesto["cliente"] != undefined) {
          var cliente = this.infoPresupuesto["cliente"];
          console.log("Cliente");
          console.log(cliente);
          if (this.checkPresence(cliente, "id") && !this.isNew) {
            this.formCliente.controls["id"].setValue(String(cliente["id"]));
          }

          this.formCliente.controls["tipoEntidad"].setValue(1)
          if (cliente["nombreEmpresa"] && cliente["cif"]) {

            this.formCliente.controls["nombreEmpresa"].setValue(cliente["nombreEmpresa"]);
            this.formCliente.controls["cif"].setValue(cliente["cif"]);
            this.formCliente.controls["tipoEntidad"].setValue(2)
            this.isParticular = false;
            this.isEmpresaOnchanged();
          }
          else{
            this.formCliente.controls["nombre"].setValue(cliente["nombre"]);
            this.formCliente.controls["apellido1"].setValue(cliente["apellido1"]);
            this.formCliente.controls["apellido2"].setValue(cliente["apellido2"]);
            this.formCliente.controls["dni"].setValue(cliente["dni"]);
            this.formCliente.controls["fechaNacimiendo"].setValue(this.formatDate2(cliente["fechaNacimiendo"]));
            this.formCliente.controls["validezDni"].setValue(this.formatDate2(cliente["validezDni"]));
          }

          
          this.formCliente.controls["email"].setValue(cliente["email"]);
          this.formCliente.controls["telefono"].setValue(String(cliente["telefono"]));


          this.formCliente.controls["tipoVia"].setValue(this.tipoVias.findIndex(object => {
            return object.id === cliente["tipoVia"]["id"];
          }));

          this.via = cliente["tipoVia"];

          this.formCliente.controls["nombreVia"].setValue(cliente["nombreVia"]);

          if (this.checkPresence(cliente, "numeroVia")) {
            this.formCliente.controls["numeroVia"].setValue(String(cliente["numeroVia"]));
          }
          if (this.checkPresence(cliente, "bloque")) {
            this.formCliente.controls["bloque"].setValue(cliente["bloque"]);
          }
          if (this.checkPresence(cliente, "escalera")) {
            this.formCliente.controls["escalera"].setValue(cliente["escalera"]);
          }
          if (this.checkPresence(cliente, "planta")) {
            this.formCliente.controls["planta"].setValue(String(cliente["planta"]));
          }
          if (this.checkPresence(cliente, "puerta")) {
            this.formCliente.controls["puerta"].setValue(String(cliente["puerta"]));
          }
          if (this.checkPresence(cliente, "km")) {
            this.formCliente.controls["km"].setValue(String(cliente["km"]));
          }

          this.formCliente.controls["comunidadAutonoma"].setValue(cliente["comunidadAutonoma"]["id"]);

          this.comunidad = cliente["comunidadAutonoma"];

          this.idComunidad = cliente["comunidadAutonoma"]["id"];
          this.setProvinciaAndMunicipio(cliente);
          /*           this.setMunicipio(cliente); */

          this.formCliente.controls["codigoPostal"].setValue(cliente["codigoPostal"]);
        }
      }
      else {
        this.everChanged = true;
      }
    }
    if (this.presupuestoRecibido != undefined && !this.isNew) {
      if (this.token.getRol() == 99 || this.token.getRol() == 3) {

        this.getTramitesSinAsociar(this.presupuestoRecibido.usuario.id)
      } else {

        this.getTramitesSinAsociar(this.token.getId())
      }
    }

  }

  addExistingDetalle(detalle) {
    console.log("Estamos leyendo detalles")
    console.log(detalle);

    var tipoIrpf: string;
    var tipoIva: string;
    if (detalle.tipoIrpf == null) {
      tipoIrpf = "";
    }
    else {
      tipoIrpf = String(detalle.tipoIrpf)
    }

    if (detalle.tipoIrpf == null) {
      tipoIva = "";
    }
    else {
      tipoIva = String(detalle.tipoIva)
    }
    var detalleInfo
    if (detalle.id != undefined) {
      detalleInfo = new FormGroup({
        id: new FormControl(detalle.id),
        concepto: new FormControl(detalle.concepto, Validators.required),
        baseImponible: new FormControl(String(detalle.baseImponible), Validators.required),
        tipoIva: new FormControl(tipoIva),
        tipoIrpf: new FormControl(tipoIrpf),
        total: new FormControl(String(detalle.total), Validators.required)
      })
      console.log("Dettalle a añadir al form");
      console.log(detalleInfo.value);
      this.detallesPresupuesto.push(detalleInfo);
    }
    else {
      detalleInfo = new FormGroup({
        concepto: new FormControl(detalle.concepto, Validators.required),
        baseImponible: new FormControl(String(detalle.baseImponible), Validators.required),
        tipoIva: new FormControl(tipoIva),
        tipoIrpf: new FormControl(tipoIrpf),
        total: new FormControl(String(detalle.total), Validators.required)
      })
      console.log("Dettalle a añadir al form");
      console.log(detalleInfo);
      this.detallesPresupuesto.push(detalleInfo);
    }
  }

  checkPresence(object, parameter) {
    if (parameter in object) {
      return true;
    }
    else {
      return false;
    }
  }

  getVias() {
    return this._serviceGeneral.getTipoVias().subscribe((data) => {
      this.tipoVias = data['TipoVia'];
    });
  }

  get detallesPresupuesto(): FormArray {
    return this.formPresupuesto.get("detallesPresupuesto") as FormArray;
  }

  getComunidades() {
    return this._serviceGeneral.getComunidades().subscribe((data) => {
      this.comunidades = data['ComunidadAutonoma'];
    });
  }

  getProvincias() {
    return this._serviceGeneral.getProvincias().subscribe((data) => {
      this.provincias = data['Provincia'];
    });
  }

  getProvinciasByID() {
    if (this.idComunidad != 0) {
      this._serviceGeneral.getProvincias().subscribe(
        (data) => {
          data['Provincia'].forEach((provincia) => {
            if (provincia.ccaa["id"] == this.idComunidad) {
              this.provincias.push(provincia);
              return this.provincias;
            }
          });
        });
    }

  }

  setProvinciaAndMunicipio(cliente) {
    var idProv;
    var cont = 0;
    if (this.idComunidad != 0) {
      this._serviceGeneral.getProvincias().subscribe(
        (data) => {
          data['Provincia'].forEach((provincia) => {
            if (provincia.ccaa["id"] == this.idComunidad) {
              this.provincias.push(provincia);
              if (provincia["id"] === cliente["provincia"]["id"]) {
                idProv = cont;
              }
              cont++
            }
          });
          this.idProvincia = cliente["provincia"]["id"];
          this.formCliente.controls["provincia"].setValue(idProv);
          this.provincia = cliente["provincia"];

          this.setMunicipio(cliente);
        });
    }

  }

  getTramitesSinAsociar(id) {
    this._presupuestoService.getTramitesSinAsociar(id).subscribe(
      (data) => {
        this.tramitesSinAsociar = data;
        console.log(this.tramitesSinAsociar)
      },
      (error) => {
        console.log(error)
      }
    )
  }
  getMunicipios() {
    return this._serviceGeneral.getMunicipios().subscribe((data) => {
      this.municipios = data['Municipio'];
    });
  }

  getMunicipiosByID() {
    if (this.idProvincia != 0) {
      this._serviceGeneral.getMunicipios().subscribe((data) => {
        data['Municipio'].forEach((municipio) => {
          if (municipio.municipioPK["provincia"] == this.idProvincia) {
            this.municipios.push(municipio);
            return this.municipios
          }
        });
      });
    }
  }

  setMunicipio(cliente) {
    if (this.idProvincia != 0) {
      this._serviceGeneral.getMunicipios().subscribe((data) => {
        data['Municipio'].forEach((municipio) => {
          if (municipio.municipioPK["provincia"] === this.idProvincia) {
            this.municipios.push(municipio);
          }
        });

        this.formCliente.controls["localidad"].setValue(cliente["localidad"]);
        this.municipio = cliente["localidad"];
      });
    }
  }

  onChangeComunidad(event: Event) {
    this.provincias = [];
    this.idComunidad = Number((event.target as HTMLInputElement).value);
    this.comunidad = this.comunidades[this.idComunidad - 1];
    this.getProvinciasByID();
  }

  onChangeProvincia(event: Event) {
    this.municipios = [];
    this.provincia = this.provincias[((event.target as HTMLInputElement).value)];
    this.idProvincia = this.provincia.id;
    this.getPreciosTramites();
    this.getMunicipiosByID();
  }

  onChangeMunicipio(event: Event) {
    this.municipio = String((event.target as HTMLInputElement).value);
  }

  onChangeTipoVia(event: Event) {
    this.via = this.tipoVias[Number((event.target as HTMLInputElement).value)];
    this.idVia = this.via.id;

  }


  onChangeTramite(event: Event) {
    this.valorSeleccionado = (event.target as HTMLSelectElement).value


  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];
        //console.log(this.usuario);
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }



  addPresupuesto() {
    this.detallesPresupuesto.push(new FormGroup({
      concepto: new FormControl("", Validators.required),
      baseImponible: new FormControl("", Validators.required),
      tipoIva: new FormControl(""),
      tipoIrpf: new FormControl(""),
      total: new FormControl("", Validators.required),
    }));
    this.listaInfoConceptos.push({conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined,  iva: 0, base: 0, total: 0})
    this.i++
  }

  onSubmitPresupuesto() {
    this.uploadPresupuesto = true;
    this.submitPresupuesto = true;
    console.log(this.isParticular);

    console.log(this.formPresupuesto, this.formCliente, this.formCliente, this.isParticular)
    var test_nif = new RegExp("([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])");
    if (this.formPresupuesto.invalid && !this.isNew) {
      this.uploadPresupuesto = false;
      return this._notificationService.warning("Información",
        "Rellene todos los campos",
        2000)
    }
    else if (this.formCliente.invalid) {
      this.uploadPresupuesto = false;
      return this._notificationService.warning("Información",
        "Rellene todos los campos",
        2000)
    }
    else if (this.formPresupuesto.controls["fecha_emision"].value > this.formPresupuesto.controls["fecha_vencimiento"].value) {
      this.uploadPresupuesto = false;
      return this._notificationService.warning("Información",
        "La fecha de vencimiento no puede ser anterior a la fecha de emisión",
        2000)
    }
    else {
      let detallesPresupuestoSave = this.changeDetails(this.formPresupuesto.controls["detallesPresupuesto"]);
      this.tipoEntidad = this.formCliente.controls['tipoEntidad'].value
      this.formCliente.removeControl('tipoEntidad');
      if(this.isParticular){
        this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "dd-MM-yyyy"));
        this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "dd-MM-yyyy"));
      }
      let viaValue = this.formCliente.controls["tipoVia"].value;
      let provinciaValue = this.formCliente.controls["provincia"].value;

      this.formCliente.controls["tipoVia"].setValue(this.via);
      this.formCliente.controls["comunidadAutonoma"].setValue(this.comunidad);
      this.formCliente.controls["provincia"].setValue(this.provincia);

      var presupuesto = new Presupuesto(
        new Date(this.formPresupuesto.controls["fecha_emision"].value),
        this.formPresupuesto.controls["codPresupuesto"].value,
        new Date(this.formPresupuesto.controls["fecha_vencimiento"].value),
        this.formPresupuesto.controls["notas"].value,
        this.subtotal,
        this.formPresupuesto.controls["id"].value,
      )
      if (!this.isNew) {
        this._presupuestoService.update(presupuesto, detallesPresupuestoSave.value, new ClientePresupuesto(undefined, this.formCliente.value)).subscribe({
          next: (data) => {
            console.log(data)
            this.uploadPresupuesto = false;
            this.newItemEvent.emit(data);

            this.formCliente.controls["tipoVia"].setValue(viaValue);
            this.formCliente.controls["comunidadAutonoma"].setValue(this.idComunidad);
            this.formCliente.controls["provincia"].setValue(provinciaValue);
            this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
            this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
            return this._notificationService.success("Exito",
              "El presupuesto se ha guardado correctamente",
              5000)
          },
          error: (error) => {
            console.log(error)
            this.uploadPresupuesto = false;
            this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
            this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
            if (error["error"] == "Not valid DNI") {
              return this._notificationService.warning("Atención",
                "El DNI no es valido",
                5000)
            }
            return this._notificationService.error("Error",
              "El presupuesto no se ha podido gurdar, intente más tarde",
              5000)
          }
        })
      }
      else {
        this._presupuestoService.create(presupuesto, detallesPresupuestoSave.value, new ClientePresupuesto(undefined, this.formCliente.value)).subscribe({
          next: (data) => {
            this.uploadPresupuesto = false;
            this.newItemEvent.emit(data);

            this.formCliente.controls["tipoVia"].setValue(viaValue);
            this.formCliente.controls["comunidadAutonoma"].setValue(this.idComunidad);
            this.formCliente.controls["provincia"].setValue(provinciaValue);

            this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
            this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
            return this._notificationService.success("Exito",
              "El presupuesto se ha guardado correctamente",
              5000)
          },
          error: (error) => {
            console.log(error)
            this.uploadPresupuesto = false;
            this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
            this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);
            if (error["error"] == "Not valid DNI") {
              return this._notificationService.warning("Atención",
                "El DNI no es valido",
                5000)
            }
            return this._notificationService.error("Error",
              "El presupuesto no se ha podido gurdar, intente más tarde",
              5000)
          }
        })
      }
    }

  }

  deleteDetalle() {
    if(this.listaInfoConceptos[this.listaInfoConceptos.length - 1].conceptoSelected == true){
      let parentConceptoIndex = this.listaInfoConceptos[this.listaInfoConceptos.length - 1].parentConceptoIndex;
      this.deleteConceptos(this.listaInfoConceptos[this.listaInfoConceptos.length - 1].parentConceptoIndex)
      if(this.detallesPresupuesto.length>1){
        this.detallesPresupuesto.removeAt(parentConceptoIndex);
        this.listaInfoConceptos.splice(parentConceptoIndex,1);
        this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
      }
      else{
        let detallePresupuesto = this.detallesPresupuesto.controls[0] as FormGroup;
        this.listaInfoConceptos[0] = {conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined};
        detallePresupuesto.controls['concepto'].setValue("");
        detallePresupuesto.controls['baseImponible'].setValue("");
        detallePresupuesto.controls['tipoIva'].setValue("");
        detallePresupuesto.controls['tipoIrpf'].setValue("");
        detallePresupuesto.controls['total'].setValue("");
        this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
      }
    }
    else{
      this.detallesPresupuesto.removeAt(this.detallesPresupuesto.length - 1);
      this.listaInfoConceptos.pop();
      this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
    }
    
  }

  calcularCantidadNeta(index: number) {
    var rowPresupuesto = this.formPresupuesto.controls["detallesPresupuesto"].value[index];
    var ivaTotal = 0;
    var irpfTotal = 0;


    if (rowPresupuesto.baseImponible != "") {
      rowPresupuesto.total = rowPresupuesto.baseImponible;

      if (rowPresupuesto.tipoIva != "") {
        ivaTotal = (rowPresupuesto.baseImponible * rowPresupuesto.tipoIva / 100);
      }

      if (rowPresupuesto.tipoIrpf != "") {
        irpfTotal = (rowPresupuesto.baseImponible * rowPresupuesto.tipoIrpf / 100);
      }

      this.formPresupuesto.controls["detallesPresupuesto"].value[index].total = (parseFloat(rowPresupuesto.baseImponible) + ivaTotal + irpfTotal).toFixed(2);

      this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
    } else {
      this.formPresupuesto.controls["detallesPresupuesto"].value[index].total = 0;
    }
  }

  calcularTotales(presupuestos:any []) {
    this.subtotal = 0;
    this.iva = 0.0;
    this.irpf = 0.0;
    this.cantidad_total = 0;
    let presupuesto: any;
    for (let i = 0; i < presupuestos.length; i++) {
      presupuesto = presupuestos[i];
      if(!(this.listaInfoConceptos[i].conceptoSelected === true && this.listaInfoConceptos[i].mainConcepto === false)){
        if (presupuesto.baseImponible == "") {
          this.subtotal += 0.0;
        } else {
          this.subtotal += Number.parseFloat(presupuesto.baseImponible);
        }
  
        if (presupuesto.tipoIva == "") {
          this.iva += 0.0;
        } else {
          if(this.listaInfoConceptos[i].iva > 0){
            this.iva += presupuesto.total - presupuesto.baseImponible
          }
          else{
            this.iva += presupuesto.baseImponible * (presupuesto.tipoIva / 100);
          }
        }
  
        if (presupuesto.tipoIrpf == "") {
          this.irpf += 0.0;
        } else {
          this.irpf += presupuesto.baseImponible * (presupuesto.tipoIrpf / 100);
        }
      }
      
    }
    this.subtotal = Number.parseFloat(this.subtotal.toFixed(2));
    this.iva = Number.parseFloat(this.iva.toFixed(2));
    this.irpf = Number.parseFloat(this.irpf.toFixed(2))
    this.cantidad_total = parseFloat((this.subtotal + this.iva + this.irpf).toFixed(2));
  }

  generatePreview() {
    if (this.formPresupuesto.invalid) {
      return this._notificationService.warning("Información",
        "Rellene todos los campos",
        2000)
    }
    else if (this.formCliente.invalid) {
      this.uploadPresupuesto = false;
      return this._notificationService.warning("Información",
        "Rellene todos los campos correctamente",
        2000)
    }
    else if (this.formPresupuesto.controls["fecha_emision"].value > this.formPresupuesto.controls["fecha_vencimiento"].value) {
      return this._notificationService.warning("Información",
        "La fecha de vencimiento no puede ser anterior a la fecha de emisión",
        2000)
    }
    else {
      let detallesPresupuestoSave = this.changeDetails(this.formPresupuesto.controls["detallesPresupuesto"]);
      this.tipoEntidad = this.formCliente.controls['tipoEntidad'].value
      this.formCliente.removeControl('tipoEntidad');
      if(this.isParticular){
        this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "dd-MM-yyyy"));
        this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "dd-MM-yyyy"));
      }

      let viaValue = this.formCliente.controls["tipoVia"].value;
      let provinciaValue = this.formCliente.controls["provincia"].value;

      this.formCliente.controls["tipoVia"].setValue(this.via);
      this.formCliente.controls["comunidadAutonoma"].setValue(this.comunidad);
      this.formCliente.controls["provincia"].setValue(this.provincia);

      var presupuesto = new Presupuesto(
        new Date(this.formPresupuesto.controls["fecha_emision"].value),
        this.formPresupuesto.controls["codPresupuesto"].value,
        new Date(this.formPresupuesto.controls["fecha_vencimiento"].value),
        this.formPresupuesto.controls["notas"].value,
        this.subtotal
      )


      this._presupuestoService.getDocPreview(presupuesto, detallesPresupuestoSave.value, this.formCliente.value).subscribe({
        next: (data) => {
          this.formCliente.controls["tipoVia"].setValue(viaValue);
          this.formCliente.controls["comunidadAutonoma"].setValue(this.idComunidad);
          this.formCliente.controls["provincia"].setValue(provinciaValue);
          if(this.isParticular){
            this.formCliente.controls["fechaNacimiendo"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["fechaNacimiendo"].value), "yyyy-MM-dd"));
            this.formCliente.controls["validezDni"].setValue(this.datepipe.transform(new Date(this.formCliente.controls["validezDni"].value), "yyyy-MM-dd"));
          }

          this.formCliente.addControl('tipoEntidad', new FormControl("", Validators.required));
          this.formCliente.controls["tipoEntidad"].setValue(this.tipoEntidad);

          const byteArray = new Uint8Array(atob(data["docBytes"]).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        }
      })
    }
  }

  addNewItem() {
    this.newItemEvent.emit(false);
  }

  formatDate(date) {

    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  formatDate2(toSplitDate) {
    let date = toSplitDate.split("-");
    let month = "" + date[1];
    let day = "" + date[0];
    const year = date[2];
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return year + "-" + month + "-" + day;
  }

  getYear(date) {
    const d = new Date(date);
    const year = d.getFullYear();

    return year;
  }

  setMostrarFormularioChild() {
    this.mTransferencia = !this.mTransferencia
  }
  mostrarTransferencia(mostrar: boolean) {
    this.completarFormulario();

    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/nueva-transferencia', this.formCliente.value]);
    }
    else {
      this._router.navigate(['/client-autoescuelas-hencok/nueva-transferencia', this.formCliente.value]);
    }
    this.mTransferencia = mostrar

  }


  mostrarInformeDgt() {
    this.completarFormulario()

    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-informe-dgt', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-informe-dgt', this.formCliente.value]);
    }

  }

  mostrarDuplicadoPermiso() {
    this.completarFormulario()

    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-duplicado-permiso-conducir', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-duplicado-permiso-conducir', this.formCliente.value]);
    }

  }

  mostrarMatriculacion() {
    this.completarFormulario()

    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-matriculacion', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-matriculacion', this.formCliente.value]);
    }

  }


  mostrarDistintivo() {
    this.completarFormulario()
    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-distintivo-ambiental', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-distintivo-ambiental', this.formCliente.value]);
    }

  }


  mostrarDuplicado() {
    this.completarFormulario()
    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-duplicado-ficha-tecnica', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-duplicado-ficha-tecnica', this.formCliente.value]);
    }

  }

  mostrarCanje() {
    this.completarFormulario()
    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitar-canje-permiso', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitar-canje-permiso', this.formCliente.value]);
    }
  }



  mostrarInternacional() {
    this.completarFormulario()
    if (this.token.getRol() == 99 || this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/solicitud-permiso-internacional', this.formCliente.value]);
    } else {
      this._router.navigate(['/client-autoescuelas-hencok/solicitud-permiso-internacional', this.formCliente.value]);
    }
  }
  completarFormulario() {
    
    this.formCliente.addControl('tipoVia2', new FormControl);
    this.formCliente.controls["tipoVia2"].setValue(this.via.id);
    this.formCliente.addControl('comunidadAutonoma2', new FormControl);
    this.formCliente.controls["comunidadAutonoma2"].setValue(this.comunidad.id);
    this.formCliente.addControl('provincia2', new FormControl);

    this.formCliente.controls["provincia2"].setValue(this.provincia.id);
    this.formCliente.addControl('municipio2', new FormControl);

    this.formCliente.controls["municipio2"].setValue(this.municipio);
    this.formCliente.addControl("AsociarTramite", new FormControl);
    this.formCliente.controls["AsociarTramite"].setValue(this.presupuestoRecibido.id);
    console.log(this.formCliente)

  }

  vincularTramiteAPresupuesto() {
    var ruta = ""
    this._presupuestoService.asociarTramiteAPresupuesto
      (this.tramitesSinAsociar[this.valorSeleccionado].id, this.presupuestoRecibido.id,
        this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id).subscribe(
          (data) => {
            console.log(data)
            this._notificationService.success("Exito",
              "El presupuesto se vinculado correctamente",
              5000)
            if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "2") {
              ruta = "transferencia";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "3") {
              ruta = "solicitar-matriculacion";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "4") {
              ruta = "solicitar-duplicado-ficha-tecnica";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "5") {
              ruta = "distintivo-ambiental";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "6") {
              ruta = "solicitar-duplicado-permiso-conducir";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "7") {
              ruta = "canje-permiso";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "8") {
              ruta = "permiso-internacional";
            }
            else if (this.tramitesSinAsociar[this.valorSeleccionado].tipoTramite.id == "9") {
              ruta = "informe-dgt";
            }


            if (this.token.getRol() == 99) {
              this._router.navigate(['/admin-hencok/' + ruta + '/' + this.tramitesSinAsociar[this.valorSeleccionado].id]);
            } else if (this.token.getRol() == 3) {
              this._router.navigate(['/admin-autoescuelas-hencok/' + ruta + '/' + this.tramitesSinAsociar[this.valorSeleccionado].id]);
            } else {
              this._router.navigate(['/client-autoescuelas-hencok/' + ruta + '/' + this.tramitesSinAsociar[this.valorSeleccionado].id]);
            }


          })


  }




  inciarTramites() {
    this.tramiteService.getTramites().subscribe({
      next: (data) => {
        this.listaTramites = data

      },
      error: (error) => {
      },
    }
    )
  }
  irATramiteVinculado() {
    var ruta = ""
    console.log(this.presupuestoRecibido)

    if (this.presupuestoRecibido.tramite.id == "2") {
      ruta = "transferencia";
    }
    else if (this.presupuestoRecibido.tramite.id == "3") {
      ruta = "solicitar-matriculacion";
    }
    else if (this.presupuestoRecibido.tramite.id == "4") {
      ruta = "duplicado-ficha-tecnica";
    }
    else if (this.presupuestoRecibido.tramite.id == "5") {
      ruta = "distintivo-ambiental";
    }
    else if (this.presupuestoRecibido.tramite.id == "6") {
      ruta = "solicitar-duplicado-permiso-conducir";
    }
    else if (this.presupuestoRecibido.tramite.id == "7") {
      ruta = "canje-permiso";
    }
    else if (this.presupuestoRecibido.tramite.id == "8") {
      ruta = "permiso-internacional";
    }
    if (this.presupuestoRecibido.tramite.id == "9") {
      ruta = "informe-dgt";
    }

    if (this.token.getRol() == 99) {
      this._router.navigate(['/admin-hencok/' + ruta + '/' + this.presupuestoRecibido.id_tramite]);
    } else if (this.token.getRol() == 3) {
      this._router.navigate(['/admin-autoescuelas-hencok/' + ruta + '/' + this.presupuestoRecibido.id_tramite]);

    } else {
      this._router.navigate(['/client-autoescuelas-hencok/' + ruta + '/' + this.presupuestoRecibido.id_tramite]);

    }
  }

  mostrarTramiteAutocompletado() {
    switch (this.tramiteSeleccionado) {

      case ("1"):
        // this.mostrarConsulta();
        break;
      case ("2"):
        this.mostrarTransferencia(false);
        break;

      case ("3"):
        this.mostrarMatriculacion();
        break;

      case ("4"):
        this.mostrarDuplicado();
        break;
      case ("5"):
        this.mostrarDistintivo();
        break;
      case ("6"):
        this.mostrarDuplicadoPermiso();
        break;
      case ("7"):
        this.mostrarCanje();
        break;
      case ("8"):
        this.mostrarInternacional();
        break;
      case ("9"):
        this.mostrarInformeDgt();
        break;
    }
  }


  onChangeTramiteAAsociar(event: Event) {
    this.tramiteSeleccionado = (event.target as HTMLSelectElement).value
  }


  params
  firma
  receivedHtml

  goPago() {
    console.log(this.presupuestoRecibido.id);
    console.log(window.location.href);

    this._presupuestoService.tryPayment(this.presupuestoRecibido.id, window.location.href).subscribe({
      next: (data) => {
        this.params = data.params
        this.firma = data.firma

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = this.REDSYS_URL;

        // Agregar el HTML como un campo oculto en el formulario
        const htmlInput = document.createElement('input');
        htmlInput.type = 'hidden';
        htmlInput.name = 'Ds_SignatureVersion';
        htmlInput.value = "HMAC_SHA256_V1";
        form.appendChild(htmlInput);

        const htmlInput1 = document.createElement('input');
        htmlInput1.type = 'hidden';
        htmlInput1.name = 'Ds_MerchantParameters';
        htmlInput1.value = this.params;
        form.appendChild(htmlInput1);

        const htmlInput2 = document.createElement('input');
        htmlInput2.type = 'hidden';
        htmlInput2.name = 'Ds_Signature';
        htmlInput2.value = this.firma;
        form.appendChild(htmlInput2);


        document.body.appendChild(form);
        form.submit();

      }
    })
  };

  getPreciosTramites(){
    this.tramiteService.getPreciosTramiteList(this.provincia).subscribe((data) => {
      console.log(data)
        this.presupuestoInfoList = data;
      })
    }
  
    setConceptos(index, event){
      let presupuestoInfoIndex : any = (event.target as HTMLInputElement).value;
      let presupuestoInfo:any = this.presupuestoInfoList[presupuestoInfoIndex];
      let detallePresupuesto = this.detallesPresupuesto.controls[index] as FormGroup;
      console.log(this.listaInfoConceptos[index].mainConcepto === true)
      if(this.listaInfoConceptos[index].mainConcepto === true){
        this.deleteConceptos(index);
      }
      if(presupuestoInfo){
        this.listaInfoConceptos[index] = {conceptoSelected: true, mainConcepto: true, parentConceptoIndex: undefined, iva: 0, base: 0, total: 0};
        this.formPresupuesto.controls["detallesPresupuesto"].value[index].concepto = presupuestoInfo.tipoTramite.nombre
  
        let importe = 0;
        let total = 0;
        /* if(presupuestoInfo.tasaDgt){
          detallePresupuesto.controls['concepto'].setValue(presupuestoInfo.tipoTramite.nombre);
          detallePresupuesto.controls['baseImponible'].setValue(String(presupuestoInfo.tasaDgt));
          detallePresupuesto.controls['tipoIva'].setValue("");
          detallePresupuesto.controls['tipoIrpf'].setValue("");
          detallePresupuesto.controls['total'].setValue(String(presupuestoInfo.tasaDgt));
          if(presupuestoInfo.honorariosGestoria){
            this.nuevoConcepto("Honorarios gestoría", presupuestoInfo.honorariosGestoria, presupuestoInfo.tipoIva, 0, (presupuestoInfo.honorariosGestoria + (presupuestoInfo.honorariosGestoria * presupuestoInfo.tipoIva / 100)).toFixed(2), {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index, iva: presupuestoInfo.tipoIva});
          }
        }
        else if(presupuestoInfo.honorariosGestoria){
  
          detallePresupuesto.controls['concepto'].setValue(presupuestoInfo.tipoTramite.nombre);
          detallePresupuesto.controls['baseImponible'].setValue(String(presupuestoInfo.honorariosGestoria));
          detallePresupuesto.controls['tipoIva'].setValue(String(presupuestoInfo.tipoIva));
          detallePresupuesto.controls['tipoIrpf'].setValue("");
          detallePresupuesto.controls['total'].setValue((presupuestoInfo.honorariosGestoria + (presupuestoInfo.honorariosGestoria * presupuestoInfo.tipoIva / 100)).toFixed(2));
          this.listaInfoConceptos[index].iva = presupuestoInfo.tipoIva;
        }
        if(presupuestoInfo.tasaGestores1){
          this.nuevoConcepto("Tasas gestiones", presupuestoInfo.tasaGestores1, 0, 0, presupuestoInfo.tasaGestores1, {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index, iva: 0});
        }
        if(presupuestoInfo.tasaGestores2){
          this.nuevoConcepto("Otras tasas de gestiones", presupuestoInfo.tasaGestores2, 0, 0, presupuestoInfo.tasaGestores2, {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index, iva: 0});
        }
        
        if(presupuestoInfo.envio){
          this.nuevoConcepto("Envío", presupuestoInfo.envio, 0, 0, presupuestoInfo.envio, {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: index, iva: 0});
        } */
        if(presupuestoInfo.tasaDgt){
          importe += presupuestoInfo.tasaDgt;
          total += presupuestoInfo.tasaDgt;
        }
        if(presupuestoInfo.tasaGestores1){
          importe += presupuestoInfo.tasaGestores1;
          total += presupuestoInfo.tasaGestores1;
        }
        if(presupuestoInfo.tasaGestores2){
          importe += presupuestoInfo.tasaGestores2;
          total += presupuestoInfo.tasaGestores2;
        }
        
        detallePresupuesto.controls['concepto'].setValue(presupuestoInfo.tipoTramite.nombre);
        detallePresupuesto.controls['baseImponible'].setValue(String(total.toFixed(2)));
        detallePresupuesto.controls['tipoIva'].setValue("");
        detallePresupuesto.controls['tipoIrpf'].setValue("");
        detallePresupuesto.controls['total'].setValue(String(total.toFixed(2)));
        this.listaInfoConceptos[index].base = parseFloat(importe.toFixed(2));
        this.listaInfoConceptos[index].total = parseFloat(total.toFixed(2));
        if(presupuestoInfo.honorariosGestoria && total != 0){
          this.nuevoConcepto("Honorarios gestoría", presupuestoInfo.honorariosGestoria, presupuestoInfo.tipoIva, 0, (presupuestoInfo.honorariosGestoria + (presupuestoInfo.honorariosGestoria * presupuestoInfo.tipoIva / 100)).toFixed(2), {conceptoSelected: false, mainConcepto: false, parentConceptoIndex: index, iva: presupuestoInfo.tipoIva});
        }
        else{
          detallePresupuesto.controls['baseImponible'].setValue(String(presupuestoInfo.honorariosGestoria.toFixed(2)));
          detallePresupuesto.controls['tipoIva'].setValue(String(presupuestoInfo.tipoIva));
          detallePresupuesto.controls['tipoIrpf'].setValue("");
          detallePresupuesto.controls['total'].setValue(String((presupuestoInfo.honorariosGestoria + (presupuestoInfo.honorariosGestoria * presupuestoInfo.tipoIva / 100)).toFixed(2)));
        }
        this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
      }
      else{
        this.listaInfoConceptos[index] = {conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined,  iva: 0, base: 0, total: 0};
        detallePresupuesto.controls['concepto'].setValue("");
        detallePresupuesto.controls['baseImponible'].setValue("");
        detallePresupuesto.controls['tipoIva'].setValue("");
        detallePresupuesto.controls['tipoIrpf'].setValue("");
        detallePresupuesto.controls['total'].setValue("");
        this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
      }
    }
  
    deleteConceptos(index){
      for(let i=0; i<this.listaInfoConceptos.length;){
        console.log(i, this.listaInfoConceptos[i].parentConceptoIndex === index)
        if(this.listaInfoConceptos[i].parentConceptoIndex === index){
          this.detallesPresupuesto.removeAt(i);
          this.listaInfoConceptos.splice(i,1);
        }
        else{i++}
      }
      console.log(this.listaInfoConceptos, this.detallesPresupuesto)
    }
  
  
  
    nuevoConcepto(concepto:any, baseImponible:any, tipoIva:any, tipoIrpf:any, total:any, infoConceptos?){
      var detalles = new FormGroup({
        concepto: new FormControl(concepto, Validators.required),
        baseImponible: new FormControl(String(baseImponible), Validators.required),
        tipoIva: new FormControl(String(tipoIva)),
        tipoIrpf: new FormControl(String(tipoIrpf)),
        total: new FormControl(String(total), Validators.required),
      });
  
      if(infoConceptos){
        this.listaInfoConceptos.push(infoConceptos)
      }
      else{
        this.listaInfoConceptos.push({conceptoSelected: false, mainConcepto: false, parentConceptoIndex: undefined,  iva: 0, base: 0, total: 0})
      }
      this.detallesPresupuesto.push(detalles);
      this.calcularTotales(this.formPresupuesto.controls["detallesPresupuesto"].value);
    }

    calculoConceptoSeleccionado(i){
      let presupuestos = this.formPresupuesto.controls["detallesPresupuesto"].value;
      let presupuesto = presupuestos[i];
      let suplemento = presupuesto.baseImponible - this.listaInfoConceptos[i].total;
      let iva = presupuesto.tipoIva;
      if(iva == ""){
        iva = 0;
      }
      if(presupuestos[i+1].concepto == presupuesto.concepto +" suplemento"){
        let presupuestoSuplemento = presupuestos[i + 1];
        presupuestoSuplemento.baseImponible = String((Math.round(suplemento * 100) / 100).toFixed(2));
        presupuestoSuplemento.tipoIva = String(iva);
        if(suplemento>0){
          suplemento += suplemento * (iva / 100)
        };
        this.formPresupuesto.controls["detallesPresupuesto"].value[i + 1].total = String((Math.round(suplemento * 100) / 100).toFixed(2));
        this.formPresupuesto.controls["detallesPresupuesto"].value[i].total = String((Math.round((suplemento + this.listaInfoConceptos[i].total) * 100) / 100).toFixed(2));
      }
      else{
        this.nuevoConcepto(presupuesto.concepto + " suplemento", suplemento, 0, 0, suplemento + suplemento * (iva / 100), {conceptoSelected: true, mainConcepto: false, parentConceptoIndex: i, iva: 0, base: 0, total: 0});
        if(suplemento>0){
          suplemento += suplemento * (iva / 100)
        };
        this.formPresupuesto.controls["detallesPresupuesto"].value[i].total = String((Math.round((suplemento + this.listaInfoConceptos[i].total) * 100) / 100).toFixed(2));
      }
      this.calcularTotales(presupuestos);
    }

    isEmpresaOnchanged() {
      if(this.formCliente.controls["tipoEntidad"].value == 2){
        this.isParticular = false
  
        this.formCliente.get('nombre').removeValidators(Validators.required);
        this.formCliente.get('apellido1').removeValidators(Validators.required);
        this.formCliente.get('apellido2').removeValidators(Validators.required);
        this.formCliente.get('dni').removeValidators(Validators.required);
        this.formCliente.get('fechaNacimiendo').removeValidators(Validators.required);
        this.formCliente.get('validezDni').removeValidators(Validators.required);
        this.formCliente.get('nombreEmpresa').addValidators(Validators.required);
        this.formCliente.get('cif').addValidators(Validators.required);
  
        this.formCliente.controls['nombre'].setErrors(null);
        this.formCliente.controls['apellido1'].setErrors(null);
        this.formCliente.controls['apellido2'].setErrors(null);
        this.formCliente.controls['dni'].setErrors(null);
        this.formCliente.controls['fechaNacimiendo'].setErrors(null);
        this.formCliente.controls['validezDni'].setErrors(null);
  
        this.formCliente.controls['nombre'].setValue(undefined)
        this.formCliente.controls['apellido1'].setValue(undefined)
        this.formCliente.controls['apellido2'].setValue(undefined)
        this.formCliente.controls['dni'].setValue(undefined)
        this.formCliente.controls['fechaNacimiendo'].setValue(undefined)
        this.formCliente.controls['validezDni'].setValue(undefined)
  
        console.log(this.formCliente)
      }else{
        this.isParticular = true
  
        this.formCliente.get('nombre').addValidators(Validators.required);
        this.formCliente.get('apellido1').addValidators(Validators.required);
        this.formCliente.get('apellido2').addValidators(Validators.required);
        this.formCliente.get('dni').addValidators(Validators.required);
        this.formCliente.get('fechaNacimiendo').addValidators(Validators.required);
        this.formCliente.get('validezDni').addValidators(Validators.required);
        this.formCliente.get('nombreEmpresa').removeValidators(Validators.required);
        this.formCliente.get('cif').removeValidators(Validators.required);
  
        this.formCliente.controls['nombreEmpresa'].setErrors(null);
        this.formCliente.controls['cif'].setErrors(null);
  
        this.formCliente.controls['nombreEmpresa'].setValue(undefined);
        this.formCliente.controls['cif'].setValue(undefined);
  
        console.log(this.formCliente)
      }
  
    }  
    changeDetails(detallesPresupuesto){
      const newDetallesPresupuesto: any = new FormArray([]);
      var detalles;
      for(let i = 0; i< detallesPresupuesto.length; i++){
        detalles = new FormGroup({
          concepto: new FormControl(detallesPresupuesto.controls[i].value.concepto, Validators.required),
          baseImponible: new FormControl(detallesPresupuesto.controls[i].value.baseImponible, Validators.required),
          tipoIva: new FormControl(detallesPresupuesto.controls[i].value.tipoIva),
          tipoIrpf: new FormControl(""),
          total: new FormControl(detallesPresupuesto.controls[i].value.total, Validators.required),
        });
        console.log(detallesPresupuesto.controls[i])
        console.log(detallesPresupuesto.controls[i].value.total)
        console.log(detalles)
        newDetallesPresupuesto.push(detalles)
        
        if(this.listaInfoConceptos[i].parentConceptoIndex == i-1 && newDetallesPresupuesto.controls[i].controls['concepto'].value == newDetallesPresupuesto.controls[i-1].controls['concepto'].value + " suplemento"){
          newDetallesPresupuesto.controls[i-1].controls['baseImponible'].setValue(this.listaInfoConceptos[i-1].total);
          newDetallesPresupuesto.controls[i-1].controls['total'].setValue(this.listaInfoConceptos[i-1].total);
          newDetallesPresupuesto.controls[i-1].controls['tipoIva'].setValue("")
        }
      }
      return newDetallesPresupuesto;
    }
}
