import { Component, ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  currentEnv: string;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(private _router: Router,) {
    this.currentEnv = environment.environment;
    this.favIcon.href =environment.ICON_TITLE_HEAD;
    document.getElementById('tituloDoc').innerHTML=environment.TITLE_HEAD;
  }

  ngOnInit(): void {

    // if(this.currentEnv == 'hencok'){
    //   this._router.navigate(['login']);
    // }

  }


}
