<div class="d-flex flex-row justify-content-between align-items-center  pr-5">
    <h1 *ngIf="!isUpdated"class="pl-4 m-0">Nueva transferencia</h1>
    <h1 *ngIf="isUpdated"class="pl-4 m-0">Editar transferencia</h1>
</div>
<div class="pl-4">
    <hr class="linea-divisoria">
</div>

<div class="firstFormTitle">
    <label class="formTitleText">Datos comprador</label>
</div>
<div  class="formGroup bgLightGreen" >

<form [formGroup]=" form" class="flexn">
    <div *ngIf="presupuesto">
        <label>Selecciona a quien quieres hacer el presupuesto</label>
        <div class="d-flex justify-content-start">
            <div class="mr-3 mt-2">
                <label><input  class="mr-2" type="radio" name="tipo" value="1"
                    (change)="seleccionarCompradorVendedor($event)"> Comprador</label>
            </div>
            <div class="ml-3 mt-2">
                <label ><input  class="mr-2"   type="radio" name="tipo" value="2"
                    (change)="seleccionarCompradorVendedor($event)"> Vendedor </label>
            </div>
        </div>
    </div>


    <div class="rowForm">
        <div class="row">
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Tipo de persona</label>
                <select formControlName="tipoEntidad" class="form-control"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['tipoEntidad'].errors}"
                    (change)="isEmpresaOnchanged()">
                    <option *ngFor="let tipo of listTipo" [value]="tipo.id">
                        {{ tipo.nombre }}
                    </option>
                </select>
            </div>
            <div class="col-sm-6" *ngIf="!isEmpresa">
                <label class="crear-editar-form requerido">Nombre empresa</label>
                <input class="form-control" type="text" id="nombre_empresa" formControlName="nombre_empresa"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_empresa'].errors}">
            </div>
            
            <div class="col-sm-3" *ngIf="!isEmpresa">
                <label class="crear-editar-form requerido">CIF empresa</label>
                <input class="form-control" type="text" id="cif_empresa" formControlName="cif_empresa"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['cif_empresa'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="isEmpresa">
                <label class="crear-editar-form requerido">Nombre </label>
                <input class="form-control" type="text" formControlName="nombre"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="isEmpresa">
                <label class="crear-editar-form requerido">Primer apellido</label>
                <input class="form-control" type="text" formControlName="apellido1"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido1'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="isEmpresa">
                <label class="crear-editar-form requerido">Segundo apellido</label>
                    <input class="form-control" type="text" formControlName="apellido2"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido2'].errors}">
            </div>   
        </div>
  
    </div>

    <div class="rowForm" *ngIf="isEmpresa">
        <div class="row">
            <!--<label class="crear-editar-form requerido col-sm-6">Tipo documento*</label>-->
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Fecha de nacimiento</label>
                <input type="date" class="form-control" name="fecha_nacimiento" formControlName="fecha_nacimiento"
                minlength="3" maxlength="20"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['fecha_nacimiento'].value == ''}" />
            </div>
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">NIF/NIE</label>
                <input class="form-control" type="text" id="numero_documento" formControlName="numero_documento"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_documento'].errors}">
            </div>
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Fecha validez NIF/NIE</label>
                <input type="date" class="form-control" name="validez_dni" formControlName="validez_dni" minlength="3"
                maxlength="20"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['validez_dni'].value == ''}" />
            </div>
            
        </div>
 
    </div>

    <div class="rowForm" *ngIf="!isEmpresa">
        <div class="row">
            <!--<label class="crear-editar-form requerido col-sm-6">Tipo documento*</label>-->
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Nombre administrador</label>
                <input class="form-control" type="text" formControlName="nombre"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Primer apellido administrador</label>
                <input class="form-control" type="text" formControlName="apellido1"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido1'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Segundo apellido administrador</label>
                    <input class="form-control" type="text" formControlName="apellido2"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido2'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">NIF/NIE administrador</label>
                <input class="form-control" type="text" id="numero_documento" formControlName="numero_documento"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_documento'].errors}">
            </div>
            
        </div>
 
    </div>

    <div class="rowForm">
        <div class="row">
            <div class="col-sm-6">
                <label class="crear-editar-form requerido">Móvil</label>
            <input class="form-control outlinenone" type="text" mask="9{9}" id="telefono" formControlName="telefono"
            [ngClass]="{'is-invalid': isRequired && this.form.controls['telefono'].errors}">
            </div>
            <div class="col-sm-6">
                <label class="crear-editar-form requerido">Correo electrónico</label>
                <input type="email" name="email" class="form-control" formControlName="email"
                [ngClass]="{'is-invalid': isRequired&& this.form.controls['email'].errors}" />
            </div>
            </div>
        </div>

<!--         <div class="rowForm" *ngIf="!isEmpresa">
            <div class="row">
                <div class="col-sm-6">
                    <label class="crear-editar-form requerido">Nombre empresa</label>
                    <input class="form-control" type="text" id="nombre_empresa" formControlName="nombre_empresa"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_empresa'].errors}">
                </div>
                
                <div class="col-sm-6">
                    <label class="crear-editar-form requerido">CIF empresa</label>
                    <input class="form-control" type="text" id="cif_empresa" formControlName="cif_empresa"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['cif_empresa'].errors}">
                </div>
            </div>
            </div> -->
    
            <div class="rowForm">
                <div class="row">
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Tipo de vía</label>
                        <select formControlName="tipo_via" class="form-control"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['tipo_via'].errors}">
                            <option *ngFor="let vias of ListVias" [value]="vias.id">
                                {{ vias.tipovianormalizado }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Dirección</label>
                            <input type="text" formControlName="nombre_via" class="form-control"
                            [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_via'].errors}" />
                    </div>
                    
    
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Nº de la vía</label>
                        <input type="text" mask="9{5}" formControlName="numero_via" class="form-control"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_via'].errors}" />
                    </div>
                    
                    <div class="col-sm-3">
                        <label class="crear-editar-form">Bloque</label>
                        <input type="text" formControlName="bloque" class="form-control" />
                    </div>
                    
                </div>
            
            </div>
            <div class="rowForm">
            <div class="row ">
            <div class="col-sm-3">
                <label class="crear-editar-form">Escalera</label>
                <input type="text" formControlName="escalera" class="form-control" />
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form">Planta</label>
                    <input type="text" formControlName="piso" class="form-control" />
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form">Puerta</label>
                <input type="text" formControlName="puerta" class="form-control" />
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form">Km</label>
                <input type="text" formControlName="km" class="form-control" />
            </div>
            
        </div>
    </div>
    
    <div class="rowForm">
        <div class="row">
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Comunidad autónoma</label>
                <select formControlName="ccaa" class="form-control" (change)="onChangeProvincia($event,false)"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['ccaa'].errors}">
                    <option *ngFor="let ccaa of ListComunidades" [value]="ccaa.id">
                        {{ ccaa.nombre }}
                    </option>
                </select>
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Provincia</label>
                <select formControlName="provincia" class="form-control" (change)="onChangeMunicipio($event,false)"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['provincia'].errors}">
                    <option *ngFor="let provincia of ListProvincias" [value]="provincia.id">
                        {{ provincia.nombre }}
                    </option>
                </select>
            </div>

            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Municipio</label>
                <select formControlName="municipio" class="form-control"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['municipio'].errors}">
                    <option *ngFor="let municipio of ListMunicipios" [value]="municipio.municipioPK['municipio_id']">
                        {{ municipio.nombre }}
                    </option>
                </select>
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Código postal</label>
                <input type="text" mask="9{5}" formControlName="codigo_postal" class="form-control"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['codigo_postal'].errors}">
            </div>
        </div>
    </div>
</form>

</div>
<div class="formTitle">
    <label class="formTitleText">Datos vendedor</label>
</div>


<form [formGroup]=" form " class="formGroup bgLightGreen">
    <div class="rowForm">
        <div class="row">
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Tipo de persona</label>
                <select formControlName="tipoEntidadVendedor" class="form-control"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['tipoEntidadVendedor'].errors}"
                    (change)="isEmpresaVendedorOnchanged()">
                    <option *ngFor="let tipo of listTipo" [value]="tipo.id">
                        {{ tipo.nombre }}
                    </option>
                </select>
            </div>
            <div class="col-sm-6" *ngIf="!isEmpresaVendedor">
                <label class="crear-editar-form requerido">Nombre empresa</label>
                <input class="form-control" type="text" id="nombre_empresa" formControlName="nombre_empresaVendedor"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_empresaVendedor'].errors}">
            </div>
            
            <div class="col-sm-3" *ngIf="!isEmpresaVendedor">
                <label class="crear-editar-form requerido">CIF empresa</label>
                <input class="form-control" type="text" id="cif_empresa" formControlName="cif_empresaVendedor"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['cif_empresaVendedor'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="isEmpresaVendedor">
                <label class="crear-editar-form requerido">Nombre </label>
                <input class="form-control" type="text" formControlName="nombreVendedor"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['nombreVendedor'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="isEmpresaVendedor">
                <label class="crear-editar-form requerido">Primer apellido</label>
                <input class="form-control" type="text" formControlName="apellido1Vendedor"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido1Vendedor'].errors}">
            </div>
            <div class="col-sm-3" *ngIf="isEmpresaVendedor">
                <label class="crear-editar-form requerido">Segundo apellido</label>
                    <input class="form-control" type="text" formControlName="apellido2Vendedor"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido2Vendedor'].errors}">
            </div>   
        </div>
  
    </div>

    <div class="rowForm" *ngIf="isEmpresaVendedor">
        <div class="row">
            <!--<label class="crear-editar-form requerido col-sm-6">Tipo documento*</label>-->
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Fecha de nacimiento</label>
                <input type="date" class="form-control" name="fecha_nacimiento" formControlName="fecha_nacimientoVendedor"
                minlength="3" maxlength="20"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['fecha_nacimientoVendedor'].value == ''}" />
            </div>
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">NIF/NIE</label>
                <input class="form-control" type="text" id="numero_documento" formControlName="numero_documentoVendedor"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_documentoVendedor'].errors}">
            </div>
            <div class="col-sm-4">
                <label class="crear-editar-form requerido">Fecha validez NIF/NIE</label>
                <input type="date" class="form-control" name="validez_dni" formControlName="validez_dniVendedor" minlength="3"
                maxlength="20"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['validez_dniVendedor'].value == ''}" />
            </div>
            
        </div>
 
    </div>

    <div class="rowForm" *ngIf="!isEmpresaVendedor">
        <div class="row">
            <!--<label class="crear-editar-form requerido col-sm-6">Tipo documento*</label>-->
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Nombre administrador</label>
                <input class="form-control" type="text" formControlName="nombreVendedor"
               [ngClass]="{'is-invalid': isRequired && this.form.controls['nombreVendedor'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Primer apellido administrador</label>
                <input class="form-control" type="text" formControlName="apellido1Vendedor"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido1Vendedor'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Segundo apellido administrador</label>
                <input class="form-control" type="text" formControlName="apellido2Vendedor"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['apellido2Vendedor'].errors}">
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">NIF/NIE administrador</label>
                <input class="form-control" type="text" id="numero_documento" formControlName="numero_documentoVendedor"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_documentoVendedor'].errors}">
            </div>
            
        </div>
 
    </div>

    <div class="rowForm">
        <div class="row">
            <div class="col-sm-6">
                <label class="crear-editar-form requerido">Móvil</label>
            <input class="form-control outlinenone" type="text" mask="9{9}" id="telefono" formControlName="telefonoVendedor"
            [ngClass]="{'is-invalid': isRequired && this.form.controls['telefonoVendedor'].errors}">
            </div>
            <div class="col-sm-6">
                <label class="crear-editar-form requerido">Correo electrónico</label>
                <input type="email" name="email" class="form-control" formControlName="emailVendedor"
                [ngClass]="{'is-invalid': isRequired&& this.form.controls['emailVendedor'].errors}" />
            </div>
            </div>
        </div>
    
            <div class="rowForm">
                <div class="row">
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Tipo de vía</label>
                        <select formControlName="tipo_viaVendedor" class="form-control"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['tipo_viaVendedor'].errors}">
                            <option *ngFor="let vias of ListVias" [value]="vias.id">
                                {{ vias.tipovianormalizado }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Dirección</label>
                            <input type="text" formControlName="nombre_viaVendedor" class="form-control"
                            [ngClass]="{'is-invalid': isRequired && this.form.controls['nombre_viaVendedor'].errors}" />
                    </div>
                    
    
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Nº de la vía</label>
                        <input type="text" mask="9{5}" formControlName="numero_viaVendedor" class="form-control"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['numero_viaVendedor'].errors}" />
                    </div>
                    
                    <div class="col-sm-3">
                        <label class="crear-editar-form">Bloque</label>
                        <input type="text" formControlName="bloqueVendedor" class="form-control" />
                    </div>
                    
                </div>
            
            </div>
            <div class="rowForm">
            <div class="row ">
            <div class="col-sm-3">
                <label class="crear-editar-form">Escalera</label>
                <input type="text" formControlName="escaleraVendedor" class="form-control" />
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form">Planta</label>
                    <input type="text" formControlName="pisoVendedor" class="form-control" />
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form">Puerta</label>
                <input type="text" formControlName="puertaVendedor" class="form-control" />
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form">Km</label>
                <input type="text" formControlName="kmVendedor" class="form-control" />
            </div>
            
        </div>
    </div>
    
    <div class="rowForm">
        <div class="row">
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Comunidad autónoma</label>
                <select formControlName="ccaaVendedor" class="form-control" (change)="onChangeProvincia($event,true)"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['ccaaVendedor'].errors}">
                    <option *ngFor="let ccaa of ListComunidades" [value]="ccaa.id">
                        {{ ccaa.nombre }}
                    </option>
                </select>
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Provincia</label>
                <select formControlName="provinciaVendedor" class="form-control" (change)="onChangeMunicipio($event,true)"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['provinciaVendedor'].errors}">
                    <option *ngFor="let provincia of ListProvinciasVendedor" [value]="provincia.id">
                        {{ provincia.nombre }}
                    </option>
                </select>
            </div>

            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Municipio</label>
                <select formControlName="municipioVendedor" class="form-control"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['municipioVendedor'].errors}">
                    <option *ngFor="let municipio of ListMunicipiosVendedor" [value]="municipio.municipioPK['municipio_id']">
                        {{ municipio.nombre }}
                    </option>
                </select>
            </div>
            
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Código postal</label>
                <input type="text" mask="9{5}" formControlName="codigo_postalVendedor" class="form-control"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['codigo_postalVendedor'].errors}">
            </div>
        </div>
    </div>
</form>

<div class="formTitle">
    <label class="formTitleText">Datos vehículo</label>
</div>

<div class="wideFormGroup">
    <form [formGroup]=" form">
    
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-3" >
                    <label class="crear-editar-form requerido">Tipo de vehículo</label>
                    <div class="d-flex justify-content-start col-sm-12 pl-1">
                        <div class="d-flex justify-content-start">
                            <div class="d-flex align-items-center">
                                <input class="checkbox" type="checkbox" [checked]="!isMoto" (change)="isMotoOnchanged()">
                                <label class="textLabel">Coche</label>
                            </div>                
                            <div class="spaceCheckbox d-flex align-items-center">
                                <input class="checkbox" type="checkbox" [checked]="isMoto" (change)="isMotoOnchanged()">
                                <label class="textLabel">Moto</label>
                            </div> 
                        </div>
                        </div>             
                    </div>
                    <div class="col-sm-3">
                        <label class="crear-editar-form requerido">Fecha matriculación</label>
                        <input type="date" class="form-control" name="fecha_matriculacion" formControlName="fecha_matriculacion"
                        minlength="3" maxlength="20"
                        [ngClass]="{'is-invalid': isRequired && this.form.controls['fecha_matriculacion'].value == ''}" />
                    </div>
    
                    <div class="col-sm-3 d-flex justify-content-start align-items-center">
                        <input class="mr-1 pr-1" type="checkbox" name="historico"  (change)="isHistoricoOnchanged()" class="checkbox">
                        <label class="textLabel">Vehículo histórico</label>
                    </div>
              
                    <div class="col-sm-3 d-flex justify-content-start align-items-center ">
                        
                            <input type="checkbox" formControlName="isEconomicas" [checked]="isEconomicas"
                            (change)="isEconomicasOnchanged()" class="checkbox">
                            <label class="textLabel">Actividades económicas</label>
                        </div>
    
            </div>
                
         
                
        </div>
   
   
        <div class="rowForm">
            <div class="row">
            <div *ngIf="!isMoto" class="col-sm-3">
                <label class="crear-editar-form requerido">Marca</label>
                <select formControlName="marca" class="form-control" (change)="onChangeMarca($event)"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['marca'].errors}">
                    <option *ngFor="let marca of ListMarcas" [value]="marca">
                        {{ marca }}
                    </option>
                </select>
            </div>
            <div *ngIf="isMoto" class="col-sm-6">
                <label class="crear-editar-form requerido">Cilindrada</label>
                <input class="form-control" type="text" formControlName="cilindrada"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['cilindrada'].errors}">
            </div>

            <div  class="col-sm-3">
                <label class="crear-editar-form requerido">Combustible</label>
                <select formControlName="combustible" class="form-control" (change)="onChangeCombustible($event)"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['combustible'].errors}">
                    <option *ngFor="let combustible of ListCombustibles" [value]="combustible.id">
                        {{ combustible.tipo }}
                    </option>
                </select> 
            </div>
            
            <div *ngIf="!isMoto" class="col-sm-3">
                <label class="crear-editar-form requerido">Modelo</label>
                <select formControlName="modelo" class="form-control"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['modelo'].errors}">
                    <option *ngFor="let modelo of ListModelos" [value]="modelo">
                        {{ modelo }}
                    </option>
                </select>
            </div>
            <div class="col-sm-3">
                <label class="crear-editar-form requerido">Matrícula</label>
                <input class="form-control" type="text" formControlName="matricula"
                [ngClass]="{'is-invalid': isRequired && this.form.controls['matricula'].errors}">
            </div>    
        </div>
        </div>
       
    
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Bastidor</label>
                    <input class="form-control" type="text" formControlName="bastidor"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['bastidor'].errors}">
                </div>
                    
                
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Km</label>
                    <input class="form-control" type="text" formControlName="kilometros" mask="0*"
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['kilometros'].errors}">
                </div>
                   
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Valor venta</label>
                    <input class="form-control" type="text" formControlName="valor" mask="0*.00" 
                    [ngClass]="{'is-invalid': isRequired && this.form.controls['valor'].errors}">
                </div>
                    
                <div class="col-sm-3">
                    <label class="crear-editar-form requerido">Hay factura</label>
                    <div class="d-flex justify-content-start">
                        <div class="d-flex align-items-center">
                            <input type="checkbox" [checked]="hayFact" (change)="hayFactOnchanged()" class="checkbox">
                            <label class="textLabel">Si</label>               
                        </div> 
                        <div class="spaceCheckbox d-flex align-items-center">
                            <input type="checkbox" [checked]="!hayFact" (change)="hayFactOnchanged()" class="checkbox">
                            <label class="textLabel">No</label>  
                        </div>  
                    </div>   
                    <label class="textLabel">Sí hay factura la tasa de ITP no se aplica</label>
                </div>

            </div>
        </div>
  
 


    </form>



    <form [formGroup]="form"  >
        <div class="rowForm">
            <div class="row">
                <div class="col-sm-12 d-flex justify-content-start">
                    <div class="d-flex align-items-center">
                        <input class="checkbox" type="checkbox" formControlName="isHerencia" (change)="isHerenciaOnchanged()">
                        <label class="textLabel">Herencia</label>
                    </div>                
                    <div class="spaceCheckbox d-flex align-items-center">
                        <input class="checkbox" type="checkbox" formControlName="isEmbargo" (change)="isEmbargoOnchanged()">
                        <label class="textLabel">Embargo</label>
                    </div> 
                </div>
                
            </div> 
        </div>
        
        
    </form>
    <div *ngIf="!isMod" class="d-flex justify-content-end rowForm">
        <button type="button" class="btn btn-primary" (click)="onSubmit() " type="submit">Guardar y continuar</button>
    </div>
</div>


