import { Component } from '@angular/core';
import { AvisoLegalService } from '../_services/hencok/aviso-legal.service';

@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html',
  styleUrls: ['./aviso-legal.component.css']
})
export class AvisoLegalComponent {
  politica_privacidad_list: any;
  email: string = "";
  emailLink: string = "";
  email_kdigital: string = "";
  emailLink_kdigital: string = "";

  stxt_hencok: string = "";
  stxt_hencok_web: string = "";
  stxt_hencok_dot: string = "";
  stxt_hencok_upper: string = "";
  stxt_kdigital: string = "";
  firstTime = true;
  constructor(private _avisoLegalService: AvisoLegalService){
    this.getList();
  }
  getList(){
    this._avisoLegalService.getAviso().subscribe((data) => {
      this.politica_privacidad_list = data.avisoLegal;
      this.getEmail("email","kdigital_email");
      this.getStxt();
      this.firstTime=false;
    })
    
  }

  getText(identifier){
    var content
    if(this.firstTime==true){
    }
    else{
      var content
      for(var item of this.politica_privacidad_list){
        if(item.identifier == identifier){
          content = item.text;
        }
      }
      return content;
    } 
  }

  getEmail(identifier,identifier2){
    for(var item of this.politica_privacidad_list){
      if(item.identifier == identifier){
        this.email = item.text;
        this.emailLink = "mailto:",this.email;
      }
      if(item.identifier == identifier2){
        this.email_kdigital = item.text;
        this.emailLink_kdigital = "mailto:",this.email;
      }
    }
  }
  getStxt(){
    for(var item of this.politica_privacidad_list){
      if(item.identifier == "stxt_hencok_web"){
        this.stxt_hencok_web = item.text;      }
      if(item.identifier == "stxt_hencok"){

        this.stxt_hencok = item.text;        
      }
      if(item.identifier == "stxt_hencok_dot"){

        this.stxt_hencok_dot = item.text;        
      }
      if(item.identifier == "stxt_hencok_upper"){

        this.stxt_hencok_upper = item.text;        
      }
      if(item.identifier == "stxt_kdigital"){

        this.stxt_kdigital = item.text;        
      }
    }
  }
}
