import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ContratoService } from 'src/app/_services/hencok/contrato.service';
import { NexcloudService } from 'src/app/_services/nexcloud.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { Contrato } from 'src/app/model/hencok/contrato';

@Component({
  selector: 'app-laboral-cliente',
  templateUrl: './laboral-cliente.component.html',
  styleUrls: ['./laboral-cliente.component.css']
})
export class LaboralClienteComponent {
  constructor(
    private contratoService: ContratoService,
    private notificacionService: NotificationService,
    private nextCloud: NexcloudService,
    private route: ActivatedRoute
  ) { }
  listadoLaboral:Contrato[]
  tipoLaboral: string = "CONTRATO";
  listadoAnnios: string[] = [];
  annioImpuesto: string = "2023";
  user = this.route.snapshot.params['id'];
  totalElements: number = 0;


  ngOnInit() {
    this.listByTipo({ tipo: this.tipoLaboral}, this.user)
  }


  public listByTipo(request, id) {
    return this.contratoService.indexByTipo(request, id).subscribe((data) => {
      console.log(data)
      if (data.status != 630) { //Si encuentra elementos en la consulta
        this.listadoLaboral = data.contrato.content;
        this.totalElements = data.contrato.totalElements;
        this.listadoAnnios = data.annios;       
      } else {
        this.listadoLaboral = [];
        this.totalElements = 0;
      }
    })
  }




  public changeTipoContrato(event) {
    
    this.tipoLaboral = event.target.innerText;
    this.listByTipo({tipo: this.tipoLaboral, annio: this.annioImpuesto }, this.user);
  }
  public listByAnnio(event) {
    
    this.annioImpuesto = event.target.value.toLowerCase();
    this.listByTipo({tipo: this.tipoLaboral, annio: this.annioImpuesto }, this.user)
  }

  openPdf(laboral: Contrato) {
    this.nextCloud.downloadJustificanteB64(laboral.idDocumento)
      .subscribe({
        next: (documento) => {
          const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        },
        error: (error) => {
          this.notificacionService.error('Error', 'No se ha podido abrir el archivo', 2000);
        }
      })
  }

  downloadPdf(laboral: Contrato) {
    this.nextCloud.downloadJustificanteB64(laboral.idDocumento).subscribe({
      next: (documento) => {
        const linkSource = 'data:application/pdf;base64,' + documento.Result;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.click();

        this.notificacionService.success('Hecho', 'Pdf descargado con éxito', 2000);

      },
      error: (error) => {
        this.notificacionService.error('Error', 'No se ha podido descargar el archivo', 2000);
      }
    })
  }
  nextPage(event: PageEvent) {
    const request = {tipo: this.tipoLaboral };
    request['page'] = event.pageIndex.toString();
    request['size'] = event.pageSize.toString();
    this.listByTipo(request, this.user);
  }
}
