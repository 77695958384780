import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Direccione } from 'src/app/model/third_model';
import { AuthService } from 'src/app/_services/auth.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-card-direcciones',
  templateUrl: './card-direcciones.component.html',
  styleUrls: ['./card-direcciones.component.css']
})
export class CardDireccionesComponent implements OnInit {

  @Input() index: number=0;
  @Input() direccion?: Direccione | undefined;

  TComunidades: any = [];
  TProvincias: any = [];
  provincias = this._servicioGeneral.getProvincias();
  TMunicipios: any = [];
  municipios = this._servicioGeneral.getMunicipios();
  TVias: any = [];
  TPais: any = [];
  TSolicitud: any = [];
  update: boolean = false;
  submitted: boolean = false;

  formDirecciones: UntypedFormGroup = new UntypedFormGroup(
    {
      tipovia: new UntypedFormControl("", Validators.required),
      nombrevia: new UntypedFormControl("", Validators.required),
      numerovia: new UntypedFormControl("", Validators.required),
      bloque: new UntypedFormControl(""),
      escalera: new UntypedFormControl(""),
      piso: new UntypedFormControl(""),
      puerta: new UntypedFormControl(""),
      codigopostal: new UntypedFormControl("", Validators.required),
      pais: new UntypedFormControl("", Validators.required),
      ccaa: new UntypedFormControl("", Validators.required),
      provincia: new UntypedFormControl("", Validators.required),
      municipio: new UntypedFormControl("", Validators.required),
      isactive: new UntypedFormControl(false)
    }
  );

  constructor(
    private _authService: AuthService,
    private _servicioGeneral: ServicioGeneral,
    private _notificationSvc: NotificationService,
    private token: TokenStorageService,
  ) { }

  ngOnInit(): void {
    this.getVias();
    this.getPais();
    this.getComunidades();
    this.getProvincias();
    this.getMunicipios();

    this.formDirecciones.patchValue({
      tipovia: this.direccion?.type_of_road.id??'',
      nombrevia: this.direccion?.name_road??'',
      numerovia: this.direccion?.number_road??'',
      bloque: this.direccion?.block??'',
      escalera: this.direccion?.stairs??'',
      piso: this.direccion?.floor??'',
      puerta: this.direccion?.door??'',
      codigopostal: this.direccion?.postal_code??'',
      pais: this.direccion?.country.id??'',
      ccaa: this.direccion?.ccaa.id??'',
      provincia: this.direccion?.province.id??'',
      municipio: this.direccion?.municipality??'',
      isactive: this.direccion?.isActive
    });
  }

  getComunidades() {
    return this._servicioGeneral.getComunidades().subscribe((data) => {
      this.TComunidades = data['ComunidadAutonoma'];
    });
  }

  getProvincias() {
    return this._servicioGeneral.getProvincias().subscribe((data) => {
      this.TProvincias = data['Provincia'];
    });
  }

  onChangeProvincia(event: Event) {
    this.TProvincias = [];
    const id = (event.target as HTMLInputElement).value;
    this.getProvinciasByID(id);
  }

  getProvinciasByID(id: string) {
    this.provincias.subscribe(
      (data) => {
        data['Provincia'].forEach((provincia) => {
          if (provincia.ccaa["id"] == id) {
              this.TProvincias.push(provincia);
              return this.TProvincias;
            }
        });
      });
  }

  getMunicipios() {
    if(!this.direccion?.province["id"]){
      return this._servicioGeneral.getMunicipios().subscribe((data) => {
        this.TMunicipios = data['Municipio'];
      });
    }else{
      return this.getMunicipiosByID(this.direccion?.province["id"])
    }
  }

  onChangeMunicipio(event: Event) {
    this.TMunicipios = [];
    const id = (event.target as HTMLInputElement).value;
    this.getMunicipiosByID(id);
  }

  getMunicipiosByID(id: string) {
    this.municipios.subscribe( (data) =>{
      data['Municipio'].forEach((municipio) => {
        if(municipio.municipioPK["provincia"] == id){
          this.TMunicipios.push(municipio);
          return this.TMunicipios
        }
      });
    });
  }



  getVias() {
    return this._servicioGeneral.getTipoVias().subscribe((data) => {
      this.TVias = data['TipoVia'];
    });
  }

  getPais() {
    return this._servicioGeneral.getPais().subscribe((data) => {
      this.TPais = data['Pais'];
    });
  }
  async updateDirection(): Promise<any>{
    this.submitted = true;
    this.update = true;

    if (!this.formDirecciones.valid) {
      this.update = false;
      this._notificationSvc.warning('Info', 'Rellene todos los campos por favor.', 2000);
      return false;
    } else {
      //console.log(this.direccion);
      
      if(this.direccion != undefined){
        //console.log('Entro a actualizar');
        if(
          this.formDirecciones.get('tipovia').value!=this.direccion?.type_of_road.id ||
          this.formDirecciones.get('nombrevia').value!=this.direccion?.name_road ||
          this.formDirecciones.get('numerovia').value!=this.direccion?.number_road ||
          this.formDirecciones.get('bloque').value!=this.direccion?.block ||
          this.formDirecciones.get('escalera').value!=this.direccion?.stairs ||
          this.formDirecciones.get('piso').value!=this.direccion?.floor ||
          this.formDirecciones.get('puerta').value!=this.direccion?.door ||
          this.formDirecciones.get('codigopostal').value!=this.direccion?.postal_code ||
          this.formDirecciones.get('pais').value!=this.direccion?.country["id"] ||
          this.formDirecciones.get('ccaa').value!=this.direccion?.ccaa["id"] ||
          this.formDirecciones.get('provincia').value!=this.direccion?.province["id"] ||
          this.formDirecciones.get('municipio').value!=this.direccion?.municipality ||
          this.formDirecciones.get('isactive').value!= this.direccion?.isActive
        ) {
          //console.log('Entro a cambio');
          this.direccion.type_of_road.id = this.formDirecciones.get('tipovia').value;
          this.direccion.name_road=this.formDirecciones.get('nombrevia').value;
          this.direccion.number_road = this.formDirecciones.get('numerovia').value;
          this.direccion.block  = this.formDirecciones.get('bloque').value;
          this.direccion.stairs  = this.formDirecciones.get('escalera').value;
          this.direccion.floor  = this.formDirecciones.get('piso').value;
          this.direccion.door  = this.formDirecciones.get('puerta').value;
          this.direccion.postal_code  = this.formDirecciones.get('codigopostal').value;
          this.direccion.country["id"]  = this.formDirecciones.get('pais').value;
          this.direccion.ccaa["id"]  = this.formDirecciones.get('ccaa').value;
          this.direccion.province["id"]  = this.formDirecciones.get('provincia').value;
          this.direccion.municipality  = this.formDirecciones.get('municipio').value;
          this.direccion.isActive = this.formDirecciones.get('isactive').value;          

          //Método de se actualiza la dirección
          (this._authService.updateUserDirection(
            this.direccion?.id,
            this.formDirecciones.get('tipovia').value as string,
            this.formDirecciones.get('nombrevia').value,
            this.formDirecciones.get('numerovia').value,
            this.formDirecciones.get('bloque')?.value,
            this.formDirecciones.get('escalera')?.value,
            this.formDirecciones.get('piso')?.value,
            this.formDirecciones.get('puerta')?.value,
            this.formDirecciones.get('codigopostal').value,
            this.formDirecciones.get('pais').value,
            this.formDirecciones.get('ccaa').value,
            this.formDirecciones.get('provincia').value,
            this.formDirecciones.get('municipio').value,
            this.formDirecciones.get('isactive').value
            )).subscribe({
                next: (updateData:any) => {
                  //console.log(updateData);
                  
                  this.token.setUser(updateData.Usuario);
                  this.update = true;
                  this._notificationSvc.success('Hecho','¡Datos de usuario actualizados!',2000);
                },
                error: (err) => {
                  this.update = false;
                  this._notificationSvc.error('Error', 'Alerta de Error: '+err.message ,20000);
                }
              });
            }else{
              this.update = false;
              this._notificationSvc.warning('Info', 'No se detectaron cambios.', 2000);
            }

      } else {
        //console.log('Entro a cambiar dir');
        //Método de setear dirección
        (this._authService.setUserDirection(
          this.formDirecciones.get('tipovia').value,
          this.formDirecciones.get('nombrevia').value,
          this.formDirecciones.get('numerovia').value,
          this.formDirecciones.get('bloque')?.value,
          this.formDirecciones.get('escalera')?.value,
          this.formDirecciones.get('piso')?.value,
          this.formDirecciones.get('puerta')?.value,
          this.formDirecciones.get('codigopostal').value,
          this.formDirecciones.get('pais').value,
          this.formDirecciones.get('ccaa').value,
          this.formDirecciones.get('provincia').value,
          this.formDirecciones.get('municipio').value,
          this.formDirecciones.get('isactive').value
        )).subscribe({
            next: (updateData:any) => {              
              this.update = true;
              //console.log(updateData);
                  
              this.token.setUser(updateData.Usuario);
              this._notificationSvc.success('Hecho','¡Datos de usuario actualizados!',2000);
            },
            error: (err) => {
              this.update = false;
              this._notificationSvc.warning('Info', 'No se detectaron cambios.', 2000);
            }
          });
        }
    }
  }
}
