
import { Component, Input, SimpleChanges } from "@angular/core";
import {
  AbstractControl,
  Form,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/_services/notification.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { environment } from "src/environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { DatePipe } from "@angular/common";
import { FileHandle } from "./drag-drop.directive";
import { ImpuestosService } from "src/app/_services/hencok/impuestos.service";
import { GestionImpuestosComponent } from "../gestion-impuestos/gestion-impuestos.component";
@Component({
  selector: 'app-formulario-adjuntar-modelo',
  templateUrl: './formulario-adjuntar-modelo.component.html',
  styleUrls: ['./formulario-adjuntar-modelo.component.css']
})
export class FormularioAdjuntarModeloComponent {

  form: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    periodo_solicitud: new UntypedFormControl("", Validators.required),
    fecha_maxima: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_impuesto: new UntypedFormControl("", Validators.required),
    trimestre: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),
    usuario_id: new UntypedFormControl()

  });
  @Input() adjModelo: any;
  @Input() primeraVezM: boolean = false;

  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = "";
  submitted: boolean = false;
  register2 = false;
  InfoRegistro: any = [];
  environment = environment;
  isLoggedIn = false;
  arrayDocuments = [];
  tipos: any = [];
  trimestres:any = [];
  miTipoGasto: any = "";
  gastosaux: any = [];
  usuarios: any[] = [];
  usuario: any = -1;
  usuariosaux: any[] = [];;
  miUsuario: any;
  fechaActual: string;

  mostrado: any = false;
  auxiliar: boolean = false;

  pdfAuxiliar: string;
  userLogged: any;
  userAdmin: Boolean = false;
  name = "Angular 5";
  load: boolean;

  uploadDocuments: Boolean = false;

  constructor(
    private _notificationSvc?: NotificationService,
    private _router?: Router,
    private tokenStorageService?: TokenStorageService,
    private impuestoService?: ImpuestosService,
    public datepipe?: DatePipe,
    private _serviceGeneral?: ServicioGeneral,
    public _activatedRoute?: ActivatedRoute
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.gastosaux = [];
    this.usuariosaux = [];
    this.miTipoGasto = "";
    this.miUsuario = [];
    if (this.primeraVezM) {
      this.form.get("importe").setValue(this.adjModelo.importe + ".00"),
        this.form.get("concepto").setValue(this.adjModelo.concepto),
        this.form
          .get("periodo_solicitud")
          .setValue(this.formatDate(this.adjModelo.fechaCreacion));
      this.miTipoGasto = this.adjModelo.tipoGasto;
      this.tipos.forEach((value) => {
        if (this.miTipoGasto.nombre != value.nombre) {
          this.gastosaux.push(value);

        }

      });
    //  console.log(this.miTipoGasto)
      this.form.get("tipo_gasto").setValue(this.miTipoGasto.nombre)

      this.gastosaux.unshift(this.miTipoGasto);


      this.miUsuario = this.adjModelo.usuario;
      this.usuarios.forEach((value) => {

        if (this.adjModelo.usuario.id != value.id) {
          this.usuariosaux.push(value);
        }
      });
      this.usuariosaux.unshift(this.miUsuario);
      this.usuario = this.miUsuario
    }
  }

  ngOnInit(): void {
    // this.detalles.forEach(element => {
    //   this.sacarUrlPdf(element);
    // });

    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.userLogged = this.tokenStorageService.getUser();
    let rol = this.tokenStorageService.getRol()
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }

    this.register2 = false;
    this.getTrimestre();
    this.getTipoImpuesto();
    this.getUserList();
  }

  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => { },
    });
  }

  getTipoImpuesto() {
    return this.impuestoService.getTipoimpuesto().subscribe({
      next: (data) => {
        this.tipos = data;
      },
    });
  }
  getTrimestre(){
    return this.impuestoService.getTtrimestre().subscribe({
      next: (data) => {
        this.trimestres = data;
      },
    });
  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];
      },
      error: (error) => { },
    });
  }

  removeFile(index: number): void {
    this.arrayDocuments.splice(index, 1);
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  onFileChange(event) {
    if (
      event.target.files[0].type == "application/pdf"
    ) {
      const file = event.target.files[0];
      this.convertFile(file).subscribe((base64) => {
        let document = {
          nombre: event.target.files[0].name,
          mime: event.target.files[0].type,
          base64: base64,
        };
        this.arrayDocuments = [document];
        this.form.controls["documento"].setValue(document);
      });
    } else {
      this._notificationSvc.warning(
        "Informacion",
        "Solo se admiten archivos del tipo: PDF",
        2000
      );
    }
  }

  filesDropped(files: FileHandle[]): void {
    this.form.patchValue({
      documento: files,
    });
    this.convertFile(files[0].file).subscribe((base64) => {
      let document = {
        nombre: files[0].file.name,
        mime: files[0].file.type,
        base64: base64,
      };
      this.arrayDocuments = [document];
      this.form.controls["documento"].setValue(document);
    });
  }
  formatDate(date) {
    let month = "" + date[1];
    let day = "" + date[2];
    const year = date[0];
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return year + "-" + month + "-" + day;
  }
  
  async onSubmit(): Promise<any> {

    this.register2 = false;
  //  console.log("save", this.form.controls);

    if (this.form.controls['documento'].value === "" || this.form.controls['documento'].value.length == 0) {
    //  console.log("documento invalido");

      this.form.controls['documento'].setValue([]);
      this._notificationSvc.warning('Informacion', 'El documento es obligatorio', 2000);
      return false;
    }
   
    this.form.addControl('usuario_id', new UntypedFormControl);

  //  console.log(this.userAdmin);

    if (this.userAdmin) {
      if (this.usuario.id == null) {
        this._notificationSvc.warning('Informacion', 'El usuario es obligatorio', 2000);
        return false;
      }
      this.form.controls['usuario_id'].setValue(this.usuario.id);
    } else {

      this.form.controls['usuario_id'].setValue(this.userLogged.id);
    }

    this.form.controls["periodo_solicitud"].setValue(this.datepipe.transform(
      this.form.controls["periodo_solicitud"].value,
      "dd/MM/yyyy"
    ))
   
    if(this.form.controls["periodo_solicitud"].invalid){
      this._notificationSvc.warning(
        "Informacion",
        "El formato de la fecha no es correcto",
        2000
      );
      this.form.controls["periodo_solicitud"].setValue(null)
      return false;
      }

      this.form.controls["fecha_maxima"].setValue(this.datepipe.transform(
        this.form.controls["fecha_maxima"].value,
        "dd/MM/yyyy"
      ))
      if(this.form.controls["fecha_maxima"].invalid){
      this._notificationSvc.warning(
        "Informacion",
        "El formato de la fecha maxima no es correcto",
        2000
      );
      this.form.controls["fecha_maxima"].setValue(null)
      return false;
      }

      if(this.form.controls["periodo_solicitud"].value > this.form.controls["fecha_maxima"].value){
        this._notificationSvc.warning(
          "Informacion",
          "La fecha maxima de presentación no puede ser anterior al fecha de emisión",
          2000
        );
        return false;
      }

    if (!this.form.valid) {
    //  console.log("form invalid")
      this._notificationSvc.warning('Informacion', 'Rellene todos los campos por favor', 2000);
      return false;

    } else {
      this.submitted = true;
      this.uploadDocuments = true;
      this.form.addControl('admin_id', new UntypedFormControl);
      this.form.controls['admin_id'].setValue(this.userLogged.id)
    //  console.log(this.form.value)
  
       this.impuestoService.saveImpuesto(this.form.value).subscribe({
        next: (data) => {
        //  console.log(data);
          this.submitted = true;
          this._router.navigateByUrl('/admin-hencok').then(() => {
            this._router.navigate(['admin-hencok/impuestos']);
          });
          this._notificationSvc.success('Hecho', "Modelo guardado con exito", 2000);
        },
        error: (err) => {
          this.uploadDocuments = false;
        //  console.log(err);

        }
      })
    } 
  }
  closeSidebar(){
    GestionImpuestosComponent.closeSidebarClienteRemoto();
  }
}