import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFacturas'
})
export class FilterFacturasPipe implements PipeTransform {

  transform(items: any[], filter: Object): any {

    if (!items || !filter) {
        return items;
    }
    
    return items.filter(contact => {      
      return contact.numeroFactura.toLocaleLowerCase().match(filter) ||
        contact.estado.toLocaleLowerCase().match(filter)||
        contact.nombre.toLocaleLowerCase().match(filter)
        
        // || contact.telefono.toLocaleLowerCase().match(filter)
    });
  }

}
