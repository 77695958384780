import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Iban } from 'src/app/model/hencok/iban.model';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';

@Component({
  selector: 'app-ficha-cliente-no-admin',
  templateUrl: './ficha-cliente-no-admin.component.html',
  styleUrls: ['./ficha-cliente-no-admin.component.css']
})
export class FichaClienteNoAdminComponent {
  usuario: any;
  id: number;
  nombre: string;
  email: string;
  name_road: string;
  number_road: string;
  provincia: string;
  codigo_postal: string;
  telefono: string;
  numero_documento: string;
  //duda
  data: DataCliente = { mostrarFormulario: false, elementUpdate: null, isForUpdate: false, iban: null }
  //
  listaProvincias;
  submitted: boolean = false;
  isIban: boolean = true;
  constructor(
    private _authService: AuthService,
    private _tokenStorageService: TokenStorageService,
    private _serviceGeneral: ServicioGeneral,
    private _route: ActivatedRoute,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.getUser();
    this.getAllProvincias();

  }

  isValidId(id: string): boolean {
    // Por ejemplo, puedes verificar si el ID es un número válido o cumple con algún otro criterio.
    return !isNaN(Number(id)) && this.id == Number(id);
  }

  setInput() {
    this.isIban = false;
  }
  goEdit() {
    this._router.navigate(["/client-hencok/editarCliente/", this.id], { skipLocationChange: true })
  }
  iban: Iban = new Iban();

  getAllProvincias() {
    this._serviceGeneral.getProvincias().subscribe(
      (data) => {
        this.listaProvincias = data['Provincia'];
      }
    )
  }
  private async getUser(): Promise<void> {
    //console.log(this._tokenStorageService.getId())
    this._route.params.subscribe(params => {
      this.id = params['id'];
    });
    await this._serviceGeneral.getClientId(this.id, this._tokenStorageService.getId())
      .subscribe({
        next: (usuario) => {
          if (usuario == null) this._router.navigate(["/client-hencok/clientes/"])
          this.usuario = usuario;
          //console.log("usuario --->", this.usuario)

          this.nombre = this.usuario?.nombre
          this.email = this.usuario?.email
          this.numero_documento = this.usuario?.documento
          this.name_road = this.usuario?.domicilio
          this.number_road = this.usuario?.numero_direccion
          this.provincia = this.usuario?.localidad
          this.codigo_postal = this.usuario?.codigo_postal
        },
        error: (error) => {
          this._router.navigate(["/client-hencok/clientes/"])
        }
      });
  }


  getFirstLetter(nombre: string) {
    return nombre?.charAt(0).toUpperCase();
  }
  ibanData;
  setMostrarFormulario(mode: Boolean, element?: any, isForUpdate?: Boolean) {

    this.data = { mostrarFormulario: mode, elementUpdate: element, isForUpdate: isForUpdate, iban: this.iban }
  }

}

export interface DataCliente {
  elementUpdate: any; // -- Campo para el caso de actualizar
  isForUpdate: Boolean;
  mostrarFormulario: Boolean;
  iban: any;
}