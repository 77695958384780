<div class="d-flex flex-row justify-content-between align-items-center full-form-group">
    <h1 class="m-0">Editar cliente</h1>
</div>
<div class="ml-5">
    <hr class="linea-divisoria mr-4">
</div>



<div class="col-sm-4 d-flex justify-content-around mb-5">
    <div class="iconName rounded-circle" style="width: 100px; height: 100px;">
        <p>{{getFirstLetter(usuario?.nombre)}}{{getFirstLetter(usuario?.apellido1)}}</p>
    </div>
    <div>
        <b>
            <p id="id"><b>#ID{{usuario?.idVisible}}</b></p>
        </b>
        <p id="info"> <b>{{usuario?.nombre}} {{usuario?.apellido1}}</b></p>
        <p id="info">{{usuario?.email}}</p>
    </div>
</div>
<div class="ml-5">
    <hr class="linea-divisoria mr-4">
</div>

<form [formGroup]="formCliente" (ngSubmit)="submit()" class="crear-editar-form ml-4">

    <div class="col-sm-12 pt-3 d-flex">
        <div class="col-sm-3">
            <p id="info"><b>{{userAdmin ? 'Nombre' : 'Nombre y apellidos'}}</b></p>
            <input class="form-control outlinenonedisable" type="text" id="nombre" formControlName="nombre"
                [ngClass]="{'is-invalid': submitted && this.formCliente.controls['nombre']}">
        </div>
        <div class="col-sm-3">
            <p id="info"><b>NIF/CIF</b></p>
            <input class="form-control outlinenonedisable" type="text" id="documento" formControlName="documento"
                [ngClass]="{'is-invalid': submitted && this.formCliente.controls['numero_documento']}">
        </div>
        <div class="col-sm-3">
            <p id="info"><b>Email</b></p>
            <input class="form-control outlinenone" type="text" id="email" formControlName="email"
                [ngClass]="{'is-invalid': submitted && this.formCliente.controls['email']}">
        </div>
    </div>
    <hr class="ml-3 mr-3">
    <div class="col-sm-12 pt-5 d-flex">
        <div class="col-sm-3">
            <p id="info"><b>Calle (direccion)</b></p>
            <input class="form-control outlinenone" type="text" id="name_road"
                [formControlName]="userAdmin ? 'name_road' : 'domicilio'"
                [ngClass]="{'is-invalid': submitted && this.formCliente.controls['name_road']}">
        </div>
        <div class="col-sm-3">
            <p id="info"><b>Número (direccion)</b></p>
            <input class="form-control outlinenone" type="text" id="number_road"
                [formControlName]="userAdmin ? 'number_road' : 'numero_direccion'"
                [ngClass]="{'is-invalid': submitted && this.formCliente.controls['number_road']}">
        </div>
        <div class="col-sm-3">
            <p id="info"><b>Localidad</b></p>
            <input class="form-control outlinenone" type="text" id="localidad" formControlName="localidad"
                [ngClass]="{'is-invalid': submitted && this.formCliente.controls['localidad']}">
        </div>
        <div class="col-sm-3">
            <p id="info"><b>C. postal</b></p>
            <input class="form-control outlinenone" type="text" id="codigo_postal" formControlName="codigo_postal"
                [ngClass]="{'is-invalid': submitted && this.formCliente.controls['codigo_postal']}">
        </div>

    </div>
    <hr class="ml-3 mr-3">
    <button class="btn btn-primary ml-4 mx-3 mt-5" type="submit">Actualizar</button>
</form>