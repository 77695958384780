import { Component, OnInit, ViewChild } from "@angular/core";
import { TokenStorageService } from "../_services/token-storage.service";
import { AuthService } from "../_services/auth.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ServicioGeneral } from "../_services/servicio-general.service";
import { NotificationService } from "../_services/notification.service";
import { UserService } from "../_services/user.service";
import { firstValueFrom } from "rxjs";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { Direccione } from "../model/third_model";
import { RaizComponent } from "../raiz/raiz.component";


@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {

  constructor(
    private token: TokenStorageService,
    private _authService: AuthService,
    private _usuarioService: UserService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _servicioGeneral: ServicioGeneral,
    private _notificationSvc: NotificationService,
    private userService: UserService,
    private raiz: RaizComponent
  ) { }
  displayedColumns: string[] = ['id', 'nombre', 'Acciones'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //Variables
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = "";
  currentUser = this.token.getUser();
  errorQR: boolean = false;
  tfActivated: boolean = false;
  isShown: boolean = false;
  loading: boolean = false;
  update: boolean = false;
  submitted: boolean = false;
  newdirection: boolean = false;
  qr: any;
  TPersonas: any = [];
  TDocumentos: any = [];
  userFormDirecciones: Array<any> = [];
  numeroDirecciones: number = 0;
  TComunidades: any = [];
  TProvincias: any = [];
  provincias = this._servicioGeneral.getProvincias();
  TMunicipios: any = [];
  municipios = this._servicioGeneral.getMunicipios();
  TVias: any = [];
  TPais: any = [];
  direccion: Direccione | undefined ;
  dataSourceRepresento = new MatTableDataSource<any>();
  dataSourceMeRepresenta = new MatTableDataSource<any>();
  dataSourcePendienteMeRepresenta = new MatTableDataSource<any>();
  dataSourceSearchUser = new MatTableDataSource<any>();
  documentSearch;

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let password = group.get('password').value;
    let confirmPassword = group.get('password_confirmacion').value
    return password === confirmPassword ? null : { notSame: true }
  }

  form: FormGroup = new FormGroup( {
    nombre: new FormControl({value:"", disabled: true}, Validators.required), 
    apellido1: new FormControl({value:"", disabled: true}, Validators.required),
    apellido2: new FormControl({value:"", disabled: true}, Validators.required),
    tipo_persona: new FormControl({value:"", disabled: true}, Validators.required),
    tipo_documento : new FormControl({value:"", disabled: true}, Validators.required),
    telefono : new FormControl("", [Validators.required, Validators.pattern("[0-9]{9}")]),
    numero_documento : new FormControl({value:"", disabled: true}, Validators.required),
    tipo_via: new FormControl("", Validators.required),
    nombre_via: new FormControl("", Validators.required),
    numero_via: new FormControl("", Validators.required),
    bloque: new FormControl(""),
    escalera: new FormControl(""),
    piso: new FormControl(""),
    puerta: new FormControl(""),
    codigo_postal: new FormControl("", [Validators.required, Validators.pattern("^(?:0[1-9]\\d{3}|[1-4]\\d{4}|5[0-2]\\d{3})$")]),
    pais: new FormControl("", Validators.required),
    ccaa: new FormControl("", Validators.required),
    provincia: new FormControl("", Validators.required),
    municipio: new FormControl("", Validators.required),
  }, {});

  form2: FormGroup = new FormGroup({});

  form3: FormGroup = new FormGroup({
    totpkey: new FormControl("")
  });

  //Init
  ngOnInit(): void {
    //this.token.saveUser();
    this.getDocumentos();
    this.getTipoPersona();
    this.getVias();
    this.getPais();
    this.getComunidades();
    this.getProvincias();
    this.userData();
    this.update = false;

    if(this.currentUser.tfa && this.currentUser.tfa != "disabled"){
      this.tfActivated = true;
    } 
  }

  //Funciones
  nuevaDireccion() {
    let auxDirection: Direccione = undefined;
    this.userFormDirecciones.push(auxDirection);
  }


  getFirstLetter(nombre: string) {
    return nombre.charAt(0).toUpperCase();
  }

  userData():void {
    if(this.currentUser.email==null){
      this.form.addControl("email", new FormControl("", Validators.required));
      this.form.addControl("password", new FormControl("", [Validators.required, Validators.minLength(6)]));
      this.form.addControl("password_confirmacion", new FormControl("", [Validators.required,]));
      this.form.addValidators(this.checkPasswords);
    }

    this.form.patchValue({
      tipo_persona: (this.currentUser.tipopersona) ? this.currentUser.tipopersona["id"] : '',
      nombre: this.currentUser.nombre,
      apellido1: this.currentUser.apellido1,
      apellido2: this.currentUser.apellido2,
      telefono: (this.currentUser.telefono) ? this.currentUser.telefono : '',
      tipo_documento: (this.currentUser.tipo_documento) ? this.currentUser.tipo_documento["id"] : '',
      numero_documento: (this.currentUser.numero_documento) ? this.currentUser.numero_documento : '',
    });
    
    if (this.currentUser.direcciones.length != 0) {
      this.direccion=this.currentUser.direcciones[0];
      this.getMunicipios();
      //console.log(this.direccion?.municipality);
      this.form.patchValue({
        tipo_via: this.direccion?.type_of_road.id??'',
        nombre_via: this.direccion?.name_road??'',
        numero_via: this.direccion?.number_road??'',
        bloque: this.direccion?.block??'',
        escalera: this.direccion?.stairs??'',
        piso: this.direccion?.floor??'',
        puerta: this.direccion?.door??'',
        codigo_postal: this.direccion?.postal_code??'',
        pais: this.direccion?.country.id??'',
        ccaa: this.direccion?.ccaa.id??'',
        provincia: this.direccion?.province.id??'',
        municipio: this.direccion?.municipality??'',
        isactive: this.direccion?.isActive
      });
    } 
    
    /*else {
      this.nuevaDireccion();
      this.newdirection = !this.newdirection;
    }*/
    if(this.currentUser.email==null){
      this._notificationSvc.info('Atención.','Debe completar sus datos de perfil para acceder a trámites',2000);
    }
  // this.consultarStatus();
  //this.getRepresentedUsers();

  }

  async onSubmit(): Promise<any> {
    this.submitted = true;
    this.update = false;


    if(this.form.get('codigo_postal').hasError('pattern')){
      this._notificationSvc.error('Error', 'No es un código postal válido.', 2000);
      return false;
    }
    if(this.currentUser.email==null){
      if(this.form.get('password').hasError('minLength')){
        this._notificationSvc.error('Error', 'La contraseña debe tener al menos 6 caracteres', 2000);
        return false;
      }
  
      if(this.form.hasError('notSame')){
        this._notificationSvc.error('Error', 'Las contraseñas no coinciden', 2000);
        this.form.controls['password_confirmacion'].setErrors({'incorrect': true});
        return false;
      }
    }

    if(!this.form.valid) {
      this._notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
      return false;
    } else {
      
      this._authService.updateUser(this.form.value).subscribe({  
        next: async data => {
          //console.log(data);
        
          this.token.saveToken(data.accessToken);
          this._usuarioService.getCurrentUserFromServer().subscribe({
            next: async data => { 
              this.token.setUser(data.Usuario);
              this._notificationSvc.success('Hecho', "Se ha actualizado con éxito", 2000);          
              this.isSignUpFailed = false;
              this.update = true;
              this.raiz.reloadHeader=!this.raiz.reloadHeader;
              this._router.navigate(['/inicio']);
            },
            error: err => {
              //console.log(err);
              this.update = false;
              
              if(err.status == 226){
                this._notificationSvc.error('Error', err.error.text, 2000);
                this.form.controls['numero_documento'].setErrors({'incorrect': true});
              } 
    
              if(err.status == 406){
                this._notificationSvc.error('Error', err.error , 2000);
                this.form.controls['numero_documento'].setErrors({'incorrect': true});
              }
    
              if(err.error.status == 628){
                this._notificationSvc.error('Error', err.error.message , 2000);
                this.form.controls['email'].setErrors({'incorrect': true});
              }
    
              if(err.error.status == 627){
                this._notificationSvc.error('Error', err.error.message , 2000);
                this.form.controls['email'].setErrors({'incorrect': true});
              }
              this.isSignUpFailed = true;
            }
          });
         
        },
        error: err => {
          //console.log(err);
          this.update = false;
          
          if(err.status == 226){
            this._notificationSvc.error('Error', err.error.text, 2000);
            this.form.controls['numero_documento'].setErrors({'incorrect': true});
          } 

          if(err.status == 406){
            this._notificationSvc.error('Error', err.error , 2000);
            this.form.controls['numero_documento'].setErrors({'incorrect': true});
          }

          if(err.error.status == 628){
            this._notificationSvc.error('Error', err.error.message , 2000);
            this.form.controls['email'].setErrors({'incorrect': true});
          }

          if(err.error.status == 627){
            this._notificationSvc.error('Error', err.error.message , 2000);
            this.form.controls['email'].setErrors({'incorrect': true});
          }
          this.isSignUpFailed = true;
        }
      });
    }
  }

  searchUser(): any {
    /*this._authService.getUserData().subscribe({
      next: (data) => {
         data;
        firstValueFrom(this.userService.getCurrentUserRepresented()).then((dataRepresented) => {
          this.currentUser.representados = dataRepresented.representa
          this.currentUser.esRepresentado = dataRepresented.representado_por
        })
      },
      error: (error) => {
        //console.log(error);

      }
    })*/
  }


  pedirQR(): any {
    this.loading = true;
    this._authService.pedirQr().subscribe({
      next: (date) => {
        this.qr = date;
        setTimeout(() => {
          this.isShown = !this.isShown;
          this.loading = false;
        }, 1000)
      },
      error: (err) => {
        //console.log(err);
      }
    });
  }

  guardarQR(): any {
    //console.log(this.form3.get('totpkey').value)
    this._authService.guardarQR(this.qr.secret, this.form3.get('totpkey').value).subscribe({
      next: (data) => {
        this.qr = data;
        if (this.qr.result === "success") {
          this.tfActivated = true;
          this.errorQR = false;
          this.isShown = false;
        } else {
          this.errorQR = true;
        }
      },
      error: (err) => {
        //console.log(err);
      }
    });
  }

  quitarQR(): any {
    this._authService.quitarQR(false).subscribe({
      next: (data) => {
        this.qr = data;
        if (this.isShown) {
          !this.isShown;
        }
        this.tfActivated = false;
        this.form3 = this._formBuilder.group({
          totpkey: [""],
        });
      },
      error: (err) => {
        //console.log(err);
      }
    })
  }

  consultarStatus(): any {
      if (!this.currentUser.twoFactorEnabled) {
         this.tfActivated = false;
       } else {
         this.tfActivated = true;
       }
  }

  scrollTop() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  reloadToMenuOption(isAllPage: Boolean) {
    if (isAllPage) {
      var url = new URL(window.location.href);
      url.searchParams.set('action', '20');
      window.location.href = url.href;
    } else {
      this._router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this._router.onSameUrlNavigation = 'reload';
      this.scrollTop()
      this._router.navigate(['/admin'], { queryParams: { action: 20 } });
    }
  }

  getTipoPersona() {
    return this._servicioGeneral.getTipoPersona().subscribe((data) => {
      this.TPersonas = data['TipoPersona'];
    });
  }

  getDocumentos() {
    return this._servicioGeneral.getTipoDocumento().subscribe((data) => {
      this.TDocumentos = data['TipoDocumento'];
    });
  }
  
  get documento() {
    return this.form.get("numero_documento");
  }

  getComunidades() {
    return this._servicioGeneral.getComunidades().subscribe((data) => {
      this.TComunidades = data['ComunidadAutonoma'];
    });
  }

  getProvincias() {
    return this._servicioGeneral.getProvincias().subscribe((data) => {
      this.TProvincias = data['Provincia'];
    });
  }

  onChangeProvincia(event: Event) {
    this.TProvincias = [];
    const id = (event.target as HTMLInputElement).value;
    this.getProvinciasByID(id);
  }

  getProvinciasByID(id: string) {
    this.provincias.subscribe(
      (data) => {
        data['Provincia'].forEach((provincia) => {
          if (provincia.ccaa["id"] == id) {
              this.TProvincias.push(provincia);
              return this.TProvincias;
            }
        });
      });
  }

  getMunicipios() {
    if(!this.direccion?.province["id"]){
      return this._servicioGeneral.getMunicipios().subscribe((data) => {
        this.TMunicipios = data;
      });
    }else{
      return this.getMunicipiosByID(this.direccion?.province["id"])
    }
  }

  onChangeMunicipio(event: Event) {
    this.TMunicipios = [];
    const id = (event.target as HTMLInputElement).value;
    this.getMunicipiosByID(id);
  }

  getMunicipiosByID(id: string) {
    this.municipios.subscribe( (data) =>{
      data['Municipio'].forEach((municipio) => {
        if(municipio.municipioPK["provincia"] == id){
          this.TMunicipios.push(municipio);
          return this.TMunicipios
        }
      });
    });
  }

  getVias() {
    return this._servicioGeneral.getTipoVias().subscribe((data) => {
      this.TVias = data['TipoVia'];
    });
  }

  getPais() {
    return this._servicioGeneral.getPais().subscribe((data) => {
      this.TPais = data['Pais'];
    });
  }

  sendAnswer(accept, idUser) {
    firstValueFrom(this.userService.sendAnswerRepresented(accept, idUser)).then(() => {
      this.token.saveUser();
      this.reloadToMenuOption(true);
    })
  }

  searchUserByDocument() {

    firstValueFrom(this.userService.searchUserByDocumemt(this.documentSearch)).then((data) => {
      this.dataSourceSearchUser.data.push(data);

    })
  }

  sendSolcitud(idUser) {
    firstValueFrom(this.userService.sendRequestRepresented(idUser)).then((data) => {
      //console.log(data);

    })
  }

}