<div class="pageTitle">
    <h1 class="m-0">Nueva consulta</h1>
</div>
<div class="">
    <hr class="linea-divisoria-title">
</div>
<div class="full-form-group">
    <form [formGroup]=" form " class="pb-3 crear-editar-form row d-flex">

        <div class="rowForm">
                <div class="col-sm-12">
                    <label class="crear-editar-form requerido">Consulta</label>
        
                </div>
                    <div class="col-sm-12">
                        <input class="form-control" type="text" formControlName="concepto" placeholder="Qué dudas o consultas tienes, escribe a continuación"
                            [ngClass]="{'is-invalid': isRequired && this.form.controls['concepto'].errors}">
                    </div>
        
            </div>
        <div class="rowForm">
                <div class="col-sm-3">
                    <label>Observaciones</label>
                </div>
                <div class="col-sm-12">
                    <textarea class="form-control outlinenone" formControlName="mensaje" rows="6" placeholder="Escribe aquí tu mensaje específico para poder resolverlo satisfactoriamente"
                        style="resize: none;"></textarea>
                </div>
        </div>

        
        
        <div class="col-12 row m-0 rowForm">
            <div class="row col-sm-12">
                <label>Documentos adjuntos</label>
            </div>
                <div class="col-12 body bodyBorder file-manager-content d-flex p-2 ml-0 mr-0">
                    <div class="border border backGround-colorAdjuntar borderAdjuntar adjuntar-area.active col-6 file-manager m-0"
                        appDrag (files)="filesDropped($event)" >
                        <input id="file" value="file" name="file"
                        class="pruebaInput" (change)="onFileChange($event)"
                        onclick="event.target.value=null" type="file" /> 
                        <div class="border rounded-circle cloudContainer">
            
                            <div class="text-center">
                                <a class="iconCloud"></a>
                            </div>
                        </div>
                        <div class="text-center">
                            <h6 class="texto mt-1">Arrastre aquí los archivos o haga clic para seleccionar</h6>
                            <h6 class="indicaciones mt-1">PDF de 20MB máximo. </h6>
                        </div>
            
                    </div>
                    <div *ngIf="this.arrayDocuments?.length>0" class="col-sm-6 pt-2">
                        <div *ngFor="let documento of this.arrayDocuments" class="p-0">
                            <div class="documento pb-1">
                                <a class="cortar-texto" (click)="abrirArchivo(documento.base64)">{{documento['nombre']}}</a>
                                <div class="card border-0 card-width-actions">
                                    <div>
                                        <a (click)="removeFile(arrayDocuments.indexOf(documento))"
                                            class="delete-button activated boton-eliminar"></a>
                                    </div>
                                </div>
                            </div>
                        </div>            
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <label>Datos de contacto</label>
            </div>
                <div class="formGroup bgLightGreen w-100">
                    <div>
                        <p><b>Correo de contacto:</b> info@hencokasesores.com</p>
                    </div>
                    <div>
                        <p><b>Teléfono de contacto:</b> 691846689</p>
                    </div>
                </div>
            <div *ngIf="!isMod" class="d-flex justify-content-end rowForm col-12">
                <button class="btn btn-primary" (click)="onSubmit() " type="submit">Guardar y continuar</button>
            </div>
            
    </form>
    
</div>

