<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Laboral</h1>
    <div class="button-header-row">
        <button (click)="changeTipoContrato($event)" class="btn btn-tertiary align-middle mr-3" [ngClass]="tipoLaboral == 'CONTRATO' ? 'btn-tertiary-selected' : 'btn-tertiary'">
            CONTRATOS
        </button>
        <button (click)="changeTipoContrato($event)" class="btn btn-tertiary align-middle mr-3" [ngClass]="tipoLaboral == 'NOMINA' ? 'btn-tertiary-selected' : 'btn-tertiary'">
            NOMINAS
        </button>
    </div>
</div>

<div>

    <div>
        <hr class="linea-divisoria">
    </div>

    <h2>{{tipoLaboral}}</h2>
    <!--<div class="button-header-row mb-5 mt-4">
        <button (click)="listByPeriodo($event)" value="anual" class="btn btn-tertiary align-middle mr-3" [ngClass]="periodoImpuesto == 'anual' ? 'btn-tertiary-selected' : 'btn-tertiary'">
            Anual
        </button>
        <button (click)="listByPeriodo($event)" value="trimestral" class="btn btn-tertiary align-middle mr-3" [ngClass]="periodoImpuesto == 'trimestral' ? 'btn-tertiary-selected' : 'btn-tertiary'">
            Trimestral
        </button>
    </div>-->

    <div class="select-filter-row my-4 d-flex flex-row">
        <div>
            <select class="form-select form-select-sm" aria-label="Default select example" (change)="listByAnnio($event)">
                <option *ngFor="let annio of listadoAnnios">{{annio}}</option>
            </select>
        </div>          
    </div>

    <ng-container>
        <div class="table-container w-100">
            <table class="w-100">
                <tr class="w-100 border-bottom px-3 pb-1 mb-3 text-muted table-header">
                    <th>Modelo</th>
                    <th>Periodo de solicitud</th>
                    <th>Estado</th>
                    <th>Acciones</th>
                </tr>
                <tr *ngFor="let laboral of listadoLaboral" class="list-group-item px-3 py-2 rounded shadow-sm">
                    <td class="d-flex flex-row align-items-center file-column"> 
                      {{laboral.concepto}}
                    </td>
                    <td>{{laboral.fechaEmision}}</td>
                    <td [ngClass]="{'pending-state': laboral.estado.nombre.toLowerCase() == 'pendiente',
                        'approved-state': laboral.estado.nombre.toLowerCase() == 'firmado'
                       }" class="d-flex flex-row align-items-center">
                        <span class="dot-prefix mr-1">&#x2022;</span> {{laboral.estado.nombre}}
                    </td>
                    <td>
                        <div class="buttons-flex-container">
                            <a class="eye-button activated" title="Ver" (click)="openPdf(laboral)"></a>
                            <a class="download-button activated" title="Descargar" (click)="downloadPdf(laboral)"></a>
                        </div>
                    </td>
                </tr>
                <div *ngIf="listadoLaboral?.length == 0" class="d-flex flex-row justify-content-center empty-data-message my-4">
                    <span>No se han encontrado registros que mostrar</span>
                </div>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements" *ngIf="listadoLaboral?.length > 0"
                (page)="nextPage($event)"> </mat-paginator>
    </ng-container>
</div>
