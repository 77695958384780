import { HTTP_INTERCEPTORS, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../_services/token-storage.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

const TOKEN_HEADER_KEY = 'accessToken';
const TOKEN_HEADER_KEY_AUTH = 'Authorization'
// for Spring Boot back-end
//const TOKEN_HEADER_KEY = 'x-access-token';   // for Node.js Express back-end


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService,
    private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = req;
    const token = this.token.getToken();
    
    if (token != null) { 
    
      if(this.token.isExpired(token)){
        console.log("is expired")
        this.token.signOutExpiration()
      } 
      authReq = req.clone({ headers:
         req.headers
         .set(TOKEN_HEADER_KEY_AUTH, 'Bearer ' + token)
         .set(TOKEN_HEADER_KEY, token)
         .set("Access-Control-Allow-Headers", "accessToken,Authorization")
         .set("Access-Control-Allow-Origin",'*')
        });
        

      // for Node.js Express back-end
      //authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
    }
    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, }
];