import { Component, OnInit } from '@angular/core';
import { Notification, NotificationType } from 'src/app/model/notification';
import { Observable, Observer, Subscription } from "rxjs";
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notifications: Notification[] = [];
  private _subscription: Subscription = new Subscription;

  constructor(private _notificationSvc: NotificationService) { }

private _addNotification(notification: Notification) {
    this.notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);

    }
  }

 ngOnInit() {
    this._subscription = this._notificationSvc.getObservable().subscribe(notification => this._addNotification(notification));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  close(notification: Notification) {
    this.notifications = this.notifications.filter(notif => notif.id !== notification.id);
  }


className(notification: Notification): string {

    let style: string;

    switch (notification.type) {

      case NotificationType.success:
        style = 'alert alert-success';
        break;

      case NotificationType.warning:
        style = 'alert alert-warning';
        break;

      case NotificationType.error:
        style = 'alert alert-danger';
        break;

      default:
        style = 'alert alert-primary';
        break;
    }

    return style;
  }
/*
    sequence = new Observable(this.toPromise).subscribe({
    next(num) { console.log("probando"); },
    complete() { console.log('Finished sequence'); }
  });;

  toPromise(observer: Observer<number>) {
    new Observable<string>(observer => {
      console.log(observer)
     this._notificationSvc.pruebaPost().subscribe( {

      next: (data) => {
        console.log(data);     
       },
     
     error: (error) => {
      console.log(error);
    }
  })
  

})
     return null;
 }
*/
/*
  prueba=new Observable((observer) => {
  
    console.log("estoy aquí")
   this._notificationSvc.pruebaPost().subscribe( {

    next: (data) => {
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA");     
     },
   
   error: (error) => {
    console.log("///////////////////////////////////////////////////////////");
  }
})})



*/


/*
  locations = new Observable((observer) => {
  let watchId: number;

  // Simple geolocation API check provides values to publish

    watchId = navigator.geolocation.watchPosition((position: GeolocationPosition) => {
      observer.next(position);
    }, (error: GeolocationPositionError) => {
      observer.error(error);
    });

    observer.error('Geolocation not available');
  

  // When the consumer unsubscribes, clean up data ready for next subscription.
  return {
    unsubscribe() {
      navigator.geolocation.clearWatch(watchId);
    }
  };
});

 locationsSubscription =  this._notificationSvc.pruebaPost().subscribe({
  next(position) {
    console.log('Current Position: ', position);
  },
  error(msg) {
    console.log('Error Getting Location: ', msg);
  }
});





*/
}