<div class="page_content_sidebar">
    <div class="content_inner">
        <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'container2':'container' ">

            <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'center-Hencok':'center'">
                <div *ngIf="!isLoggedIn">
                    <h1>{{environment.TITLE_REGISTER}}</h1>
                    <p>
                        {{environment.TEXT_REGISTER}}
                    </p>
                </div>
                <form *ngIf="!isSuccessful" name="form" (ngSubmit)="onSubmit()" [formGroup]="form" novalidate
                    method="post" enctype="multipart/form-data">
                    <div class="row pt-4">
                        <div class="d-flex justify-content-between col-sm-12 p-0">
                            <div class="col-sm-6 pl-4 d-flex p-0">
                                <h5>Nuevo Gasto</h5>
                            </div>
                            
                            <div *ngIf="userAdmin" class="col-sm-6 rowForm pr-4 mb-0">
                            
                                <div class="item-aligner">
                                    <select #user (change)="getUser(user.value)"
                                        class="colorOption mb-3">
                                        <option style="display:none">Seleccionar id</option>
                                        <option *ngFor="let usuario of usuarios" [value]="usuario.id"
                                            class="colorOption">
                                            #ID{{ usuario.id }}
                                        </option>
                                    </select>
                                </div>
                                
                                <div class="col-sm-12 w-100 pr-0">
                                    <p class="name-text"><b>{{usuario.nombre}} {{usuario.apellido1}} {{usuario.apellido2}}</b></p>
                                    <p class="name-text">{{usuario.email}}</p>
                                </div>
    
                            </div>
                            <div *ngIf="!userAdmin" class="col-sm-6 rowForm" >
                               
                            
                                <div class="col-sm-12 w-100">
                                    <p class="name-text"><b>{{usuario.nombre}} {{usuario.apellido1}} {{usuario.apellido2}}</b></p>
                                    <p class="name-text">{{usuario.email}}</p>
                                </div>
    
                            </div>
                                
                            


                            <div class="col-sm-6 rowForm" *ngIf="_activatedRoute.snapshot.url[1] && !userAdmin">
                                <div class="col-sm-12 w-100">
                                    <p class="colorId">#ID{{userLogged.id}}</p>
                                    <p><b>{{userLogged.nombre}} {{userLogged.apellido1}} {{userLogged.apellido2}}</b>
                                    </p>
                                    <p>{{userLogged.email}}</p>
                                </div>
                            </div>


                            <div *ngIf="_activatedRoute.snapshot.url[1] && userAdmin" class="col-sm-6 rowForm">
                                <div class="col-sm-12 rowForm">
                                    <div class="col-sm-12 w-100">
                                        <p class="colorId">#ID{{usuario.id}}</p>
                                        <p>Nombre : {{usuario.nombre}} {{usuario.apellido1}} {{usuario.apellido2}}</p>
                                        <p>Correo electrónico : {{usuario.email}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row paddingCustom lineas"
                            [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'border-top':'border'">
                            <div class="col-sm-12">
                                <label class="crear-editar-form requerido">Concepto</label>
                                <input type="text" class="form-control" name="concepto" formControlName="concepto"
                                    minlength="3" maxlength="20"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['concepto'].errors}" />

                            </div>
                            <div class="col-sm-6 rowForm">
                                <label class="crear-editar-form requerido">Fecha de alta</label>
                                <input type="date" class="form-control" name="fecha_creacion"
                                required pattern="\d{4}-\d{2}-\d{2}"
                                    formControlName="fecha_creacion" minlength="3" maxlength="20"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['fecha_creacion'].errors}" />

                            </div>
                            <div class="col-sm-6 rowForm">
                                <label class="crear-editar-form requerido">Importe</label>
                                <input type="text" placeholder="0.00" mask="0*.00" class="form-control" name="importe"
                                    formControlName="importe"
                                    [ngClass]="{'is-invalid': submitted && this.form.controls['importe'].errors}" />

                            </div>
                            <div class="col-sm-6 rowForm">
                                <label class="crear-editar-form requerido">Tipo</label>
                                <select formControlName="tipo_gasto" class="form-control tipo" name="tipo_gasto"
                                        [ngClass]="{'is-invalid': submitted && this.form.controls['tipo_gasto'].errors}">
                                        <option *ngFor="let gasto of gastos" [value]="gasto.id">
                                            {{ gasto.nombre}}
                                        </option>
                                    </select>
                            </div>
                            <br>
                            <div class="col-sm-12 ml-0 mb-1 rowForm">
                                <label class="crear-editar-form">Adjuntar Ficheros</label>
                            </div>
                            
                            <div class="col-12 row mx-0">
                                <div class="col-12 body bodyBorder file-manager-content d-flex p-2 m-0">
                                    <div class="border border backGround-colorAdjuntar borderAdjuntar adjuntar-area.active col-6 file-manager m-0"
                                        appDrag (files)="filesDropped($event)" >
                                        <input id="file" value="file" name="file"
                                        class="pruebaInput" (change)="onFileChange($event)"
                                        onclick="event.target.value=null" type="file" /> 
                                        <div class="border rounded-circle cloudContainer">
                
                                            <div class="text-center">
                                                <a class="iconCloud"></a>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <h6 class="texto mt-1">Arrastre aquí los archivos o haga clic para seleccionar</h6>
                                            <h6 class="indicaciones mt-1">PDF de 20MB máximo. </h6>
                                        </div>
                
                                    </div>
                                    <div class="col-sm-6 ordenar-archivos pt-2">
                                        <div *ngFor="let documento of this.arrayDocuments"
                                            class="documento pl-0 pb-1">
                                            <a class="cortar-texto">{{documento['nombre']}}</a>
                                            <div class="border-0 card-width-actions">
                                                <div>
                                                    <a (click)="removeFile(arrayDocuments.indexOf(documento))"
                                                        class="delete-button activated boton-eliminar"></a>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                            <div class="col-12 mt-4 w-100 d-flex justify-content-end">
                                <button type="button" class="btn  btn-outline-primary mr-2" (click)="closeSidebar()" [disabled]="submitted">
                                    <div>Cancelar</div>
                                </button>
                                <button type="submit" class="btn btn-primary" [disabled]="submitted">
                                    <div class="spinner-border spinner-border-sm" *ngIf="uploadDocuments"></div>
                                    <div *ngIf="!uploadDocuments">Guardar</div>
                                </button>
                            </div>
                        </div>
                        
                    </div>
                </form>
                <div class="alert alert-success" *ngIf="isSuccessful">
                    Se ha registrado satisfactoriamente!
                </div>
            </div>
        </div>
    </div>
</div>