import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '../token-storage.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DuplicadoFichaTecnicaService {

  protected resourceUrldupl = `${environment.API_URL}/tramite/duplicadoFichaTecnica`;
  protected API_URL = environment.API_URL

  constructor(
    protected http: HttpClient,
    protected token:TokenStorageService
  ) { }


  saveFicha(form: any) {
    console.log(form)
    return this.http.post(`${this.resourceUrldupl}/save`, form);
  }
  updateFicha(form: any,id) {
    return this.http.post(`${this.resourceUrldupl}/update/${id}`, form);
  }
  getFichaId(id: number) {
    return this.http.get(`${this.resourceUrldupl}/getById/${id}/${this.token.getId()}`);
  }

  signMandato(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrldupl}/signMandato`, {id});
  }

  downloadB64(id: number): Observable<any> {
    return this.http.post(`${this.resourceUrldupl}/getPdfMandato`, { id });
  }
  saveDocumentoModelo(id: number, documento: any): Observable<any> {
    return this.http.post(`${this.resourceUrldupl}/savePdfMandato`, { id, documento });
  }
}
