import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';
import { Iban } from '../../../model/hencok/iban.model';
import { Observaciones } from 'src/app/model/hencok/observaciones';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ClienteService } from 'src/app/_services/hencok/cliente.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { UserService } from 'src/app/_services/user.service';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-ficha-cliente-actualizar',
  templateUrl: './ficha-cliente-actualizar.component.html',
  styleUrls: ['./ficha-cliente-actualizar.component.css']
})
export class FichaClienteActualizarComponent implements OnInit {


  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let password = group.get('password').value;
    let confirmPassword = group.get('password_confirmacion').value
    return password === confirmPassword ? null : { notSame: true }
  }

  showConfirmPassword=false;
  showPassword=false;

  usuario: any;
  id: number;
  mostrarFormulario: Boolean = false;
  //duda
  data: DataCliente = { mostrarFormulario: false, elementUpdate: null, isForUpdate: false, iban: null }
  //
  form = new FormGroup({
    nombre: new FormControl(""),
    apellido1: new FormControl(""),
    email: new FormControl(""),
    name_road: new FormControl(null),
    number_road: new FormControl(null),
    provincia: new FormControl(null),
    codigo_postal: new FormControl(null),
    telefono: new FormControl(null),
    iban: new FormControl(null),
    entidad: new FormControl(null),
    titular: new FormControl(null),
    numero_documento: new FormControl(null),
    estado: new FormControl(null),
    observaciones: new FormControl(null),
  })

  formPass = new FormGroup({
    password: new FormControl("", [Validators.required, Validators.minLength(6)]),
    password_confirmacion: new FormControl(""),
  }, { validators: this.checkPasswords });

  iban: Iban = new Iban();
  observaciones = new Observaciones();
  listaProvincias;
  submitted: boolean = false;
  isIban: boolean = true;
  userRol:any;
  userlogged: any;
  passwordChange=false;

  userLogged: any;
  isAdmin: Boolean = false;

  constructor(
    private _authService: AuthService,
    private _serviceGeneral: ServicioGeneral,
    private _route: ActivatedRoute,
    private _router: Router,
    private _tokenStorageService: TokenStorageService,
    private _notificationService: NotificationService,
    private _userService: UserService,

  ) {


  }

  ngOnInit(): void {

    this.userRol = this._tokenStorageService.getRol();
    this.userLogged = this._tokenStorageService.getUser();
    this.getUser();
    this.getAllProvincias();

  }
  disabled() {
    this.form.controls['nombre'].disable();
    this.form.controls['apellido1'].disable();
  }
  initiateForm() {
    console.log(this.usuario)

    this.form = new FormGroup({
      nombre: new FormControl(this.usuario?.nombre),
      apellido1: new FormControl(this.usuario?.apellido1),
      email: new FormControl(this.usuario?.email),
      name_road: new FormControl(this.usuario?.direcciones[0]?.name_road),
      number_road: new FormControl(this.usuario?.direcciones[0]?.number_road),
      provincia: new FormControl(this.usuario?.direcciones[0]?.province?.id),
      codigo_postal: new FormControl(this.usuario?.direcciones[0]?.postal_code),
      telefono: new FormControl(this.usuario?.telefono),
      numero_documento: new FormControl(this.usuario?.numero_documento),
      estado: new FormControl(this.usuario?.active),
      iban: new FormControl(this.iban?.iban),
      entidad: new FormControl(this.iban?.entidad),
      titular: new FormControl(this.iban?.titular),
      observaciones: new FormControl(this.observaciones.observacion ? this.observaciones.observacion : ''),
    });


    this.disabled();

  }
  setInput() {
    this.isIban = false;
  }

  submit() {
    this.iban.iban = this.form.value.iban
    this.iban.entidad = this.form.value.entidad;
    this.iban.titular = this.form.value.titular;
    this.iban.usuario = new Usuario(this.usuario);
    let provincia;
    this.submitted = true;
    /* let password = '123456' */

    this._authService.updateClienteHencok(
      this.usuario?.id,
      this.form.value.nombre == null ? this.usuario?.nombre : this.form?.value?.nombre,
      this.form.value.apellido1 == null ? this.usuario?.apellido1 : this.form?.value?.apellido1,
      // this.form.value.apellido2 == null ? this.data?.elementUpdate?.apellido2 : this.form?.value?.apellido2,
      this.form.value.email == null ? this.usuario?.email : this.form?.value?.email,
      this.usuario?.tipopersona?.id,
      this.form?.value?.numero_documento == null ? this.usuario?.numero_documento : this.form?.value?.numero_documento,
      this.form.value.telefono == null ? this.usuario?.telefono : this.form?.value?.telefono,
      this.form.value.estado == null ? this.usuario?.active : this.form?.value?.estado


      // this.form.get('concepto')?.value,
      // this.form.get('fechaAlta')?.value,
      // this.form.get('importe')?.value,
      // this.form.get('Tipo')?.value,
      // this.form.get('IVA')?.value

    ).subscribe({
      next: async data => {

        this._authService.updateUserDirectionCliente(
          this.usuario?.id,
          this.usuario?.direcciones[0].id,
          this.usuario?.direcciones[0]?.type_of_road,
          this.form.value.name_road == null ? this.usuario?.direcciones[0]?.name_road : this.form.value.name_road,
          this.form.value.number_road == null ? this.usuario?.direcciones[0]?.number_road : this.form.value.number_road,
          this.usuario?.direcciones[0]?.bloque,
          this.usuario?.direcciones[0]?.escalera,
          this.usuario?.direcciones[0]?.piso,
          this.usuario?.direcciones[0]?.puerta,
          this.form.value.codigo_postal == null ? this.usuario.direcciones[0]?.postal_code : this.form.value.codigo_postal,
          this.usuario?.direcciones[0]?.country.id,
          this.usuario?.direcciones[0]?.ccaa.id,
          provincia = this.form.value.provincia == 0 ? null : (
            {
              id: this.form.value.provincia
            }),
          this.usuario?.direcciones[0]?.municipality,
          this.usuario?.direcciones[0]?.isActive,

        ).subscribe({
          next: async data => {
            //console.log(this.observaciones)

            this.observaciones.usuario = new Usuario(this.usuario);
            this.observaciones.observacion = this.form?.value.observaciones;
            //console.log(this.observaciones)

            this._authService.updateObservacion(this.observaciones).subscribe({
              next: async data => {
                this._notificationService.success(
                  "Éxito",
                  "Actualización exitosa",
                  5000
                );
                if(this.form.controls['iban'].value!=null){
                  this._authService.saveIban(this.form.controls['iban'].value).subscribe({
                    next: async data => {
                      let rol = this._tokenStorageService.getRol();
                      if (rol == 3) {
                        this._router.navigate(['admin-autoescuelas-hencok/clientes/']);
                      }
                      if (rol == 5 || rol == 6) {
                        this._router.navigate(['mod-autoescuelas-hencok/clientes/']);
                      }
                      else {
                        this._router.navigate(['client-hencok/clientes/']);
                      }
                    }
                  });
                }
                else{
                  let rol = this._tokenStorageService.getRol();
                  if (rol == 3) {
                    this._router.navigate(['admin-autoescuelas-hencok/clientes/']);
                  }
                  if (rol == 5 || rol == 6) {
                    this._router.navigate(['mod-autoescuelas-hencok/clientes/']);
                  }
                  else {
                    this._router.navigate(['client-hencok/clientes/']);
                  }
                }

                //console.log("actualizando ob")
              }
            });
          }
        });
      }
    });
  }

  getAllProvincias() {
    this._serviceGeneral.getProvincias().subscribe(
      (data) => {
        this.listaProvincias = data['Provincia'];
      }
    )
  }

  private async getUser(): Promise<void> {
    //console.log('Entro a get user');
    //console.log('User Admin:',this.userAdmin);

    let rol = this._tokenStorageService.getRol()
    if (rol == 99 || rol == 2 || rol == 3 || rol == 5 || rol == 6) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    if (this.isAdmin) {
      this._route.params.subscribe(params => {
        this.id = params['id'];
      });
    } else {
      this.id = this._tokenStorageService.getUser().id
    }

    await this._serviceGeneral.getUserId(this.id)
      .subscribe({
        next: (usuario) => {
          this.usuario = usuario['Usuario'];

          //console.log(this.usuario.active);
          this.getIban();
        },
        error: (error) => {
          //console.log(error);
        }
      });

  }
  getObservaciones() {
    this._serviceGeneral.getObservacionesUsuario(this.id).subscribe({
      next: (observacion) => {

        if (observacion != null) {
          this.observaciones = observacion
        }
        this.initiateForm();
        


      },
      error: (error) => {
        this.initiateForm();
        //console.log(error);
      }
    });
  }
  getIban() {
    this._serviceGeneral.getIbanByUser(this.id)
      .subscribe({
        next: (iban) => {
          this.iban = iban;
          this.getObservaciones();
        },
        error: (error) => {
          console.log(error);
          this.getObservaciones();
        }
      });
  }

  getFirstLetter(nombre: string) {
    return nombre?.charAt(0).toUpperCase();
  }
  ibanData;
  setMostrarFormulario(mode: Boolean, element?: any, isForUpdate?: Boolean) {
    this.mostrarFormulario = mode;
    this.data = { mostrarFormulario: mode, elementUpdate: element, isForUpdate: isForUpdate, iban: this.iban }
  }
  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;
    //this.getPropietarios();
  }

  dialogSubmit(dialog:any){
    dialog.close();
    this.submitted = true;
    console.log(this.formPass)
    if (this.formPass.controls['password'].errors != undefined) {
      if (this.formPass.controls['password'].errors['minlength'] != undefined) {
      this._notificationService.error('Error', 'La contraseña debe tener al menos 6 caracteres', 2000);
      }
    }
    if (this.formPass.hasError('notSame')) {
      this._notificationService.error('Error', 'Las contraseñas no coinciden', 2000);
      this.formPass.controls['password_confirmacion'].setErrors({ 'incorrect': true });
    }
    if(this.formPass.valid){
      this._authService.changePassword(this.formPass.controls['password'].value, this.usuario.id).subscribe({next: (data) => {

        this.submit();
        
      },error: (error) => {
        this._notificationService.error('Error', 'No se ha podido guardar la contraseña', 2000);
      this.formPass.controls['password_confirmacion'].setErrors({ 'incorrect': true });
      this.formPass.controls['password'].setErrors({ 'incorrect': true });
      }
    })      
    }
    else{
      console.log(this.formPass)
    }
    
  }
  resetAccess(dialog:any){
    dialog.close();
    this._authService.resetAccess(this.usuario.id).subscribe((data) => {
      if(data==null){
        this._notificationService.success('Exito', 'Se ha restablecido el acceso', 2000);
      }
    })
  }

  resizeAndSaveImage(event) {
    
    if(event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png"){

      let image = new Image();
      image.src = URL.createObjectURL(event.target.files[0]);
      image.onload = () => {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var maxW = 400;
        var maxH = 400;
        var iw = image.width;
        var ih = image.height;
        var scale = Math.min((maxW / iw), (maxH / ih));
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        context.drawImage(image, 0, 0, iwScaled, ihScaled);
        console.log(canvas.toDataURL("image/jpeg"));
        let base64img = canvas.toDataURL("image/jpeg").split(';base64,')[1];
        this.usuario.imgPerfil = base64img;
        this._userService.updateImgPerfil(this.usuario.id, base64img).subscribe((data) =>{

        })
      }
    }
    }

    convertFile(file: File): Observable<string> {
      const result = new ReplaySubject<string>(1);
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event) =>
        result.next(btoa(event.target.result.toString()));
      return result;
    }

    uploadSignedContract(event) {
      
      if (
        event.target.files[0].type == "application/pdf"
      ) {
        const file = event.target.files[0];
        this.convertFile(file).subscribe((base64) => {
          let document = {
            nombre: event.target.files[0].name,
            mime: event.target.files[0].type,
            base64: base64,
          };

          this._authService.saveSignedContract(base64, this.usuario.email).subscribe((data) => {
            this._notificationService.success(
              "Éxito",
              "Contrato subido con éxito",
              5000
            );
          },
          (error) => {
            this._notificationService.error(
              "Error",
              "El usuario ya tiene un contrato firmado",
              5000
            );
          })
          
        });
      } else {
        this._notificationService.warning(
          "Informacion",
          "Solo se admiten archivos del tipo: PDF",
          5000
        );
      }
    }

}

export interface DataCliente {
  elementUpdate: any; // -- Campo para el caso de actualizar
  isForUpdate: Boolean;
  mostrarFormulario: Boolean;
  iban: any;
}
