import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, catchError, pipe, retry } from "rxjs";
import { environment } from "src/environments/environment";
import { ServicioGeneral } from "../servicio-general.service";

@Injectable({
  providedIn: "root",
})
export class CalculadoraitpService {
 
  API_URL: string = environment.API_URL;
  constructor(
    private http: HttpClient,
    private serviceGeneral: ServicioGeneral
  ) {}

  listMarcas() {
    return this.http.get(`${this.API_URL}/vehiculo/listMarcas`);
  }

  listCarburantes() {
    return this.http.get(`${this.API_URL}/vehiculo/listCarburantes`);
  }

  listModelos(marca:String) {

    return this.http.post(`${this.API_URL}/vehiculo/listModelos`, marca);
  }
  listCombustibles(marca:string){
    return this.http.post(`${this.API_URL}/vehiculo/listCombustible`, {marca});

  }
  listModelosByMarcaAndComb(marca:String, tipo:Object) {
    return this.http.post(`${this.API_URL}/vehiculo/listModelosByCombustible`, {marca,tipo});
  }
  calcularItp(form): Observable<any> {
    return this.http.post(`${this.API_URL}/ipt/calcularIpt`, form.value, { responseType: 'text' });
  }
}