import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUser'
})
export class FilterPipeUser implements PipeTransform {

  transform(items: any[], filter: Object): any {

    if (!items || !filter) {
        return items;
    }
    
    return items.filter(contact => {      
      return contact.nombre.toLocaleLowerCase().match(filter) ||
        contact.apellido1.toLocaleLowerCase().match(filter) ||
        contact.apellido2.toLocaleLowerCase().match(filter)||
        contact.email.toLocaleLowerCase().match(filter)
        
        // || contact.telefono.toLocaleLowerCase().match(filter)
    });
  }

}
