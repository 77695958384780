import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { finalize } from 'rxjs';
import { ImpuestosService } from 'src/app/_services/hencok/impuestos.service';
import { NexcloudService } from 'src/app/_services/nexcloud.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { Impuesto } from 'src/app/model/hencok/impuesto';
import { SharedDataService } from "src/app/_services/shared-data.service";
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-gestion-impuestos',
  templateUrl: './gestion-impuestos.component.html',
  styleUrls: ['./gestion-impuestos.component.css']
})
export class GestionImpuestosComponent implements OnInit {




  constructor(
    private _notificationSvc: NotificationService,
    private impuestosService: ImpuestosService,
    private nextCloud: NexcloudService,
    private notificacionService: NotificationService,
    private _sharedDataService: SharedDataService,

  ) { }

  mostrarFormulario: Boolean = false;
  editarFormulario: Boolean = false;
  totalElements: number = 0;
  listadoImpuestos: Impuesto[];
  listadoTotaslImpuestos: any;
  listadoTotaslImpuestosInmutable: any;

  pageSize = 5;
  impuesto: any = null;
  modeloEnv: any;
  mostrarImpuesto: boolean = false;

  isSortedByName: string = "";
  isSortedById: string = "";
  isSortedByModelo: string = ""; 
  isSortedByPeriodoSolicitud:string="";
  isSortedByEstado:string="";


  mostrarPag=true;
  searchText: String = "";
  
  isDelete=false;

  detalles;
  isShowedImpueto: boolean = false;
  sortArray : any = {};

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };


  primeraVez: boolean = true;
  documentoData;
  ngOnInit() {
    this.indexImpuestos({ page: "0", size: "5" })
    this.geTotalImpuestos()
    this.sortArrayInitialize();
    this._sharedDataService.currentMessage.subscribe((message) =>
    this.filtrar(message)
  );
  }

  geTotalImpuestos(){
    this.impuestosService.getImpuestosList().subscribe({
      next:(data)=>{
        this.listadoTotaslImpuestos= data
        this.listadoTotaslImpuestosInmutable=data
      },
      error:(error) =>{

      }
    })
  }



  filtrar(mesagge: string) {
    if(this.listadoTotaslImpuestosInmutable!= undefined){ 
    if (mesagge.length > 0) {
      this.listadoImpuestos = this.listadoTotaslImpuestosInmutable.filter(
        (item) =>
      (item.concepto.toLowerCase().indexOf(mesagge.toLowerCase())>-1)||
      item.concepto.toLowerCase().indexOf(mesagge.toLowerCase()) >1||
          (item.autonomo.nombre.toLowerCase() + " "+ item.autonomo.apellido1.toLowerCase() + " "+ 
          item.autonomo.apellido2.toLowerCase()) .indexOf(mesagge.toLowerCase()) >-1||
         item.estado.nombre.toLowerCase().indexOf(mesagge.toLowerCase())>-1||
          item.autonomo.id.toString().toLowerCase().indexOf(mesagge.toLowerCase())>-1||
          item.periodoImpuesto.replaceAll("-", "/").indexOf(mesagge) > -1 ||
        item.fechaMaxima.replaceAll("-", "/").indexOf(mesagge) > -1 
      );
      this.listadoTotaslImpuestos=this.listadoImpuestos;
      this.pageSize=this.listadoTotaslImpuestos.size
    } else {
      this.indexImpuestos({ page: "0", size: "5" })
      this.geTotalImpuestos()
    }
  
  }   
  if(mesagge != ""){
    this.mostrarPag=false
  }else{
    this.mostrarPag=true
  }
  }




  public indexImpuestos(request) {
    return this.impuestosService.index(request).subscribe((data) => {
    //  console.log(data)
      if (data.status != 630) { //Si encuentra elementos en la consulta
        this.listadoImpuestos = data['impuesto']['content'];
        this.totalElements = data['impuesto']['totalElements'];
      } else {
        this.listadoImpuestos = [];
        this.totalElements = 0;
      }
    })
  }

  nextPage(event: PageEvent) {
    const request = {};
    request['page'] = event.pageIndex.toString();
    request['size'] = event.pageSize.toString();
    this.pageSize=event.pageSize;
    this.indexImpuestos(request);
  }

  previewModel(impuesto: Impuesto) {
  //  console.log(impuesto)
  }

  openPdf(impuesto: Impuesto) {
    this.nextCloud.downloadJustificanteB64(impuesto.idDocumento)
      .subscribe({
        next: (documento) => {
          const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        }
      })
  }

  downloadPdf(impuesto: Impuesto) {
    this.nextCloud.downloadJustificanteB64(impuesto.idDocumento).subscribe(
      (documento) => {
        const linkSource = 'data:application/pdf;base64,' + documento.Result;
        const downloadLink = document.createElement("a");
        const fileName = impuesto.concepto;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        this.notificacionService.success('Hecho', 'Pdf descargado con éxito', 2000);

      }
    )
  }
  openSidebar(event: Event) {
    var element = document.getElementById("sidebar");
    element.classList.add("active");
    event.stopPropagation();
  }
  openSidebarModificar(impuesto, event: any) {
    var element = document.getElementById("sidebar_modificar");
  //  console.log('impuesto ', impuesto);

    if(impuesto.idDocumento!=null){

    this.nextCloud.downloadJustificanteB64(impuesto.idDocumento).subscribe((documento) => {


      let document = {
        nombre: impuesto.concepto+".pdf",
        mime: "application/pdf",
        base64: documento.Result,
        id_gestor: impuesto.idDocumento
      };
     
      this.impuesto = impuesto;
      this.detalles = document
      element?.classList.add('active');


      event.stopPropagation();

    })
  }else{
    this.impuesto = impuesto;
      this.detalles = null
      element?.classList.add('active');


      event.stopPropagation();
  }


  }
  openSidebarVisualizar(impuesto, event: any) {
    var ojos = Array.from(document.getElementsByClassName('eye-button') as HTMLCollectionOf<HTMLElement>)

    Array.from(ojos).forEach((ojo) => {
      ojo.style.cssText = "pointer-events:none"
    })

    this.mostrarImpuesto = true;

    var padre = event.target.parentElement;
    var ojo = event.target;

    padre.removeChild(ojo)
    var spinner = document.createElement('div');
    spinner.classList.add("spinner-border");
    spinner.classList.add("spinner-border-sm")
    padre.prepend(spinner)

    var element = document.getElementById("sidebar_detalle");
  //  console.log('impuesto ', impuesto);
    this.impuesto = impuesto;

    this.nextCloud.downloadJustificanteB64(impuesto.idDocumento).subscribe((documento) => {

      var embed = document.createElement('embed');
    //  console.log(embed)
      const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
    //  console.log(byteArray)
      embed.setAttribute("type", 'application/pdf');
      var blob = new Blob([byteArray], { type: 'application/pdf' });
      var pdfUrl = URL.createObjectURL(blob)
      //this.pdfAuxiliar = pdfUrl
      embed.setAttribute("src", pdfUrl);
      embed.setAttribute("width", "500px");
      embed.setAttribute("height", "600px");
      this.impuesto['documentoData'] = embed;
      this.impuestosService.getByImpuestoId(impuesto.id).subscribe({
        next: (data) => {
        //  console.log(data)
          this.detalles = data['idDocumento']
          this.isShowedImpueto = true;
          padre.removeChild(spinner);
          padre.prepend(ojo);
          Array.from(ojos).forEach((ojo) => {
            ojo.style.cssText = ""
          })
          this.primeraVez = true;
          element?.classList.add('active');
        },
        error: (error) => {
          // console.log(error);
        }
      })

      event.stopPropagation();

    })


  }
  static closeSidebarClienteRemoto(){
    var element_gasto = document?.getElementById("sidebar");
    var elementV = document?.getElementById("sidebar_detalle");
    var element_gasto2 = document?.getElementById("sidebar_modificar");
    element_gasto2?.classList.remove("active");
    elementV?.classList.remove("active");
    element_gasto?.classList.remove("active");
  }


  closeSidebar(e: any) {
    if (e.target.classList.value == "eye-button activated") {
    } else {
      var element_gasto = document?.getElementById("sidebar");
      var elementV = document?.getElementById("sidebar_detalle");
      var element_gasto2 = document?.getElementById("sidebar_modificar");
      element_gasto2?.classList.remove("active");
      elementV?.classList.remove("active");
      element_gasto?.classList.remove("active");
    }
    e.stopPropagation();
  }

  deleteImpuesto(impuesto, e: any) {
    this.isDelete = true;
    let id_gestor = [];
    this.impuesto = impuesto
    if (this.impuesto.estado.nombre == "PENDIENTE") {
      this.impuestosService
        .getByImpuestoId(impuesto.id)
        .pipe(
          finalize(() => {
            id_gestor.push(impuesto.idDocumento)

           


                  this.impuestosService
                    .deleteImpuesto(impuesto.id)
                    .subscribe({
                      next: (data) => {
                        this.indexImpuestos({
                          page: "0", size: this.pageSize
                        });

                        this._notificationSvc.success(
                          "Hecho",
                          "Borrado correctamente",
                          2000
                        );
                        this.isDelete=false
                      },
                      error: (err) => {
                        this.isDelete=false
                        this._notificationSvc.error(
                          "ERROR",
                          "No se ha podido borrar  el impuesto",
                          2000

                        );
                      },
                    });
             
              
            
          })
        )
        .subscribe({
          next: (data) => {
            this.detalles = data["documentos"];
          },
          error: (err) => {

          },
        });

    } else {
      this.isDelete=false
      this._notificationSvc.info(
        "Aviso!",
        "No es posible eliminar un impuesto completado",
        2000
      );
    }

  }
  orderByName() {
  
    this.isSortedByModelo = ""
    this.isSortedById = "";
    this.isSortedByPeriodoSolicitud="";
    this.isSortedByEstado="";

    if (this.isSortedByName != "chevron-button") {
      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if (n1.autonomo.nombre > n2.autonomo.nombre) {
          return -1;
        }

        if (n1.autonomo.nombre < n2.autonomo.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByName = "chevron-button";

    } else if (this.isSortedByName == "chevron-button") {

      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if (n1.autonomo.nombre > n2.autonomo.nombre) {
          return 1;
        }

        if (n1.autonomo.nombre < n2.autonomo.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByName = "chevron-button-up";

    }
    this.listadoImpuestos=[]
    this.listadoImpuestos=this.listadoTotaslImpuestos.slice(0, this.pageSize);
  }

  orderById() {
    this.isSortedByModelo = ""
    this.isSortedByName="";
    this.isSortedByPeriodoSolicitud="";
    this.isSortedByEstado="";

    if (this.isSortedById != "chevron-button") {
      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if (n1.autonomo.id > n2.autonomo.id) {
          return -1;
        }

        if (n1.autonomo.id <  n2.autonomo.id) {
          return 1;
        }

        return 0;
      });
      this.isSortedById = "chevron-button";

    } else if (this.isSortedById == "chevron-button") {

      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if (n1.autonomo.id >  n2.autonomo.id) {
          return 1;
        }

        if (n1.autonomo.id <  n2.autonomo.id) {
          return -1;
        }

        return 0;
      });
      this.isSortedById = "chevron-button-up";

    }
    this.listadoImpuestos=[]
    this.listadoImpuestos=this.listadoTotaslImpuestos.slice(0, this.pageSize);
  }
  orderByModelo() {
    this.isSortedById = "";
    this.isSortedByName="";
    this.isSortedByPeriodoSolicitud="";
    this.isSortedByEstado="";

    if (this.isSortedByModelo != "chevron-button") {
      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if (n1.concepto > n2.concepto) {
          return -1;
        }

        if (n1.concepto < n2.concepto) {
          return 1;
        }

        return 0;
      });
      this.isSortedByModelo = "chevron-button";

    } else if (this.isSortedByModelo == "chevron-button") {

      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if (n1.concepto > n2.concepto) {
          return 1;
        }

        if (n1.concepto < n2.concepto) {
          return -1;
        }

        return 0;
      });
      this.isSortedByModelo = "chevron-button-up";

    }
    this.listadoImpuestos=[]
    this.listadoImpuestos=this.listadoTotaslImpuestos.slice(0, this.pageSize);
  }


  orderByEstado() {
    this.isSortedById = "";
    this.isSortedByName="";
    this.isSortedByPeriodoSolicitud="";
    this.isSortedByModelo="";


    if (this.isSortedByEstado != "chevron-button") {
      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {

        if (n1.estado.nombre > n2.estado.nombre) {
          return -1;
        } 

        if (n1.estado.nombre < n2.estado.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button";
    } else if (this.isSortedByEstado == "chevron-button") {
      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if (n1.estado.nombre > n2.estado.nombre) {
          return 1;
        }

        if (n1.estado.nombre < n2.estado.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button-up";
    }

    this.listadoImpuestos=[]
         this.listadoImpuestos=this.listadoTotaslImpuestos.slice(0, this.pageSize);
  }



  getDateToCompare(fechas) {
 
    var date = new Date(fechas);
    return date.getTime();
  }
  orderByfecha() {
    this.isSortedById = "";
    this.isSortedByName="";
    this.isSortedByModelo="";
    this.isSortedByEstado="";


    if (this.isSortedByPeriodoSolicitud != "chevron-button") {
      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if ( this.getDateToCompare(n1.periodoImpuesto) > this.getDateToCompare(n2.periodoImpuesto)) {
          return -1;
        } 

        if (this.getDateToCompare(n1.periodoImpuesto) < this.getDateToCompare(n2.periodoImpuesto)) {
          return 1;
        }

        return 0;
      });
      this.isSortedByPeriodoSolicitud = "chevron-button";
    } else if (this.isSortedByPeriodoSolicitud == "chevron-button") {
      this.listadoTotaslImpuestos = this.listadoTotaslImpuestos.sort((n1, n2) => {
        if (this.getDateToCompare(n1.periodoImpuesto) > this.getDateToCompare(n2.periodoImpuesto)) {
          return 1;
        }

        if (this.getDateToCompare(n1.periodoImpuesto)< this.getDateToCompare(n2.periodoImpuesto)) {
          return -1;
        }

        return 0;
      });
      this.isSortedByPeriodoSolicitud = "chevron-button-up";
    }

    this.listadoImpuestos=[]
         this.listadoImpuestos=this.listadoTotaslImpuestos.slice(0, this.pageSize);
  }

  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getPresupuestos();
  }

  getPresupuestos() {
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this.impuestosService
      .getImpuestosFilter(this.request)
      .subscribe((data: any) => {
        this.listadoImpuestos = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
      });
  }

  sortArrayInitialize(){
    this.sortArray = {nombre:'none', id:'none', concepto:'none', periodoImpuesto:'none', estado:'none'};
  }

  changeSorts(key, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = key instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of key){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(key);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    this.getPresupuestos();
  }


}
