<div class="notifications">
	<div *ngFor="let notification of notifications" class="notification" [ngClass]="className(notification)">
		<ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
	</div>
</div>

<ng-template #notificationTpl let-notification="notification">
	<div class="title d-flex justify-content-between" >
        <div><b>{{notification.title}}</b></div>
		<button class="close" (click)="close(notification)">
            <span aria-hidden="true">&times;</span>
        </button>
	</div>
	<div class="message">{{notification.message}}</div>
</ng-template>
