import { Component, OnInit, Input, ViewChild } from "@angular/core";
declare const AutoScript: any;
declare const Constants: any;
declare const autoFirmaScriptFunctions: any;

@Component({
  selector: 'app-auto-firma',
  templateUrl: './auto-firma.html',
  styleUrls: ['./auto-firma.css'],
})


export class AutoFirmaComponent implements OnInit {
    ngOnInit(): void {
       
        AutoScript;
        Constants;
        autoFirmaScriptFunctions();
    }
    
}