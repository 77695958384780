import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { ClienteService } from 'src/app/_services/hencok/cliente.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Iban } from 'src/app/model/hencok/iban.model';
import { Observaciones } from 'src/app/model/hencok/observaciones';
import { Usuario } from 'src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model';

@Component({
  selector: 'app-ficha-cliente-no-admin-actualizar',
  templateUrl: './ficha-cliente-no-admin-actualizar.component.html',
  styleUrls: ['./ficha-cliente-no-admin-actualizar.component.css']
})
export class FichaClienteNoAdminActualizarComponent {

  usuario: any;
  id: number;
  mostrarFormulario: Boolean = false;
  //duda
  //
  formCliente = new FormGroup({
    id: new FormControl(),
    nombre: new FormControl(""),
    email: new FormControl(""),
    domicilio: new FormControl(null),
    numero_direccion: new FormControl(null),
    localidad: new FormControl(null),
    codigo_postal: new FormControl(null),
    documento: new FormControl(null),
  })

  observaciones = new Observaciones();
  listaProvincias;
  submitted: boolean = false;
  isIban: boolean = true;

  userLogged: any;
  userAdmin: Boolean = false;

  constructor(
    private _authService: AuthService,
    private _serviceGeneral: ServicioGeneral,
    private _route: ActivatedRoute,
    private _router: Router,
    private _tokenStorageService: TokenStorageService,
    private _clienteService: ClienteService,
    private _notificationService: NotificationService
  ) {
    this.userLogged = this._tokenStorageService.getUser();
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    }
  }

  ngOnInit(): void {
    this.getUser();
  }
  disabled() {
    this.formCliente.controls['documento'].disable();
    this.formCliente.controls['nombre'].disable();
  }
  initiateForm() {
    //console.log(this.observaciones)

    this.formCliente = new FormGroup({
      id: new FormControl(),
      nombre: new FormControl(this.usuario?.nombre),
      email: new FormControl(this.usuario?.email),
      domicilio: new FormControl(this.usuario.domicilio),
      numero_direccion: new FormControl(this.usuario.numero_direccion),
      localidad: new FormControl(this.usuario.localidad),
      codigo_postal: new FormControl(this.usuario.codigo_postal),
      documento: new FormControl(this.usuario.documento),
    }, { updateOn: 'change' });


    this.disabled();

  }
  setInput() {
    this.isIban = false;
  }

  submit() {

    this.submitted = true;

    this._clienteService.update(
      this.usuario.id,
      this.usuario.id_autonomo.id,
      this.formCliente.value.nombre == null ? this.usuario?.nombre : this.formCliente?.value?.nombre,
      this.formCliente.value.email == null ? this.usuario?.email : this.formCliente?.value?.email,
      this.formCliente?.value?.documento == null ? this.usuario?.documento : this.formCliente?.value?.documento,
      this.formCliente?.value?.domicilio == null ? this.usuario?.domicilio : this.formCliente?.value?.domicilio,
      this.formCliente?.value?.numero_direccion == null ? this.usuario?.numero_direccion : this.formCliente?.value?.numero_direccion,
      this.formCliente?.value?.localidad == null ? this.usuario?.localidad : this.formCliente?.value?.localidad,
      this.formCliente?.value?.codigo_postal == null ? this.usuario?.codigo_postal : this.formCliente?.value?.codigo_postal,



    ).subscribe({
      next: async data => {
        //console.log("actualizando ob")
        this._router.navigate(['admin-hencok/clientes/']);

      }
    });
  }

  private async getUser(): Promise<void> {
    //console.log('Entro a get user');
    //console.log('User Admin:', this.userAdmin);

    this._route.params.subscribe(params => {
      this.id = params['id'];
    });

    await this._serviceGeneral.getClientId(this.id, this._tokenStorageService.getId())
      .subscribe({
        next: (usuario) => {
          if (usuario == null) this._router.navigate(["/client-hencok/clientes/"])
          this.usuario = usuario;
          //console.log(this.usuario.active, " asd");
          this.initiateForm()
        },
        error: (error) => {
          this._router.navigate(["/client-hencok/clientes/"])
        }
      });
  }
  getFirstLetter(nombre: string) {
    return nombre?.charAt(0).toUpperCase();
  }
  ibanData;
  setMostrarFormulario(mode: Boolean, element?: any, isForUpdate?: Boolean) {
    this.mostrarFormulario = mode;
  }
  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;
    //this.getPropietarios();
  }

}

export interface DataCliente {
  elementUpdate: any; // -- Campo para el caso de actualizar
  isForUpdate: Boolean;
  mostrarFormulario: Boolean;
  iban: any;
}
