import { formatDate } from "@angular/common";
import { HttpParams } from "@angular/common/http";
import { Component, HostListener, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { TramitesService } from "src/app/_services/autoescuela/tramites.service";
import { NotificationService } from "src/app/_services/notification.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { Tramite } from "src/app/model/respuesta_tramite_modelo";
import { environment } from 'src/environments/environment.hencok';

@Component({
  selector: "app-listado-tramites",
  templateUrl: "./listado-tramites.component.html",
  styleUrls: ["./listado-tramites.component.css"],
})
export class ListadoTramitesComponent {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("paginator") paginator: MatPaginator;

  mostrarTramites = false;
  tramites: any[] = [];
  totalElements = 0;
  page = "0";
  size = "5";
  environment = environment;
  isAdmin = false
  isMod = false
  filters: any[] = [];
  sorts: any[] = [];
  tipoTramites: any[];
  getScreenWidth: number;
  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };
  popup
  descripcion
  titulo
  documentos_necesarios
  listaTramites :any= [];
  estadosShow:boolean;
  firstFilter:boolean=true;
  sortArray: any = {}
  sortVariable = {
    direction: undefined,
    key: undefined
  }

  //MULTISELECT
  /*dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};*/

  constructor(
    private tokenService: TokenStorageService,
    private tramiteService: TramitesService,
    public router?: Router,
    private route?: ActivatedRoute,
    private notificationSvc?: NotificationService

  ) { }
  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;

    if (this.tokenService.getRol() == 3 || this.tokenService.getRol() == 99) {
      this.isAdmin = true;
    }else if (this.tokenService.getRol()==5 || this.tokenService.getRol()==6){
      this.isMod = true;
    }
    this.getTipoTramites();
    this.getTramites();
    //this.initMultiSelect();
    this.getlistaTramites()
    this.sortArrayInitialize();
  }


  getlistaTramites() {
    this.tramiteService.getListadoTramites().subscribe({
      next: (data) => {
        this.listaTramites = data;
        console.log(this.listaTramites[0].valor)
      },
    });
  }

  getTipoTramites() {
    this.tramiteService.getTramites().subscribe({
      next: (data: Tramite[]) => {
        this.tramiteService.getPreciosTramiteList(undefined).subscribe((prices) => {
          console.log(data)
          this.tipoTramites = [];
          this.tipoTramites.push(data[0])
          for(let i = 1; i < data.length; i++){
            let totalTramite = 0;
            if(prices[i-1].tasaDgt){
              totalTramite += prices[i-1].tasaDgt;
            }
            if(prices[i-1].tasaGestores1){
              totalTramite += prices[i-1].tasaGestores1;
            }
            if(prices[i-1].tasaGestores2){
              totalTramite += prices[i-1].tasaGestores2;
            }
            if(prices[i-1].honorariosGestoria){
              totalTramite += prices[i-1].honorariosGestoria + prices[i-1].honorariosGestoria * prices[i-1].tipoIva/100;
            }

            this.tipoTramites.push(
              {descripcion: data[i]['descripcion'],
              documentos_necesarios: data[i]['documentos_necesarios'],
              icono: data[i]['icono'],
              id: data[i]['id'],
              nombre: data[i]['nombre'],
              precio: totalTramite.toFixed(2)
            })
          }
          
        })
      },
    });
  }

  getTramites() {
    let rol = this.tokenService.getRol();
    if (rol == 5 && this.firstFilter) {
      console.log(rol)
      let type = ['usuario.idPadre.id', 'usuario.idPadre.idPadre.id']
      let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        console.log(composeKey);
      let filterUser = {
        key: composeKey,
        keys: type,
        operator: "EQUAL_OR",
        field_type: "STRING",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterUser);
      this.firstFilter = true;
    }
    if (rol == 6 && this.firstFilter) {
      let type = 'usuario.idPadre.id'
      let filterUser = {
        key: type,
        operator: "EQUAL",
        field_type: "STRING",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterUser);
      this.firstFilter = true;
    }
    if (rol == 4 && this.firstFilter) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterUser);
      this.firstFilter=false;
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    if (rol == 3 || rol == 99) {
      let filterBorr = {
        key: "usuario.id",
        operator: "CUSTOM_BORRADO",
        field_type: "LONG",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterBorr);
    }
    if (rol == 4) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this.tokenService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this.tramiteService
      .getTramitesRealizadosFilter(this.request, this.sortVariable)
      .subscribe((data: any) => {
        this.tramites = data.pageList;
        console.log(data);

        this.totalElements = data.nrOfElements;
      });
  }

  show(id, tipo) {
    if (tipo == 1) {
       if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/consulta/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/consulta/' + id])

      } 
      else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/consulta/" + id])
      }
    }
    else if (tipo == 2) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/transferencia/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/transferencia/' + id])
      } else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/transferencia/" + id])
      }
    }
    else if (tipo == 3) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/matriculacion/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/matriculacion/' + id])
      } else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/matriculacion/" + id])
      }
    }
    else if (tipo == 4) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/duplicado-ficha-tecnica/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/duplicado-ficha-tecnica/' + id])
      } else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/duplicado-ficha-tecnica/" + id])
      }
    }
    else if (tipo == 5) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/distintivo-ambiental/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/distintivo-ambiental/' + id])
      } else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/distintivo-ambiental/" + id])
      }
    }
    else if (tipo == 6) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/duplicado-permiso-conducir/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/duplicado-permiso-conducir/' + id])
      } else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/duplicado-permiso-conducir/" + id])
      }
    }
    else if (tipo == 7) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/canje-permiso/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/canje-permiso/' + id])
      } else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/canje-permiso/" + id])
      }
    }
    else if (tipo == 8) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/permiso-internacional/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/permiso-internacional/' + id])
      } else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/permiso-internacional/" + id])
      }
    }
    else if (tipo == 9) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/informe-dgt/' + id])

      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/informe-dgt/' + id])
      } else {
        this.tramiteService.changeStateTramite(tipo, id).subscribe()
        this.router.navigate(["admin-autoescuelas-hencok/informe-dgt/" + id])
      }
    }
  }
  update(id, tipo) {
    console.log(id);
    console.log(tipo);

    if (tipo == 1) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-consulta/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-consulta/' + id)
      }
    }
    else if (tipo == 2) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-transferencia/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-transferencia/' + id)
      }

    }
    else if (tipo == 3) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-matriculacion/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-matriculacion/' + id)
      }

    }
    else if (tipo == 4) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-duplicado-ficha-tecnica/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-duplicado-ficha-tecnica/' + id)
      }

    }
    else if (tipo == 5) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-distintivo-ambiental/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-distintivo-ambiental/' + id)
      }

    }
    else if (tipo == 6) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-duplicado-permiso-conducir/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-duplicado-permiso-conducir/' + id)
      }

    }
    else if (tipo == 7) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-canje-permiso/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-canje-permiso/' + id)
      }

    }
    else if (tipo == 8) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-permiso-internacional/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-permiso-internacional/' + id)
      }

    }
    else if (tipo == 9) {
      if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/editar-informe-dgt/' + id])
      } else {
        this.router.navigateByUrl('admin-autoescuelas-hencok/editar-informe-dgt/' + id)
      }

    }
  }

  openTramite(tipo) {
    if (tipo == 1) {
        if (this.isMod) {
         this.router.navigate(['mod-autoescuelas-hencok/nueva-consulta'])
       }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/nueva-consulta'])
      } else {
        this.router.navigate(["admin-autoescuelas-hencok/nueva-consulta"])
      }
    } 
    else if (tipo == 2) {
        if (this.isMod) {
         this.router.navigate(['mod-autoescuelas-hencok/nueva-transferencia'])
       }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/nueva-transferencia'])
      } else {
        this.router.navigate(["admin-autoescuelas-hencok/nueva-transferencia"])
      }

    }
    else if (tipo == 3) {
        if (this.isMod) {
         this.router.navigate(['/mod-autoescuelas-hencok/solicitar-matriculacion'])
       }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/solicitar-matriculacion'])
      } else {
        this.router.navigate(["admin-autoescuelas-hencok/solicitar-matriculacion"])
      }

    }
     else if (tipo == 4) {
        if (this.isMod) {
         this.router.navigate(['/mod-autoescuelas-hencok/solicitar-duplicado-ficha-tecnica'])
       }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/solicitar-duplicado-ficha-tecnica'])
      } else {
        this.router.navigate(["admin-autoescuelas-hencok/solicitar-duplicado-ficha-tecnica"])
      }

    }
    else if (tipo == 5) {
        if (this.isMod) {
         this.router.navigate(['/mod-autoescuelas-hencok/solicitar-distintivo-ambiental'])
       }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/solicitar-distintivo-ambiental'])
      } else {
        this.router.navigate(["admin-autoescuelas-hencok/solicitar-distintivo-ambiental"])
      }

    }
    else if (tipo == 6) {
        if (this.isMod) {
         this.router.navigate(['/mod-autoescuelas-hencok/solicitar-duplicado-permiso-conducir'])
       }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/solicitar-duplicado-permiso-conducir'])
      } else {
        this.router.navigate(["admin-autoescuelas-hencok/solicitar-duplicado-permiso-conducir"])
      }

    }
    else if (tipo == 7) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/solicitar-canje-permiso'])
      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/solicitar-canje-permiso'])

      } else {
        this.router.navigate(["admin-autoescuelas-hencok/solicitar-canje-permiso"])
      }

    }
    else if (tipo == 8) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/solicitar-permiso-internacional'])
      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/solicitar-permiso-internacional'])

      } else {
        this.router.navigate(["admin-autoescuelas-hencok/solicitar-permiso-internacional"])
      }

    }
    else if (tipo == 9) {
      if (this.isMod) {
        this.router.navigate(['/mod-autoescuelas-hencok/solicitar-informe-dgt'])
      }else if (!this.isAdmin) {
        this.router.navigate(['/client-autoescuelas-hencok/solicitar-informe-dgt'])

      } else {
        this.router.navigate(["admin-autoescuelas-hencok/solicitar-informe-dgt"])
      }

    }
    console.log(tipo);

  }
  nextPage(event: PageEvent) {
    this.page = event.pageIndex.toString();
    this.size = event.pageSize.toString();
    this.getTramites();
  }

  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    //console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    this.page = "0";
    this.size = "5";
    //console.log("los filtros son", this.filters);
    this.getTramites();
  }

  //MULTISELECT

  /*initMultiSelect(){
    this.dropdownSettings = { 
      singleSelection: false, 
      searchPlaceholderText:"Tipo Tramite",
      selectAllText:'Todos',
      unSelectAllText:'Ninguno',
      enableSearchFilter: true,
      classes:"searchinput",
      labelKey:"nombre"
    };  
    this.tipoTramites.forEach(item =>{
      let obj={"id":item.id, "itenName":item.nombre};
      this.dropdownList.push(obj);
    })
  }
 
onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }*/
  buttons = ['Botón 1', 'Botón 2', 'Botón 3', 'Botón 4', 'Botón 5']; // Puedes agregar más botones según sea necesario

  isMouseDown = false;
  startX: number;
  scrollLeft: number;


  doPopUp(titulo, descripcion, documentos_necesarios) {
   this.documentos_necesarios=documentos_necesarios;
   console.log(documentos_necesarios.length)
    this.titulo = titulo
    this.descripcion = descripcion
    this.popup = true
  }

  showTramites(mostrar) {
    this.mostrarTramites = mostrar;
  }

  download(idTramite, tipoTramite, numero) {
    console.log(idTramite);

    this.tramiteService.download(tipoTramite, idTramite).subscribe((data: ArrayBuffer) => {
      console.log(data);

      if (data == null) {
        this.notificationSvc.warning('Alerta', "No tiene contenido", 2000);
      } else {
        var file = new Blob([data], { type: 'application/zip' })
        var fileUrl = URL.createObjectURL(file)
        var a = document.createElement('a')
        a.href = fileUrl
        a.download = "Tramite_" + numero + ".zip"
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }

    },
      error => {

      }

    )
  }

  showImage(url){
    var newTab = window.open('about:blank', '_blank');
    newTab.document.write("<object width='max-content' height='max-content' data='" + url + "' type='"+ 'image/png' +"' ></object>");
  }
  
  
  moveLeft () {
      var container = document.getElementById('card_container');
      this.sideScroll(container,'left',190);
  };
  
  moveRight() {
      var container = document.getElementById('card_container');
      this.sideScroll(container,'right',190);
  };
  
  sideScroll(element,direction,step){
      let scrollAmount = 0;
      if(direction == 'left'){
          element.scrollLeft -= step;
      } else {
          element.scrollLeft += step;
      }
      scrollAmount += step;
  }

  downloadExcel(){
    this.tramiteService
      .generateExcel()
      .subscribe({
        next: (data: any) => {
          console.log(data)
          const byteCharacters = atob(data["document"]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          // Crea un objeto Blob y URL para el archivo
          const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          // Crea un enlace de descarga y simula un clic para descargar el archivo
          const a = document.createElement('a');
          a.href = url;
          a.download = 'lista_tramites.xlsx';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url); }
          ,error:(error)=>{
            console.log(error)
          }
      });
  }

  checkPresence(object, parameter) {
    if (parameter in object) {
      return true;
    }
    else {
      return false;
    }
  }
  @HostListener('window:resize', ['$event'])  
  onResize(event) {  
    this.getScreenWidth = window.innerWidth; 
  }

  sortArrayInitialize(){
    this.sortArray = {numeroTramite:'none', nombre:'none', tipoTramite:'none', matricula:'none', estado:'none', fechaCreacion:'none', fechaRespuesta:'none', precio:'none', id: 'none'};
  }

  changeSorts(key, field){
    if(this.sortArray[field] === 'none'){
      this.sortArrayInitialize();
      this.sortArray[field] = 'DESC'
      this.sortVariable = {direction: 'DESC', key: key}
    }
    else if(this.sortArray[field] === 'DESC'){
      this.sortArray[field] = 'ASC'
      this.sortVariable = {direction: 'ASC', key: key}

    }
    else{
      this.sortArrayInitialize();
    }
    this.getTramites();
  }

}
