import { Component } from '@angular/core';
import { formatDate } from "@angular/common";
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PresupuestoService } from 'src/app/_services/autoescuela/presupuesto.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { SharedDataService } from 'src/app/_services/shared-data.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-gestion-presupuestos-gestor',
  templateUrl: './gestion-presupuestos-gestor.component.html',
  styleUrls: ['./gestion-presupuestos-gestor.component.css']
})
export class GestionPresupuestosGestorComponent {
  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _presupuestoService: PresupuestoService,
    private _tokenStorageService: TokenStorageService,
    private _notificationService: NotificationService,
    private _sharedDataService: SharedDataService,
    private _router: Router
  ) {}

  usuario = this._tokenStorageService.getUser();
  

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };

  pageIndex: number = 0;
  pageSize: number = 5;

  totalElements: any;

  mostrarFormulario:boolean=false;
  editarFormulario:boolean=false;
  mostrarPag: boolean = true;
  isDelete: boolean = false;
  userAdmin:boolean = false;

  isMod=false;

  presupuestos:any = [];
  presupuestosInit:any = [];
  estados_presupuesto:any;
  presupuestosFullList:any;
  presupuestosListToFilter:any;

  infoPresupuestoEnv:any;
  editOpen=false;
  firstFilter: Boolean=true;
  sortArray: any = {};
  paymentData: any = {};

  canvas;

  ngOnInit(): void {
    if (this._tokenStorageService.getRol()==3 || this._tokenStorageService.getRol()==99) {
      this.userAdmin = true;
    }else if (this._tokenStorageService.getRol()==5 || this._tokenStorageService.getRol() == 6){
      this.isMod = true;
    }

    this._sharedDataService.currentMessage.subscribe(message => (this.filtrar(message)));
    var url = window.location.href;
    var urlVars = url.split('?');
    console.log(urlVars)
    window.history.pushState(null, urlVars[0], urlVars[0])
    if(urlVars.length>1){
      this.filterVariables(urlVars[1])
    }
    else{
      this.getPresupuestos();
    }
    this.getEstadosPresupuesto();
    this.getAllPresupuestos();
    this.sortArrayInitialize();
    console.log(this.presupuestosFullList);
  }

  public getEstadosPresupuesto(){
    this._presupuestoService.getEstadosList().subscribe({next: (data) => {
      this.estados_presupuesto = data["estados"]
    }})
  }

  filtrar(mesagge: string) {
    if(this.presupuestosFullList!= undefined){
      if (mesagge.length > 0) {
        this.presupuestos = this.presupuestosFullList.filter((item) =>
        (item.usuario.nombre.toLowerCase() + " "+ item.usuario.apellido1.toLowerCase() + " "+ 
        item.usuario.apellido2.toLowerCase()) .indexOf(mesagge.toLowerCase()) >
        -1 ||
           item.usuario.email.toLowerCase().indexOf(mesagge.toLowerCase()) > -1 ||
          item.usuario.id.toString().includes(mesagge) ||
         item.estado.nombre.toString().toLowerCase().indexOf(mesagge.toLowerCase()) > -1||
        (this.getDateToFilter(item.fecha)).includes(mesagge)
        )
        this.presupuestosListToFilter=this.presupuestos;
        this.pageSize=this.presupuestosListToFilter.size
      } else{
        this.getPresupuestos();
        this.getAllPresupuestos();
      }
      if(mesagge != ""){
        this.mostrarPag=false
      }else{
        this.mostrarPag=true
      }
  
  }}

  getAllPresupuestos(){
    this._presupuestoService
    .list()
    .subscribe({
      next: (data) => {
        this.presupuestosListToFilter=data["presupuesto"];
        this.presupuestosFullList=data["presupuesto"];
      },
      error: (error) => {
      //  console.log(error);
      },
    });
  }

  changeEstado(estado: any , presupuesto: any) {
    this._presupuestoService.updateEstado(estado, presupuesto.id).subscribe({
      next: (data) => {
          this.getPresupuestos();
            this._notificationService.success("Hecho", "Estado actualizado", 2000); 

        
      },
      error: (err) => {
        this._notificationService.error(
          "Error",
          "No se ha podido actualizar el estado",
          2000
        );
      },
    });
  }

  openPDF(presupuesto) {
    this._presupuestoService.downloadJustificanteB64(presupuesto.documento.id_gestor_documental)
    .subscribe({
      next: (documento) => {
        const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank")
      },
      error: (error) => {
        this._notificationService.error('Error', 'No se ha podido abrir el archivo', 2000);
      }
    })
      
  }

  nextPage(event: PageEvent) {
    this.page = event.pageIndex.toString();
    this.size = event.pageSize.toString();
    this.getPresupuestos();
  }

  setMostrarFormulario(mostrar){
    this.mostrarFormulario=mostrar;
  }

  deletePresupuesto(presupuesto, e: any, dialog) {
    this.isDelete = true;
 
    if(presupuesto.estado.nombre != "RECHAZADO"){
      this._presupuestoService.delete(presupuesto.id).subscribe({next : (data) => {
        console.log(data)
            this._notificationService.success(
              "Exito",
              "El gasto se ha borrado correctamente",
              2000
            );
            this.isDelete=false;
            dialog.close();
            this.getPresupuestos();
      },
      error:(error) =>{
      this._notificationService.warning(
        "Aviso!",
        "El gasto no se ha podido borrar",
        2000
      );
      console.log(error)
      this.isDelete=false;
      dialog.close();
      this.getPresupuestos();
    }})

    }
    else if(presupuesto.estado.nombre == "Rechazado"){
      this._notificationService.info(
        "Aviso!",
        "No es posible modificar un presupuesto rechazado",
        2000
      );
    }
    else{
      this._notificationService.info(
        "Aviso!",
        "No es posible modificar un ingreso \ngenerado a partir de una factura",
        2000
      );
      }
  }

  public getPaginatedPresupuestoListByUsuario(request) {
    this._presupuestoService
      .getPaginatedPresupuestoListByUsuario(this.usuario.id, request)
      .subscribe({
        next: (data) => {
          this.presupuestos = data["content"];
          this.presupuestosInit = data["content"];

          this.totalElements = data["totalElements"];
        },
        error: (error) => {
          this.presupuestos = null;
          // console.log(error);
        },
      });
  }

  setEditarPresupuestoSend(mode: boolean, presupuesto){
    this.editarFormulario=mode;
    this.infoPresupuestoEnv = {presupuesto:presupuesto , detalle: presupuesto.detalle, generateItp:undefined, cliente: presupuesto.cliente};
  }

  downloadInvoice(presupuesto) {
    console.log(presupuesto)
    if(presupuesto.factura){
      this._presupuestoService.downloadZip(presupuesto.id).subscribe((data: ArrayBuffer) => {
            console.log(data)
            if (data == null) {
              this._notificationService.warning('Alerta', "No tiene contenido", 2000);
            } else {
              var file = new Blob([data], { type: 'application/zip' })
              var fileUrl = URL.createObjectURL(file)
              var a = document.createElement('a')
              a.href = fileUrl
              a.download = "Presupuesto_" + presupuesto.codigoPresupuesto + ".zip"
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              this._notificationService.success(
                "Hecho",
                "Documentos descargados con éxito",
                2000
              );
            }
          },
          error => {
            console.log(error);
          }    
      );
    }
    else{
      let documento= presupuesto.documento;
      this._presupuestoService
        .downloadJustificanteB64(documento.id_gestor_documental)
        .subscribe({
          next: (fichero) => {
            const linkSource = "data:application/pdf;base64," + fichero.Result;
            const downloadLink = document.createElement("a");
            const fileName = documento.nombre_documento;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            this._notificationService.success(
              "Hecho",
              "Pdf descargado con éxito",
              2000
            );
          },
          error: (error) => {
            //console.log(error);
          },
        });
    }
    
  }

  getDateFilter(fechas) {
    var fechaString = new Date(fechas);
    let month = "" + (fechaString.getMonth() + 1);
    let day = "" + fechaString.getDate();
    const year = fechaString.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year];
  }

  getDateToFilter(fechas) {
    var fechaString = new Date(fechas);
    let month = "" + (fechaString.getMonth() + 1);
    let day = "" + fechaString.getDate();
    const year = fechaString.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  showEditDeleteError(error){
    this._notificationService.info(
      "Aviso!",
      "No se puede " + error + " el presupuesto",
      2000
    );
  }

  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getPresupuestos();
  }

  getPresupuestos() {
    let rol = this._tokenStorageService.getRol();
    if (rol == 5 && this.firstFilter) {
      console.log(rol)
      let type = ['usuario.idPadre.id', 'usuario.idPadre.idPadre.id']
      let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        console.log(composeKey);
      let filterUser = {
        key: composeKey,
        keys: type,
        operator: "EQUAL_OR",
        field_type: "STRING",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
      this.firstFilter = true;
    }
    if (rol == 6 && this.firstFilter) {
      let type = 'usuario.idPadre.id'
      let filterUser = {
        key: type,
        operator: "EQUAL",
        field_type: "STRING",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
      this.firstFilter = true;
    }
    if (rol == 4 && this.firstFilter) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
      this.firstFilter=false;
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this._presupuestoService
      .getPresupuestoFilter(this.request)
      .subscribe((data: any) => {
        this.presupuestos = data.pageList;
        console.log(data);

        this.totalElements = data.nrOfElements;
      });
  }
  setMostrarFormularioChild(){
    this.getPresupuestos();
    this.setMostrarFormulario(false);
  }

  setEditarFormularioChild(){
    this.getPresupuestos();
    this.editarFormulario=false;
  }

calcTextWidth(text, font) {
  let canvas = this.canvas || (this.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width + 24;
}

downloadExcel(){
  this._presupuestoService
    .generateExcel()
    .subscribe({
      next: (data: any) => {
        console.log(data)
        const byteCharacters = atob(data["document"]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // Crea un objeto Blob y URL para el archivo
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        // Crea un enlace de descarga y simula un clic para descargar el archivo
        const a = document.createElement('a');
        a.href = url;
        a.download = 'lista_presupuesto.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url); }
        ,error:(error)=>{
          console.log(error)
        }
    });
}

  sortArrayInitialize(){
    this.sortArray = {codigoPresupuesto:'none', fecha:'none', nombre:'none', nombreEmpresa:'none', dni:'none', cif:'none',estado:'none', baseImponible:'none', importeIva:'none', importeIrpf: 'none', total: 'none'};
  }

  changeSorts(type, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = type instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of type){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(type);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){           
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    console.log(this.sorts)
    this.getPresupuestos();
  }

  filterVariables(vars){
    var DS_variables = vars.split("&");
    console.log(DS_variables)
    DS_variables[0] = DS_variables[0].replace('Ds_SignatureVersion=', '');
    DS_variables[1] = DS_variables[1].replace('Ds_MerchantParameters=', '').replace("%3D0", '');
    DS_variables[2] = DS_variables[2].replace('Ds_Signature=', '');
    const paymentRespVars = {
      Ds_SignatureVersion: DS_variables[0],
      Ds_MerchantParameters: DS_variables[1],
      Ds_Signature: DS_variables[2]
    }
    this._presupuestoService.getPaymentResponse(paymentRespVars).subscribe((data) => {
      console.log(data)
      var statusText = "ha sido pagada correctamente."
      if(data['status'] == 'unpaid'){
        statusText = "no se ha logrado pagar."
      }
      if(data['status'] == 'paid'){
        console.log(data['presu'].id_tramite, data['presu'])
        let info = data;
        this._presupuestoService.asociarTramiteAPresupuesto
        (data['presu'].id_tramite, data['presu'].id, data['presu'].tramite.id).subscribe(
          (data) => { }
          , (error) => { this.paymentData = {
            codPresu: info['presu'].codigoPresupuesto,
            orderId: info['orderId'],
            status: info['status'],
            statusText: statusText};
            const dialogPayment = document.getElementById("dialogPayment") as HTMLDialogElement;
            dialogPayment.showModal();
            this.getPresupuestos(); }
        )
        this.paymentData = {
          codPresu: data['presu'].codigoPresupuesto,
          orderId: data['orderId'],
          status: data['status'],
          statusText: statusText};
          const dialogPayment = document.getElementById("dialogPayment") as HTMLDialogElement;
          dialogPayment.showModal();
          this.getPresupuestos();
      }

    })
  }

}
