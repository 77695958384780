import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/_services/auth.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { Usuario } from "src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model";
import { Iban } from "../../model/hencok/iban.model";
import { FichaClienteActualizarComponent } from "../ficha-cliente-actualizar/ficha-cliente-actualizar/ficha-cliente-actualizar.component";
import { NotificationService } from "src/app/_services/notification.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";

@Component({
  selector: "app-ficha-cliente",
  templateUrl: "./ficha-cliente.component.html",
  styleUrls: ["./ficha-cliente.component.css"],
})
export class FichaClienteComponent {
  usuario: any;
  id: number;
  isAdmin: boolean;
  nombre: string;
  apellido1: string;
  apellido2: string;
  email: string;
  name_road: string;
  number_road: string;
  provincia: string;
  codigo_postal: string;
  telefono: string;
  ibann= undefined;
  entidad: string;
  titular: string;
  numero_documento: string;
  estado: boolean;
  mostrarFormulario: Boolean = false;
  observaciones: string;
  isActive: boolean;
  //duda
  data: DataCliente = {
    mostrarFormulario: false,
    elementUpdate: null,
    isForUpdate: false,
    iban: undefined,
  };
  //
  form = new FormGroup({
    nombre: new FormControl(""),
    apellido1: new FormControl(""),
    email: new FormControl(""),
    name_road: new FormControl(null),
    number_road: new FormControl(null),
    provincia: new FormControl(null),
    codigo_postal: new FormControl(null),
    telefono: new FormControl(null),
    iban: new FormControl(null),
    entidad: new FormControl(null),
    titular: new FormControl(null),
    numero_documento: new FormControl(null),
    estado: new FormControl(null),
  });
  listaProvincias;
  submitted: boolean = false;
  isIban: boolean = true;
  constructor(
    private _authService: AuthService,
    private _serviceGeneral: ServicioGeneral,
    private _route: ActivatedRoute,
    private _router: Router,
    private notificationService: NotificationService,
    private token: TokenStorageService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getAllProvincias();
  }
  disabled() {
    this.form.controls["numero_documento"].disable();
    this.form.controls["email"].disable();
  }
  initiateForm() {
    console.log("usuario cargado", this.usuario);
    //console.log("usuario numero documento", this.usuario?.numero_documento);
    //console.log(this.usuario?.twoFactorEnabled);
    this.nombre = this.usuario?.nombre;
    this.apellido1 = this.usuario?.apellido1;
    this.apellido2 = this.usuario?.apellido2;
    this.email = this.usuario?.email;
    this.name_road = this.usuario?.direcciones[0].name_road;
    this.number_road = this.usuario?.direcciones[0].number_road;
    this.provincia = this.usuario?.direcciones[0]?.province?.nombre;
    this.codigo_postal = this.usuario?.direcciones[0]?.postal_code;
    this.telefono = this.usuario?.telefono;
    this.ibann = this.iban?.iban;
    this.entidad = this.iban?.entidad;
    this.titular = this.iban?.titular;
    this.numero_documento = this.usuario?.numero_documento;
    this.estado = this.usuario?.active;
    this.isActive = this.usuario?.twoFactorEnabled;
    this.isAdmin = this.usuario?.isThird;
    this.getObservaciones();
    this.getIban();
    // console.log("es admin", this.isAdmin)
  }
  setInput() {
    this.isIban = false;
  }
  iban: Iban = new Iban();
  submit() {
    this.iban.iban = this.form.value.iban;
    this.iban.entidad = this.form.value.entidad;
    this.iban.titular = this.form.value.titular;
    this.iban.usuario = new Usuario(this.usuario);
    // console.log("IBAN PRE -->", this.iban)
    let provincia;
    this.submitted = true;
    this._authService
      .update(
        this.form.value.nombre == null
          ? this.usuario?.nombre
          : this.form?.value?.nombre,
        this.form.value.apellido1 == null
          ? this.usuario?.apellido1
          : this.form?.value?.apellido1,
        // this.form.value.apellido2 == null ? this.data?.elementUpdate?.apellido2 : this.form?.value?.apellido2,
        this.form.value.email == null
          ? this.usuario?.email
          : this.form?.value?.email,
        this.usuario?.tipopersona?.id,
        this.form?.value?.numero_documento == null
          ? this.usuario?.numero_documento
          : this.form?.value?.numero_documento,
        this.form.value.telefono == null
          ? this.usuario?.telefono
          : this.form?.value?.telefono,
        this.form.value.estado == null
          ? this.usuario?.active
          : this.form?.value?.estado

        // this.form.get('concepto')?.value,
        // this.form.get('fechaAlta')?.value,
        // this.form.get('importe')?.value,
        // this.form.get('Tipo')?.value,
        // this.form.get('IVA')?.value
      )
      .subscribe({
        next: async (data) => {
          // console.log("provincia pre -->", this.data?.elementUpdate?.direcciones[0]?.type_of_road?.id)
          // console.log(this.usuario)
          this._authService.saveIban(this.iban).subscribe({
            next: async (data) => {
              this._authService
                .updateUserDirection(
                  this.usuario?.direcciones[0].id,
                  this.usuario?.direcciones[0]?.type_of_road,
                  this.form.value.name_road == null
                    ? this.usuario?.direcciones[0]?.name_road
                    : this.form.value.name_road,
                  this.form.value.number_road == null
                    ? this.usuario?.direcciones[0]?.number_road
                    : this.form.value.number_road,
                  this.usuario?.direcciones[0]?.bloque,
                  this.usuario?.direcciones[0]?.escalera,
                  this.usuario?.direcciones[0]?.piso,
                  this.usuario?.direcciones[0]?.puerta,
                  this.form.value.codigo_postal == null
                    ? this.usuario.direcciones[0]?.postal_code
                    : this.form.value.codigo_postal,
                  this.usuario?.direcciones[0]?.country.id,
                  this.usuario?.direcciones[0]?.ccaa.id,
                  (provincia =
                    this.form.value.provincia == 0
                      ? null
                      : {
                          id: this.form.value.provincia,
                        }),
                  this.usuario?.direcciones[0]?.municipality,
                  this.usuario?.direcciones[0]?.isActive
                )
                .subscribe({
                  next: async (data) => {
                    this._router.navigate(["/"]).then(() => {
                      this._router.navigate([
                        `admin-hencok/perfil/`,
                        this.usuario.id,
                      ]);
                    });
                  },
                });
            },
          });
        },
      });
  }
  getAllProvincias() {
    this._serviceGeneral.getProvincias().subscribe((data) => {
      this.listaProvincias = data["Provincia"];
    });
  }
  private async getUser(): Promise<void> {
    let rol = this.token.getRol()
    if( rol == 99 || rol == 2 || rol == 3 || rol == 5){
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    if (this.isAdmin) {
      this._route.params.subscribe((params) => {
        this.id = params["id"];
      });
    } else {
      this.id = this.token.getUser().id;
    }

    await this._serviceGeneral.getUserId(this.id).subscribe({
      next: (usuario) => {
        this.usuario = usuario["Usuario"];
        // console.log("usuario --->", this.usuario)
        this.initiateForm();

        // console.log("es admin", this.isAdmin)
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }

  private getIban(): void {

    this._serviceGeneral.getIbanByUser(this.id).subscribe({
      next: (iban) => {
        this.iban = iban;
        this.ibann = this.iban?.iban;
        this.entidad = this.iban?.entidad;
        this.titular = this.iban?.titular;
         //console.log("iban ", this.iban)
      },
      error: (error) => {
        this.iban = undefined;
        //console.log("iban ", this.iban)
        console.log(error);
      },
    });
  }

  getFirstLetter(nombre: string) {
    return nombre?.charAt(0).toUpperCase();
  }
  ibanData;
  setMostrarFormulario(mode: Boolean, element?: any, isForUpdate?: Boolean) {
    this.mostrarFormulario = mode;
    this.data = {
      mostrarFormulario: mode,
      elementUpdate: element,
      isForUpdate: isForUpdate,
      iban: this.iban,
    };
  }
  getValueForm(event) {
    this.mostrarFormulario = event.mostrarFormulario;
    //this.getPropietarios();
  }
  getObservaciones() {
    this._route.params.subscribe((params) => {
      this.id = params["id"];
    });
    this._serviceGeneral.getObservacionesUsuario(this.id).subscribe({
      next: (observacion) => {
        if (observacion != null) {
          this.observaciones = observacion["observacion"];
        }
        
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }
  updateTwoFactor() {
    if (this.usuario.twoFactorEnabled) {
      this.usuario.twoFactorEnabled = false;
    } else {
      this.usuario.twoFactorEnabled = true;
    }
    this._authService
      .updateTwoFactor(this.usuario.id, this.usuario.twoFactorEnabled)
      .subscribe({
        next: async (data) => {
          this._router.navigate(["/"]).then(() => {
            this._router.navigate([`admin-hencok/perfil/`, this.usuario.id]);
          });
        },
      });
  }
  goUpdate() {
    let rol = this.token.getRol();
    if (rol == 99 || rol == 2) {
      this._router.navigate(["/admin-hencok/editarCliente/" + this.usuario.id]);
    }else if (rol==3){
      this._router.navigate(["/admin-autoescuelas-hencok/editarCliente/" + this.usuario.id]);
    }else if (rol==5 || rol==6){
      this._router.navigate(["/mod-autoescuelas-hencok/editarCliente/" + this.usuario.id]);
    } 
    else if(rol==4){
      this._router.navigate(["client-autoescuelas-hencok/miPerfil/editar"]);
    }
    else{
      this._router.navigate(["client-hencok/miPerfil/editar"]);
    }
  }
  downloadContrato() {
    let contrato = undefined;
    if(this.usuario.idDocumentoFirmado == undefined){
      contrato = this.usuario.docContrato.id_gestor_documental
    }
    else{
      contrato = this.usuario.idDocumentoFirmado
    }
    console.log(contrato)
    this._serviceGeneral
      .downloadJustificanteB64(contrato).subscribe({
        next: (documento) => {
          const byteArray = new Uint8Array(atob(documento.Result).split('').map(char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank")
        },
        error: (error) => {
          this.notificationService.error('Error', 'No se ha podido abrir el archivo', 2000);
        }
      });
  }
}

export interface DataCliente {
  elementUpdate: any; // -- Campo para el caso de actualizar
  isForUpdate: Boolean;
  mostrarFormulario: Boolean;
  iban: any;
}
