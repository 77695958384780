import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultaService {
  
  protected resourceUrltransfe = `${environment.API_URL}/tramite/consulta`;
  protected API_URL = environment.API_URL

  constructor(
    protected http: HttpClient,
    protected token:TokenStorageService
  ) { }


  saveConsulta(form: any) {
    return this.http.post(`${this.resourceUrltransfe}/saveConsulta`, form);
  }
  updateConsulta(form: any,id) {
    return this.http.post(`${this.resourceUrltransfe}/updateConsulta/${id}`, form);
  }
  getConsultaById(id) {
    return this.http.get(`${this.resourceUrltransfe}/getConsulta/${id}/${this.token.getId()}`);
  }
}
