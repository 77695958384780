<div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'page_content_sidebar':'page__content'">
    <div class="content__inner"
        [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'altura-formulario-Hencok' : '' ">
        <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'container2':'container' ">
            <div class="left" *ngIf="!isLoggedIn">
                <img src="{{environment.ICON_LATERAL}}" alt="Logo" class="logo">
                <div class="px-3" *ngFor="let informacion of InfoRegistro">
                    <h3 *ngIf="!informacion.cuerpo">Alta usuario</h3>
                    <p><strong *ngIf="informacion.cuerpo">{{informacion.tituloWeb}}</strong> {{informacion.cuerpo}}</p>
                </div>
                <div *ngIf="environment.environment != 'hencok'">
                    <p class="autofirma">Para la correcta presentación de solicitudes, es necesario realizar algunas
                        configuraciones en el ordenador. Estas configuraciones están enfocadas a poder realizar
                        operaciones con certificados electrónicos, tales como la identificación en la Sede Electrónica o
                        la firma de la solicitud: Autofirma se puede descargar desde el siguiente <a
                            href="http://firmaelectronica.gob.es/Home/Descargas.html"
                            class="linkDestacado">enlace.</a>Para su correcta instalación se recomienda cerrar todos los
                        navegadores de internet y ejecutar el instalador como administrador</p>
                </div>
            </div>
            <div [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'center-Hencok-logged':'center'">
                <div>
                    <h1>{{environment.TITLE_REGISTER}}</h1>
                    <p>
                        {{environment.TEXT_REGISTER}}
                    </p>
                </div>
                <form *ngIf="!isSuccessful" name="form" (ngSubmit)="onSubmit(dialog)" [formGroup]="form" novalidate>
                    <div class="row" [ngClass]="!isLoggedIn ? 'pt-4' : 'pt-3'">
                        <div class="col-sm-12 firstFormTitle">
                            <label class="formTitleText">Datos personales</label>
                        </div>
                        <div class="row full-form-group addingCustom" *ngIf="!isTipoPersona"
                            [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'border-top paddingCustom-Hencok':'border paddingCustom' ">

                            <div class="rowForm col-sm-4 ">
                                <label class="crear-editar-form requerido">Tipo de persona</label>
                                <select class="form-control" formControlName="tipo_persona"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['tipo_persona'].errors}" (change)="tipoPersona()">
                                    <option *ngFor="let tipopersona of TPersonas" [value]="tipopersona.id">
                                        {{ tipopersona.nombre }}
                                    </option>
                                </select>
                            </div>

                            <div class="rowForm col-sm-4">
                                <label class="crear-editar-form requerido">Nombre</label>
                                <input type="text" class="form-control" name="nombre" formControlName="nombre"
                                    minlength="3" maxlength="20"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['nombre'].errors}" />
                            </div>

                            <div class="rowForm col-sm-4">
                                <label class="crear-editar-form requerido">Primer apellido</label>
                                <input type="text" class="form-control" name="apellido1" formControlName="apellido1"
                                    minlength="3" maxlength="20"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['apellido1'].errors}" />
                            </div>
                            

                            <div class="rowForm col-sm-4">
                                <label class="crear-editar-form requerido">Segundo apellido</label>
                                <input type="text" class="form-control" name="apellido2" formControlName="apellido2"
                                    minlength="3" maxlength="20"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['apellido2'].errors}" />
                            </div>


                            <div class="rowForm col-sm-4">
                                <label class="crear-editar-form requerido">Tipo de documento</label>
                                <select class="form-control" formControlName="tipo_documento"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['tipo_documento'].errors}">
                                    <option *ngFor="let tipodocumento of TDocumentos" [value]="tipodocumento.id">
                                        {{ tipodocumento.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="rowForm col-sm-4">
                                <label class="crear-editar-form requerido">Nº de documento</label>
                                <input name="numero_documento" class="w-100 text-align-center form-control"
                                    formControlName="numero_documento"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['numero_documento'].errors}" />
                            </div>
                        </div>
                            <!-- N*ngIf tipo-->
                        <div class="row full-form-group paddingCustom" *ngIf="isTipoPersona"
                            [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'border-top paddingCustom-Hencok':'border paddingCustom' ">

                            <div class="rowForm col-sm-4 ">
                                <label class="crear-editar-form requerido">Tipo de persona</label>
                                <select class="form-control" formControlName="tipo_persona"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['tipo_persona'].errors}" (change)="tipoPersona()">
                                    <option *ngFor="let tipopersona of TPersonas" [value]="tipopersona.id">
                                        {{ tipopersona.nombre }}
                                    </option>
                                </select>
                            </div>

                            <div class="rowForm col-sm-4">
                                <label class="crear-editar-form requerido">Razón social</label>
                                <input type="text" class="form-control" name="nombre" formControlName="nombre"
                                    minlength="3" maxlength="255"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['nombre'].errors}" />
                            </div>

                            <div class="rowForm col-sm-4 ">
                                <label class="crear-editar-form requerido">Tipo de documento</label>
                                <select class="form-control" formControlName="tipo_documento"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['tipo_documento'].errors}">
                                    <option  [value]="tipoDoc.id">
                                        {{ tipoDoc.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="rowForm col-sm-4">
                                <label class="crear-editar-form requerido">Nº de documento</label>
                                <input name="numero_documento" class="w-100 text-align-center form-control"
                                    formControlName="numero_documento"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['numero_documento'].errors}" />
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="environment.environment == 'hencok' && rol!=3 && rol!=5 && rol!=6"
                        [ngClass]="!isLoggedIn ? 'mt-4' && 'mb-3' : ''">
                        <div class="col-sm-12 formTitle">
                            <label class="formTitleText">Datos Bancarios</label>
                        </div>
                        <div class="row m-10 full-form-group paddingCustom  w-100"
                            [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'border-top paddingCustom-Hencok':'border paddingCustom'">
                            <div class="col-sm-3 rowForm" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">Titular</label>
                                <input name="titular" class="w-100 text-align-center form-control"
                                    formControlName="titular"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['titular'].errors}" />
                            </div>
                            <div class="col-sm-6 rowForm" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">IBAN</label>
                                <input name="iban" class="w-100 text-align-center form-control" formControlName="iban"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['iban'].errors}" />
                            </div>
                            <div class="col-sm-3 rowForm" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">Entidad Bancaria</label>
                                <input name="entidad_bancaria" class="w-100 text-align-center form-control"
                                    formControlName="entidad_bancaria"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['entidad_bancaria'].errors}" />
                            </div>
                        </div>
                    </div>

                    <div class="row" [ngClass]="!isLoggedIn ? 'mt-4' && 'mb-3' : ''">
                        <div class="col-sm-12 formTitle">
                            <label class="formTitleText">Datos de contacto</label>
                        </div>
                        <div class="row full-form-group paddingCustom"
                            [ngClass]="isLoggedIn && environment.environment == 'hencok' ? 'border-top paddingCustom-Hencok':'border paddingCustom'">
                            <div class="col-sm-3 rowForm">
                                <label class="crear-editar-form requerido">Tipo de vía</label>
                                <select formControlName="tipo_via" class="form-control"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['tipo_via'].errors}">
                                    <option *ngFor="let vias of TVias" [value]="vias.id">
                                        {{ vias.tipovianormalizado }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-6 rowForm">
                                <label class="crear-editar-form requerido">Dirección</label>
                                <input type="text" formControlName="nombre_via" class="form-control"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['nombre_via'].errors}" />
                            </div>


                            <div class="col-sm-3 rowForm">
                                <label class="crear-editar-form requerido">Nº de la vía</label>
                                <input type="text" formControlName="numero_via" class="form-control"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['numero_via'].errors}" />
                            </div>

                            <div class="col-sm-3 rowForm">
                                <label class="crear-editar-form">Bloque:</label>
                                <input type="text" formControlName="bloque" class="form-control" />
                            </div>

                            <div class="col-sm-3 rowForm">
                                <label class="crear-editar-form">Escalera:</label>
                                <input type="text" formControlName="escalera" class="form-control" />
                            </div>

                            <div class="col-sm-3 rowForm">
                                <label class="crear-editar-form">Piso:</label>
                                <input type="text" formControlName="piso" class="form-control" />
                            </div>

                            <div class="col-sm-3 rowForm">
                                <label class="crear-editar-form">Puerta:</label>
                                <input type="text" formControlName="puerta" class="form-control" />
                            </div>

                            <div class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">País</label>
                                <select formControlName="pais" class="form-control"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['pais'].errors}">
                                    <option *ngFor="let _pais of TPais" [value]="_pais.id">
                                        {{ _pais.nombre }}
                                    </option>
                                </select>
                            </div>

                            <div class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">Comunidad</label>
                                <select formControlName="ccaa" class="form-control" (change)="onChangeProvincia($event)"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['ccaa'].errors}">
                                    <option *ngFor="let ccaa of TComunidades" [value]="ccaa.id">
                                        {{ ccaa.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">Provincia</label>
                                <select formControlName="provincia" class="form-control"
                                    (change)="onChangeMunicipio($event)"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['provincia'].errors}">
                                    <option *ngFor="let provincia of TProvincias" [value]="provincia.id">
                                        {{ provincia.nombre }}
                                    </option>
                                </select>
                            </div>

                            <div class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">Municipio</label>
                                <select formControlName="municipio" class="form-control"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['municipio'].errors}">
                                    <option *ngFor="let municipio of TMunicipios"
                                        [value]="municipio.municipioPK['municipio_id']">
                                        {{ municipio.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">Código postal</label>
                                <input type="text" mask="9{5}" formControlName="codigo_postal" class="form-control"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['codigo_postal'].errors}" />
                            </div>
                            <div class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''">
                                <label class="crear-editar-form requerido">Teléfono</label>
                                <input type="text" class="form-control" name="telefono" formControlName="telefono"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['telefono'].errors}" />
                            </div>
                            <div class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''"
                                style="background-color:#dae7f1; padding-bottom:15px; margin-top:1rem !important">
                                <label class="crear-editar-form requerido">Email</label>
                                <input type="email" name="email" class="form-control" formControlName="email"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['email'].errors}" />
                            </div>

                            <div *ngIf="rol!=3 && rol!=5 && rol!=6" class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''"
                                style="background-color:#dae7f1; padding-bottom:15px; margin-top:1rem !important">
                                <label class="crear-editar-form requerido">Contraseña</label>
                                <div class="password-div">
                                    <input type="{{showPassword ? 'text' : 'password' }}" class="form-control password-form-control" name="password" formControlName="password"
                                    minlength="6"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['password'].errors, 'is-valid': isRequired&& this.form.controls['password'].errors == null}" />
                                    <mat-icon matSuffix (click)="showPassword = !showPassword" class="eye-b">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </div>
                                <span style="font-size: 12px; font-weight: 400;" *ngIf="this.form.controls['password'].errors">*Mínimo 6 caracteres</span>
                            </div>
                            <div *ngIf="rol!=3 && rol!=5  && rol!=6" class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''"
                                style="background-color:#dae7f1; padding-bottom:15px; margin-top:1rem !important">
                                <label class="crear-editar-form requerido">Confirmar contraseña</label>
                                <div class="password-div">
                                    <input type="{{showConfirmPassword ? 'text' : 'password' }}" class="form-control password-form-control" name="password_confirmacion"
                                    formControlName="password_confirmacion" minlength="6"
                                    [ngClass]="{'is-invalid': isRequired&& this.form.controls['password_confirmacion'].errors, 'is-valid': isRequired&& this.form.controls['password_confirmacion'].errors == null}" />
                                    <mat-icon matSuffix (click)="showConfirmPassword = !showConfirmPassword" class="eye-b">{{showConfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </div>
                                <span style="font-size: 12px; font-weight: 400;" *ngIf="this.form.controls['password_confirmacion'].errors">*Mínimo 6 caracteres</span>
                            </div>
                            <div *ngIf="rol==3 || rol==5 || rol==6 || rol==99" class="rowForm col-sm-4" [ngClass]="!isLoggedIn ? 'mt-3' : ''"
                                style="background-color:#dae7f1; padding-bottom:15px; margin-top:1rem !important">
                                <label class="crear-editar-form requerido">Rol</label>
                                <select formControlName="rol_control" class="form-control" [ngClass]="{'is-invalid': isRequired&& this.form.controls['rol_control'].errors}">
                                <option *ngFor="let userRol of roles"
                                    [value]="userRol['id']">
                                    {{ userRol['nombre'] }}
                                </option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 d-flex justify-content-end">
                        <button type="button" class="btn btn-outline-primary mr-2" [attr.disabled]="submitted?'':null"
                            style="margin-bottom: 1rem;" (click)= "reload()">
                            {{isLoggedIn ? 'Cancelar'  : ' Volver a login'}}
                        </button>
                        <button type="submit" class="btn btn-primary" [attr.disabled]="submitted?'':null"
                            style="margin-bottom: 1rem;">
                            <div class="spinner-border spinner-border-sm" *ngIf="submitted"></div>
                            {{isLoggedIn ? 'Guardar' : 'Registrar'}}
                        </button>
                    </div>

                </form>
                <div class="alert alert-success" *ngIf="isSuccessful">
                    Se ha registrado satisfactoriamente!
                </div>
                <dialog #dialog>
                    <div class="m-3 justify-content-center align-items-center">
                        <div class="m-4 d-flex align-items-center">

                            <h5 id="textdialog">¿Desea restaurar al usuario?
                            </h5>
                        </div>
                        <div class="d-flex justify-content-end m-4">
                            <button (click)="cancelRestore(dialog)" id="btcancel"
                                class="btn btn-primary mr-1"
                                type="submit">Cancelar</button>
                                <button type="submit" class="btn btn-primary" [attr.disabled]="submitted?'':null" (click)="onSubmit(dialog)">
                                <div class="spinner-border spinner-border-sm" *ngIf="submitted"></div>
                                {{isLoggedIn ? 'Guardar' : 'Registrar'}}
                            </button>
                        </div>
                    </div>
                </dialog>
            </div>
        </div>
    </div>
</div>