<div class="padre">
        <div class="pageTitle">
            <h1 class="m-0"> Calculadora ITP</h1>

        </div>
        <div class="pageTitle subtitle" >
            <h5 class=""> Comprueba el impuesto de transmisiones patrimoniales de tu vehículo</h5>
        </div>

    <form name="form" [hidden]="mostrarFormulario" (ngSubmit)="onSubmit() " [formGroup]="form" novalidate method="post" enctype="multipart/form-data"
        class="full-form-group">
        <div class="row m-0">
            <div class="col-md-6 pl-0">
                <div class="extendedRowForm">
                    <label class="crear-editar-form crear-editar-form requerido">Tipo de Vehículo:</label>
                    <div class="d-flex justify-content-start">
                        <div class="mr-3 mt-2">
                            <input  class="mr-2" formControlName="tipo_vehiculo" type="radio" name="tipo" value="COCHE" (click)="vehicleTypeChange('COCHE')" ><label class="textLabel">Coche</label>
                        </div>
                        <div class="ml-3 mt-2">
                            <input  class="mr-2" formControlName="tipo_vehiculo" type="radio" name="tipo" value="MOTO" (click)="vehicleTypeChange('MOTO')"><label class="textLabel">Moto</label>
                        </div>
                    </div>
                </div>
        
                <div class="extendedRowForm">
                    <label class="crear-editar-form requerido">Comunidad</label>
                    <select class=" mt-1 form-control tipo" formControlName="comunidad_autonoma"
                        [ngClass]="{'is-invalid': submitted &&  this.form.controls['comunidad_autonoma'].errors}">
                        <option *ngFor="let ccaa of TComunidades" [value]="ccaa.id">
                            {{ ccaa.nombre }}
                        </option>
                    </select>
                    <div class="checks2 mr-6 pb-2"*ngIf="form.controls['comunidad_autonoma'].value =='18' || form.controls['comunidad_autonoma'].value =='19'">
                        <label class="d-flex justify-content-end mr-6">
                            <input  class="mr-1" formControlName="empadronado" type="checkbox" name="empadronado">Empadronado</label>
                    
                    </div>
                </div>
                <div class="extendedRowForm">
                    <label class="crear-editar-form requerido">Combustible</label>
                    <select class=" mt-1 form-control tipo " formControlName="combustible" name="combustible"
                        (change)="this.getModelos($event)"
                        [ngClass]="{'is-invalid': submitted &&  this.form.controls['combustible'].errors}">
                        <option *ngFor="let carburante of TCarburantes" [value]="carburante.id">
                            {{carburante.tipo}}
                        </option>
                    </select>
                </div>
          
            </div>
            <div  class="col-md-6 pr-0"> 
         
                <div >
                <div class="extendedRowForm">
                    <label class="crear-editar-form requerido">Fecha de Matriculación</label>
                    <input class=" mt-1 form-control tipo" formControlName="fecha_matriculacion" type="date" name="fechaMatriculacion"
                        [ngClass]="{'is-invalid': submitted &&  this.form.controls['fecha_matriculacion'].errors}">
                  
                </div>
                <div class="checks">
                    <label class="d-flex justify-content-end textLabel">
                        <input class="mr-1" formControlName="historico" type="checkbox" name="historico">Histórico</label>
                </div>
                <div class="extendedRowForm" *ngIf="this.form.controls['combustible'].value!=5 && this.form.controls['tipo_vehiculo'].value=='MOTO'">
                    <label class="crear-editar-form requerido">Cilindrada</label>
                    <input type="text" placeholder="0,00" mask="0*,00" class="mt-1 form-control tipo" name="cilindrada"
                        formControlName="cilindrada"
                        [ngClass]="{'is-invalid': submitted && this.form.controls['cilindrada'].errors}" />
            
                </div>
                
                <div class="extendedRowForm" *ngIf="this.form.controls['combustible'].value==5  && this.form.controls['tipo_vehiculo'].value=='MOTO'">
                    <label class="crear-editar-form requerido">Potencia kW</label>
                    <input type="text" placeholder="0,00" mask="0*,00" class="mt-1 form-control tipo" name="potencia_kW"
                        formControlName="potencia_kW"
                        [ngClass]="{'is-invalid': submitted && this.form.controls['potencia_kW'].errors}" />
            
                </div> 
        
                <div class="extendedRowForm" *ngIf="this.form.controls['tipo_vehiculo'].value!='MOTO'">
                    <label class="crear-editar-form requerido">Marca</label>
                    <select class=" mt-1 form-control tipo" formControlName="marca" name="marca" id="marca"
                        (change)= "onChangeMarca($event)"
                        [ngClass]="{'is-invalid': submitted &&  this.form.controls['marca'].errors}">
                        <option *ngFor="let marca of TMarcas" [value]="marca">
                            {{ marca}}
                        </option>
                    </select>
                </div>
            
                <div class="extendedRowForm" *ngIf="this.form.controls['tipo_vehiculo'].value!='MOTO'">
                    <label class="crear-editar-form requerido">Modelo</label>
                    <select class=" mt-1 form-control tipo" formControlName="modelo" name="modelo"
                        [ngClass]="{'is-invalid': submitted &&  this.form.controls['modelo'].errors}">
                        <option *ngFor="let modelo of TModelos" [value]="modelo">
                            {{modelo}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

   
        <div class="col d-flex justify-content-end mt-3 pt-4 mr-0 pr-0 ">
            <button *ngIf="!isMod" class="btn btn-outline-primary" type="button" (click)="generatePresupuesto(true)">Generar presupuesto </button>
            <button class="btn btn-primary ml-4" type="submit">Calcular</button>    
        </div>
    </div>
    </form>

    <div class="ml-4 mr-4">
        <textarea *ngIf="DatosRecividos.size!=0" class="form-control mt-4 pt-4" rows="9" style="resize: none;"
        disabled>
        Valoración en tablas: {{DatosRecividos.get("valoracón en tablas")}}€
        Factor de corrección: {{DatosRecividos.get("factorCorrecion")}}%
        Tipo gravemen: {{DatosRecividos.get("tipoGravement")}} 
        CC: {{DatosRecividos.get("cc")}}  
        CVf :{{DatosRecividos.get("CVf ")}} 
        Comunidad: {{DatosRecividos.get("comunidad")}} 
        Descuento: {{DatosRecividos.get("descuento_empadronamiento")}}
        ITP: {{DatosRecividos.get("facturacion")}}€
        </textarea>
    </div>
    
    <div [hidden]="!mostrarFormulario" class="mt-3">
        <app-presupuesto (newItemEvent)="setMostrarFormularioChild($event)" [comprador]="comprador" [vendedor]=" vendedor"  [vehiculo]="vehiculo"[itpInfo]="itpPresupuesto" [formVehiculo]="form.value" ></app-presupuesto>
    </div>


   
      