import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-raiz',
  templateUrl: './raiz.component.html',
  styleUrls: ['./raiz.component.css']
})
export class RaizComponent implements OnInit {

  currentEnv: string;
  currentLocation:string;
  public reloadHeader:boolean = false;

  constructor(
    public router: Router,
    public location: Location,
  ) {
    this.currentEnv = environment.environment;    
  }


  ngOnInit(): void {
  }

}
