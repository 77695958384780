import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.hencok';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Presupuesto } from 'src/app/model/autoescuela/presupuesto';
import { DetallesPresupuesto } from 'src/app/model/autoescuela/detalles_presupuesto';
import { Observable } from 'rxjs';
import { ClientePresupuesto } from 'src/app/model/autoescuela/clientePresupuesto';

@Injectable({
  providedIn: 'root'
})
export class PresupuestoService {
  protected resourceUrl = `${environment.API_URL}/presupuesto`;
  protected API_URL = environment.API_URL;

  constructor(protected http: HttpClient) { }

  create(presupuesto: Presupuesto, detallepresupuesto: DetallesPresupuesto[], cliente: ClientePresupuesto){   
    console.log({'presupuesto': presupuesto, 'detalles': detallepresupuesto, 'cliente': cliente}) 
    return this.http.post(`${this.resourceUrl}/save`, {'presupuesto': presupuesto, 'detalles': detallepresupuesto, 'cliente': cliente});
  }

  update(presupuesto: Presupuesto, detallepresupuesto: DetallesPresupuesto[], cliente: ClientePresupuesto){   
    console.log({'presupuesto': presupuesto, 'detalles': detallepresupuesto, 'cliente': cliente}) 
    return this.http.post(`${this.resourceUrl}/update`, {'presupuesto': presupuesto, 'detalles': detallepresupuesto, 'cliente': cliente});
  }

  delete(id: number) {
    return this.http.post(this.resourceUrl + "/delete", {"id": id});
  }

  list() {
    return this.http.get(this.resourceUrl + "/list");
  }

  listByUsuario(id: number) {
    return this.http.get(this.resourceUrl + `/listByUsuario/${id}`);
  }

  getDocPreview(presupuesto: Presupuesto, detallepresupuesto: DetallesPresupuesto[], cliente: ClientePresupuesto) {
    return this.http.post(`${this.resourceUrl}/preview_doc`, { 'presupuesto': presupuesto, 'detalles': detallepresupuesto, 'cliente': cliente });
  }

  getCodigo(year: number) {
    let json = {
      "year": year
    }
    return this.http.post(`${this.resourceUrl}/getCodigo`, json);
  }

  downloadJustificanteB64(id: number): Observable<any> {
    let API_URL = `${this.API_URL}/nextcloud/downloadB64/${id}`;
    return this.http.get(API_URL);
  }

  downloadZip(id: number): Observable<any> {
    return this.http.get(`${this.resourceUrl}/download/${id}`,{responseType: 'arraybuffer'});
  }

  getPaginatedPresupuestoListByUsuario(id: number, request) {
    const params = request;

    return this.http
      .get(`${this.resourceUrl}/listPaginateByUser/${id}`, { params });

  }

  getPaginatedPresupuestoList(request) {
    const params = request;

    return this.http
      .get(`${this.resourceUrl}/listPaginate`, { params });

  }

  getEstadosList() {
    return this.http.get(`${this.resourceUrl}/getAllEstados`);
  }

  updateEstado(estado: string, id: number) {
    const infoPresupuesto = {
      estado: estado,
      id: id
    }
    return this.http.post(`${this.resourceUrl}/updateEstado`, infoPresupuesto);
  }

  getPresupuestoFilter(request: any) {
    return this.http.post(`${this.resourceUrl}/filter`, request);
  }

  getTramitesSinAsociar(id) {
    return this.http.get(`${this.resourceUrl}/getTramitesSinAsociar/${id}`);
  }


  asociarTramiteAPresupuesto(id_tramitre: number, id_presupuesto: number, tipo_tramite: number) {
    const infoPresupuesto = {
      idTramite: id_tramitre,
      idPresu: id_presupuesto,
      tipoTramite: tipo_tramite
    }
    console.log(infoPresupuesto)
    return this.http.post(`${environment.API_URL}/tramite/vincularTramiteAPresupuesto`, infoPresupuesto);
  }

  generateExcel(){
    return this.http.get(`${this.resourceUrl}/generateExcel`);
  }

  
  tryPayment(id,route): Observable<any> {
    return this.http.post(`${this.resourceUrl}/pagoTpv`, { id,route })
  }

  getPaymentResponse(paymentRespVars){
    return this.http.post(`${this.resourceUrl}/pagoRespuesta`, paymentRespVars)
  }
}
