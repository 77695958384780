import { TipoGasto } from "../tipoGasto";

export class Factura{
    id?:number;
    fechaFactura= "";
    fechaVencimiento="";
    importeIva="";
    subtotal="";
    cantidadTotal= "";
    numeroFactura="";
    idCliente="";
    email = "";
    numeroDocumento = "";
    nombre = "";
    domicilio = "";
    codigoPostal = "";
    localidad = "";
    tipoGasto = {};
    rectificada? = false;
    estado? = "";

    

    constructor(fecha_factura: string,fecha_vencimiento: string,importe_iva: string,subtotal: string,cantidad_total: string,numeroFactura: string, idCliente:string,email: string,numeroDocumento: string,nombre: string,domicilio: string,codigoPostal: string,localidad: string,tipoGasto: TipoGasto, id?: number, rectificada?:boolean, estado?:string){
        this.fechaFactura  = fecha_factura;
        this.fechaVencimiento = fecha_vencimiento;
        this.importeIva = importe_iva;
        this.subtotal = subtotal;
        this.cantidadTotal = cantidad_total;
        this.numeroFactura = numeroFactura;
        this.idCliente = idCliente;
        this.email = email;
        this.numeroDocumento = numeroDocumento;
        this.nombre = nombre;
        this.domicilio = domicilio;
        this.codigoPostal = codigoPostal;
        this.localidad = localidad;
        this.tipoGasto=tipoGasto;
        this.id=id;
        this.rectificada=rectificada;
        this.estado = estado;
    }

}

