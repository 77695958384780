import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { FacturaService } from "src/app/_services/hencok/factura.service";
// import { NotificationsService } from 'angular2-notifications';
import { NotificationService } from "src/app/_services/notification.service";
import { PdfGenerateHencokService } from "src/app/_services/pdfGenerateHencok/pdf-generate-hencok.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import { UserService } from "src/app/_services/user.service";
import { Usuario } from "src/app/model/novotaxi/gastos-ingresos/gastos-ingresos.model";
import { Factura } from "src/app/model/hencok/factura";
import { ClienteService } from "src/app/_services/hencok/cliente.service";
import { GastoIngreso } from "src/app/model/hencok/gasto_ingreso";

const cifRegex = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const nifRegex = /^(\d{8})([A-Z])$/;
const nieRegex = /^[XYZ]\d{7,8}[A-Z]$/;
const codPostalRegex = /^[0-9]{5,5}$/;

@Component({
  selector: "app-cumplimentacion-factura",
  templateUrl: "./cumplimentacion-factura.component.html",
  styleUrls: ["./cumplimentacion-factura.component.css"],
})
export class CumplimentacionFacturaComponent implements OnInit {
  usuarioAutonomo: any;
  usuario: any;

  mostrarPagina: boolean = true;
  submitForm: boolean = false;

  fechaFactura: any;
  fechaVencimiento: any;
  id_factura: any;
  detalles: any[] = [];
  detallesFinal: any = {
    detalles: this.detalles,
  };

  detalle: any = {
    concepto: "",
    cantidad: 0,
    precio: "",
    descuento: "",
    cantidadNeta: 0,
    tipoIva: 0,
  };

  factura: any = {
    id: "",
    fechaFactura: "",
    fechaVencimiento: "",
    importeIva: "",
    subtotal: "",
    cantidadTotal: "",
    numeroFactura: "",
    email: "",
    numeroDocumento: "",
    nombre: "",
    domicilio: "",
    codigoPostal: "",
    localidad: "",
    tipoGasto: {},
    idCliente: "",
    //"detalle" : this.detalles
    //? ADD estado y año
  };

  emailSplit: any;
  email: any;
  domicilio_numero: any;
  constructor(
    private _facturaService: FacturaService,
    private _notificationService: NotificationService,
    private _generatePdf: PdfGenerateHencokService,
    private _usuarioService: UserService,
    private _clienteService: ClienteService,
  ) {}

  ngOnInit(): void {
    var path = window.location.href.split("/");
    this.id_factura = path[path.length - 1];
    this._facturaService.getByFacturaId(this.id_factura).subscribe({
      next: (data) => {
        if(data["idCliente"]==0){
          this.detalles = data["detalle"];
          //console.log(data);
  
          this.fechaFactura = this.getCorrectDateFormat(
            new Date(data["fechaFactura"])
          );
          this.fechaVencimiento = this.getCorrectDateFormat(
            new Date(data["fechaVencimiento"])
          );
          this.factura["fechaFactura"] = this.formatDate(data["fechaFactura"]);
          this.factura["fechaVencimiento"] = this.formatDate(
            data["fechaVencimiento"]
          );
          this.factura["importeIva"] = Number.parseFloat(
            data["importeIva"].toFixed(2)
          ).toString();
          this.factura["subtotal"] = data["subtotal"].toString();
          this.factura["cantidadTotal"] = data["cantidadTotal"].toString();
          this.factura["email"] = data["email"];
          this.factura["id"] = data["id"];
          this.factura["tipoGasto"] = { id: data["tipoGasto"].id };
          //this.factura["detalle"] = this.detalles;
          this.emailSplit = this.factura["email"].split("@");
          this.email = this.emailSplit[0] + "@" + this.emailSplit[1];
          this.emailSplit = this.emailSplit[0] + " @" + this.emailSplit[1];
          this.usuarioAutonomo = data["usuario"];
          //console.log("eeefeewfwfugsdjfgdsjgfj", this.emailSplit);
          //console.log(this.usuarioAutonomo);
        }
        else{
          this.mostrarPagina=false;
        }
      },
      error: (error) => {
        //console.log(error);
      },
    });
  }

  formClient: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl("", Validators.required),
    nombre: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl("", Validators.required),
    domicilio: new UntypedFormControl("", Validators.required),
    numero_direccion: new UntypedFormControl("", Validators.required),
    codigo_postal: new UntypedFormControl("", Validators.required),
    localidad: new UntypedFormControl("", Validators.required),
    autonomo_id: new UntypedFormControl("", Validators.required),
  });

  formFactura: FormGroup = new FormGroup({
    nombre: new FormControl("", Validators.required),
    cif: new FormControl("", [Validators.required, this.validateInput]),
    domicilio: new FormControl("", Validators.required),
    codigo_postal: new FormControl("", [
      Validators.required,
      Validators.pattern(codPostalRegex),
    ]),
    localidad: new FormControl("", Validators.required),
  });

  validateInput(c: FormControl) {
    return c.value.match(cifRegex) ||
      c.value.match(nifRegex) ||
      c.value.match(nieRegex)
      ? null
      : { checkValidAddress: true };
  }

  onSubmitFacturas() {
    
    this.submitForm = true;

    if (this.validacionesFormulario()) {
      this.saveCliente();

      return true;
    } else {
      return false;
    }
  }

  downloadPdf(base64Document, fileName) {
    const source = "data:application/pdf;base64," + base64Document;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }

  validacionesFormulario() {
    var result = true;

    if (this.formFactura.controls["nombre"].value === "") {
      this._notificationService.warning(
        "Informacion",
        "El nombre es obligatorio",
        2000
      );
      result = false;
    }
    if (this.formFactura.controls["cif"].value === "") {
      this._notificationService.warning(
        "Informacion",
        "El cif es obligatorio",
        2000
      );

      result = false;
    } else if (
      !this.formFactura.controls["cif"].value.match(cifRegex) &&
      !this.formFactura.controls["cif"].value.match(nifRegex) &&
      !this.formFactura.controls["cif"].value.match(nieRegex)
    ) {
      this._notificationService.warning(
        "Informacion",
        "El cif debe cumplir el formato correcto",
        2000
      );
      result = false;
    }
    if (
      this.formFactura.controls["domicilio"].value === ""
    ) {
      this._notificationService.warning(
        "Informacion",
        "El domicilio es obligatorio, y debe tener número",
        2000
      );
      result = false;
    }
    if (this.formFactura.controls["codigo_postal"].value === "") {
      this._notificationService.warning(
        "Informacion",
        "El código postal es obligatorio",
        2000
      );
      result = false;
    } else if (
      this.formFactura.controls["codigo_postal"].value.match(codPostalRegex)
    ) {
    } else {
      this._notificationService.warning(
        "Informacion",
        "El código postal debe cumplir el formato correcto",
        2000
      );
      result = false;
    }
    if (this.formFactura.controls["localidad"].value === "") {
      this._notificationService.warning(
        "Informacion",
        "La localidad es obligatoria",
        2000
      );
      result = false;
    }
    return result;
  }

  getCorrectDateFormat(date: Date) {
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  async saveCliente(): Promise<any>{
    let cliente;
    this._clienteService.getClienteByEmail({email: this.email, idAutonomo: this.usuarioAutonomo.id }).subscribe({next: (data) =>{
        cliente=data;
        if(cliente==null){
        
          
      this.factura["nombre"] = this.formFactura.controls["nombre"].value;
      this.factura["numeroDocumento"] =
        this.formFactura.controls["cif"].value;
      this.factura["domicilio"] =
        this.formFactura.controls["domicilio"].value;
      this.factura["codigoPostal"] =
        this.formFactura.controls["codigo_postal"].value;
      this.factura["localidad"] =
        this.formFactura.controls["localidad"].value; 

          this.domicilio_numero =
        this.formFactura.controls["domicilio"].value.match(/[a-zA-Z]+|[0-9]+/g);
        if(this.formFactura.controls["domicilio"].value.match(/[a-zA-Z]+\s+[0-9]+/g)){
          let domicilio=this.domicilio_numero[0];
          for(let i = 1; i<this.domicilio_numero.length - 1; i++){
            domicilio+= " " + this.domicilio_numero[i]
    
          }
    
          this.formClient.controls["email"].setValue(this.email);
          this.formClient.controls["nombre"].setValue(
            this.formFactura.controls["nombre"].value
          );
          this.formClient.controls["documento"].setValue(
            this.formFactura.controls["cif"].value
          );
          this.formClient.controls["domicilio"].setValue(domicilio);
          this.formClient.controls["numero_direccion"].setValue(
            this.domicilio_numero[this.domicilio_numero.length - 1]
          );
          this.formClient.controls["codigo_postal"].setValue(
            this.formFactura.controls["codigo_postal"].value
          );
          this.formClient.controls["localidad"].setValue(
            this.formFactura.controls["localidad"].value
          );
          this.formClient.controls["autonomo_id"].setValue(this.usuarioAutonomo.id);
    
          //console.log(this.formClient.value); 
    
          this._clienteService.save(this.formClient.value).subscribe({
            next: (data) => {
              this.factura.idCliente = data["idVisible"]
              let tipo_transaccion = {
                id:2,
                nombre:"INGRESO",
              }
              let ingreso = new GastoIngreso(
                this.usuarioAutonomo.id,
                "",
                tipo_transaccion,
              )
              this._facturaService
              .cumplimentar(this.factura, this.detalles, ingreso)
              .subscribe({
                next: (data) => {
                  //console.log("data", data);
                  this.mostrarPagina = false;
                  this._notificationService.success(
                    "Hecho",
                    "Factura guardada con éxito",
                    2000
                  );
                },
                error: (error) => {
                  //console.log("Numero de documento no correcto");
                  //console.log(error);
                },
              });
    
            },
            error: (err) => {
             // console.log(err);
            },
            
          });
        }
        else{
          this._notificationService.warning(
            "Aviso",
            "Introduce una dirección correcta",
            2000
          );
        }
        }
        else{

          this.factura["nombre"] = cliente.nombre;
          this.factura["numeroDocumento"] = cliente.documento;
          this.factura["domicilio"] = cliente.domicilio + " " + cliente.numero_direccion;
          this.factura["codigoPostal"] = cliente.codigo_postal;
          this.factura["localidad"] = cliente.localidad;
          let tipo_transaccion = {
            id:2,
            nombre:"INGRESO",
          }
          let ingreso = new GastoIngreso(
            this.usuarioAutonomo.id,
            "",
            tipo_transaccion,
          )
          this.factura.idCliente = data["idVisible"];
          this._facturaService
          .cumplimentar(this.factura, this.detalles,ingreso)
          .subscribe({
            next: (data) => {
              //console.log("data", data);
              this.mostrarPagina = false;
              this._notificationService.success(
                "Hecho",
                "Factura guardada con éxito",
                2000
              );
            },
            error: (error) => {
              //console.log("Numero de documento no correcto");
              //console.log(error);
            },
          });
        }
    },});
   
    
  }

  currentUser() {
   // console.log(this.factura.email);
    this._usuarioService.getUserByEmail(this.factura["email"]).subscribe({
      next: (data) => {
        this.usuario = data["Usuario"];
      },
      error: (error) => {
       // console.log(error);
      },
    });
  }

  size() {
    var tamanio = window.screen.width;

    if (tamanio >= 800) {
      return true;
    } else {
      return false;
    }
  }

  openCloseConcepto: number = 0;
  showDetalles(detalle, event) {
    // if(event.target.parentElement.children.length > 1){
    if (
      this.openCloseConcepto != 0 &&
      event.target.parentElement.children.length > 1
    ) {
      event.target.parentElement.removeChild(
        event.target.parentElement.lastElementChild
      );
      this.openCloseConcepto--;
    } else {
      const div = document.createElement("div");
      div.classList.add("col-12");
      div.innerHTML =
        `
      <div class="row">
                
                <div class="col-2"></div>
                <div class="col-6 ">
                <p><b>Concepto: </b></p>
                </div>
                <div class="col-2 d-flex justify-content-end">
                    <p>` +
        detalle.concepto +
        `</p>
                </div>
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-6 ">
                    <p><b>Cantidad: </b></p>
                </div>
                <div class="col-2 d-flex justify-content-end">
                    <p>` +
        detalle.cantidad +
        `</p>
                </div>
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-6 ">
                    <p><b>Precio: </b></p>
                </div>
                <div class="col-2 d-flex justify-content-end">
                    <p>` +
        detalle.precio +
        `</p>
                </div>
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-6 ">
                    <p><b>Descuento: </b></p>
                </div>
                <div class="col-2 d-flex justify-content-end">
                    <p>` +
        detalle.descuento +
        `</p>
                </div>
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-6 ">
                    <p><b>Cantidad neta: </b></p>
                </div>
                <div class="col-2 d-flex justify-content-end">
                    <p>` +
        detalle.cantidadNeta +
        `</p>
                </div>
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-6 ">
                    <p><b>IVA/VAT: </b></p>
                </div>
                <div class="col-2 d-flex justify-content-end">
                    <p>` +
        detalle.tipoIva +
        `</p>
                </div>
                </div>
                `;
      event.target.parentElement.appendChild(div);
     // console.log(event.target.parentElement.children);

      this.openCloseConcepto++;
    }
  }
}
