import { Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { RaizComponent } from 'src/app/raiz/raiz.component';
import { environment } from "src/environments/environment";
import { VisualizarNotificacionesComponent } from '../visualizar-notificaciones/visualizar-notificaciones.component';

@Component({
  selector: 'app-detalles-notificaciones',
  templateUrl: './detalles-notificaciones.component.html',
  styleUrls: ['./detalles-notificaciones.component.css']
})
export class DetallesNotificacionesComponent {
  isSuccessful = false;
  isSignUpFailed = false; 
  errorMessage = '';
  submitted: boolean = false;
  register2 = false;
  InfoRegistro: any = [];
  isLoggedIn = false;
  arrayDocuments = [];
  gastos: any = [];
  usuarios: any[] = [];
  usuario: any = -1;
  fechaActual: string;
mostrado:any = false;
  auxiliar:boolean = false;
    
pdfAuxiliar:string;
  userLogged : any;
  userAdmin: Boolean = false;
  name = 'Angular 5';
  load: boolean;

  uploadDocuments: Boolean = false;
  environment = environment;

  @Input()
  notificacion : any;
  mi_notificacion:any;


  
  constructor(
    private _notificationSvc?: NotificationService,
    private _router?: Router,
    private raiz?: RaizComponent,
    private tokenStorageService?: TokenStorageService,

  ) { }

  adviseError: boolean = false;
  typeError: boolean = false;

  ngOnInit(): void {



  }
  ngOnChanges(changes: SimpleChanges) {
   /*  console.log("this.notificacion")
    console.log(this.notificacion) */

  }
  
   
  formatDate(date){
    if(date!=null){
    let month = "" + date[1];
    let day = "" + date[2];
    const year = date[0];
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return    + date[3]+ ":"+ date[4] +" "+ day+ "-"+ month + "-" +  year}
    else return null;
}





closeSidebar() {
  VisualizarNotificacionesComponent.closeSidebarClienteRemoto();
}

}

