import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '../token-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InformeDgtService {

  
  protected resourceUrlDgt = `${environment.API_URL}/tramite/informeDGT`;
  protected API_URL = environment.API_URL

  constructor(
    protected http: HttpClient,
    protected token:TokenStorageService
  ) { }


  saveInforme(form: any) {
    console.log(form)
    return this.http.post(`${this.resourceUrlDgt}/save`, form);
  }
  updateInforme(form: any,id) {
    return this.http.post(`${this.resourceUrlDgt}/update/${id}`, form);
  }
  getInformeId(id: number) {
    return this.http.get(`${this.resourceUrlDgt}/getById/${id}/${this.token.getId()}`);
  }
}
