import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, catchError, pipe, retry } from "rxjs";
import { environment } from "src/environments/environment";
import { TipoGasto } from "../../model/tipoGasto";
import { ServicioGeneral } from "../servicio-general.service";

@Injectable({
  providedIn: "root",
})
export class GastosService {
 
  API_URL: string = environment.API_URL;
  constructor(
    private http: HttpClient,
    private serviceGeneral: ServicioGeneral
  ) {}

  saveGasto(form: FormGroup) {
    return this.http.post(`${this.API_URL}/gasto/save`, form);
  }

  updateGasto(form: FormGroup) {
    return this.http.post(`${this.API_URL}/gasto/update`, form);
  }
  updateGastoEstado(estado:String, idGasto_Ingreso) {
    const datos = {
      estado: estado, 
      gasto: idGasto_Ingreso
    };

    return this.http.post(`${this.API_URL}/gasto/updateEstado`, datos);
  }
  downloadFile(documento: any): Observable<any> {
    const datos = {
      id_gestor: documento.id_gestor
    }
    return this.http.post(`${this.API_URL}/nextcloud/downloadB64/`, datos);  
  }

  getTipoGasto(): Observable<TipoGasto> {
    return this.http
      .get(`${this.API_URL}/tipo_gasto/list`)
      .pipe(retry(1), catchError(this.serviceGeneral.handleError));
  }

  findAllEstados() {
    return this.http
    .get(`${this.API_URL}/gasto/findAllEstados`)
  }
}
