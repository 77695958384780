import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder, UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/_services/hencok/cliente.service';
import { FacturaService } from 'src/app/_services/hencok/factura.service';
import { GastosService } from 'src/app/_services/hencok/gastos.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { PdfGenerateHencokService } from 'src/app/_services/pdfGenerateHencok/pdf-generate-hencok.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Factura } from 'src/app/model/hencok/factura';
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { IngresosGastosService } from 'src/app/_services/hencok/ingresos-gastos.service';
import { GastoIngreso } from 'src/app/model/hencok/gasto_ingreso';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-formulario-factura-editar',
  templateUrl: './formulario-factura-editar.component.html',
  styleUrls: ['./formulario-factura-editar.component.css']
})
export class FormularioFacturaEditarComponent {
  @Input () FacturaRec: any;
  @Output() newItemEvent = new EventEmitter<Boolean>();

  everChanged: boolean = false;

  formIngreso: UntypedFormGroup = new UntypedFormGroup({
    concepto: new UntypedFormControl("", Validators.required),
    fecha_creacion: new UntypedFormControl("", Validators.required),
    importe: new UntypedFormControl("", Validators.required),
    tipo_gasto: new UntypedFormControl("", Validators.required),
    documento: new UntypedFormControl(""),
    tipo_transaccion: new UntypedFormControl(2),
    gasto_id: new UntypedFormControl(""),
    usuario_id: new UntypedFormControl,
  });

  arrayDocuments = [];
  userLogged: any = -1;
  userFrom: any = -1;
  userAdmin: Boolean = false;
  usuario: any = -1;
  usuarios: any[] = [];

  facturas: any;
  submitFacturas: Boolean = false;
  submitted: Boolean = false;

  uploadDocuments: Boolean = false;

  detallesFinal: [];
  tipoGastos: any = [];
  gasto: any = "";


  editarFormulario: Boolean = false;

  cantidades: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  cantidad: number = 0.00;
  subtotal: number = 0.00;
  iva: number = 0.00;
  cantidad_total: number = 0.00;
  cantidadNeta: number;
  precio: number = 0.00;
  total_deuda: number = 0;
  total_pendiente: number = 0;
  total_facturado: any = 0;
  selectedUser:any;

  gasto_ingreso:any;
  
  constructor(
    private _serviceGeneral: ServicioGeneral,
    private _tokenStorageService: TokenStorageService,
    private _facturaService: FacturaService,
    private _gastosService: GastosService,
    private notificationService: NotificationService,
    private _generatePdf: PdfGenerateHencokService,
    private _clienteService: ClienteService,
    private _ingresos_gastosService: IngresosGastosService,
    private _router: Router,
    private fb: FormBuilder
  ) {
    
  }
  
  ngOnInit(): void {
    this.userLogged = this._tokenStorageService.getUser();
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    } 

    this.getUserList();
    this.getTipoGastosList();

    this.formFactura.controls['fecha_factura'].setValue(this.formatDate(new Date));
    

  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.everChanged){
      this.userFrom= this.FacturaRec.usuario;
    //  console.log(this.FacturaRec.email)
      var userId = (this.usuarios.find(i => i.email === this.FacturaRec.email)).id;
      this.selectedUser = userId;
      if(this.userAdmin){
        this.getUser(userId);
      }
      else{
        this.getCliente(userId);
      }
      

      this.getGasto(this.FacturaRec.tipoGasto.id);


      this.formFactura.controls['numeroFactura'].setValue(this.FacturaRec.numeroFactura);
      this.formFactura.controls['tipoGasto'].setValue(this.FacturaRec.tipoGasto.id);
      this.formFactura.controls['fecha_factura'].setValue(this.formatDate(this.FacturaRec.fechaFactura));
      this.formFactura.controls['fecha_vencimiento'].setValue(this.formatDate(this.FacturaRec.fechaVencimiento));
    //  console.log(this.detallesFactura.length)
      this.detallesFactura.clear();
      for (let i=0; i<this.FacturaRec.detalle.length; i++){
        var detalle = this.FacturaRec.detalle.at(i);
        this.addExistingFactura(detalle)
        this.calcularCantidadNeta(i)
      }

      this.getGastoIngreso(this.FacturaRec.id); 
    }
    else{
      this.everChanged=true;
    }
  }

  formFactura: FormGroup = new FormGroup({
    numeroFactura: new FormControl("", Validators.required),
    tipoGasto: new FormControl("", Validators.required),
    fecha_factura: new FormControl("", Validators.required),
    fecha_vencimiento: new FormControl("", Validators.required),
    detallesFactura: this.fb.array([
    ])
  });

  get detallesFactura() {

    return this.formFactura.controls["detallesFactura"] as FormArray;
  }

  deleteFactura() {
    this.detallesFactura.value.pop();
    for (let i = 0; i < this.formFactura.controls['detallesFactura'].value.length; i++) {
      this.calcularCantidadNeta(i);
    }


    this.detallesFactura.removeAt(this.detallesFactura.length - 1)
  }

  addFactura() {
    const detalleInfo = this.fb.group({
      concepto: ["", [Validators.required]],
      cantidad: ["", [Validators.required]],
      precio: ["", [Validators.required]],
      descuento: [""],
      cantidadNeta: ["", [Validators.required]],
      tipoIva: [""]
    })
    this.detallesFactura.push(detalleInfo);
  }

  addExistingFactura(detalle) {
    var descuento = ""
      if (detalle.descuento == null){
        descuento = "0.00"
      }
      else{
        descuento = String(detalle.descuento)
      }
      const detalleInfo = this.fb.group({
        concepto: [detalle.concepto, [Validators.required]],
        cantidad: [String(detalle.cantidad), [Validators.required]],
        precio: [String(detalle.precio), [Validators.required]],
        descuento: [descuento],
        cantidadNeta: [String(detalle.cantidadNeta), [Validators.required]],
        tipoIva: [String(detalle.tipoIva)]
      })
      this.detallesFactura.push(detalleInfo);
  }

      
  async onSubmitFacturas(): Promise<any> {
    if (this.usuario == -1) {
      this.notificationService.warning("INFORMACIÓN", "Debe seleccionar un usuario", 2000)
      var element = document.getElementById('selectUser');
      element.focus();
      return false;
    }
    if(this.formFactura.controls["fecha_factura"].invalid){
      this.notificationService.warning(
        "Informacion",
        "El formato de la fecha  no es correcto",
        2000
      );
      return false;
     }

      if(this.formFactura.controls["fecha_vencimiento"].invalid){
        this.notificationService.warning(
          "Informacion",
          "El formato de la fecha de vencimiento no es correcto",
          2000
        );
        return false;
      
    }else if(this.formFactura.controls["detallesFactura"].invalid){
      this.notificationService.warning(
        "Informacion",
        "los datos del detalle de la factura no son correctos",
        2000
      );
      return false;
    }
    else if (!this.formFactura.valid) {
    //  console.log("form invalid")
      this.notificationService.warning('Informacion', 'Rellene todos los campos por favor', 2000);
      return false;
    }
    else if(this.formFactura.controls["fecha_factura"].value > this.formFactura.controls["fecha_vencimiento"].value){
      this.notificationService.warning(
        "Informacion",
        "La fecha de vencimiento no puede ser anterior a la fecha de emisión",
        2000
      );
      return false;
    }
    else{
    this.submitFacturas = true;
    this.uploadDocuments = true;

    var gastoFinal = { id : this.gasto };

    var idCliente
    if(this.userAdmin){
      idCliente=this.usuario.id;
    }
    else{
      idCliente=this.usuario.idVisible;
    }

    var domicilio = this.usuario?.direcciones ? this.usuario?.direcciones[0].name_road + " " + this.usuario?.direcciones[0]?.number_road : this.usuario.domicilio + this.usuario.numero_direccion;
    var codPostal = this.usuario?.direcciones ? this.usuario?.direcciones[0].postal_code : this.usuario.codigo_postal;
    var localidad = this.usuario?.direcciones ? this.usuario?.direcciones[0].province.nombre : this.usuario.localidad;
    var documento = this.usuario.numero_documento ? this.usuario.numero_documento : this.usuario.documento;

    var nombre = this.usuario?.apellido1 ? this.usuario.nombre + ' ' + this.usuario.apellido1 + ' ' + this.usuario.apellido2 : this.usuario.nombre;

    var facturaFinal = new Factura(this.formatDate(this.formFactura.controls['fecha_factura'].value), this.formatDate(this.formFactura.controls['fecha_vencimiento'].value), this.iva.toString(), this.subtotal.toString(), this.cantidad_total.toString(), this.formFactura.controls['numeroFactura'].value, idCliente, this.usuario.email, documento, nombre, domicilio, codPostal, localidad, gastoFinal, this.FacturaRec.id);


    this.detallesFinal = this.formFactura.controls['detallesFactura'].value;
    if (this.userAdmin != true) {
      this.formIngreso.controls["importe"].setValue(
        facturaFinal.cantidadTotal
      );
      //console.log(gastoFinal);
      this.formIngreso.controls["tipo_gasto"].setValue(
        gastoFinal.id
      );

      let tipo_transaccion = {
        id:2,
        nombre:"INGRESO",
      }
      let ingreso;
      this._ingresos_gastosService.getByFacturaId(this.FacturaRec.id).subscribe({next: (data) => {

        ingreso = new GastoIngreso(
        data.id,
        "",
        //this.formatDate(this.formFactura.controls["fecha_factura"].value),
        tipo_transaccion,
      )
          
      this._facturaService.updateWithIngreso(facturaFinal, this.detallesFinal, ingreso,false).subscribe({
        next: (data) => {
          this.submitted = true;
          this._router.navigateByUrl("/client-hencok/facturacion").then(() => {
            this._router.navigate(
              ["client-hencok/facturacion/" + this.userLogged.id],
              { skipLocationChange: true }
            );
          });
          this.notificationService.success(
            "Hecho",
            "Factura guardada con éxito",
            2000
          );
          this.uploadDocuments = false;
  
        }, error: (error) => {
          this.uploadDocuments = false;
        //  
  
        }
      })
    }, error:() => {}})
      
    }
    
    else{
      this._facturaService.update(facturaFinal, this.detallesFinal, true).subscribe({
        next: (data) => {
          this.submitted = true;
          this._router.navigateByUrl("/admin-hencok").then(() => {
            this._router.navigate(["admin-hencok/facturacion"]);
          });
          this.notificationService.success(
            "Hecho",
            "Factura guardada con éxito",
            2000
          );
          this.uploadDocuments = false;
  
        }, error: (error) => {
          this.uploadDocuments = false;
        //  
  
        }
      })
    }
    }

  }

  addNewItem() {
    this.newItemEvent.emit(false);
  }

  getGasto(gasto) {
    this.gasto = gasto;
  }

  setEditarFormulario(mode: Boolean) {
    this.editarFormulario = mode;
  }

  getUser(id): void {
    this._serviceGeneral.getUserId(id)
      .subscribe({
        next: (usuario) => {
          this.usuario = usuario['Usuario'];
          
          // this._serviceGeneral.getIbanByUser(id).subscribe({
          //   next: (iban) => {
          //     this.iban = iban.iban
          //   }
          // });
        },
        error: (error) => {
          //
        }
      });
  }

  getCliente(id): void {
    this._serviceGeneral.getClientId(id, this.userLogged.id)
      .subscribe({
        next: (usuario) => {
          this.usuario = usuario;
          // this._serviceGeneral.getIbanByUser(id).subscribe({
          //   next: (iban) => {
          //     this.iban = iban.iban
          //   }
          // });
        },
        error: (error) => {
          //
        }
      });
  }

  public downloadPdf(base64Document, fileName) {
    const source = 'data:application/pdf;base64,' + base64Document;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  calcularCantidadNeta(index: number) {

    var rowFactura = this.formFactura.controls['detallesFactura'].value[index];
    var cantidad = 1;
    var descuento = 0;
    var ivaTotal = 0;

    if (rowFactura.precio != "") {
      if (rowFactura.cantidad != "") {
        cantidad = rowFactura.cantidad;

      }
      if (rowFactura.descuento != "") {
        descuento = rowFactura.descuento;
      }

      this.formFactura.controls['detallesFactura'].value[index].cantidad = parseFloat(this.formFactura.controls['detallesFactura'].value[index].cantidad);
      this.formFactura.controls['detallesFactura'].value[index].cantidadNeta = ((cantidad * rowFactura.precio) - descuento);

      // calculamos el iva y se lo sumamos a cada prodiucto
      if (rowFactura.tipoIva != ""){
        ivaTotal = ((cantidad * rowFactura.precio) - descuento) * (rowFactura.tipoIva / 100);
      }
      //Modificamos subtotal, IVA total y Precio final
      this.calcularTotales(this.formFactura.controls['detallesFactura'].value);


    } else {
      this.formFactura.controls['detallesFactura'].value[index].cantidadNeta = 0;
      this.subtotal = 0;
      this.cantidad_total = 0;

    }

  }


  calcularTotales(facturas) {
    this.subtotal = 0;
    this.iva = 0;
    this.cantidad_total = 0;
    var ivaFactura = 0;


    for (let factura of facturas) {

      if (factura.cantidadNeta == "" ){
        this.subtotal += 0.00;
      }
      else{
        this.subtotal += Number.parseFloat(factura.cantidadNeta);
       }

      
      if (factura.tipoIva == ""){
        this.iva += 0.00;
      }
      else{
       ivaFactura= (factura.cantidadNeta) * (factura.tipoIva / 100)
        this.iva += (ivaFactura);
      }

    }


    this.subtotal = Number.parseFloat(this.subtotal.toFixed(2));
    this.iva = Number.parseFloat(this.iva.toFixed(2));
    this.cantidad_total = Number.parseFloat((this.subtotal + this.iva).toFixed(2));

  }

  generateFactura() {
   // console.log(this.formFactura.value)
    this._generatePdf.generateFacturaCompleta(this.userLogged, this.usuario, this.formFactura.value, this.iva.toString(), this.subtotal.toString(), this.cantidad_total.toString(), 'BORRADOR', 'BORRADOR', this.userAdmin).then(
      (documentDefinition) => {
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.open();
      }
    );
  }

  getUserList() {
    if (this.userAdmin) {
      this._serviceGeneral.getUsersRoleOne().subscribe({
        next: (data) => {
          this.usuarios = data;

        },
        error: (error) => {
         
        }
      })
    } else {
      this._clienteService.getListClientesByIdAutonomo(this.userLogged.id).subscribe({
        next: (data) => {
          this.usuarios = data;
        },
        error: (error) => {
          
        }
      })
    }
  }

  getTipoGastosList() {
    this._gastosService.getTipoGasto().subscribe({
      next: (data) => {

        this.tipoGastos = data;

      },
      error: (error) => {
        
      }
    })
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  formatDateIngreso(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month ,year].join('/');
  }

  getGastoIngreso(idFactura){
    this._ingresos_gastosService.getByFacturaId(idFactura).subscribe({next: (data) => {
      this.gasto_ingreso = data;
    }})
  }
}


