import { Component, HostListener } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { TokenStorageService } from "../_services/token-storage.service";
import { ServicioGeneral } from "../_services/servicio-general.service";
import { UrlService } from "../_services/urlService";
import { NotificationsService } from "angular2-notifications";
import { VisualizarNotificacionesService } from "../_services/hencok/visualizar-notificaciones.service";

export interface DataModel {
  menuListado: string;
}

@Component({
  selector: "app-board-admin-hencok",
  templateUrl: "./board-admin-hencok.component.html",
  styleUrls: ["./board-admin-hencok.component.css"],
})
export class BoardAdminHencokComponent {
  API_URL: string = environment.API_URL;
  //content?: string;
  content: any = [];
  myForm: UntypedFormGroup;
  currentUser: any;
  currentEnv: string;
  totalElements: number = 0;

  showDiv = {
    crear: false,
    actualizar: false,
    eliminar: false,
  };

  Listado: any = [];
  miData: any = [];
  menuListado: any = [];
  menuPerfil: any = [];

  seleccionado: string;
  miid;

  TipoFirma: any = [];
  EstadoElaboracion: any = [];
  EstadoDocumento: any = [];
  Origenes: any = [];
  TipoDocumentales: any = [];
  SubTipoDocumentales: any = [];

  formData: UntypedFormGroup;

  menuOption: String;

  formVisible: boolean;
  formAsuntoVisible;
  formLibroVisible;
  procVisible: boolean;
  autoVisible: boolean;
  textProcedimientos: string;
  textAutoliquidaciones: string;
  hijosList: any = [];
  menuPadre: any = [];
  selectedOption;
  environment = environment;
  isAdmin: boolean;
  isAdminAuto: boolean = false;
  getScreenWidth: any;
  userRol: any;
  previousUrl: string = "";
  menuOut: boolean;

  tienesNotificaciones;
  constructor(
    private servicioGeneral: ServicioGeneral,
    private formBuilder: UntypedFormBuilder,
    private token: TokenStorageService,
    private router: Router,
    public route: ActivatedRoute,
    private urlService: UrlService,
    private _router: Router,
    private notificacionessService: VisualizarNotificacionesService
  ) {
    this.buildForm();
    this.currentEnv = environment.environment;
  }

  public onSubmit(data: DataModel) {}

  public buildForm() {
    this.myForm = this.formBuilder.group({
      opcionesmenu: [null],
    });
  }

  setSelectedOption(valueSelected) {
    //console.log(valueSelected);
    //console.log(valueSelected.rol);
    //console.log(this.currentUser);
    // console.log("currenUser ",this.currentUser ,"  valueSelected ",valueSelected)
    if (this.currentUser.rol.includes(valueSelected.rol.id)) {
      this.selectedOption = valueSelected;
    } else {
      this.selectedOption = { action: -1, nombre: "Home" };
    }
    //console.log(this.selectedOption);
  }
  redirectUrl(menu) {
    window.open("#/" + menu.url);
  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;

    if(this.getScreenWidth>=768){
      this.menuOut=true;
    }
    else{
      this.menuOut=false
    }
    this.currentUser = this.token.getUser();

    this.getMenuLateralListByRol();
    this.checkPrevious();
  
    this.getImgUser();

    this.userRol = this.token.getRol();
    if (this.userRol == 99 || this.userRol == 2) {
      this.isAdmin = true;
      if (this.userRol == 99) {
        this.isAdminAuto = true;
      }
    } else {
      this.isAdmin = false;
      if (this.userRol == 3) {
        this.isAdminAuto = true;
      } else {
        this.isAdminAuto = false;
      }
    }

    if (this.userRol == 99 || this.userRol == 3) {
      this.comrpobarNotificacionesAdmin();}
      else{
        this.comrpobarNotificaciones();
      }

  }

  getImgUser() {
    this.servicioGeneral.getUserId(this.currentUser.id).subscribe({
      next: (data) => {
        //  console.log("usu data ",data)
        this.currentUser.imgPerfil = data.Usuario.imgPerfil;
      },
    });
  }

  comrpobarNotificaciones() {
    this.notificacionessService
      .notificacionesPendientes(this.currentUser.id)
      .subscribe({
        next: (data) => {
          this.tienesNotificaciones = data;
        },
        error: (error) => {},
      });
  }

  comrpobarNotificacionesAdmin() {
    console.log("entro a comprobar notificzciones admin")
    this.notificacionessService
      .notificacionesPendientesAdmin(this.currentUser.id)
      .subscribe({
        next: (data) => {
          console.log(data)
          this.tienesNotificaciones = data;
        },
        error: (error) => {},
      });
  }

  checkPrevious() {
    this.previousUrl = this.urlService.getPreviousUrl();

    if (this.previousUrl == "/") {
      history.pushState(null, "", location.href);
      window.onpopstate = function () {
        history.go(1);
      };
    }
  }

  logout(): void {
    this.token.signOut();
    this.router.navigate(["/login"]);
  }

  getMenuLateralList() {
    this.servicioGeneral.getMenuLateralList().subscribe({
      next: (data) => {
        this.menuListado = data["Menu"];
      },
      error: () => {
        this.token.signOut();
        this.router.navigate(["/login"]);
      },
    });
  }

  getMenuLateralListByRol() {
    this.servicioGeneral.getMenuLateralListByRol().subscribe({
      next: (data) => {
        this.menuListado = data["Menu"];
        console.log('los menus', data["Menu"]);
        
      },
      error: () => {
        this.token.signOut();
        this.router.navigate(["/login"]);
      },
    });
  }

  showMenu(submenu, currentUser) {
    let canShow = false;
    if (submenu.rol != null && currentUser.rol.includes(submenu.rol["id"])) {
      canShow = true;
      if (submenu.action == 30 || submenu.action == 31 || submenu.action == 3) {
        if (currentUser.rol.includes(2) && !currentUser.rol.includes(3)) {
          canShow = false;
        } else {
          canShow = true;
        }
      }
    }
    return canShow;
  }
  navegar(url) {
    if (this.userRol == 99 || this.userRol == 3) {
      this.comrpobarNotificacionesAdmin();}
      else{
        this.comrpobarNotificaciones();
      }
    let rol = this.token.getRol();
    
    if(url.includes('notificacion') && (rol == 1 || rol == 4)){
      url = url + this.currentUser.id
    }

    if(url.includes('perfilCliente') && (rol != 1 && rol != 4) ){
      url = url + this.currentUser.id
    }
    
    if(url.includes('misImpuestos') && rol == 1){
      this._router.navigate(["client-hencok/misImpuestos"]).then(() => {
        this._router.navigate(
          ["/client-hencok/impuestos/", this.currentUser.id],
          { skipLocationChange: true }
        );
      });
    }else{
      this._router.navigate([url])
    }
   /*
    }

    
   
    
   
    if (url == "aviso-legal") {
      this._router.navigate([`${initPath}/aviso-legal`]);
    } 
      }
    }*/
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }

  getFirstLetter(nombre: string) {
    return nombre.charAt(0).toUpperCase();
  }

  getCorporativo() {
    let style;
    switch (environment.environment) {
      case "gmucordoba":
        style = "corporativo-gmu";
        break;
      case "emvs":
        style = "corporativo-emvs";
        break;
      default:
        style = "";
        break;
    }
    return style;
  }
  /*  closeSidebar(e: any) {

   if (e.target.classList.value == "eye-button activated" || e.target.classList.value.includes("btn") || e.target.parentElement.id == "nocerrar") {
    } else {
      var element_gasto = document?.getElementById("sidebar");
      var element = document?.getElementById("sidebar_ingreso");
      var elementV = document?.getElementById("sidebar_detalle");
      var element_gasto2 = document?.getElementById("sidebar_modificar");
      element_gasto2?.classList.remove("active");
      elementV?.classList.remove("active");
      element?.classList.remove("active");
      element_gasto?.classList.remove("active");
    }
    e.stopPropagation();
  } */
}
