import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  API_URL: string = environment.API_URL;
  private resourceUrl: string = `${environment.API_URL}/cliente`;

  constructor(
    private http: HttpClient,
    private tokenservice: TokenStorageService,
  ) { }

  getListClientesByIdAutonomoPaginate(request, id: number){    
    const params = request;

    return this.http
    .get<any>(`${this.resourceUrl}/listByIdUserPaginate/${id}`,{params})
  }

  getListClientesByIdAutonomo(id: number){    
    return this.http
    .get<any>(`${this.resourceUrl}/list/${id}`)
  }

  getClienteById(id: number){    
    return this.http
    .get<any>(`${this.resourceUrl}/get/${id}`)
  }

  save(cliente: UntypedFormGroup){    
    return this.http
    .post<any>(`${this.resourceUrl}/save`,cliente)
  }
  update(id:any,autonomo_id:any, nombre?: string,email?: string, documento?: string,domicilio?: string,numero_direccion?: string,localidad?: string, codigo_postal?: string){    
   // console.log(id+"     adsda    "+autonomo_id)
    return this.http
    .post<any>(`${this.resourceUrl}/updateClient`,{
    'id':id,
    'nombre': nombre,
    'email': email,
    'documento': documento,
    'domicilio':domicilio,
    'numero_direccion':numero_direccion,
    'localidad':localidad,
    'codigo_postal':codigo_postal,
    'autonomo_id':autonomo_id,
  }, {
    headers: {
      "accessToken": this.tokenservice.getToken()
    }
  });
    
  }
  desactivateClient(idClient:any){
    return this.http.post(`${this.resourceUrl}/desactivateClient`,{"id":idClient});
  }

  getClienteByEmail(request){
    const params = request;
    return this.http.get<any>(`${this.resourceUrl}/getClientByEmailAndAutonomo`, {params});
  }

  getClienteFilter(request: any){
    return this.http.post(`${this.resourceUrl}/filter`, request);
  }
  

}