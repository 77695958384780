export class GastoIngreso{
        id?:number;
        importe: string;
        concepto: string;
        fechaCreacion?:string;
        tipo_transaccion:{};
        estado?:string;

        constructor(importe: string, concepto: string, tipo_transaccion:{}, fechaCreacion?:string, id?:number, estado?:string){
                this.importe = importe;
                this.concepto = concepto;
                this.fechaCreacion = fechaCreacion;
                this.tipo_transaccion = tipo_transaccion;
                this.id=id;
                this.estado = estado;
            }
}