<div class="d-flex flex-row justify-content-between align-items-center"
  [ngClass]="getScreenWidth>=768 ? 'full-form-group' : 'pl-3 pr-3' ">
  <h1 class="m-0">Trámites</h1>
  <div class="d-flex flex-row justify-content-between">
    <button *ngIf="getScreenWidth>=768" class="btn btn-primary align-middle mr-2" (click)="downloadExcel()">
      <span class="align-middle">Exportar</span>
    </button>

  </div>
</div>
<div class="row pb-2 mb-4 list-tramites"
  style="padding-left: 63px; padding-right: 63px; padding-top: 48px; position: relative;">
  <button type="button" class="arrow-container left-container" (click)="moveLeft()">
    <a class="left-arrow arrows"> </a>
  </button>

  <div class="d-flex pb-2 pr-0 pl-0" style=" width: 100%;
      height: auto;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      position: relative;
      z-index: 4;" id="card_container">
    <div style=" display: flex; flex-direction: row;">
      <div id="card-container" *ngFor="let tramite of tipoTramites" style="
        width: 100%;
        display: flex;
        padding: 0 12px;
        height: 100%;
      ">
        <div (click)="openTramite(tramite.id)" class="div_card card d-flex" style="
          cursor: pointer;
          width: 100%;
          flex-direction: row;
          align-items: flex-end;
          align-content: center;
          justify-content: flex-start;
          margin-bottom: 30px;
        ">
          <div class="shadow cardTramite" style="display: flex;">
            <div style="margin-top: 23px; margin-bottom:23px; width: 100%; display: flex; flex-direction: column; ">
              <img class="" src="{{ environment.API_URL  }}{{ tramite.icono }}" alt="Icono tramite {{ tramite.nombre }}"
                style="margin: auto; width: 50px; height: 50px;" />
            </div>
            <div style="
              width: 80%;
              display: flex;
              margin: 0 auto;
              align-content: flex-end;
              flex-direction: column;
              justify-content: flex-end;
              flex: 1;
            ">
              <div class="div-header-card" style="text-align: center; height: 90px; margin-bottom: auto;">
                <h6 style="font-size: 17px; font-weight: 650;">{{ tramite.nombre }}</h6>
              </div>
              <div class="card-body"
                style="text-align: center; display: flex; flex-direction: column; justify-content: flex-end; padding: 0.35rem; min-height: max-content;">
                <p *ngIf="tramite.precio" style="color: #275b55; font-size: 17px; font-weight: 650; margin: 0; min-height: max-content;">{{tramite.precio}}€</p>
                <p *ngIf="tramite.precio" style="color: #275b55; text-decoration: underline; font-size: 13px;"
                  (click)="doPopUp(tramite.nombre,tramite.descripcion, tramite.documentos_necesarios);$event.stopPropagation();$event.preventDefault()">
                  Documentación requerida
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="arrow-container right-container" (click)="moveRight()">
    <a class="right-arrow arrows"> </a>
  </button>
</div>

<div>



  <div class="overlay" *ngIf="popup">
    <div class="popup ">
      <h2>{{titulo}}</h2>
      <a class="close" (click)="popup = false">&times;</a>
      <div class="content mb-1">
        {{descripcion}}
      </div>
      <b *ngIf="documentos_necesarios.length!= 0">Documentos necesarios:</b>
      <div class="row ml-0 mt-1 mb-1">

        <div *ngFor="let documento of documentos_necesarios" class="col-md-6">

          <span class="row "> {{documento.nombre}}</span>
          <img class="row" src="{{ documento.icono}}" alt="Icono tramite {{ documento.nombre }}"
            style="margin-top: 5px; width: 180px; height: 110px;" (click)="showImage(documento.icono)" />

        </div>
      </div>
    </div>
  </div>

  <div class="state-overlay" *ngIf="estadosShow">
    <div class="popup ">
      <h2>Estados</h2>
      <a class="close" (click)="estadosShow = false">&times;</a>
      <div class="content mb-1">
        <div *ngFor="let tramite of listaTramites" class="col">
          <div>
            <p><span [style]="
            'padding: 0;width: 18%; color:' +
            tramite.color +
            '!important; font-size: 14px; min-width=145px; font-weight:600' 
            "> {{ tramite.valor }} : &nbsp;</span>{{tramite.descripcion}} </p>
          </div>

        </div>
      </div>
    </div>
  </div>



  <div class="d-flex flex-row justify-content-between align-items-center full-form-group">

  </div>
  <div class="full-form-group">
    <div class="mt-2 pt-4 pb-4 mb-2 d-flex flex-row">
      <h5>Listado de todos los trámites</h5>
      <a class="questionico" title="Leyenda Estado de Trámites" (click)="estadosShow=true"></a>
    </div>
    <div>
      <div>
        <div class="border-bottom " *ngIf="getScreenWidth>=768">
          <div class="tr-padding" style="margin: 0 0; width: 100%;">
            <tr class="w-100 row ordering-table"
              style="margin: 0 0; padding-left: 15px; padding-right: 15px; font-size: 11px;">
              <th [ngClass]="isAdmin||isMod ? 'card-width-id-admin' : 'card-width-id' ">
                <div class="filter-container">
                  <input type="text" class="searchinput" placeholder="Nº TRÁMITE"
                    (keyup)="doFilter($event, 'numeroTramite', 'LIKE', 'STRING')" />
                  <button (click)="changeSorts('numeroTramite', 'numeroTramite')"><a
                      [ngClass]="sortArray['numeroTramite']==='ASC' ? 'asc-sort': sortArray['numeroTramite']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
              </th>
              <th *ngIf="isAdmin||isMod" class="card-width-normal-admin">
                <div class="filter-container">
                  <input type="text" class="searchinput" placeholder="AUTOESCUELA"
                    (keyup)="doFilter($event, 'usuario.nombre', 'LIKE', 'STRING')" />
                  <button (click)="changeSorts('usuario.nombre', 'nombre')"><a
                      [ngClass]="sortArray['nombre']==='ASC' ? 'asc-sort': sortArray['nombre']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
              </th>
              <th *ngIf="isAdmin||isMod" class="card-width-id-admin">
                <div class="filter-container">
                  <input type="text" class="searchinput" placeholder="ID AUTO"
                    (keyup)="doFilter($event, 'usuario.id', 'LIKE', 'STRING')" />
                  <button (click)="changeSorts('usuario.id', 'id')"><a
                      [ngClass]="sortArray['id']==='ASC' ? 'asc-sort': sortArray['id']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
              </th>
              <th [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <input type="text" class="searchinput" placeholder="CLIENTE" (keyup)="
                  doFilter(
                    $event,
                    ['comprador.concated', 'solicitanteDistintivo.concated', 'solicitanteInforme.concated', 'solicitanteFichaTecnica.concated', 'solicitante.concated', 'titularVehiculo.concated'],
                    'LIKE_OR',
                    'STRING'
                  )
                " />
              <th [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <div class="filter-container">
                  <input type="text" class="searchinput" placeholder="TIPO TRÁMITE" (keyup)="
                  doFilter($event, 'tipoTramite.nombre', 'LIKE', 'STRING')
                " />
                  <button (click)="changeSorts('tipoTramite.nombre', 'tipoTramite')"><a
                      [ngClass]="sortArray['tipoTramite']==='ASC' ? 'asc-sort': sortArray['tipoTramite']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
                <!--
                **MULTISELECT**

                <angular2-multiselect
                [data]="tipoTramites"
                [(ngModel)]="selectedItems"
                [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="OnItemDeSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)"
              >
              </angular2-multiselect>-->
              </th>
              <th [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <input type="text" class="searchinput" placeholder="TÍTULO" (keyup)="
                  doFilter(
                    $event,
                    ['vehiculo.matricula', 'tipoTramite.nombre'],
                    'LIKE_OR',
                    'STRING'
                  )
                " />
              </th>
              <th [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <div class="filter-container">
                  <input type="text" class="searchinput" placeholder="ESTADO"
                    (keyup)="doFilter($event, 'estado.valor', 'LIKE', 'STRING')" />
                  <button (click)="changeSorts('estado.valor', 'estado')"><a
                      [ngClass]="sortArray['estado']==='ASC' ? 'asc-sort': sortArray['estado']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
              </th>
              <th [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <div class="filter-container">
                  <input type="text" class="searchinput" onfocus="(this.type='date')"
                    onblur="if(this.value==''){this.type='text'}" placeholder="FECHA CREACIÓN" (change)="
                  doFilter($event, 'fechaCreacion', 'CURRENT_DATE', 'DATE')
                " />
                  <button (click)="changeSorts('fechaCreacion', 'fechaCreacion')"><a
                      [ngClass]="sortArray['fechaCreacion']==='ASC' ? 'asc-sort': sortArray['fechaCreacion']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
              </th>
              <th [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <div class="filter-container">
                  <input type="text" class="searchinput" onfocus="(this.type='date')"
                    onblur="if(this.value==''){this.type='text'}" placeholder="FECHA RESPUESTA" (change)="
                  doFilter($event, 'fechaRespuesta', 'CURRENT_DATE', 'DATE')
                " />
                  <button (click)="changeSorts('fechaRespuesta', 'fechaRespuesta')"><a
                      [ngClass]="sortArray['fechaRespuesta']==='ASC' ? 'asc-sort': sortArray['fechaRespuesta']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
              </th>
              <th [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-id' ">
                <div class="filter-container">
                  <input type="text" class="searchinput" placeholder="TOTAL"
                    (keyup)="doFilter($event, ['presupuesto.total'], 'LIKE_OR', 'STRING')" />
                  <button (click)="changeSorts('precio', 'precio')"><a
                      [ngClass]="sortArray['precio']==='ASC' ? 'asc-sort': sortArray['precio']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                </div>
              </th>
              <div class="card-with-actions">
                <div class="buttons">
                  ACCIONES
                </div>
              </div>
            </tr>
          </div>
        </div>
        <div class="card w-100 card-generic border-0" *ngIf="tramites.length > 0">
          <ul class="list-group list-group-flush">
            <li class="list-group-item p-3 d-flex border rounded shadow" *ngFor="let tramite of tramites">
              <div class="card border-0 colorOption" [ngClass]="isAdmin||isMod ? 'card-width-id-admin' : 'card-width-id' ">
                #{{ tramite.numeroTramite }}
              </div>
              <div *ngIf="(isAdmin||isMod)&&getScreenWidth>=768" class="card border-0 card-width-normal-admin">
                <p class="cut-text" [title]="tramite.usuario.nombre?tramite.usuario.nombre:''">
                  {{
                  tramite.usuario.nombre
                  }}
                </p>
              </div>
              <div *ngIf="(isAdmin||isMod)&&getScreenWidth>=768" class="card border-0 card-width-id-admin">
                <p class="cut-text colorOption">
                  #{{
                  tramite.usuario.id
                  }}
                </p>
              </div>
              <div *ngIf="getScreenWidth>=768" class="card border-0"
                [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <p class="cut-text"
                  title="{{checkPresence(tramite, 'comprador')===true ? tramite.comprador.nombre + ' ' + tramite.comprador.apellido1  + ' ' + tramite.comprador.apellido2: 
            checkPresence(tramite, 'vendedor')===true ? tramite.vendedor.nombre + ' ' + tramite.vendedor.apellido1 + ' ' + tramite.vendedor.apellido2: 
            checkPresence(tramite, 'solicitanteDistintivo')===true ? tramite.solicitanteDistintivo.nombre + ' ' + tramite.solicitanteDistintivo.apellido1 + ' ' + tramite.solicitanteDistintivo.apellido2: 
            checkPresence(tramite, 'solicitanteInforme')===true ? tramite.solicitanteInforme.nombre + ' ' + tramite.solicitanteInforme.apellido1 + ' ' + tramite.solicitanteInforme.apellido2: 
            checkPresence(tramite, 'solicitanteFichaTecnica')===true ? tramite.solicitanteFichaTecnica.nombre + ' ' + tramite.solicitanteFichaTecnica.apellido1 + ' ' + tramite.solicitanteFichaTecnica.apellido2: 
            checkPresence(tramite, 'solicitante')===true ? tramite.solicitante.nombre + ' ' + tramite.solicitante.apellido1 + ' ' + tramite.solicitante.apellido2: 
            checkPresence(tramite, 'titularVehiculo')===true ? tramite.titularVehiculo.nombre + ' ' + tramite.titularVehiculo.apellido1 + ' ' + tramite.titularVehiculo.apellido2: '-'}}">
                  {{checkPresence(tramite, 'comprador')===true ? tramite.comprador.nombre + ' ' +
                  tramite.comprador.apellido1 + ' ' + tramite.comprador.apellido2:
                  checkPresence(tramite, 'vendedor')===true ? tramite.vendedor.nombre + ' ' + tramite.vendedor.apellido1
                  + ' ' + tramite.vendedor.apellido2:
                  checkPresence(tramite, 'solicitanteDistintivo')===true ? tramite.solicitanteDistintivo.nombre + ' ' +
                  tramite.solicitanteDistintivo.apellido1 + ' ' + tramite.solicitanteDistintivo.apellido2:
                  checkPresence(tramite, 'solicitanteInforme')===true ? tramite.solicitanteInforme.nombre + ' ' +
                  tramite.solicitanteInforme.apellido1 + ' ' + tramite.solicitanteInforme.apellido2:
                  checkPresence(tramite, 'solicitanteFichaTecnica')===true ? tramite.solicitanteFichaTecnica.nombre + '
                  ' + tramite.solicitanteFichaTecnica.apellido1 + ' ' + tramite.solicitanteFichaTecnica.apellido2:
                  checkPresence(tramite, 'solicitante')===true ? tramite.solicitante.nombre + ' ' +
                  tramite.solicitante.apellido1 + ' ' + tramite.solicitante.apellido2:
                  checkPresence(tramite, 'titularVehiculo')===true ? tramite.titularVehiculo.nombre + ' ' +
                  tramite.titularVehiculo.apellido1 + ' ' + tramite.titularVehiculo.apellido2: '-'}}
                </p>
              </div>
              <div class="card border-0" [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <p class="cut-text" [title]="tramite.tipoTramite.nombre?tramite.tipoTramite.nombre:''">
                  {{ tramite.tipoTramite.nombre }}
                </p>
              </div>
              <div *ngIf="getScreenWidth>=768" class="card border-0"
                [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                <p class="cut-text"
                  [title]="tramite.vehiculo?tramite.tipoTramite.nombre + ' : ' + tramite.vehiculo.matricula:tramite.tipoTramite.nombre">
                  {{
                  tramite.vehiculo != null && tramite.tipoTramite.nombre != 'Matriculación'
                  ? tramite.tipoTramite.nombre +
                  " : " +
                  tramite.vehiculo.matricula
                  : tramite.tipoTramite.nombre
                  }}
                </p>
              </div>
              <div *ngIf="getScreenWidth>=768" class="card border-0"
                [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' " [style]="
              'color:' +
              tramite.estado.color +
              '!important;' 
            ">
                <p class="status m-0" title="{{tramite.estado.descripcion}}"> {{ tramite.estado.valor }}</p>
              </div>
              <div *ngIf="getScreenWidth>=768" class="card border-0"
                [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                {{
                tramite.fechaCreacion[2] +
                "/" +
                tramite.fechaCreacion[1] +
                "/" +
                tramite.fechaCreacion[0]
                }}
              </div>
              <div *ngIf="getScreenWidth>=768" class="card border-0"
                [ngClass]="isAdmin||isMod ? 'card-width-normal-admin' : 'card-width-normal' ">
                {{
                tramite.fechaRespuesta ? tramite.fechaRespuesta : "--/--/----"
                }}
              </div>
              <div class="card border-0" [ngClass]="isAdmin||isMod ? 'card-width-estimate-admin' : 'card-width-id' "
                *ngIf="getScreenWidth>=768">
                {{
                tramite.presupuesto != null && tramite.presupuesto.total ? tramite.presupuesto.total.toFixed(2) + "€" :
                tramite.vehiculo != null && tramite.vehiculo.precio ? tramite.vehiculo.precio.toFixed(2) + "€" : "-"
                }}
              </div>
              <div *ngIf="getScreenWidth>=768" class=" card-with-actions ">
                <div class="button-center">
                  <div class="buttons">
                    <a (click)="show(tramite.id,tramite.tipoTramite.id)" class="eye-button activated action-button"
                      title="Ver"></a>
                    <a *ngIf="isMod" class="edit-button activated pencil button-off button-ed"></a>
                    <a *ngIf="!isMod &&  !(tramite.estado.valor=='ACEPTADO' ||tramite.estado.valor=='CANCELADO' ||tramite.estado.valor=='TRAMITACION')"
                      (click)="update(tramite.id,tramite.tipoTramite.id)" class="edit-button activated pencil button-ed"
                      title="Editar"></a>
                    <a *ngIf="!isMod &&   (tramite.estado.valor=='ACEPTADO' ||tramite.estado.valor=='CANCELADO' ||tramite.estado.valor=='TRAMITACION')"
                      (click)="update(tramite.id,tramite.tipoTramite.id)"
                      class="edit-button activated pencil button-off button-ed"></a>
                    <a (click)="download(tramite.id,tramite.tipoTramite.id,tramite.numeroTramite)"
                      class="download-button activated action-button" title="Descargar"></a>
                    <a class="delete-button activated button-off"></a>

                  </div>
                </div>

              </div>
              <div *ngIf="getScreenWidth<=768" class="button-array">
                <a (click)="show(tramite.id,tramite.tipoTramite.id)" class="eye-button activated action-button"
                  title="Ver"></a>
              </div>

            </li>
          </ul>
        </div>
        <div *ngIf="tramites.length == 0" class="d-flex flex-row justify-content-center empty-data-message my-4">
          <span>No se han encontrado registros que mostrar</span>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements"
          (page)="nextPage($event)">
        </mat-paginator>
      </div>
    </div>
  </div>