import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServicioGeneral } from '../servicio-general.service';
import { HttpClient } from "@angular/common/http";
import { catchError, retry } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisualizarNotificacionesService {
 
  API_URL: string = environment.API_URL;

  constructor( private http: HttpClient, ) {}


  
  findByIdUsuario(request){
    return this.http.post(this.API_URL +"/notificaciones/findbyIdUsuario/", request
    );
  
  }

  
  findByidAdmin(request){
/*     console.log("fecha aceptacion")
 */    return this.http.post(this.API_URL +"/notificaciones/findbyIdAdmin/", request
    );
  }

  save(request){
       return this.http.post(this.API_URL +"/notificaciones/save", request
        );
      }


  setfechaAceptacion(id){
    return this.http.get(this.API_URL +"/notificaciones/setfechaAceptacion/"+id);
  }


  notificacionesPendientes(id){
    return this.http.get(this.API_URL +"/notificaciones/notificacionesPendientes/"+id);
  }


  setfechaAceptacionAdmin(id){
    return this.http.get(this.API_URL +"/notificaciones/setfechaAceptacionAdmin/"+id);
  }


  notificacionesPendientesAdmin(id){
    return this.http.get(this.API_URL +"/notificaciones/notificacionesPendientesAdmin/"+id);
  }

}
