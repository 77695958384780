
export class VehiculoTransderencia {
    id?: Number;
    tipoVehiculo: TipoVehiculo;
    fechaMatriculacion: String;
    historico: boolean;
    ativEconomicas: boolean;
    vehiculoMaestro: VehiculoMaestro;
    matricula: String;
    bastidor: String;
    km: Number;
    precio: Number;
    hayFactura: boolean;   
    herencia: boolean;
    embargo: boolean;
}
export class TipoVehiculo{
    id?:Number;
	nombre:String;
}
export class VehiculoMaestro{
    id?:Number;
    marca:String;
    modelo:String;
    caballosVapor:Number;
    potenciaFiscal :Number;
    cilindrada:Number;
    valorEuros:Number;
    carburante:TipoCarburante;
}
export class TipoCarburante{
    id:Number;
    tipo:String;
    diminutivo:String;
}