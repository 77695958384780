<nav id="sidebar_detalle">
    <div class="fixed">
        <app-visualizar-impuesto [detallesP]="impuesto" [detalles]="detalles"
            ></app-visualizar-impuesto>
    </div>
</nav>

<div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="m-0">Impuestos</h1>
    <div class="button-header-row">
        <button (click)="changeTipoImpuesto($event)" class="btn btn-tertiary align-middle mr-3" [ngClass]="tipoImpuesto == 'IRPF' ? 'btn-tertiary-selected' : 'btn-tertiary'">
            IRPF
        </button>
        <button (click)="changeTipoImpuesto($event)" class="btn btn-tertiary align-middle mr-3" [ngClass]="tipoImpuesto == 'IVA' ? 'btn-tertiary-selected' : 'btn-tertiary'">
            IVA
        </button>
    </div>
</div>

<div (click)="closeSidebar($event)">

    <div>
        <hr class="linea-divisoria">
    </div>

    <h2>{{tipoImpuesto}}</h2>
    <!--<div class="button-header-row mb-5 mt-4">
        <button (click)="listByPeriodo($event)" value="anual" class="btn btn-tertiary align-middle mr-3" [ngClass]="periodoImpuesto == 'anual' ? 'btn-tertiary-selected' : 'btn-tertiary'">
            Anual
        </button>
        <button (click)="listByPeriodo($event)" value="trimestral" class="btn btn-tertiary align-middle mr-3" [ngClass]="periodoImpuesto == 'trimestral' ? 'btn-tertiary-selected' : 'btn-tertiary'">
            Trimestral
        </button>
    </div>-->

    <div class="select-filter-row my-4 d-flex flex-row">
        <div>
            <select class="form-select form-select-sm" aria-label="Default select example" (change)="listByPeriodo($event)">
                <option value="anual" [selected]="periodoImpuesto == 'anual'">Anual</option>
                <option value="trimestral">Trimestral</option>
            </select>
        </div>

        <div>
            <select class="form-select form-select-sm" aria-label="Default select example" (change)="listByAnnio($event)">
                <option *ngFor="let annio of listadoAnnios">{{annio}}</option>
            </select>
        </div>          
    </div>

    <ng-container *ngIf="periodoImpuesto == 'anual'">
        <div class="table-container w-100">
            <table class="w-100">
                <tr class="w-100 border-bottom px-3 pb-1 mb-3 text-muted table-header">
                    <th class="filter-container"><input
                        type="text"
                        class="searchinput"
                        placeholder="MODELO"
                        (keyup)="doFilter($event, 'concepto', 'LIKE', 'STRING')"
                      />
                      <button (click)="changeSorts('concepto', 'concepto')"><a [ngClass]="sortArray['concepto']==='ASC' ? 'asc-sort': sortArray['concepto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </th>
                    <th class="filter-container"><input
                        type="text"
                        class="searchinput"
                        onfocus="(this.type='date')"
                        onblur="if(this.value==''){this.type='text'}"
                        placeholder="PERIODO DE SOLICITUD"
                        (change)="
                        doFilter($event, 'periodoImpuesto', 'CURRENT_DATE', 'DATE')
                        "
                    />
                    <button (click)="changeSorts('periodoImpuesto', 'periodoImpuesto')"><a [ngClass]="sortArray['periodoImpuesto']==='ASC' ? 'asc-sort': sortArray['periodoImpuesto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>  
                    </th>
                    <th class="filter-container"><input
                        type="text"
                        class="searchinput"
                        placeholder="ESTADO"
                        (keyup)="doFilter($event, 'estado.nombre', 'LIKE', 'STRING')"
                        />
                        <button (click)="changeSorts('estado.nombre', 'estado')"><a [ngClass]="sortArray['estado']==='ASC' ? 'asc-sort': sortArray['estado']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </th>
                    <th class="card-with-actions col-2 pr-0">
                        <div class="buttons">
                            ACCIONES
                        </div>
                    </th>
                </tr>
                <tr *ngFor="let impuesto of listadoImpuestos" class="list-group-item px-3 py-2 rounded shadow-sm">
                    <td class="d-flex flex-row align-items-center file-column">
                        <img src="/assets/hencok/file_icon.svg" alt="icono de archivo" class="icono-archivo mr-2">
                        <span (click)="openPdf(impuesto)" role="button">{{impuesto.concepto}}</span>
                    </td>
                    <td>{{impuesto.periodoImpuesto | replace: '-' : '/'}} - {{impuesto.fechaMaxima | replace: '-' : '/'}}</td>
                    <td [ngClass]="{'pending-state': impuesto.estado.nombre.toLowerCase() == 'pendiente',
                        'approved-state': impuesto.estado.nombre.toLowerCase() == 'aprobado',
                        'expired-state': impuesto.estado.nombre.toLowerCase() == 'expirado'}" class="d-flex flex-row align-items-center status">
                        <span class="dot-prefix mr-1">&#x2022;</span> {{impuesto.estado.nombre}}
                    </td>
                    <td>
                        <div class="buttons-flex-container">
                            <a class="eye-button activated" title="Ver"   (click)="openSidebarVisualizar(impuesto,$event)"></a>
                            <a class="download-button activated" title="Descargar" (click)="downloadPdf(impuesto)"></a>
                        </div>
                    </td>
                </tr>
                <div class="d-flex flex-row justify-content-between px-3 py-2 mb-3 importe-container" *ngIf="listadoImpuestos?.length > 0">
                    <span>Importe</span>
                    <span>{{totalImporteAnual}}</span>
                </div>
                <div *ngIf="listadoImpuestos?.length == 0" class="d-flex flex-row justify-content-center empty-data-message my-4">
                    <span>No se han encontrado registros que mostrar</span>
                </div>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements" *ngIf="listadoImpuestos?.length > 0"
                (page)="nextPage($event)"> </mat-paginator>
    </ng-container>

    <!-- Si se selecciona el modo 'trimestral' el formato de las tablas cambia -->
    <ng-container *ngIf="periodoImpuesto == 'trimestral'">
        <ng-container *ngFor="let trimestre of listadoTrimestres; let i = index">
            <div class="table-container w-100 mb-5">
                <table class="w-100">
                    <tr class="w-100 border-bottom px-3 pb-1 mb-3 text-muted table-header">
                        <th>Modelo - {{trimestre.toUpperCase()}}</th>
                        <th>Periodo de solicitud</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                    <tr *ngFor="let impuesto of listadoImpuestosTrimestre[i]" class="list-group-item px-3 py-2 rounded shadow-sm">
                        <td class="d-flex flex-row align-items-center file-column">
                            <img src="/assets/hencok/file_icon.svg" alt="icono de archivo" class="icono-archivo mr-2">
                            <span (click)="openPdf(impuesto)" role="button">{{impuesto.concepto}}</span>
                        </td>
                        <td>{{impuesto.periodoImpuesto | replace: '-' : '/'}} - {{impuesto.fechaMaxima | replace: '-' : '/'}}</td>
                        <td [ngClass]="{'pending-state': impuesto.estado.nombre.toLowerCase() == 'pendiente',
                            'approved-state': impuesto.estado.nombre.toLowerCase() == 'aprobado',
                            'expired-state': impuesto.estado.nombre.toLowerCase() == 'expirado'}" class="d-flex flex-row align-items-center">
                            {{impuesto.estado.nombre}}
                        </td>
                        <td>
                            <div class="buttons-flex-container">
                                <a class="eye-button activated" title="Ver" (click)="openSidebarVisualizar(impuesto,$event)"></a>
                                <a class="download-button activated" title="Descargar" (click)="downloadPdf(impuesto)"></a>
                            </div>
                        </td>
                    </tr>
                    <div class="d-flex flex-row justify-content-between px-3 py-2 importe-container" *ngIf="listadoImpuestosTrimestre[i]?.length > 0">
                        <span>Importe</span>
                        <span *ngIf="i==0">{{totalImportePrimTrim}}</span>
                        <span *ngIf="i==1">{{totalImporteSegTrim}}</span>
                        <span *ngIf="i==2">{{totalImporteTerTrim}}</span>
                        <span *ngIf="i==3">{{totalImporteCuaTrim}}</span>
                    </div>

                    <div *ngIf="listadoImpuestos?.length == 0 || listadoImpuestosTrimestre[i]?.length == 0" class="d-flex flex-row justify-content-center empty-data-message my-4">
                        <span>No se han encontrado registros que mostrar</span>
                    </div>
                </table>
            </div>
        </ng-container>
    </ng-container>
    
</div>
