import { Component } from '@angular/core';
import { PoliticaPrivacidadService } from '../_services/hencok/politica-privacidad.service';

@Component({
  selector: 'app-politica-privacidad',
  templateUrl: './politica-privacidad.component.html',
  styleUrls: ['./politica-privacidad.component.css']
})


export class PoliticaPrivacidadComponent {
  politica_privacidad_list: any;
  email: string = "";
  emailLink: string = "";
  firstTime = true;
  constructor(private _politicaPrivacidadService: PoliticaPrivacidadService){
    this.getList();
  }

  getList(){
    this._politicaPrivacidadService.getPoliticas().subscribe((data) => {
      this.politica_privacidad_list = data.politicaPrivacidad;
      this.getEmail("email_ejercicios_de_derecho");
      this.firstTime=false;
    })
    
  }

  getText(identifier){
    var content
    if(this.firstTime==true){
    }
    else{
      var content
      for(var item of this.politica_privacidad_list){
        if(item.identifier == identifier){
          content = item.text;
        }
      }
      return content;
    } 
  }

  getEmail(identifier){
    for(var item of this.politica_privacidad_list){
      if(item.identifier == identifier){
        this.email = item.text;
        this.emailLink = "mailto:",this.email;
      }
    }
  }

}
