import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'] 
})
export class InicioComponent implements OnInit {

  currentEnv: string;
  constructor(
  ) {
    this.currentEnv = environment.environment;
  }

  ngOnInit(): void {
   
  }

}
