import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class InfoService {
    API_URL: string = environment.API_URL;
  constructor(
    private http: HttpClient,
  ) { }

  getInfoById(id): any {
    return this.http.get(`${this.API_URL}/informacion/get/${id}`,{});
  }

  getInfRegistro(): any {
    return this.http.get(`${this.API_URL}/informacion/listInfoRegistroWeb`,{});
  }

}