<div class="pageTitle">
    <h1 class="">Editar cliente</h1>
</div>


<div class="col-sm-5 d-flex justify-content-around mb-5 mt-4">
    
    <div class="d-flex align-items-center">
        <div *ngIf="!usuario?.imgPerfil" class="iconName rounded-circle" style="width: 100px; height: 100px;">
            <p> {{getFirstLetter(usuario?.nombre)}}{{getFirstLetter(usuario?.apellido1)}}</p>
        </div>
        <!-- <div class="image-holder">
            <img *ngIf="usuario?.imgPerfil" src="data:image;base64,{{usuario.imgPerfil}}" width="100%"
            height="100%" class="img-circle" alt="">
        </div> -->
        <img *ngIf="usuario?.imgPerfil" src="data:image;base64,{{usuario.imgPerfil}}" width="100%"
            height="100%" class="rounded-circle" style="width: 100px; height: 100px;" alt="">
        <div class="edit-img">
            <a class="edit-button activated pencil"></a>
            <input id="file" type="file" name="file" accept="image/*"
                class="pruebaInput" (change)="resizeAndSaveImage($event)"/>
        </div>
        
        
    </div>
    <div>
        
            <p id="id"><b>#ID{{usuario?.id}}</b></p>
        
        <p id="info"><b>{{ usuario?.tipopersona.id==1 ? (usuario?.nombre + ' ' + usuario?.apellido1) : usuario?.nombre}}</b></p>
        <p id="info">{{usuario?.email}}</p>
    </div>
</div>
<form [formGroup]=" form "(ngSubmit)="submit() " *ngIf="usuario"
    class="full-form-group">

    <h3 class="clientSubtitle">Datos Personales</h3>
    <div class="detailViewer row">
        <div class="col-sm-3">
            <label class="crear-editar-form">{{usuario?.tipopersona.id==2 && isAdmin ? 'Razón Social' : isAdmin ? 'Nombre' : 'Nombre y apellidos'}}</label>
            <input class="form-control outlinenonedisable" type="text" id="nombre" formControlName="nombre"
                [ngClass]="{'is-invalid': submitted && this.form.controls['nombre'].errors}">
        </div>
        <div class="col-sm-3" *ngIf="usuario?.tipopersona.id==1">
            <label class="crear-editar-form">Primer apellido</label>
            <input class="form-control outlinenonedisable" type="text" id="nombre" formControlName="apellido1"
                [ngClass]="{'is-invalid': submitted && this.form.controls['apellido1'].errors}">
        </div>
        <div class="col-sm-3">
            <label class="crear-editar-form">{{usuario?.tipo_documento?.nombre}}</label>
            <input class="form-control outlinenone" type="text" id="numero_documento" formControlName="numero_documento"
                [ngClass]="{'is-invalid': submitted && this.form.controls['numero_documento'].errors}">
        </div>
        <div class="col-sm-3">
            <label class="crear-editar-form">Email</label>
            <input class="form-control outlinenone" type="text" id="email" formControlName="email"
                [ngClass]="{'is-invalid': submitted && this.form.controls['email'].errors}">
        </div>
        <div class="col-sm-3" *ngIf="usuario?.tipopersona.id==2">
            <label class="crear-editar-form">Teléfono</label>
            <input class="form-control outlinenone" type="text" id="telefono" formControlName="telefono"
                [ngClass]="{'is-invalid': submitted && this.form.controls['telefono'].errors}">
        </div>
    </div>

    <div class="row detailViewer">
        <div class="col-sm-3" *ngIf="usuario?.tipopersona.id==1">
            <label class="crear-editar-form">Teléfono</label>
            <input class="form-control outlinenone" type="text" id="telefono" formControlName="telefono"
                [ngClass]="{'is-invalid': submitted && this.form.controls['telefono'].errors}">
        </div>
        
    </div>

    <h3 class="clientSubtitle">Dirección</h3>
    <div class=" row">
        <div class="col-sm-3">
            <label class="crear-editar-form">Calle (dirección)</label>
            <input class="form-control outlinenone" type="text" id="name_road"
                [formControlName]="'name_road'"
                [ngClass]="{'is-invalid': submitted && this.form.controls['name_road'].errors}">
        </div>
        <div class="col-sm-3">
            <label class="crear-editar-form">Número (dirección)</label>
            <input class="form-control outlinenone" type="text" id="number_road"
                [formControlName]="'number_road'"
                [ngClass]="{'is-invalid': submitted && this.form.controls['number_road'].errors}">
        </div>
        <div class="col-sm-3">
            <label class="crear-editar-form">Provincia</label>
            <select id="provincia" class="form-control form-select outlinenone" formControlName="provincia"
                [ngClass]="{'is-invalid': submitted && this.form.controls['provincia'].errors}">
                <option [value]=0>Selecciona una provincia</option>
                <option [value]="provincia.id" *ngFor="let provincia of listaProvincias">{{provincia.nombre}}
                </option>
            </select>
        </div>
        <div class="col-sm-3">
            <label class="crear-editar-form">Código postal</label>
            <input class="form-control outlinenone" type="text" id="codigo_postal" formControlName="codigo_postal"
                [ngClass]="{'is-invalid': submitted && this.form.controls['codigo_postal'].errors}">
        </div>
    </div>

    <h3 class="clientSubtitle">Información bancaria</h3>
    <div class="row">
        <div class="col-sm-3">
            <label class="crear-editar-form">Titular</label>
            <input class="form-control outlinenone" type="text" id="titular" formControlName="titular"
                [ngClass]="{'is-invalid': submitted && this.form.controls['titular'].errors}">
        </div>
        <div class="col-sm-3">
            <label class="crear-editar-form">IBAN</label>
            <input class="form-control outlinenone" type="text" id="iban" formControlName="iban"
                [ngClass]="{'is-invalid': submitted && this.form.controls['iban'].errors}">
        </div>
        <div class="col-sm-3">
            <label class="crear-editar-form">Entidad</label>
            <input class="form-control outlinenone" type="text" id="entidad" formControlName="entidad">
        </div>

    </div>
    <div class="rowForm row" *ngIf="isAdmin">
        <div class="col-sm-3 ml-4">
            <input class="form-check-input" type="checkbox" formControlName="estado" ng-checked="usuario.active">
            <label class="form-check-label ml-2" for="ultimaTarifaAplicada"><b>Activo</b></label>
        </div>
        <div class="col-sm-12 p-0 d-flex justify-content-end" *ngIf="usuario.id != userLogged.id">
            <button class="btn btn-primary" type="button" (click)="uploader.click()">Subir Contrato</button>
            <input id="file" value="file" name="file" class="pruebaInput" (change)="uploadSignedContract($event)" onclick="event.target.value=null" type="file" #uploader hidden/>
        </div>
    </div>

    <div class="rowForm" *ngIf="isAdmin">
        <label for="exampleFormControlTextarea1"><b>Observaciones</b></label>
        <textarea class="form-control outlinenone mt-3" id="exampleFormControlTextarea1" formControlName="observaciones" 
            rows="6" style="resize: none;"></textarea>

    </div>
    <div *ngIf="!passwordChange && !usuario.new" class="rowForm d-flex justify-content-end">
        <button class="btn btn-primary" type="button" (click)="passwordChange = !passwordChange">Cambiar Contraseña</button>
    </div>
    <div *ngIf="!passwordChange" [ngClass]="usuario.new ? 'justify-content-between' : 'justify-content-end' " class="d-flex align-items-center detailViewer">
        <label class="send-pass" *ngIf="usuario.new" (click)="dialogReset.showModal()"><b>Restablecer acceso</b></label>
        <button *ngIf="!passwordChange" class="btn btn-primary" type="submit">Actualizar</button>
    </div>
</form>
<form [formGroup]=" formPass " *ngIf="passwordChange && (userRol==5 || userRol==6 || userRol==99)" class="full-form-group">
    <div class="row detailViewer">
        <div class="col-4">
            <label class="crear-editar-form">Contraseña</label>
            <div class="password-div">
                <input type="{{showPassword ? 'text' : 'password' }}" class="form-control outlinenone password-form-control" name="password"
                formControlName="password" minlength="6"
                [ngClass]="{'is-invalid': submitted&& this.formPass.controls['password'].errors, 'is-valid': submitted&& this.formPass.controls['password'].errors == null}" />
                <mat-icon matSuffix (click)="showPassword = !showPassword" class="eye-b">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                <span style="font-size: 12px; font-weight: 400;" *ngIf="this.formPass.controls['password'].errors">*Mínimo 6 caracteres</span>
            </div>
        </div>
        <div class="col-4">
            <label class="crear-editar-form">Confirmar contraseña</label>
            <div class="password-div">
                <input type="{{showConfirmPassword ? 'text' : 'password' }}" class="form-control outlinenone password-form-control" name="password_confirmacion"
                formControlName="password_confirmacion" minlength="6"
                [ngClass]="{'is-invalid': submitted&& this.formPass.controls['password_confirmacion'].errors, 'is-valid': submitted&& this.formPass.controls['password_confirmacion'].errors == null}" />
                <mat-icon matSuffix (click)="showConfirmPassword = !showConfirmPassword" class="eye-b">{{showConfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                <span style="font-size: 12px; font-weight: 400;" *ngIf="this.formPass.controls['password_confirmacion'].errors">*Mínimo 6 caracteres</span>
            </div>
        </div>

        <div class="col-4 d-flex align-items-end justify-content-end">
            <button class="btn btn-primary" type="button" (click)="passwordChange = !passwordChange">Ocultar</button>
        </div>
        
    </div>
    <div class="row">
        <div class="row d-flex justify-content-end rowForm">
            <button class="btn btn-primary" type="button" (click)="dialog.showModal()" >Actualizar</button>
        </div>
    </div>
</form>
<dialog #dialog>
    <div class="m-3 justify-content-center align-items-center">
        <div class="m-4 align-items-center">
            <div class="d-flex justify-content-center">
                <a class="lock dialogborrar"></a>
            </div>
            <h5 id="textdialog">¿Esta cambiando la contraseña, esta seguro de cambiar la contraseña?
            </h5>
        </div>
        <div class="d-flex justify-content-end m-4">
            <button (click)="dialog.close()" id="btcancel"
                class="btn btn-primary mx-1 mt-5"
                type="submit">Cancelar</button>
            <button (click)="dialogSubmit(dialog)" id="btacept"
                class="btn btn-primary mx-1 mt-5" >
                <span class="align-middle"> Confirmar</span>
            </button>
        </div>
    </div>
</dialog>
<dialog #dialogReset>
    <div class="m-3 justify-content-center align-items-center">
        <div class="m-4 align-items-center">
            <div class="d-flex justify-content-center">
                <a class="lock dialogborrar"></a>
            </div>
            <h5 id="textdialog" *ngIf="usuario">Va a restablecer el acceso al usuario con correo "{{usuario.email}}", si este no es el correo deseado, actualice el correo del usuario antes del restablecimiento de acceso.
            </h5>
        </div>
        <div class="d-flex justify-content-end m-4">
            <button (click)="dialogReset.close()" id="btcancel"
                class="btn btn-primary mx-1 mt-5"
                type="submit">Cancelar</button>
            <button (click)="resetAccess(dialogReset)" id="btacept"
                class="btn btn-primary mx-1 mt-5" >
                <span class="align-middle"> Confirmar</span>
            </button>
        </div>
    </div>
</dialog>
