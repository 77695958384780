import { Component, OnInit } from '@angular/core';
import { AuthService } from "../_services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  tfa: any = {};
  accessToken: string = "";
  errorMessage: string = null;

  constructor(private authService: AuthService) {
    this.getAuthDetails();
  }

  ngOnInit() {
  }

  getAuthDetails() {
    this.authService.getAuth().subscribe((data) => {
      const result = data.body
      if (data['status'] === 200) {
        //console.log(result);
        if (result == null) {
          this.setup();
        } else {
          this.tfa = result;
        }
      }
    });
  }

  setup() {
    this.authService.setupAuth().subscribe((data) => {
      const result = data.body
      if (data['status'] === 200) {
        //console.log(result);
        this.tfa = result;
      }
    });
  }

  confirm() {
    this.authService.verifyAuth(this.accessToken).subscribe((data) => {
      const result = data.body
      if (result['status'] === 200) {
        //console.log(result);
        this.errorMessage = null;
        this.tfa.secret = this.tfa.tempSecret;
        this.tfa.tempSecret = "";
      } else {
        this.errorMessage = result['message'];
      }
    });
  }

  disabledTfa() {
    this.authService.deleteAuth().subscribe((data) => {
      const result = data.body
      if (data['status'] === 200) {
        //console.log(result);
        this.accessToken = "";
        this.getAuthDetails();
      }
    });
  }
}
