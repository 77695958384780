import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TramitesService } from 'src/app/_services/autoescuela/tramites.service';
import { VisualizarNotificacionesService } from 'src/app/_services/hencok/visualizar-notificaciones.service';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-notificaciones-tramites',
  templateUrl: './notificaciones-tramites.component.html',
  styleUrls: ['./notificaciones-tramites.component.css']
})
export class NotificacionesTramitesComponent {

  @Input() idTramite: any;
  @Input() idTipoTramite: any;
  @Output() newItemEvent = new EventEmitter<any>();
constructor(
  private notificacionessService: VisualizarNotificacionesService,
  private notificationSvc: NotificationService,
  private tramiteService?:TramitesService

){}

form: UntypedFormGroup = new UntypedFormGroup({
  asunto: new UntypedFormControl("", Validators.required),
   cuerpo: new UntypedFormControl("", Validators.required),
 })
  async onSubmit2(): Promise<any> {
     this.notificacionessService.save(this.form.value).subscribe({
      next: (data) => {
        this.notificationSvc.success('Exito', "Notificación enviada", 3000);
      },
      error:(err) => {
          
      },
    }
    ); 
    this.newItemEvent.emit();
  }

  sendTramite(){
    this.tramiteService.changeStateTramite(this.idTipoTramite,this.idTramite).subscribe({
      next: (data) => {
        this.notificationSvc.success('Exito', "Trámite enviado", 3000);
      },
      error:(err) => {
          
      },
    });
    this.newItemEvent.emit();
  }
}
