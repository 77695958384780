import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CanjeDelPermisoService } from 'src/app/_services/autoescuela/canje-del-permiso.service';
import { PresupuestoService } from 'src/app/_services/autoescuela/presupuesto.service';
import { NotificationService } from 'src/app/_services/notification.service';
import { ServicioGeneral } from 'src/app/_services/servicio-general.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { EntidadTransferencia } from 'src/app/model/autoescuela/entidad_transferencia';
import { finalize } from 'rxjs';
@Component({
  selector: 'app-formulario-canje-del-permiso',
  templateUrl: './formulario-canje-del-permiso.component.html',
  styleUrls: ['./formulario-canje-del-permiso.component.css']
})
export class FormularioCanjeDelPermisoComponent {
  usuario = this.token.getId();
  isRequired: boolean = false;
  ListComunidades: any = [];
  ListVias: any = [];
  ListProvincias: any = [];
  ListMunicipios: any = [];
  ListConvenios: any = [];
  viewConvenio= false;
  selectedDate: Date;
  isChecked= false;
  isEmpresa: boolean = false;
  isUpdated = false;
  isAdmin = false;
  isMod = false;

  submitted = false;
  presupuesto: boolean = false;
  idInform
  solicitante: EntidadTransferencia;
  canjePermiso: any;

  listTipo = [{
    id: "1",
    nombre: "Empresa"
  }, {
    id: "2",
    nombre: "Particular"
  },]
  formCliente2: any;
  formCliente: any;
  convenioSelected:any;
  canjeable= false;

  form: UntypedFormGroup = new UntypedFormGroup({

    usuario: new UntypedFormControl(this.usuario),

    tipoEntidad: new UntypedFormControl(this.listTipo[1]["id"]),
    nombre: new UntypedFormControl("", Validators.required),
    email: new UntypedFormControl("", Validators.required),
    apellido1: new UntypedFormControl("", Validators.required),
    apellido2: new UntypedFormControl("", Validators.required),
    numero_documento: new UntypedFormControl("", Validators.required),
    fecha_nacimiento: new UntypedFormControl(""),
    validez_dni: new UntypedFormControl(""),
    telefono: new UntypedFormControl("", Validators.required),
    tipo_via: new UntypedFormControl("", Validators.required),
    nombre_via: new UntypedFormControl("", Validators.required),
    numero_via: new UntypedFormControl("", Validators.required),
    bloque: new UntypedFormControl(""),
    escalera: new UntypedFormControl(""),
    piso: new UntypedFormControl(""),
    puerta: new UntypedFormControl(""),
    km: new UntypedFormControl(""),
    ccaa: new UntypedFormControl(this.ListComunidades, Validators.required),
    provincia: new UntypedFormControl("", Validators.required),
    municipio: new UntypedFormControl("", Validators.required),
    codigo_postal: new UntypedFormControl("", Validators.required),
    nombre_empresa: new UntypedFormControl(""),
    cif_empresa: new UntypedFormControl(""),
    paisConvenio: new UntypedFormControl("", Validators.required),
  })


  constructor(
    private servicioGeneral: ServicioGeneral,
    private presupuestoService: PresupuestoService,
    private router?: Router,
    private route?: ActivatedRoute,
    private token?: TokenStorageService,
    private notificationSvc?: NotificationService,
    private servicioCanjePermiso?: CanjeDelPermisoService
  ) { }


  ngOnInit() {
    this.getConvenios();
    this.getVias();
    this.getComunidades();

    if (this.token.getRol() == 3 || this.token.getRol() == 99) {
      this.isAdmin = true;
    }
    if (this.token.getRol() == 5 || this.token.getRol() == 6) {
      this.isMod = true;
    }

    this.formCliente2 = this.route.snapshot.paramMap
    this.formCliente2 = this.formCliente2.params

    if (this.formCliente2.AsociarTramite != undefined) {
      this.formCliente = this.formCliente2
      this.  setInforme();
    }

    if (this.formCliente2.AsociarTramite != undefined) {
      this.presupuesto = true
    }
    if(this.formCliente==undefined){
    this.route.params.subscribe((params) => {
      this.idInform = params["id"];
    })
  }
  if (this.idInform) {
    this.getInforme(this.idInform)
     this.isUpdated = true
   }
 
  }

  getInforme(id) {
    this.servicioCanjePermiso.getCanjeId(id).subscribe((data) => {

      if (data == null) {
        this.notificationSvc.error(
          "Error",
          "Acceso no permitido",
          2000
        );
        if (!this.isAdmin) {
          this.router.navigate(['/client-autoescuelas-hencok/listado-tramites'])

        } else {
          this.router.navigate(['/admin-autoescuelas-hencok/listado-tramites'])
        }
      }

      this.solicitante = data["solicitante"];
      this.canjePermiso = data;
      console.log(this.solicitante);


      this.initiateForm()
    })

  }

  initiateForm() {
    this.getProvinciasByID(this.solicitante.comunidadAutonoma.id)
    this.isEmpresa = false

    this.canjeable=true;
    this.isChecked=true;
    this.convenioSelected =  this.ListConvenios.at(Number.parseInt(this.canjePermiso.paisConvenio.id) - 1);
    if (this.solicitante.tipoEntidad.id == 1) this.isEmpresa = true



    this.form = new UntypedFormGroup({
      usuario: new UntypedFormControl(this.usuario),
      tipoEntidad: new UntypedFormControl(this.solicitante.tipoEntidad.id),
      nombre: new UntypedFormControl(this.solicitante.nombre, Validators.required),
      email: new UntypedFormControl(this.solicitante.email, Validators.required),
      apellido1: new UntypedFormControl(this.solicitante.apellido1, Validators.required),
      apellido2: new UntypedFormControl(this.solicitante.apellido2, Validators.required),
      numero_documento: new UntypedFormControl(this.solicitante.dni, Validators.required),
      fecha_nacimiento: new UntypedFormControl(undefined, Validators.required),
      validez_dni: new UntypedFormControl(undefined, Validators.required),
      telefono: new UntypedFormControl(this.solicitante.telefono + "", Validators.required),
      tipo_via: new UntypedFormControl(this.solicitante.tipoVia.id, Validators.required),
      nombre_via: new UntypedFormControl(this.solicitante.nombreVia, Validators.required),
      numero_via: new UntypedFormControl(this.solicitante.numeroVia.toString(), Validators.required),
      bloque: new UntypedFormControl(this.solicitante.bloque + ""),
      escalera: new UntypedFormControl(this.solicitante.escalera),
      piso: new UntypedFormControl(this.solicitante.planta),
      puerta: new UntypedFormControl(this.solicitante.puerta),
      km: new UntypedFormControl(this.solicitante.km),
      ccaa: new UntypedFormControl(this.solicitante.comunidadAutonoma.id, Validators.required),
      provincia: new UntypedFormControl(this.solicitante.provincia.id, Validators.required),
      municipio: new UntypedFormControl("", Validators.required),
      codigo_postal: new UntypedFormControl(this.solicitante.codigoPostal, Validators.required),
      nombre_empresa: new UntypedFormControl(this.solicitante.nombreEmpresa),
      cif_empresa: new UntypedFormControl(this.solicitante.cif),
      paisConvenio: new UntypedFormControl(this.canjePermiso.paisConvenio.id, Validators.required),
    }, { updateOn: 'change' });

    if(this.solicitante.tipoEntidad.id == 1){
      this.form.get('nombre_empresa').addValidators(Validators.required);
      this.form.get('cif_empresa').addValidators(Validators.required);

      this.form.get('fecha_nacimiento').removeValidators(Validators.required);
      this.form.get('validez_dni').removeValidators(Validators.required);

      this.form.controls['fecha_nacimiento'].setErrors(null);
      this.form.controls['validez_dni'].setErrors(null);
    }
    else{
      this.form.controls['fecha_nacimiento'].setValue(this.formatDate(this.solicitante.fechaNacimiendo))
      this.form.controls['validez_dni'].setValue(this.formatDate(this.solicitante.validezDni))
    }

    this.getMunicipiosByID(this.solicitante.provincia.id + "", true)

  }

    isEmpresaOnchanged() {
    if(this.isEmpresa){
      this.form.controls["tipoEntidad"].setValue(2)
      this.isEmpresa=false;

      this.form.get('fecha_nacimiento').addValidators(Validators.required);
      this.form.get('validez_dni').addValidators(Validators.required);
      this.form.get('nombre_empresa').removeValidators(Validators.required);
      this.form.get('cif_empresa').removeValidators(Validators.required);

      this.form.controls['nombre_empresa'].setErrors(null);
      this.form.controls['cif_empresa'].setErrors(null);

      this.form.controls['nombre_empresa'].setValue("");
      this.form.controls['cif_empresa'].setValue("");

      console.log(this.form)
    }else{
      this.form.controls["tipoEntidad"].setValue(1)
      this.isEmpresa=true;

      this.form.controls['fecha_nacimiento'].setErrors(null);
      this.form.controls['validez_dni'].setErrors(null);

      this.form.controls['fecha_nacimiento'].setValue("")
      this.form.controls['validez_dni'].setValue("")

      this.form.get('fecha_nacimiento').removeValidators(Validators.required);
      this.form.get('validez_dni').removeValidators(Validators.required);
      this.form.get('nombre_empresa').addValidators(Validators.required);
      this.form.get('cif_empresa').addValidators(Validators.required);

      console.log(this.form)
    }

  }


  onChangeProvincia(event: Event) {

    this.ListProvincias = [];


    const id = (event.target as HTMLInputElement).value;
    this.getProvinciasByID(id);
  }
  onChangeMunicipio(event: Event) {

    this.ListMunicipios = [];


    const id = (event.target as HTMLInputElement).value;
    this.getMunicipiosByID(id);
  }

  getMunicipiosByID(id: string, edit?) {
    this.servicioGeneral.getMunicipios().subscribe((data) => {
      data['Municipio'].forEach((municipio) => {
        if (municipio.municipioPK["provincia"] == id) {
          this.ListMunicipios.push(municipio);
        }
      });
      if(this.formCliente!=undefined) this.form.controls["municipio"].setValue( this.sacaridMunicipio(this.formCliente.localidad));
      if(edit) this.form.controls["municipio"].setValue( this.sacaridMunicipio(this.solicitante.localidad));
    });
  }
  getProvinciasByID(id: string) {
    this.servicioGeneral.getProvincias().pipe(
      finalize(() => {
      if(this.formCliente!=undefined){   
        console.log(this.ListProvincias)
          this.form.controls["provincia"].setValue(this.formCliente.provincia2);
   }                    
  })).subscribe(
      (data) => {
        data['Provincia'].forEach((provincia) => {
          if (provincia.ccaa["id"] == id) {

            this.ListProvincias.push(provincia);
            return this.ListProvincias;


          }
        });
      });
  }

  getComunidades() {
    return this.servicioGeneral.getComunidades().subscribe((data) => {
      this.ListComunidades = data['ComunidadAutonoma'];
    });
  }
  getVias() {
    return this.servicioGeneral.getTipoVias().subscribe((data) => {
      this.ListVias = data['TipoVia'];
    });
  }

  getConvenios(){
    this.servicioCanjePermiso.getConvenios().subscribe((data) => {
      this.ListConvenios = data;
    })
  }

  async onSubmit(): Promise<any> {


    this.isRequired = true



    this.submitted = true;
    if (!this.form.valid) {

      this.notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
      this.isRequired = true;
      return false;
    } else {
      if (this.isEmpresa) {
        if (this.form.get('nombre_empresa')?.value == undefined ||
          this.form.get('cif_empresa')?.value == undefined) {
            
          this.notificationSvc.warning('Información', 'Rellene los campos necesarios para empresas.', 2000);
          this.submitted = false;
        }
      }

      if (this.form.get('telefono').value.length != 9) {
        this.notificationSvc.error('Error', 'No es un teléfono válido.', 2000);

        return false;
      }
      if (this.form.get('codigo_postal').value.length != 5) {
        this.notificationSvc.error('Error', 'No es un código postal válido.', 2000);
        return false;
      }
      if ((this.form.get('validez_dni').value == ""
        || this.form.get('fecha_nacimiento').value == "") && this.form.controls["tipoEntidad"].value == 2) {
        this.notificationSvc.warning('Información', 'Rellene todos los campos por favor.', 2000);
        this.isRequired = true;

        return false;
      }
    }
    if (this.form.valid) {
      if (!this.isUpdated) {
        this.submitted = true;
        this.servicioCanjePermiso.saveCanje(this.form.value).subscribe({
          next: (data) => {

               if (this.presupuesto) {
                if (this.formCliente.AsociarTramite != undefined) {
  
  
                  this.presupuestoService.asociarTramiteAPresupuesto
                    (data["id"], this.formCliente["AsociarTramite"],7).subscribe(
                      (data) => { this.notificationSvc.success('Exito', "Informe asociado", 2000); }
                      , (error) => { }
                    )
                }
              } 

            this.notificationSvc.success('Exito', "Informe creado", 2000);
            if (!this.isAdmin) {
              this.router.navigate(['/client-autoescuelas-hencok/canje-permiso/' + data["id"]])

            } else {
              this.router.navigate(['admin-autoescuelas-hencok/canje-permiso/' + data["id"]])
            }
            this.submitted = false;
            this.isRequired = false;

          },
          error: (err) => {

            if (err.error.status == 644) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
            } else if (err.error.status == 627) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['email'].setErrors({ 'incorrect': true });
            }
            else {
              this.notificationSvc.error('ERROR', err.error + "", 2000);
            }
            this.submitted = false;
          }
        })
      } else {
        this.servicioCanjePermiso.updateCanje(this.form.value, this.idInform).subscribe({
          next: (data) => {
            this.notificationSvc.success('Exito', "Informe actualizado", 2000);

            if (!this.presupuesto) {
              if (!this.isAdmin) {
                this.router.navigate(['/client-autoescuelas-hencok/canje-permiso/' + data["id"]])

              } else {
                this.router.navigate(['admin-autoescuelas-hencok/canje-permiso/' + data["id"]])
              }
            }
            this.submitted = false;
            this.isRequired = false;

          },
          error: (err) => {

            if (err.error.status == 644) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['numero_documento'].setErrors({ 'incorrect': true });
            } else if (err.error.status == 627) {
              this.notificationSvc.error('Error', err.error.message, 2000);
              this.form.controls['email'].setErrors({ 'incorrect': true });
            }
            else {
              this.notificationSvc.error('ERROR', err.error + "", 2000);
            }
            this.submitted = false;
          }
        })
      }


    }


  }
  formatDate(date) {
    var date2 = []
    date2 = date.split("-");
    let dia = date2[0];
    let mes = date2[1];
    let anio = date2[2];
    return anio + "-" + mes + "-" + dia;
  }

  setInforme() {
    this.canjeable=true;
    this.isChecked=true;
    this.convenioSelected =  this.ListConvenios.at(Number.parseInt(this.canjePermiso.paisConvenio.id) - 1);
    if (!this.formCliente.cif || this.formCliente.cif == "" || this.formCliente.cif == "null") {
      this.isEmpresa = false
      this.form.controls["tipoEntidad"].setValue(2)

    } else {
      this.isEmpresa = true
      this.form.controls["tipoEntidad"].setValue(1)
      this.form.controls["nombre_empresa"].setValue(this.formCliente.nombreEmpresa);
      this.form.controls["cif_empresa"].setValue(this.formCliente.cif)
    }
    this.form.controls["paisConvenio"].setValue(this.canjePermiso.paisConvenio.id);
    this.form.controls["nombre"].setValue(this.formCliente.nombre);

    this.form.controls["apellido1"].setValue(this.formCliente.apellido1);
    this.form.controls["apellido1"].setValue(this.formCliente.apellido1);
    this.form.controls["apellido2"].setValue(this.formCliente.apellido2);

    this.form.controls["numero_documento"].setValue(this.formCliente.dni);
    this.form.controls["fecha_nacimiento"].setValue(this.formCliente.fechaNacimiendo);
    this.form.controls["validez_dni"].setValue(this.formCliente.validezDni);

    this.form.controls["telefono"].setValue(this.formCliente.telefono);
    this.form.controls["email"].setValue(this.formCliente.email);

    this.form.controls["tipo_via"].setValue(this.formCliente.tipoVia2);
    this.form.controls["nombre_via"].setValue(this.formCliente.nombreVia);
    this.form.controls["numero_via"].setValue(this.formCliente.numeroVia);

    this.form.controls["bloque"].setValue(this.formCliente.bloque == "null" ? "" : this.formCliente.bloque);
    this.form.controls["escalera"].setValue(this.formCliente.escalera == "null" ? "" : this.formCliente.escalera);
    this.form.controls["piso"].setValue(this.formCliente.planta == "null" ? "" : this.formCliente.planta);
    this.form.controls["puerta"].setValue(this.formCliente.puerta == "null" ? "" : this.formCliente.puerta);
    this.form.controls["km"].setValue(this.formCliente.km == "null" ? "" : this.formCliente.km);
    this.form.controls["codigo_postal"].setValue(this.formCliente.codigoPostal);
    this.form.controls["ccaa"].setValue(this.formCliente.comunidadAutonoma);
   this.getProvinciasByID(this.formCliente.comunidadAutonoma)
    this.form.controls["provincia"].setValue("this.formCliente.provincia");
  this.getMunicipiosByID(this.formCliente.provincia2)



  }

  showMoreInfo(convenio: Event){
    this.viewConvenio=true;
    let convenioPosition = (convenio.target as HTMLInputElement).value;
    this.convenioSelected =  this.ListConvenios.at(Number.parseInt(convenioPosition) - 1);
    this.canjeable=true;
    this.isChecked=true;
  }

  checkDate(){
      this.canjeable=true;
      this.isChecked=true;
  }
  
  sacaridMunicipio(nombre: String){

    var id: number = 0;
    for(let i = 0; i < this.ListMunicipios.length ; i++){
      if (this.ListMunicipios.at(i).nombre == nombre) {
        id = this.ListMunicipios.at(i).municipioPK.municipio_id;
      }
    }
    return id;
  }

}
