import { formatDate } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { FacturaService } from "src/app/_services/hencok/factura.service";
import { GastosService } from "src/app/_services/hencok/gastos.service";
import { IngresosGastosService } from "src/app/_services/hencok/ingresos-gastos.service";
import { NexcloudService } from "src/app/_services/nexcloud.service";
import { NotificationService } from "src/app/_services/notification.service";
import { ServicioGeneral } from "src/app/_services/servicio-general.service";
import { SharedDataService } from "src/app/_services/shared-data.service";
import { TokenStorageService } from "src/app/_services/token-storage.service";
import { Factura } from "src/app/model/hencok/factura";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-gestion-ingresos-gastos",
  templateUrl: "./gestion-ingresos-gastos.component.html",
  styleUrls: ["./gestion-ingresos-gastos.component.css"],
})
export class GestionIngresosGastosComponent {
  isSortedByFecha: string = "";
  isSortedByConcepto: string = "";
  isSortedByImporte: string = "";
  isSortedByEstado: string = "";
  isSortedByTipo: string = "";
  isSortedByCliente: string = "";
  isSortedByName: string="";
  isDelete = false;

  gasto_ingreso: any = null;
  gasto_ingreso2: any = null;
  usuarios = [];
  sortedUsuarios = [];
  filteredUsuarios = [];
  gastos_ingresos: any=[];
   gastoIngreso: any=[]  ;
  gastos_ingresosInicio: any;
  total_gastos_ingresos: any;
  total_gastos_ingresosInmutable: any;
  estados_gasto: any ;

  total_facturado: any = 0;
  total_pendiente: number = 0;
  total_deuda: number = 0;

  isShowedGastoIngreso: boolean = false;
  mostrarGastoIngreso: boolean = false;
  userAdmin: Boolean = false;

  searchText: String = "";
  
  totalElements: number = 0;
  userLogged: any;
  usuario: any = -1;
  fecha_actual: string;
  detalles = [];
  detalles2 = [];
  primeraVez: boolean = true;
  primeraVezM: boolean = false;

  page = "0";
  size = "5";
  
  filters: any[] = [];
  sorts: any[] = [];
  sortArray : any = {};

  canvas;

  request = {
    filters: this.filters,
    sorts: this.sorts,
    page: this.page,
    size: this.size,
  };
 
  mostrarPag=true;
  load: boolean;
  constructor(
    private nextCloudService: NexcloudService,
    private _notificationSvc: NotificationService,
    private _serviceGeneral: ServicioGeneral,
    private _sharedDataService: SharedDataService,
    private _ingresosGastosService: IngresosGastosService,
    private _tokenStorageService: TokenStorageService,
    private _router: Router,
    private gastoservice: GastosService,
    private facturaService: FacturaService,
    private http: HttpClient
  ) {
    this.load = false;
  }

  ngOnInit(): void {
    this.gastoservice.findAllEstados().subscribe({
      next: (data) => {
       this.estados_gasto=data;
      },
      error: (error) => {
        console.log(error);
      },
    });
    this.userLogged = this._tokenStorageService.getUser();
    let rol = this._tokenStorageService.getRol();
    if (rol == 99 || rol == 2) {
      this.userAdmin = true;
    } else {
      this.userAdmin = false;
      this._router.navigateByUrl("/admin-hencok").then(() => {
        this._router.navigate([
          "client-hencok/gastos-ingresos/" + this.userLogged.id,
        ]);
      });

      
      
    }


    this.getIngresosListByUsuario({ page: "0", size: "5" });
    this.getUserList();
    this.fecha();
    this._sharedDataService.currentMessage.subscribe(message => (this.filtrar(message)));
    this.getoTotalGastos();
    this.sortArrayInitialize();
  }


  filtrar(mesagge: string) {
    if(this.total_gastos_ingresosInmutable!= undefined){
      if (mesagge.length > 0) {
        this.gastos_ingresos = this.total_gastos_ingresosInmutable.filter((item) =>
        (item.usuario.nombre.toLowerCase() + " "+ item.usuario.apellido1.toLowerCase() + " "+ 
        item.usuario.apellido2.toLowerCase()) .indexOf(mesagge.toLowerCase()) >
        -1 ||
           item.usuario.email.toLowerCase().indexOf(mesagge.toLowerCase()) > -1 ||
           item.tipo_transaccion.nombre.toLowerCase().indexOf(mesagge.toLowerCase()) > -1 ||
          item.usuario.id.toString().includes(mesagge) ||
         item.estado.nombre.toString().toLowerCase().indexOf(mesagge.toLowerCase()) > -1||
        ( item.fechaCreacion[2].toString()+"/" + item.fechaCreacion[1].toString() + "/" + item.fechaCreacion[0].toString()).includes(mesagge)
        )
        this.total_gastos_ingresos=this.gastos_ingresos;
        this.pageSize=this.total_gastos_ingresos.size
      } else{
        this.getIngresosListByUsuario({page: "0", size: "5"  });
        this.getoTotalGastos();
      }
      if(mesagge != ""){
        this.mostrarPag=false
      }else{
        this.mostrarPag=true
      }
  
  }}
  getoTotalGastos(){
    this._ingresosGastosService
    .getIngresoGastoList()
    .subscribe({
      next: (data) => {
        this.total_gastos_ingresos=data;
        this.total_gastos_ingresosInmutable=data;
      },
      error: (error) => {
      //  console.log(error);
      },
    });
  }
  getIngresosListByUsuario(request) {
    if (this.userAdmin) {
      this._ingresosGastosService
        .getIngresoGastoListPaginate(request)
        .subscribe({
          next: (data) => {
            this.gastos_ingresos = data["content"];
            this.gastos_ingresosInicio = data["content"]; 
            this.totalElements = data["totalElements"];
          },
          error: (error) => {
          //  console.log(error);
          },
        });

       
    } else {
      this._ingresosGastosService
        .getIngresoGastoListByUsuario(request, this.userLogged.id)
        .subscribe({
          next: (data) => {
            this.gastos_ingresos = data["content"];
            this.gastos_ingresosInicio = data["content"];
            this.totalElements = data["totalElements"];
          },
          error: (error) => {
          //  console.log(error);
          },
        });
    }
  }

  getUserList() {
    this._serviceGeneral.getUsersRoleOne().subscribe({
      next: (data) => {
        this.usuarios = data;
      },
      error: (error) => {
      //  console.log(error);
      },
    });
  }

  getDateToCompare(fechas) {
    var fechaString = fechas.join("-");
    var date = new Date(fechaString);
    return date.getTime();
  }
  orderByName() {
    this.isSortedByConcepto = "";
    this.isSortedByImporte = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";
    this.isSortedByCliente = "";
    this.isSortedByFecha = "";

    if (this.isSortedByName != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (n1.usuario.nombre > n2.usuario.nombre) {
          return -1;
        }

        if (n1.usuario.nombre < n2.usuario.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByName = "chevron-button";

    } else if (this.isSortedByName == "chevron-button") {

      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (n1.usuario.nombre > n2.usuario.nombre) {
          return 1;
        }

        if (n1.usuario.nombre < n2.usuario.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByName = "chevron-button-up";
    }
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }
  
  orderByFecha() {
    this.isSortedByConcepto = "";
    this.isSortedByImporte = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";
    this.isSortedByCliente = "";
    this.isSortedByName = "";

    if (this.isSortedByFecha != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (
          this.getDateToCompare(n1.fechaCreacion) >
          this.getDateToCompare(n2.fechaCreacion)
        ) {
          return -1;
        }

        if (
          this.getDateToCompare(n1.fechaCreacion) <
          this.getDateToCompare(n2.fechaCreacion)
        ) {
          return 1;
        }

        return 0;
      });
      this.isSortedByFecha = "chevron-button";
    } else if (this.isSortedByFecha == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (
          this.getDateToCompare(n1.fechaCreacion) >
          this.getDateToCompare(n2.fechaCreacion)
        ) {
          return 1;
        }

        if (
          this.getDateToCompare(n1.fechaCreacion) <
          this.getDateToCompare(n2.fechaCreacion)
        ) {
          return -1;
        }

        return 0;
      });
      
      this.isSortedByFecha = "chevron-button-up";
    }
  
      this.gastos_ingresos=[]
      this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);

  }
  orderByConcepto() {
    this.isSortedByFecha = "";
    this.isSortedByImporte = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";
    this.isSortedByCliente = "";
    this.isSortedByName = "";


    if (this.isSortedByConcepto != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (n1.concepto > n2.concepto) {
          return -1;
        }

        if (n1.concepto < n2.concepto) {
          return 1;
        }

        return 0;
      });
      this.isSortedByConcepto = "chevron-button";
    } else if (this.isSortedByConcepto == "chevron-button") {
      this.gastos_ingresos = this.gastos_ingresos.sort((n1, n2) => {
        if (n1.concepto > n2.concepto) {
          return 1;
        }

        if (n1.concepto < n2.concepto) {
          return -1;
        }

        return 0;
      });
      this.isSortedByConcepto = "chevron-button-up";
    }
    
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }
  orderByImporte() {
    this.isSortedByFecha = "";
    this.isSortedByConcepto = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";
    this.isSortedByCliente = "";
    this.isSortedByName = "";


    if (this.isSortedByImporte != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (parseInt(n1.importe) > parseInt(n2.importe)) {
          return -1;
        }

        if (parseInt(n1.importe) < parseInt(n2.importe)) {
          return 1;
        }

        return 0;
      });
      this.isSortedByImporte = "chevron-button";
    } else if (this.isSortedByImporte == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (parseInt(n1.importe) > parseInt(n2.importe)) {
          return 1;
        }

        if (parseInt(n1.importe) < parseInt(n2.importe)) {
          return -1;
        }

        return 0;
      });
      this.isSortedByImporte = "chevron-button-up";
    }
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }
  orderByEstado() {
    this.isSortedByFecha = "";
    this.isSortedByImporte = "";
    this.isSortedByConcepto = "";
    this.isSortedByTipo = "";
    this.isSortedByCliente = "";
    this.isSortedByName = "";


    if (this.isSortedByEstado != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (n1.estado.nombre > n2.estado.nombre) {
          return -1;
        }

        if (n1.estado.nombre < n2.estado.nombre) {
          return 1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button";
    } else if (this.isSortedByEstado == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (n1.estado.nombre > n2.estado.nombre) {
          return 1;
        }

        if (n1.estado.nombre < n2.estado.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByEstado = "chevron-button-up";
    }
    
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }
  orderByTipo() {
    this.isSortedByFecha = "";
    this.isSortedByImporte = "";
    this.isSortedByConcepto = "";
    this.isSortedByEstado = "";
    this.isSortedByCliente = "";
    this.isSortedByName = "";


    if (this.isSortedByTipo != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        //console.log(n1.tipo_transaccion)
        //console.log(n1.tipo_transaccion > n2.tipo_transaccion)
        if (n1.tipo_transaccion.nombre > n2.tipo_transaccion.nombre) {
          return -1;
        }

        if (n1.tipo_transaccion < n2.tipo_transaccion) {
          return 1;
        }

        return 0;
      });
      this.isSortedByTipo = "chevron-button";
    } else if (this.isSortedByTipo == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
       /*  console.log(n1.tipo_transaccion)
        console.log(n2.tipo_transaccion)
        console.log(n1.tipo_transaccion.nombre > n2.tipo_transaccion.nombre) */
        if (n1.tipo_transaccion.nombre > n2.tipo_transaccion.nombre) {
          return 1;
        }

        if (n1.tipo_transaccion.nombre < n2.tipo_transaccion.nombre) {
          return -1;
        }

        return 0;
      });
      this.isSortedByTipo = "chevron-button-up";
    }
    
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }
  orderByCliente() {
    this.isSortedByFecha = "";
    this.isSortedByImporte = "";
    this.isSortedByConcepto = "";
    this.isSortedByEstado = "";
    this.isSortedByTipo = "";
    this.isSortedByName = "";


    if (this.isSortedByCliente != "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (parseInt(n1.usuario.id) > parseInt(n2.usuario.id)) {
          return -1;
        }

        if (parseInt(n1.usuario.id) < parseInt(n2.usuario.id)) {
          return 1;
        }

        return 0;
      });
      this.isSortedByCliente = "chevron-button";
    } else if (this.isSortedByCliente == "chevron-button") {
      this.total_gastos_ingresos = this.total_gastos_ingresos.sort((n1, n2) => {
        if (parseInt(n1.usuario.id) > parseInt(n2.usuario.id)) {
          return 1;
        }

        if (parseInt(n1.usuario.id) < parseInt(n2.usuario.id)) {
          return -1;
        }

        return 0;
      });
      this.isSortedByCliente = "chevron-button-up";
    }
    this.gastos_ingresos=[]
    this.gastos_ingresos=this.total_gastos_ingresos.slice(0, this.pageSize);
  }

  pageSize = 5;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 50, 100];
  showFirstLastButtons = true;

  getByGastoId(id) {
    this._ingresosGastosService.getByGastoId(id).subscribe({
      next: (data) => {},
      error: (error) => {
      //  console.log(error);
      },
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  openSidebar(event: Event) {
    var element = document.getElementById("sidebar");
    element.classList.add("active");
    event.stopPropagation();
  }

  openSidebarModificar(gasto_ingreso2, e: any) {
    var spinner = document.createElement("div");
    spinner.classList.add("spinner-border");
    spinner.classList.add("spinner-border-sm");
    var element = document.getElementById("sidebar_modificar");

    this.gasto_ingreso2 = gasto_ingreso2;
    this._ingresosGastosService
      .getByGastoId(gasto_ingreso2.id)
      .subscribe({
        next: (data) => {
          this.detalles2 = data["documentos"];
        },
        error: (error) => {
        //  console.log(error);
        },
      })
      .add(() => {
        this.primeraVezM = true;

        element?.classList.add("active");
      });
  }

  closeSidebar(e: any) {
    if (e.target.classList.value == "eye-button activated") {
    } else {
      var element_gasto = document?.getElementById("sidebar");
      var element = document?.getElementById("sidebar_ingreso");
      var elementV = document?.getElementById("sidebar_detalle");
      var element_gasto2 = document?.getElementById("sidebar_modificar");
      element_gasto2?.classList.remove("active");
      elementV?.classList.remove("active");
      element?.classList.remove("active");
      element_gasto?.classList.remove("active");
    }
    e.stopPropagation();
  }

  openSidebarIngreso(event) {
    var element = document.getElementById("sidebar_ingreso");

    element.classList.add("active");
    event.stopPropagation();
  }

  closeSidebarIngreso() {
    var element_gasto = document.getElementById("sidebar");
    var element = document.getElementById("sidebar_ingreso");
    element.classList.remove("active");
    element_gasto.classList.remove("active");
  }

  openSidebarDetalles(gasto_ingreso, e: any) {
  //  console.log(gasto_ingreso)
    var ojos = Array.from(
      document.getElementsByClassName(
        "eye-button"
      ) as HTMLCollectionOf<HTMLElement>
    );
    Array.from(ojos).forEach((ojo) => {
      ojo.style.cssText = "pointer-events:none";
    });
    this.mostrarGastoIngreso = true;
    var padre = e.target.parentElement;
    var ojo = e.target;
    padre.removeChild(ojo);
    var spinner = document.createElement("div");
    spinner.classList.add("spinner-border");
    spinner.classList.add("spinner-border-sm");
    padre.prepend(spinner);
    var element = document.getElementById("sidebar_detalle");

    this.gasto_ingreso = gasto_ingreso;
    this._ingresosGastosService.getByGastoId(gasto_ingreso.id).subscribe({
      next: (data) => {
        this.detalles = data["documentos"];
        this.isShowedGastoIngreso = true;
        padre.removeChild(spinner);
        padre.prepend(ojo);
        Array.from(ojos).forEach((ojo) => {
          ojo.style.cssText = "";
        });
        this.primeraVez = true;
        element?.classList.add("active");
      },
      error: (error) => {
      //  console.log(error);
      },
    });
  }

  deleteGastoIngreso(gasto_ingreso, e: any) {
    this.isDelete = true;
    let detalles_id_gestor = [];
    this.gasto_ingreso= gasto_ingreso
  //  console.log(this.gasto_ingreso.factura)
 
    if(this.gasto_ingreso.estado.nombre != "COMPLETADO" && this.gasto_ingreso.factura == undefined){
    this._ingresosGastosService
      .getByGastoId(gasto_ingreso.id)
      .pipe(
        finalize(() => {
         /*  console.log(
            "**********************cuando se finaliza se llama a delete file "
          ); */
          this.detalles.forEach((detalle) =>
            detalles_id_gestor.push(detalle.id_gestor)
          );
          this._ingresosGastosService
            .DeleteFile(detalles_id_gestor)
            .pipe(
              finalize(() => {
               /*  console.log(
                  "**********************cuando se finaliza se llama a delete gasto "
                ); */

                this._ingresosGastosService
                  .deleteGastoIngreso(gasto_ingreso.id)
                  .subscribe({
                    next: (data) => {
                      this.getIngresosListByUsuario({
                        page: this.pageIndex,
                        size: this.pageSize,
                      });

                      this._notificationSvc.success(
                        "Hecho",
                        "Borrado correctamente"
                      );
                      this.isDelete = false;
                    },
                    error: (err) => {
                      this._notificationSvc.error(
                        "ERROR",
                        "No se ha podido borrar  el ingreso/gasto"
                      );
                    },
                  });
              })
            )
            .subscribe({ next: (data) => {}, error: (err) => {} });
        })
      )
      .subscribe({
        next: (data) => {
          this.detalles = data["documentos"];
        },
        error: (err) => {},
      });

    }
    else if(this.gasto_ingreso.estado.nombre != "PENDIENTE"){
      this._notificationSvc.info(
        "Aviso!",
        "No es posible modificar un gasto completado",
        2000
      );
    }
    else{
      this._notificationSvc.info(
        "Aviso!",
        "No es posible modificar un ingreso \ngenerado a partir de una factura",
        2000
      );
      }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("/");
  }

  nextPage(event: PageEvent) {
    const request = {};
    request["page"] = event.pageIndex;
    this.pageIndex = event.pageIndex;
    request["size"] = event.pageSize;
    this.pageSize = event.pageSize;
    this.getIngresosListByUsuario(request);
  }

  getFirstLetter(nombre: string) {
    return nombre.charAt(0).toUpperCase();
  }

  fecha() {
    const d = new Date();
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    this.fecha_actual = [day, month, year].join("-");
  }

  changeEstado(estado: any , gasto_ingreso: any) {
    this.gastoservice.updateGastoEstado(estado, gasto_ingreso.id.toString()).subscribe({
      next: (data) => {
        var factura = gasto_ingreso.factura;
        if(factura != undefined){
          this.facturaService.updateState(factura.id, estado).subscribe({next: (data) =>{
                    this.getIngresosListByUsuario({
                    page: this.pageIndex,
                    size: this.pageSize,
                    }); 
                    this._notificationSvc.success("Hecho", "Estado actualizado", 2000);
          }})
         // console.log(gasto_ingreso.factura)
        }
        else{
          this.getIngresosListByUsuario({
            page: this.pageIndex,
            size: this.pageSize,
            });
            this._notificationSvc.success("Hecho", "Estado actualizado", 2000); 
        }

        
      },
      error: (err) => {
        this._notificationSvc.error(
          "ERROR",
          "No se ha podido actualizar el estado",
          2000
        );
      },
    });
  }


  downloadFile(idarch: String, idgasto: number) {
    this._ingresosGastosService
      .getByGastoId(idgasto)
      .pipe(
        finalize(() =>
          /* if( this.detalles[0]== undefined){
          throw this._notificationSvc.error('ERROR', "No se ha podido descargar el documento", 2000);
        }*/
          this.detalles.forEach((detalle) =>
            this.nextCloudService.downloadJustificanteB64(detalle.gasto_id).subscribe({
              next: (fichero) => {
               // console.log(fichero);
                const linkSource = "data:application/pdf;base64," + fichero.Result;
          const downloadLink = document.createElement("a");
          const fileName = detalle.nombre;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
                this._notificationSvc.success(
                  "Descargando",
                  "Descarga realiza con exito",
                  2000
                );
              },
              error: (err) => {
               // console.log(err);
                this._notificationSvc.error(
                  "ERROR",
                  "No se ha podido descargar el documento",
                  2000
                );
              },
            })
          )
        )
      )
      .subscribe({
        next: (data) => {
          this.detalles = data["documentos"];
        },
        error: (err) => {},
      });
  }

  formatDateFactura(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  showEditDeleteError(error, estado){
    if(estado == 'COMPLETADO'){
      this._notificationSvc.info(
        "Aviso!",
        "No se ha podido " + error + " el gasto/ingreso, es un gasto/ingreso completado",
        2000
      );
    }
    else{
      this._notificationSvc.info(
        "Aviso!",
        "No se ha podido " + error + " el gasto/ingreso, es una factura emitida",
        2000
      );
    }
    
  }
  API_URL: string = environment.API_URL;

  downloadExcel(){
   var  listaGastos:any=[];
   this.total_gastos_ingresos.forEach(element => {
    listaGastos.push(element.id)
   });
   
    return this.http.post(`${this.API_URL}/gasto/generateExcelSeleccion`, listaGastos,{responseType: 'text'}).subscribe({
      next: (data: any) => {
       // Decodifica el archivo Base64 a un arreglo de bytes
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      // Crea un objeto Blob y URL para el archivo
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      // Crea un enlace de descarga y simula un clic para descargar el archivo
      const a = document.createElement('a');
      a.href = url;
      a.download = 'mi_archivo.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); }
      ,error:(error)=>{
        console.log(error)
      }
  });
  }

  static closeSidebarClienteRemoto(){
    var element_gasto = document?.getElementById("sidebar");
    var element = document?.getElementById("sidebar_ingreso");
    var elementV = document?.getElementById("sidebar_detalle");
    var element_modificar = document?.getElementById("sidebar_modificar");
    elementV?.classList.remove("active");
    element?.classList.remove("active");
    element_gasto?.classList.remove("active");
    element_modificar?.classList.remove("active");
  }

  doFilter(event, type, operator, field_type) {
    let value = event.target.value;
    let isArray = type instanceof Array;

    console.log(isArray);

    if (value != "") {
      if (field_type == "DATE") {
        value = formatDate(value, "dd-MM-yyyy", "en-US");
      }
      let index = null;
      let newFilter = null;
      if (!isArray) {
        index = this.filters.findIndex((item) => item.key == type);
        newFilter = {
          key: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });

        index = this.filters.findIndex((item) => item.key == composeKey);
        newFilter = {
          key: composeKey,
          keys: type,
          operator: operator,
          field_type: field_type,
          value: value,
        };
      }
      if (index != -1) {
        this.filters[index] = newFilter;
      } else {
        this.filters.push(newFilter);
      }
    } else {
      if (!isArray) {
        this.filters = this.filters.filter((item) => item.key !== type);
      } else {
        let composeKey = "";
        type.forEach((element) => {
          composeKey += element;
        });
        this.filters = this.filters.filter((item) => item.key !== composeKey);
      }
    }
    console.log("los filtros son", this.filters);
    this.getPresupuestos();
  }

  getPresupuestos() {
    let rol = this._tokenStorageService.getRol();

    if (rol == 1) {
      let filterUser = {
        key: "usuario.id",
        operator: "EQUAL",
        field_type: "LONG",
        value: this._tokenStorageService.getId(),
      };
      this.filters.push(filterUser);
    }
    this.request = {
      filters: this.filters,
      sorts: this.sorts,
      page: this.page,
      size: this.size,
    };
    this._ingresosGastosService
      .getGastoIngresoFilter(this.request)
      .subscribe((data: any) => {
        this.gastos_ingresos = data.pageList;
        console.log(data.pageList);

        this.totalElements = data.nrOfElements;
      });
  }

  calcTextWidth(text, font) {
    console.log(text, font)
    let canvas = this.canvas || (this.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    console.log(metrics.width + 24)
    return metrics.width + 24;
  }

  sortArrayInitialize(){
    this.sortArray = {nombre:'none', id:'none', tipoGasto:'none', fechaCreacion:'none', estado:'none', importe:'none'};
  }

  changeSorts(key, field){
    let index;
    let typeArray = [];
    let fieldArray = [];
    let isArray = key instanceof Array;
    let newSort = {}
    if(isArray){
      for(let attribute of key){
        typeArray.push(attribute);
      }
      for(let sortField of field){
        fieldArray.push(sortField);
      }
    }
    else{
      typeArray.push(key);
      fieldArray.push(field)
    }
    
    for(let i = 0; i < typeArray.length; i++){
      if(this.sortArray[fieldArray[i]] === 'none'){
        this.sortArray[fieldArray[i]] = 'DESC'
        newSort = {direction: 'DESC', key: typeArray[i]}
        this.sorts.push(newSort)
      }
      else if(this.sortArray[fieldArray[i]] === 'DESC'){
        this.sortArray[fieldArray[i]] = 'ASC'
        index = this.sorts.findIndex((item) => item.key == typeArray[i]);
        newSort = {direction: 'ASC', key: typeArray[i]}
        this.sorts[index] = newSort;

      }
      else{
        this.sortArray[fieldArray[i]] = 'none'
        this.sorts =  this.sorts.filter((item) => item.key !== typeArray[i]);
        delete this.sortArray[index];
      }
    }
    this.getPresupuestos();
  }
  
}
