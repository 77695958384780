<nav id="sidebar">
    <div class="fixed">
        <app-formulario-adjuntar-modelo></app-formulario-adjuntar-modelo>
    </div>
</nav>

<nav id="sidebar_detalle">
    <div class="fixed">
        <app-visualizar-impuesto [detallesP]="impuesto" [detalles]="detalles"></app-visualizar-impuesto>
    </div>
</nav>

<nav id="sidebar_modificar">
    <div class="fixed">
        <app-formulario-editar-modelo [impuesto]="impuesto" [detalles]="detalles"></app-formulario-editar-modelo>
    </div>
</nav>


<div (click)="closeSidebar($event)">
    <div class="d-flex flex-row justify-content-between align-items-center full-form-group">
        <h1 class="m-0">Impuestos</h1>
        <div>
            <button class="btn btn-primary align-middle" (click)="openSidebar($event)">
                <mat-icon class="align-middle mr-2">add</mat-icon><span class="align-middle">Adjuntar modelo</span>
            </button>
        </div>
    </div>

    <div class="full-form-group">

        <div class="subtitle">
            <h5>Listado de todos los impuestos</h5>
        </div>
        <div class="w-100 border-bottom filter-bar tableHead">
            <div class="w-100 filteringTable">
                <div class="w-100 row ordering-table">
                    <div class="card-width-name filter-container col-3">
                        <input type="text" class="searchinput" placeholder="CLIENTE"
                            (keyup)="doFilter($event, 'autonomo.nombre', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('autonomo.nombre', 'nombre')"><a
                                [ngClass]="sortArray['nombre']==='ASC' ? 'asc-sort': sortArray['nombre']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-id filter-container col-1">
                        <input type="text" class="searchinput" placeholder="ID"
                            (keyup)="doFilter($event, 'autonomo.id', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('autonomo.id', 'id')"><a
                                [ngClass]="sortArray['id']==='ASC' ? 'asc-sort': sortArray['id']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-modelo filter-container col-2">
                        <input type="text" class="searchinput" placeholder="MODELO"
                            (keyup)="doFilter($event, 'concepto', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('concepto', 'concepto')"><a
                                [ngClass]="sortArray['concepto']==='ASC' ? 'asc-sort': sortArray['concepto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-periodo filter-container col-2">
                        <input type="text" class="searchinput" onfocus="(this.type='date')"
                            onblur="if(this.value==''){this.type='text'}" placeholder="PERIODO DE SOLICITUD" (change)="
                            doFilter($event, 'periodoImpuesto', 'CURRENT_DATE', 'DATE')
                            " />
                        <button (click)="changeSorts('periodoImpuesto', 'periodoImpuesto')"><a
                                [ngClass]="sortArray['periodoImpuesto']==='ASC' ? 'asc-sort': sortArray['periodoImpuesto']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-width-status filter-container col-2">
                        <input type="text" class="searchinput" placeholder="ESTADO"
                            (keyup)="doFilter($event, 'estado.nombre', 'LIKE', 'STRING')" />
                        <button (click)="changeSorts('estado.nombre', 'estado')"><a
                                [ngClass]="sortArray['estado']==='ASC' ? 'asc-sort': sortArray['estado']==='DESC' ? 'desc-sort': 'no-sort'"></a></button>
                    </div>
                    <div class="card-with-actions col-2 pr-0">
                        <div class="buttons">
                            ACCIONES
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card w-100 card-generic border-0">
            <ul class="list-group list-group-flush">
                <li class="list-group-item p-3 d-flex border rounded shadow"
                    *ngFor="let impuesto of listadoImpuestos | filterImpuesto : searchText">

                    <div class="row w-100 row-with-cards ml-0 mr-0">
                        <div class="card border-0 card-width-name col-xl-3">
                            <p class="cut-text"
                                [title]="impuesto.autonomo.nombre?impuesto.autonomo.nombre+' '+impuesto.autonomo.apellido1+' '+impuesto.autonomo.apellido2:''">

                                {{impuesto.autonomo.nombre}} {{impuesto.autonomo.apellido1}}
                                {{impuesto.autonomo.apellido2}}
                            </p>
                        </div>
                        <div class="card border-0 card-width-id colorOption col-md-1 col-xl-1">
                            <a
                                [routerLink]="['/admin-hencok/impuestos/', impuesto.autonomo.id]">#{{impuesto.autonomo.id}}</a>
                        </div>
                        <div class="d-flex flex-row card border-0 card-width-id file-column card-width-modelo col-xl-2">
                            <img src="/assets/hencok/file_icon.svg" alt="icono de archivo" class="icono-archivo mr-2">
                            <span (click)="openPdf(impuesto)" role="button">
                                <p class="cut-text" [title]="impuesto.concepto?impuesto.concepto:''">
                                    {{impuesto.concepto}}
                                </p>
                            </span>
                        </div>
                        <div class="card border-0 card-width-periodo col-lg-5 col-xl-2">
                            <p class="cut-text" [title]="impuesto.periodoImpuesto?(impuesto.periodoImpuesto | replace: '-' : '/') +' - ' 
                            +(impuesto.fechaMaxima | replace:  '-' :'/'):''">

                                {{impuesto.periodoImpuesto | replace: '-' : '/'}} - {{impuesto.fechaMaxima | replace:
                                '-' : '/'}}
                            </p>
                        </div>
                        <div [ngClass]="{'pending-state': impuesto.estado.nombre.toLowerCase() == 'pendiente',
                        'approved-state': impuesto.estado.nombre.toLowerCase() == 'aprobado',
                        'expired-state': impuesto.estado.nombre.toLowerCase()== 'expirado'}"
                            class="d-flex flex-row align-items-center card-width-status col-xl-2 status">
                            {{impuesto.estado.nombre}}
                        </div>

                        <div class="button-center col-xl-2">
                            <div class="buttons-flex-container align-items-center card-width-actions">
                                <a class="eye-button activated" title="Ver"
                                    (click)="openSidebarVisualizar(impuesto,$event)" title="Editar"></a>
                                <a class="edit-button activated pencil"
                                    (click)="openSidebarModificar(impuesto,$event)"></a>
                                <a class="download-button activated" title="Descargar"
                                    (click)="downloadPdf(impuesto)"></a>
                                <a (click)="dialog.showModal()" class="delete-button activated" title="Eliminar"></a>

                                <dialog #dialog>
                                    <div class="m-3 justify-content-center align-items-center">
                                        <a class="delete-button dialogborrar"></a>

                                        <div class="m-4">
                                            <h5 id="textdialog">¿Desea confirmar la eliminacion?
                                            </h5>
                                        </div>
                                        <div class="d-flex justify-content-end m-4">
                                            <button (click)="dialog.close()" id="btcancel"
                                                class="btn btn-primary mx-1 mt-5" type="submit">Cancelar</button>
                                            <button (click)="deleteImpuesto(impuesto,$event)" id="btacept"
                                                class="btn btn-primary mx-1 mt-5" type="submit" [disabled]="isDelete">
                                                <div class="spinner-border spinner-border-sm" *ngIf="isDelete"></div>
                                                <span class="align-middle"> Confirmar</span>
                                            </button>
                                        </div>
                                    </div>
                                </dialog>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div *ngIf="listadoImpuestos?.length == 0"
            class="d-flex flex-row justify-content-center empty-data-message my-4">
            <span>No se han encontrado registros que mostrar</span>
        </div>
        <mat-paginator *ngIf="mostrarPag" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5" [length]="totalElements"
            (page)="nextPage($event)"> </mat-paginator>

    </div>
</div>