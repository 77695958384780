<div class="page__content">
  <div class="content__inner">
    <div class="content__login">
      <div class="card__login">
        <form [formGroup]="form">
          <div *ngIf="!isLoggedIn && !tfaFlag && !resetQR">

       
            <h1>Iniciar sesión</h1>

            <div class="form-group">
              <input type="email" class="form-control" name="email" formControlName="email" required email
                placeholder='Email' [ngClass]="{'is-invalid': submitted && this.form.controls['email'].errors}" />
            </div>
            <div class="form-group password-div">
              <input type="{{showPassword ? 'text' : 'password' }}" class="form-control" name="password" formControlName="password"
                placeholder= 'Contraseña'
                [ngClass]="{'is-invalid': submitted && this.form.controls['password'].errors}">
              <mat-icon matSuffix (click)="showPassword = !showPassword" class="eye-b">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
            </div>
            <div class="form-group">
              <button class="btn btn-primary btn-block" (click)="onSubmit()" [disabled]="submitted">
                <div class="spinner-border spinner-border-sm" *ngIf="submitted"></div>
                        <span class="align-middle">  Iniciar sesion</span>
              </button>
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            </div>

            <a routerLink="/forgot-password" class="forgot-password">¿Olvidó la contraseña?</a>
            <a routerLink="/register" class="register ml-3">Registrarse</a>

            <!--<div class="d-flex flex-row justify-content-around mt-12 align-items-center text-muted" *ngIf="currentEnv == 'novotaxi'">
              <p class="m-0">Política de privacidad</p>
              <p class="m-0">Términos de uso</p>
            </div>-->

          </div>

          <div *ngIf="tfaFlag">
            <input type="text" class="form-control mb-3 text-center" autocomplete="off"
              placeholder="Código Authenticator" formControlName="totpkey" name="totpkey"
              [ngClass]="{'is-invalid': submitted && this.form.controls['totpkey'].errors}" maxlength="6" />
            <div class="form-group">
              <button class="btn btn-primary btn-block" (click)="loginTfaEnabled()">Confirmar</button>
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            </div>
          </div>
        </form>
        <div *ngIf="resetQR && !resetedQR" class="centerQR">
          <div id="qr">
            Se ha solicitado un restablecimiento de Two Factor.
            Vuelva a escanear el código QR con la aplicación Authenticator en su móvil
            <div>
              <img [src]=qr.secretURL class="m-2" />

              <form [formGroup]="form2" (ngSubmit)="guardarQR()">
                <div class="d-flex justify-content-center">
                  <input type="text" name="totpkey" formControlName="totpkey"
                    class="col-sm-4 mr-2 text-center form-control" placeholder="Código Authenticator" maxlength="6"
                    [ngClass]="{'is-invalid': submitted && this.form2.controls['totpkey'].errors}">
                  <button type="submit" class="btn btn-primary m-left">
                    Activar 2FA
                  </button>
                </div>
              </form>
            </div>
            <div *ngIf="errorQR" class="m-2">
              <p>Error al activar doble factor.
                Por favor vuelva a escaner el código QR e introduzca los 6 dígitos.
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="alert alert-success" *ngIf="isLoggedIn">
          <div *ngIf="midata | json">
            <p>
              Conectado como <br /><strong>{{ midata["nombre"] }}</strong>
            </p>
            <p>
              MI id es: <br /><strong>{{ midata["id"] }}</strong>
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="text-center">
      <p *ngIf="resetedQR">Two factor activado, se le redirigirá a la página de login de nuevo</p>
    </div>
  </div>
</div>